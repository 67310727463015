
<div class="row" *ngIf="!formMissing && submissionSheetDetails && submissionSheetDetails.pdfFormId">
    <div class="col-2 shadow overflow-auto" style="border-right: 2px solid darkgray; " >
        <div class="mt-3">
        <h4>User input</h4>
        <hr>
        </div>
        <div class="row" >
        <ng-container  *ngFor="let variable of submissionSheetDetails.submissionSheetVariableDetails">
        <div *ngIf="variable.fillable &&  variable.display" class="col-12">
            <div *ngIf="'CHECK'!==variable.varType" class="d-flex align-items-center">
                <h6 style="font-size: smaller; font-weight: 600 !important;">{{variable.varNameAlias}}</h6>
                <button type="button" class="btn btn-sm btn-light f-10 ml-4 ml-auto" *ngIf="'IMAGE'===variable.varType" (click)="selectVariableForImageModal(variable)">
                    <i class="fas fa-external-link-alt ml-2 showLinkIcon"></i> &nbsp; Expand
                </button>
            </div>
            <hr class="hr-min">
            <div *ngIf="'IMAGE'===variable.varType">
                <app-image-selection [variable]="variable" [product]="product" [brand]="brand" [productImages]="productImages"></app-image-selection>
            </div>
            <div *ngIf="'TEXT'===variable.varType" class="mb-3 ml-1">
                <textarea name="{{variable.varNameAlias}}" rows="{{(variable.varValue?.length / 16)>2?(variable.varValue?.length / 16):1}}"
                    matTooltip="&#8226; Type (c) for Copyright Symbol © &#13;&#8226; Type (r) for Registered Symbol ® &#13;&#8226; Type (tm) for Trademark Symbol ™" 
                    matTooltipPosition="left" matTooltipClass="bg-dark allow-cr f-14"
                     [ngModel]="variable.varValue | replace:'\\(c\\)':'&#169;' | replace:'\\(r\\)':'&#174;' | replace:'\\(tm\\)':'&#8482;'| replace:'\\(C\\)':'&#169;' | replace:'\\(R\\)':'&#174;' | replace:'\\(TM\\)':'&#8482;'" 
                     (ngModelChange)="variable.varValue=$event" class="form-control form-control-sm"
                        maxlength="250"></textarea>
            </div>
            <div *ngIf="'DATE'===variable.varType" class="mb-3 ml-1">
                    <div class="input-group">
                        <input name="dateDisplay_{{variable.varNameAlias}}" value="{{variable.varValue |date:'mediumDate'}}"
                        class="form-control form-control-sm f-13"  readonly>
                        <div class="input-group-append">
                            <button class="btn btn-sm" (click)="dateVarPicker.open()"><i class="fas fa-calendar-alt"></i></button>
                        </div>
                        <div style="display: none;">
                        <input matInput [matDatepicker]="dateVarPicker" name="{{variable.varNameAlias}}" style="display: none !important;"
                        id="{{variable.varNameAlias}}" [(ngModel)]="variable.varValue" (click)="dateVarPicker.open()" style="cursor: pointer;"
                        class="form-control  form-control-sm" #dateVar="ngModel" 
                        [ngClass]="{'is-invalid':dateVar.touched && dateVar.errors}">
                        <mat-datepicker #dateVarPicker style="z-index: 1099;"></mat-datepicker>
                        </div>
                    </div>
            </div>
            <div *ngIf="'CHECK'===variable.varType" class="mb-3 ml-1">
                <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" class="custom-control-input " id="{{variable.varNameAlias}}1"   (change)="selectedCheckboxVariable($event,variable)"
                    [checked]="isSelectedCheckboxVariable(variable)" ><!---->
                    <label class="custom-control-label text-break" for="{{variable.varNameAlias}}1">{{variable.varNameAlias}}</label>
                </div>
            </div>
            <div *ngIf="'DROPDOWN'===variable.varType" class="mb-3 ml-1">
                <div class="">
                    <select class="form-control form-control-sm custom-select" [(ngModel)]="variable.varValue"
                        name="dropdown_{{variable.varNameAlias}}" #dropDownVar="ngModel" required
                        [ngClass]="{'is-invalid':dropDownVar.touched && dropDownVar.errors}">
                        <option *ngFor="let item of variable.varValueSource.split(',')" [ngValue]="item">
                            {{item}}</option>
                    </select>
                </div>
            </div>
            
        </div>
        </ng-container>
        </div>
    </div>
    <div class="col-10 mt-3" >
        <div class="row d-flex justify-content-center">
            <h2 class="d-inline" *ngIf="subCategory==='SUBMISSION_SHEET'">Submission Sheet - {{product?.sku}}</h2>
            <h2 class="d-inline" *ngIf="subCategory==='APPROVAL_FORM'">Approval Form - {{product?.sku}}</h2>
            <h2 class="d-inline" *ngIf="subCategory==='SAMPLE_FORM'">Sample Form - {{product?.sku}}</h2>
            <h2 class="d-inline" *ngIf="subCategory==='RETAILER_INFO_FORM'">Retailer Info - {{product?.sku}}</h2>
            <h2 class="d-inline" *ngIf="subCategory==='PHOTO_APPROVAL_FORM'">Photo Apporval - {{product?.sku}}</h2>
            <h2 class="d-inline" *ngIf="subCategory==='PRINTER_MATRIX_FORM'">Printer Matrix - {{product?.sku}}</h2>
            
        </div>
        <hr>
        <button type="button" class="btn btn-warning ml-2" (click)="createSubmissionSheet()">Preview</button>
        <button type="button" class="btn btn-success ml-2" *ngIf="submissionSheetDetails.pdfSrc" (click)="confirmSubmissionSheet('pdf')"><i class="fas fa-save"></i> Pdf</button>
        <button type="button" class="btn btn-success ml-2" *ngIf="submissionSheetDetails.pdfSrc && !product.isCollection" (click)="confirmSubmissionSheet('jpg')"><i class="fas fa-save"></i> Image</button>
        <p class="text-muted ml-2" *ngIf="!submissionSheetDetails.pdfSrc">Please click preview</p>
        <div  class="border mt-2 shadow">
            <pdf-viewer [src]="submissionSheetDetails.pdfSrc"  [original-size]="false"></pdf-viewer>
        </div>
    </div>
</div>




<div class="floatBtn d-flex flex-column">
    <button type="button" class="btn btn-circle bg-secondary mb-2" matTooltip="Scroll To Top" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'});"  matTooltipPosition="left" matTooltipClass="bg-dark">
        <i class="fas fa-arrow-up fa-2x" style="color:white"></i>
     </button>
    <a (click)="goBack()" class="btn btn-circle" style="background-color: tomato;" matTooltip="Exit" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-arrow-left  fa-2x"></i></a><!--fa-file-pdf-->                
</div>

<div *ngIf="formMissing" class="d-flex justify-content-center"><br/><br/><h5 class="center">Error while retrieving the pdf form. Contact support team. </h5></div>

<button type="button" class="btn btn-primary" id="imageModalId" style="display: none;" data-toggle="modal" data-target=".bd-example-modal-lg">Image Selection Modal</button>
<div >
    <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" style="max-width: 85%;">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">{{variableForImageModal.varNameAlias}}</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body overflow-auto">
                <div *ngIf="'IMAGE'===variableForImageModal.varType" class="p-2">
                    <app-image-selection [variable]="variableForImageModal" [product]="product" [brand]="brand" [productImages]="productImages" [imageWidthPx]="300" [imageHeightPx]="300" [modal]="true"></app-image-selection>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
