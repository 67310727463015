<div class="" >
    <div *ngIf="product" class="p-2">
        
        <div class="row" >
            <div class="col-2">
                

                <div style="position: relative;">
                    <h6 class="mat-tab-label f-14" style="font-weight: 600 !important;font-size: 18px; opacity: 1;">{{product.sku}}</h6>
                    <img [src]="product.productImages[0]?.thumbnailSignedUrl" 
                    class="img-thumbnail img-fluid zoom-4 "
                    style="
                    display: block;
                    max-width: 120px;
                    max-height: 120px;
                    width: 100%;
                    height: auto;
                    -o-object-fit: contain;
                    object-fit: contain;"
                    [style.border-color]="'cadetblue'"
                    (error)="handleThumbnailError(product.productImages[0],$event)" />
                </div>
                <!--<a type="button" style="color: black;" matTooltip="View product" matTooltipPosition="below" matTooltipClass="bg-dark" target="_blank"
                           [routerLink]="['/product/view/'+orderDetail.product.licensorId+'/'+orderDetail.product.brandId+'/'+orderDetail.product.id]">-->
                        <!--<app-image-with-preview [defaultHeight]="'auto'" [defaultWidth]="'230px'" [disablePreview]="true" [attachment]="orderDetail.product.productImages[0]">
                        </app-image-with-preview>-->
                        <!--</a>--> 
            </div>
            
            <div class="col-6">
                <mat-tab-group>
                    
                    <!--<mat-tab>
                        <ng-template mat-tab-label>
                            <h6 class="f-14">Files</h6>
                        </ng-template>
                        <mat-tab-group>-->
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <h6 class="f-14">Approval Documents</h6>
                                </ng-template>
                                <div class="overflow-hidden mt-1">
                                    <app-attachment-operations [header]="'Approval Documents'" [editable]="editable" [showHeader]="false"
                                    [createAction]="false" [attachments]="product.approvalDocs" [isSelectable]="true"
                                    [canUpload]="userAccess?.canUploadApprovalDocs" [canDownload]="userAccess?.canDownloadApprovalDocs" [canRenameFile]="true"
                                    [canDelete]="userAccess?.canDeleteApprovalDocs" [category]="'APPROVAL_DOCS'" [viewCreatedBy]="false" ></app-attachment-operations>
                                </div>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <h6 class="f-14">Approval Photos</h6>
                                </ng-template>
                                <div class="overflow-hidden mt-1">
                                    <app-attachment-operations  [header]="'Approval Photos'" [editable]="editable" [showHeader]="false"
                                             [createAction]="false" [attachments]="product.photoApproval" [isSelectable]="true"
                                             [canUpload]="userAccess?.canUploadPhotoApproval" [canDownload]="userAccess?.canDownloadPhotoApproval" [canRenameFile]="true"
                                             [canDelete]="userAccess?.canDeletePhotoApproval" [category]="'PHOTO_APPROVAL'" [viewCreatedBy]="false" ></app-attachment-operations>
                                </div>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <h6 class="f-14">Product Details</h6>
                                </ng-template>
                                <div class="overflow-hidden mt-1">
                                    <div class="row f-13 my-1">
                                        <div class="col-6">
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">SKU: </label>
                                                <div class="col-sm-7">{{product.sku}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">Description: </label>
                                                <div class="col-sm-7">{{product.description}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">Licensor: </label>
                                                <div class="col-sm-7">{{product.licensorName}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">Brand: </label>
                                                <div class="col-sm-7">{{product.brandName}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">Approval Status: </label>
                                                <div class="col-sm-7">{{product.approvalStatus}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">licensorComments: </label>
                                                <div class="col-sm-7">{{product.licensorComments}}</div>
                                            </div>
                                            
                                        </div>
                                        <div class="col-6">
                                            
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">Color: </label>
                                                <div class="col-sm-7">{{product.color}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">Treatment: </label>
                                                <div class="col-sm-7">{{product.treatment}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">Category: </label>
                                                <div class="col-sm-7">{{product.category}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">Garment: </label>
                                                <div class="col-sm-7">{{product.garment}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">Print Effect: </label>
                                                <div class="col-sm-7">{{product.printEffect}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted"># Of Screens: </label>
                                                <div class="col-sm-7">{{product.screenCount}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                            <!--<mat-tab>
                            <ng-template mat-tab-label>
                                <h6 class="f-14">Brand PNL</h6>
                            </ng-template>
                            <div class="f-13 my-1 text-danger">{{orderDetailInfo.orderDetail.pnlSpeciality}}</div>
                            <div class="overflow-hidden mt-3">
                                    <app-attachment-operations [header]="'Brand PNL'" [editable]="false" [showHeader]="false"
                                    [createAction]="false" [attachments]="getPreferredPnl(orderDetailInfo.orderDetail)" 
                                    [canUpload]="false" [canDownload]="userAccess?.canDownloadPnls" [canDelete]="false" [viewCreatedBy]="false"></app-attachment-operations>
                            </div>
                            </mat-tab>  -->
                        <!--</mat-tab-group>
                    </mat-tab>-->
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>
<hr>
