<mat-tab-group>
    <mat-tab>
       <ng-template mat-tab-label>
          <h6>SKU Approval Stages</h6>
          <span class="text-right ml-2" *ngIf="!mgStatusDetailsVar.valid">
            <i class="fa fa-exclamation-triangle" style="color:#dc3545;" aria-hidden="true"></i>
         </span>
       </ng-template>
       <div ngModelGroup="mgStatusDetails"  #mgStatusDetailsVar="ngModelGroup" style="border: 0px;">
       <div class="table-responsive mt-2"><!--card card-body-->
        <table id="detailsTable" class="mt-1 f-13 table table-sm table-bordered table-striped table-condensed hover">
            <thead>
               <tr class="bg-dark text-white">
                 <th style="white-space: nowrap;width: 1%;">&nbsp;</th>
                  <th style="width: auto;" class="align-middle text-center text-nowrap" *ngFor="let submissionDetailHeader of submissionDetailHeaders">{{submissionDetailHeader}}</th>
               </tr>
            </thead>
            <tbody class="bg-light">
                <ng-container class="row ml-2 mt-1" *ngFor="let sd of product?.statusDetails;index as index">
               <!--<tr [style]="sd.current?'box-shadow: 0px 0px 5px rgba(231, 13, 13, 0.98);border: 2px solid tomato;':''">
                    <td style="white-space: nowrap;width: 1%;;" class="align-middle text-center"></td>-->
                 <tr><!--[style]="sd.current?'box-shadow: 0px 0px 5px rgba(231, 13, 13, 0.98);border: 2px solid tomato;':''"-->
                    <td style="white-space: nowrap;width: 1%;;" class="align-middle text-center"><i class="fas fa-play" style="color: tomato;" *ngIf="sd.current"></i><span (click)="removeStatus(sd)"><i class="fas fa-trash" style="color: #db2707;" *ngIf="!sd.id"></i></span></td>
                    <td style="width: auto;" class="align-middle text-center text-nowrap">{{sd.sku}}</td>
                    <td style="width: auto;" class="align-middle text-center text-nowrap">{{sd.status}}</td>
                    <td style="width: auto;" class="align-middle text-center text-nowrap" *ngIf="!editable || sd.fromCollection">{{sd.statusDate | date:'mediumDate'}}</td>
                    <td style="width: 10%;" class="align-middle text-center text-nowrap" *ngIf="editable && !sd.fromCollection">
                        <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="statusDatePicker" name="statusDatePicker_{{index}}" 
                                id="statusDateId_{{index}}" [(ngModel)]="sd.statusDate" (click)="statusDatePicker.open()" style="cursor: pointer;"
                                class="form-control  form-control-sm"  #statusDateVar="ngModel"  required
                                [ngClass]="{'is-invalid':statusDateVar.touched && statusDateVar.errors}">
                            
                            <mat-datepicker #statusDatePicker style="z-index: 1099;"></mat-datepicker>
                            <button class="btn btn-sm" (click)="statusDatePicker.open()"><i class="fas fa-calendar-alt"></i></button>
                        </div>
                    </td>
                    <td style="width: auto;" class="align-middle text-center text-nowrap">{{sd.createdBy}}</td><!--<td style="width: auto;" class="align-middle text-center">{{sd.response}}</td>
                  <td style="width: auto;" class="align-middle text-center">{{sd.responseOn | date:'mediumDate'}}</td>-->
                  <td style="width: auto;" class="align-middle text-center text-wrap" *ngIf="!editable || sd.fromCollection">{{sd.licensorComments}}</td>
                  <td style="width: auto;" class="align-middle text-center" *ngIf="editable && !sd.fromCollection">
                        <textarea name="licensoComments{{index}}" id="licensoComments{{index}}" [(ngModel)]="sd.licensorComments" rows="{{(sd.licensorComments?.length / 50)>1?(sd.licensorComments?.length / 50)+1:1}}"
                        class="form-control form-control-sm f-13" [disabled]="!editable" maxlength="2000"
                            #licensoCommentsVar="ngModel" 
                            [ngClass]="{'is-invalid':licensoCommentsVar.touched && licensoCommentsVar.errors}">
                        </textarea><!--[required]="sharedService.licComReqMandAppStatus.includes(sd.status)"-->
                    </td>
                    <td style="width: auto;" class="align-middle text-center text-wrap" *ngIf="!editable || sd.fromCollection">{{sd.philcosComments}}</td>
                    <td style="width: auto;" class="align-middle text-center" *ngIf="editable && !sd.fromCollection">
                        <textarea name="philcosComments{{index}}" id="philcosComments{{index}}" [(ngModel)]="sd.philcosComments" rows="{{(sd.philcosComments?.length / 50)>1?(sd.philcosComments?.length / 50)+1:1}}"
                        class="form-control form-control-sm f-13" [disabled]="!editable" maxlength="500"
                            #philcosCommentsVar="ngModel"
                            [ngClass]="{'is-invalid':philcosCommentsVar.touched && philcosCommentsVar.errors}">
                        </textarea>
                    </td>
               </tr>
            
                </ng-container>
               
            </tbody>
            </table>
            </div>
        </div>
    </mat-tab>





















<!-- SAMPLE DETAILS STARTS-->
    <mat-tab>
       <ng-template mat-tab-label>
          <h6>Sample Details</h6>
          <span class="text-right ml-2" *ngIf="mgSampleDeatilsVar.invalid">
            <i class="fa fa-exclamation-triangle" style="color:#dc3545;" aria-hidden="true"></i>
         </span>
       </ng-template>
       <div class="d-flex justify-content-between">
            <div class=" d-flex justify-content-start">
                    <button type="button" *ngIf="editable" class="btn btn-info btn-sm mt-1" (click)="addNewSampleDetailsEntry()">+ Add</button>
            </div >    
            <div class="mt-2" *ngIf="product?.sampleDetails && product?.sampleDetails.length>0 && product?.photoApprovalRequired">
                <span class="text-right ml-2 text-danger" *ngIf="true">
                    <i class="fa fa-exclamation-triangle" style="color:#dc3545;" aria-hidden="true"></i>
                    Photo approval is required.
                 </span>
            </div>
            <div class=" d-flex justify-content-end align-items-center" *ngIf="product?.sampleDetails && product?.sampleDetails.length>0">
                <!--<div class="mr-2 d-flex " >
                    <a class="badge badge-pill bg-dark" [matTooltip]="sampleDetailsDistribution" 
                    matTooltipPosition="right" matTooltipClass="allow-cr extended-tooltip"><i class="fas fa-info" style="color: white;" ></i></a> 
                </div>-->
                
                <div class="custom-control custom-checkbox mr-sm-2" >
                    <input type="checkbox" class="custom-control-input " id="includeShippingDetailsId{{product?.sku}}{{uniqueId}}"   (change)="includeShippingDetailsChange($event)"
                    [checked]="showTrackingDeatils" ><!---->
                    <label class="custom-control-label text-break align-middle" for="includeShippingDetailsId{{product?.sku}}{{uniqueId}}"><small>Shipping Details</small></label>
                </div>
                
            </div>
        </div>
   

       <div ngModelGroup="mgSampleDeatils"  #mgSampleDeatilsVar="ngModelGroup" style="border: 0px;">
       <div class="table-responsive mt-2" *ngIf="product?.sampleDetails && product?.sampleDetails.length>0"><!--card card-body-->
        <table id="sampleDetailsTable" class="mt-1 f-13 table table-sm table-bordered  hover" [style]="showTrackingDeatils?'width: 125%;':''">
            <thead *ngIf="showTrackingDeatils">
               <tr class="bg-dark text-white" >
                 <th style="white-space: nowrap;width: 1%;">&nbsp;</th>
                  <!--style="width: auto;" --><th  class="align-middle text-center text-nowrap" [attr.colspan]="groupHeader.colspan"  *ngFor="let groupHeader of sampleDetailsGroupHeaders">{{groupHeader.name}}</th>
               </tr>
               <tr class="text-white" style="background-color: #343a40c2 !important;">
                <th style="white-space: nowrap;width: 1%;">&nbsp;</th>
                 <!--style="width: auto;" --><th class="align-middle text-center text-nowrap" *ngFor="let sampleDetailsHeader of sampleDetailsHeaders">{{sampleDetailsHeader}}</th>
              </tr>
            </thead>
            <thead *ngIf="!showTrackingDeatils">
                <tr class="bg-dark text-white" >
                  <th style="white-space: nowrap;width: 1%;">&nbsp;</th>
                   <!--style="width: auto;" --><th  class="align-middle text-center text-nowrap" [attr.colspan]="groupHeader.colspan"  *ngFor="let groupHeader of sampleDetailsGroupHeadersMinimal">{{groupHeader.name}}</th>
                </tr>
                <tr class="text-white" style="background-color: #343a40c2 !important;">
                 <th style="white-space: nowrap;width: 1%;">&nbsp;</th>
                  <!--style="width: auto;" --><th class="align-middle text-center text-nowrap" *ngFor="let sampleDetailsHeader of sampleDetailsHeadersMinimal">{{sampleDetailsHeader}}</th>
               </tr>
             </thead>
            <tbody class="bg-light">
                <ng-container class="row ml-2 mt-1" *ngFor="let sd of product?.sampleDetails;index as index">
                 <tr class="mt-5" [style]="index%2===1?'background-color: rgba(0,0,0,.05);':''" *ngIf="!sd.delete">
                    <td style="white-space: nowrap;width: 1%;" rowspan="2" class="align-middle text-center">
                        <i class="fas fa-play" style="color: tomato;" *ngIf="sd.current"></i>
                        <span (click)="removeSample(sd)" *ngIf="editable && (!sd.id || userAccess?.canDeleteSkuTrackerItems)">
                            <i class="fas fa-trash ml-2" style="color: #db2707;"></i>
                        </span>
                    </td>
                    <td *ngIf="!editable" style="width: auto;" class="align-middle text-center text-nowrap">{{sd.sampleReqDate  | date:'mediumDate'}}</td>
                    <td *ngIf="editable" class="align-middle text-center" style="width: auto;">
                        <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="sampleReqDatePicker" name="sampleReqDatePicker_{{index}}" 
                                id="sampleReqDate_{{index}}" [(ngModel)]="sd.sampleReqDate" (click)="sampleReqDatePicker.open()" style="cursor: pointer;"
                                class="form-control  form-control-sm"  #sampleReqDateVar="ngModel" 
                                [ngClass]="{'is-invalid':sampleReqDateVar.touched && sampleReqDateVar.errors}">
                            <mat-datepicker #sampleReqDatePicker style="z-index: 1099;"></mat-datepicker>
                            <!--<button class="btn btn-sm" (click)="sampleReqDatePicker.open()"><i class="fas fa-calendar-alt"></i></button>-->
                        </div>
                    </td>
                    <!--<td style="width: auto;" class="align-middle text-center text-wrap" *ngIf="!editable">{{sd.customerId}}</td>-->
                    <td style="width: auto;" class="align-middle text-center" >
                            <select style="width: auto;" name="customer{{index}}" id="customer{{index}}" [(ngModel)]="sd.customerId" class="f-13 form-control form-control-sm custom-select" 
                            #sdcustomerVar="ngModel"
                               [disabled]="!editable">
                               <option *ngFor="let customer of customerList" [ngValue]="customer.id">
                                                                   {{customer.customerName}}</option>
                            </select>
                      
                    </td>
                    <td *ngIf="!editable" style="width: auto;" class="align-middle text-center text-nowrap">{{sd.customerOrderDueDate  | date:'mediumDate'}}</td>
                    <td *ngIf="editable" class="align-middle text-center" style="width: auto;">
                        <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="customerOrderDueDatePicker" name="customerOrderDueDatePicker_{{index}}" 
                                id="customerOrderDueDate_{{index}}" [(ngModel)]="sd.customerOrderDueDate" (click)="customerOrderDueDatePicker.open()" style="cursor: pointer;"
                                class="form-control  form-control-sm"  #customerOrderDueDateVar="ngModel" 
                                [ngClass]="{'is-invalid':customerOrderDueDateVar.touched && customerOrderDueDateVar.errors}">
                            <mat-datepicker #customerOrderDueDatePicker style="z-index: 1099;"></mat-datepicker>
                            <!--<button class="btn btn-sm" (click)="customerOrderDueDatePicker.open()"><i class="fas fa-calendar-alt"></i></button>-->
                        </div>
                    </td>
                    <td style="width: auto;" class="align-middle text-center text-wrap" *ngIf="!editable">{{sd.requestTo}}</td>
                    <td style="width: auto;" class="align-middle text-center" *ngIf="editable">
                          <textarea name="requestTo{{index}}" id="requestTo{{index}}" [(ngModel)]="sd.requestTo" rows="{{(sd.requestTo?.length / 50)>1?(sd.requestTo?.length / 50)+1:1}}"
                          class="form-control form-control-sm f-13" [disabled]="!editable" maxlength="50"
                              #requestToVar="ngModel" 
                              [ngClass]="{'is-invalid':requestToVar.touched && requestToVar.errors}">
                          </textarea>
                    </td>
                    <td style="width: auto;" *ngIf="!editable" class="align-middle text-center text-nowrap">{{sd.sampleReqQty}}</td>
                    <td style="white-space: nowrap;width: 4%;" *ngIf="editable" class="align-middle text-center text-nowrap">
                        <input type="number" name="sampleReqQty_{{index}}" [(ngModel)]="sd.sampleReqQty" class="form-control form-control-sm" >
                    </td>
                    <!--SAMPLE RECEIVE STARTS-->
                    <td *ngIf="!editable" style="width: auto;" class="align-middle text-center text-nowrap">{{sd.samplesReceivedDate  | date:'mediumDate'}}</td>
                    <td *ngIf="editable" class="align-middle text-center" style="width: auto;">
                        <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="samplesReceivedDatePicker" name="samplesReceivedDatePicker_{{index}}" 
                                id="samplesReceivedDate_{{index}}" [(ngModel)]="sd.samplesReceivedDate" (click)="samplesReceivedDatePicker.open()" style="cursor: pointer;"
                                class="form-control  form-control-sm"  #samplesReceivedDateVar="ngModel" 
                                [ngClass]="{'is-invalid':samplesReceivedDateVar.touched && samplesReceivedDateVar.errors}">
                            <mat-datepicker #samplesReceivedDatePicker style="z-index: 1099;"></mat-datepicker>
                            <!--<button class="btn btn-sm" (click)="samplesReceivedDatePicker.open()"><i class="fas fa-calendar-alt"></i></button>-->
                        </div>
                    </td>
                    <td style="width: auto;" *ngIf="!editable" class="align-middle text-center text-nowrap">{{sd.samplesReceivedQty}}</td>
                    <td style="white-space: nowrap;width: 4%;" *ngIf="editable" class="align-middle text-center text-nowrap">
                        <input type="number" name="samplesReceivedQty_{{index}}" [(ngModel)]="sd.samplesReceivedQty" class="form-control form-control-sm" >
                    </td>
                    <!--SAMPLE RECEIVE ENDS-->
                    <!--PRE PRO PHOTO SAMPLE STARTS-->
                    <td *ngIf="!editable" style="width: auto;" class="align-middle text-center text-nowrap">{{sd.preProPhotoSamplesReqDate  | date:'mediumDate'}}</td>
                    <td *ngIf="editable" class="align-middle text-center" style="width: auto;">
                        <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="preProPhotoSamplesReqDatePicker" name="preProPhotoSamplesReqDatePicker_{{index}}" 
                                id="preProPhotoSamplesReqDate_{{index}}" [(ngModel)]="sd.preProPhotoSamplesReqDate" (click)="preProPhotoSamplesReqDatePicker.open()" style="cursor: pointer;"
                                class="form-control  form-control-sm"  #preProPhotoSamplesReqDateVar="ngModel" 
                                [ngClass]="{'is-invalid':preProPhotoSamplesReqDateVar.touched && preProPhotoSamplesReqDateVar.errors}">
                            <mat-datepicker #preProPhotoSamplesReqDatePicker style="z-index: 1099;"></mat-datepicker>
                            <!--<button class="btn btn-sm" (click)="preProPhotoSamplesReqDatePicker.open()"><i class="fas fa-calendar-alt"></i></button>-->
                        </div>
                    </td>
                    <td style="width: auto;" *ngIf="!editable" class="align-middle text-center text-nowrap">{{sd.preProPhotoSamplesQty}}</td>
                    <td style="white-space: nowrap;width: 4%;" *ngIf="editable" class="align-middle text-center text-nowrap">
                        <input type="number" name="preProPhotoSamplesQty_{{index}}" [(ngModel)]="sd.preProPhotoSamplesQty" class="form-control form-control-sm" >
                    </td>
                    <td *ngIf="!editable" style="width: auto;" class="align-middle text-center text-nowrap">{{sd.preProPhotoSamplesResDate  | date:'mediumDate'}}</td>
                    <td *ngIf="editable" class="align-middle text-center" style="width: auto;">
                        <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="preProPhotoSamplesResDatePicker" name="preProPhotoSamplesResDatePicker_{{index}}" 
                                id="preProPhotoSamplesResDate_{{index}}" [(ngModel)]="sd.preProPhotoSamplesResDate" (click)="preProPhotoSamplesResDatePicker.open()" style="cursor: pointer;"
                                class="form-control  form-control-sm" #preProPhotoSamplesResDateVar="ngModel" 
                                [ngClass]="{'is-invalid':preProPhotoSamplesResDateVar.touched && preProPhotoSamplesResDateVar.errors}">
                            <mat-datepicker #preProPhotoSamplesResDatePicker style="z-index: 1099;"></mat-datepicker>
                            <!--<button class="btn btn-sm" (click)="preProPhotoSamplesResDatePicker.open()"><i class="fas fa-calendar-alt"></i></button>-->
                        </div>
                    </td>
                    <!--PRE PRO PHOTO SAMPLE ENDS-->
                    <!--PRE PRO SAMPLE STARTS-->
                    <td *ngIf="!editable" style="width: auto;" class="align-middle text-center text-nowrap">{{sd.preProSamplesReqDate  | date:'mediumDate'}}</td>
                    <td *ngIf="editable" class="align-middle text-center" style="width: auto;">
                        <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="preProSamplesReqDatePicker" name="preProSamplesReqDatePicker_{{index}}" 
                                id="preProSamplesReqDate_{{index}}" [(ngModel)]="sd.preProSamplesReqDate"  (click)="preProSamplesReqDatePicker.open()" style="cursor: pointer;"
                                class="form-control  form-control-sm"  #preProSamplesReqDateVar="ngModel" 
                                [ngClass]="{'is-invalid':preProSamplesReqDateVar.touched && preProSamplesReqDateVar.errors}">
                            <mat-datepicker #preProSamplesReqDatePicker style="z-index: 1099;"></mat-datepicker>
                            <!--<button class="btn btn-sm" (click)="preProSamplesReqDatePicker.open()"><i class="fas fa-calendar-alt"></i></button>-->
                        </div>
                    </td>
                    <td style="width: auto;" *ngIf="!editable" class="align-middle text-center text-nowrap">{{sd.preProSamplesQty}}</td>
                    <td style="white-space: nowrap;width: 4%;" *ngIf="editable" class="align-middle text-center text-nowrap">
                        <input type="number" name="preProSamplesQty_{{index}}" [(ngModel)]="sd.preProSamplesQty" class="form-control form-control-sm" >
                    </td>
                    <td style="width: auto;" class="align-middle text-center text-wrap" *ngIf="!editable && showTrackingDeatils">{{sd.preProSamplesTrackingNo}}</td>
                    <td style="width: auto;" class="align-middle text-center" *ngIf="editable && showTrackingDeatils">
                        <textarea name="preProSamplesTrackingNo{{index}}" id="preProSamplesTrackingNo{{index}}" [(ngModel)]="sd.preProSamplesTrackingNo" rows="{{(sd.preProSamplesTrackingNo?.length / 50)>1?(sd.preProSamplesTrackingNo?.length / 50)+1:1}}"
                        class="form-control form-control-sm f-13" [disabled]="!editable" maxlength="50"
                            #preProSamplesTrackingNoVar="ngModel" 
                            [ngClass]="{'is-invalid':preProSamplesTrackingNoVar.touched && preProSamplesTrackingNoVar.errors}">
                        </textarea>
                    </td>
                    <td style="width: auto;" class="align-middle text-center text-wrap" *ngIf="!editable && showTrackingDeatils">{{sd.preProSamplesCourier}}</td>
                    <!--<td style="width: auto;" class="align-middle text-center" *ngIf="editable && showTrackingDeatils">
                        <textarea name="preProSamplesCourier{{index}}" id="preProSamplesCourier{{index}}" [(ngModel)]="sd.preProSamplesCourier" rows="{{(sd.preProSamplesCourier?.length / 50)>1?(sd.preProSamplesCourier?.length / 50)+1:1}}"
                        class="form-control form-control-sm f-13" [disabled]="!editable" maxlength="50"
                            #preProSamplesCourierVar="ngModel" 
                            [ngClass]="{'is-invalid':preProSamplesCourierVar.touched && preProSamplesCourierVar.errors}">
                        </textarea>
                    </td>-->
                    <td style="white-space: nowrap;width: 1%;" class="align-middle text-center" *ngIf="editable && showTrackingDeatils">
                        <select style="width: auto;" name="preProSamplesCourier{{index}}" [(ngModel)]="sd.preProSamplesCourier" class="f-13 form-control form-control-sm custom-select" 
                        #preProcourierVar="ngModel" [disabled]="!editable">
                           <option *ngFor="let courier of courierList" [ngValue]="courier">{{courier}}</option>
                        </select>
                    </td>
                    <td *ngIf="!editable" style="width: auto;" class="align-middle text-center text-nowrap">{{sd.preProSamplesResDate  | date:'mediumDate'}}</td>
                    <td *ngIf="editable" class="align-middle text-center" style="width: auto;">
                        <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="preProSamplesResDatePicker" name="preProSamplesResDatePicker_{{index}}" 
                                id="preProSamplesResDate_{{index}}" [(ngModel)]="sd.preProSamplesResDate"  (click)="preProSamplesResDatePicker.open()" style="cursor: pointer;"
                                class="form-control  form-control-sm"  #preProSamplesResDateVar="ngModel" 
                                [ngClass]="{'is-invalid':preProSamplesResDateVar.touched && preProSamplesResDateVar.errors}">
                            <mat-datepicker #preProSamplesResDatePicker style="z-index: 1099;"></mat-datepicker>
                            <!--<button class="btn btn-sm" (click)="preProSamplesResDatePicker.open()"><i class="fas fa-calendar-alt"></i></button>-->
                        </div>
                    </td>
                    <!--PRE PRO SAMPLE ENDS-->
                    <!--FINAL SAMPLE STARTS-->
                    <td *ngIf="!editable" style="width: auto;" class="align-middle text-center text-nowrap">{{sd.finalSamplesReqDate  | date:'mediumDate'}}</td>
                    <td *ngIf="editable" class="align-middle text-center" style="width: auto;">
                        <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="finalSamplesReqDatePicker" name="finalSamplesReqDatePicker_{{index}}" 
                                id="finalSamplesReqDate_{{index}}" [(ngModel)]="sd.finalSamplesReqDate"  (click)="finalSamplesReqDatePicker.open()" style="cursor: pointer;"
                                class="form-control  form-control-sm"  #finalSamplesReqDateVar="ngModel" 
                                [ngClass]="{'is-invalid':finalSamplesReqDateVar.touched && finalSamplesReqDateVar.errors}">
                            <mat-datepicker #finalSamplesReqDatePicker style="z-index: 1099;"></mat-datepicker>
                            <!--<button class="btn btn-sm" (click)="finalSamplesReqDatePicker.open()"><i class="fas fa-calendar-alt"></i></button>-->
                        </div>
                    </td>
                    <td style="width: auto;" *ngIf="!editable" class="align-middle text-center text-nowrap">{{sd.finalSamplesQty}}</td>
                    <td style="white-space: nowrap;width: 4%;" *ngIf="editable" class="align-middle text-center text-nowrap">
                        <input type="number" name="finalSamplesQty_{{index}}" [(ngModel)]="sd.finalSamplesQty" class="form-control form-control-sm" >
                    </td>
                    <td style="width: auto;" class="align-middle text-center text-wrap" *ngIf="!editable && showTrackingDeatils">{{sd.finalSamplesTrackingNo}}</td>
                    <td style="width: auto;" class="align-middle text-center" *ngIf="editable && showTrackingDeatils">
                        <textarea name="finalSamplesTrackingNo{{index}}" id="finalSamplesTrackingNo{{index}}" [(ngModel)]="sd.finalSamplesTrackingNo" rows="{{(sd.finalSamplesTrackingNo?.length / 50)>1?(sd.finalSamplesTrackingNo?.length / 50)+1:1}}"
                        class="form-control form-control-sm f-13" [disabled]="!editable" maxlength="50"
                            #finalSamplesTrackingNoVar="ngModel" 
                            [ngClass]="{'is-invalid':finalSamplesTrackingNoVar.touched && finalSamplesTrackingNoVar.errors}">
                        </textarea>
                    </td>
                    <td style="width: auto;" class="align-middle text-center text-wrap" *ngIf="!editable && showTrackingDeatils">{{sd.finalSamplesCourier}}</td>
                    <!--<td style="width: auto;" class="align-middle text-center" *ngIf="editable && showTrackingDeatils">
                        <textarea name="finalSamplesCourier{{index}}" id="finalSamplesCourier{{index}}" [(ngModel)]="sd.finalSamplesCourier" rows="{{(sd.finalSamplesCourier?.length / 50)>1?(sd.finalSamplesCourier?.length / 50)+1:1}}"
                        class="form-control form-control-sm f-13" [disabled]="!editable" maxlength="50"
                            #finalSamplesCourierVar="ngModel" 
                            [ngClass]="{'is-invalid':finalSamplesCourierVar.touched && finalSamplesCourierVar.errors}">
                        </textarea>
                    </td>-->
                    <td style="white-space: nowrap;width: 1%;" class="align-middle text-center" *ngIf="editable && showTrackingDeatils">
                        <select style="width: auto;" name="finalSamplesCourier{{index}}" [(ngModel)]="sd.finalSamplesCourier" class="f-13 form-control form-control-sm custom-select" 
                        #fscourierVar="ngModel" [disabled]="!editable">
                           <option *ngFor="let courier of courierList" [ngValue]="courier">{{courier}}</option>
                        </select>
                    </td>
                    <td *ngIf="!editable" style="width: auto;" class="align-middle text-center text-nowrap">{{sd.finalSamplesResDate  | date:'mediumDate'}}</td>
                    <td *ngIf="editable" class="align-middle text-center" style="width: auto;">
                        <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="finalSamplesResDatePicker" name="finalSamplesResDatePicker_{{index}}" 
                                id="finalSamplesResDate_{{index}}" [(ngModel)]="sd.finalSamplesResDate"  (click)="finalSamplesResDatePicker.open()" style="cursor: pointer;"
                                class="form-control  form-control-sm"  #finalSamplesResDateVar="ngModel" 
                                [ngClass]="{'is-invalid':finalSamplesResDateVar.touched && finalSamplesResDateVar.errors}">
                            <mat-datepicker #finalSamplesResDatePicker style="z-index: 1099;"></mat-datepicker>
                            <!--<button class="btn btn-sm" (click)="finalSamplesResDatePicker.open()"><i class="fas fa-calendar-alt"></i></button>-->
                        </div>
                    </td>
                   
                    <!--FINAL SAMPLE ENDS-->
                    
                   <!--SHIPPING START OBSOLETE
                    <td style="width: auto;" *ngIf="!editable" class="align-middle text-center text-nowrap">{{sd.samplesShippedQty}}</td>
                    <td style="white-space: nowrap;width: 4%;" *ngIf="editable" class="align-middle text-center text-nowrap">
                        <input type="number" name="samplesShippedQty_{{index}}" [(ngModel)]="sd.samplesShippedQty" class="form-control form-control-sm" >
                    </td>
                    <td *ngIf="!editable" style="width: auto;" class="align-middle text-center text-nowrap">{{sd.samplesShippedDate  | date:'mediumDate'}}</td>
                    <td *ngIf="editable" class="align-middle text-center" style="width: auto;">
                        <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="samplesShippedDatePicker" name="samplesShippedDatePicker_{{index}}" 
                                id="samplesShippedDate_{{index}}" [(ngModel)]="sd.samplesShippedDate" (click)="samplesShippedDatePicker.open()" style="cursor: pointer;"
                                class="form-control  form-control-sm"  #samplesShippedDateVar="ngModel" 
                                [ngClass]="{'is-invalid':samplesShippedDateVar.touched && samplesShippedDateVar.errors}">
                            <mat-datepicker #samplesShippedDatePicker style="z-index: 1099;"></mat-datepicker>
                           
                        </div>
                    </td>
                    <td style="width: auto;" class="align-middle text-center text-wrap" *ngIf="!editable">{{sd.shippingTrackingNumber}}</td>
                    <td style="width: auto;" class="align-middle text-center" *ngIf="editable">
                        <textarea name="shippingTrackingNumber{{index}}" id="shippingTrackingNumber{{index}}" [(ngModel)]="sd.shippingTrackingNumber" rows="{{(sd.shippingTrackingNumber?.length / 50)>1?(sd.shippingTrackingNumber?.length / 50)+1:1}}"
                        class="form-control form-control-sm f-13" [disabled]="!editable" maxlength="50"
                            #shippingTrackingNumberVar="ngModel" 
                            [ngClass]="{'is-invalid':shippingTrackingNumberVar.touched && shippingTrackingNumberVar.errors}">
                        </textarea>
                    </td>
                    <td style="width: auto;" class="align-middle text-center text-wrap" *ngIf="!editable">{{sd.shippingCompany}}</td>
                    <td style="width: auto;" class="align-middle text-center" *ngIf="editable">
                        <textarea name="shippingCompany{{index}}" id="shippingCompany{{index}}" [(ngModel)]="sd.shippingCompany" rows="{{(sd.shippingCompany?.length / 50)>1?(sd.shippingCompany?.length / 50)+1:1}}"
                        class="form-control form-control-sm f-13" [disabled]="!editable" maxlength="50"
                            #shippingCompanyVar="ngModel" 
                            [ngClass]="{'is-invalid':shippingCompanyVar.touched && shippingCompanyVar.errors}">
                        </textarea>
                    </td>
                    
                    SHIPPING ENDS OBSOLETE-->
                    
                    
               </tr>
               <tr class="mb-2" style="border-bottom: 1.5px solid darkgray;" [style]="index%2===1?'background-color: rgba(0,0,0,.05);':''" *ngIf="!sd.delete">
                   
                   <td style="width: auto;"  [attr.colspan]="showTrackingDeatils?'20':'16'" class="align-middle text-center text-wrap" *ngIf="!editable">
                    <div class="d-flex d-inline">
                        <label for="sampleDetailsComments{{index}}" class="font-weight-bold  col-form-label">Notes:</label>
                        <textarea name="sampleDetailsComments{{index}}" id="sampleDetailsComments{{index}}"  rows="{{(sd.notes?.length / 100)>1?(sd.notes?.length / 100)+1:1}}"
                            class="form-control form-control-sm f-13 ml-2" [disabled]="!editable" maxlength="500">{{sd.notes}}
                            </textarea>
                    </div>
                   
                    </td>
                    <td style="width: auto;"  [attr.colspan]="showTrackingDeatils?'20':'16'" class="align-middle text-center" *ngIf="editable">
                        <div class="d-flex d-inline">
                            <label for="sampleDetailsComments{{index}}" class="font-weight-bold  col-form-label">Notes:</label>
                        <textarea name="sampleDetailsComments{{index}}" id="sampleDetailsComments{{index}}" [(ngModel)]="sd.notes" rows="{{(sd.notes?.length / 100)>1?(sd.notes?.length / 100)+1:1}}"
                        class="form-control form-control-sm f-13 ml-2" [disabled]="!editable" maxlength="500" 
                            #sampleDetailsCommentsVar="ngModel"
                            [ngClass]="{'is-invalid':sampleDetailsCommentsVar.touched && sampleDetailsCommentsVar.errors}">
                        </textarea>
                        </div>
                    </td>
               </tr>
               <!--<tr><td colspan="14">&nbsp;</td></tr>-->
               
                </ng-container>
               
            </tbody>
        </table>
        </div>
        <div class="table-responsive mt-2" *ngIf="!product?.sampleDetails || product?.sampleDetails.length===0">
            <p class="text-muted ml-3"> No sample details available for SKU - {{product?.sku}}</p>
        </div>
        </div>
     </mat-tab>

     <!-- SAMPLE DETAILS ENDS-->





     <!---RETAILER INFO-->
     <mat-tab>
        <ng-template mat-tab-label>
           <h6>Retailer Info</h6>
           <span class="text-right ml-2" *ngIf="mgRetailerInfoVar.invalid">
             <i class="fa fa-exclamation-triangle" style="color:#dc3545;" aria-hidden="true"></i>
          </span>
        </ng-template>
        <button type="button" *ngIf="editable" class="btn btn-info btn-sm mt-1" (click)="addNewRetailerInfoEntry()">+ Add</button>
        <div ngModelGroup="mgRetailerInfo"  #mgRetailerInfoVar="ngModelGroup" style="border: 0px;">
        <div class="table-responsive mt-2 d-flex justify-content-center" *ngIf="product?.skuTrackerRetailerInfo && product?.skuTrackerRetailerInfo.length>0"><!--card card-body-->
         <table id="skuTrackerRetailerInfoTable" style="width: 100%;" class="mt-1 f-13 table table-sm table-bordered table-striped table-condensed hover">
            <thead>
                <tr class="bg-dark text-white">
                  <th style="white-space: nowrap;width: 1%;">&nbsp;</th>
                   <th style="width: auto;" class="align-middle text-center text-nowrap" *ngFor="let skuTrackerRetailerInfoHeader of skuTrackerRetailerInfoHeaders">{{skuTrackerRetailerInfoHeader}}</th>
                </tr>
             </thead>
             <tbody class="bg-light">
                 <ng-container class="row ml-2 mt-1" *ngFor="let sd of product?.skuTrackerRetailerInfo;index as index">
                <!--<tr [style]="sd.current?'box-shadow: 0px 0px 5px rgba(231, 13, 13, 0.98);border: 2px solid tomato;':''">
                     <td style="white-space: nowrap;width: 1%;;" class="align-middle text-center"></td>-->
                     <tr *ngIf="!sd.delete"><!--[style]="sd.current?'box-shadow: 0px 0px 5px rgba(231, 13, 13, 0.98);border: 2px solid tomato;':''"-->
                        <td style="white-space: nowrap;width: 1%;;" class="align-middle text-center">
                            <i class="fas fa-play" style="color: tomato;" *ngIf="sd.current"></i>
                            <span (click)="removeOrder(sd)" class="ml-2" *ngIf="editable && (!sd.id || userAccess?.canDeleteSkuTrackerItems)">
                                <i class="fas fa-trash" style="color: #db2707;" ></i>
                            </span>
                        </td>
                        <td style="white-space: nowrap;width: 1%;;" class="align-middle text-center text-nowrap">{{sd.sku}}</td>
                        <!--<td style="width: auto;" class="align-middle text-center text-nowrap">{{sd.createdBy}}</td>-->
                        <!--<td style="white-space: nowrap;width: 1%;" class="align-middle text-center text-nowrap" *ngIf="!editable">{{sd.customerId}}</td>-->
                        <td style="white-space: nowrap;width: 1%;" class="align-middle text-center" >
                            <select style="width: auto;" name="customerSTRI{{index}}" [(ngModel)]="sd.customerId" class="f-13 form-control form-control-sm custom-select" 
                            #ricustomerVar="ngModel" [disabled]="!editable">
                               <option *ngFor="let customer of customerList" [ngValue]="customer.id">{{customer.customerName}}</option>
                            </select>
                       </td>
                        <td style="width: auto;" *ngIf="!editable" class="align-middle text-center text-nowrap">{{sd.orderQty}}</td>
                        <td style="white-space: nowrap;width: 6%;" *ngIf="editable" class="align-middle text-center text-nowrap">
                            <input type="number" name="orderQtySTRI_{{index}}" [(ngModel)]="sd.orderQty" class="form-control form-control-sm" >
                        </td>
                        <td style="width: auto;" *ngIf="!editable" class="align-middle text-center text-nowrap">${{sd.wholesaleCost}}</td>
                        <td style="white-space: nowrap;width: 6%;" *ngIf="editable" class="align-middle text-center text-nowrap">
                            <div class="d-flex d-inline align-items-center">$&nbsp;<input type="number" name="wholesaleCostSTRI_{{index}}" [(ngModel)]="sd.wholesaleCost" class="form-control form-control-sm" ></div>
                        </td>
                        <td style="width: auto;" *ngIf="!editable" class="align-middle text-center text-nowrap">${{sd.retailCost}}</td>
                        <td style="white-space: nowrap;width: 6%;" *ngIf="editable" class="align-middle text-center text-nowrap">
                            <div class="d-flex d-inline align-items-center">$&nbsp;<input type="number" name="retailCostSTRI_{{index}}" [(ngModel)]="sd.retailCost" class="form-control form-control-sm" ></div>
                        </td>
                        
                        <td style="width: auto;" class="align-middle text-center text-nowrap" *ngIf="!editable">{{sd.orderDueDate | date:'mediumDate'}}</td>
                        <td style="white-space: nowrap;width: 6%;" class="align-middle text-center text-nowrap" *ngIf="editable">
                            <div class="d-flex d-inline">
                                <input matInput [matDatepicker]="orderDueDateSTRIPicker" name="orderDueDateSTRIPicker_{{index}}" 
                                    [(ngModel)]="sd.orderDueDate" (click)="orderDueDateSTRIPicker.open()" style="cursor: pointer;"
                                    class="form-control  form-control-sm"  #orderDueDateSTRIVar="ngModel"  
                                    [ngClass]="{'is-invalid':orderDueDateSTRIVar.touched && orderDueDateSTRIVar.errors}">
                                
                                <mat-datepicker #orderDueDateSTRIPicker style="z-index: 1099;"></mat-datepicker>
                                <button class="btn btn-sm" (click)="orderDueDateSTRIPicker.open()"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                        </td>
                        <!--
                        <td style="width: auto;" class="align-middle text-center text-nowrap" *ngIf="!editable">{{sd.orderShippedDate | date:'mediumDate'}}</td>
                        <td style="width: auto;" class="align-middle text-center text-nowrap" *ngIf="editable">
                            <div class="d-flex d-inline">
                                <input matInput [matDatepicker]="orderShippedDateSTRIPicker" name="orderShippedDateSTRIPicker_{{index}}" 
                                    [(ngModel)]="sd.orderShippedDate" (click)="orderShippedDateSTRIPicker.open()" style="cursor: pointer;"
                                    class="form-control  form-control-sm"  #orderShippedDateSTRIVar="ngModel"  
                                    [ngClass]="{'is-invalid':orderShippedDateSTRIVar.touched && orderShippedDateSTRIVar.errors}">
                                
                                <mat-datepicker #orderShippedDateSTRIPicker style="z-index: 1099;"></mat-datepicker>
                                <button class="btn btn-sm" (click)="orderShippedDateSTRIPicker.open()"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                        </td>
                    -->
                        <td style="white-space: nowrap;width: 1%;" class="align-middle text-center text-nowrap" *ngIf="!editable">{{sd.status}}</td>
                        <td style="white-space: nowrap;width: 1%;" class="align-middle text-center" *ngIf="editable">
                            <select style="width: auto;" name="statusSTRI{{index}}" [(ngModel)]="sd.status" class="f-13 form-control form-control-sm custom-select" 
                            #statusVar="ngModel" [disabled]="!editable">
                                <option value=""></option>
                                <option value="Current Order">Current Order</option>
                                <option value="Shipped">Shipped</option>
                                <option value="Cancelled">Cancelled</option>
                            </select>
                       </td>
                        <td style="width: auto;" class="align-middle text-center text-wrap" *ngIf="!editable">{{sd.comments}}</td>
                        <td style="white-space: nowrap;width: auto;" class="align-middle text-center" *ngIf="editable">
                            <textarea name="commentsSTRI{{index}}"  [(ngModel)]="sd.comments" rows="{{(sd.comments?.length / 50)>1?(sd.comments?.length / 50)+1:1}}"
                            class="form-control form-control-sm f-13" [disabled]="!editable" maxlength="500"
                                #commentsVar="ngModel"
                                [ngClass]="{'is-invalid':commentsVar.touched && commentsVar.errors}">
                            </textarea>
                        </td>
                   </tr>
                 </ng-container>
                
             </tbody>
         </table>
         </div>
         <div class="table-responsive mt-2" *ngIf="!product?.skuTrackerRetailerInfo || product?.skuTrackerRetailerInfo.length===0">
             <p class="text-muted ml-3"> No retailer info available for SKU - {{product?.sku}}</p>
         </div>
         </div>
      </mat-tab>
     <!--RETAILER INFO ENDS-->
     
</mat-tab-group>
<div style="font-size: x-small;" class="text-muted"><em>Product Version: {{product?.version}}</em></div>