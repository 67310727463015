import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/shared/model/user.model';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/service/auth.service';
import { VendorsService } from 'src/app/shared/service/vendors/vendors.service';
import { BlanksSupplier } from 'src/app/shared/model/vendors/blanks-supplier.model';

@Component({
  selector: 'app-blanks-supplier',
  templateUrl: './blanks-supplier.component.html',
  styleUrls: ['./blanks-supplier.component.css']
})
export class BlanksSupplierComponent implements OnInit {

   createAction: boolean = false;
   viewAction: boolean = false;
   editable: boolean = false;

   constructor(private authService: AuthService, private router: Router, private vendorService: VendorsService,
      private activatedRoute: ActivatedRoute, private toastrService: ToastrService) { }

   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;
   selectedBlanksSupplierId: string;
   blanksSupplier: BlanksSupplier;


   ngOnInit() {
      this.handleSubscriptions();
      this.blanksSupplier = new BlanksSupplier();
      this.getBlanksSupplier();
      this.evaluateAction();
      window.scrollTo(0, 0);
   }

   handleSubscriptions() {
      // Subscriptions
      this.userAccess = new UserAccess();
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });

      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
         this.currentLoggedInUser = currentLoggedInUser;
      });
   }


   onSubmit() {
      if (this.editable && !this.createAction) {
         this.updateBlanksSupplier();
      } else {
         this.createBlanksSupplier();
      }
   }


   getBlanksSupplier() {
      let blanksSupplierId = this.activatedRoute.snapshot.paramMap.get('id');
      this.selectedBlanksSupplierId = blanksSupplierId;
      if (blanksSupplierId) {
         this.vendorService.getBlanksSupplierById(blanksSupplierId).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.blanksSupplier = response.responsePayload;
            } else {
               this.toastrService.error(response.responseStatus.message);
            }
         });
      }
   }


   createBlanksSupplier() {
      this.vendorService.createBlanksSupplier(this.blanksSupplier).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.router.navigate(['blanksSupplier/list']);
         } else {
            console.error("BlanksSupplier Component - create blanksSupplier failed...", response.responseStatus.message);

            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }

   updateBlanksSupplier() {
      this.vendorService.updateBlanksSupplier(this.blanksSupplier).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.getBlanksSupplier();
            this.editable = false;
            this.viewAction = true;
            //this.router.navigate(['blanksSupplier/list']);
         } else {
            console.error("BlanksSupplier Component - update BlanksSupplier failed...", response.responseStatus.message);
            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }

   evaluateAction() {
      const activatedRoutePath = this.activatedRoute.snapshot.routeConfig.path;
      if (this.activatedRoute.snapshot.paramMap.get('action') === "view") {
         this.viewAction = true;
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "create") {
         this.createAction = true;
         this.editable = true;
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "edit") {
         this.editable = true;
         this.createAction = false;
      }
   }

   
   goBack() {
      this.router.navigate(['blanksSupplier/list']);
   }


   edit(blanksSupplierId: string) {
      this.vendorService.getBlanksSupplierById(blanksSupplierId).subscribe(response => {
         this.blanksSupplier = response.responsePayload;
      });
      this.createAction = false;
      this.viewAction = false;
      this.editable = true;

   }

   cancelEdit() {
      this.getBlanksSupplier();
      this.editable = false;
      this.viewAction = true;
   }

}
