import { Product } from '../product.model';
import { SubOrderDetail } from './sub-order-detail.model';
import { Blanks } from '../blanks.model';

export class OrderDetail {
   id: string;
   parentId: string; // sales order Id
   productId: string;
   blanksId: string;
   trims: string;
   photoSampleRequired: boolean;
   photoSampleDeadlineDate: Date;
   ppSampleRequired: boolean;
   ppSampleDeadlineDate: Date;
   topSampleRequired: boolean;
   topSampleDeadlineDate: Date;
   swatchSampleRequired: boolean;
   swatchSampleDeadlineDate: Date;
   photoSampleArtApprovalRequired: boolean;
   ppSampleArtApprovalRequired: boolean;
   topSampleArtApprovalRequired: boolean;
   cost: number;
   retailCost: number;
   totalCost: number;
   totalRetailCost: number;
   notes: string;
   isRepeatOrder: boolean = false;
   isPnlRequired: boolean = false;
   overrideSizes: boolean = false;
   individualSizeCosts: boolean = false;
   product: Product;
   subOrderDetailList: SubOrderDetail[] = [];
   //blanks:Blanks = new Blanks;
   deleteFlag: boolean = false;
   status: string;
   totalOrderQuantity:number;
   totalSampleQuantity:number;
   specialFeatures:string;
   pnlSpeciality:string;
   pnlSpecialityRequired:boolean;
   pnlSpecialityType:string;
   trimsList: string[] = [];
   orderDetailSizeQuantityList: OrderDetailSizeQuantity[] = [];
   blanksQuality: string;
   blanksColour: string;
   licensorSampleStatus:string;
   brandRequiredTrims:string;
   brandRequiredTrimsList: string[] = [];
   internalNotes: string;
}

export class OrderDetailSizeQuantity {
   id: string;
   parentId: string;
   size:string;
   overrideSize:string;
   selected:boolean;
   quantity: number;
   sampleQuantity: number;
   cost: number;
   //transient
   activeSize:boolean;
}