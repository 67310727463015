<div class="m-2 d-flex justify-content-end p-2">
    <button class="btn btn-sm btn-success" type="button" *ngIf="createItemAuditErrors.length>0 && userAccess?.canRetryCreateItemSage" (click)="retryCreateItemAudit()">Retry</button>
 </div>
 <table class=" f-11 table table-sm table-pad table-bordered table-striped " style="width: 75% !important;">
    <thead class="thead-dark">
       <tr class="text-center">
          <!--<th>&nbsp;</th>-->
          <th>Sku</th>
          <th>Size</th>
          <th>Sage Sku Code</th>
          <th>Error Type</th>
          <th>Company</th>
          <th>Date/Time</th>
          <th>Error Description</th>
          
       </tr>
    </thead>
    <tbody>
       <tr class="text-center" *ngFor="let entry of createItemAuditErrors;index as ciaIndex;">
          <!--<td>
             <div class="d-flex justify-content-left" style="display: block; height:15px;" *ngIf="userAccess?.canChangeProductionStatus" >
                <label class="cb-container">&nbsp;
                   <input type="checkbox" [(ngModel)]="entry.selected" [checked]="entry.selected" name="entryAudit_{{ciaIndex
                   }}">
                   <span class="checkmark-sm"></span>
                </label>
             </div>
          </td>-->
          <td class="text-nowrap">{{entry.skuCode}}</td>
          <td class="text-nowrap">{{entry.size}}</td>
          <td class="text-nowrap">{{entry.sageSkuCode}}</td>
          <td>{{entry.status}}</td>
          <td>{{entry.companyCode}}</td>
          <td>{{entry.createdDate | date:'medium'}}</td>
          <td>{{entry.error}}</td>
          
       </tr>
    </tbody>
 </table>