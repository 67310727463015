import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { Observable } from "rxjs/index";
import { environment } from 'src/environments/environment';
import { Upc } from '../model/upc-detail.model';

@Injectable({
   providedIn: 'root'
})

export class UpcService {
   readonly baseUrl = environment.apiEndPoint;

   constructor(private http: HttpClient) { }

   getUpcDetailByProductId(productId: string): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/upc/list/productId/' + productId, null);
   }

   /*
   createUpc(upc: Upc, sku :string): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/upc/create/'+sku, upc);
   }
   */

   generateUpcCodes(selectedSizesToCreateUpcCode: Upc[], sku :string): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/upc/codes/generate/'+sku , selectedSizesToCreateUpcCode);
   }

   createUpcCodes(selectedSizesToCreateUpcCode: Upc[], sku :string): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/upc/codes/create/'+sku, selectedSizesToCreateUpcCode);
   }

   deleteUpc(upcId: string) {
      return this.http.post<ApiResponse>(this.baseUrl + '/upc/delete/' + upcId, null);
   }

   getUpcList(): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/upc/list', null);
   }

   updateUpc(upc: Upc): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/upc/update', upc);
   }
}
