<ng-container *ngIf="orderDetail">
   <div *ngIf="!orderDetail.deleteFlag" class="card mb-2" [style]="orderDetail.status==='CANCELLED'?'outline: none;border: 3px solid red;':''" [style.opacity]="orderDetail.status==='CANCELLED'?'0.6':'10'"><!-- [style.background-color]="orderDetailIndex%2===0?'white':'white'" [style.opacity]="orderDetail.status==='CANCELLED'?'0.3':'10'"-->
     
      <div ngModelGroup="orderDetailGroup_{{orderDetailIndex}}" #orderDetailCtrl="ngModelGroup">
         <div class="col-sm-12 card-header d-flex justify-content-between  bg-color-{{approvalStatusColor(orderDetail.product.approvalStatus)}}"  style="cursor: pointer; padding: .45rem 1.25rem !important;">
            <div style="width: 10%;">
               <span class="ml-0 mr-3" data-toggle="collapse" id="expandOrderDetail_{{orderDetailIndex}}" [attr.data-target]="'#orderDetail_'+orderDetailIndex">
                  <i class="fas fa-expand-alt fa-1x"></i>
               </span>
               <span class="mx-3" *ngIf="editable" matTooltip="Clone" matTooltipPosition="below" matTooltipClass="bg-dark">
                  <a style="border: 0px;" (click)="cloneOrderDetail(orderDetail)" style="border-width:0rem;" >
                     <i class="fas fa-copy fa-1x"></i></a>
               </span>
               <span class="mx-3" *ngIf="editable" >
                     <a style="border: 0px;" *ngIf="!salesOrder.orderInitialSubmitDate || salesOrder.orderInitialSubmitDate===null" (click)="deleteOrderDetail(orderDetail)" style="border-width:0rem;" matTooltip="Delete Order Detail" matTooltipPosition="below" matTooltipClass="bg-dark" >
                        <i class="fas fa-trash fa-1x"></i></a>
                        <a style="border: 0px;" class="mr-2" *ngIf="userAccess?.isSupportAdmin" (click)="deleteOrderDetail(orderDetail)" style="border-width:0rem;" matTooltip="Delete Order Detail" matTooltipPosition="below" matTooltipClass="bg-dark" >
                           <i class="fas fa-trash fa-1x"></i></a>
                     
                     <a style="border: 0px;" *ngIf="salesOrder.orderInitialSubmitDate && salesOrder.orderInitialSubmitDate!==null" (click)="cancelOrderDetail(orderDetail)" matTooltip="Cancel Order Detail" matTooltipPosition="below" matTooltipClass="bg-dark">
                        <i class="far fa-window-close fa-1x"></i></a>
               </span>
            </div>
            <div style="width: 80%;" class="text-center">
               <span data-toggle="collapse" [attr.data-target]="'#orderDetail_'+orderDetailIndex">
                     <h6>{{orderDetail.status==='CANCELLED'?'Cancelled ':''}}{{orderDetail.product.sku}} - {{orderDetail.product.approvalStatus}}
                        <span style="font-weight: 700;color:red;">{{isLicensorPreProApprovalMessageRequired(orderDetail.product)?' (Licensor Pre Production Approval Required)':''}}</span>
                     </h6>
                     
                  
               </span>
            </div>
            <div style="width: 10%;" data-toggle="collapse" [attr.data-target]="'#orderDetail_'+orderDetailIndex">&nbsp;</div>
            
         </div>

         <!-- ########## START: DIV FOR ORDER DETAIL ##########-->
         <div id="orderDetail_{{orderDetailIndex}}" class="collapse show card-body" style="padding: .25rem;" >
            <div style="position: absolute;" *ngIf="orderDetail.status==='CANCELLED'">
               <img src="assets/cancelledBanner.png" 
                      class="" width="auto" height="100">
            </div> 
            <table class="table-sm bordered" width="100%">
               <tbody>

                  <tr>
                     <td rowspan="999" style="vertical-align: middle;width: 240px;" class="align-center">
                        <a type="button" style="color: black;" matTooltip="View product" matTooltipPosition="below" matTooltipClass="bg-dark" target="_blank"
                           [routerLink]="['/product/view/'+orderDetail.product.licensorId+'/'+orderDetail.product.brandId+'/'+orderDetail.product.id]">
                        <app-image-with-preview [defaultHeight]="'auto'" [defaultWidth]="'230px'" [disablePreview]="true" [attachment]="orderDetail.product.productImages[0]">
                        </app-image-with-preview>
                        </a>           
                                          
                     </td>
                     <td>
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">SKU</label>
                        <div class="d-flex">
                           <input name="orderDetail_product_sku_{{orderDetailIndex}}" class="form-control form-control-sm" required style="font-size: 11px;" 
                              [ngModel]="orderDetail.product.sku" [readOnly]="true"
                              [ngClass]="{'is-invalid':skuVar.touched && skuVar.invalid}" #skuVar>
                           <button type="button" class="btn btn-sm" *ngIf="createAction || (editable && salesOrder.status==='DRAFT')" (click)="changeProductSku(orderDetail)"><i class="fas fa-pencil-alt"></i></button>
                        </div>
                     </td>
                     <td>
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Description</label>
                        <input name="orderDetail_product_description_{{orderDetailIndex}}" class="form-control form-control-sm f-11" #descriptionVar required [disabled]="true"
                           [ngModel]="orderDetail.product.description" style="font-size: 11px;" [ngClass]="{'is-invalid':descriptionVar.touched && descriptionVar.errors}" maxlength="40">
                     </td>
                     <td>
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Color</label>
                        <input name="color_{{orderDetailIndex}}" class="form-control form-control-sm f-11" #colorVar required [disabled]="true" placeholder="Color"
                           [ngModel]="orderDetail.product.color" style="font-size: 11px;" [ngClass]="{'is-invalid':colorVar.touched && colorVar.errors}" maxlength="40"
                           onfocus="this.placeholder = ''" onblur="this.placeholder = 'Color'">
                        <div *ngIf="colorVar?.invalid && (colorVar.dirty || colorVar.touched)" class="invalid-feedback">
                           <div *ngIf="colorVar.errors.required">
                              color is required.
                           </div>
                        </div>
                     </td>
                     <td>
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Body Type</label>
                        <input name="bodyType_{{orderDetailIndex}}" class="form-control form-control-sm f-11" #bodyTypeVar [readOnly]="true"
                           [ngModel]="orderDetail.product.category" style="font-size: 11px;" [disabled]="!editable" [ngClass]="{'is-invalid':bodyTypeVar.touched && bodyTypeVar.errors}"
                           maxlength="40">
                     </td>
                     <td>
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Garment Style</label>
                        <input name="garmentStyle_{{orderDetailIndex}}" class="form-control form-control-sm f-11" #garmentStyleVar [readOnly]="true" [ngModel]="orderDetail.product.garment"
                           style="font-size: 11px;" [ngClass]="{'is-invalid':garmentStyleVar.touched && garmentStyleVar.errors}" maxlength="40">
                     </td>
                     <td>
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Print Effect</label>
                        <input name="printEffect_{{orderDetailIndex}}" class="form-control form-control-sm f-11" [readOnly]="true" [ngModel]="orderDetail.product.printEffect"
                           style="font-size: 11px;" [ngClass]="{'is-invalid':printEffectVar.touched && printEffectVar.invalid}" #printEffectVar>
                     </td>
                     <td>
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Treatment</label>
                        <input name="treatment_{{orderDetailIndex}}" class="form-control form-control-sm f-11" [readOnly]="true" [ngModel]="orderDetail.product.treatment"
                           style="font-size: 11px;" [ngClass]="{'is-invalid':printEffectVar.touched && printEffectVar.invalid}" #treatmentVar>
                     </td>
                  </tr>

                  <tr>
                     <td>
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Licensor</label>
                        <input name="licensorName_{{orderDetailIndex}}" class="form-control form-control-sm f-11" [readOnly]="true" [ngModel]="orderDetail.product.licensorName"
                           style="font-size: 11px;">
                     </td>
                     <td>
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Brand</label>
                        <input name="brandName_{{orderDetailIndex}}" class="form-control form-control-sm f-11" [readOnly]="true" [ngModel]="orderDetail.product.brandName"
                           style="font-size: 11px;">
                     </td>
                     <td>
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;"># of Screens</label>
                        <input name="screenCount_{{orderDetailIndex}}" class="form-control form-control-sm f-11" [readOnly]="true" [ngModel]="orderDetail.product.screenCount"
                           style="font-size: 11px;">
                     </td>
                  </tr>

                  <tr>
                     <td colspan="5">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;align-self: center;">Trims</label>
                        <div class="d-flex flex-wrap border p-1" style="border-radius: .2rem !important;">
                           <ng-container *ngFor="let trim of trimsListFromMetadata; index as trimsIndex">
                              <div class="form-check form-check-inline">
                                 <input class="form-check-input" type="checkbox" id="trims_{{trimsIndex}}_{{orderDetailIndex}}" name="trims_{{trimsIndex}}_{{orderDetailIndex}}"
                                    [checked]="doesOrderDetailContainsTrim(orderDetail,trim)" [disabled]="!editable" (click)="onTrimSelection($event,trim,orderDetail)">
                                 <label class="form-check-label f-10" style="color:black" for="trims_{{trimsIndex}}_{{orderDetailIndex}}">{{trim}}</label>
                              </div>
                           </ng-container>
                           <ng-container *ngFor="let trim of getAddlTrimsList(orderDetail); index as trimsIndex">
                              <div class="form-check form-check-inline">
                                 <input class="form-check-input" type="checkbox" id="trims_{{trimsIndex}}_{{orderDetailIndex}}" name="trims_{{trimsIndex}}_{{orderDetailIndex}}"
                                    [checked]="doesOrderDetailContainsTrim(orderDetail,trim)" [disabled]="!editable" (click)="onTrimSelection($event,trim,orderDetail)">
                                 <label class="form-check-label f-10" style="color:black" for="trims_{{trimsIndex}}_{{orderDetailIndex}}">{{trim}}</label>
                              </div>
                           </ng-container>
                           <div class="d-flex flex-wrap" *ngIf="editable">
                              <div class="d-flex m-2">
                                 <input name="spltrims_details_{{orderDetailIndex}}" minlength="3" #splTrims class="form-control form-control-sm f-10" size="50" placeholder="Special Trims...."  required [disabled]="!editable">
                                 <button type="button" class="btn btn-sm btn-warning f-10" (click)="onAddlTrimEntered(splTrims.value,orderDetail);splTrims.value=null">+</button>
                              </div>
                           </div>
                           
                        </div>
                     </td>

                     <td colspan="2">
                        <ng-container *ngIf="orderDetail?.brandRequiredTrimsList && orderDetail?.brandRequiredTrimsList !==undefined && orderDetail?.brandRequiredTrimsList.length >0">
                           <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;align-self: center;">Trims Required by Brand</label>
                           <div class="d-flex d-inline border p-1" style="border-radius: .2rem !important; background: aqua;">
                              <ng-container *ngFor="let brandRequiredTrim of orderDetail?.brandRequiredTrimsList; index as trimsIndex">
                                 <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="brandRequiredTrim_{{trimsIndex}}_{{orderDetailIndex}}" name="brandRequiredTrim_{{trimsIndex}}_{{orderDetailIndex}}" [checked]="true"
                                       [disabled]="true">
                                    <label class="form-check-label f-10" style="color:black" for="brandRequiredTrim_{{trimsIndex}}_{{orderDetailIndex}}">{{brandRequiredTrim}}</label>
                                 </div>
                              </ng-container>
                           </div>
                        </ng-container>
                     </td>
                  </tr>

                  <tr>
                     <td colspan="5">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;align-self: center;">Sample Required / Deadlines</label>
                        <div class="d-flex d-inline border p-1">

                           <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" name="photoSample_{{orderDetailIndex}}" id="photoSample_{{orderDetailIndex}}"
                                 [(ngModel)]="orderDetail.photoSampleRequired" [disabled]="!editable">
                              <label class="form-check-label f-10" style="color:black" for="photoSample_{{orderDetailIndex}}">Photo Sample</label>
                              <div class="d-flex d-inline form-check-inline" *ngIf="orderDetail.photoSampleRequired">
                                 <input matInput [matDatepicker]="photoSampleDeadlinePicker" name="photoSampleDeadline_{{orderDetailIndex}}"
                                    [(ngModel)]="orderDetail.photoSampleDeadlineDate" (click)="photoSampleDeadlinePicker.open()" style="cursor: pointer;font-size: 11px; width:75px;"
                                    class="form-control form-control-sm ml-1" required [disabled]="!editable" #photoSampleDeadlineVar
                                    [ngClass]="{'is-invalid':photoSampleDeadlineVar.touched && photoSampleDeadlineVar.errors}">
                                 <mat-datepicker #photoSampleDeadlinePicker style="z-index: 1099;"></mat-datepicker>
                                 <button class="btn btn-sm" type="button" (click)="photoSampleDeadlinePicker.open()"><i class="fas fa-calendar-alt"></i></button>
                              </div>
                           </div>

                           <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" name="ppSample_{{orderDetailIndex}}" id="ppSample_{{orderDetailIndex}}"
                                 [(ngModel)]="orderDetail.ppSampleRequired" [disabled]="!editable" (ngModelChange)="resetSampleQty('PP_SAMPLE',orderDetail)"><!-- (ngModelChange)="resetSampleQty('PP_SAMPLE',orderDetail)"-->
                              <label class="form-check-label f-10" style="color:black" for="ppSample_{{orderDetailIndex}}">PP Sample</label>
                              <div class="d-flex d-inline form-check-inline" *ngIf="orderDetail.ppSampleRequired">
                                 <input matInput [matDatepicker]="ppSampleDeadlinePicker" name="ppSampleDeadline_{{orderDetailIndex}}" [(ngModel)]="orderDetail.ppSampleDeadlineDate"
                                    (click)="ppSampleDeadlinePicker.open()" style="cursor: pointer;font-size: 11px; width:75px;" class="form-control form-control-sm ml-1" required
                                    [disabled]="!editable" #ppSampleDeadlineVar [ngClass]="{'is-invalid':ppSampleDeadlineVar.touched && ppSampleDeadlineVar.errors}">
                                 <mat-datepicker #ppSampleDeadlinePicker style="z-index: 1099;"></mat-datepicker>
                                 <button class="btn btn-sm" type="button" (click)="ppSampleDeadlinePicker.open()"><i class="fas fa-calendar-alt"></i></button>
                              </div>
                           </div>

                           <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" name="topSample_{{orderDetailIndex}}" id="topSample_{{orderDetailIndex}}"
                                 [(ngModel)]="orderDetail.topSampleRequired" [disabled]="!editable" (ngModelChange)="resetSampleQty('TOP_SAMPLE',orderDetail)">
                              <label class="form-check-label f-10" style="color:black" for="topSample_{{orderDetailIndex}}">Top Sample</label>
                              <div class="d-flex d-inline form-check-inline" *ngIf="orderDetail.topSampleRequired">
                                 <input matInput [matDatepicker]="topSampleDeadlinePicker" name="topSampleDeadline_{{orderDetailIndex}}" [(ngModel)]="orderDetail.topSampleDeadlineDate"
                                    (click)="topSampleDeadlinePicker.open()" style="cursor: pointer;font-size: 11px; width:75px;" class="form-control form-control-sm ml-1" required
                                    [disabled]="!editable" #topSampleDeadlineVar [ngClass]="{'is-invalid':topSampleDeadlineVar.touched && topSampleDeadlineVar.errors}">
                                 <mat-datepicker #topSampleDeadlinePicker style="z-index: 1099;"></mat-datepicker>
                                 <button class="btn btn-sm" type="button" (click)="topSampleDeadlinePicker.open()"><i class="fas fa-calendar-alt"></i></button>
                              </div>
                           </div>

                           <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" name="swatchSample_{{orderDetailIndex}}" id="swatchSample_{{orderDetailIndex}}"
                                 [(ngModel)]="orderDetail.swatchSampleRequired" [disabled]="!editable">
                              <label class="form-check-label f-10" style="color:black" for="swatchSample_{{orderDetailIndex}}">Swatch Sample</label>
                              <div class="d-flex d-inline form-check-inline" *ngIf="orderDetail.swatchSampleRequired">
                                 <input matInput [matDatepicker]="swatchSampleDeadlinePicker" name="swatchSampleDeadline_{{orderDetailIndex}}" [(ngModel)]="orderDetail.swatchSampleDeadlineDate"
                                    (click)="swatchSampleDeadlinePicker.open()" style="cursor: pointer;font-size: 11px; width:75px;" class="form-control form-control-sm ml-1" required
                                    [disabled]="!editable" #swatchSampleDeadlineVar [ngClass]="{'is-invalid':swatchSampleDeadlineVar.touched && swatchSampleDeadlineVar.errors}">
                                 <mat-datepicker #swatchSampleDeadlinePicker style="z-index: 1099;"></mat-datepicker>
                                 <button class="btn btn-sm" type="button" (click)="swatchSampleDeadlinePicker.open()"><i class="fas fa-calendar-alt"></i></button>
                              </div>
                           </div>

                        </div>
                     </td>

                     <td colspan="2">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;align-self: center;">Art Approval Required?</label>
                        <div class="d-flex d-inline border p-1" style="border-radius: .2rem !important;">
                           <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" name="photoSample_artApproval_{{orderDetailIndex}}" id="photoSample_artApproval_{{orderDetailIndex}}"
                                 [(ngModel)]="orderDetail.photoSampleArtApprovalRequired" [disabled]="!editable">
                              <label class="form-check-label f-10" style="color:black" for="photoSample_artApproval_{{orderDetailIndex}}">Photo Sample</label>
                           </div>
                           <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" name="ppSample_artApproval_{{orderDetailIndex}}" id="ppSample_artApproval_{{orderDetailIndex}}"
                                 [(ngModel)]="orderDetail.ppSampleArtApprovalRequired" [disabled]="!editable">
                              <label class="form-check-label f-10" style="color:black" for="ppSample_artApproval_{{orderDetailIndex}}">PP Sample</label>
                           </div>
                           <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" name="topSample_artApproval_{{orderDetailIndex}}" id="topSample_artApproval_{{orderDetailIndex}}"
                                 [(ngModel)]="orderDetail.topSampleArtApprovalRequired" [disabled]="!editable">
                              <label class="form-check-label f-10" style="color:black" for="topSample_artApproval_{{orderDetailIndex}}">Top Sample</label>
                           </div>
                        </div>
                     </td>
                  </tr>

                  <!-- START: Total Cost, Total Retail Cost Notes etc.-->
                  <tr>
                     <td>
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Cost ({{(salesOrder?.currency)}})</label>
                        <input type="number" name="cost_{{orderDetailIndex}}" class="form-control form-control-sm" #costVar required style="text-align: right; font-size: 11px;"
                           [disabled]="!editable || (editable && orderDetail.individualSizeCosts)" [(ngModel)]="orderDetail.cost"
                           [ngClass]="{'is-invalid':costVar.touched && costVar.invalid}" (change)="onOrderDetailSingleCostChange(salesOrder, orderDetail)">
                     </td>
                     <td>
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Retail ({{(salesOrder?.currency)}})</label>
                        <input type="number" name="retailCost_{{orderDetailIndex}}" class="form-control form-control-sm" #retailCostVar required style="text-align: right; font-size: 11px;"
                           [disabled]="!editable" [(ngModel)]="orderDetail.retailCost" [ngClass]="{'is-invalid':retailCostVar.touched && retailCostVar.errors}"
                           (change)="onOrderDetailSingleRetailCostChange(salesOrder,orderDetail)">
                     </td>
                     <td>
                        <label class="mb-0 ml-1" style="font-size: .60rem; color:lightslategray;">Total Cost ({{(salesOrder?.currency)}})</label>
                        <input type="number" name="totalCost_{{orderDetailIndex}}" class="form-control form-control-sm" #totalCostVar style="text-align: right; font-size: 11px;"
                           [(ngModel)]="orderDetail.totalCost" [disabled]="true">
                     </td>
                     <td>
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Total Retail ({{(salesOrder?.currency)}})</label>
                        <input type="number" name="totalRetail_{{orderDetailIndex}}" class="form-control form-control-sm" #totalRetailCostVar [disabled]="true"
                           style="text-align: right; font-size: 11px;" [(ngModel)]="orderDetail.totalRetailCost">
                     </td>
                     <td colspan="3">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Notes</label>
                        <input name="orderDetail_notes_{{orderDetailIndex}}" class="form-control form-control-sm" #notesVar style="font-size: 11px;" [(ngModel)]="orderDetail.notes"
                           [disabled]="!editable" maxlength="250">
                     </td>
                  </tr>
                  <!-- END: Total Cost, Total Retail Cost, Notes etc.-->


                  <!-- START: Is Repeat Order, Override Size etc.-->
                  <tr>
                     <td colspan="1">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;align-self: center;">&nbsp;</label>
                        <div class="d-flex d-inline border p-1">
                           <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="repeatOrder_{{orderDetailIndex}}" name="repeatOrder_{{orderDetailIndex}}"
                                 [(ngModel)]="orderDetail.isRepeatOrder" [disabled]="!editable">
                              <label class="form-check-label f-10" style="color:black" for="repeatOrder_{{orderDetailIndex}}">Is Repeat Order?</label>
                           </div>
                        </div>
                     </td>
                     <td colspan="1">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;align-self: center;">&nbsp;</label>
                        <div class="d-flex d-inline border p-1">
                           <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="pnlRequired_{{orderDetailIndex}}" name="pnlRequired_{{orderDetailIndex}}"
                                 [(ngModel)]="orderDetail.isPnlRequired" [disabled]="!editable">
                              <label class="form-check-label f-10" style="color:black" for="pnlRequired_{{orderDetailIndex}}">PNL Required?</label>
                           </div>
                        </div>
                     </td>
                     <td colspan="1">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;align-self: center;">&nbsp;</label>
                        <div class="d-flex d-inline border p-1">
                           <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="overrideSizes_{{orderDetailIndex}}" name="overrideSizes_{{orderDetailIndex}}"
                                 [(ngModel)]="orderDetail.overrideSizes" [disabled]="!editable" [checked]="orderDetail.overrideSizes"
                                 (click)="onChangeSizeOverrideCheckBox($event,orderDetail)">
                              <label class="form-check-label f-10" style="color:black" for="overrideSizes_{{orderDetailIndex}}">Override Sizes?</label>
                           </div>
                        </div>
                     </td>
                     <td colspan="1" *ngIf="salesOrder.orderType !== 'Estimation'">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;align-self: center;">&nbsp;</label>
                        <div class="d-flex d-inline border p-1">
                           <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="individualSizeCosts_{{orderDetailIndex}}" name="individualSizeCosts_{{orderDetailIndex}}"
                                 [(ngModel)]="orderDetail.individualSizeCosts" [disabled]="!editable" [checked]="orderDetail.individualSizeCosts"
                                 (click)="onChangeIndividualCostCheckBox($event,orderDetail)">
                              <label class="form-check-label f-10" style="color:black" for="individualSizeCosts_{{orderDetailIndex}}">Individual Cost</label>
                           </div>
                        </div>
                     </td>
                     <td colspan="3">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;align-self: center;">Internal Notes</label>
                        <textarea rows="2" type="text" name="internalNotes_{{orderDetailIndex}}" class="form-control form-control-sm f-11" style="font-size:11px; margin-bottom: 0.25rem;"
                           [disabled]="!editable" [(ngModel)]="orderDetail.internalNotes" maxlength="254"></textarea>
                     </td>
                     </tr>
                  <tr>
                     <td colspan="1">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;align-self: center;">&nbsp;</label>
                        <div class="d-flex d-inline border p-1">
                           <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="pnlSpecialityRequired_{{orderDetailIndex}}" name="pnlSpecialityRequired_{{orderDetailIndex}}"
                                 [(ngModel)]="orderDetail.pnlSpecialityRequired" [disabled]="!editable" [checked]="orderDetail.pnlSpecialityRequired"
                                 (ngModelChange)="resetPnlSpecialityRequired(orderDetail)">
                              <label class="form-check-label f-10" style="color:black" for="pnlSpecialityRequired_{{orderDetailIndex}}">PNL Speciality</label>
                           </div>
                        </div>
                     </td>
                     <td *ngIf="orderDetail.pnlSpecialityRequired">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;align-self: center;">PNL Speciality Type</label>
                        <select class="form-control form-control-sm f-11" name="pnlSpecialityType_{{orderDetailIndex}}" [disabled]="!editable"
                           [(ngModel)]="orderDetail.pnlSpecialityType" style="font-size:11px; padding-left: 0.2rem; padding-right: 0.2rem; margin-bottom: 0.25rem;" required>
                           <!--<option value="PHILMXPNL.0">Philcos Logo</option>-->
                           <ng-container *ngIf="brandPnlSpecialities && brandPnlSpecialities.length>0">
                              <ng-container *ngFor="let brandSpeciality of brandPnlSpecialities">
                                 <option [value]="brandSpeciality.displayName" [disabled]="!brandSpeciality.status" [class]="!brandSpeciality.status?'text-muted':''">{{brandSpeciality.displayName}}{{!brandSpeciality.status?' (Inactive)':''}}</option>
                              </ng-container>
                              
                           </ng-container>
                           <!--<option value="ACDMXPNL.PHIL">ACDC with Philcos Logo</option>
                           <option value="PKFMXPNL.PHIL">PKF with Philcos Logo</option>
                           <option value="DAEPKPNL.PHIL.0">DAE with Philcos Logo</option>
                           <option value="GOBMXPNL.PHIL">GOB with Philcos Logo</option>-->
                           <option value="Buyer Provided PNL">Buyer Provided PNL</option>
                           <option value="Request Custom PNL">Request Custom PNL</option>

                        </select>
                     </td>
                     <td colspan="3" *ngIf="orderDetail.pnlSpecialityRequired && orderDetail.pnlSpecialityType==='Request Custom PNL'">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;align-self: center;">PNL Speciality</label>
                        <textarea rows="2" type="text" name="pnlSpeciality_{{orderDetailIndex}}" class="form-control form-control-sm f-11" style="font-size:11px; margin-bottom: 0.25rem;"
                           [disabled]="!editable" [(ngModel)]="orderDetail.pnlSpeciality" maxlength="254"></textarea>
                     </td>
                     
                  </tr>
                  <!-- END: Is Repeat Order, Override Size etc.-->
               </tbody>
            </table>

            <!-- ########## START: DIV FOR SUB ORDER DETAIL ##########-->
            <div class="border p-1 overflow-auto" style="border-radius: .2rem !important;">
               <table class="table-sm">
                  <thead>
                     <tr style="background-color:#7788999e;font-weight: 400;" class="text-white">
                        <th width="2%" class="f-12 fw-400 p-0" style="text-align: center;">
                           <a style="border: 0px;" class="btn form-control form-control-sm" style="border-width:0rem;" (click)="addNewSubOrderDetailForSales(orderDetail)" *ngIf="editable && false">
                              <i class="fas fa-plus fa-lg text-white"></i></a>
                        </th>
                        <th width="8%" class="f-12 fw-400" style="text-align: center;">Customer PO#</th>
                        <th *ngIf="showSubOrderDetailPoCustomer" width="8%" class="f-12 fw-400" style="text-align: center;">Customer</th>
                        <th width="8%" class="f-12 fw-400" style="text-align: center;">PO Type</th>
                        
                        <th width="9%" class="f-12 fw-400" style="text-align: center;">&nbsp;</th>
                        <th [width]="orderDetail.orderDetailSizeQuantityList?.length*4.5+'%'" class="f-12 fw-400" style="text-align: center;">
                           <div class="d-flex d-inline p-1" style="justify-content: center;">
                              <div class="form-check form-check-inline"> Sizes & Quantities &nbsp;&nbsp;
                                 <button class="btn btn-sm bg-dark text-white f-10" id="selectSizes_{{orderDetailIndex}}" name="selectSizes_{{orderDetailIndex}}" [disabled]="!editable" *ngIf="editable"
                                    type="button" (click)="onClickSelectSizes(orderDetail,orderDetail.id)">Size(s)</button>
                              </div>
                           </div>
                        </th>
                        <th width="5%" class="f-12 fw-400" style="text-align: center;">Total</th>
                        <th width="15%" class="f-12 fw-400" style="text-align: center;">Packaging</th>
                        <th width="auto" class="f-12 fw-400" style="text-align: center;">Notes</th>
                     </tr>
                  </thead>

                  <tbody>

                     <!-- START:  Customer Size Row stored at Order Detail Level - Coming from orderDetail.orderDetailSizeQuantityList -->
                     <tr class="mt-2">
                        <td [attr.colspan]="3+colspanIncrementCount"> &nbsp;</td>
                        <td style="text-align: center;vertical-align: bottom;">
                           <label class="f-11 font-weight-bold ">Customer Size</label>
                        </td>
                        <td>
                           <table class="table-sm overflow-auto">
                              <tr>
                                 <ng-container *ngFor="let orderDetailSizeQuantity of orderDetail.orderDetailSizeQuantityList; index as orderDetailSizeQuantityIndex">
                                    <th class="f-10" style="text-align: center;">
                                       <input type="text" name="size_{{orderDetailSizeQuantity.size}}_{{orderDetailIndex}}_{{orderDetailSizeQuantityIndex}}"
                                          class="border-0 font-weight-bold form-control cell-min form-control-sm f-11" style="text-align: center; font-size:11px; background-color: white;"
                                          readonly [(ngModel)]="orderDetailSizeQuantity.size">
                                    </th>
                                 </ng-container>
                              </tr>
                           </table>
                        </td>
                        <td colspan="3">&nbsp;</td>
                     </tr>
                     <!-- End:  Customer Size Row stored at Order Detail Level - Coming from orderDetail.orderDetailSizeQuantityList -->


                     <!-- START:  Invididual Cost Row stored at Order Detail Level - Coming from orderDetail.orderDetailSizeQuantityList -->
                     <tr class="mt-2" *ngIf="orderDetail.individualSizeCosts" style="background: antiquewhite;">
                        <td [attr.colspan]="3+colspanIncrementCount">&nbsp;</td>
                        <td style="text-align: center;vertical-align: bottom;">
                           <label class="f-11 font-weight-bold">Cost/Size ({{salesOrder.currency}})</label>
                        </td>
                        <td>
                           <table class="table-sm overflow-auto">
                              <tr>
                                 <ng-container *ngFor="let orderDetailSizeQuantity of orderDetail.orderDetailSizeQuantityList; index as orderDetailSizeQuantityIndex">
                                    <td>
                                       <input type="number" name="individualCost_{{orderDetailIndex}}_{{orderDetail.orderDetailSizeQuantityList[orderDetailSizeQuantityIndex].size}}"
                                          class="form-control cell-min form-control-sm f-11" style="text-align: right; font-size:11px;" [disabled]="!editable"
                                          (change)="onOrderDetailIndividualCostChange(salesOrder,orderDetail)" required [(ngModel)]="orderDetailSizeQuantity.cost">
                                    </td>
                                 </ng-container>
                              </tr>
                           </table>
                        </td>
                        <td colspan="3">&nbsp;</td>
                     </tr>
                     <!-- END:  Invididual Cost Row stored at Order Detail Level - Coming from orderDetail.orderDetailSizeQuantityList -->


                     <!-- START:  Override Size Row stored at Order Detail Level - Coming from orderDetail.orderDetailSizeQuantityList -->
                     <tr *ngIf="orderDetail.overrideSizes" style="background: bisque;">
                        <td [attr.colspan]="3+colspanIncrementCount">&nbsp;</td>
                        <td style="text-align: center;vertical-align: bottom;">
                           <label class="f-11 font-weight-bold">Blank's Size</label></td>
                        <td>
                           <table class="table-sm overflow-auto">
                              <tr>
                                 <ng-container *ngFor="let orderDetailSizeQuantity of orderDetail.orderDetailSizeQuantityList; index as orderDetailSizeQuantityIndex">
                                    <td class="f-10" style="text-align: center;">
                                       <select class="form-control cell-min form-control-sm" style="font-size:11px;" [(ngModel)]="orderDetailSizeQuantity.overrideSize"
                                          (change)="onDropdownChangeSizeOverride(orderDetail,orderDetailSizeQuantity)"
                                          name="blankSize__{{orderDetailIndex}}_{{orderDetail.orderDetailSizeQuantityList[orderDetailSizeQuantityIndex].size}}" #sizeOverrideOrderDetail [disabled]="!editable" required>
                                          <option *ngFor="let sizeFromMetadata of sizesMetadataList" [ngValue]="sizeFromMetadata.size" [disabled]="!sizeFromMetadata.status">
                                             {{sizeFromMetadata.size}}</option>
                                       </select>
                                    </td>
                                 </ng-container>
                              </tr>
                           </table>
                        </td>
                        <td colspan="3"></td>
                     </tr>
                     <!-- END:  Override Size Row stored at Order Detail Level - Coming from orderDetail.orderDetailSizeQuantityList -->


                     <!-- START:  Sub Order Details list and Size Order Details - Rows for SALES Order Quatity -->
                     <ng-container *ngFor="let subOrderDetail of orderDetail.subOrderDetailList; index as subOrderDetailIndex">
                        <tr *ngIf="!subOrderDetail.deleteFlag && subOrderDetail.type ==='SALES'" [style.opacity]="subOrderDetail.status==='CANCELLED'?'0.4':'10'">
                           <td style="text-align: center; vertical-align: bottom;" class="p-0 mb-2">
                              <div class="d-flex d-inline mb-2">
                                 <a style="border: 0px;" class="ml-2 mr-1" (click)="deleteSubOrderDetail(orderDetail, subOrderDetail)"
                                    *ngIf="editable && salesOrder.orderType !== 'Estimation' && subOrderDetail.type!=='PP_SAMPLE' && subOrderDetail.type!=='TOP_SAMPLE' && subOrderDetail.type!=='LICENSOR_SAMPLE'">
                                    <i class="fas fa-trash fa-1x" style="color: #dc3545;cursor: pointer;"></i></a>
                                 <!--
                                 <a style="border: 0px;" class="ml-2 mr-1" (click)="cancelSubOrderDetail(orderDetail, subOrderDetail)"
                                    *ngIf="(!editable && salesOrder.status==='SUBMITTED' && subOrderDetail.status !=='CANCELLED')">
                                    <i class="fas fa-ban fa-1x" style="color: grey;cursor: pointer;"></i></a>
                                 -->
                              </div>
                           </td>
                           <td style="vertical-align: bottom;">
                              <input type="text" name="customerPO_{{orderDetailIndex}}_{{subOrderDetail.sortOrder}}" class="form-control form-control-sm f-11"
                                 style="font-size:11px; padding-left: 0.2rem; padding-right: 0.2rem; margin-bottom: 0.25rem;" required [disabled]="!editable || (editable && salesOrder.singlePO)"
                                 [(ngModel)]="subOrderDetail.customerPONumber"
                                 *ngIf="subOrderDetail.type!=='PP_SAMPLE' && subOrderDetail.type!=='TOP_SAMPLE' && subOrderDetail.type!=='LICENSOR_SAMPLE'">
                           </td>

                           <td *ngIf="showSubOrderDetailPoCustomer">
                              <select class="form-control form-control-sm f-11" [(ngModel)]="subOrderDetail.poCustomerId" id="poCustomer_{{orderDetailIndex}}_{{subOrderDetail.sortOrder}}"
                                 name="poCustomer_{{orderDetailIndex}}_{{subOrderDetail.sortOrder}}" #poCustomerVar="ngModel" required  [disabled]="!editable"
                                 [ngClass]="{'is-invalid':poCustomerVar.touched && poCustomerVar.errors}"
                                 style="font-size:11px; padding-left: 0.2rem; padding-right: 0.2rem; margin-bottom: 0.25rem;">
                                 <option *ngFor="let poCustomer of poCustomerList" [ngValue]="poCustomer.id">
                                    {{poCustomer.customerName}}</option>
                              </select>
                           </td>

                           <td>
                              <select class="form-control form-control-sm f-11" name="poType_{{orderDetailIndex}}_{{subOrderDetail.sortOrder}}" #poTypeVar [disabled]="!editable"
                                 [(ngModel)]="subOrderDetail.customerPoType" style="font-size:11px; padding-left: 0.2rem; padding-right: 0.2rem; margin-bottom: 0.25rem;">
                                 <option *ngFor="let customerPOType of customerPoTypeListFromMetadata" [ngValue]="customerPOType">
                                    {{customerPOType}}</option>
                              </select>
                           </td>

                           <td style="text-align: center;vertical-align: bottom;">
                              <label *ngIf="subOrderDetail.type==='SALES'" class="f-10">Order Quantity</label>
                           </td>

                           <td>
                              <table class="table-sm overflow-auto">
                                 <tr>
                                    <ng-container *ngFor="let sizeOrderDetail of subOrderDetail.sizeOrderDetailList; index as sizeOrderDetailIndex">
                                       <td>
                                          <input type="number" (change)="onChangeSubOrderDetailQuantity(salesOrder,orderDetail, subOrderDetail)"
                                             name="{{orderDetailIndex}}_{{sizeOrderDetail.size}}_{{subOrderDetail.sortOrder}}_{{sizeOrderDetailIndex}}" required
                                             [readonly]="(editable && subOrderDetail.packagingType ==='Prepack')" class="form-control form-control-sm f-11 cell-min"
                                             style="text-align: right; font-size:11px" [disabled]="!editable " [(ngModel)]="sizeOrderDetail.quantity">
                                       </td>
                                    </ng-container>
                                 </tr>
                              </table>
                           </td>

                           <td>
                              <input type="number" min="1" name="totalQty_{{orderDetailIndex}}_{{subOrderDetail.sortOrder}}"
                                 class="form-control font-weight-bold cell-min form-control-sm f-12" [disabled]="true" style="text-align: right; font-size:11px;"
                                 [(ngModel)]="subOrderDetail.totalSubOrderQuantity">
                           </td>
                           <td style="vertical-align: bottom;">
                              <div class="d-flex d-inline">
                                 <select class="form-control form-control-sm" style="font-size:11px; margin-bottom: 0.25rem;" required
                                    name="packagingType_{{orderDetailIndex}}_{{subOrderDetail.sortOrder}}" #packagingType [disabled]="!editable" 
                                    [ngModel]="subOrderDetail.packagingType"
                                    (ngModelChange)="onPackagingTypeChangeOrPrepackClick(orderDetail,orderDetailIndex, subOrderDetail,subOrderDetailIndex, subOrderDetail.packagingType,$event)">
                                    <option *ngFor="let packaging of packagingTypeListFromMetadata" [ngValue]="packaging">
                                       {{packaging}}</option>
                                 </select>
                                 
                                    <input type="text" name="prePackDetails_{{orderDetailIndex}}_{{subOrderDetail.sortOrder}}" [readonly]="true"
                                       [(ngModel)]="subOrderDetail.prepackDetail"
                                       *ngIf="subOrderDetail.packagingType === 'Prepack'" class="form-control form-control-sm f-11 ml-1 px-1" style="font-size:11px; margin-bottom: 0.25rem;">
                                    <button type="button" class="btn btn-sm px-1" *ngIf="editable && subOrderDetail.packagingType === 'Prepack'" (click)="onPackagingTypeChangeOrPrepackClick(orderDetail,orderDetailIndex, subOrderDetail,subOrderDetailIndex, subOrderDetail.packagingType,'Prepack')"><i class="fas fa-pencil-alt"></i></button>
                                 
                              </div>
                           </td>
                           <td style="vertical-align: bottom;">
                              <input type="text" name="notes_{{orderDetailIndex}}_{{subOrderDetail.sortOrder}}" class="form-control form-control-sm f-11"
                                 style="font-size:11px; margin-bottom: 0.25rem;" [disabled]="!editable" [(ngModel)]="subOrderDetail.notes" maxlength="250">
                           </td>
                        </tr>
                     </ng-container>

                     <tr *ngIf="editable && salesOrder.orderType !== 'Estimation'" >
                        <td [attr.colspan]="3+colspanIncrementCount">&nbsp;</td>
                        <td colspan="1">
                           <button type="button" class=" f-10 btn btn-sm  btn-block btn-info" (click)="addNewSubOrderDetailForSales(orderDetail)">Add Sub Order</button>
                        </td>
                     </tr>
                     <!-- END:  Sub Order Details list and Size Order Details - Rows for SALES Order Quatity -->


                     <!-- START: TOTAL SALES ORDER From Order Detail Size Quantity at the Order Detail Level -->
                  <ng-container *ngIf="salesOrder.orderType !== 'Estimation'">
                     <tr class="mt-2"  style="background-color: aliceblue;">
                        <td [attr.colspan]="3+colspanIncrementCount">&nbsp;</td>
                        <td style="text-align: center;vertical-align: bottom;">
                           <label class="f-11 font-weight-bold">TOTAL ORDER</label>
                        </td>
                        <td>
                           <table>
                              <tr>
                                 <ng-container *ngFor="let orderDetailSizeQuantity of orderDetail.orderDetailSizeQuantityList; index as orderDetailSizeQuantityIndex">
                                    <td>
                                       <input type="number" name="SALES_{{orderDetailSizeQuantity.size}}_{{orderDetailIndex}}_{{orderDetailSizeQuantityIndex}}"
                                          class="form-control cell-min form-control-sm f-12 font-weight-bold" style="text-align: right; font-size:11px; " required readonly
                                          [disabled]="!editable" [(ngModel)]="orderDetailSizeQuantity.quantity">
                                    </td>
                                 </ng-container>
                              </tr>
                           </table>
                        </td>
                        <td>
                           <input type="number" name="SALES_totalOrderQuantity_{{orderDetail.totalOrderQuantity}}_{{orderDetailIndex}}"
                              class="form-control cell-min form-control-sm f-12 font-weight-bold" style="text-align: right; font-size:11px;" [disabled]="true"
                              [(ngModel)]="orderDetail.totalOrderQuantity">
                        </td>
                        <td colspan="2">&nbsp;</td>
                     </tr>
                     <!-- END: TOTAL SALES ORDER ROW From Order Detail Size Quantity at the Order Detail Level  -->

                     <tr>
                        <td [attr.colspan]="8+colspanIncrementCount">
                           <hr class="mt-1 mb-1">
                        </td>
                     </tr>
                  </ng-container>

                     <!-- START:  Sub Order Details list and Size Order Details - Rows for PHOTO, PP and TOP Sample -->
                  <ng-container *ngIf="salesOrder.orderType !== 'Estimation'">
                     <ng-container *ngFor="let subOrderDetail of orderDetail.subOrderDetailList; index as subOrderDetailIndex">
                        <tr *ngIf="!subOrderDetail.deleteFlag 
                                    && subOrderDetail.category==='SAMPLE'
                                    " [style.opacity]="subOrderDetail.status==='CANCELLED'?'0.4':'10'">
                                    <!--&& (
                                       (subOrderDetail.type==='PP_SAMPLE' && orderDetail.ppSampleRequired)
                                       ||(subOrderDetail.type==='TOP_SAMPLE' && orderDetail.topSampleRequired)
                                       ||(subOrderDetail.type==='LICENSOR_SAMPLE' && subOrderDetail.totalSubOrderQuantity>0)
                                    )-->
                           <td style="text-align: center; vertical-align: bottom;" class="p-0 mb-2">
                              <div class="d-flex d-inline mb-2">
                                 <a style="border: 0px;" class="ml-2 mr-1" (click)="deleteSubOrderDetail(orderDetail, subOrderDetail)"
                                    *ngIf="editable && subOrderDetail.type!=='PP_SAMPLE' && subOrderDetail.type!=='TOP_SAMPLE' && subOrderDetail.type!=='LICENSOR_SAMPLE'">
                                    <i class="fas fa-trash fa-1x" style="color: #dc3545;cursor: pointer;"></i></a>
                                 <!--<a style="border: 0px;" class="ml-2 mr-1" (click)="cancelSubOrderDetail(orderDetail, subOrderDetail)"
                                    *ngIf="(!editable && salesOrder.status==='SUBMITTED' && subOrderDetail.status !=='CANCELLED')">
                                    <i class="fas fa-ban fa-1x" style="color: grey;cursor: pointer;"></i></a>-->
                              </div>
                           </td>
                           <td style="vertical-align: bottom;">
                              <input type="text" name="customerPO_{{orderDetailIndex}}_{{subOrderDetail.sortOrder}}" class="form-control form-control-sm f-11"
                                 style="font-size:11px; padding-left: 0.2rem; padding-right: 0.2rem; margin-bottom: 0.25rem;" required [disabled]="!editable"
                                 [(ngModel)]="subOrderDetail.customerPONumber"
                                 *ngIf="subOrderDetail.type!=='PP_SAMPLE' && subOrderDetail.type!=='TOP_SAMPLE' && subOrderDetail.type!=='LICENSOR_SAMPLE'">
                           </td>

                           <td *ngIf="showSubOrderDetailPoCustomer">
                              &nbsp;
                           </td>

                           <td>
                              &nbsp;
                           </td>


                           <td style="text-align: center;vertical-align: bottom;">
                              <label *ngIf="subOrderDetail.type==='PP_SAMPLE'" class="f-10">PP Sample</label>
                              <label *ngIf="subOrderDetail.type==='TOP_SAMPLE'" class="f-10">TOP Sample</label>
                              <label *ngIf="subOrderDetail.type==='LICENSOR_SAMPLE'" class="f-10">Licensor Sample</label>
                           </td>
                           <td>
                              <table class="table-sm overflow-auto">
                                 <tr>
                                    <ng-container *ngFor="let sizeOrderDetail of subOrderDetail.sizeOrderDetailList; index as sizeOrderDetailIndex">
                                       <td>
                                          <input type="number" (change)="onChangeSubOrderDetailQuantity(salesOrder,orderDetail, subOrderDetail)"
                                             name="{{orderDetailIndex}}_{{subOrderDetail.sortOrder}}_{{sizeOrderDetailIndex}}" required
                                             [readonly]="(editable && subOrderDetail.packagingType ==='Prepack')" class="form-control form-control-sm f-11 cell-min"
                                             style="text-align: right; font-size:11px" 
                                             [disabled]="!editable  
                                                         || (editable && subOrderDetail.type==='LICENSOR_SAMPLE') 
                                                         || ((subOrderDetail.type==='PP_SAMPLE' && !orderDetail.ppSampleRequired) 
                                                               || (subOrderDetail.type==='TOP_SAMPLE' && !orderDetail.topSampleRequired)
                                                            )" [(ngModel)]="sizeOrderDetail.quantity">
                                       </td>
                                    </ng-container>
                                 </tr>
                              </table>
                           </td>

                           <td>
                              
                              <ng-container *ngIf="(
                                             orderDetail.status!=='CANCELLED' && 
                                             (
                                                (subOrderDetail.type==='PP_SAMPLE' && orderDetail.ppSampleRequired) 
                                                   || (subOrderDetail.type==='TOP_SAMPLE' && orderDetail.topSampleRequired)
                                             )
                                          )
                               then customMinRequired else customMinNotRequired"></ng-container>
                              <ng-template #customMinRequired>
                                 <input type="number"  name="SampletotalQty_{{subOrderDetail.type}}_{{orderDetailIndex}}_{{subOrderDetail.sortOrder}}" [customMin]="1"
                                 class="form-control font-weight-bold cell-min form-control-sm f-12" #total="ngModel"   [readOnly]="true" style="text-align: right; font-size:11px;"
                                 [ngClass]="{'is-invalid':total.errors}"
                                 [(ngModel)]="subOrderDetail.totalSubOrderQuantity">
                                 <div style="position:absolute;left: 10%;" *ngIf="editable && total.errors && total.errors.customMin" class="invalid-feedback">
                                    <div>{{'sales.sampleType.'+subOrderDetail.type | transform}} Qty is Required</div>
                                 </div>
                              </ng-template>
                              <ng-template #customMinNotRequired>
                                 <input type="number"  name="SampletotalQty_{{subOrderDetail.type}}_{{orderDetailIndex}}_{{subOrderDetail.sortOrder}}" 
                                 class="form-control font-weight-bold cell-min form-control-sm f-12" #total="ngModel"   [readOnly]="true" style="text-align: right; font-size:11px;"
                                 [(ngModel)]="subOrderDetail.totalSubOrderQuantity">
                              </ng-template>
                              
                           </td> 
                           <td style="vertical-align: bottom;">
                              <div class="d-flex d-inline">
                                 <select class="form-control form-control-sm" style="font-size:11px; margin-bottom: 0.25rem;" required
                                    name="packagingType_{{orderDetailIndex}}_{{subOrderDetail.sortOrder}}" #packagingType [disabled]="!editable" [ngModel]="subOrderDetail.packagingType"
                                    (ngModelChange)="onPackagingTypeChangeOrPrepackClick(orderDetail,orderDetailIndex, subOrderDetail,subOrderDetailIndex, subOrderDetail.packagingType,$event)">
                                    <option *ngFor="let packaging of packagingTypeListFromMetadata" [ngValue]="packaging">
                                       {{packaging}}</option>
                                 </select>
                                 <input type="text" name="prePackDetails_{{orderDetailIndex}}_{{subOrderDetail.sortOrder}}" [readonly]="true"
                                       [(ngModel)]="subOrderDetail.prepackDetail"
                                    *ngIf="subOrderDetail.packagingType === 'Prepack'" class="form-control form-control-sm f-11 ml-1 px-1" style="font-size:11px; margin-bottom: 0.25rem;">
                                    <button type="button" class="btn btn-sm px-1" *ngIf="editable && subOrderDetail.packagingType === 'Prepack'" (click)="onPackagingTypeChangeOrPrepackClick(orderDetail,orderDetailIndex, subOrderDetail,subOrderDetailIndex, subOrderDetail.packagingType,'Prepack')"><i class="fas fa-pencil-alt"></i></button>
                              </div>
                           </td>
                           <td style="vertical-align: bottom;">
                              <input type="text" name="notes_{{orderDetailIndex}}_{{subOrderDetail.sortOrder}}" class="form-control form-control-sm f-11"
                                 style="font-size:11px; margin-bottom: 0.25rem;" [disabled]="!editable" [(ngModel)]="subOrderDetail.notes" maxlength="250">
                           </td>
                        </tr>
                     </ng-container>
                  </ng-container>
                     <!-- END:  Sub Order Details list and Size Order Details - Rows for PHOTO, PP and TOP Sample -->

                     <!-- START: TOTAL SAMPLES ROW From Order Detail Size Quantity at the Order Detail Level FOR SAMPLES-->
                  <ng-container *ngIf="salesOrder.orderType !== 'Estimation'">
                     <tr class="mt-2" style="background-color: aliceblue;"><!--*ngIf="orderDetail.totalSampleQuantity && orderDetail.totalSampleQuantity>0"-->
                        <td [attr.colspan]="3+colspanIncrementCount">&nbsp;</td>
                        <td style="text-align: center;vertical-align: bottom;">
                           <label class="f-11 font-weight-bold">TOTAL SAMPLE</label>
                        </td>
                        <td>
                           <table>
                              <tr>
                                 <ng-container *ngFor="let orderDetailSizeQuantity of orderDetail.orderDetailSizeQuantityList; index as orderDetailSizeQuantityIndex">
                                    <td>
                                       <input type="number" name="SAMPLE_{{orderDetailSizeQuantity.size}}_{{orderDetailIndex}}_{{orderDetailSizeQuantityIndex}}"
                                          class="form-control cell-min form-control-sm f-12 font-weight-bold" style="text-align: right; font-size:11px; " required readonly
                                          [disabled]="!editable" [(ngModel)]="orderDetailSizeQuantity.sampleQuantity">
                                    </td>
                                 </ng-container>
                              </tr>
                           </table>
                        </td>
                        <td>
                           <input type="number" name="totalSampleQuantity_{{orderDetail.totalOrderQuantity}}_{{orderDetailIndex}}"
                              class="form-control cell-min form-control-sm f-12 font-weight-bold" style="text-align: right; font-size:11px;" [disabled]="true"
                              [(ngModel)]="orderDetail.totalSampleQuantity">
                        </td>
                        <td colspan="2">&nbsp;</td>
                     </tr>
                     <!-- END: TOTAL SAMPLE ROW From Order Detail Size Quantity at the Order Detail Level FOR SAMPLES-->

                     <tr>
                        <td [attr.colspan]="8+colspanIncrementCount">
                           <hr class="mt-1 mb-1">
                        </td>
                     </tr>
                  </ng-container>

                     <!-- START: GRAND TOTAL SALES ORDER  + SAMPLE ORDER ROW at the Order Detail Level  -->
                  <ng-container *ngIf="salesOrder.orderType !== 'Estimation'">
                     <tr class="mt-2" style="background: #abb5c0;color: white;">
                        <td [attr.colspan]="3+colspanIncrementCount">&nbsp;</td>
                        <td style="text-align: center;vertical-align: bottom;">
                           <label class="f-11 font-weight-bold">GRAND TOTAL</label>
                        </td>
                        <td>
                           <table>
                              <tr>
                                 <ng-container *ngFor="let orderDetailSizeQuantity of orderDetail.orderDetailSizeQuantityList; index as orderDetailSizeQuantityIndex">
                                    <td>
                                       <input type="number" name="GRAND_TOTAL_{{orderDetailSizeQuantity.size}}_{{orderDetailIndex}}_{{orderDetailSizeQuantityIndex}}"
                                          class="form-control cell-min form-control-sm f-12 font-weight-bold" style="text-align: right; font-size:11px; " required readonly
                                          [disabled]="!true" value="{{orderDetailSizeQuantity.quantity + orderDetailSizeQuantity.sampleQuantity}}">
                                    </td>
                                 </ng-container>
                              </tr>
                           </table>
                        </td>
                        <td>
                           <input type="number" name="GRAND_TOTAL_{{orderDetail.totalOrderQuantity}}_{{orderDetailIndex}}"
                              class="form-control cell-min form-control-sm f-12 font-weight-bold" style="text-align: right; font-size:11px;" [disabled]="true"
                              value="{{orderDetail.totalOrderQuantity + orderDetail.totalSampleQuantity}}">
                        </td>
                        <td colspan="2">&nbsp;</td>
                     </tr>
                  </ng-container>
                     <!-- END: GRAND TOTAL SALES ORDER  + SAMPLE ORDER ROW at the Order Detail Level  -->                                 


                  </tbody>
               </table>
            </div>
            <!-- ########## END: DIV FOR SUB ORDER DETAIL ##########-->

            <!-- START: BLANK DETAILS -->
            <table class="table-sm bordered" width="100%">
               <tbody>
                  <tr>
                     <!--<td *ngIf="!editable">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Blank Code</label>
                        <input type="text" name="blankSkuCode_{{orderDetailIndex}}" class="form-control form-control-sm" [(ngModel)]="orderDetail.blanks.skuCode" style="font-size: 11px;"
                           [disabled]="true" maxlength="40">
                     </td>

                     <td *ngIf="editable">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Blank Code</label>
                        <select class="form-control form-control-sm custom-select" [(ngModel)]="orderDetail.blanksId" id="blankSkuCode_{{orderDetailIndex}}"
                           name="blankSkuCode_{{orderDetailIndex}}" style="font-size: 11px;" maxlength="40" #blankCodeVar="ngModel" (change)="onBlanksSkuCodeChange($event,orderDetail)"
                           [ngClass]="{'is-invalid':blankCodeVar.touched && blankCodeVar.errors}">
                           <option [ngValue]="null">&nbsp;</option>
                           <option *ngFor="let blanks of blanksList" [ngValue]="blanks.id">
                              {{blanks.skuCode}}</option>
                        </select>
                        <div *ngIf="blankCodeVar?.invalid && (blankCodeVar.dirty || blankCodeVar.touched)" class="invalid-feedback">
                           <div *ngIf="blankCodeVar.errors.required">
                              Blank Code is required.
                           </div>
                        </div>
                     </td>

                     <td>
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Blank Supplier</label>
                        <input type="text" name="blankSupplier_{{orderDetailIndex}}" class="form-control form-control-sm" required [(ngModel)]="orderDetail.blanks.supplier"
                           style="font-size: 11px;" [disabled]="true" [ngClass]="{'is-invalid':blankSupplierVar.touched && blankSupplierVar.invalid}" #blankSupplierVar>
                     </td>

                     <td>
                        <label class="mb-0 ml-1" style="font-size: .60rem; color:lightslategray;">Fabric Content</label>
                        <input type="fabricContent" name="fabricContent_{{orderDetailIndex}}" class="form-control form-control-sm" #fabricContentVar
                           [(ngModel)]="orderDetail.blanks.fabricContent" style="font-size: 11px;" [disabled]="true"
                           [ngClass]="{'is-invalid':fabricContentVar.touched && fabricContentVar.errors}" maxlength="40">
                     </td>

                     <td>
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">GSM</label>
                        <input type="text" name="gsm_{{orderDetailIndex}}" class="form-control form-control-sm" #gsmVar [(ngModel)]="orderDetail.blanks.gsm" [disabled]="true"
                           style="font-size: 11px;" [ngClass]="{'is-invalid':gsmVar.touched && gsmVar.errors}" maxlength="40">
                     </td>-->

                     <td width="20%">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Blanks Quality</label>
                        <select class="form-control form-control-sm" style="font-size:11px;" name="blanksQuality_{{orderDetailIndex}}" #blanksQualityVar [disabled]="!editable"
                           [(ngModel)]="orderDetail.blanksQuality">
                           <option *ngFor="let blanksQuality of blanksQualityListFromMetadata" [ngValue]="blanksQuality">
                              {{blanksQuality}}</option>
                        </select>
                     </td>

                     <td width="20%">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Blanks Color</label>
                        <select class="form-control form-control-sm" style="font-size:11px;" name="blanksColour_{{orderDetailIndex}}" #blanksColourVar [disabled]="!editable"
                           [(ngModel)]="orderDetail.blanksColour">
                           <option *ngFor="let blanksColour of blanksColourListFromMetadata" [ngValue]="blanksColour">
                              {{blanksColour}}</option>
                        </select>
                     </td>

                     <td width="60%">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Special Features</label>
                        <input name="specialFeatures_{{orderDetailIndex}}" class="form-control form-control-sm" #specialFeaturesVar [(ngModel)]="orderDetail.specialFeatures"
                           style="font-size: 11px;" [disabled]="!editable" maxlength="250">
                     </td>
                  </tr>
               </tbody>
            </table>
            <!-- END: BLANK DETAILS -->

         </div>
         <!-- ########## END: DIV FOR ORDER DETAIL ##########-->

      </div>
   </div>
</ng-container>