import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserManagementService } from 'src/app/shared/service/user-management.service';
import { User } from 'src/app/shared/model/user.model';
import { RoleService } from 'src/app/shared/service/role.service';
import { Role } from 'src/app/shared/model/role.model';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/service/auth.service';
import { VendorsService } from 'src/app/shared/service/vendors/vendors.service';

@Component({
   selector: 'app-user',
   templateUrl: './user.component.html',
   styleUrls: ['./user.component.css']
})

export class UserComponent implements OnInit {
   createAction: boolean = false;
   viewAction: boolean = false;
   editAction: boolean = false;
   

   constructor(private router: Router, private userManagementService: UserManagementService,
      private roleService: RoleService, private activatedRoute: ActivatedRoute, private vendorService: VendorsService,
      private sharedService: SharedService,private authService:AuthService, private toastrService: ToastrService) { }

   selectedUserId: string;
   user: User;
   roles: Role[];
   roleListModalFiltered: Role[];
   userRoles: Role[];
   selectedRoles: Role[];
   selectedRole: string;
   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;
   orgTypeList:string[]=["Printer"];
   orgList:any[]=[];
   orgIdProperty:string='id';
   orgNameProperty:string='name';

   ngOnInit() {
      this.handleSubscriptions();
      this.user = new User();
      this.roles = new Array<Role>();
      this.getUser();
      this.getRoles();
      this.getRolesByUser(this.selectedUserId);
      this.evaluateAction();
      window.scrollTo(0, 0);
   }

   /**
    * This method handles subscriptiosn
    */
   handleSubscriptions() {
      // Subscriptions
      this.userAccess = new UserAccess();
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });

      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
         this.currentLoggedInUser = currentLoggedInUser;
      });
   }

   /**
    * This method is called on form submit.
    */
   onSubmit() {
      if (this.user.userRoles?.length <= 0) {
         this.toastrService.error("Please select atleaset one User Role.");
         return;
      }
      if (this.createAction) {
         this.user.createdBy = this.currentLoggedInUser.email;
         this.createUser(this.user);
      }
      if (this.editAction) {
         this.user.updatedBy = this.currentLoggedInUser.email;
         this.updateUser(this.user);
      }
   }

   /**
    * Method to get user by user id.
    */
   getUser() {
      let userId = this.activatedRoute.snapshot.paramMap.get('id');
      this.selectedUserId = userId;
      if (userId) {
         this.userManagementService.getUserById(userId).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.user = response.responsePayload;
               this.loadOrgList();
            } else {
               this.toastrService.error(response.responseStatus.message);
            }
         });
      }
   }

   /**
    * Method to Create User.
    * @param user 
    */
   createUser(user: User) {
      this.userManagementService.createUser(user).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.router.navigate(['user/list']);
         } else if (response.responseStatus.status === 'FAILED') {
            if (response.responseStatus.errorCode === 'USER.API.CREATE.USER.SEND.EMAIL.ERROR.002') {
               console.error("User Component - createUser send email failed...", response.responseStatus.message);
               this.router.navigate(['user/list']);
            }
            if (response.responseStatus.errorCode === 'USER.API.CREATE.USER.ERROR.001') {
               console.error("User Component - createUser failed...", response.responseStatus.message);
            }
            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }

   /**
    * Method to update user.
    * @param user 
    */
   updateUser(user: User) {
      this.userManagementService.updateUser(user).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.router.navigate(['user/list']);
         } else if (response.responseStatus.status === 'FAILED') {
            console.error("User Component - updateUser failed...", response.responseStatus.message);
            if (response.responseStatus.errorCode === 'USER.API.UPDATE.EMAIL.ERROR.001'){
               this.router.navigate(['user/list']);
            }
            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }

   /**
    * Method to send password reset email.
    */
   sendResetPasswordMail() {
      this.user.userName = this.user.email;
      this.userManagementService.sendResetPasswordEmail(this.user).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.router.navigate(['user/list']);
         } else if (response.responseStatus.status === 'FAILED') {
            console.error("User Component - sendResetPasswordMail failed...", response.responseStatus.message);
            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }

   /**
    * 
    */
   getRoles() {
      this.roleService.loadRoles().subscribe(response => {
         this.roles = response.responsePayload;
         this.roleListModalFiltered = this.roles;

      });
   }

   /**
    * This method gets the  user roles based on user id.
    * @param id 
    */
   getRolesByUser(id: string) {
      this.roleService.loadUserRoles(id).subscribe(response => {
         this.userRoles = response.responsePayload;
         this.user.userRoles = this.userRoles;
      });
   }

   /**
    * 
    * @param id 
    */
   isRoleSelected(id: string): boolean {
      if (this.user.userRoles) {
         let temp: Role[] = this.user.userRoles.filter(itemInList => itemInList.id === id);
         return (temp.length == 1);
      } else {
         return false;
      }
   }

   /**
    * 
    * @param event 
    * @param role 
    */
   selectedRolesForSearch(event, role: Role) {
      if (event.target.checked) {
         if (!this.isRoleSelected(role.id)) {
            this.user.userRoles.push(role);
         }
      } else {
         this.user.userRoles = this.user.userRoles.filter(itemInList => itemInList.id !== role.id);
      }
   }

   /**
    * This method evaluates the action.
    */
   evaluateAction() {
      const activatedRoutePath = this.activatedRoute.snapshot.routeConfig.path;
      if (this.activatedRoute.snapshot.paramMap.get('action') === "view") {
         this.viewAction = true;
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "create") {
         this.editAction = false;
         this.createAction = true;
         this.user.active = true;
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "edit") {
         this.editAction = true;
         this.createAction = false;
      }
   }

   goBack() {
      this.router.navigate(['user/list']);
   }

   edit(userId: string) {
      this.userManagementService.getUserById(userId).subscribe(response => {
         this.user = response.responsePayload;
         this.user.userRoles = this.userRoles;
      });
      this.editAction = true;
      this.createAction = false;
      this.viewAction = false;
   }

   onKeyupRoleModalFilter(event, value) {
      if (value.trim().length > 0) {
         this.roleListModalFiltered = this.roles.filter(item => item.roleDescription.toUpperCase().indexOf(value.toUpperCase()) !== -1);
      } else {
         this.roleListModalFiltered = this.roles;
      }
   }

   loadOrgList(){
      if(this.user && this.user.orgType){
         if(this.user.orgType==='Printer'){
            this.getPrinterList();
         }
      }
   }

   orgTypeChanged(user:User){
      this.orgList=[];
      user.orgId=undefined;
      if(user.orgType==='Printer'){
         this.getPrinterList();
      }
   }

   getPrinterList() {
  
      this.vendorService.getPrinterList().subscribe(res=>{
        if(res && res.responseStatus.status==='SUCCESS' && res.responsePayload !=null){
          let printerList=res.responsePayload;
          printerList = this.sharedService.sortListByPropertyName(printerList,'name');
          this.orgList=printerList;
          this.orgNameProperty='name';
          this.orgIdProperty='id';
        }
      });
    }

}
