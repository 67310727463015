import { Component, OnInit, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';
import { SearchComponent } from 'src/app/common/search/search.component';
import { SearchDetails } from 'src/app/shared/model/common/search-details.model';
import { FilterConfig } from 'src/app/shared/model/common/search-filter.model';
import { Customer } from 'src/app/shared/model/customer.model';
import { ProductionTracker } from 'src/app/shared/model/sales/fulfillment/production-tracker.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { Printer } from 'src/app/shared/model/vendors/printer.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { CustomerService } from 'src/app/shared/service/customer.service';
import { ProductionTrackerService } from 'src/app/shared/service/sales/fulfillment/production-tracker.service';
import { UserManagementService } from 'src/app/shared/service/user-management.service';
import { SearchService } from 'src/app/shared/service/search.service';
import { VendorsService } from 'src/app/shared/service/vendors/vendors.service';
import { SalesHelperService } from 'src/app/shared/service/sales/sales-helper.service';

@Component({
  selector: 'app-production-tracker-list',
  templateUrl: './production-tracker-list.component.html',
  styleUrls: ['./production-tracker-list.component.css']
})
export class ProductionTrackerListComponent implements OnInit {

  constructor(private productionTrackerService:ProductionTrackerService,private searchService:SearchService,
    private authService:AuthService,private vendorService:VendorsService, private customerService:CustomerService,
    private userService:UserManagementService, private salesHelperService:SalesHelperService) { }

  //userAccessSubscription: Subscription;
   userAccess: UserAccess;

   //currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;

   printerList:Printer[]=[];
   customers:Customer[]=[];
   salesUserList:User[] = [];
   
  productionTrackerList:ProductionTracker[]=[];
  searchConfig:SearchDetails;
  sortColumns = [
    { columnName: 'SALES_ORDER_NUMBER', sortByName: 'Sales Order #' },
    
    { columnName: 'CUSTOMER_SHIP_DATE', sortByName: 'Order Ship Date' },
    { columnName: 'CUSTOMER_CANCEL_DATE', sortByName: 'Order Cancel Date' },
 ];
 /*{ columnName: 'CUSTOMER_NAME', sortByName: 'Customer' },*/
 @ViewChild('searchComponent') searchComponent:SearchComponent<ProductionTracker>;
  async ngOnInit(): Promise<void> {
    this.handleSubscriptions();
    this.handleSearchOnInit();
  }
  
  async handleSearchOnInit() {
    await this.getVendorList();
    //console.log('Call Get vendor');
    await this.getCustomers();
    //console.log('Call Get Customer');
    await this.getSalesUserList();
    //console.log('Call Get SalesUser list');
    if (!this.searchConfig) {
      this.searchConfig = this.searchService.productionTrackerListSearchDetails;
      this.setFilterConfigs();
      //this.search();
      /*this.searchConfig.setFilterInList('CLIENTID','String',[]);
      this.searchConfig.setFilterInList('WHPID','String',[]);
      this.searchConfig.searchCriteria.searchSubType='INVENTORY_LEVEL_2';*/
    }
  }

    async getCustomers(){
      await this.customerService.getCustomers().toPromise().then(res=>{
         if(res.responseStatus.status==='SUCCESS'){
            this.customers=res.responsePayload as Customer[];
            //console.log('Got Customer list');
         }
      })
    }

    async getSalesUserList() {
      await this.userService.getUsersByRoleId('SALES_USER').toPromise().then(response => {
         this.salesUserList = response.responsePayload;
         //console.log('Got SalesUser list');
      });
   }
  
  setFilterConfigs() {
   if(!this.searchConfig.filterConfigs){
      this.searchConfig.filterConfigs=[];
   
    let filterConfig2:FilterConfig={
      filterType:'IN_CHECKLIST',
      label:'Printer',
      currentSelection:'ALL',
      options: this.printerList,
      optionDislpayVar:'name',
      optionValueVar:'name',
      fieldName:'PRINTERS'
    };
    this.searchConfig.filterConfigs.push(filterConfig2);
    let filterConfig:FilterConfig={
      filterType:'DATE_RANGE',
      label:'Ship Date',
      currentSelection:undefined,
      fieldName:'CUSTOMER_SHIP_DATE'
    };
    this.searchConfig.filterConfigs.push(filterConfig);
    let customerFilterConfig:FilterConfig={
      filterType:'IN_CHECKLIST',
      label:'Customers',
      currentSelection:'ALL',
      options: this.customers,
      optionDislpayVar:'customerName',
      optionValueVar:'id',
      fieldName:'CUSTOMER_ID'
    };
    this.searchConfig.filterConfigs.push(customerFilterConfig);

    let salesUserFilterConfig:FilterConfig={
      filterType:'IN_CHECKLIST',
      label:'Sales Rep.',
      currentSelection:'ALL',
      options: this.salesUserList,
      optionDislpayVar:'fullName',
      optionValueVar:'userName',
      fieldName:'SALES_USER_NAME'
    };
    this.searchConfig.filterConfigs.push(salesUserFilterConfig);

    let filterConfig3:FilterConfig={
      filterType:'IN_CHECKLIST',
      label:'Printer Status',
      currentSelection:'ALL',
      options: [{value:'DELAYED',display:'Delayed'},{value:'ON_TIME',display:'On Time'},{value:'DETAILS_MISSING',display:'Details Missing'}],
      optionDislpayVar:'display',
      optionValueVar:'value',
      fieldName:'PRINT_INFO_STATUS'
    };
    this.searchConfig.filterConfigs.push(filterConfig3);

    /*let fulfillmentStatus:FilterConfig={
      filterType:'IN_CHECKLIST',
      label:'Fulfillment Status',
      currentSelection:'ALL',
      options: [{value:'ART_PENDING',display:'Art Pending'},{value:'TRIMS Pending',display:'Trims Pending'}],
      optionDislpayVar:'display',
      optionValueVar:'value',
      fieldName:'SAMPLE_STATUS'
    };
    this.searchConfig.filterConfigs.push(fulfillmentStatus);*/
    
   }
   if(!this.searchConfig.defaultNonDisplayFilterConfigs){
     this.searchConfig.defaultNonDisplayFilterConfigs=[];
     let productionStatusListFilterForProductionTracker=this.salesHelperService.productionStatusListFilterForProductionTracker;
    let productionStatus:FilterConfig={
      filterType:'IN_CHECKLIST',
      label:'Production Status',
      currentSelection:'ALL',
      options: productionStatusListFilterForProductionTracker,
      optionDislpayVar:'display',
      optionValueVar:'value',
      fieldName:'PRODUCTION_STATUS'
    };
    this.searchConfig.defaultNonDisplayFilterConfigs.push(productionStatus);
   }
  }

  handleSubscriptions() {
    this.authService.userAccess.subscribe(access => {
       this.userAccess = access;
       //console.log('Got userAccess');
    });
    this.authService.currentUser.subscribe(currentLoggedInUser => {
       this.currentLoggedInUser = currentLoggedInUser;
       //console.log(' Got CurrentLoggedINuser');
    });
 }

  
  search() {
    if (this.searchComponent) {
       this.searchComponent.search();
    }
 }

 onProductionTrackerChnage(index:number,salesOrderId:string){
   if(this.productionTrackerList){
     this.productionTrackerService.loadProductionTracker(salesOrderId).subscribe(res=>{
       if(res && res!==null){
         res.accordionExpanded=true;
        this.productionTrackerList.splice(index,1,res);
       }
     })
      
   } 
 }

 async getVendorList() {
  
  await this.vendorService.getPrinterList().toPromise().then(res=>{
    if(res && res.responseStatus.status==='SUCCESS' && res.responsePayload !=null){
      this.printerList=res.responsePayload;
      //console.log('Got Vendor list');
    }
  });
}

productionTrackerForSalesOpened(productionTracker){
  productionTracker.accordionExpanded=true;
}


 
}
