<!-- 
<div class="row mt-2 d-flex justify-content-center">
   <h2 class="d-inline">Tasks List</h2>
</div>
<hr>
-->



<!--
<mat-tab-group [selectedIndex]="selectedTab" (selectedTabChange)="selectedTabChange($event)" animationDuration="0ms">

   <mat-tab label="active">

      <ng-template mat-tab-label>
         <h6>Active<span class="ml-2 font-weight-normal"></span></h6>
      </ng-template>

      <div class="overflow-hidden">-->

         <div class="row" *ngIf="currentLoggedInUser && currentLoggedInUser!==null && searchConfig ">
            <div class="col-12">
               <app-search #searchComponent [placeholder]="'Task Name, Description, Reference#, etc..'" [searchConfig]="searchConfig" [existingList]="taskList" [sortColumns]="sortColumns">
               </app-search>
            </div>
         </div>

         <button type="button" (click)="reassignTaskList()" class="btn btn-sm btn-warning f-11" *ngIf="taskSelectedCount>0">Reassign</button>

         <div *ngIf="currentLoggedInUser && currentLoggedInUser!==null && searchConfig">

            <table class="table table-condensed table-sm  border"><!--customtablehover-->
               <thead class="thead-dark">
                  <tr class="f-11">
                     <th style="width: 1%;" class=" align-middle text-center text-nowrap border"></th>
                     <th style="width: 1%;" class=" align-middle text-center text-nowrap border"></th>
                     <th style="width: auto;" class=" align-middle text-center text-nowrap border">Name</th>
                     <th style="width: auto;" class=" align-middle text-center text-nowrap border">Reference#</th>
                     <th style="width: auto;" class=" align-middle text-center text-nowrap border">Description</th>
                     <th style="width: auto;" class=" align-middle text-center text-nowrap border">Created On</th>
                     <th style="width: auto;" class=" align-middle text-center text-nowrap border">Due On</th>
                     <th style="width: auto;" class=" align-middle text-center text-nowrap border">Status</th>
                     <th style="width: auto;" class=" align-middle text-center text-nowrap border">Assigned To</th>
                  </tr>

               </thead>
               <tbody>
                  <ng-container *ngFor="let task of taskList; index as taskIndex">
                     <tr class="f-11" [style.background]="task.expanded?'rgba(248, 220, 129, 0.25)':''">
                        <td style="white-space: nowrap;width: 1%;">
                           <div class="custom-control custom-checkbox">
                              <input class="custom-control-input" type="checkbox" name="select_{{task.id}}" 
                              [(ngModel)]="task.selected"  id="select_{{task.id}}" (change)="taskSelected(task)" [disabled]="task.status!=='ACTIVE'">
                              <label class="custom-control-label" for="select_{{task.id}}">&nbsp;</label>
                          </div>
                        </td>
                        <td style="white-space: nowrap;width: 1%;" (click)="showHideTaskDetails(task)">
                           
                           <!--<div class="d-flex d-inline">
                              <a class="btn" style="border: 0px;" class="ml-1 mr-1" *ngIf="true" (click)="completeTask(task)"><i class="fas fa-check-circle fa-1x"
                                    style="color: grey;cursor: pointer;"></i></a>
                           </div>-->
                           <button type="button" *ngIf="!task.expanded" class="transparent-button border-0"><i class="fas fa-angle-down fa-lg"></i></button>
                           <button type="button" *ngIf="task.expanded" class="transparent-button border-0"><i class="fas fa-angle-up fa-lg"></i></button>

                        </td>
                        <td style="width: auto;" (click)="showHideTaskDetails(task)" class="align-middle text-left text-nowrap">{{task?.name}}</td>
                        <td style="width: auto;" class="align-middle text-left text-nowrap">
                           <div *ngIf="task.classification==='Sales'; then thenBlock else elseBlock"></div>
                              <ng-template #thenBlock>
                                 <ng-container *ngIf="task.classification==='Sales'">
                                    <a [routerLink]="['/salesOrder/view/'+task.classificationId]">{{task?.classificationReference}}</a>
                                 </ng-container>
                              </ng-template>
                              <ng-template #elseBlock>
                                 {{task?.classificationReference}}
                              </ng-template>
                           
                        </td>
                        <td style="width: auto;" class="align-middle text-left text-nowrap" (click)="showHideTaskDetails(task)">{{task?.description}}</td>
                        <td style="width: auto;" class="align-middle text-center text-nowrap">{{task?.createdOn | date:'mediumDate'}}</td>
                        <td style="width: auto;" class="align-middle text-center text-nowrap">{{task?.plannedEndDate | date:'mediumDate'}}</td>
                        <td style="width: auto;" class="align-middle text-left text-nowrap">{{task?.status}}</td>
                        <td style="width: auto;" class="align-middle text-left text-nowrap">{{task?.assignedToIndividual}}</td>
                     </tr>
                     <ng-container *ngIf="task.expanded">
                        <tr [style.background]="task.expanded?'rgba(248, 220, 129, 0.05)':''">
                           <td colspan="999">
                              <div class="container-fluid f-12">
                                 <div class="row">
                                    <div class="col-8">
                                       <mat-tab-group  animationDuration="0ms">
                                          <mat-tab label="active">
                                             <ng-template mat-tab-label>
                                                <div class="f-11">Details<span class="ml-2 font-weight-normal"></span></div>
                                             </ng-template>
                                             <div class="overflow-hidden">
                                                <ng-container *ngIf="task.taskDetails && task.taskDetails.taskDetailedDescriptions && task.taskDetails.taskDetailedDescriptions.length>0">
                                                   <div class="m-2">
                                                      <ng-container *ngFor="let detailedDesc of task.taskDetails.taskDetailedDescriptions">
                                                         <li>{{detailedDesc}}</li>
                                                      </ng-container>
                                                   </div>
                                                </ng-container>
                                                <ng-container *ngIf="task.taskDetails && task.taskDetails.taskWorkItems && task.taskDetails.taskWorkItems.length>0">
                                                   <hr>
                                                   <h6 class="f-14 font-weight-bold">Work Items</h6>
                                                   
                                                   <div class="m-2">
                                                      <ng-container *ngFor="let wi of task.taskDetails.taskWorkItems; index as wiIndex">
                                                         
                                                            <div class="row mb-1">
                                                               <div class="col-auto" *ngIf="wi.eligibleStatuses.includes(wi.status)"><i class="fas fa-check-circle text-success"></i></div>
                                                               <div class="col-auto" *ngIf="!wi.eligibleStatuses.includes(wi.status)"><i class="fas fa-times-circle text-danger"></i></div>
                                                               <div class="col-sm-6">{{wi.description}}</div>
                                                               <div class="col-auto">
                                                               <select class="form-control form-control-sm" [required]="wi.mandatory" style="font-size: 9px !important;" name="wi{{taskIndex}}_{{wiIndex}}" 
                                                                  [(ngModel)]="wi.status"
                                                                  #wi="ngModel" 
                                                                  [ngClass]="{'is-invalid':wi && wi.errors}">
                                                                     <option class="f9" [value]="wiOptions" *ngFor="let wiOptions of wi.statusOptions">{{wiOptions}}</option>
                                                                  </select>
                                                               </div>
                                                            </div>

                                                         
                                                      </ng-container>
                                                   </div>
                                                   <hr>
                                                </ng-container>
                                                <div class="d-flex d-inline" *ngIf="task.status==='ACTIVE' && task.useCanComplete">
                                                   <a class="btn" style="border: 0px;" class="btn btn-sm bg-warning text-white f-11 ml-1 mr-1 text-dark" *ngIf="task.taskDetails && task.taskDetails.taskWorkItems && task.taskDetails.taskWorkItems.length>0" (click)="saveTask(task)">Save Task</a>
                                                   <a class="btn" style="border: 0px;" class="btn btn-sm bg-success text-white f-11 ml-1 mr-1" *ngIf="true" (click)="completeTask(task)">Complete Task</a>
                                                   
                                                </div>
                                             </div>
                                          </mat-tab>
                                          <mat-tab label="active">
                                             <ng-template mat-tab-label>
                                                <div class="f-11">History<span class="ml-2 font-weight-normal"></span></div>
                                             </ng-template>
                                             <div class="overflow-hidden">
                                                <div class="d-flex d-inline" *ngIf="task.taskDetails && task.taskDetails.taskHistory">
                                                   <ng-container>
                                                      <table class="table table-condensed table-sm  ">
                                                         <thead >
                                                            <tr class="f-11">
                                                               <th style="width: auto;" class="text-nowrap ">Activity</th>
                                                               <th style="width: auto;" class="text-nowrap ">Description</th>
                                                               <th style="width: auto;" class="text-nowrap ">Date</th>
                                                               <th style="width: auto;" class="text-nowrap ">User#</th>
                                                            </tr>
                                                         </thead> 
                                                         <tbody>
                                                            <ng-container *ngFor="let th of task.taskDetails.taskHistory; index as thIndex">
                                                               <tr class="f-11">
                                                                  <td>{{th.activity}}</td>
                                                                  <td>{{th.description}}</td>
                                                                  <td>{{th.createdOn | date:'medium'}}</td>
                                                                  <td>{{th.createdBy}}</td>
                                                               </tr>
                                                            </ng-container>
                                                         </tbody>
                                                         </table>

                                                   </ng-container>
                                                </div>
                                             </div>
                                          </mat-tab>
                                    </mat-tab-group>
                                    </div>
                                    <div class="col-4">
                                       <div  class="border-info pl-2"><!--style="max-width: calc(70vw);"-->
                                          <app-notes [editable]="true" [parentId]="task.id" [type]="'TASK_NOTES'" [subType]="'USER_NOTES'" [notesHeaderText]="'Notes'" [notesSubject]="''"></app-notes>  
                                      </div>
                                    </div>
                                 </div>
                              </div>
                           </td>
                           
                        </tr>
                     </ng-container>
                  </ng-container>

               </tbody>
            </table>


         </div>
     <!-- </div>
   </mat-tab>


   <mat-tab label="closed">
      <ng-template mat-tab-label>
         <h6>Closed<span class="ml-2 font-weight-normal"></span></h6>
      </ng-template>

      <div class="overflow-hidden">
         <div class="row" *ngIf="currentLoggedInUser && currentLoggedInUser!==null && searchConfig ">
            <div class="col-12">
               <app-search #searchComponent [placeholder]="'Task Name, Description etc..'" [searchConfig]="searchConfig" [existingList]="taskList" [sortColumns]="sortColumns">
               </app-search>
            </div>
         </div>
      </div>
   </mat-tab>

</mat-tab-group>-->