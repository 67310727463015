<form name="customer" (ngSubmit)="onSubmit()" #customerForm="ngForm" autocomplete="off" novalidate class="mb-3">

   <div class="container">

      <div class="d-flex justify-content-center mt-3">
         <div class="text-color-{{customer.status+'' | replace:' ':'_'|lowercase}}">
            <h2>{{customer.customerName}}</h2>
         </div>
         <div></div>
      </div>
      <div *ngIf="customer.status!==undefined" class="separator-{{customer.status+'' | replace:' ':'_'|lowercase}}">
         <h6>{{customer.status?'Active':'Inactive'}}</h6>
      </div>


      <div class="row  mt-4 shadow-lg p-3 mb-5 bg-white rounded">
         <div class="col-lg-12">
            <mat-tab-group>
               <!-- animationDuration="0ms"-->

               <!-- Customer DETAILS -->
               <mat-tab>
                  <ng-template mat-tab-label>
                     <h5>Customer <span class="ml-2 font-weight-normal"></span></h5>
                  </ng-template>
                  <div class="overflow-hidden">
                     <!-- TAB Customer DETAILS -->
                     <div class="row mt-4 ml-10">
                        <!--<div class="col-lg-2">
                           <div class="form-group">
                              <label>Code</label>
                              <input name="customerCode" [(ngModel)]="customer.customerCode" class="form-control form-control-sm" #customerCode="ngModel" required [disabled]="!editable"
                                 [ngClass]="{'is-invalid':customerCode.touched && customerCode.errors}" maxlength="40">
                              <div *ngIf="customerCode?.invalid && (customerCode.dirty || customerCode.touched)" class="invalid-feedback">
                                 <div *ngIf="customerCode.errors.required">
                                    Customer Code is required.
                                 </div>
                              </div>
                           </div>
                        </div>-->

                        <div class="col-lg-4">
                           <div class="form-group">
                              <label>Name</label>
                              <input name="customerName" [(ngModel)]="customer.customerName" class="form-control form-control-sm" #customerName="ngModel" required [disabled]="!editable"
                                 [ngClass]="{'is-invalid':customerName.touched && customerName.errors}" maxlength="40">
                              <div *ngIf="customerName?.invalid && (customerName.dirty || customerName.touched)" class="invalid-feedback">
                                 <div *ngIf="customerName.errors.required">
                                    Customer Name is required.
                                 </div>
                              </div>
                           </div>
                        </div>

                        <!--<div class="col-lg-6">
                           <div class="form-group">
                              <label>Legal Name</label>
                              <input name="legalName" [(ngModel)]="customer.legalName" class="form-control form-control-sm" #legalName="ngModel" required [disabled]="!editable"
                                 [ngClass]="{'is-invalid':legalName.touched && legalName.errors}" maxlength="40">
                              <div *ngIf="legalName?.invalid && (legalName.dirty || legalName.touched)" class="invalid-feedback">
                                 <div *ngIf="legalName.errors.required">
                                    Customer Legal Name is required.
                                 </div>
                              </div>
                           </div>
                        </div>-->                        

                        
                        <div class="col-lg-2">
                           <div class="form-group">
                              <label>Territory</label>
                              <select class="form-control form-control-sm" [(ngModel)]="customer.territory" name="customerTerritory" #customerTerritory="ngModel" required [disabled]="!editable"
                                 [ngClass]="{'is-invalid':customerTerritory.touched && customerTerritory.errors}">
                                 <option *ngFor="let territory of territoryList" [ngValue]="territory">
                                    {{territory}}</option>
                              </select>
                              <div *ngIf="!viewAction && customerTerritory?.invalid && (customerTerritory.dirty || customerTerritory.touched)" class="invalid-feedback">
                                 <div *ngIf="customerTerritory.errors.required">
                                    Customer Territory is required.
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="col-lg-2">
                           <div class="form-group">
                              <label>Category</label>
                              <select class="form-control form-control-sm" [(ngModel)]="customer.tier" name="tier" #tierVar="ngModel" required [disabled]="!editable"
                                 [ngClass]="{'is-invalid':tierVar.touched && tierVar.errors}">
                                 <option *ngFor="let tier of customerTierList" [ngValue]="tier">
                                    {{tier}}</option>
                              </select>
                              <div *ngIf="!viewAction && tierVar?.invalid && (tierVar.dirty || tierVar.touched)" class="invalid-feedback">
                                 <div *ngIf="tierVar.errors.required">
                                    Customer Tier is required.
                                 </div>
                              </div>
                           </div>
                        </div>
                                                
                        <div class="col-lg-2">
                           <div class="form-group">
                              <label>Status</label>
                              <select class="form-control form-control-sm" [(ngModel)]="customer.status" name="customerStatus" #customerStatus="ngModel" required [disabled]="!editable"
                                 [ngClass]="{'is-invalid':customerStatus.touched && customerStatus.errors}">
                                 <option value=false>Inactive</option>
                                 <option value=true>Active</option>
                              </select>
                              <div *ngIf="!viewAction && customerStatus?.invalid && (customerStatus.dirty || customerStatus.touched)" class="invalid-feedback">
                                 <div *ngIf="customerStatus.errors.required">
                                    Customer Status is required.
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="col-lg-2">
                           <div class="form-group">
                              <label>Production Coordinator</label>
                              <select class="form-control form-control-sm custom-select" [(ngModel)]="customer.productionCoordinatorId" name="productionCoordinator" 
                                 #productionCoordinatorVar="ngModel" [disabled]="!editable" required [ngClass]="{'is-invalid':productionCoordinatorVar.touched && productionCoordinatorVar.errors}">
                                 <option *ngFor="let user of productionUserList" [ngValue]="user.id">
                                    {{user.firstName.toUpperCase()}} {{user.lastName.toUpperCase()}}</option>
                              </select>
                              <div *ngIf="!viewAction && productionCoordinatorVar?.invalid && (productionCoordinatorVar.dirty || productionCoordinatorVar.touched)" class="invalid-feedback">
                                 <div *ngIf="productionCoordinatorVar.errors.required">
                                    Production Coordinator is required.
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="col-lg-2">
                           <div class="form-group">
                              <label>Total Discount (%)</label>
                              <input name="totalDiscount" [(ngModel)]="customer.totalDiscount" class="form-control form-control-sm" #totalDiscountVar="ngModel" [disabled]="!editable"
                                 [ngClass]="{'is-invalid':totalDiscountVar.touched && totalDiscountVar.errors}" maxlength="40">
                           </div>
                        </div>

                        <div class="col-lg-6">
                           <div class="form-group">
                              <label>Notes</label>
                              <textarea name="customerNotes" [(ngModel)]="customer.notes" class="form-control form-control-sm" rows="1" #customerNotes="ngModel" [disabled]="!editable"
                                 maxlength="250"></textarea>
                           </div>
                        </div>
                        <div class="col-lg-2">

                        </div>
                     </div>
                  </div>
               </mat-tab>


               <mat-tab>
                  <ng-template mat-tab-label>
                     <h5>Sales Representatives <span class="ml-2 font-weight-normal"></span></h5>
                  </ng-template>
                  <div class="overflow-hidden">

                     <div class="form-group d-flex d-inline mb-0 mt-3" *ngIf="editable">
                        <div class="mt-1">
                           <h5>Add Sales Representative</h5>
                        </div>
                        <div class="d-flex mr-1">
                           <div class="ml-1">
                              <select class="form-control form-control-sm custom-select" [(ngModel)]="selectedSalesPerson"
                                 name="salesPerson" #salesPersonVar="ngModel" [disabled]="!editable">
                                 <option *ngFor="let user of salesUserList" [ngValue]="user.id">
                                    {{user.firstName.toUpperCase()}} {{user.lastName.toUpperCase()}}</option>
                              </select>
                           </div>
                           <div class="ml-1">
                              <button type="button" class="btn btn-info btn-sm" [disabled]="selectedSalesPerson.length==0" (click)="addSalesPerson()" 
                                 #addSalesPersonVar><i class="fas fa-plus"></i></button>
                           </div>
                        </div>
                     </div>  

                     <div class="row gallery-block cards-gallery mt-4">
                        <ng-container *ngFor="let customerSalesPerson of customer.customerSalesPersonList;index as i">
                           <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-4" *ngIf="!customerSalesPerson.deleteFlag">
                              <div class=" card border-0 transform-on-hover h-100 u-color-{{i%4}}" style="margin-bottom: 2px;">
                                 <div class="card-body" style="padding: 0.25rem;">
                                    <div class=" ml-1 mr-1">
                                       <div class=" d-flex justify-content-left">
                                          <button
                                             class="btn bnt-lg bg-dark text-white ">{{customerSalesPerson.salesPerson.firstName.charAt(0).toUpperCase()}}{{customerSalesPerson.salesPerson.lastName?.charAt(0).toUpperCase()}}</button>
                                       </div>
                                       <div>
                                          <h5 class="text-break  ml-2 mr-2 card-text justify-content-center">{{customerSalesPerson.salesPerson.firstName.toUpperCase()}}</h5>
                                          <h5 class="text-break  ml-2 mr-2 card-text justify-content-center">{{customerSalesPerson.salesPerson.lastName.toUpperCase()}}</h5>
                                       </div>
                                       <p class="mb-0">{{customerSalesPerson.salesPerson.email}}</p>
                                    </div>
                                    <div *ngIf="editable || (customerSalesPerson.sharedSalesRepUserNameList?.length>0)" >
                                       <hr class="m-2 bg-white">
                                       <h6 class="f-13 mb-1">Sales order shared with: </h6>
                                       <div class="d-flex mr-1" *ngIf="editable">
                                          <div class="ml-1">
                                             <select class="form-control form-control-sm custom-select" [(ngModel)]="customerSalesPerson.selectedSharedSalesPersonUsername"
                                                name="sharedSalesPerson_{{i}}" #salesPersonVar="ngModel" [disabled]="!editable">
                                                <ng-container *ngFor="let sharedRep of customer.customerSalesPersonList">
                                                   <option  *ngIf="sharedRep.salesPerson.userName!==customerSalesPerson.salesPerson.userName && !customerSalesPerson.sharedSalesRepUserNameList?.includes(sharedRep.salesPerson.userName)" [ngValue]="sharedRep.salesPerson.userName">
                                                      {{sharedRep.salesPerson.firstName.toUpperCase()}} {{sharedRep.salesPerson.lastName.toUpperCase()}} ({{sharedRep.salesPerson.userName}})</option>
                                                </ng-container>
                                             </select>
                                          </div>
                                          <div class="ml-1">
                                             <button type="button" class="btn btn-info btn-sm" [disabled]="customerSalesPerson.selectedSharedSalesPersonUsername===undefined" (click)="addSharedSalesPerson(customerSalesPerson)" 
                                                #addSalesPersonVar><i class="fas fa-plus"></i></button>
                                          </div>
                                       </div>
                                       <div class="flex-column">
                                          <div class="m-1"
                                                *ngFor="let sharedRepUserName of customerSalesPerson.sharedSalesRepUserNameList;index as i">
                                                <div>
                                                         <div class="chip color-{{i%4}}">
                                                            {{sharedRepUserName}}
                                                                  <span class="closebtn" *ngIf="editable" (click)="removeSharedSalesPerson(customerSalesPerson,sharedRepUserName)">&times;</span>
                                                         </div>
                                                </div>
                                          </div>
                                       </div>
                                    </div>
                                
                                    <div class="mb-2" *ngIf="editable">&nbsp;</div>
                                    <button type="button" class="btn  btn-circle btn-circle-sm d-inline" (click)="removeSalesUser(customerSalesPerson)" *ngIf="editable">
                                       <i class="fas fa-trash text-light"></i>
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </ng-container>
                     </div>

                  </div>
               </mat-tab>


               <mat-tab>
                  <ng-template mat-tab-label>
                     <h5>Associated Customers<span class="ml-2 font-weight-normal"></span></h5>
                  </ng-template>

                  <div class="overflow-hidden">

                     <div *ngIf="(customer.id === null || customer.id === undefined) || (customer.id !== null && customer.parentCustomerId === null)">
                        <div class="form-group d-flex d-inline mb-0 mt-3" *ngIf="editable">
                           <div class="mt-1">
                              <h5>Add Customer Association</h5>
                           </div>
                           <div class="d-flex mr-1">
                              <div class="ml-1">
                                 <select class="form-control form-control-sm custom-select" [(ngModel)]="selectedAssociatedCustomer" name="associationCustomer" #salesPersonVar="ngModel" [disabled]="!editable">
                                    <ng-container *ngFor="let customerForAssociation of customerListForAssociation;index as i">
                                       <option [ngValue]="customerForAssociation" *ngIf="customerForAssociation.parentCustomerId === null && customerForAssociation.id !== customer.id && !customerForAssociation.parent">{{customerForAssociation.customerName}}</option>
                                    </ng-container>
                                 </select>
                              </div>
                              <div class="ml-1">
                                 <button type="button" class="btn btn-info btn-sm" [disabled]="!selectedAssociatedCustomer " (click)="addCustomerAssociation()" #addAssociatedCustomerAssociationVar><i
                                       class="fas fa-plus"></i></button>
                              </div>
                           </div>
                        </div>
                  
                        <div class="row ml-2 mr-2 mt-2">
                           <div class="d-inline m-1 mt-0" *ngFor="let associatedCustomer of customer.customerAssociationList;index as i">
                              <div>
                                 <div class="chip color-{{i%4}}" *ngIf="associatedCustomer.parentCustomerId !== null">
                                    {{associatedCustomer.customerName}}
                                    <span class="closebtn" *ngIf="editable" (click)="removeCustomerAssociation(associatedCustomer)">&times;</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div class="mt-4" *ngIf="customer.parentCustomerId !== null">
                        <ng-container *ngFor="let customerForAssociation of customerListForAssociation;index as i">
                           <p class="text-muted text-center" *ngIf="customerForAssociation.id === customer.parentCustomerId">{{customer.customerName}} is already associated with {{customerForAssociation.customerName}}</p>
                        </ng-container>
                     </div>

                  </div>
               
               </mat-tab>


               <mat-tab *ngIf="false">
                  <ng-template mat-tab-label>
                     <h5>Discounts<span class="ml-2 font-weight-normal"></span></h5>
                  </ng-template>

                  <div class="overflow-hidden">

                     <div class="form-group d-flex d-inline mb-0 mt-3">
                        <table class="table table-condensed table-sm table-borderless">
                           <tbody>
                              <tr style="width: 100%;">
                                 <td style="width: 50%;">
                                    <div class="ml-1 d-flex" *ngIf="editable">
                                       <span  style="align-self: center;" class="text-nowrap">Discount Type:&nbsp;&nbsp;</span>
                                       <select class="form-control form-control-sm custom-select" [(ngModel)]="selectedDiscountTypeToAdd" name="discountType" #discountTypeVar="ngModel" [disabled]="!editable">
                                          <ng-container *ngFor="let discountType of customerDiscountTypeList; index as i">
                                             <option [ngValue]="discountType">{{discountType}}</option>
                                          </ng-container>
                                       </select>
                                       <button type="button" class="btn btn-info btn-sm ml-2" [disabled]="!selectedDiscountTypeToAdd" (click)="addCustomerDiscount()" #addcustomerDiscountVar><i class="fas fa-plus"></i></button>
                                    </div>
                                 </td>
                                 <td style="width: 50%; " class="align-middle text-right pr-2">
                                    <div class="ml-1 d-flex">
                                       <div class="col-10">
                                          <span>Total Customer Discount (%): </span>
                                       </div>
                                       <div>
                                          <input type="number" min="1" max="9999" name="totalDiscount" class="form-control cell-min form-control-sm f-11 pr-2" style="text-align: right;" [disabled]="!editable"
                                          [(ngModel)]="customer.totalDiscount">
                                       </div>
                                    </div>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>

                     <div classs="mt-3">
                        <table class="table table-condensed table-sm customtablehover table-borderless">
                           <thead class="thead-dark">
                              <tr>
                                 <th style="width: 5%;" class=" align-middle text-center text-nowrap border">Actions</th>
                                 <th style="width: 35%;" class=" align-middle text-center text-nowrap border">Discount Type</th>
                                 <th style="width: 10%;" class=" align-middle text-center text-nowrap border">Discount %</th>
                                 <th style="width: 10%;" class=" align-middle text-center text-nowrap border">Terms</th>
                                 <th style="width: 40%;" class=" align-middle text-center text-nowrap border">Remarks</th>
                              </tr>
                           </thead>
                           <tbody>
                              <ng-container *ngFor="let customerDiscount of customer.customerDiscountList; index as index">
                                 <tr *ngIf="!customerDiscount.deleteFlag">
                                    <td style="white-space: nowrap;width: 5%;">
                                       <a class="btn" style="border: 0px;" class="ml-1 mr-1" *ngIf="editable" (click)="deleteCustomerDiscount(customerDiscount)"><i class="fas fa-trash fa-1x"
                                          style="color: #dc3545;cursor: pointer;"></i></a>
                                    </td>
                                    <td style="width: auto;" class="align-middle text-left text-nowrap pl-2">{{customerDiscount.discountType}}</td>
                                    <td>
                                       <input type="number" min="1" max="9999" name="discountPercentage_{{index}}" class="form-control cell-min form-control-sm f-11 pr-2"
                                          style="text-align: right;" required [disabled]="!editable" [(ngModel)]="customerDiscount.discountPercentage" (change)="onDiscountPercentChange()">
                                    </td>
                                    <td>
                                       <input type="text" name="terms_{{index}}" class="form-control cell-min form-control-sm f-11 pl-2"
                                          style="text-align: left;" [disabled]="!editable" [(ngModel)]="customerDiscount.terms">
                                    </td>
                                    <td>
                                       <input type="text" name="remarks_{{index}}" class="form-control cell-min form-control-sm f-11 pl-2"
                                          style="text-align: left;" [disabled]="!editable" [(ngModel)]="customerDiscount.remarks">
                                    </td>
                                 </tr>
                              </ng-container>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </mat-tab>


            </mat-tab-group>

         </div>

      </div>

      <div class="row d-flex justify-content-end">
         <div class="col-lg-3">
            <div class="form-group ml-0">
               <button type="submit" class="btn btn-info btn-block" [disabled]="!customerForm.valid" *ngIf="editable && createAction">Submit</button>
            </div>
         </div>
      </div>

   </div>
   <div class="floatBtn d-flex flex-column">
      <button type="submit" class=" btn btn-circle bg-info mb-2" *ngIf="editable && !createAction" matTooltip="Save Changes" matTooltipPosition="left" matTooltipClass="bg-dark"
         [disabled]="customerForm.invalid"><i class="fas fa-save fa-2x" style="color:white"></i>
      </button>
      <a rel="noopener" (click)="cancelEdit()" class=" btn btn-circle bg-dark mb-2" *ngIf="editable && !createAction" matTooltip="Cancel Edit" matTooltipPosition="left"
         matTooltipClass="bg-dark"><i class="fas fa-times fa-2x" style="color:white"></i></a>
      <a class="btn btn-circle bg-dark mb-2" (click)="edit(customer.id)" *ngIf="viewAction && userAccess?.canEditCustomers" matTooltip="Edit" matTooltipPosition="left" matTooltipClass="bg-dark"> <i
            class="fas fa-pencil-alt fa-lg" style="color: white;"></i></a>
      <a (click)="goBack()" class="btn btn-circle" style="background-color: tomato;" *ngIf="viewAction || createAction" matTooltip="Exit" matTooltipPosition="left" matTooltipClass="bg-dark"><i
            class="fas fa-arrow-left  fa-2x"></i></a>
   </div>

</form>