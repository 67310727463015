import { CostTypeDetail } from "../../costsheet/cost-type-detail.model";


export class CostingSheet {
   id: string;
   orderDetailId: string;
   salesOrderId: string;
   productSkuCode: string;
   fullPackageOrder: boolean = false;
   sortOrder: number;
   approvalStatus: string;

   grossProfit: number;
   grossProfitPercentage: number;
   totalCost: number;
   totalRevenue: number;
   averageBlanksCost: number;
   totalPrintingCost: number;

   costTypeDetailList: CostTypeDetail[] = [];
   allocatedPrinterNames: string;
   allocatedBlankSupplierNames: string;
   printerCombId: string;
   supplierCombId: string;
   additionalNotes: string;

   constructor(id:string,printerCombId: string, supplierCombId: string) {
      this.id=id;
      this.printerCombId=printerCombId;
      this.supplierCombId=supplierCombId;
   }

}
