import { ExternalLinks } from "../../common/external-links.model";
import { Notes } from "../../common/notes.model";

export class TrimsInfo {

    id:string;
    salesOrderId:string;
    printerId:string;
    printerInfoId:string;
    trimsRequired:boolean;
    trimSupplier:string;
    trimTrackingNum:string;
    trimETA:Date;
    trimDeliveryStatus:string;
    trimTrackingLinks:ExternalLinks[]=[];

    fininishingSheetRequired:boolean;
    FinishingSheetSentDate:Date;

    boxLabelsRequired:boolean;
    boxLabelsArrangement:string;
    boxLabelsStatus:string;
    boxLabelsSentDate:Date;

    polyBagsRequired:boolean;
    polyBagsArrangement:string;
    polyBagsStatus:string;

    deleteFlag:boolean=false;
    origination:string;
    obsolete:boolean;
    version:number=0;

    boxLabelsInfoStatus:string;
	polyBagsInfoStatus:string;
	finishingSheetInfoStatus:string;
	trimInfoStatus:string;

    constructor(salesOrderId:string){
        this.origination='MANUAL';
        this.salesOrderId=salesOrderId;
    }
}

export class TrimsOrderTrackerDetailsSalesOrderGrouped {
    salesOrderId:string;
	salesOrderNumber:string;
	productionCoordinator:string;
	customer:string;
	customerPoNum:string;
	productionStatus:string;
	
	trackerDetailPrinterGroupedList:TrimsOrderTrackerDetailPrinterGrouped[]=[];
	trackerDetailPrinterGroupedListCount:number;
	salesTrimsOrderTrackerDetailsCount:number;
    //transient
    editable:boolean=false;
}

export class TrimsOrderTrackerDetailPrinterGrouped {
    printerName:string;
	printerPhilcosPoNum:string;
	printerId:string;
	printerInfoId:string;
	
	printerPoPlacementDate:Date;
	printerShipToAddress:string;
	totalPrintQty:number;
	
	trimsOrderTrackerDetails:TrimsOrderTrackerDetail[]=[];
	trimsOrderTrackerDetailsCount:number;
}

export class TrimsOrderTrackerDetail {
    id:string;
	salesOrderId:string;
	printerId:string;
	printerInfoId:string;
	
	trimSupplier:string;
	trimsOrderedQty:number;
	trimOrderedDate:Date;
	trims:string;
	trimsStatus:string;
	trimTrackingNum:string;
	trimInvoiceNum:string;
	
	trimsInvoiceValue:number;
	freightCharges:number;
	totalInvoiceValue:number;
	origination:string;
	
	trimDetails:TrimsOrderTrackerTrimsDetail[]=[];
	
	deleteFlag:boolean;
    
	version:number=0;
    
    lastModifiedDate:Date;
	lastModifiedBy:string;
}

export class TrimsOrderTrackerTrimsDetail {
    id:string;
	parentId:string;
	
	trimsOrderedQty:number;
	
	trim:string;
	trimStatus:string;
	deleteFlag:boolean;
    lastModifiedDate:Date;
	lastModifiedBy:string;
}

/*export class TrimsInfoView {
    
    id:string;
    salesOrderId:string;
    printerId:string;
    printerInfoId:string;
    trimsRequired:boolean;
    trimSupplier:string;
    trimTrackingNum:string;
    trimETA:Date;
    trimDeliveryStatus:string;
    trimTrackingLinks:ExternalLinks[]=[];

    fininishingSheetRequired:boolean;
    FinishingSheetSentDate:Date;

    boxLabelsRequired:boolean;
    boxLabelsArrangement:string;
    boxLabelsStatus:string;
    boxLabelsSentDate:Date;

    polyBagsRequired:boolean;
    polyBagsArrangement:string;
    polyBagsStatus:string;

    deleteFlag:boolean=false;
    origination:string;
    obsolete:boolean;
    version:number=0;

    boxLabelsInfoStatus:string;
	polyBagsInfoStatus:string;
	finishingSheetInfoStatus:string;
	trimInfoStatus:string;
    
}*/
