<form name="changepassword" (ngSubmit)="onFormSubmit()" #changePasswordForm="ngForm" autocomplete="off" class="form-signin">
<div class="container" style="display: flex;justify-content: center;align-items: center;height: 100vh;">
  <div class="card shadow" style="width: 30rem;">
    <div class="card-body">
      <div class="text-center mb-4">
        <img class="mb-4 round" src="{{'loginBrandIcon'|env}}" alt="Icon" style="border-radius: 50%;" width="136px" height="136px">
        <h2 class="h2 mb-2 font-weight-normal">Change Password</h2>
      </div>

        <div class="card-text">

          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-envelope"></i> </span>
            </div>
              <input name="email" id="email" [(ngModel)]="user.email" placeholder="Email"  class="form-control" #email="ngModel" 
              required [ngClass]="{'is-invalid':email.touched && email.invalid}" email>
              <div *ngIf="email?.invalid && (email.dirty || email.touched)" class="invalid-feedback">
                <div *ngIf="email.errors.required">
                  Email is required.
                </div>
                <div *ngIf="email.errors.email">
                  Email must be a valid email address.
                </div>
              </div>
          </div>


          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
            </div>
            <input type="password" [(ngModel)]="user.password" placeholder="Password" name="password" class="form-control" id="password"
              #password="ngModel" required minlength="3" maxlength="20" [ngClass]="{'is-invalid':password.touched && password.errors}">
            <div *ngIf="password?.invalid && (password.dirty || password.touched)" class="invalid-feedback">
              <div *ngIf="password.errors.required">
                Password is required.
              </div>
              <div *ngIf="password.errors.minlength">
                Password Minimum Length is 3
              </div>
            </div>
          </div>
  
              
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
            </div>
            <input type="password" [(ngModel)]="user.confirmpassword" placeholder="Confirm Password" name="confirmpassword" class="form-control" id="confirmpassword"
              #confirmpassword="ngModel" required minlength="3" maxlength="20" [ngClass]="{'is-invalid':confirmpassword.touched && confirmpassword.errors}">
            <div *ngIf="confirmpassword?.invalid && (confirmpassword.dirty || confirmpassword.touched)" class="invalid-feedback">
              <div *ngIf="confirmpassword.errors.required">
                Confirm Password is required.
              </div>
              <div *ngIf="confirmpassword.errors.minlength">
                Password Minimum Length is 3
              </div>
            </div>
          </div>

            
          <button class="btn btn-md btn-primary" [disabled]="changePasswordForm.invalid">Submit</button>
          <a [routerLink]="['/login']" class="btn btn-sm btn-link">Cancel</a>

        </div>
      </div>
    </div>
  </div>
  </form>