import { BlankSizeDetails } from "../blank-size-details.model";

export class BlankSummary {
    id:string;
	salesOrderId:string;
	
	printerName:string;
	supplierName:string;
	printerId:string;
	supplierId:string;
	
	blankSummaryDetail:BlankSummaryDetail;
	lastModifiedDate:Date;
	lastModifiedBy:string;
	submissionCount:number;
	costingApprovalCount:number;
	submissionApprovalCount:number;
	approvalReason:string;
	latest:boolean;

	createdOn:Date;
	createdBy:string;

	
}

export class BlankSummaryDetail {
    printerName:string;
	supplierName:string;
	printerId:string;
	supplierId:string;
	bsd:BlankSizeDetails[];
	supplierCurrency:string;
    totalQty:number;
	totalCost:number;

}

export class BlankSummaryView {
   	createdOn:Date;
	isCurrent:boolean;
	submissionCount:number;
	blankSummaries:BlankSummary[]=[];
	costingApprovalCount:number;
	submissionApprovalCount:number;
	approvalReason:string;
	latest:boolean;

}
