import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Notes } from 'src/app/shared/model/common/notes.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { NotesService } from 'src/app/shared/service/notes/notes.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {


  userAccessSubscription: Subscription;
  userAccess: UserAccess;
  currentLoggedInUserSubscription: Subscription;
  currentLoggedInUser: User;

  @Input() parentId: string;
  @Input() type: string;
  @Input() subType: string;
  @Input() editable: boolean;
  @Input() notesList: Notes[];
  @Input() notesHeaderText: string;
  @Input() notesHeaderSubText1: string;
  @Input() notesHeaderSubText2: string;
  @Input() vh: number=40;
  @Input() notesSubject: string;
  // local ng model
  description: string;
  @ViewChild('notesScrollSection') private notesScrollSectionRef: ElementRef;
  constructor(private notesService: NotesService, private toastrService: ToastrService, private dialog: MatDialog, private authService: AuthService) { }

  ngOnInit(): void {
    this.handleSubscriptions();
    this.getNotesList();
  }

  

  ngAfterViewChecked() {        
      this.scrollToBottom();        
  } 

scrollToBottom(): void {
    try {
        this.notesScrollSectionRef.nativeElement.scrollTop = this.notesScrollSectionRef.nativeElement.scrollHeight;
    } catch(err) { }                 
}
  handleSubscriptions() {
    this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
      this.userAccess = access;
    });
    this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
      this.currentLoggedInUser = currentLoggedInUser;
    });
  }

  getNotesList() {
    this.notesService.getNotesList(this.parentId,this.type,this.subType).subscribe(response => {
      if (response.responseStatus.status === 'SUCCESS') {
        this.notesList = response.responsePayload;
        this.scrollToBottom();
      } else {
        this.toastrService.error(response.responseStatus.message);
      }
    }, err => {
      console.error('Error while retrieving Notes : ' + JSON.stringify(err) + ' with status - ');
    });
  }
  createNotes(): Notes {
    let newNotes: Notes = new Notes;
    newNotes.createdDate = new Date;
    newNotes.parentId = this.parentId;
    newNotes.createdBy = this.currentLoggedInUser.fullName;
    newNotes.userName = this.currentLoggedInUser.userName;
    newNotes.type = this.type;
    newNotes.subType = this.subType;
    newNotes.description = this.description;
    newNotes.subject = this.notesSubject;
    return newNotes;
  }

  addNotes() {
    let createdNotes: Notes = this.createNotes();
    this.notesList.push(createdNotes);
    //this.sortListByCreatedDateDesc(this.notesList, 'createdDate');
    this.description = null;
    this.saveNotesList();
  }

  deleteNotes(notes: Notes) {
    this.notesList = this.notesList.filter(item => item !== notes);
  }

  ok() {
    if (this.checkIfUnsavedNotes()) {
      this.saveNotesList();
    }
    this.close();
  }

  cancel() {
    if (this.checkIfUnsavedNotes()) {
      this.unsavedNotesWarningDialog();
    } else {
      this.close();
    }
  }

  close() {
    /* this.dialogRef.close({
        data: {
           notesList: this.notesList
        }
     });*/
  }

  saveNotesList() {
    this.notesService.saveNotesList(this.notesList,this.parentId).subscribe(response => {
      if (response.responseStatus.status === 'SUCCESS') {
        this.toastrService.success(response.responseStatus.message);
        this.getNotesList();
      } else {
        this.toastrService.error(response.responseStatus.message);
      }
    }, err => {
      console.error('Error while Saving Notes : ' + JSON.stringify(err) + ' with status - ');
    });
  }

  sortListByCreatedDateDesc(list: any, propertyName: string): [] {
    if (propertyName && list && list != null) {
      let sortedList: [] = list.sort(function (a, b) {
        if (a[propertyName] != null && a[propertyName] != undefined && b[propertyName] != null && b[propertyName] != undefined) {
          const propertyValue = a[propertyName];
          if (propertyValue instanceof Date) {
            const dateA: Date = new Date(a[propertyName]);
            const dateB: Date = new Date(b[propertyName]);
            return (dateB.getTime() - dateA.getTime());
          }
        }
      });
      return sortedList;
    }
    return list;
  }

  checkIfUnsavedNotes(): boolean {
    if (this.notesList !== null && this.notesList !== undefined) {
      for (let index = 0; index < this.notesList.length; index++) {
        let notes: Notes = this.notesList[index];
        if (notes.id === undefined || notes.id === null) {
          return true;
        }
      }
    }
    return false;
  }

  unsavedNotesWarningDialog() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        title: 'Unsaved Notes Warning!',
        message: 'Recently added notes have not been saved. Are you sure you want to cancel ?',
        confirmBtnText: 'Yes',
        cancelBtnText: 'No'
      }
    });
    confirmDialog.afterClosed().subscribe(res => {
      if (res) {
        // this.dialogRef.close();
      }
    });
  }

  togglePriority(notes: Notes) {
    if (notes.priority === 10) {
      notes.priority = 1;
    } else {
      notes.priority = 10;
    }
    this.notesService.updateNotes(notes).subscribe(response => {
      if (response.responseStatus.status === 'SUCCESS') {
        //this.toastrService.success(response.responseStatus.message);
        //this.getNotesList();
      } else {
        //this.toastrService.error(response.responseStatus.message);
      }
    }, err => {
      console.error('Error while Saving Notes : ' + JSON.stringify(err) + ' with status - ');
    });
  }
  
  getEnterCount(str: string, max: number,defaultCount:number) {
    if (str && str !== null) {
      let rowCount = str.split(/\r\n|\r|\n/).length;
      if (rowCount > max) {
        return max;
      } else {
        return rowCount;
      }
    } else {
      return defaultCount;
    }
  }
}
