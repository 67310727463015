<div *ngIf="product && product.printerMatrix">
   <!-- <div class="row">
        <ng-container *ngFor="let item of attributes;index as index">
            <div class="form-group col-lg-3 mb-0 pr-1">
                <label class="f-11 mb-0 text-muted  pl-2">{{item.label}}</label>
                <div class="" *ngIf="item.label!==''">
                    <div class="input-group" *ngIf="item.type!=='textArea'">
                        <input id="{{index}}" name="{{item.label}}" [(ngModel)]="product.printerMatrix[item.source]" type="search"
                            class="form-control form-control-sm form-control-cust-mat font-weight-bold pt-0" #itemVar="ngModel" [disabled]="!editable"
                            [ngClass]="{'is-invalid':itemVar.touched && itemVar.errors}" [style]="editable?'background-color: white;':'background-color: white;cursor: not-allowed;'" 
                            maxlength="25">
                    </div>
                    <div class="input-group" *ngIf="item.type==='textArea'">
                        
                        <textarea name="{{item.label}}-{{index}}" [(ngModel)]="product.printerMatrix[item.source]"
                        rows="{{(product.printerMatrix[item.source]?.length / 20)>2?(product.printerMatrix[item.source]?.length / 20):2}}"
                            class="form-control form-control-sm form-control-cust-mat pt-0 font-weight-bold"  #itemVar="ngModel" [disabled]="!editable"
                            [ngClass]="{'is-invalid':itemVar.touched && itemVar.errors}" [style]="editable?'background-color: white;':'background-color: white;cursor: not-allowed;'" 
                            maxlength="500">
                      </textarea>
                    </div>
                </div>
            </div>
            
        </ng-container>
    </div>-->
    <div class="border-box">
        <div class="ml-2 mt-3">
            <div class="row">
                <ng-container *ngFor="let item of attributes;index as index">
                            <div class="col-lg-6 mb-1">
                                <div class="row input-group">
                                    <div class="col-4 pr-1">
                                        <span class=" f-13 align-middle text-muted">{{item.label}}</span>
                                    </div>
                                    <div class="col-8" *ngIf="item.label!=='' && item.type!=='textArea'">
                                        <input id="{{index}}" name="{{item.label}}" [(ngModel)]="product.printerMatrix[item.source]" type="search"
                                            class="form-control form-control-sm" #itemVar="ngModel" [disabled]="!editable"
                                            [ngClass]="{'is-invalid':itemVar.touched && itemVar.errors}"
                                            maxlength="25">
                                    </div>
                                    <div class="col-8" *ngIf="item.type==='textArea'">
                                        <textarea name="{{item.label}}-{{index}}" [(ngModel)]="product.printerMatrix[item.source]"
                                        rows="{{(product.printerMatrix[item.source]?.length / 15)>2?(product.printerMatrix[item.source]?.length / 15):2}}"
                                            class="form-control form-control-sm"  #itemVar="ngModel" [disabled]="!editable"
                                            [ngClass]="{'is-invalid':itemVar.touched && itemVar.errors}"
                                            maxlength="500">
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                </ng-container>
            </div>
            <hr class="my-1">
            <div class="row">
                <div class="col-lg-6 text-muted d-flex justify-content-center"><h6 class="font-weight-bold">Colours - Front</h6></div>
                <div class="col-lg-6 text-muted d-flex justify-content-center"><h6 class="font-weight-bold">Colours - Back/Sleeve</h6></div>
            </div>
            <hr class="mt-1 mb-2">
            <div class="row">
            <ng-container *ngFor="let item of colorAttributes;index as index">
                        <div class="col-lg-6 mb-1">
                            <div class="row input-group">
                                <div class="col-4 pr-1">
                                    <span class="f-13 align-middle text-muted">{{item.label}}</span>
                                </div>
                                <div class="col-8" *ngIf="item.label!=='' && item.type!=='textArea'">
                                    <input id="{{index}}" name="{{item.label}}" [(ngModel)]="product.printerMatrix[item.source]" type="search"
                                        class="form-control form-control-sm" #itemVar="ngModel" [disabled]="!editable"
                                        [ngClass]="{'is-invalid':itemVar.touched && itemVar.errors}"
                                        maxlength="25">
                                </div>
                            </div>
                        </div>
            </ng-container>
            </div>
        
        </div>
    </div>
   
</div>