<form #rolePermissionsForm="ngForm">
   <div class="container">

      <div class="row mt-2 d-flex justify-content-center">
         <h2 class="d-inline">Role and Permissions</h2>
      </div>
      <hr>

      <div class="mt-4 shadow-lg p-3 mb-5 bg-white rounded">

         <!-- Start: ROLE Section -->
         <div class="row mb-4 mt-4">
            <div class="col">
               <fieldset class="border p-2">
                  <legend class="fieldset-border">Role</legend>
                  <div class="row form-group">
                     <div class="col-sm-2"><label class="col-form-label">Role ID</label></div>
                     <div class="col-sm-10">
                        <input name="roleId" [(ngModel)]="selectedRole.roleId" class="form-control form-control"
                           required #roleIdVar="ngModel" [disabled]="true">
                     </div>
                  </div>
                  <div class="row form-group">
                     <div class="col-sm-2"><label class="col-form-label">Role Description</label></div>
                     <div class="col-sm-10">
                        <input name="roleDescription" [(ngModel)]="selectedRole.roleDescription"
                           class="form-control" placeholder="Enter Role Description..."
                           required #roleDescriptionVar="ngModel" required [disabled]="!editable" oninput="this.value = this.value.toUpperCase()"
                           [ngClass]="{'is-invalid':roleDescriptionVar.touched && roleDescriptionVar.errors}">
                        <div
                           *ngIf="roleDescriptionVar?.invalid && (roleDescriptionVar.dirty || roleDescriptionVar.touched)"
                           class="invalid-feedback">
                           <div *ngIf="roleDescriptionVar.errors.required">Role Description is required.</div>
                        </div>
                     </div>
                  </div>
               </fieldset>
            </div>
         </div>
         <!-- End: ROLE Section -->

         <!-- Start: PERMISSIONS Section -->
         <div class="row">

            <div class="col-6 d-flex justify-content-center">
               <div cdkDropList #selectedList="cdkDropList" [cdkDropListData]="selectedRole?.rolePermissions"
                  [cdkDropListConnectedTo]="unselectedList" class="example-list" (cdkDropListDropped)="drop($event)"
                  [cdkDropListDisabled]="!editable">
                  <div class="card" style="width: 32rem;">
                     <div class="card-header text-center" style="background-color:#1BDDB1; padding:0.5rem">
                        <!-- #32B0FC-->
                        <h5 class="card-title text-white text-align-justify">Selected Permissions</h5>
                     </div>
                     <ul class="list-group list-group-flush"
                        *ngFor="let selectedPermission of selectedRole?.rolePermissions; index as counter;" cdkDrag>
                        <li class="list-group-item ml-2" style="padding: .30rem;">
                           <div class="row form-group mb-1 mt-1" style="width: 100%;">
                              <div class="col-sm-8">
                                 <span><i class="fas fa-check-circle fa-sm"
                                       style="color:#FF6347;"></i>&nbsp;&nbsp;{{selectedPermission.securityDescription}}</span>
                              </div>
                              <div class="col-sm-2">
                                 <svg width="24px" fill="lightgray" viewBox="0 0 24 24" *ngIf="editable">
                                    <path
                                       d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                    </path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                 </svg>
                              </div>
                              <div class="col-sm-1">
                                 <span class="btn btn-sm btn-warning rounded-circle ml-2 mr-2" style="cursor: pointer;"
                                    (click)="addOrEditPermission(selectedPermission)" *ngIf="editable"><i
                                       class="fas fa-pencil-alt"></i></span>
                              </div>
                              <div class="col-sm-1">
                                 <span class="btn btn-sm btn-warning rounded-circle ml-2 mr-2" style="cursor: pointer;"
                                    (click)="deletePermission(selectedPermission)" *ngIf="false"><i
                                       class="fas fa-trash fa-1x"></i></span>
                              </div>
                           </div>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>

            <div class="col-6 d-flex justify-content-center">
               <div cdkDropList #unselectedList="cdkDropList" [cdkDropListData]="unselectedPermissionsList"
                  [cdkDropListConnectedTo]="selectedList" class="example-list" (cdkDropListDropped)="drop($event)"
                  [cdkDropListDisabled]="!editable">
                  <div class="card" style="width: 32rem;">
                     <div class="card-header text-center" style="background-color:#32B0FC; padding:0.5rem">
                        <h5 class="card-title text-white">Available Permissions
                           <button type="button" (click)="addOrEditPermission(null)"
                              class="btn btn-circle btn-circle-sm d-inline ml-2" *ngIf="editable && userAccess?.isSupportAdmin"
                              matTooltip="Create New Permission" matTooltipPosition="right" matTooltipClass="bg-dark">
                              <i class="fas fa-plus fa-1x text-light"></i>
                           </button></h5>
                     </div>
                     <ul class="list-group list-group-flush"
                        *ngFor="let unselectedPermission of unselectedPermissionsList; index as i;" cdkDrag>
                        <li class="list-group-item ml-2" style="padding: .30rem;">
                           <div class="row form-group mb-1 mt-1" style="width: 100%;">
                              <div class="col-sm-8">
                                 <span><i class="fas fa-circle fa-sm"
                                       style="color:lightgray;"></i>&nbsp;&nbsp;{{unselectedPermission.securityDescription}}</span>
                              </div>
                              <div class="col-sm-2">
                                 <svg width="24px" fill="lightgray" viewBox="0 0 24 24" *ngIf="editable">
                                    <path
                                       d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                    </path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                 </svg>
                              </div>
                              <div class="col-sm-1">
                                 <span class="btn btn-sm btn-warning rounded-circle ml-2 mr-2" style="cursor: pointer;"
                                    (click)="addOrEditPermission(unselectedPermission)" *ngIf="editable && userAccess?.isSupportAdmin"><i
                                       class="fas fa-pencil-alt"></i></span>
                              </div>
                              <div class="col-sm-1">
                                 <span class="btn btn-sm btn-danger rounded-circle ml-2 mr-2" style="cursor: pointer;"
                                    (click)="deletePermission(unselectedPermission)" *ngIf="editable && userAccess?.isSupportAdmin"><i
                                       class="fas fa-trash fa-1x"></i></span>
                              </div>
                           </div>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>

         <!-- END: PERMISSIONS Section -->

      </div>

   </div>
</form>


<!--  MODAL for Add Edit Permissions  -->

<div class="modal fade" id="addEditPermissionModal" data-keyboard="false" tabindex="-1"
   role="dialog" data-focus-on="input:first" aria-labelledby="addEditPermissionModalTitle" aria-hidden="true">
   <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
         <div class="modal-header" style="background-color: #f7f7f7;">
            <h6 class="modal-title" id="addEditPermissionModalLongTitle" style="font-weight: bold;">Add/Edit New
               Permission</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
               (click)="closeAddEditPermissionModal()">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <form #addEditPermissionForm="ngForm">
               <div class="form-group">
                  <label class="col-form-label">Security ID</label>
                  <input name="securityId" class="form-control mb-2" type="text" #securityIdVar="ngModel" required
                     placeholder="Enter Security ID..." [(ngModel)]="newOrSelectedPermission.securityId"
                     [ngClass]="{'is-invalid':securityIdVar.touched && securityIdVar.errors}">
                  <div
                     *ngIf="securityIdVar?.invalid && (securityIdVar.dirty || securityIdVar.touched)"
                     class="invalid-feedback">
                     <div *ngIf="securityIdVar.errors.required">Security ID is required.</div>
                  </div>
               </div>
               <div class="form-group">
                  <label class="col-form-label">Security Description</label>
                  <input name="securityDescription" class="form-control mb-2" type="text" #securityDescriptionVar="ngModel" required
                     placeholder="Enter Security Description..."
                     [(ngModel)]="newOrSelectedPermission.securityDescription"
                     [ngClass]="{'is-invalid':securityDescriptionVar.touched && securityDescriptionVar.errors}">
                  <div
                     *ngIf="securityDescriptionVar?.invalid && (securityDescriptionVar.dirty || securityDescriptionVar.touched)"
                     class="invalid-feedback">
                     <div *ngIf="securityDescriptionVar.errors.required">Security Description is required.</div>
                  </div>
               </div>
            </form>
         </div>
         <div class="modal-footer" style="background-color: #f7f7f7;">
            <button type="button" id="addEditPermissionModalCloseButton" class="btn btn-secondary btn-sm"
               data-dismiss="modal" (click)="closeAddEditPermissionModal()">Close</button>
            <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal"
               [disabled]="!addEditPermissionForm.valid"
               (click)="createOrUpdatePermission(newOrSelectedPermission)">Save</button>
         </div>
      </div>
   </div>
</div>

<button id="addEditPermissionModalToggler" type="button" style="display: none;" data-toggle="modal"
   data-target="#addEditPermissionModal"></button>


<!-- Floating Buttons -->
<div class="floatBtn d-flex flex-column">
   <button type="button" class="btn btn-circle bg-info mb-2" *ngIf="editable" matTooltip="Save Changes"
      matTooltipPosition="left" matTooltipClass="bg-dark" (click)="updateRoleWithSelectedPermissions()"
      [disabled]="!rolePermissionsForm.valid">
      <i class="fas fa-save fa-2x" style="color:white"></i>
   </button>
   <button type="button" class="btn btn-circle bg-dark mb-2" *ngIf="editable" matTooltip="Cancel Edit"
      matTooltipPosition="left" matTooltipClass="bg-dark" (click)="cancelEdit()"><i class="fas fa-times fa-2x"
         style="color:white"></i>
   </button>
   <a class="btn btn-circle bg-dark mb-2" (click)="edit()" *ngIf="!editable && userAccess?.isSupportAdmin"
      matTooltip="Edit" matTooltipPosition="left" matTooltipClass="bg-dark">
      <i class="fas fa-pencil-alt fa-lg" style="color: white;"></i>
   </a>
   <a (click)="goBack()" class="btn btn-circle" style="background-color: tomato;" matTooltip="Exit"
      matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-arrow-left  fa-2x"></i></a>
</div>