import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

@Component({
   selector: 'app-redirect',
   templateUrl: './redirect.component.html',
   styleUrls: ['./redirect.component.css']
})

export class RedirectComponent implements OnInit {
   constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

   ngOnInit(): void {
      let redirectFullPath = this.activatedRoute.snapshot.paramMap.get('redirectFullPath');
      if (redirectFullPath !== null && redirectFullPath !== undefined) {
        // let destinationUrl = redirectFullPath.replace('~', '/');
        let destinationUrl = redirectFullPath.split('~').join('/');
        this.router.navigate([destinationUrl]);
      } else {
         console.error('Problem with redirect URL. Sending back to Login Page..', redirectFullPath);
         this.router.navigate(['login']);
      }
   }
}

/*
   //this.routeParamReportType = this.activatedRoute.snapshot.paramMap.get('fullPathToReDirect');
   //this.router.navigate(['report/' + this.routeParamReportType]);
   let url: string = this.router.url;
   let redirectUrl = url.substr(10, url.length);
   
         this.router.events.subscribe(event => {
         if (event instanceof NavigationEnd) {
         };
      });

*/