export class PrinterMatrix {
    frontWidth:string;
    frontHeight:string;

    backWidth:string;
    backHeight:string;

    comments:string = '2" FROM COLLAR SEAM. SOFTHAND FEEL';
    pnlCode:string ='SEE REQ';

    frontColor1:string;
    frontColor2:string;
    frontColor3:string;
    frontColor4:string;
    frontColor5:string;
    frontColor6:string;
    frontColor7:string;
    frontColor8:string;
    frontColor9:string;
    frontColor10:string;
    frontColor11:string;
    frontColor12:string;
    frontColor13:string;

    backColor1:string;
    backColor2:string;
    backColor3:string;
    backColor4:string;
    backColor5:string;
    backColor6:string;
    backColor7:string;
    backColor8:string;
    backColor9:string;
    backColor10:string;
    backColor11:string;
    backColor12:string;
    backColor13:string;
    id:string;
	parentId:string;
}
