export class Notes {
   id: string;
   parentId: string;
   createdDate: Date;
   createdBy: string;
   description: string;
   deleteFlag: boolean;
   userName:string;
   type:string;
   subType:string;
   version:number;
   priority:number;
   subject: string;
   emailRecipients: string;
}

