import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExternalLinks } from 'src/app/shared/model/common/external-links.model';

@Component({
  selector: 'app-tracking-link-modal',
  templateUrl: './tracking-link-modal.component.html',
  styleUrls: ['./tracking-link-modal.component.css']
})
export class TrackingLinkModalComponent implements OnInit {
  editable:boolean;
  type:string;
  subType:string;
  linksArray:ExternalLinks[];
  constructor(public dialogRef: MatDialogRef<TrackingLinkModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    if(data.linksArray){
      this.linksArray=data.linksArray;
    }
    if(data.editable){
      this.editable=data.editable;
    }
    if(data.type){
      this.type=data.type;
    }
    if(data.subType){
      this.subType=data.subType;
    }
  }

  ngOnInit(): void {
  }

  save() {
    this.dialogRef.close(true);
  }
  close() {
    this.dialogRef.close(false);
  }

  discardModalWithNoAction(){
   this.dialogRef.close('NO_ACTION');
  }
  addLinkItem(){
    if(this.linksArray){
      this.linksArray.push(new ExternalLinks(this.type,this.subType));
    }
  }
}
