<ng-container *ngIf="dashletDetails">
   <div class="card table-within-dashlet" ><!--style=" width: fit-content;"-->
      <div class="d-flex justify-content-between mt-1 px-2">
         <span (click)="dashletDetails.chart.tabular=!dashletDetails.chart.tabular;">
            <div *ngIf="dashletDetails.chart.tabular"><i class="far fa-chart-bar"></i></div>
            <div *ngIf="!dashletDetails.chart.tabular"><i class="fas fa-th"></i></div>
         </span>
         <div class="d-flex justify-content-center">{{dashletDetails.chart.title}}</div>
         <div>&nbsp;</div>
      </div>

      <google-chart [title]="dashletDetails.chart.title" (ready)="onReady($event)" (select)="onSelect(dashletDetails.chartData,$event)"
         [type]="dashletDetails.chart.tabular?'Table':dashletDetails.chart.type" [style]="dashletDetails.chart.tabular?'overflow-y:auto':''" [data]="dashletDetails.chartData" [columns]="dashletDetails.chart.columnNames"
         [options]="dashletDetails.chart.options" >
      </google-chart>
   </div>
</ng-container>