<form name="blanks" (ngSubmit)="onSubmit()" #blanksForm="ngForm" autocomplete="off" novalidate class="mb-3">

   <div class="container">

      <div class="d-flex justify-content-center mt-3">
         <div class="text-color-{{blanks.status+'' | replace:' ':'_'|lowercase}}">
            <h2>{{blanks.skuCode}}</h2>
         </div>
         <div></div>
      </div>
      <div *ngIf="blanks.status!==undefined" class="separator-{{blanks.status+'' | replace:' ':'_'|lowercase}}">
         <h6>{{blanks.status?'Active':'Inactive'}}</h6>
      </div>


      <div class="row  mt-4 shadow-lg p-3 mb-5 bg-white rounded">
         <div class="col-lg-12">
            <mat-tab-group>

               <mat-tab>
                  <ng-template mat-tab-label>
                     <h6>Blanks <span class="ml-2 font-weight-normal"></span></h6>
                  </ng-template>
                  <div class="overflow-hidden">
                     <!-- TAB Blanks DETAILS -->
                     <div class="row mt-4 ml-10">

                        <div class="col-lg-3">
                           <div class="form-group">
                              <label>SKU Code</label>
                              <input name="skuCode" [(ngModel)]="blanks.skuCode" class="form-control form-control-sm" #skuCode="ngModel" required [disabled]="!editable"
                                 [ngClass]="{'is-invalid':skuCode.touched && skuCode.errors}" maxlength="40">
                              <div *ngIf="skuCode?.invalid && (skuCode.dirty || skuCode.touched)" class="invalid-feedback">
                                 <div *ngIf="skuCode.errors.required">
                                    Sku Code is required.
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="col-lg-5">
                           <div class="form-group">
                              <label>Description</label>
                              <input name="blanksDescription" [(ngModel)]="blanks.description" class="form-control form-control-sm" #blanksDescription="ngModel" [disabled]="!editable" maxlength="50">
                           </div>
                        </div>

                        <div class="col-lg-2">
                           <div class="form-group">
                              <label>Supplier</label>
                              <input name="supplier" [(ngModel)]="blanks.supplier" class="form-control form-control-sm" #supplier="ngModel" required [disabled]="!editable"
                                 [ngClass]="{'is-invalid':supplier.touched && supplier.errors}" maxlength="40">
                              <div *ngIf="supplier?.invalid && (supplier.dirty || supplier.touched)" class="invalid-feedback">
                                 <div *ngIf="supplier.errors.required">
                                    Supplier is required.
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="col-lg-2">
                           <div class="form-group">
                              <label>Status</label>
                              <select class="form-control form-control-sm" [(ngModel)]="blanks.status" name="blanksStatus" #blanksStatus="ngModel" required [disabled]="!editable"
                                 [ngClass]="{'is-invalid':blanksStatus.touched && blanksStatus.errors}">
                                 <option value=false>Inactive</option>
                                 <option value=true>Active</option>
                              </select>
                              <div *ngIf="blanksStatus?.invalid && (blanksStatus.dirty || blanksStatus.touched)" class="invalid-feedback">
                                 <div *ngIf="blanksStatus.errors.required">
                                    Blanks Status is required.
                                 </div>
                              </div>
                           </div>
                        </div>

                     </div>


                     <div class="row mt-4 ml-10">

                        <div class="col-lg-3">
                           <div class="form-group">
                              <label>Fabric Content</label>
                              <input name="fabricContent" [(ngModel)]="blanks.fabricContent" class="form-control form-control-sm" #fabricContent="ngModel" required [disabled]="!editable"
                                 [ngClass]="{'is-invalid':fabricContent.touched && fabricContent.errors}" maxlength="40">
                              <div *ngIf="fabricContent?.invalid && (fabricContent.dirty || fabricContent.touched)" class="invalid-feedback">
                                 <div *ngIf="fabricContent.errors.required">
                                    Fabric Content is required.
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="col-lg-3">
                           <div class="form-group">
                              <label>GSM</label>
                              <input name="gsm" [(ngModel)]="blanks.gsm" class="form-control form-control-sm" #gsm="ngModel" required [disabled]="!editable"
                                 [ngClass]="{'is-invalid':gsm.touched && gsm.errors}" maxlength="40">
                              <div *ngIf="gsm?.invalid && (gsm.dirty || gsm.touched)" class="invalid-feedback">
                                 <div *ngIf="gsm.errors.required">
                                    GSM is required.
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="col-lg-6">
                           <div class="form-group">
                              <label>Notes</label>
                              <textarea name="blanksNotes" [(ngModel)]="blanks.notes" class="form-control form-control-sm" rows="{{(blanks?.notes?.length / 25)>2?(blanks?.notes?.length / 25):2}}"
                                 #blanksNotes="ngModel" [disabled]="!editable" style="white-space: pre-wrap;" maxlength="250"></textarea>
                           </div>
                        </div>

                     </div>

                  </div>
               </mat-tab>
            </mat-tab-group>

         </div>

      </div>

      <div class="row d-flex justify-content-end">
         <div class="col-lg-3">
            <div class="form-group ml-0">
               <button type="submit" class="btn btn-info btn-block" [disabled]="!blanksForm.valid" *ngIf="editable && createAction">Submit</button>
            </div>
         </div>
      </div>

   </div>

   <div class="floatBtn d-flex flex-column">
      <button type="submit" class=" btn btn-circle bg-info mb-2" *ngIf="editable" matTooltip="Save Changes" matTooltipPosition="left" matTooltipClass="bg-dark" [disabled]="blanksForm.invalid"><i
            class="fas fa-save fa-2x" style="color:white"></i>
      </button>
      <a rel="noopener" (click)="cancelCreateOrUpdateBlanks(blanks)" class=" btn btn-circle bg-dark mb-2" *ngIf="editable" matTooltip="Cancel Edit" matTooltipPosition="left"
         matTooltipClass="bg-dark"><i class="fas fa-times fa-2x" style="color:white"></i></a>
      <a class="btn btn-circle bg-dark mb-2" (click)="editBlanks(blanks)" *ngIf="!editable && userAccess?.canEditBlanks" matTooltip="Edit" matTooltipPosition="left" matTooltipClass="bg-dark"> <i
            class="fas fa-pencil-alt fa-lg" style="color: white;"></i></a>
      <a (click)="goBack()" class="btn btn-circle" style="background-color: tomato;" *ngIf="!editable" matTooltip="Exit" matTooltipPosition="left" matTooltipClass="bg-dark"><i
            class="fas fa-arrow-left  fa-2x"></i></a>
   </div>

</form>