import { Brand } from './brand.model';

export class Profile {
   id: string;
   title: string;
   firstName: string;
   middleName: string;
   lastName: string;
   dateOfBirth: Date;
   contactDetailsType:string;
   jobTitle: string;
   submissionSheetReviewer: boolean;
   emailRecipient:string;
   contactDetailsTypeList: string[];
   //brandId: string;
   //Transient
   associatedBrands: Brand[];
   constructor(){
      this.id=null;
      this.title=null;
      this.firstName=null
      this.middleName=null;
      this.lastName=null;
      this.dateOfBirth=null;
      this.contactDetailsType=null;
      this.jobTitle=null;
      this.emailRecipient='TO';
      this.contactDetailsTypeList = [];
      //this.brandName=null;
      //this.brandId=null;
      this.associatedBrands=[];
   }
}
