import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { Observable } from "rxjs/index";
import { environment } from 'src/environments/environment';
import { Metadata, MetadataTemplate } from 'src/app/shared/model/metadata.model';
import { ToastrService } from 'ngx-toastr';

@Injectable({
   providedIn: 'root'
})
export class MetadataService {
   readonly baseUrl = environment.apiEndPoint;
   constructor(private http: HttpClient, private toastrService: ToastrService) { }

   createMetadata(metadata: Metadata): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/metadata/create', metadata);
   }

   /*************************METADATA OBJECT LIST *************************/
   getMetadataListByClassification(metadataClassification: string): Observable<ApiResponse> {
      return this.http.get<ApiResponse>(this.baseUrl + '/metadata/list/classification/' + metadataClassification);
   }

   getMetadataListByType(metadataType: string): Observable<ApiResponse> {
      return this.http.get<ApiResponse>(this.baseUrl + '/metadata/list/type/' + metadataType);
   }

   getMetadataListBySubType(metadataSubType: string): Observable<ApiResponse> {
      return this.http.get<ApiResponse>(this.baseUrl + '/metadata/list/subType/' + metadataSubType);
   }

   getMetadataListByClassificationAndType(metadataClassification: string, metadataType: string) {
      return this.http.get<ApiResponse>(this.baseUrl + '/metadata/list/classification/' + metadataClassification + '/type/' + metadataType);
   }

   getMetadataListByTypeAndSubType(metadataType: string, metadataSubType: string) {
      return this.http.get<ApiResponse>(this.baseUrl + '/metadata/list/type/' + metadataType + '/subType/' + metadataSubType);
   }

   getMetadataListByClassificationAndTypeAndSubType(metadataClassification: string, metadataType: string, metadataSubType: string) {
      return this.http.get<ApiResponse>(this.baseUrl + '/metadata/list/classification/' + metadataClassification + '/type/' + metadataType + '/subType/' + metadataSubType);
   }

   /*************************METADATA VALUE LIST *************************/
   getMetadataValueListByClassification(metadataClassification: string): Observable<ApiResponse> {
      return this.http.get<ApiResponse>(this.baseUrl + '/metadata/list/value/classification/' + metadataClassification);
   }

   getMetadataValueListByType(metadataType: string): Observable<ApiResponse> {
      return this.http.get<ApiResponse>(this.baseUrl + '/metadata/list/value/type/' + metadataType);
   }

   getMetadataValueListBySubType(metadataSubType: string): Observable<ApiResponse> {
      return this.http.get<ApiResponse>(this.baseUrl + '/metadata/list/value/type/' + metadataSubType);
   }

   getMetadataValueListByClassificationAndType(metadataClassification: string, metadataType: string) {
      return this.http.get<ApiResponse>(this.baseUrl + '/metadata/list/value/classification/' + metadataClassification + '/type/' + metadataType);
   }

   getMetadataValueListByTypeAndSubType(metadataType: string, metadataSubType: string) {
      return this.http.get<ApiResponse>(this.baseUrl + '/metadata/list/value/type/' + metadataType + '/subType/' + metadataSubType);
   }

   getMetadataValueListByClassificationAndTypeAndSubType(metadataClassification: string, metadataType: string, metadataSubType: string) {
      return this.http.get<ApiResponse>(this.baseUrl + '/metadata/list/value/classification/' + metadataClassification + '/type/' + metadataType + '/subType/' + metadataSubType);
   }

   /**
    * 
    * @param metadataType 
    * @param metadataSubType 
    * @param metadataValue 
    */
   saveMetadata(metadataType, metadataSubType, metadataValue) {
      //
      let metadata = new Metadata();
      metadata.metadataType = metadataType.toUpperCase();
      metadata.metadataSubType = metadataSubType;
      metadata.metadataValue = metadataValue.toUpperCase();
      this.createMetadata(metadata).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
      });
   }

   getMetadataTemplateList(): Observable<ApiResponse> {
      return this.http.get<ApiResponse>(this.baseUrl + '/metadata/metadataTemplate/list');
   }

   updateMetadataList(metadataList: Metadata[]): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/metadata/update', metadataList);
   }

   deleteMetadata(metadataId: string){
      return this.http.post<ApiResponse>(this.baseUrl + '/metadata/delete/'+metadataId,null);
   }
   
   updateMetadataTemplateList(metadataTemplateList: MetadataTemplate[]): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/metadata/metadataTemplate/update', metadataTemplateList);
   }

   createMetadataTemplate(metadataTemplate: MetadataTemplate): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/metadata/metadataTemplate/create', metadataTemplate);
   }

   deleteMetadataTemplate(metadataTemplateId: string){
      return this.http.post<ApiResponse>(this.baseUrl + '/metadata/metadataTemplate/delete/'+metadataTemplateId,null);
   }
   
   /*

   getMetadataClassificationAliases(): Observable<ApiResponse> {
      return this.http.get<ApiResponse>(this.baseUrl + '/metadata/list/metadataClassificationAliases');
   }

   getMetadataTypeAliases(): Observable<ApiResponse> {
      return this.http.get<ApiResponse>(this.baseUrl + '/metadata/list/metadataTypeAliases');
   }

   getMetadataTypeAliasesForClassificationAlias(metadataClassificationAlias: string): Observable<ApiResponse> {
      return this.http.get<ApiResponse>(this.baseUrl + '/metadata/list/metadataTypeAliases/metadataClassificationAlias/'+metadataClassificationAlias);
   }   

   getMetadataListByMetadataClassificationAliasAndMetadaTypeAlias(metadataClassificationAlias: string,metadataTypeAlias: string) {
      return this.http.get<ApiResponse>(this.baseUrl + '/metadata/list/metadataClassificationAlias/'+metadataClassificationAlias+'/metadataTypeAlias/'+metadataTypeAlias);
   }

   */

}
