import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ChargeBack, ChargeBackVendor } from 'src/app/shared/model/accounts/charge-back.model';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { SearchService } from 'src/app/shared/service/search.service';
import { SharedService } from 'src/app/shared/service/shared.service';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { ProductionTrackerService } from 'src/app/shared/service/sales/fulfillment/production-tracker.service';
import { ProductionTracker } from 'src/app/shared/model/sales/fulfillment/production-tracker.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ChargeBackService } from 'src/app/shared/service/accounts/charge-back.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FileHandlingService } from 'src/app/shared/service/file-handling.service';

@Component({
   selector: 'app-charge-back',
   templateUrl: './charge-back.component.html',
   styleUrls: ['./charge-back.component.css']
})
export class ChargeBackComponent implements OnInit {

   constructor(private authService: AuthService, private searchService: SearchService, private sharedService: SharedService, private metadataService: MetadataService, private location: Location,
      private productionTrackerService: ProductionTrackerService, private activatedRoute: ActivatedRoute, private chargeBackService: ChargeBackService, private router: Router,
      private dialog: MatDialog, private toastrService: ToastrService, private fileHanldingService: FileHandlingService) { }

   userAccessSubscription: Subscription;
   userAccess: UserAccess;

   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;

   chargeBackVendorList: ChargeBackVendor[] = [];
   cbCategoryList: string[] = [];
   cbReasonList: string[] = [];
   cbVendorStatusList: string[] = [];
   cbCustomerStatusList: string[] = [];
   cbVendorTypeList: string[] = [];
   chargeBack: ChargeBack = new ChargeBack;
   editable: boolean = false;
   createAction: boolean = false;
   viewAction: boolean = false;
   //draftAction: boolean = false;
   chargebackId: string;
   @Input() salesOrderId: string;
   createCurrency: string;

   productionTracker: ProductionTracker;
   customerPONumberList: string[];

   customerStatusListForRequiredFieldValidation: string[] = [];

   ngOnInit(): void {
      this.evaluateRoute();
      this.handleSubscriptions();
      this.loadCbCategoryList();
      this.loadCbReasonList();
      this.loadCbVendorStatusList();
      this.loadCbCustomerStatusList();
      this.loadCbVendorTypeList();
      this.loadCustomerStatusListForRequiredFieldValidation();
      this.initialize();
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
         this.currentLoggedInUser = currentLoggedInUser;
      });
   }

   evaluateRoute() {
      const activatedRoutePath = this.activatedRoute.snapshot.routeConfig.path;
      if (this.activatedRoute.snapshot.paramMap.get('action') === "view") {
         this.viewAction = true;
         //this.draftAction = false;
         this.createAction = false;
         this.chargebackId = this.activatedRoute.snapshot.paramMap.get('id');
         //console.log(this.chargebackId);
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "create") {
         this.createAction = true;
         this.viewAction = false;
         //this.draftAction = false;
         this.salesOrderId = this.activatedRoute.snapshot.paramMap.get('id');
         this.createCurrency = this.activatedRoute.snapshot.queryParams.currency;
         //console.log(this.salesOrderId);
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "draft") {
         //this.draftAction = true;
         this.viewAction = false;
         this.createAction = false;
      }
   }

   async initialize() {
      if (this.viewAction) {
         this.loadChargeBack(this.chargebackId);
      }

      if (this.createAction) {
         if (this.salesOrderId) {
            await this.loadProductionTracker(this.salesOrderId);
            this.populateChargeBackWithProductionTrackerInfo();
         }
         this.editable = true;
      }

      /*
      if (this.draftAction) {
         this.chargeBack = new ChargeBack();
         this.editable = true;
         this.chargeBack.status = 'DRAFT';
         this.chargeBack.chargeBackNo = 'DRAFT';
      }
      */
   }

   loadChargeBack(id: string) {
      this.chargeBackService.getChargeBackById(id).subscribe(response => {
         this.chargeBack = response.responsePayload;
         this.loadProductionTracker(this.chargeBack.salesOrderId);
         this.loadAttachments();
      });
   }

   populateChargeBackWithProductionTrackerInfo() {
      this.chargeBack = new ChargeBack();
      this.chargeBack.salesOrderId = this.productionTracker.salesOrderId;
      this.chargeBack.salesOrderNo = this.productionTracker.salesOrderNumber;
      this.chargeBack.customerName = this.productionTracker.customerName;
      //this.chargeBack.customerPoNumber = this.productionTracker.customerPO;
      this.chargeBack.currency = this.createCurrency;
   }

   async loadProductionTracker(salesOrderId) {
      //this.salesOrderId = "8a80801787ab5a220187aec28baa0021";
      await this.productionTrackerService.loadProductionTracker(salesOrderId).toPromise().then(res => {
         if (res && res != null) {
            this.productionTracker = res;
            //console.log('this.productionTracker = ', this.productionTracker);
            this.buildChargeBackVendorsList(this.productionTracker, this.chargeBack.vendorType);
            this.buildCustomerPONumberList(this.productionTracker);
         } else {
         }
      });
   }

   async buildChargeBackVendorsList(productionTracker: ProductionTracker, vendorType: string) {
      this.chargeBackVendorList = [];
      switch (vendorType) {
         case 'PRINTER':
            if (productionTracker && productionTracker.printerInfoList) {
               productionTracker.printerInfoList.forEach(printerInfo => {
                  let cbVendor: ChargeBackVendor = new ChargeBackVendor();
                  cbVendor.id = printerInfo.printerId;
                  cbVendor.name = printerInfo.printerName
                  cbVendor.displayName = printerInfo.printerName
                  cbVendor.type = "PRINTER";
                  cbVendor.parentId = printerInfo.id;
                  cbVendor.poNumber = printerInfo.philcosPrinterPO;
                  this.chargeBackVendorList.push(cbVendor);
               });
            }
            break;

         case 'BLANKS_SUPPLIER':
            if (productionTracker && productionTracker.blanksInfoList) {
               productionTracker.blanksInfoList.forEach(blanksInfo => {
                  let cbVendor: ChargeBackVendor = new ChargeBackVendor();
                  cbVendor.id = blanksInfo.supplierId;
                  cbVendor.name = blanksInfo.blankSupplierName;
                  cbVendor.displayName = blanksInfo.blankSupplierName + (blanksInfo.printerName ? ' to ' + blanksInfo.printerName : '');
                  cbVendor.type = "BLANKS_SUPPLIER";
                  cbVendor.parentId = blanksInfo.id;
                  cbVendor.poNumber = blanksInfo.philcosBlanksPO;
                  this.chargeBackVendorList.push(cbVendor);
               });
            }
            break;
      }
      //console.log('this.chargeBackVendorList = ', this.chargeBackVendorList);
   }

   async buildCustomerPONumberList(productionTracker: ProductionTracker) {
      if (productionTracker && productionTracker.printerInfoList) {
         if (this.productionTracker.customerPO) {
            let tempList = this.productionTracker.customerPO.split(',');
            tempList.forEach(x => {
               x.trim();
            });
            this.customerPONumberList = tempList;
         }
      }
   }

   loadCbCategoryList() {
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('ACCOUNTS', 'CHARGE_BACK', 'CATEGORY').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.cbCategoryList = this.sharedService.sort(res.responsePayload);
         }
      });
   }

   loadCbReasonList() {
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('ACCOUNTS', 'CHARGE_BACK', 'REASON').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.cbReasonList = this.sharedService.sort(res.responsePayload);
         }
      });
   }

   loadCbVendorStatusList() {
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('ACCOUNTS', 'CHARGE_BACK', 'VENDOR_STATUS').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.cbVendorStatusList = this.sharedService.sort(res.responsePayload);
         }
      });
   }

   loadCbCustomerStatusList() {
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('ACCOUNTS', 'CHARGE_BACK', 'CUSTOMER_STATUS').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.cbCustomerStatusList = this.sharedService.sort(res.responsePayload);
         }
      });
   }

   loadCbVendorTypeList() {
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('ACCOUNTS', 'CHARGE_BACK', 'VENDOR_TYPE').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.cbVendorTypeList = this.sharedService.sort(res.responsePayload);
         }
      });
   }

   loadCustomerStatusListForRequiredFieldValidation(){
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('ACCOUNTS', 'CHARGE_BACK', 'STATUS_LIST_FOR_REQUIRED_FIELD_VALIDATION').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.customerStatusListForRequiredFieldValidation = this.sharedService.sort(res.responsePayload);
         }
      });
   }

   handleVendorTypeChange($event) {
      this.chargeBack.vendorId = undefined;
      this.chargeBack.vendorPtInfoId = undefined;
      this.chargeBack.vendorPoNo = undefined;
      this.chargeBack.vendorName = undefined;
      this.chargeBack.vendorDisplayName = undefined;
      this.buildChargeBackVendorsList(this.productionTracker, this.chargeBack.vendorType);
   }

   handleVendorChange($event) {
      let temp: ChargeBackVendor[] = this.chargeBackVendorList.filter(listElem => (listElem.parentId === this.chargeBack.vendorPtInfoId));
      this.chargeBack.vendorId = temp[0].id;
      this.chargeBack.vendorType = temp[0].type;
      this.chargeBack.vendorPtInfoId = temp[0].parentId
      this.chargeBack.vendorName = temp[0].name;
      this.chargeBack.vendorPoNo = temp[0].poNumber;
      this.chargeBack.vendorDisplayName = temp[0].displayName;
      //console.log(this.chargeBack);
   }

   handleVendorNameChange(){
      //alert(this.chargeBack.vendorType);
      this.chargeBack.vendorName = this.chargeBack.vendorName?.trim();
      this.chargeBack.vendorId = this.chargeBack.vendorType+'_'+this.chargeBack.vendorName;
      this.chargeBack.vendorDisplayName = this.chargeBack.vendorName;
   }

   editChargeBack() {
      this.editable = true;
   }

   async cancelEditOrCreateChargeBack() {
      if (this.chargeBack.id === null || this.chargeBack.id === undefined) {
         this.cancelCreateConfirmDialog();
      } else {
         await this.loadChargeBack(this.chargeBack.id);
         this.editable = false;
      }
   }

   cancelCreateConfirmDialog() {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
         disableClose: true,
         data: {
            title: 'Cancel Create Charge Back',
            message: 'Charge Back is not created. Are you sure you want to cancel ?',
            confirmBtnText: 'Yes',
            cancelBtnText: 'Cancel'
         }
      });
      confirmDialog.afterClosed().subscribe(res => {
         if (res) {
            this.router.navigate(['chargeback/list']);
         } else {
         }
      });
   }

   saveChargeBack() {
      this.chargeBack.cbDocumentsCount = this.chargeBackService.getChargeBackDocumentCount(this.chargeBack);
      if (this.chargeBack.id) {
         this.updateChargeBack()
      } else {
         this.createChargeBack();
      }
   }

   createChargeBack() {
      //console.log('create = ', this.chargeBack);
      // this.populateLocalFileList();

      if(!this.chargeBackService.isCBDocumentAvailable(this.customerStatusListForRequiredFieldValidation, this.chargeBack)){
         return;
      }

      this.chargeBackService.createChargeBack(this.chargeBack).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let createdChargeBack = response.responsePayload;
            this.editable = false;
            this.createAction = false;
            this.toastrService.success(response.responseStatus.message);
            this.uploadAttachments(createdChargeBack);
            this.chargeBack.id = createdChargeBack.id;
            this.chargeBack.chargeBackNo = createdChargeBack.chargeBackNo;
            this.chargeBack.version = createdChargeBack.version;
            //this.loadChargeBack(createdChargeBack.id);
            //this.router.navigate(['chargeback/view/' + createdChargeBack.id]);
         } else if (response.responseStatus.status === 'FAILED') {
            console.error("ChargeBack Component - create failed...", response.responseStatus.message);
            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }

   updateChargeBack() {
      //console.log('update = ', this.chargeBack);
      //this.populateLocalFileList();
      if(!this.chargeBackService.isCBDocumentAvailable(this.customerStatusListForRequiredFieldValidation, this.chargeBack)){
         return;
      }

      this.chargeBackService.updateChargeBack(this.chargeBack).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let updatedChargeBack = response.responsePayload;
            this.editable = false;
            this.createAction = false;
            this.toastrService.success(response.responseStatus.message);
            //console.log(updatedChargeBack, this.chargeBack);
            this.uploadAttachments(updatedChargeBack);
            this.chargeBack.id = updatedChargeBack.id;
            this.chargeBack.version = updatedChargeBack.version;
            this.chargeBack.chargeBackNo = updatedChargeBack.chargeBackNo;
            //this.loadChargeBack(updatedChargeBack.id);
            //this.router.navigate(['chargeback/view/' + updatedChargeBack.id]);
         } else if (response.responseStatus.status === 'FAILED') {
            console.error("ChargeBack Component - update failed...", response.responseStatus.message);
            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }

   goBack() {
      this.router.navigate(['chargeback/list']);
   }

   /****************************** File Handling Starts *******************************/

   loadAttachments() {
      this.chargeBackService.loadAttachments(this.chargeBack);
   }

   uploadAttachments(updatedChargeBack: ChargeBack) {
      //console.log(this.createAction);
      this.chargeBackService.uploadAttachments(updatedChargeBack, this.chargeBack, this.createAction);
   }

   getGroupItemForChargeBack(chargeBack: ChargeBack) {
      return this.chargeBackService.getGroupItemForChargeBack(chargeBack);
   }

   /****************************** File Handling Ends ******************************/

}
