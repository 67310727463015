<div class="container">
    <div class="row mt-2 d-flex justify-content-center">
        <h2 class="d-inline">Licensors</h2>
        <button type="button" [routerLink]="['../licensor/create']"
        class="btn btn-circle btn-circle-md d-inline ml-4" *ngIf="userAccess?.canCreateLicensor" matTooltip="Create New Licensor" matTooltipPosition="right" matTooltipClass="bg-dark"><i class="fas fa-plus fa-lg text-light"></i></button>
    </div>
    
    <hr >
    <div class="row">
        <div class="col-sm-6">
            <input class="form-control mb-2" #search placeholder="Filter by Licensor Name.." (keyup)="onKeyupLicensorFilter($event,search.value)">
        </div>
        <div class="col-sm-6 mt-2">
            Found {{filteredLicensorList?.length}} {{search.value.length>0? 'Licensors found for "'+search.value+'"' : 'Licensors'}}
        </div>
    </div>

    <!-- Ungrouped Display
    <div class="row gallery-block cards-gallery mt-4">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-4" *ngFor="let licensor of filteredLicensorList;index as i">
            <div class=" card border-0 transform-on-hover h-100 color-{{i%4}}" style="background-color:rgba(255, 195, 82, 0.89)">
                <a  [routerLink]="['/licensor/view/'+licensor.id]">
                
                <div class="card-body" style="padding: 0.25rem;">
                    <div class=" ml-1 mr-1" >
                        <div class=" d-flex justify-content-left">
                        <button class="btn bnt-lg bg-dark text-white ">
                            {{licensor.licensorName.charAt(0).toUpperCase()}}</button>
                        </div>
                    <div class="mb-3" >
                            <h4 class="text-break  ml-2 mr-2 card-text justify-content-center">{{licensor.licensorName}}</h4>
                            
                        </div>
                    </div>
                    <p class="card-text">{{licensor.licensorStatus?'Active':'Inactive'}}</p>
                   
                </div>
            </a>
            </div>
        </div>
    </div>   
    -->


    <!-- GROUPED DISPLAY -->
    <div *ngFor="let groupedLicensors of groupedLicensorList;index as l">
        <h5> {{groupedLicensors.key}} </h5>
        <hr style="margin-top: 0rem">
        <div class="row gallery-block cards-gallery mt-4">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-4" *ngFor="let licensor of groupedLicensors.licensors;index as i">
                <div class=" card border-0 transform-on-hover h-100 " [style.background-color]="licensor.licensorColor">
                   
                    
                    <div class="card-body" style="padding: 0.25rem;">
                        <a  [routerLink]="['/licensor/view/'+licensor.id]">
                        <div class=" ml-1 mr-1" >
                            <div class=" d-flex justify-content-left">
                            <button class="btn bnt-lg bg-dark text-white" *ngIf="licensor.licensorStatus">{{licensor.licensorName.charAt(0).toUpperCase()}}</button>
                            <button class="btn bnt-lg bg-danger text-white" *ngIf="!licensor.licensorStatus">{{licensor.licensorName.charAt(0).toUpperCase()}}</button>
                            </div>
                        <div class="mb-3" >
                                <h5 class="text-truncate  ml-2 mr-2 card-text justify-content-center">
                                 {{licensor.licensorName}}</h5>
                                 
                            </div>
                        </div>
                        <p class="card-text">{{licensor.licensorStatus?'Active':'Inactive'}}</p>
                    </a>
                    <div>
                        <button class="btn  btn-circle btn-circle-sm d-inline" *ngIf="userAccess.canDeleteLicensor"  (click)="deleteLincesor(licensor)" >
                            <i class="fas fa-trash text-light"></i>
                        </button> 
                    </div>    
                    </div>
                
                </div>
            </div>
        </div>   
    </div>

</div>
<app-brand-list [hideBrandFilter]="hideBrandFilter"></app-brand-list>