import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../model/api-response';

@Injectable({
  providedIn: 'root'
})
export class SageService {

  constructor(private http: HttpClient) { }

  readonly rooturl = environment.apiEndPoint;


  getCreateItemAuditError(origination:string,originationReferenceId:string) {
    return this.http.get<ApiResponse>(this.rooturl + '/createItemAudit/list/'+origination+'/'+originationReferenceId);
 }

 getCreateItemAuditErrorCount(origination:string,originationReferenceId:string) {
  return this.http.get<number>(this.rooturl + '/NonBlocking/createItemAudit/count/'+origination+'/'+originationReferenceId);
}

 retryCreateItemAuditError(origination:string,originationReferenceId:string,trigger:string,createItemAuditErrors:any[]) {
    return this.http.post<ApiResponse>(this.rooturl + '/createItemAudit/list/'+origination+'/'+originationReferenceId+'/'+trigger,createItemAuditErrors);
 }

 getAvailableCompanies() {
  return this.http.get<ApiResponse>(this.rooturl + '/sageIntegration/available/companies/details');
}
}
