import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ComposeEmail } from 'src/app/shared/model/compose-email.model';
import { EmailTemplateConfig } from 'src/app/shared/model/email-template-config.model';
import { Product } from 'src/app/shared/model/product.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { ComposeEmailService } from 'src/app/shared/service/compose-email.service';
import { ProductCatalogService } from 'src/app/shared/service/product-catalog.service';
import { ProductService } from 'src/app/shared/service/product.service';
import { SharedService } from 'src/app/shared/service/shared.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-multiple-submission',
  templateUrl: './multiple-submission.component.html',
  styleUrls: ['./multiple-submission.component.css']
})
export class MultipleSubmissionComponent implements OnInit {

  constructor(private authService:AuthService,private productCatalogService:ProductCatalogService,
    private productService:ProductService,private composeEmailService:ComposeEmailService,private sharedService:SharedService,
    private location: Location) { }

  userAccessSubscription: Subscription;
  userAccess: UserAccess;

  currentLoggedInUserSubscription: Subscription;
  currentLoggedInUser: User;
  selectedProductList:Product[]=[];


  composeEmail: ComposeEmail = new ComposeEmail;
   showEmailEditor:boolean=false;

  ngOnInit(): void {
    this.handleSubscriptions();
    this.selectedProductList = this.productCatalogService.selectedProductsForCatalog;
    this.loadProductAditionalDetails(this.selectedProductList);

  }
  handleSubscriptions() {
    this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
       this.userAccess = access;
    });
    this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
       this.currentLoggedInUser = currentLoggedInUser;
    });
  }


  loadProductAditionalDetails(productList: Product[]) {
    if (productList && productList != null && productList.length > 0) {
      productList.forEach(product => {
        if (product !== null && product != undefined) {
          //this.productService.loadProductAttachmentsUrls(product,'PNL',product.licensorId,product.brandId);
          //this.productService.loadProductImagesUrls(product,product.licensorId,product.brandId);
          //this.productService.loadProductAttachmentsUrls(product,'MASTER_ARTS',product.licensorId,product.brandId);
          //this.productService.loadProductAttachmentsUrls(product,'SEPERATIONS',product.licensorId,product.brandId);
          this.productService.loadProductAttachmentsUrls(product, 'APPROVAL_DOCS', product.licensorId, product.brandId);
          this.productService.loadProductAttachmentsUrls(product,'PHOTO_APPROVAL',product.licensorId,product.brandId);
          //this.loadPrinterMatrix();
        }
      });
    }
  }

  sendApprovalEmail(selectedProductList:Product[]){
    this.showEmailEditor=true;
    let emailConfig: EmailTemplateConfig = new EmailTemplateConfig;
    //emailConfig.productId=this.product.id;
    emailConfig.brandId=this.selectedProductList[0].brandId;
    emailConfig.licensorId=this.selectedProductList[0].licensorId;
    if(selectedProductList && selectedProductList.length>0){
      selectedProductList.forEach(product => {
        emailConfig.productSkuList.push(product.sku);
        emailConfig.productIdList.push(product.id);
      });
    }
    //emailConfig.sku=this.product.sku;
    emailConfig.templateName='productApprovalEmail';
    
    this.composeEmail=new ComposeEmail;
    this.getEmailTemplate(emailConfig);
    let availableAtt=[];
    if(selectedProductList && selectedProductList.length>0){
      selectedProductList.forEach(prod => {
        availableAtt=availableAtt.concat(prod.photoApproval).concat(prod.approvalDocs);
      });
    }
    this.composeEmail.emailAttachments=this.sharedService.productAttachmentToEmailAttachment(availableAtt.filter(x=>x.isSelected));
   }

   getEmailTemplate(emailConfig:EmailTemplateConfig){
    this.composeEmailService.getEmailTemplate(emailConfig).subscribe(response => {
       let composeEmailTemp:ComposeEmail = response.responsePayload;
       this.composeEmail.emailContent=composeEmailTemp.emailContent;
       this.composeEmail.emailTo=composeEmailTemp.emailTo;
       this.composeEmail.emailCc=composeEmailTemp.emailCc;
       this.composeEmail.emailSubject=composeEmailTemp.emailSubject;
       this.composeEmail.emailTemplateConfig=emailConfig;
       document.getElementById('composeEmailToggler').click();
    });
 }

 async emailSent(composeEmail:ComposeEmail,productList:Product[]){
  if(composeEmail && composeEmail.emailTemplateConfig){
    let config:EmailTemplateConfig=composeEmail.emailTemplateConfig;
    if(config.templateName==='productApprovalEmail'){
      if (productList && productList != null && productList.length > 0) {
        productList.forEach(async product => {
        this.productService.logApprovalEmail(composeEmail,product,'APPROVAL_DOCS');
        await this.productService.handleApprovalEmailSentForProduct(product,'EMAIL','APPROVAL_DOCS');
        //
        });
        //this.goBack();
      }
     
    }
    /*if(config.templateName==='productPhotoApprovalEmail'){
     this.productService.logApprovalEmail(composeEmail,this.product,'PHOTO_APPROVAL');
     await this.productService.handleApprovalEmailSentForProduct(this.product,'EMAIL','PHOTO_APPROVAL');
     //this.loadProduct();
   }*/
  }
}

goBack(){
  this.location.back();
}
}
