export class Permission {
    id: string;
    securityId : string;
    securityDescription : string;

    constructor(){
        this.id = null;
        this.securityId = null;
        this.securityDescription = null;
    }
}
