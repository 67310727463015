import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ContactDetails } from 'src/app/shared/model/contact-details.model';

@Component({
   selector: 'app-contact-details-modal',
   templateUrl: './contact-details-modal.component.html',
   styleUrls: ['./contact-details-modal.component.css']
})

export class ContactDetailsModalComponent implements OnInit {

   constructor() { }

   @Input() header: string = 'Contact  Details';

   @Input()
   addEditContactDetail: ContactDetails;

   @Input() modalId: string;
   @Input() editable: boolean;
   @Input() showProfile: boolean=true;
   @Input() showPhone: boolean=true;
   @Input() showEmail: boolean=true;
   @Input() showAddress: boolean=true;
   @Input() multipleAddress: boolean=true;
   @Input() multiplePhones: boolean=true;
   @Input() multipleEmails: boolean=true;
   @Input() licensorId: string;
   @Input() showAddressType: boolean = true;
   @Input() show: boolean = true;
   
   @ViewChild('addEditContactForm') addEditContactForm: any;
   @Output() onSave = new EventEmitter<ContactDetails>();
   @Output() onCancel = new EventEmitter<ContactDetails>();

   ngOnInit(): void {
   }

   closeAddEditContactDetail(details: ContactDetails) {
      //
      if(this.addEditContactDetail.id===undefined || this.addEditContactDetail.id===null){
         this.onCancel.emit(this.addEditContactDetail);
      }
      
   }

   saveAddEditContactDetail() {
      this.onSave.emit(this.addEditContactDetail);
   }

}
