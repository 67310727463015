import { Component, OnInit, Input, Output, EventEmitter, AfterContentInit } from '@angular/core';
import { GroupedProductAttachment, ProductAttachment } from 'src/app/shared/model/product.model';
import { ToastrService } from 'ngx-toastr';
import { CdkDragDrop, transferArrayItem, moveItemInArray } from '@angular/cdk/drag-drop';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SharedService } from 'src/app/shared/service/shared.service';
import { ProductService } from 'src/app/shared/service/product.service';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AttachmentViewerDialogComponent } from '../attachment-viewer-dialog/attachment-viewer-dialog.component';
import { group } from 'console';

@Component({
  selector: 'app-attachment-operations',
  templateUrl: './attachment-operations.component.html',
  styleUrls: ['./attachment-operations.component.css'],
  animations: [
   trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void => *', animate(1000)),
    ]),
    trigger('EnterLeave', [
      state('flyIn', style({ transform: 'translateX(0)' })),
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s 300ms ease-in')
      ]),
      transition(':leave', [
        animate('0.3s ease-out', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('balloonEffect', [
      state('void', style({
        transform: 'scale(0.2)'
      })),
      state('*', style({
        transform: 'scale(1)'
      })),
      transition('void=>*', animate('1000ms')),
      transition('*=>void', animate('1500ms'))
    ]),
]
})
export class AttachmentOperationsComponent implements OnInit {
 
  @Input() header:string='';
  @Input() headerSize:string='4';
  @Input() showHeader:boolean=true;
  @Input() editable:boolean;
  @Input() createAction:boolean;
  @Input() canUpload:boolean;
  @Input() canDownload:boolean;
  @Input() canDelete:boolean;
  @Input() canRenameFile:boolean;
  @Input() canQuickView:boolean=true;
  @Input() isSelectable:boolean=false;
  @Input() likeable:boolean=false;
  @Input() viewCreatedBy:boolean=true;
  attachmentList:ProductAttachment[];
  @Input()  category:string;
  @Input()  emptyListError:string;
  selectedImgAccesUrl:string;
  selectedImgId:string;
  @Output() onNewUpload = new EventEmitter<boolean>();
  @Output() onDelete = new EventEmitter<boolean>();
  @Output() onLike = new EventEmitter<ProductAttachment>();

  @Input() groupItems:any[]=[];
  @Input() isGroupedAttachments:boolean=false;
  @Input() groupItemDisplayVar:string='';
  @Input() groupItemReferenceVar:string='';
  
  groupItemsAttachment:GroupedProductAttachment[]=[];
  contentLoaded:boolean=false;
  showSlideTracker:boolean=false;
  constructor(private dialog: MatDialog,private productService:ProductService,private toastrService:ToastrService,private sharedService:SharedService) { }
  ngOnInit(): void {
    
  }
  
  @Input()
  set attachments(val : ProductAttachment[]){
    this.attachmentList=val;
    if(this.isGroupedAttachments){
      this.createGroupsWithAttachment();
    }
    if(this.category==='IMAGES'){
      this.showDefaultImage();
    }
  }
  get attachments():ProductAttachment[]{
    return this.attachmentList;
  }

  fileSelectedDragDrop(files: File[], category: string,groupItem?:any) {
    if(this.editable){
    if (files.length > 0 && (category==='IMAGES')){
      for (let index = 0; index < files.length; index++) {
         var mimeType = files[index].type;
         if (mimeType.match(/image\/*/) != null || mimeType.match(/application\/postscript\/*/) != null) {
            continue;
         }else{
            this.toastrService.error('Invalid File Type');
           // this.uploadError = true;
            return;
         }
      }
   }
    if (files.length > 0 ) {
      if (this.attachmentList === null || this.attachmentList === undefined) {
        this.attachmentList = [];
      }
      
      for (let index = 0; index < files.length; index++) {
        if(category==='IMAGES'){
        
        setTimeout(()=>{
        var reader = new FileReader();
        reader.readAsDataURL(files[index]);
       
        reader.onload = (_event) => {
          let attachment = new ProductAttachment;
          attachment.islocal=true;
          attachment.fileSignedUrl = reader.result;
          attachment.thumbnailSignedUrl = reader.result;
          attachment.localfile=files[index];
          attachment.originalFileName=files[index].name;
          attachment.id=this.attachmentList.length.toString();
          this.attachmentList.push(attachment);
          this.showImage(attachment.fileSignedUrl,attachment.id);
          this.setSortOrderForAttachment();
        }
      });
        }else{
          let attachment = new ProductAttachment;
              attachment.islocal=true;
              attachment.localfile=files[index];
              attachment.originalFileName=files[index].name;
              
              if(groupItem && this.groupItemReferenceVar && groupItem[this.groupItemReferenceVar]){
                attachment.groupReferenceId=groupItem[this.groupItemReferenceVar];
              }                
              console.log('Attachment ',attachment);
              attachment.id=this.attachmentList.length.toString();
              this.attachmentList.push(attachment);
        }
        this.onNewUpload.emit(true);
      }
    
    } 
    }
  }

  deleteFile(id: string, category: string) {
    this.onDelete.emit(true);
    this.markFileForDelete(id);
    if(category==='IMAGES'){
      this.showDefaultImage();
    }
    this.setSortOrderForAttachment();
  }

  renameFile(id: string, category: string){
    let attachment;
    this.attachmentList.forEach(element => {
      if(id===element.id){
        attachment=element;
      }
    });
    if(attachment){
      this.renameFilePopup(attachment);
    }
  }
  markFileForDelete(id: string){
    this.attachmentList.forEach(element => {
      if(id===element.id){
        element.toDelete=true;
      }
    });
  }

  showImage(accessUrl,id, event?) {
    this.selectedImgAccesUrl = accessUrl;
    this.selectedImgId=id;
  }
  showDefaultImage() {
    this.selectedImgAccesUrl = undefined;
    this.selectedImgId=undefined;
    if(this.attachmentList!==undefined && this.attachmentList.length>0){
      for (const element of this.attachmentList) {
        if(!element.toDelete){
          this.selectedImgAccesUrl = element.fileSignedUrl;
          this.selectedImgId=element.id;
          break;
        }
      }
    }
  }

  

  handleThumbnailError(productAttachment: ProductAttachment, event) {
    if (isNaN(productAttachment.thumbnailErrorCount)) {
      productAttachment.thumbnailErrorCount = 0;
    }
    event.target.src = productAttachment.fileSignedUrl;
    var x: number = productAttachment.thumbnailErrorCount;
    productAttachment.thumbnailErrorCount = x + 1;
    if (productAttachment.thumbnailErrorCount < 2) {
      event.target.src = productAttachment.fileSignedUrl;
      productAttachment.thumbnailSignedUrl = productAttachment.fileSignedUrl;
    } else {
      event.target.src = "/assets/default-image.jpg"
    }

  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer !== event.container) {
       transferArrayItem(event.previousContainer.data, event.container.data,
          event.previousIndex, event.currentIndex)
    } else {
       moveItemInArray(this.attachmentList, event.previousIndex, event.currentIndex);
       this.setSortOrderForAttachment(false);
    }
 }

 setSortOrderForAttachment(removeLocalFileReference?: boolean) {
    if (this.attachmentList != undefined && this.attachmentList != null && this.attachmentList.length > 0) {
       for (let index = 0; index < this.attachmentList.length; index++) {
          this.attachmentList[index].sortOrder = index;
         /* if (removeLocalFileReference) {
             this.attachmentList[index].localfile = undefined;
          }*/
       }
    }
 }

 getAvailableAttachment():number{
   var counter=0;
   if(this.attachmentList!==undefined && this.attachmentList!==null && this.attachmentList.length>0){
    for (const element of this.attachmentList) {
      if(!element.toDelete){
       counter=counter+1;
      }
    }
  }
  return counter;
 }

 

 /*
 handlePaste(event,reference){
  reference.focus();
  let successful = document.execCommand("paste");
  if (successful) {
    // do something
  } else {
    // handle the error
  }
 }*/

 /**SCROLL */

 timer: NodeJS.Timeout;

  scrollDiv(elementToScroll:HTMLElement, depl) {
    elementToScroll.scrollLeft -= depl;
    //
    this.timer = setTimeout(()=>{
      this.scrollDiv(elementToScroll, depl)
    }, 10);
  }

  stopTimer(timer:NodeJS.Timeout) {
    clearTimeout(timer);
  }
 /**SCROLL ENDS */

 renameFilePopup(attachment:ProductAttachment){
  //this.getCatalogName();
  let oldName=attachment.originalFileName;
  const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    disableClose: true,
    data: {
      modalType:'TEXT_INPUT',
      maxlength:150,
      textInputRegex:'^[\\w\\s-\\()]+\\.[a-zA-Z]+$',
      textInputDefault:oldName,
      textInputRegexError:'File Name must have extnesion and only contain alphabets, numbers, underscore,paranthesis and hyphen',
      title: 'Rename File',
      message: 'Enter the new filename with extension. Once saved, file name will be changed permanently.\n', 
      confirmBtnText: 'Save',
      cancelBtnText:'Cancel'
    }
  });
  confirmDialog.afterClosed().subscribe(res => {
    if(res && res!==oldName){
        attachment.originalFileName=res;
        this.productService.renameProductAttachment(attachment).subscribe(res=>{
          if(res && res!=null){
            attachment=res as ProductAttachment;
            this.sharedService.loadMimeType(attachment);
          }else{
            attachment.originalFileName=oldName;
            this.toastrService.error('Error while renaming the file - '+attachment.originalFileName);
          }
        });
    }
  });
   
}

openAttachmentViewer(attachmentToView:ProductAttachment){
  //this.getCatalogName();
   const config:MatDialogConfig={};
  const confirmDialog = this.dialog.open(AttachmentViewerDialogComponent, {
    disableClose: false,
    height:'90vh',
    width:'80vw',
    data: {
      attachment:attachmentToView,
      cancelBtnText:'Close'
    }
  });
  confirmDialog.afterClosed().subscribe(res => {
    //Do Nothing
  });
   
}

liked(productAttachment:ProductAttachment){
  this.onLike.emit(productAttachment);
}

getGroupedItemAttachmentList(attachmentList:ProductAttachment[], groupItem:any){
  let retVal:ProductAttachment[]=[];
  let filterReference=groupItem[this.groupItemReferenceVar];
  if(!filterReference){
    //Specifically setting it to nullas when groupItem has the value as null, filterReference is changing to undefined and not matching receords with null 
    filterReference=null;
  }
  if(attachmentList && attachmentList.length>0){
    retVal=attachmentList.filter(x=>x.groupReferenceId===filterReference);
  }
  if(!retVal){
    retVal=[];
  }
  console.log('getGroupedItemAttachmentList',filterReference,retVal,attachmentList);
  return retVal;
}

createGroupsWithAttachment() {
  this.groupItemsAttachment.length=0;
  if(this.isGroupedAttachments){
    if(this.groupItems && this.groupItems.length>0){
      this.groupItems.forEach(gi => {
        let gpa:GroupedProductAttachment=new GroupedProductAttachment;
        gpa.groupItemConfig=gi;
        gpa.groupAttachmentList=this.getGroupedItemAttachmentList(this.attachmentList,gi);
        gpa.groupName=gpa.groupItemConfig[this.groupItemDisplayVar];
        this.groupItemsAttachment.push(gpa);
      });
   }
    
    /*let nonGroupItemConfig:any= new Object;
    nonGroupItemConfig['groupItemConfig']=new Object;
    nonGroupItemConfig.groupItemConfig[this.groupItemDisplayVar]="Others";
    nonGroupItemConfig.groupItemConfig[this.groupItemReferenceVar]=null;
    console.log('getGroupedItemAttachmentList : Non grouped Config - ',nonGroupItemConfig);
*/
    /*let gpa:GroupedProductAttachment=new GroupedProductAttachment;
    gpa.groupItemConfig=nonGroupItemConfig;
    gpa.groupName=gpa.groupItemConfig[this.groupItemDisplayVar];
    console.log('getGroupedItemAttachmentList : Group Name - ',this.groupItemDisplayVar,gpa.groupItemConfig[this.groupItemDisplayVar],gpa.groupName);
*/
    let gpa:GroupedProductAttachment=new GroupedProductAttachment;
    gpa.nonGrouped=true;
    gpa.groupItemConfig[this.groupItemDisplayVar]="Others";
    gpa.groupItemConfig[this.groupItemReferenceVar]=null;
    gpa.groupName=gpa.groupItemConfig[this.groupItemDisplayVar];
    console.log('getGroupedItemAttachmentList : Group Name - ',this.groupItemDisplayVar,gpa.groupItemConfig[this.groupItemDisplayVar],gpa.groupName);

    gpa.groupAttachmentList=this.getGroupedItemAttachmentList(this.attachmentList,gpa.groupItemConfig);
   
    this.groupItemsAttachment.push(gpa);
    console.log('create: getGroupedItemAttachmentList - ',this.groupItemsAttachment);
  }
}

}
