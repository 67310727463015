import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Phone } from 'src/app/shared/model/phone.model';
import { ControlContainer, NgForm, NgModelGroup } from '@angular/forms';
import { MetadataService } from 'src/app/shared/service/metadata.service';

@Component({
   selector: 'app-phone',
   templateUrl: './phone.component.html',
   styleUrls: ['./phone.component.css'],
   viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]

})
export class PhoneComponent implements OnInit {

   constructor(private metadataService: MetadataService) { }

   phoneTypes: string[];
   @Input() editable: boolean;
   @Input() multiplePhones: boolean;
   @Input() contactType: string;

   @Input()
   phoneList: Phone[];

   ngOnInit(): void {
      this.loadPhoneTypes();
   }

   private loadPhoneTypes() {
      //
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('PROFILE', 'PHONE', 'TYPE').subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.phoneTypes = response.responsePayload;
         }
         // 
      }, error => {
         //console.error(error);
      });
   }

   addPhone() {
      let phone = new Phone;
      phone.transientIndex=this.phoneList.length;
      this.phoneList.push(phone);
   }

   removePhone(index: number) {
      this.phoneList.splice(index, 1);
      //this.phoneList.splice(this.phoneList.indexOf(phoneToRemove), 1);
   }

}
