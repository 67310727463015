import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderDetailViewerDialogComponent } from 'src/app/common/order-detail-viewer-dialog/order-detail-viewer-dialog.component';

@Component({
  selector: 'app-sku-printer-assignment-modal',
  templateUrl: './sku-printer-assignment-modal.component.html',
  styleUrls: ['./sku-printer-assignment-modal.component.css']
})
export class SkuPrinterAssignmentModalComponent implements OnInit {

  

  
  skuPrinterAssignmentList:any[];
  title:string;

   constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<SkuPrinterAssignmentModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = false;

      if (data.skuPrinterAssignmentList) {
         this.skuPrinterAssignmentList = data.skuPrinterAssignmentList;
      }
      if (data.title) {
        this.title = data.title;
     }
   }

   ngOnInit(): void {
  }


}
