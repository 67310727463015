<!--TRIM SECTION STARTS-->
<div >
    <div ngModelGroup="trimInfo_{{printerInfoIndex}}_{{trimInfoIndex}}" #trimInfoCtrl="ngModelGroup" class="form-ngModelGroup">
        <h6 class="mt-4" *ngIf="false">Trims & Finishing Sheet Info</h6>
        <hr class="hr-min" *ngIf="false">
        <div class="p-2 my-3" *ngIf="printerInfo.trimInfoList?.length===0 && !editable"><h6  class="text-muted f-13">No Sample info available</h6>
        </div>
        <ng-container *ngFor="let trimInfo of printerInfo.trimInfoList; index as trimInfoIndex">
            <div class="p-2 my-3" [style]="trimInfoIndex%2===1?'':''" *ngIf="trimInfo.deleteFlag===false">
                    <div class="row">
                        <div class="col-3 d-flex">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" name="trimsRequired_{{printerInfoIndex}}_{{trimInfoIndex}}"
                                [(ngModel)]="trimInfo.trimsRequired" [disabled]="!editable">
                                <label class="form-check-label f-10" style="color:black">Trims Required</label>
                            </div>
                        </div>
                        
                        <div class="col-2" *ngIf="trimInfo.trimsRequired">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Trim Supplier</label>
                            <input name="trimSupplier_{{printerInfoIndex}}_{{trimInfoIndex}}" class="form-control form-control-sm" style="font-size: 11px;" #trimSupplier="ngModel" 
                            [(ngModel)]="trimInfo.trimSupplier" [ngClass]="{'is-invalid':trimSupplier.touched && trimSupplier.errors}"
                            [disabled]="!editable">
                            <div *ngIf="trimSupplier?.invalid && (trimSupplier.dirty || trimSupplier.touched)" class="invalid-feedback">
                            <div *ngIf="trimSupplier.errors.required">
                                Trim Supplier is required.
                            </div>
                            </div>
                        </div>
                        <div class="col-2" *ngIf="trimInfo.trimsRequired">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Trims Delivery Status</label>
                            <select class="form-control form-control-sm custom-select" [(ngModel)]="trimInfo.trimDeliveryStatus"  name="trimDeliveryStatus_{{printerInfoIndex}}_{{trimInfoIndex}}"  style="font-size: 11px;"
                                #trimDeliveryStatusVar="ngModel" required  [ngClass]="{'is-invalid':trimDeliveryStatusVar.touched && trimDeliveryStatusVar.errors}"
                                [disabled]="!editable">
                                <option value="PENDING">Pending</option>
                                <option value="ORDER_PLACED">Order Placed</option>
                                <option value="IN_TRANSIT">In Transit</option>
                                <option value="DELIVERED">Delivered</option>
                            </select>
                            <div *ngIf="trimDeliveryStatusVar?.invalid && (trimDeliveryStatusVar.dirty || trimDeliveryStatusVar.touched)" class="invalid-feedback">
                                <div *ngIf="trimDeliveryStatusVar.errors.required">
                                    Trims Delivery Status is required.
                                </div>
                            </div>
                        </div>
                        <!--<div class="col-2" *ngIf="trimInfo.trimsRequired">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Trim Tracking #</label>
                            <input name="trimTrackingNum_{{printerInfoIndex}}_{{trimInfoIndex}}" class="form-control form-control-sm" style="font-size: 11px;" #trimTrackingNum="ngModel" 
                            [(ngModel)]="trimInfo.trimTrackingNum" [ngClass]="{'is-invalid':trimTrackingNum.touched && trimTrackingNum.errors}"
                            [disabled]="true">
                            <div *ngIf="trimTrackingNum?.invalid && (trimTrackingNum.dirty || trimTrackingNum.touched)" class="invalid-feedback">
                            <div *ngIf="trimTrackingNum.errors.required">
                                Trim Tracking # is required.
                            </div>
                            </div>
                        </div>-->
                        <div class="col-2" *ngIf="trimInfo.trimsRequired">
                            <app-tracking-link [editable]="editable" [linksArray]="trimInfo.trimTrackingLinks" [type]="'TRIMS_INFO'" [subType]="'TRIM_TRACKING'"></app-tracking-link>
                        </div>
                        <!--
                        <div class="col-2" *ngIf="trimInfo.trimsRequired">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Trims ETA</label>
                            <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="trimETA"  name="trimETA_{{printerInfoIndex}}_{{trimInfoIndex}}" [(ngModel)]="trimInfo.trimETA" style="cursor: pointer;font-size: 11px;"
                                class="form-control  form-control-sm" #trimETAVar="ngModel" [ngClass]="{'is-invalid':trimETAVar.touched && trimETAVar.errors}"
                                [disabled]="!editable">
                            <mat-datepicker #trimETA></mat-datepicker>
                            <button class="btn btn-sm" type="button" (click)="trimETA.open()" [disabled]="!editable"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <mat-error *ngIf="trimETAVar?.errors?.required && (trimETAVar.dirty || trimETAVar.touched)" style="font-size: 80%;color: #dc3545">
                            Trim ETA is required.
                            </mat-error>
                            <div *ngIf="trimETAVar?.invalid && (trimETAVar.dirty || trimETAVar.touched)" class="invalid-feedback">
                                <div *ngIf="trimETAVar.errors.required">Trim ETA is required.</div>
                            </div>
                        </div>-->
                    </div>
                    <div class="row">
                        <div class="col-3 d-flex">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" name="fininishingSheetRequired_{{printerInfoIndex}}_{{trimInfoIndex}}"
                                [(ngModel)]="trimInfo.fininishingSheetRequired" [disabled]="!editable">
                                <label class="form-check-label f-10" style="color:black">Finishing Sheet Required</label>
                            </div>
                        </div>
                        

                        <div class="col-2" *ngIf="trimInfo.fininishingSheetRequired">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Finsihing Sheet Sent Date</label>
                            <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="FinishingSheetSentDate"  name="FinishingSheetSentDate_{{printerInfoIndex}}_{{trimInfoIndex}}" [(ngModel)]="trimInfo.FinishingSheetSentDate" style="cursor: pointer;font-size: 11px;"
                                class="form-control  form-control-sm" #FinishingSheetSentDateVar="ngModel" [ngClass]="{'is-invalid':FinishingSheetSentDateVar.touched && FinishingSheetSentDateVar.errors}"
                                [disabled]="!editable">
                            <mat-datepicker #FinishingSheetSentDate></mat-datepicker>
                            <button class="btn btn-sm" type="button" (click)="FinishingSheetSentDate.open()" [disabled]="!editable"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <mat-error *ngIf="FinishingSheetSentDateVar?.errors?.required && (FinishingSheetSentDateVar.dirty || FinishingSheetSentDateVar.touched)" style="font-size: 80%;color: #dc3545">
                            Finishing Sheet Sent Date is required.
                            </mat-error>
                            <div *ngIf="FinishingSheetSentDateVar?.invalid && (FinishingSheetSentDateVar.dirty || FinishingSheetSentDateVar.touched)" class="invalid-feedback">
                                <div *ngIf="FinishingSheetSentDateVar.errors.required">Finishing Sheet Sent Date is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 d-flex">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" name="boxLabelsRequired_{{printerInfoIndex}}_{{trimInfoIndex}}"
                                [(ngModel)]="trimInfo.boxLabelsRequired" [disabled]="!editable">
                                <label class="form-check-label f-10" style="color:black">Box Labels Required</label>
                            </div>
                        </div>

                        <div class="col-2" *ngIf="trimInfo.boxLabelsRequired">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Box Labels Arrangement</label>
                            <select class="form-control form-control-sm custom-select" [(ngModel)]="trimInfo.boxLabelsArrangement"  name="boxLabelsArrangement_{{printerInfoIndex}}_{{trimInfoIndex}}"  style="font-size: 11px;"
                                #boxLabelsArrangementVar="ngModel" required  [ngClass]="{'is-invalid':boxLabelsArrangementVar.touched && boxLabelsArrangementVar.errors}"
                                [disabled]="!editable">
                                <option value="PRINTER">Printer</option>
                                <option value="PHILCOS">Philcos</option>
                            </select>
                            <div *ngIf="boxLabelsArrangementVar?.invalid && (boxLabelsArrangementVar.dirty || boxLabelsArrangementVar.touched)" class="invalid-feedback">
                                <div *ngIf="boxLabelsArrangementVar.errors.required">
                                    Box Label Arrangement is required.
                                </div>
                            </div>
                        </div>

                        <div class="col-2" *ngIf="trimInfo.boxLabelsRequired  && trimInfo.boxLabelsArrangement==='PHILCOS'">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Box Labels Status</label>
                            <select class="form-control form-control-sm custom-select" [(ngModel)]="trimInfo.boxLabelsStatus"  name="boxLabelsStatus_{{printerInfoIndex}}_{{trimInfoIndex}}"  style="font-size: 11px;"
                                #boxLabelsStatusVar="ngModel" required  [ngClass]="{'is-invalid':boxLabelsStatusVar.touched && boxLabelsStatusVar.errors}"
                                [disabled]="!editable">
                                <option value="PENDING">Pending</option>
                                <option value="PENDING_PACKING_LIST">Pending Packing List</option>
                                <option value="ORDER_PLACED">Order Placed</option>
                                <option value="IN_TRANSIT">In Transit</option>
                                <option value="DELIVERED">Delivered</option>
                                
                            </select>
                            <div *ngIf="boxLabelsStatusVar?.invalid && (boxLabelsStatusVar.dirty || boxLabelsStatusVar.touched)" class="invalid-feedback">
                                <div *ngIf="boxLabelsStatusVar.errors.required">
                                    Box Label Status is required.
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-2" *ngIf="trimInfo.boxLabelsRequired && trimInfo.boxLabelsArrangement==='PHILCOS'">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Box Labels Provided Date</label>
                            <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="boxLabelsSentDate"  name="boxLabelsSentDate_{{printerInfoIndex}}_{{trimInfoIndex}}" [(ngModel)]="trimInfo.boxLabelsSentDate" style="cursor: pointer;font-size: 11px;"
                                class="form-control  form-control-sm" #boxLabelsSentDateVar="ngModel" [ngClass]="{'is-invalid':boxLabelsSentDateVar.touched && boxLabelsSentDateVar.errors}"
                                [disabled]="!editable">
                            <mat-datepicker #boxLabelsSentDate></mat-datepicker>
                            <button class="btn btn-sm" type="button" (click)="boxLabelsSentDate.open()" [disabled]="!editable"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <mat-error *ngIf="boxLabelsSentDateVar?.errors?.required && (boxLabelsSentDateVar.dirty || boxLabelsSentDateVar.touched)" style="font-size: 80%;color: #dc3545">
                            Box Labels Provided Date is required.
                            </mat-error>
                            <div *ngIf="boxLabelsSentDateVar?.invalid && (boxLabelsSentDateVar.dirty || boxLabelsSentDateVar.touched)" class="invalid-feedback">
                                <div *ngIf="boxLabelsSentDateVar.errors.required">Box Labels Sent Date is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!--Polybags Starts-->
                        <!--<div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" name="polyBagsRequired_{{printerInfoIndex}}_{{trimInfoIndex}}" id="polyBagsRequired_{{printerInfoIndex}}_{{trimInfoIndex}}"
                                [(ngModel)]="trimInfo.polyBagsRequired" [disabled]="!editable">
                            <label class="custom-control-label f-10" style="color:black" for="polyBagsRequired_{{printerInfoIndex}}_{{trimInfoIndex}}">
                                <span>Polybags Required</span></label>
                        </div>-->
                       
                        <div class="col-3 d-flex">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" name="polyBagsRequired_{{printerInfoIndex}}_{{trimInfoIndex}}"
                                [(ngModel)]="trimInfo.polyBagsRequired" [disabled]="!editable">
                                <label class="form-check-label f-10" style="color:black">Polybags Required</label>
                            </div>
                        </div>

                        <div class="col-2" *ngIf="trimInfo.polyBagsRequired">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Polybags Arrangement</label>
                            <select class="form-control form-control-sm custom-select" [(ngModel)]="trimInfo.polyBagsArrangement"  name="polyBagsArrangement_{{printerInfoIndex}}_{{trimInfoIndex}}"  style="font-size: 11px;"
                                #polyBagsArrangementVar="ngModel" required  [ngClass]="{'is-invalid':polyBagsArrangementVar.touched && polyBagsArrangementVar.errors}"
                                [disabled]="!editable">
                                <option value="PRINTER">Printer</option>
                                <option value="PHILCOS">Philcos</option>
                            </select>
                            <div *ngIf="polyBagsArrangementVar?.invalid && (polyBagsArrangementVar.dirty || polyBagsArrangementVar.touched)" class="invalid-feedback">
                                <div *ngIf="polyBagsArrangementVar.errors.required">
                                    Polybags Arrangement is required.
                                </div>
                            </div>
                        </div>

                        <div class="col-2" *ngIf="trimInfo.polyBagsRequired  && trimInfo.polyBagsArrangement==='PHILCOS'">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Polybags Status</label>
                            <select class="form-control form-control-sm custom-select" [(ngModel)]="trimInfo.polyBagsStatus"  name="polyBagsStatus_{{printerInfoIndex}}_{{trimInfoIndex}}"  style="font-size: 11px;"
                                #polyBagsStatusVar="ngModel" required  [ngClass]="{'is-invalid':polyBagsStatusVar.touched && polyBagsStatusVar.errors}"
                                [disabled]="!editable">
                                <option value="ORDER_PLACED">Order Placed</option>
                                <option value="IN_TRANSIT">In Transit</option>
                                <option value="DELIVERED">Delivered</option>
                            </select>
                            <div *ngIf="polyBagsStatusVar?.invalid && (polyBagsStatusVar.dirty || polyBagsStatusVar.touched)" class="invalid-feedback">
                                <div *ngIf="polyBagsStatusVar.errors.required">
                                    Polybags Status is required.
                                </div>
                            </div>
                        </div>
                        <!--PolybagsEnds-->
                    </div>

            </div>
            
        </ng-container>
       
    </div>
</div>
