import { Directive } from '@angular/core';
import { Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';



@Directive({
   selector: '[appDragDrop]'
})
export class DragDropDirective {

   @Output() onFileDropped = new EventEmitter<any>();
   @Input() editable: boolean;

   @HostBinding('style.background-color') private background = 'transparent';
   @HostBinding('style.opacity') private opacity = '1';
   //@HostBinding('style.border') private border = '0px hidden #131313';
   //@HostBinding('style.border-radius') private border_radius = '0px';
   //@HostBinding('style.z-index') private z_index = '10000';

   //Dragover listener
   @HostListener('dragover', ['$event']) onDragOver(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      console.log(this.editable,evt);
      if (this.editable) {
         this.highlight();
      }

   }

   //Dragleave listener
   @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      if (this.editable) {
         this.reset();
      }
   }

   //Drop listener
   @HostListener('drop', ['$event']) public ondrop(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      if (this.editable) {
         this.reset();
         //
         let files: File[] = evt.dataTransfer.files;
         //
         if (files.length > 0) {
            //
            this.onFileDropped.emit(files);
         }
      }
   }

   highlight() {
      this.background = 'rgb(238, 214, 109)';
      this.opacity = '0.3'
     // this.border = '1px dashed #131313';
      //this.border_radius = '5px';
   }
   reset() {
      this.background = 'transparent'
      this.opacity = '1'
      //this.border = '0px hidden #131313';
     // this.border_radius = '0px';
   }

   @HostListener('paste', ['$event']) public paste(evt:ClipboardEvent) {
      //

      /*evt.preventDefault();
      evt.stopPropagation();
      if (this.editable) {
         this.reset();
      }*/
   }


}
