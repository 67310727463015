import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { AuthService } from '../../shared/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  //userAccessSubscription: Subscription;
  userAccess: UserAccess;

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastrService: ToastrService
  ) { //this.handleSubscriptions(); 
  }

  /*handleSubscriptions() {
     this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
       this.userAccess = access;
    });
 }*/

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    let url: string = state.url;
    if (!this.authService.isUserLoggedIns()) {
      this.authService.redirectUrl = url;
      this.router.navigate(['/auth/login']);
      return false;
    }
    else if (route.data && route.data.permissions) {
      console.log('Route Data',route.data);
      let retVal = this.allowedToRoute(route, url);
      return retVal;
    }
    else {
      return true;
    }
  }


  allowedToRoute(route: ActivatedRouteSnapshot, url: string): Observable<boolean> {
    return this.authService.userAccess.pipe(map(access => {
      if (access && access!==null) {
        this.userAccess = access;
        let permissions = route.data.permissions;
        let isAllowed = false;
        for (let index = 0; index < permissions.length; index++) {
          const permission = permissions[index];
          if (url.includes(permission.subPath)) {
            if (this.userAccess[permission.permission]) {
              isAllowed = true;
              break;
            }
          }
        }
        if (isAllowed) {
          return true;
        } else {
          this.toastrService.error('You do not have permission to access this url');
          this.router.navigate(['/auth/login']);
          return false;
        }
      }else{
        this.authService.redirectUrl=url;
        this.authService.loadRolesAndPermissions();
        return false;
      }
    }));

    //});
  }

}
