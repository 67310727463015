import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/service/auth.service';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/model/user.model';
import { ToastrService } from 'ngx-toastr';

@Component({
   selector: 'app-forgot-password',
   templateUrl: './forgot-password.component.html',
   styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {
   user: User;

   constructor(private authService: AuthService, private router: Router, private toastrService: ToastrService) { }

   ngOnInit() {
      this.user = new User();
   }

   /**
    * 
    */
   onFormSubmit() {
      this.user.userName = this.user.email;
      this.authService.forgotpassword(this.user).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.router.navigate(['login']);
         } else {
            console.error("ForgotPasswordComponent - Login Service Error...");
            this.toastrService.error(response.responseStatus.message);
         }
      }, error => {
         console.error(error);
      });
   }

}
