import { animateChild, trigger, transition, style, query, group, animate } from '@angular/animations';
/*
export const fadeAnimation =

    trigger('fadeAnimation', [

        transition( '* => *', [

            query(':enter', 
                [
                    style({ opacity: 0 })
                ], 
                { optional: true }
            ),

            query(':leave', 
                [
                    style({ opacity: 1 }),
                    animate('0.5s', style({ opacity: 0 }))
                ], 
                { optional: true }
            ),

            query(':enter', 
                [
                    style({ opacity: 0 }),
                    animate('0.5s', style({ opacity: 1 }))
                ], 
                { optional: true }
            )

        ])

    ]);

    export const fadeInAnimation =
    // trigger name for attaching this animation to an element using the [@triggerName] syntax
    trigger('fadeInAnimation', [

        // route 'enter' transition
        transition(':enter', [

            // css styles at start of transition
            style({ opacity: 0 }),

            // animation and styles at end of transition
            animate('3s', style({ opacity: 1 }))
        ]),
    ]);
    */
 
   export const slideInAnimation =
   trigger('routeAnimations', [
     transition('* <=> *', [
       style({ position: 'relative' }),
       query(':enter, :leave', [
         style({
           position: 'absolute',
           top: 0,
           left: 0,
           width: '100%'
         })
       ],{optional:true}),
       query(':enter', [
        style({ left: '0%' ,opacity:0}),
         
       ],{optional:true}),
       query(':leave', animateChild(),{optional:true}),
       group([
         query(':leave', [
          /* animate('500ms ease-out', style({ left: '100%'})),*/
          
           animate('300ms ease-out', style({opacity:0}))
         ],{optional:true}),
         query(':enter', [
           animate('500ms ease-out', style({opacity:1}))
         ],{optional:true})
       ]),
       query(':enter', animateChild(),{optional:true}),
     ])
   ]);