import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/shared/model/user.model';

import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { Blanks } from 'src/app/shared/model/blanks.model';
import { BlanksService } from 'src/app/shared/service/blanks.service';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
   selector: 'app-blanks',
   templateUrl: './blanks.component.html',
   styleUrls: ['./blanks.component.css']
})
export class BlanksComponent implements OnInit {

   createAction: boolean = false;
   viewAction: boolean = false;
   editAction: boolean = false;
   editable: boolean = false;

   constructor(private router: Router, private activatedRoute: ActivatedRoute, private blanksService: BlanksService,
      private sharedService: SharedService,private authService: AuthService, private toastrService: ToastrService) { }

   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;
   selectedBlanksId: string;
   blanks: Blanks;

   ngOnInit() {
      this.handleSubscriptions();
      this.evaluateAction();
      this.blanks = new Blanks();
      this.getBlanks();
      window.scrollTo(0, 0);
   }

   handleSubscriptions() {
      // Subscriptions
      this.userAccess = new UserAccess();
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });

      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
         this.currentLoggedInUser = currentLoggedInUser;
      });
   }

   evaluateAction() {
      const activatedRoutePath = this.activatedRoute.snapshot.routeConfig.path;
      if (this.activatedRoute.snapshot.paramMap.get('action') === "view") {
         this.viewAction = true;
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "create") {
         this.editAction = false;
         this.createAction = true;
         this.editable = true;
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "edit") {
         this.editAction = true;
         this.createAction = false;
         this.editable = true;
      }
   }

   onSubmit() {
      if (this.editable && !this.createAction) {
         this.updateBlanks();
      } else {
         this.createBlanks();
      }
   }

   getBlanks() {
      let blanksId = this.activatedRoute.snapshot.paramMap.get('id');
      this.selectedBlanksId = blanksId;
      if (blanksId) {
         this.blanksService.getBlanksById(blanksId).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.blanks = response.responsePayload;
            } else {
               this.toastrService.error(response.responseStatus.message);
            }
         });
      }
   }

   editBlanks(blanks: Blanks) {
      this.editAction = true;
      this.createAction = false;
      this.viewAction = false;
      this.editable = true;
   }

   cancelCreateOrUpdateBlanks(blanks: Blanks) {
      this.editable = false;
      if (blanks != null && blanks.id !== null && blanks.id !== undefined) {
         this.blanksService.getBlanksById(blanks.id).subscribe(response => {
            this.blanks = response.responsePayload;
         });
      } else {
         this.router.navigate(['blanks/list']);
      }
   }

   createBlanks() {
      this.blanksService.createBlanks(this.blanks).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.editable = false;
            this.getBlanks();
         } else {
            console.error("Blanks Component - create Blanks failed...", response.responseStatus.message);
            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }

   updateBlanks() {
      this.blanksService.updateBlanks(this.blanks).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.editable = false;
            this.getBlanks();
         } else {
            console.error("Blanks Component - update Blanks failed...", response.responseStatus.message);
            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }

   goBack() {
      this.router.navigate(['blanks/list']);
   }
}
