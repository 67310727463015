
<h2 mat-dialog-title>Assign Style to Printer</h2>
<mat-dialog-content class="mat-typography">



    
        <table class="table">
            <thead>
                <tr>
                    <th style="width: 30%;">Image</th>
                    <th style="width: 40%;">SKU</th>
                    <th style="width: 30%;">Attach</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="width: 100%;" colspan="3" class="f-10">
                        <div class="d-flex justify-content-end">
                            <div >
                                <button class="btn btn-danger btn-sm f-10" (click)="unAssignAllOrderDetail()"><i class="fas fa-unlink"></i> Unassign All</button>
                            </div>
                            <div class="pl-2">
                                <button class="btn btn-danger btn-sm f-10" (click)="aassignAllOrderDetail()"><i class="fas fa-link"></i> Assign All</button>
                            </div>
                        </div>
                    </td>
                    
                </tr>
                <ng-container *ngFor="let orderDetail of salesOrder.orderDetails">
                
                <tr *ngIf="orderDetail.product">
                    <td style="width: 30%;">
                        <div style="position: relative;">
                            <div style="position: absolute;" *ngIf="orderDetail.status==='CANCELLED'" >
                                <img src="assets/cancelledBanner.png" 
                                    class="" width="auto" height="50">
                            </div>
                            
                            <img [src]="orderDetail.product.productImages[0]?.thumbnailSignedUrl" 
                                                    class="img-thumbnail img-fluid zoom-4 "
                                                    style="display: block;  width:60px;  height:60px; min-width: 60px;"
                                                    [style.border-color]="'cadetblue'"
                                                    (error)="handleThumbnailError(orderDetail.product.productImages[0],$event)"
                                                />
                        </div>
                                            
                    </td>
                    <td class="f-10" style="width: 40%;">
                        {{orderDetail.product.sku}}
                    </td>
                    <div *ngIf="isAssignable(orderDetail.id); then thenBlock else elseBlock"></div>
                    <ng-template #thenBlock>
                        <td style="width: 30%;" class="f-10" *ngIf="orderDetail.status!=='CANCELLED'">
                            <button class="btn btn-info btn-sm f-10" (click)="attachOrderDetailToPrinter(orderDetail)"><i class="fas fa-link"></i> Assign</button>
                        </td>
                    </ng-template>
                    <ng-template #elseBlock>
                        <td style="width: 30%;" class="f-10" *ngIf="orderDetail.status!=='CANCELLED'">
                            <button class="btn btn-danger btn-sm f-10" (click)="unAttachOrderDetailToPrinter(orderDetail)"><i class="fas fa-unlink"></i> Unassign</button>
                        </td>
                    </ng-template>

                    
                </tr>
                
            </ng-container>
            </tbody>
        </table>
        
    

</mat-dialog-content>
<mat-dialog-actions align="end">
    <!--<button mat-button mat-dialog-close>Cancel</button>-->
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
  </mat-dialog-actions>
