import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SearchCriteriaBuilder } from 'src/app/shared/model/common/search-criteria-builder.model';

@Injectable({
  providedIn: 'root'
})
export class RoyaltyMismatchService {

   constructor(private http: HttpClient) { }

   readonly rooturl = environment.apiEndPoint;

   createRoyaltyMismatchExcel(searchCriteria: SearchCriteriaBuilder) {
      return this.http.post(this.rooturl + '/royaltyMismatch/export/excel', searchCriteria, { responseType: 'blob' });
   }

}
