import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { BrandService } from 'src/app/shared/service/brand.service';
import { Brand } from 'src/app/shared/model/brand.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { BrandRights } from 'src/app/shared/model/brand-rights.model';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { BrandRightsService } from 'src/app/shared/service/brand-rights.service';
import { ToastrService } from 'ngx-toastr';
import { Licensor } from 'src/app/shared/model/licensor.model';
import { LicensorService } from 'src/app/shared/service/licensor.service';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { Subscription } from 'rxjs';
import { Customer } from 'src/app/shared/model/customer.model';
import { FormControl } from '@angular/forms';
import { CustomerService } from 'src/app/shared/service/customer.service';
import { cloneDeep } from 'lodash';
import { AuthService } from 'src/app/shared/service/auth.service';
import { RoyaltyService } from 'src/app/shared/service/royalty.service';

@Component({
   selector: 'app-rights-overview',
   templateUrl: './rights-overview.component.html',
   styleUrls: ['./rights-overview.component.css']
})
export class RightsOverviewComponent implements OnInit {

   constructor(private sharedService: SharedService, private metadataService: MetadataService, private royaltyService: RoyaltyService,
      private brandRightsService: BrandRightsService, private toastrService: ToastrService, private licensorService: LicensorService,
      private location: Location, private customerService: CustomerService,private authService:AuthService) { }

   userAccessSubscription: Subscription;
   userAccess: UserAccess;

   sortColumns = [{ columnName: 'Licensor', sortByName: 'licensorName' },
   { columnName: 'Brand', sortByName: 'brandName' },
   { columnName: 'Contract Start Date', sortByName: 'contractStartDate' },
   { columnName: 'Contract End Date', sortByName: 'contractEndDate' },
   { columnName: 'Sell Off Date', sortByName: 'sellOffDate' },
   { columnName: 'Territory', sortByName: 'permittedTerritory' }
   ];

   selectedSortByName: string;
   sortMode: string = 'asc';

   brandRightsList: BrandRights[];
   filteredBrandRightsList: BrandRights[];

   licensorList: Licensor[] = null;
   brandList: Brand[] = null;

   editMode: boolean = false;

   addOrEditBrandRight: Brand = new Brand;

   ageCategoryList: string[];
   filteredAgeCategoryList: string[];

   productCategoryList: string[];
   filteredProductCategoryList: string[];

   territoryList: string[];
   filteredTerritoryList: string[];

   customerList: Customer[];
   filteredCustomerList: Customer[];

   selectetdTerritory: string;
   selectedBrandRightsObject: BrandRights;

   selectUnSelectAllRadio: string = null;

   ngOnInit(): void {
      this.handleSubscriptions();
      this.loadBrandRights();
      this.loadTerritories();
      this.loadLicensors();
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
   }

   loadBrandRights() {
      this.brandRightsService.getBrandRightsList().subscribe(response => {
         this.brandRightsList = response.responsePayload;
         this.filteredBrandRightsList = response.responsePayload;
         //
         this,this.filteredBrandRightsList = this.sharedService.sortListByPropertyName(this.filteredBrandRightsList,'licensorName');
         //
      });
   }

   loadLicensors() {
      this.licensorService.loadLicensors().subscribe((res: Licensor[]) => {
         this.licensorList = this.sharedService.sortListByPropertyName(res, 'licensorName');
      });
   }

   loadLicensorWithOnlyBrands(brandRights: BrandRights) {
      this.licensorService.loadLicensorWithOnlyBrands(brandRights.licensorId,false).subscribe((res) => {
         let selectedlicensor = res as Licensor;
         this.brandList = this.sharedService.sortListByPropertyName(selectedlicensor.brands, 'brandName');
         brandRights.brandList = this.brandList;
      });
   }

   onLicensorChange(event: any, brandRights: BrandRights) {
      //
      brandRights.brandList = null;
      brandRights.brandId = undefined;
      brandRights.brandName = undefined;
      this.loadLicensorWithOnlyBrands(brandRights);

   }

   onBrandChange($event) {
   }

   loadAgeCategories() {
      this.metadataService.getMetadataValueListByClassificationAndType('LICENSOR', 'AGE_CATEGORY').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.ageCategoryList = this.sharedService.sort(res.responsePayload);
            this.filteredAgeCategoryList = this.ageCategoryList;
         }
      });
   }

   loadRoyaltyProductCategories(){
      this.royaltyService.getRoyaltyProductCategoryList().subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.productCategoryList = this.sharedService.sort(res.responsePayload);
            this.filteredProductCategoryList = this.productCategoryList;
         }
      });
   }

   loadTerritories() {
      this.metadataService.getMetadataValueListByClassificationAndType('LICENSOR', 'TERRITORY').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.territoryList = this.sharedService.sort(res.responsePayload);
            this.filteredTerritoryList = this.territoryList;
         }
      });
   }

   onTerritoryChange(selectedTerritory, brandRights: BrandRights) {
      //
      this.selectetdTerritory = selectedTerritory;
      brandRights.exceptionCustomerList = [];
   }

   onAgeCategoryAddOrEdit(brandRights: BrandRights) {
      //('onAgeCategoryAddOrEdit = ', brandRights)
      this.selectedBrandRightsObject = brandRights;
      this.loadAgeCategories();
      this.selectUnSelectAllRadio = null;
      document.getElementById('ageCategoryModalToggler').click();
   }

   onAgeCategorySelection(event: any, item: string, brandRights: BrandRights) {
      //
      if (brandRights !== null && brandRights !== undefined) {
         this.selectedBrandRightsObject = brandRights;
      }
      if (event.target.checked) {
         if (!this.checkIfItemExists(this.selectedBrandRightsObject.ageCategoryList, item)) {
            this.selectedBrandRightsObject.ageCategoryList.push(item);
         }
      } else {
         this.selectedBrandRightsObject.ageCategoryList = this.selectedBrandRightsObject.ageCategoryList.filter(itemInList => itemInList !== item);
      }
   }

   onProductCategoryAddOrEdit(brandRights: BrandRights) {
      //
      this.selectedBrandRightsObject = brandRights;
      //this.loadProductCategories();
      this.loadRoyaltyProductCategories();
      this.selectUnSelectAllRadio = null;
      document.getElementById('productCategoryModalToggler').click();
   }

   onProductCategorySelection(event: any, item: string, brandRights: BrandRights) {
      //
      if (brandRights !== null && brandRights !== undefined) {
         this.selectedBrandRightsObject = brandRights;
      }
      if (event.target.checked) {
         if (!this.checkIfItemExists(this.selectedBrandRightsObject.productCategoryList, item)) {
            this.selectedBrandRightsObject.productCategoryList.push(item);
         }
      } else {
         this.selectedBrandRightsObject.productCategoryList = this.selectedBrandRightsObject.productCategoryList.filter(itemInList => itemInList !== item);
      }
   }

   onCustomerAddOrEdit(brandRights: BrandRights) {
      this.selectetdTerritory = brandRights.territory;
      this.selectedBrandRightsObject = brandRights;
      this.loadCustomers();
      this.selectUnSelectAllRadio = null;
      document.getElementById('customerSelectionModalToggler').click();
   }

   loadCustomers() {
      this.getCustomersByTerritory(this.selectetdTerritory);
   }

   getCustomersByTerritory(territory: string) {
      this.customerService.getCustomerListByTerritory(territory).subscribe(response => {
         this.customerList = response.responsePayload;
         this.customerList=this.sharedService.sortListByPropertyName(this.customerList,'customerName');
         this.filteredCustomerList = this.customerList;
      });
   }

   checkIfItemExists(list: string[], item: string) {
      if (list) {
         let temp = list.filter(itemInList => itemInList === item);
         return (temp.length == 1);
      } else {
         return false;
      }
   }

   isCustomerSelected(customer: Customer) {
      let temp = this.selectedBrandRightsObject.exceptionCustomerList.filter(itemInList => itemInList.customerName === customer.customerName);
      return (temp.length == 1);
   }

   isItemSelected(item: string, type: string): boolean {
      if (type === 'ProductCategory') {
         return this.checkIfItemExists(this.selectedBrandRightsObject?.productCategoryList, item);
      }
      if (type === 'AgeCategory') {
         return this.checkIfItemExists(this.selectedBrandRightsObject?.ageCategoryList, item);
      }
   }

   onCustomerStatusRadioSelection(selection: string) {
      if (selection === 'allpermitted') {
         this.selectedBrandRightsObject.allCustomersPermitted = true;
         this.selectedBrandRightsObject.allCustomersRestricted = false;
      } else {
         this.selectedBrandRightsObject.allCustomersPermitted = false;
         this.selectedBrandRightsObject.allCustomersRestricted = true;
      }
   }

   onCustomerSelection(event: any, customer: Customer, brandRights: BrandRights) {
      if (this.selectedBrandRightsObject == null || this.selectedBrandRightsObject === undefined) {
         this.selectedBrandRightsObject = brandRights;
      }
      if (event.target.checked) {
         this.selectedBrandRightsObject.exceptionCustomerList.push(customer);
      } else {
         this.selectedBrandRightsObject.exceptionCustomerList = this.selectedBrandRightsObject.exceptionCustomerList.filter(itemInList => itemInList.customerName !== customer.customerName);
      }
   }

   onSelectOrUnselectAll(status: string, type: string) {
      //
      this.selectUnSelectAllRadio = status;
      if (status === 'true') {
         if (type === 'ProductCategory') {
            this.selectedBrandRightsObject.productCategoryList = [];
            this.selectedBrandRightsObject.productCategoryList = this.selectedBrandRightsObject.productCategoryList.concat(this.productCategoryList);
         }
         if (type === 'AgeCategory') {
            this.selectedBrandRightsObject.ageCategoryList = [];
            this.selectedBrandRightsObject.ageCategoryList = this.selectedBrandRightsObject.ageCategoryList.concat(this.ageCategoryList);
         }
      } else {
         if (type === 'ProductCategory') {
            this.selectedBrandRightsObject.productCategoryList = [];
         }
         if (type === 'AgeCategory') {
            this.selectedBrandRightsObject.ageCategoryList = [];
         }
      }
   }

   addNewBrandRights() {
      let newBrandRights: BrandRights = new BrandRights;
      newBrandRights.editable = true;
      this.filteredBrandRightsList.push(newBrandRights);
      this.evaluateEditMode();
   }

   saveBrandRightsListOld() {
      //
      let brandRightsListToSave: BrandRights[] = this.filteredBrandRightsList.filter(item => item.editable === true);
      //
      this.brandRightsService.createOrUpdateBrandRightsList(brandRightsListToSave).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
         this.loadBrandRights();
         this.filteredBrandRightsList = this.filteredBrandRightsList.filter(item => item.editable = false);
         this.evaluateEditMode();
      }, (err) => {
         console.error(err);
         this.toastrService.error(JSON.stringify(err));
      });

   }

   saveBrandRightsList() {
      //
      this.filteredBrandRightsList.forEach((element, index) => {
         if (element.editable) {
            this.saveBrandRights(element, index);
         }
      });
   }


   editBrandRights(brandRights: BrandRights) {
      //
      brandRights.editable = true;
      this.loadLicensorWithOnlyBrands(brandRights);
      this.evaluateEditMode();
   }

   cancelBrandRights(brandRights: BrandRights, brandRightsIndex: string) {
      //
      brandRights.editable = false;
      if (brandRights.id !== null && brandRights.id !== undefined) {
         this.getBrandRights(brandRights.id, brandRightsIndex);
      } else {
         this.filteredBrandRightsList = this.filteredBrandRightsList.filter(item => item !== brandRights);
      }
      this.evaluateEditMode();
   }

   getBrandRights(brandRightsId: string, brandRightsIndex: string) {
      this.brandRightsService.getBrandRights(brandRightsId).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.filteredBrandRightsList[brandRightsIndex] = response.responsePayload;
         }
      });
   }

   saveBrandRights(brandRights: BrandRights, brandRightsIndex: any) {
      //
      this.brandRightsService.createOrUpdateBrandRights(brandRights).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let brandRigthsAfterSave = response.responsePayload;
            //
            brandRights.hasError = brandRigthsAfterSave.hasError;
            if (brandRigthsAfterSave.hasError) {
               brandRights.editable = true;
               brandRights.hasError = brandRigthsAfterSave.hasError;
               this.toastrService.error("Brand Rights Save Failed.");
            } else {
               this.getBrandRights(brandRigthsAfterSave.id, brandRightsIndex);
               brandRights.editable = false;
               this.toastrService.success(response.responseStatus.message);
            }
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
      }, (err) => {
         console.error(err);
         this.toastrService.error(JSON.stringify(err));
      });
      this.evaluateEditMode();
   }

   deleteBrandRights(brandRights: BrandRights, brandRightsIndex: string) {
      //
      this.brandRightsService.deleteBrandRights(brandRights.id).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.filteredBrandRightsList = this.filteredBrandRightsList.filter(item => item !== brandRights);
            let tempBrandRightsList : BrandRights[] =[];
            tempBrandRightsList = cloneDeep(this.filteredBrandRightsList);
            this.refreshBrandRightsList(tempBrandRightsList);
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
      }, (err) => {
         this.toastrService.error(JSON.stringify(err));
      });
      this.evaluateEditMode();
   }

   goBack() {
      this.location.back();
   }

   cancelEditMode() {
      //
      this.loadBrandRights();
      this.filteredBrandRightsList = this.filteredBrandRightsList.filter(item => item.editable = false);
      this.evaluateEditMode();
   }

   evaluateEditMode() {
      let temp: BrandRights[] = this.filteredBrandRightsList.filter(item => item.editable === true);
      //('evaluateEditMode =', temp)
      this.editMode = (temp.length >= 1);
   }

   onSortColumnChange($event: any) {
      //
      this.filteredBrandRightsList = this.sharedService.sortListByPropertyName(this.filteredBrandRightsList, this.selectedSortByName);
   }

   sortAsc() {
      this.sortMode = "asc";
      this.onSortColumnChange(null);
   }

   sortDesc() {
      this.sortMode = "desc";
      this.onSortColumnChange(null);
      this.filteredBrandRightsList.reverse();
   }

   onKeyupFilter(event, value) {
      if (event.key !== 'Shift') {
         if (value.trim().length > 0) {
            this.filteredBrandRightsList = this.filter(this.brandRightsList, value);
         } else {
            this.filteredBrandRightsList = this.brandRightsList;
         }
      }
   }

   filter(list: BrandRights[], value: string) {
      //
      let filteredList: BrandRights[] = list.filter(item => {
         //
         //
         return (
            item.licensorName?.toUpperCase().includes(value.toUpperCase()) ||
            item.brandName?.toUpperCase().includes(value.toUpperCase()) ||
            item.territory?.toUpperCase().includes(value.toUpperCase()) ||
            item.productCategories?.toUpperCase().includes(value.toUpperCase()) ||
            item.ageCategories?.toUpperCase().includes(value.toUpperCase()) ||
            item.notes?.toUpperCase().includes(value.toUpperCase())
         )
      });
      return filteredList;
   }

   onStartDateInput(brandRightsForm, index, $event) {
      //(brandRightsForm,index)
      let contractStartDateField: string = 'contractStartDate_' + index;
      let contractEndDateField: string = 'contractEndDate_' + index;
      let contractStartDateFormControl: FormControl = brandRightsForm.controls[index].controls[contractStartDateField];
      let contractEndDateFormControl: FormControl = brandRightsForm.controls[index].controls[contractEndDateField];
      //
      //
      const contractStartDateFieldValue: Date = new Date(contractStartDateFormControl.value);
      const contractEndDateFieldValue: Date = new Date(contractEndDateFormControl.value);
      if (contractStartDateFieldValue.getTime() > contractEndDateFieldValue.getTime()) {
         //
         //contractStartDateFormControl.setErrors({ ...contractStartDateFormControl.errors, 'startDateError': true });
      } else if (contractStartDateFieldValue.getTime() < contractEndDateFieldValue.getTime()) {
         //
      } else {
         //
      }

   }

   cloneBrandRights(brandRightsToBeCloned: BrandRights, brandRightsIndex: number){
      let clonedBrandRights: BrandRights = new BrandRights;
      clonedBrandRights = cloneDeep(brandRightsToBeCloned);
      let tempBrandRightsList : BrandRights[] =[];
      tempBrandRightsList = cloneDeep(this.filteredBrandRightsList);

      clonedBrandRights.id = null;
      clonedBrandRights.editable = true;
      this.loadLicensorWithOnlyBrands(clonedBrandRights);
      tempBrandRightsList.splice(brandRightsIndex+1, 0, clonedBrandRights);

      this.refreshBrandRightsList(tempBrandRightsList);
      this.evaluateEditMode();
   }

   refreshBrandRightsList(tempBrandRightsList : BrandRights[]){
      this.filteredBrandRightsList = [];
      tempBrandRightsList.forEach(brandRights => {
         this.filteredBrandRightsList.push(brandRights);
       });
   }

}
