import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ExternalLinks } from 'src/app/shared/model/common/external-links.model';
import { TrackingLinkModalComponent } from '../tracking-link-modal/tracking-link-modal.component';

@Component({
  selector: 'app-tracking-link',
  templateUrl: './tracking-link.component.html',
  styleUrls: ['./tracking-link.component.css']
})
export class TrackingLinkComponent implements OnInit {

  @Input() editable:boolean;
  @Input() linksArray:ExternalLinks[];
  @Input() type:string;
  @Input() subType:string;
  @Input() header:string='Tracking Links';
  @Input() sectionLabel:boolean;
  @Input() headerSize:string='4';

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openModal(){
    
    const confirmDialog = this.dialog.open(TrackingLinkModalComponent, {
      disableClose: true,
      data: {
        linksArray:this.linksArray,
        editable: this.editable,
        type: this.type,
        subType: this.subType,
      },
      width:"80%"
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
      }
    });
  }
}
