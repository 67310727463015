<form name="salesOrderForm" #salesOrderForm="ngForm" autocomplete="off" novalidate class="mb-3">
   <div class="p-2">
      <div class="card card-body p-1" [style.background]="salesOrder.orderType ==='Estimation'? 'rgba(8, 178, 201, 0.30)': 'rgba(248, 220, 129, 0.25)'"  *ngIf="!editable && !createAction">
      <div class="row" *ngIf="!editable && !createAction">
         
         <div class="col-lg-2">
            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">{{(salesOrder.orderType ==='Estimation')?'Estimation Number':'Sales Order Number'}}</label>
            <span class="form-control form-control-sm" style="background: transparent; font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.salesOrderNumber}}</span>
         </div>
         <div class="col-lg-2" *ngIf="!editable">
            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer Name</label>
            <span class="form-control form-control-sm" style="background: transparent; font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.customerName}}</span>
         </div>
         <div class="col-lg-2" *ngIf="!editable && (salesOrder?.customerPoNumbers?.length>0)">
            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer PO(s)</label>
            <span class="form-control form-control-sm" style="background: transparent; font-size: 13px; padding-left: .30rem;border-width: 0px;">
               {{ (salesOrder?.customerPoNumbers?.length>15)? (salesOrder?.customerPoNumbers | slice:0:15)+'..':(salesOrder?.customerPoNumbers) }}
            </span>
         </div>
         <div class="col-lg-1">
            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Ship Date</label>
            <span class="form-control form-control-sm" style="background: transparent; font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.orderShipDate | date:'mediumDate'}}</span>
         </div>
         <div class="col-lg-1">
            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Initial Submit Date</label>
            <span class="form-control form-control-sm" style="background: transparent; font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.orderInitialSubmitDate | date:'mediumDate'}}</span>
         </div>         

         <div class="col-lg-1" *ngIf="!editable && (salesOrder?.orderCancelDate)">
            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Cancel Date</label>
            <span class="form-control form-control-sm" style="background: transparent; font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.orderCancelDate| date:'mediumDate'}}</span>
         </div>
         <div class="col d-flex justify-content-end">
            <div>
               <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Production Status</label>
               <span class="form-control form-control-sm" style="background: transparent; font-size: 13px; padding-left: .30rem;border-width: 0px;">
                  <ng-container *ngIf="!salesOrder.editProductionStatus">
                     <div class="d-flex justify-content-between" style="align-items: center;">
                        <span *ngIf="salesOrder.orderType !=='Estimation'">{{'sales.productionStatus.'+salesOrder?.productionStatus | transform}}</span>
                        <span *ngIf="salesOrder.orderType ==='Estimation'">{{'estimation.productionStatus.'+salesOrder?.productionStatus | transform}}</span>
                        <button type="button" class="btn btn-sm btn-info ml-4 f-11" *ngIf="userAccess?.canChangeProductionStatus && salesOrder.status==='SUBMITTED' 
                                 && ( (salesOrder?.productionStatus!=='COSTING_GENERATION_PENDING' && salesOrder.productionStatus!=='COSTING_APPROVAL_PENDING'
                                 && salesOrder?.coordinatorUserName === currentLoggedInUser?.userName) || userAccess?.canOverrideProductionStatus || userAccess?.isSupportAdmin)
                                 && salesOrder.orderType !=='Estimation'"
                           (click)="editProductionStatus()"><i class="fas fa-pencil-alt fa-lg"></i></button>
                     </div>
                  </ng-container>
                  <ng-container *ngIf="salesOrder.editProductionStatus && userAccess?.canChangeProductionStatus">
                     <div class="d-flex">
                        <select class="form-control form-control-sm" [(ngModel)]="selectedProductionStatusTemp" name="productionStatus" #productionStatusVar="ngModel">
                           <option *ngFor="let productionStatus of productionStatusList" [ngValue]="productionStatus.key" [disabled]="productionStatus.disable">
                              {{'sales.productionStatus.'+productionStatus.key | transform}}</option>
                        </select>
                        <button type="button" class="btn btn-sm btn-success ml-2" (click)="confirmAndSaveProductionStatusChange()"><i class="fas fa-save"></i></button>
                        <button type="button" class="btn btn-sm btn-dark ml-2" (click)="cancelProductionStatusChange()"><i class="fas fa-window-close"></i></button>
                     </div>
                  </ng-container>
               </span>
            </div>
         </div>
      </div>
      </div>

      <mat-tab-group [selectedIndex]="selectedTab" (selectedTabChange)="selectedTabChange($event)" animationDuration="0ms">

         <!-- Product Sheet -->
         <mat-tab [disabled]="editableFileAttachments">

            <ng-template mat-tab-label>
               <h6><span class="ml-2 font-weight-normal f-13">{{(salesOrder.orderType ==='Estimation')?'Estimation Sheet':'Production Sheet'}}</span></h6>
            </ng-template>

            <div class="overflow-hidden">

               <!-- Start: Top Level Sales Order Detail -->
               <ng-container *ngIf="salesOrder.orderType !=='Estimation'">
                  <div id="details" class="collapse show" aria-labelledby="detailsHeader" data-parent="#detailsId">
                     <div class="card-body" style="padding: .5rem;">

                        <div class="row ml-10">

                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Sales Order Number</label>
                              <span class="form-control form-control-sm" style="font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.salesOrderNumber}}</span>
                           </div>

                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Date Created</label>
                              <span class="form-control form-control-sm" style="font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.orderCreatedDate  | date:'mediumDate'}}</span>
                           </div>

                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Sales Rep.</label>
                              <span class="form-control form-control-sm" style="font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.salesRepName}}</span>
                           </div>

                           <!--<div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer PO(s)#</label>
                              <span class="form-control form-control-sm" style="font-size: 11px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.customerPoNumbers}}</span>
                           </div>-->
                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Status</label>
                              <span class="form-control form-control-sm" style="font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.status | titlecase}}</span>
                           </div>

                           <div class="col-lg-1">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Total Units</label>
                              <span class="form-control form-control-sm" style="font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.totalUnits}}</span>
                           </div>

                           <div class="col-lg-1" *ngIf="salesOrder.totalSampleUnits && salesOrder.totalSampleUnits>0">
                              <label class="mb-0 ml-1 text-nowrap" style="font-size: .60rem; color: lightslategray;" >Total Units + Samples</label>
                              <span class="form-control form-control-sm" style="font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.totalUnits + salesOrder.totalSampleUnits}}</span>
                           </div>

                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Total Costs</label>
                              <span class="form-control form-control-sm" style="font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.totalCost | currency:salesOrder?.currency}}</span>
                           </div>

                        </div>

                        <div class="row ml-10">
                           <div class="col-lg-2" *ngIf="editable">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer Name</label>
                              <select class="form-control form-control-sm custom-select" [(ngModel)]="salesOrder.customerId" id="customerName" name="customerName" style="font-size: 11px;"
                                 #customerNameVar="ngModel" required (change)="onCustomerChange()" [ngClass]="{'is-invalid':customerNameVar.touched && customerNameVar.errors}"
                                 [disabled]="salesOrder.id && salesOrder.salesOrderNumber !=='DRAFT'">
                                 <option *ngFor="let customer of customerList" [ngValue]="customer.id">
                                    {{customer.customerName}}</option>
                              </select>
                              <div *ngIf="customerNameVar?.invalid && (customerNameVar.dirty || customerNameVar.touched)" class="invalid-feedback">
                                 <div *ngIf="customerNameVar.errors.required">
                                    Customer is required.
                                 </div>
                              </div>
                           </div>

                           <div class="col-lg-2" *ngIf="!editable">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer Name</label>
                              <input type="text" name="customerName" class="form-control form-control-sm" [(ngModel)]="salesOrder.customerName" style="font-size: 11px;" [disabled]="true">
                           </div>

                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Department</label>
                              <input name="customerDepartment" class="form-control form-control-sm" style="font-size: 11px;" #customerDepartment="ngModel" required [disabled]="!editable"
                                 [(ngModel)]="salesOrder.customerDepartment" [ngClass]="{'is-invalid':customerDepartment.touched && customerDepartment.errors}">
                              <div *ngIf="customerDepartment?.invalid && (customerDepartment.dirty || customerDepartment.touched)" class="invalid-feedback">
                                 <div *ngIf="customerDepartment.errors.required">
                                    Department is required.
                                 </div>
                              </div>
                           </div>
                           

                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color:lightslategray;">Buyer</label>
                              <input name="buyer" class="form-control form-control-sm" style="font-size: 11px;" #buyer required [disabled]="!editable" [(ngModel)]="salesOrder.customerBuyerName"
                                 [ngClass]="{'is-invalid':buyer.touched && buyer.errors}" maxlength="40" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Buyer'">
                              <div *ngIf="buyer?.invalid && (buyer.dirty || buyer.touched)" class="invalid-feedback">
                                 <div *ngIf="buyer.errors.required">
                                    Buyer is required.
                                 </div>
                              </div>
                           </div>


                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Ship Date</label>
                              <div class="d-flex d-inline">
                                 <input matInput [matDatepicker]="shipDatePicker" id="shipDate" name="shipDate" [(ngModel)]="salesOrder.orderShipDate" style="cursor: pointer;font-size: 11px;"
                                    class="form-control  form-control-sm" required #shipDateVar="ngModel" [ngClass]="{'is-invalid':shipDateVar.touched && shipDateVar.errors}" [disabled]="!editable">
                                 <mat-datepicker #shipDatePicker></mat-datepicker>
                                 <button class="btn btn-sm" type="button" (click)="shipDatePicker.open()"><i class="fas fa-calendar-alt"></i></button>
                              </div>
                              <mat-error *ngIf="shipDateVar?.errors?.required && (shipDateVar.dirty || shipDateVar.touched)" style="font-size: 80%;color: #dc3545">
                                 Order Ship Date is required.
                              </mat-error>
                              <div *ngIf="shipDateVar?.invalid && (shipDateVar.dirty || shipDateVar.touched)" class="invalid-feedback">
                                 <div *ngIf="shipDateVar.errors.required">Order Ship Date is required.</div>
                              </div>
                           </div>

                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Cancel Date</label>
                              <div class="d-flex d-inline">
                                 <input matInput [matDatepicker]="cancelDatePicker" id="cancelDate" name="cancelDate" [(ngModel)]="salesOrder.orderCancelDate" required [min]="salesOrder.orderShipDate"
                                    style="cursor: pointer;font-size: 11px;" class="form-control  form-control-sm" #cancelDateVar="ngModel"
                                    [ngClass]="{'is-invalid':cancelDateVar.touched && cancelDateVar.errors}" [disabled]="!editable">
                                 <mat-datepicker #cancelDatePicker></mat-datepicker>
                                 <button class="btn btn-sm" type="button" (click)="cancelDatePicker.open()"><i class="fas fa-calendar-alt"></i></button>
                              </div>
                              <mat-error *ngIf="cancelDateVar?.errors?.required && (cancelDateVar.dirty || cancelDateVar.touched)" style="font-size: 80%;color: #dc3545">
                                 Order Cancel Date is required.
                              </mat-error>
                              <div *ngIf="cancelDateVar?.invalid && (cancelDateVar.dirty || cancelDateVar.touched)" class="invalid-feedback">
                                 <div *ngIf="cancelDateVar.errors.required">Order Cancel Date is required.</div>
                              </div>
                           </div>

                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Currency</label>
                              <select class="form-control form-control-sm" style="font-size:11px;" name="currency" #currencyVar="ngModel" [disabled]="!editable" required [(ngModel)]="salesOrder.currency"
                                 [ngClass]="{'is-invalid':currencyVar.touched && currencyVar.errors}">
                                 <option *ngFor="let currency of currencyListFromMetadata" [ngValue]="currency">
                                    {{currency}}</option>
                              </select>
                              <div *ngIf="currencyVar?.invalid && (currencyVar.dirty || currencyVar.touched)" class="invalid-feedback">
                                 <div *ngIf="currencyVar.errors.required">
                                    Currency is required.
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="row ml-10 mt-2">
                           <div class="col-lg-2" *ngIf="(editable && salesRepListForCustomer?.length>1) ||  salesOrder.sharedSalesRepUserNameList?.length>0">
                              <label class="mb-0  ml-1" style="font-size: .60rem; color: lightslategray;">Shared with Sale Rep</label>
                              <div *ngIf="createAction || (editable && currentLoggedInUser?.userName===salesOrder.createdBy)">
                                 <div class="d-flex">
                                    <div class="ml-1">
                                       <select class="form-control form-control-sm custom-select" [(ngModel)]="selectedSalesRepUserName" name="salesPersonDropdown" #salesPersonDropdownVar="ngModel"
                                          [disabled]="!editable">
                                          <ng-container *ngFor="let salesRep of this.salesRepListForCustomer">
                                             <option
                                                *ngIf="currentLoggedInUser?.userName!==salesRep.salesPerson.userName && salesOrder.createdBy!==salesRep.salesPerson.userName && !salesOrder.sharedSalesRepUserNameList?.includes(salesRep.salesPerson.userName)"
                                                [ngValue]="salesRep.salesPerson.userName">
                                                {{salesRep.salesPerson.userName}}</option>
                                          </ng-container>
                                       </select>
                                    </div>
                                    <div class="ml-1">
                                       <button type="button" class="btn btn-info btn-sm" (click)="addSharedSalesPerson(selectedSalesRepUserName)" #addSharedSalesPersonVar><i
                                             class="fas fa-plus"></i></button>
                                    </div>
                                 </div>
                                 <div *ngFor="let sharedRepUserName of salesOrder.sharedSalesRepUserNameList;index as i">
                                    <div>
                                       <div class="chip color-{{i%4}} mt-1" *ngIf="editable">
                                          {{sharedRepUserName}}
                                          <span class="closebtn" (click)="removeSharedSalesPerson(sharedRepUserName)">&times;</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div *ngIf="!editable">
                                 <div *ngFor="let sharedRepUserName of salesOrder.sharedSalesRepUserNameList;index as i">
                                    <span class="f-13 pl-1" style="color: #495057;">{{sharedRepUserName}}</span>
                                 </div>
                              </div>
                           </div>


                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Production Coordinator</label>
                              <span class="form-control form-control-sm" style="font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder.productionCoordinator?.firstName}}
                                 {{salesOrder.productionCoordinator?.lastName}}</span>
                           </div>


                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Single PO</label>
                              <div class="d-inline">
                                 <input class="form-check-input ml-2" type="checkbox" name="singlePO" id="singlePO" [(ngModel)]="salesOrder.singlePO" [disabled]="!editable"
                                    (change)="onChangeSinglePOCheckBox($event)">
                                 <div *ngIf="salesOrder.singlePO">
                                    <input name="singlePoNumber" class="form-control form-control-sm" style="font-size: 11px;" #singlePoNumberVar="ngModel" required [disabled]="!editable"
                                       [(ngModel)]="salesOrder.singlePoNumber" [ngClass]="{'is-invalid':singlePoNumberVar.touched && singlePoNumberVar.errors}" (change)="onChangeSinglePONumberInput()">
                                    <div *ngIf="singlePoNumberVar?.invalid && (singlePoNumberVar.dirty || singlePoNumberVar.touched)" class="invalid-feedback">
                                       <div *ngIf="singlePoNumberVar.errors.required">
                                          PO Number is required.
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Order Type</label>
                              <select class="form-control form-control-sm" style="font-size:11px;" name="orderType" #orderTypeVar="ngModel" [disabled]="!createAction" required [(ngModel)]="salesOrder.orderType"
                                 [ngClass]="{'is-invalid':orderTypeVar.touched && orderTypeVar.errors}">
                                 <option value="Sales">Sales</option>
                                 <option value="Sample">Sample</option>
                              </select>
                              <div *ngIf="orderTypeVar?.invalid && (orderTypeVar.dirty || orderTypeVar.touched)" class="invalid-feedback">
                                 <div *ngIf="orderTypeVar.errors.required">
                                    Order Type is required.
                                 </div>
                              </div>
                           </div>


                        </div>
                     </div>
                  </div>
                  <!-- End: Top Level Sales Order Detail -->

                  <!-- Start: Shared Sales Reps
                  <div *ngIf="editable || salesOrder.sharedSalesRepUserNameList?.length>0">
                     <h6 class="mt-3 text-muted">Sales Order Sharing Detail</h6>
                     <hr class="mb-1 mt-2">

                     <div class="d-flex">
                        <div class="d-flex mr-1">
                           <div class="ml-1"  *ngIf="editable && currentLoggedInUser?.userName===salesOrder.createdBy">
                              <select class="form-control form-control-sm custom-select" [(ngModel)]="selectedSalesRepUserName" name="salesPersonDropdown" #salesPersonDropdownVar="ngModel" [disabled]="!editable">
                                 <ng-container *ngFor="let salesRep of this.salesRepListForCustomer">
                                    <option *ngIf="currentLoggedInUser?.userName!==salesRep.salesPerson.userName && salesOrder.createdBy!==salesRep.salesPerson.userName && !salesOrder.sharedSalesRepUserNameList?.includes(salesRep.salesPerson.userName)" [ngValue]="salesRep.salesPerson.userName">
                                       {{salesRep.salesPerson.userName}}</option>
                                 </ng-container>
                              </select>
                           </div>
                           <div class="ml-1"  *ngIf="editable && currentLoggedInUser?.userName===salesOrder.createdBy">
                              <button type="button" class="btn btn-info btn-sm"(click)="addSharedSalesPerson(selectedSalesRepUserName)" #addSharedSalesPersonVar><i class="fas fa-plus"></i></button>
                           </div>
                           <div class="m-1 ml-4" *ngFor="let sharedRepUserName of salesOrder.sharedSalesRepUserNameList;index as i">
                              <div>
                                 <div class="chip color-{{i%4}}">
                                    {{sharedRepUserName}}
                                    <span class="closebtn" *ngIf="editable && currentLoggedInUser?.userName===salesOrder.createdBy" (click)="removeSharedSalesPerson(sharedRepUserName)">&times;</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  End: Shared Sales Reps-->


                  <div *ngIf="editable || (!editable && salesOrder.sampleShipmentDetailList && salesOrder.sampleShipmentDetailList.length>0)">
                     <div class="d-flex">
                        <h6 class="mt-3 text-muted">Sample Shipment Details &nbsp;
                           <span *ngIf="editable" (click)="addSampleShipmentDetails()"><i class="fas fa-plus"></i></span>
                        </h6>

                     </div>
                     <hr class="mb-1 mt-2">

                     <div class="d-flex d-inline  p-1 row" style="border-radius: .2rem !important;">
                        <ng-container *ngFor="let sampleShipmentDetail of salesOrder.sampleShipmentDetailList; index as shipmentDetailIndex">
                           <div *ngIf="!sampleShipmentDetail.deleteFlag" class="col-3">

                              <label class="mb-0 ml-0" style="font-size: .60rem; color: lightslategray;align-self: center;" *ngIf="editable">
                                 <a style="border: 0px;" class="ml-0 mr-1" (click)="deleteSampleShipmentDetails(sampleShipmentDetail)"><i class="fas fa-trash fa-lg"
                                       style="color: #dc3545;cursor: pointer;"></i></a>
                              </label>

                              <textarea class="form-control" cols="50" rows="3" style="font-size:11px;" name="shipmentDetail_{{shipmentDetailIndex}}" #shipmentDetailVar="ngModel" [disabled]="!editable"
                                 required [(ngModel)]="sampleShipmentDetail.shipmentDetail"></textarea>
                           </div>
                        </ng-container>
                     </div>
                  </div>
               
               </ng-container>



               <!-- *********************************** Start: Top Level ESTIMATION Order Detail *****************************************-->

               <ng-container *ngIf="salesOrder.orderType ==='Estimation'">
               
                  <div id="details" class="collapse show" aria-labelledby="detailsHeader" data-parent="#detailsId">
                     <div class="card-body" style="padding: .5rem;">
               
                        <div class="row ml-10">
               
                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">{{(salesOrder.orderType ==='Estimation')?'Estimation Number':'Sales Order Number'}}</label>
                              <span class="form-control form-control-sm" style="font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.salesOrderNumber}}</span>
                           </div>
               
                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Date Created</label>
                              <span class="form-control form-control-sm" style="font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.orderCreatedDate  | date:'mediumDate'}}</span>
                           </div>
               
                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Sales Rep.</label>
                              <span class="form-control form-control-sm" style="font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.salesRepName}}</span>
                           </div>
               
                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Status</label>
                              <span class="form-control form-control-sm" style="font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.status | titlecase}}</span>
                           </div>
               
                           <div class="col-lg-1">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Total Units</label>
                              <span class="form-control form-control-sm" style="font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.totalUnits}}</span>
                           </div>
               
                           <div class="col-lg-1" *ngIf="salesOrder.totalSampleUnits && salesOrder.totalSampleUnits>0">
                              <label class="mb-0 ml-1 text-nowrap" style="font-size: .60rem; color: lightslategray;">Total Units + Samples</label>
                              <span class="form-control form-control-sm" style="font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.totalUnits + salesOrder.totalSampleUnits}}</span>
                           </div>
               
                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Total Costs</label>
                              <span class="form-control form-control-sm" style="font-size: 13px; padding-left: .30rem;border-width: 0px;">{{salesOrder?.totalCost | currency:salesOrder?.currency}}</span>
                           </div>
               
                        </div>
               
                        <div class="row ml-10">
                           <div class="col-lg-2" *ngIf="editable">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer Name</label>
                              <select class="form-control form-control-sm custom-select" [(ngModel)]="salesOrder.customerId" id="customerName" name="customerName" style="font-size: 11px;" #customerNameVar="ngModel"
                                 required (change)="onCustomerChange()" [ngClass]="{'is-invalid':customerNameVar.touched && customerNameVar.errors}"
                                 [disabled]="salesOrder.id && salesOrder.salesOrderNumber !=='DRAFT'">
                                 <option *ngFor="let customer of customerList" [ngValue]="customer.id">
                                    {{customer.customerName}}</option>
                              </select>
                              <div *ngIf="customerNameVar?.invalid && (customerNameVar.dirty || customerNameVar.touched)" class="invalid-feedback">
                                 <div *ngIf="customerNameVar.errors.required">
                                    Customer is required.
                                 </div>
                              </div>
                           </div>
               
                           <div class="col-lg-2" *ngIf="!editable">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer Name</label>
                              <input type="text" name="customerName" class="form-control form-control-sm" [(ngModel)]="salesOrder.customerName" style="font-size: 11px;" [disabled]="true">
                           </div>

                           <div class="col-lg-2" *ngIf="(editable && salesRepListForCustomer?.length>1) ||  salesOrder.sharedSalesRepUserNameList?.length>0 || (userAccess?.canSalesForAllCustomers && salesRepListForCustomer?.length>0)">
                              <label class="mb-0  ml-1" style="font-size: .60rem; color: lightslategray;">Shared with Sale Rep</label>
                              <div *ngIf="createAction || (editable && currentLoggedInUser?.userName===salesOrder.createdBy)">
                                 <div class="d-flex">
                                    <div class="ml-1">
                                       <select class="form-control form-control-sm custom-select" [(ngModel)]="selectedSalesRepUserName" name="salesPersonDropdown" #salesPersonDropdownVar="ngModel"
                                          [disabled]="!editable">
                                          <ng-container *ngFor="let salesRep of this.salesRepListForCustomer">
                                             <option
                                                *ngIf="currentLoggedInUser?.userName!==salesRep.salesPerson.userName && salesOrder.createdBy!==salesRep.salesPerson.userName && !salesOrder.sharedSalesRepUserNameList?.includes(salesRep.salesPerson.userName)"
                                                [ngValue]="salesRep.salesPerson.userName">
                                                {{salesRep.salesPerson.userName}}</option>
                                          </ng-container>
                                       </select>
                                    </div>
                                    <div class="ml-1">
                                       <button type="button" class="btn btn-info btn-sm" (click)="addSharedSalesPerson(selectedSalesRepUserName)" #addSharedSalesPersonVar><i
                                             class="fas fa-plus"></i></button>
                                    </div>
                                 </div>
                                 <div *ngFor="let sharedRepUserName of salesOrder.sharedSalesRepUserNameList;index as i">
                                    <div>
                                       <div class="chip color-{{i%4}} mt-1" *ngIf="editable">
                                          {{sharedRepUserName}}
                                          <span class="closebtn" (click)="removeSharedSalesPerson(sharedRepUserName)">&times;</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div *ngIf="!editable">
                                 <div *ngFor="let sharedRepUserName of salesOrder.sharedSalesRepUserNameList;index as i">
                                    <span class="f-13 pl-1" style="color: #495057;">{{sharedRepUserName}}</span>
                                 </div>
                              </div>
                           </div>
               
               
                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Ship Date</label>
                              <div class="d-flex d-inline">
                                 <input matInput [matDatepicker]="shipDatePicker" id="shipDate" name="shipDate" [(ngModel)]="salesOrder.orderShipDate" style="cursor: pointer;font-size: 11px;"
                                    class="form-control  form-control-sm" #shipDateVar="ngModel" [ngClass]="{'is-invalid':shipDateVar.touched && shipDateVar.errors}" [disabled]="!editable">
                                 <mat-datepicker #shipDatePicker></mat-datepicker>
                                 <button class="btn btn-sm" type="button" (click)="shipDatePicker.open()"><i class="fas fa-calendar-alt"></i></button>
                              </div>
                              <mat-error *ngIf="shipDateVar?.errors?.required && (shipDateVar.dirty || shipDateVar.touched)" style="font-size: 80%;color: #dc3545">
                                 Order Ship Date is required.
                              </mat-error>
                              <div *ngIf="shipDateVar?.invalid && (shipDateVar.dirty || shipDateVar.touched)" class="invalid-feedback">
                                 <div *ngIf="shipDateVar.errors.required">Order Ship Date is required.</div>
                              </div>
                           </div>
               
               
                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Currency</label>
                              <select class="form-control form-control-sm" style="font-size:11px;" name="currency" #currencyVar="ngModel" [disabled]="!editable" required [(ngModel)]="salesOrder.currency"
                                 [ngClass]="{'is-invalid':currencyVar.touched && currencyVar.errors}">
                                 <option *ngFor="let currency of currencyListFromMetadata" [ngValue]="currency">
                                    {{currency}}</option>
                              </select>
                              <div *ngIf="currencyVar?.invalid && (currencyVar.dirty || currencyVar.touched)" class="invalid-feedback">
                                 <div *ngIf="currencyVar.errors.required">
                                    Currency is required.
                                 </div>
                              </div>
                           </div>
               
               
                           <div class="col-lg-2">
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Order Type</label>
                              <select class="form-control form-control-sm" style="font-size:11px;" name="orderType" #orderTypeVar="ngModel" [disabled]="true" [(ngModel)]="salesOrder.orderType">
                                 <option value="Estimation">Estimation</option>
                              </select>
                           </div>
               
                        </div>
               
                     </div>
                  </div>
               
               </ng-container>

               <!-- ************************** End: Top Level ESTIMATION Order Detail *************************************** -->



               <div class="d-flex align-items-center mt-3">
                  <input type="hidden" style="display: hidden;" [value]="getActiveStylesCount()" #activeStyleCount>
                  <div class="mr-auto">
                     <h6 class=" text-muted">Order Details
                        <span class="badge badge-pill badge-success">Active - {{activeStyleCount.value - totalOrderDetailDeletedCount() }}</span>
                        <span class="badge badge-pill badge-danger" *ngIf="(salesOrder.orderDetails?.length - activeStyleCount.value - totalOrderDetailDeletedCount())>0">Cancelled - {{salesOrder.orderDetails?.length - activeStyleCount.value - totalOrderDetailDeletedCount()}}</span>
                     </h6>
                  </div>
                  
                  <div *ngIf="editable && getActiveStylesCount()>0" class="mr-5">
                     <button type="button" class="btn btn-danger btn-sm f-11" (click)="cancelSalesOrder()">Cancel All Styles</button>
                  </div>
                  <div *ngIf="userAccess?.canRequestForReCosting && !editable && salesOrder.status==='SUBMITTED' && (salesOrder.productionStatus==='PO_PLACEMENT_PENDING' || salesOrder.productionStatus==='FULFILLMENT_IN_PROGRESS')" class="mr-5">
                     <button type="button" class="btn btn-danger btn-sm f-11" (click)="requestForReCosting()">Request For Recosting</button>
                  </div>
                  
               </div>
               <hr>

               <!--<div id="orderDetails" class="collapse show" aria-labelledby="orderDetailsHeader" data-parent="#orderDetailsId">-->

               <!-- LOOP ORDER DEATAILS-->
               <ng-container *ngFor="let orderDetail of salesOrder.orderDetails; index as orderDetailIndex">
                  <app-order-detail [salesOrder]="salesOrder" [orderDetail]="orderDetail" [editable]="orderDetail.status==='CANCELLED'?false:editable" [createAction]="createAction" [orderDetailIndex]="orderDetailIndex"
                     [colspanIncrementCount]="colspanIncrementCount" [showSubOrderDetailPoCustomer]="showSubOrderDetailPoCustomer" [poCustomerList]="poCustomerList" [userAccess]="userAccess"
                     (event)="handleEventFromOrderDetails($event)" (cloneEvent)="handleCloneEventFromOrderDetails($event)" (changeSkuEvent)="handleChangeProductSku($event)" ></app-order-detail> <!--[collapseOnInit]="orderDetailIndex===0?false:true"-->
               </ng-container>

            </div>

            <div class="floatBtn d-flex flex-column">
               <a (click)="redirectToCatalogForCurrentSales()" class="btn btn-circle mb-2" style="background-color: #ffc107;" *ngIf="!editable && userAccess?.canCreateCatalogForSalesOrder>0"
                  matTooltip="Preview Catalog" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-file-alt fa-2x"></i></a>

               <ng-container *ngIf="salesOrder.orderType !=='Estimation'">                  
                  <button id="exportExcel" type="button" class="btn btn-circle  mb-2 bg-success" *ngIf="!editable && salesOrder.status ==='SUBMITTED' && userAccess?.canExportSalesOrder"
                     (click)="excelExportConfirmDialog()" matTooltip="Export Excel" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fa fa-file-excel fa-2x text-light"></i></button>
                  <button id="exportExcel" type="button" class="btn btn-circle  mb-2 bg-success"
                     *ngIf="!editable && salesOrder.status ==='SUBMITTED' && userAccess?.canExportSalesOrderRef && !userAccess?.canExportSalesOrder" (click)="exportExcel('SALES_REF')"
                     matTooltip="Export Excel" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fa fa-file-excel fa-2x text-light"></i></button>
                  <button id="addOrderDetail" type="button" class="btn btn-circle  mb-2 bg-navy-blue"
                     *ngIf="editable && userAccess?.canCreateSalesOrder && salesOrder.customerId && (salesOrder.singlePO && salesOrder.singlePoNumber || !salesOrder.singlePO)"
                     (click)="addNewOrderDetail()" matTooltip="Add Order Detail" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fa fa-plus text-light fa-2x"></i></button>
               </ng-container>

               <ng-container *ngIf="salesOrder.orderType ==='Estimation'">
                  <button id="exportExcel" type="button" class="btn btn-circle  mb-2 bg-success"
                     *ngIf="!editable && salesOrder.status ==='SUBMITTED'" (click)="exportExcel('PRINTER_ESTIMATION')"
                     matTooltip="Export Printer Estimation Excel" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fa fa-file-excel fa-2x text-light"></i></button>
                  <button id="createAndAddOrderDetail" type="button" class="btn btn-circle  mb-2 bg-success"
                     *ngIf="editable && userAccess?.canCreateSalesOrder && salesOrder.customerId"
                     (click)="createAndAddOrderDetail()" matTooltip="Create & Add New Product" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fa fa-plus text-light fa-2x"></i></button>
                  <button id="addOrderDetail" type="button" class="btn btn-circle  mb-2 bg-navy-blue"
                     *ngIf="editable && userAccess?.canCreateSalesOrder && salesOrder.customerId"
                     (click)="addNewOrderDetail()" matTooltip="Add Order Detail" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fa fa-plus text-light fa-2x"></i></button>
               </ng-container>

               <button type="button" class=" btn btn-circle bg-info mb-2" (click)="saveSalesOrder()" *ngIf="editable" matTooltip="Save Order" matTooltipPosition="left" matTooltipClass="bg-dark"
                  [disabled]="salesOrderForm.invalid || salesOrder.orderDetails?.length===0"><i class="fas fa-save fa-2x" style="color:white"></i>
               </button>
               <button type="button" class="btn btn-circle bg-info mb-2" (click)="submitSalesOrder()" *ngIf="!editable && salesOrder.status ==='DRAFT' && userAccess?.canSubmitSalesOrder"
                  matTooltip="Submit Order" matTooltipPosition="left" matTooltipClass="bg-dark" [disabled]="salesOrderForm.invalid || salesOrder.orderDetails?.length===0"><i
                     class="fas fa-sign-in-alt fa-2x" style="color:white;"></i>
               </button>
               <a class="btn btn-circle bg-dark mb-2" (click)="revokeSalesOrderSubmission()" *ngIf="!editable && salesOrder.status ==='SUBMITTED' && userAccess?.canUnsubmitSalesOrder"
                  matTooltip="Unsubmit" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-undo-alt fa-2x" style="color:white"></i></a>
               <a class="btn btn-circle bg-dark mb-2" (click)="cancelEditOrCreateSalesOrder()" *ngIf="editable" matTooltip="Cancel Edit" matTooltipPosition="left" matTooltipClass="bg-dark"><i
                     class="fas fa-times fa-2x" style="color:white"></i></a>
               <a class="btn btn-circle bg-dark mb-2" (click)="editSalesOrder()" *ngIf="!editable && salesOrder.status ==='DRAFT' && userAccess?.canEditSalesOrder" matTooltip="Edit"
                  matTooltipPosition="left" matTooltipClass="bg-dark">
                  <i class="fas fa-pencil-alt fa-lg" style="color: white;"></i></a>
               <button type="button" class="btn btn-circle bg-secondary mb-2" matTooltip="Scroll To Top" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'});" matTooltipPosition="left"
                  matTooltipClass="bg-dark">
                  <i class="fas fa-arrow-up fa-2x" style="color:white"></i>
               </button>
               <a (click)="goBack()" class="btn btn-circle" style="background-color: tomato;" *ngIf="!editable" matTooltip="Exit" matTooltipPosition="left" matTooltipClass="bg-dark"><i
                     class="fas fa-arrow-left  fa-2x"></i></a>
            </div>


         </mat-tab>

         <!--
         <mat-tab>
            <ng-template mat-tab-label>
               <h5>Cost Sheet <span class="ml-2 font-weight-normal"></span></h5>
            </ng-template>
         </mat-tab>
         -->
         
            <mat-tab *ngIf="salesOrder.id" [disabled]="editable" label="fileAttachment">
               <ng-template mat-tab-label>
                  <span class="ml-2 font-weight-normal">
                     <h6><span class="ml-2 font-weight-normal f-13">File attachments</span></h6>
                  </span>
               </ng-template>

               <div class="overflow-hidden">

                  <div class="mr-2">
                     <div class="row mt-2 d-flex justify-content-end">
                        <div class="col-2" *ngIf="!editableFileAttachments && (userAccess?.canUploadCustomerPO || userAccess?.canUploadSalesFiles)">
                           <button type="button" class="btn btn-block  btn-info btn-sm text-nowrap ml-1 " (click)="editFileAttachments()">
                              Edit
                           </button>
                        </div>
                        <div class="col-2" *ngIf="editableFileAttachments">
                           <button type="button" class=" btn btn-block btn-info btn-sm text-nowrap ml-1" (click)="saveFileAttachments()">
                              Save
                           </button>
                        </div>
                        <div class="col-2" *ngIf="editableFileAttachments">
                           <button type="button" class="btn btn-block btn-dark btn-sm text-nowrap ml-1" (click)="cancelFileAttachments()">
                              Cancel
                           </button>
                        </div>
                     </div>
                  </div>

               
                     
               
                  <div class="mt-1">
                     <mat-tab-group>
                        <ng-container *ngIf="salesOrder.orderType ==='Estimation'">
                           <mat-tab>
                              <ng-template mat-tab-label>
                                 <h6><span class="ml-2 font-weight-normal f-13">Estimation Files</span></h6>
                              </ng-template>
                              <div class="overflow-hidden mt-3">
                                 <div class="row ">
                                    <div class="col-6">
                                             <app-attachment-operations [header]="'Spec Sheet(s)'" [editable]="editableFileAttachments" [createAction]="createAction" [attachments]="salesOrder.estimationSpecSheet"
                                          [canUpload]="userAccess?.canUploadCustomerPO || userAccess?.canUploadSalesFiles" [canRenameFile]="userAccess?.canRenameCustomerPO || userAccess?.canRenameSalesFiles" [canDownload]="userAccess?.canDownloadCustomerPO || userAccess?.canDownloadSalesFiles"
                                          [canDelete]="userAccess?.canDeleteCustomerPO || userAccess?.canDeleteSalesFiles" [category]="'SALES_ESTIMATION_SPECSHEET_FILES'" [showHeader]="true" [headerSize]="'6'">
                                          </app-attachment-operations>
                                    </div>
                                 </div>
                              </div>
                           </mat-tab>
                        </ng-container>
                        <ng-container *ngIf="salesOrder.orderType !=='Estimation'">
                        <mat-tab>
                           <ng-template mat-tab-label>
                              <h6><span class="ml-2 font-weight-normal f-13">Customer Files</span></h6>
                           </ng-template>
                           <div class="overflow-hidden mt-3">
                              <div class="row ">
                                 <div class="col-6">
                                          <app-attachment-operations [header]="'Customer PO(s)'" [editable]="editableFileAttachments" [createAction]="createAction" [attachments]="salesOrder.customerPOAttachments"
                                       [canUpload]="userAccess?.canUploadCustomerPO || userAccess?.canUploadSalesFiles" [canRenameFile]="userAccess?.canRenameCustomerPO || userAccess?.canRenameSalesFiles" [canDownload]="userAccess?.canDownloadCustomerPO || userAccess?.canDownloadSalesFiles"
                                       [canDelete]="userAccess?.canDeleteCustomerPO || userAccess?.canDeleteSalesFiles" [category]="'SALES_CUSTOMER_PO_FILES'" [showHeader]="true" [headerSize]="'6'">
                                       </app-attachment-operations>
                                 </div>
                                 <div class="col-6">
                                    <app-attachment-operations [header]="' Distribution List'" [editable]="editableFileAttachments" [createAction]="createAction" [attachments]="salesOrder.distributionListAttachments"
                                 [canUpload]="userAccess?.canUploadCustomerPO || userAccess?.canUploadSalesFiles" [canRenameFile]="userAccess?.canRenameCustomerPO || userAccess?.canRenameSalesFiles" [canDownload]="userAccess?.canDownloadCustomerPO || userAccess?.canDownloadSalesFiles"
                                 [canDelete]="userAccess?.canDeleteCustomerPO || userAccess?.canDeleteSalesFiles" [category]="'SALES_DIST_LIST_FILES'" [showHeader]="true" [headerSize]="'6'">
                                 </app-attachment-operations>
                           </div>
                              </div>
                              <div class="row mt-5">
                              <div class="col-6">
                                 <app-attachment-operations [header]="'PNL Speciality'" [editable]="editableFileAttachments" [createAction]="createAction" [isGroupedAttachments]="true" [attachments]="salesOrder.pnlSpecialityAttachments" [groupItems]="getPnlSpecialityProducts(salesOrder)" [groupItemDisplayVar]="'sku'" [groupItemReferenceVar]="'id'"
                              [canUpload]="userAccess?.canUploadCustomerPO || userAccess?.canUploadSalesFiles" [canRenameFile]="userAccess?.canRenameCustomerPO || userAccess?.canRenameSalesFiles" [canDownload]="userAccess?.canDownloadCustomerPO || userAccess?.canDownloadSalesFiles"
                              [canDelete]="userAccess?.canDeleteCustomerPO || userAccess?.canDeleteSalesFiles" [category]="'SALES_PNL_SPECIALITY_FILES'" [showHeader]="true" [headerSize]="'6'">
                                    </app-attachment-operations>
                                 </div>
                              </div>
                           </div>
                        </mat-tab>
                        <mat-tab>
                           <ng-template mat-tab-label>
                              <h6><span class="ml-2 font-weight-normal f-13">Blanks Files</span></h6>
                           </ng-template>
                           <div class="overflow-hidden mt-3">
                              <div class="row ">
                                 <div class="col-6">
                                          <app-attachment-operations [header]="'Blanks PO(s)'" [editable]="editableFileAttachments" [createAction]="createAction" [attachments]="salesOrder.blanksPOAttachments"
                                       [canUpload]="userAccess?.canUploadSalesFiles" [canRenameFile]="userAccess?.canRenameSalesFiles" [canDownload]="userAccess?.canDownloadSalesFiles"
                                       [canDelete]="userAccess?.canDeleteSalesFiles" [category]="'SALES_BLANKS_PO_FILES'" [showHeader]="true" [headerSize]="'6'" [canQuickView]="userAccess?.canQuickViewSalesFiles">
                                    </app-attachment-operations>
                                 </div>
                                 <div class="col-6">
                                    <app-attachment-operations [header]="'Blanks Packing List'" [editable]="editableFileAttachments" [createAction]="createAction" [attachments]="salesOrder.blanksPackagingAttachments"
                                    [canUpload]="userAccess?.canUploadSalesFiles" [canRenameFile]="userAccess?.canRenameSalesFiles" [canDownload]="userAccess?.canDownloadSalesFiles"
                                    [canDelete]="userAccess?.canDeleteSalesFiles" [category]="'SALES_BLANKS_PACKAGING_FILES'" [showHeader]="true" [headerSize]="'6'" [canQuickView]="userAccess?.canQuickViewSalesFiles">
                                    </app-attachment-operations>
                                 </div>
                                 <div class="col-6">
                                    <app-attachment-operations [header]="'Blanks Proof Of Delivery'" [editable]="editableFileAttachments" [createAction]="createAction" [attachments]="salesOrder.blanksPodAttachments"
                                    [canUpload]="userAccess?.canUploadSalesFiles" [canRenameFile]="userAccess?.canRenameSalesFiles" [canDownload]="userAccess?.canDownloadSalesFiles"
                                    [canDelete]="userAccess?.canDeleteSalesFiles" [category]="'SALES_BLANKS_POD_FILES'" [showHeader]="true" [headerSize]="'6'" [canQuickView]="userAccess?.canQuickViewSalesFiles">
                                    </app-attachment-operations>
                                 </div>
                              </div>
                           </div> 
                        </mat-tab>

                        <mat-tab>
                           <ng-template mat-tab-label>
                              <h6><span class="ml-2 font-weight-normal f-13">Printer Files</span></h6>
                           </ng-template>
                           <div class="overflow-hidden mt-3">
                              <div class="row ">
                                 <div class="col-6">
                                          <app-attachment-operations [header]="'Printer PO(s)'" [editable]="editableFileAttachments" [createAction]="createAction" 
                                          [isGroupedAttachments]="true"
                                          [groupItems]="getPrinterPOAttachmentGroupDetails(salesOrder)" [groupItemDisplayVar]="'name'" [groupItemReferenceVar]="'id'"
                                          [attachments]="salesOrder.printerPOAttachments"
                                       [canUpload]="userAccess?.canUploadSalesFiles" [canRenameFile]="userAccess?.canRenameSalesFiles" [canDownload]="userAccess?.canDownloadSalesFiles"
                                       [canDelete]="userAccess?.canDeleteSalesFiles" [category]="'SALES_PRINTER_PO_FILES'" [showHeader]="true" [headerSize]="'6'" [canQuickView]="userAccess?.canQuickViewSalesFiles">
                                    </app-attachment-operations>
                                 </div>
                                 <div class="col-6">
                                    <app-attachment-operations [header]="'Printer Packing List'" [editable]="editableFileAttachments" [createAction]="createAction" [attachments]="salesOrder.printerPackagingAttachments"
                                    [canUpload]="userAccess?.canUploadSalesFiles" [canRenameFile]="userAccess?.canRenameSalesFiles" [canDownload]="userAccess?.canDownloadSalesFiles"
                                    [canDelete]="userAccess?.canDeleteSalesFiles" [category]="'SALES_PRINTER_PACKAGING_FILES'" [showHeader]="true" [headerSize]="'6'" [canQuickView]="userAccess?.canQuickViewSalesFiles">
                                    </app-attachment-operations>
                                 </div>
                              </div>
                           </div> 
                        </mat-tab>

                        <mat-tab>
                           <ng-template mat-tab-label>
                              <h6><span class="ml-2 font-weight-normal f-13">Finishing Sheet</span></h6>
                           </ng-template>
                           <div class="overflow-hidden mt-3">
                              <div class="row ">
                                 <div class="col-6">
                                    <app-attachment-operations [header]="'Finishing Sheet'" [editable]="editableFileAttachments" [createAction]="createAction" [attachments]="salesOrder.finishingSheetAttachments"
                                       [canUpload]="userAccess?.canUploadSalesFiles" [canRenameFile]="userAccess?.canRenameSalesFiles" [canDownload]="userAccess?.canDownloadSalesFiles"
                                       [canDelete]="userAccess?.canDeleteSalesFiles" [category]="'SALES_FINISHING_SHEET_FILES'" [showHeader]="true" [headerSize]="'6'" [canQuickView]="userAccess?.canQuickViewSalesFiles">
                                    </app-attachment-operations>
                                 </div>
                              </div>
                           </div>
                        </mat-tab>

                        <mat-tab>
                           <ng-template mat-tab-label>
                              <h6><span class="ml-2 font-weight-normal f-13">Reference</span></h6>
                           </ng-template>
                           <div class="overflow-hidden mt-3">
                              <div class="row ">
                                 <div class="col-6">
                                          <app-attachment-operations [header]="'Email Confirmation'" [editable]="editableFileAttachments" [createAction]="createAction" [attachments]="salesOrder.emailConfirmationAttachments"
                                       [canUpload]="userAccess?.canUploadSalesFiles" [canRenameFile]="userAccess?.canRenameSalesFiles" [canDownload]="userAccess?.canDownloadSalesFiles"
                                       [canDelete]="userAccess?.canDeleteSalesFiles" [category]="'SALES_EMAIL_CONFIRMATION_FILES'" [showHeader]="true" [headerSize]="'6'" [canQuickView]="userAccess?.canQuickViewSalesFiles">
                                    </app-attachment-operations>
                                 </div>
                                 <div class="col-6">
                                    <app-attachment-operations [header]="'Charge Back Communication'" [editable]="editableFileAttachments" [createAction]="createAction" [attachments]="salesOrder.chargeBackCommunicationAttachments"
                                    [canUpload]="userAccess?.canUploadSalesFiles" [canRenameFile]="userAccess?.canRenameSalesFiles" [canDownload]="userAccess?.canDownloadSalesFiles"
                                    [canDelete]="userAccess?.canDeleteSalesFiles" [category]="'SALES_CHARGE_BACK_COMMUNICATION_FILES'" [showHeader]="true" [headerSize]="'6'" [canQuickView]="userAccess?.canQuickViewSalesFiles">
                                    </app-attachment-operations>
                                 </div>
                              </div>
                              <div class="row mt-3">
                                 <div class="col-6">
                                          <app-attachment-operations [header]="'Customer Discounts'" [editable]="editableFileAttachments" [createAction]="createAction" [attachments]="salesOrder.customerDiscountsAttachments"
                                       [canUpload]="userAccess?.canUploadSalesFiles" [canRenameFile]="userAccess?.canRenameSalesFiles" [canDownload]="userAccess?.canDownloadSalesFiles"
                                       [canDelete]="userAccess?.canDeleteSalesFiles" [category]="'SALES_CUST_DISCOUNT_FILES'" [showHeader]="true" [headerSize]="'6'" [canQuickView]="userAccess?.canQuickViewSalesFiles">
                                    </app-attachment-operations>
                                 </div>
                                 <div class="col-6">
                                    <app-attachment-operations [header]="'Freight Quotes'" [editable]="editableFileAttachments" [createAction]="createAction" [attachments]="salesOrder.freightQuotesAttachments"
                                    [canUpload]="userAccess?.canUploadSalesFiles" [canRenameFile]="userAccess?.canRenameSalesFiles" [canDownload]="userAccess?.canDownloadSalesFiles"
                                    [canDelete]="userAccess?.canDeleteSalesFiles" [category]="'SALES_FREIGHT_QUOTES_FILES'" [showHeader]="true" [headerSize]="'6'" [canQuickView]="userAccess?.canQuickViewSalesFiles">
                                    </app-attachment-operations>
                                 </div>
                              </div>
                           </div> 
                        </mat-tab>
                     
                        <mat-tab>
                           <ng-template mat-tab-label>
                              <h6><span class="ml-2 font-weight-normal f-13">File Closing</span></h6>
                           </ng-template>
                           <div class="overflow-hidden mt-3">
                              <div class="row">
                                 <div class="col-6">
                                    <div class="mt-4">
                                       <app-attachment-operations [header]="'Order Processing Checklist'" [editable]="editableFileAttachments" [createAction]="createAction" [attachments]="salesOrder.orderProcessingChecklistAttachments"
                                             [canUpload]="userAccess?.canUploadSalesFiles" [canRenameFile]="userAccess?.canRenameSalesFiles" [canDownload]="userAccess?.canDownloadSalesFiles"
                                             [canDelete]="userAccess?.canDeleteSalesFiles" [category]="'SALES_ORDER_PROCESSING_CHECKLIST_FILES'" [showHeader]="true" [headerSize]="'6'" [canQuickView]="userAccess?.canQuickViewSalesFiles">
                                          </app-attachment-operations>
                                       </div>
                                       <div class="mt-4">
                                          <app-attachment-operations [header]="'Invoices'" [editable]="editableFileAttachments" [createAction]="createAction" [attachments]="salesOrder.invoicesAttachments"
                                          [canUpload]="userAccess?.canUploadSalesFiles" [canRenameFile]="userAccess?.canRenameSalesFiles" [canDownload]="userAccess?.canDownloadSalesFiles"
                                          [canDelete]="userAccess?.canDeleteSalesFiles" [category]="'SALES_INVOICES_FILES'" [showHeader]="true" [headerSize]="'6'" [canQuickView]="userAccess?.canQuickViewSalesFiles">
                                          </app-attachment-operations>
                                       </div>
                                       <div class="mt-4">
                                          <app-attachment-operations [header]="'Signed BOL/POD'" [editable]="editableFileAttachments" [createAction]="createAction" [attachments]="salesOrder.signedBolAttachments"
                                          [canUpload]="userAccess?.canUploadSalesFiles" [canRenameFile]="userAccess?.canRenameSalesFiles" [canDownload]="userAccess?.canDownloadSalesFiles"
                                          [canDelete]="userAccess?.canDeleteSalesFiles" [category]="'SALES_SIGNED_BOL_FILES'" [showHeader]="true" [headerSize]="'6'" [canQuickView]="userAccess?.canQuickViewSalesFiles">
                                          </app-attachment-operations>
                                       </div>

                                 </div>
                                 <div class="col-6  border-left " *ngIf="salesOrder && salesOrder.id">
                                    <div class="mt-4 ">
                                       <app-tracking-link [editable]="editableFileAttachments" [linksArray]="fileClosingExternalLinks" [type]="'SALES_ORDER'" [sectionLabel]="true" [headerSize]="'6'" [header]="'External File Links'"
                                          [subType]="'FILE_CLOSING'"></app-tracking-link>
                                    </div>
                                    <div style="margin-top: 84px;">
                                       <app-notes [editable]="true" [parentId]="salesOrder.id" [type]="'SALES_ORDER'" [subType]="'FILE_CLOSING'" [notesHeaderText]="'Notes'"></app-notes>
                                    </div>
                                    
                                    <!--<textarea class="form-control" rows="20"></textarea>-->
                                 </div>
                              </div>
                              <div class="row ">
                                 
                              </div>
                              <div class="row mt-3">
                              
                              </div>
                           </div> 
                        </mat-tab>
                        </ng-container>
                     </mat-tab-group>
                  </div>

               </div>
            </mat-tab>
         

         <mat-tab *ngIf="salesOrder.id" label="history">
            <ng-template mat-tab-label>
               <span class="ml-2 font-weight-normal">
                  <h6><span class="ml-2 font-weight-normal f-13">History</span></h6>
               </span>
            </ng-template>

            <!--<div class="overflow-hidden mt-3 border-box" *ngIf="salesOrder.salesOrderActivityList && salesOrder.salesOrderActivityList.length>0">
               <div class="mt-2">
                  <ng-container *ngFor="let salesOrderActivity of salesOrder.salesOrderActivityList; index as statusDetailIndex">
                     <div><span class="font-weight-light ml-3">Last status updated By <em class="font-weight-bold">{{salesOrderActivity.lastModifiedBy}}</em> on <em
                              class="font-weight-bold">{{salesOrderActivity.lastModifiedDate | date:'medium'}}</em></span>
                        <div class="ml-3">
                           <ul class="ml-3">
                              <li class="text-muted">Current Status: {{salesOrderActivity.status}}</li>
                              <li class="text-muted">Activity: {{salesOrderActivity.activity}}</li>
                           </ul>
                        </div>
                     </div>
                     <hr class="mt-1 mb-1">

                  </ng-container>
               </div>
            </div>
            <div class="mt-2" *ngIf="salesOrder.salesOrderActivityList?.length===0">
               <p class="text-muted"><em>No History</em></p>
            </div>
            <div class="mt-3 col-8" *ngIf="salesOrder.salesOrderHistoryExport">
               <app-attachment-operations [header]="'Production Sheet Revision History'" 
                  [attachments]="salesOrder.salesOrderHistoryExport" 
                  [canDownload]="userAccess?.canUploadCustomerPO" 
                  [category]="'SALES_ORDER_HISTORY_EXPORT'" [showHeader]="true">
               </app-attachment-operations>
            </div>-->

            <!--Accordion Changes-->
            <div class="mt-2" *ngIf="salesOrderActivityList && salesOrderActivityList.length>0">

               <mat-accordion>
                  <mat-expansion-panel hideToggle disabled class="bg-dark">
                     <mat-expansion-panel-header>
                        <table class="table table-borderless my-0">
                           <thead>
                              <tr class="text-white">
                                 <th class=" align-middle text-left text-nowrap " style="width: 33%;">Date/Time</th>
                                 <th class=" align-middle text-left text-nowrap " style="width: 33%;">Activity</th>
                                 <th class=" align-middle text-left text-nowrap " style="width: 33%;">User</th>
                              </tr>
                           </thead>
                        </table>
                     </mat-expansion-panel-header>
                  </mat-expansion-panel>
                  <mat-expansion-panel *ngFor="let salesOrderActivity of salesOrderActivityList" (opened)="loadAdditionalDetailForSalesOrderActivity(salesOrderActivity,'LOAD_ATTACHMENT')"
                     #bt>
                     <!--(opened)="getInventoryLevel2(inventoryItemView.itemId,inventoryItemView)"
                           (closed)="hideInevntoryDetailsLevel2(inventoryItemView)"-->
                     <mat-expansion-panel-header [style]="bt.expanded?'background:bisque':'background:#fff'">
                        <table class="table table-borderless my-0">
                           <thead>
                              <tr class="f-12">
                                 <td class=" align-middle text-left text-nowrap " style="width: 33%;">{{salesOrderActivity.lastModifiedDate | date:'medium'}}</td>
                                 <td class=" align-middle text-left text-nowrap " style="width: 33%;" *ngIf="!salesOrderActivity.activity.includes(' ')">{{salesOrderActivity.activity | titlecase}}</td>
                                 <td class=" align-middle text-left text-nowrap " style="width: 33%;" *ngIf="salesOrderActivity.activity.includes(' ')">{{salesOrderActivity.activity}}</td>
                                 <td class=" align-middle text-left text-nowrap " style="width: 33%;">{{salesOrderActivity.userName}}</td>
                              </tr>
                           </thead>
                        </table>
                     </mat-expansion-panel-header>

                     <div class="py-0">
                        <ul class="ml-1">
                           <li class="text-muted" *ngIf="salesOrderActivity.notes">
                              <div>
                                 <p class="text-wrap">Notes: {{salesOrderActivity.notes}}</p>
                              </div>
                           </li>
                           <li class="text-muted" *ngIf="salesOrderActivity.attachment">
                              <div *ngIf="userAccess?.canExportSalesOrderHistory">
                                 Snapshot of updated production sheet: <a class="btn btn-sm ml-2" [href]="salesOrderActivity.attachment.fileSignedUrl" target="_blank"
                                    [download]="salesOrderActivity.attachment.originalFileName"><i class="fas fa-download"></i><em> &nbsp;<i
                                          class="fa fa-file-excel"></i>&nbsp;{{salesOrderActivity.attachment.originalFileName}}</em></a>
                              </div>
                              <div *ngIf="salesOrderActivity.changeContainerArr && salesOrderActivity.changeContainerArr.length>0">
                                 <app-change-tree  [changeContainerArr]="salesOrderActivity.changeContainerArr"></app-change-tree>
                              </div>
                           </li>
                        </ul>
                     </div>

                  </mat-expansion-panel>

               </mat-accordion>
            </div>
            <!--Accordion Changes ends-->

         </mat-tab>

         <ng-container *ngIf="salesOrder.orderType !=='Estimation'">
            <mat-tab label="productionTracker" *ngIf="salesOrder && salesOrder.id && userAccess?.canViewProductionTracker && salesOrder.status==='SUBMITTED'">
               <ng-template mat-tab-label>
                  <span class="ml-2 font-weight-normal">
                     <h6><span class="ml-2 font-weight-normal f-13">WIP</span></h6>
                  </span>
               </ng-template>
               <div class="overflow-hidden">
                  <app-production-tracker  [eventBasedLazyLoad]="true" [events]="prodTrackerEventsSubject.asObservable()" [salesOrder]="salesOrder" [userAccess]="userAccess"></app-production-tracker>
               </div>
            </mat-tab>
         </ng-container>

         <mat-tab label="costing" *ngIf="salesOrder.id && ((userAccess?.canViewCostingSection && salesOrder.orderType !=='Estimation') || (userAccess?.canViewEstimationCosting && salesOrder.orderType ==='Estimation'))">
            <ng-template mat-tab-label>
               <h6 class="costingTab"><span class="ml-2 font-weight-normal f-13">{{salesOrder.orderType ==='Estimation'? 'Estimation Costing': 'Costing'}}</span><span class="ml-2 font-weight-normal"></span></h6>
            </ng-template>
            <div class="overflow-hidden">
               <app-allocation-sheet [events]="costingEventsSubject.asObservable()" [salesOrderId]="salesOrder.id" [salesOrder]="salesOrder" (statusChanged)="statusChangedEventHandler($event)"></app-allocation-sheet>
            </div>
         </mat-tab>

         <ng-container *ngIf="salesOrder.orderType !=='Estimation'">
            <mat-tab label="blankSummary" *ngIf="userAccess?.canViewBlankSummary && salesOrder.id">
            <ng-template mat-tab-label>
               <h6><span class="ml-2 font-weight-normal f-13">Blank Summary </span><span class="ml-2 font-weight-normal"></span></h6>
            </ng-template>
            <div class="overflow-hidden ">
               <app-blank-summary [events]="blankSummaryEventsSubject.asObservable()" [salesOrderNumber]="salesOrder.salesOrderNumber" [salesOrderId]="salesOrder.id"></app-blank-summary>
            </div>
            </mat-tab>
         </ng-container>
         
         <ng-container *ngIf="salesOrder && salesOrder.orderType !=='Estimation'">
            <mat-tab label="printerSummary" *ngIf="userAccess?.canViewPrinterSummary && salesOrder.id">
            <ng-template mat-tab-label>
               <h6><span class="ml-2 font-weight-normal f-13">Printer Summary </span><span class="ml-2 font-weight-normal"></span></h6>
            </ng-template>
            <div class="overflow-hidden ">
               <app-printer-summary [events]="printSummaryEventsSubject.asObservable()" [salesOrder]="salesOrder" [salesOrderNumber]="salesOrder.salesOrderNumber" [salesOrderId]="salesOrder.id"></app-printer-summary>
         
            </div>
            </mat-tab>
         </ng-container>
         <ng-container *ngIf="createItemAuditErrorsCount && createItemAuditErrorsCount>0">
            <mat-tab label="createItemAuditErrors">
            <ng-template mat-tab-label>
               <h6><span class="ml-2 font-weight-normal f-13">Sage Item Errors <span class="ml-2 font-weight-normal badge badge-pill badge-danger">{{createItemAuditErrorsCount}}</span></span></h6>
            </ng-template>
            <div class="overflow-hidden mt-2">
               <app-create-item-audit [events]="createItemAuditEventsSubject.asObservable()" [origination]="'SALES_ORDER'" [trigger]="'RETRY'" [originationReferenceId]="salesOrder.id"></app-create-item-audit>
         
            </div>
            </mat-tab>
         </ng-container>
      </mat-tab-group>

   </div>

   <button id="skuSelectionModalToggler" type="button" style="display: none;" data-toggle="modal" data-target="#selectSkusModal"></button>
   <button id="skuCreationModalToggler" type="button" style="display: none;" data-toggle="modal" data-target="#createSkusModal"></button>

</form>


<div class="modal fade" id="selectSkusModal" tabindex="-1" role="dialog" aria-labelledby="selectSkusModalTitle" aria-hidden="true">
   <div class="modal-dialog modal-dialog-scrollable " style="max-width: 97%;" role="document">
      <div class="modal-content">
         <div class="modal-body">
            <div *ngIf="showProductScreen">
               <app-product-list [hideFloatBtns]="true" [hideAddProduct]="true" [stopProductNavigation]="true" [stopProductDeletion]="true" [enableExclusiveSelection]="true" [showAdhocProduct]="salesOrder.orderType ==='Estimation'"></app-product-list>
            </div>
         </div>
         <div class="modal-footer p-1" style="background-color: #f7f7f7;">
            <button type="button" class="btn btn-sm btn-primary" *ngIf="orderDetailOperationType === 'ADD'" (click)="addSelectedProductsToSalesOrder('skuSelectionModalToggler')">Ok</button>
            <button type="button" class="btn btn-sm btn-primary" *ngIf="orderDetailOperationType === 'UPDATE'" (click)="replaceProductSku()">Replace</button>
            <button type="button" class="btn btn-sm btn-primary" *ngIf="orderDetailOperationType === 'CLONE'" (click)="cloneOrderDetailWithSelectedProduct()">Ok</button>
            <button type="button" id="closePrepackDetailModal" class="btn btn-sm btn-secondary mr-7" (click)="showProductScreen=false;" data-dismiss="modal">Cancel</button>
         </div>
      </div>
   </div>
</div>


<div class="modal fade" id="createSkusModal" tabindex="-1" role="dialog" aria-labelledby="createSkusModalTitle" aria-hidden="true">
   <div class="modal-dialog modal-dialog-scrollable " style="max-width: 97%;" role="document">
      <div class="modal-content">
         <div class="modal-body">
            <div *ngIf="showCreateProductScreen">
               <app-product [createAdhocProductForEstimation]="salesOrder" (productCreated)="adhocProductCreatedFroEstimation($event)"> </app-product>
            </div>
         </div>
         <div class="modal-footer p-1" style="background-color: #f7f7f7;">
           <!--<button type="button" class="btn btn-sm btn-primary" *ngIf="orderDetailOperationType === 'ADD'" (click)="addSelectedProductsToSalesOrder()">Ok</button>-->
         <!--
            <button type="button" class="btn btn-sm btn-primary" *ngIf="orderDetailOperationType === 'UPDATE'" (click)="replaceProductSku()">Replace</button>
            <button type="button" class="btn btn-sm btn-primary" *ngIf="orderDetailOperationType === 'CLONE'" (click)="cloneOrderDetailWithSelectedProduct()">Ok</button>
         -->
            <button type="button" id="closeCreateSkusModalModal" class="btn btn-sm btn-secondary mr-7" (click)="showCreateProductScreen=false;" data-dismiss="modal">Cancel</button>
         </div>
      </div>
   </div>
</div>