import { Component, OnInit } from '@angular/core';
import { ProductCatalogService } from 'src/app/shared/service/product-catalog.service';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import { Product, CatalogList, ProductAttachment } from 'src/app/shared/model/product.model';
import { ProductCatalogRequest, TextFormat } from 'src/app/shared/model/product-catalog-request.model';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { transferArrayItem, moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { ServiceStatus } from 'src/app/shared/model/service-status.model';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared/service/shared.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { SelectFromListDialogComponent } from 'src/app/common/select-from-list-dialog/select-from-list-dialog.component';


@Component({
  selector: 'app-catalog-view',
  templateUrl: './catalog-view.component.html',
  styleUrls: ['./catalog-view.component.css']
})
export class CatalogViewComponent implements OnInit {
  backgroundEditable:boolean=false;
  frontPageRequired:boolean=false;
  printSkuCode:boolean=true;
  printApprovalStatus:boolean=true;
  printTreatment:boolean=false;
  printColor:boolean=false;
  compressed:boolean=false;
  selectedTab: number=1;
  sortType="NONE";
  groupType="NONE";
  catalogBackgroundDataUrl:Product;
  backGroundUrlList:ProductAttachment[]=[];
  creatingCatalog:boolean;
  canvasLoadedArr:string[]=[];
  currentId:string;
  productList:Product[]=[];
  masterCatalogList:CatalogList[];
  selectedProductForCatalogCount:number=0;

  selectedBackgroundUrl='';
  itemsPerRow:number=2;
  rowsPerPage:number=2;
  pageSize='LETTER';
  orientation='L';
  imageBorder='thin';
  borderColor='white';
  imageBackGround='transparent';
  advanced=true;
  textDetailsArr:TextFormat[]=[new TextFormat(24,'Heading'),new TextFormat(16,'Sub Heading'),new TextFormat(12,'Description')];//[this.catalogFrontPageHeading,this.catalogFrontPageSubHeading,this.catalogFrontPageDesctiption];
  catalogFrontPageMainImageUrl:string|ArrayBuffer;
  fontSizeArr=[8,10,12,14,16,18,20,22,24,26,28,30,32,34,36,38,40];
  catalogName: string='Catalog';
 constructor(private dialog: MatDialog,private router: Router,private productCatalogService:ProductCatalogService, private toastrService: ToastrService,private sharedService:SharedService) { 
  }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.setCatalogSetting();
    this.refreshList();
    this.getBackgroundList();
    this.segregateProductsIntoPages(false);
    this.canvasLoadedArr=[];
    this.creatingCatalog=false;
    if(screen.width < 1024) {
      document.getElementById("viewport").setAttribute("content", "width=1024px; initial-scale=1.0");
    }
    this.catalogBackgroundDataUrl=new Product;
    window.scrollTo(0, 0);
  }

  frontPageToggle(){
    if(this.frontPageRequired){
      this.selectedTab=0;
    }else{
      this.selectedTab=1;
    }
  }
  printApprovalStatusToggle(){}

  printSkuCodeToggle(){}

  editBackground(){
    this.backgroundEditable=true;
  }
  saveEditBackground(){
    this.backgroundEditable=false;
  }
  
  sortProductsInPages(){
    if(this.sortType==='NONE'){
    }else{
     
     this.masterCatalogList.forEach(list=>{
      var sortTypeTemp=this.sortType;
      list.products=list.products.sort(function (a, b) {
        var valueForSort = function(type: string, typeItem: Product): string{
          if(type==='GARMENT'){
            return typeItem.garment;
          }else if(type==='CATEGORY'){
            return typeItem.category;
          } else if(type==='COLOR'){
            return typeItem.color;
          }else if(type==='TREATMENT'){
            return typeItem.treatment;
          } else if(type==='PRINT_EFFECT'){
            return typeItem.printEffect;
          }else if(type==='BRAND_CATEGORY'){
            return typeItem.brandCategory;
          }else if(type==='SKU'){
            return typeItem.sku;
          }else if(type==='BRAND_NAME'){
            return typeItem.brandName;
          }
        }
        var textA = valueForSort(sortTypeTemp,a);
        var textB = valueForSort(sortTypeTemp,b);
        return (textA < textB) ? -1 : (textA> textB) ? 1 : 0;
      
      });
    });
  }
}

  segregateProductsIntoPages(changeSortType:boolean){
    if(this.groupType==='NONE'){
      this.masterCatalogList=[];
      let list:CatalogList={classification:'NONE',classificationValue:'Selected',products:this.productList};
      this.masterCatalogList.push(list);
    }else{
    this.masterCatalogList=[];
    let typeArr:string[] =this.getTypeListForSelectedProducts(this.groupType);
    if(typeArr){
    typeArr.forEach(typeItem=>{
      let temp:Product[]=this.getFilteredCatalogListForType(this.groupType,typeItem);
      if(temp.length>0){
        let list:CatalogList={classification:this.groupType,classificationValue:typeItem,products:temp};
        this.masterCatalogList.push(list);
      }
    });
  }
  }
  if(changeSortType){
    this.sortType="NONE";
  }
  }
  getFilteredCatalogListForType(type: string, typeItem: string): Product[] {
    if(type==='GARMENT'){
      return this.productList.filter(element=>element.garment===typeItem);
    }else if(type==='CATEGORY'){
      return this.productList.filter(element=>element.category===typeItem);
    } else if(type==='COLOR'){
      return this.productList.filter(element=>element.color===typeItem);
    }else if(type==='TREATMENT'){
      return this.productList.filter(element=>element.treatment===typeItem);
    } else if(type==='PRINT_EFFECT'){
      return this.productList.filter(element=>element.printEffect===typeItem);
    } else if(type==='BRAND_CATEGORY'){
      return this.productList.filter(element=>element.brandCategory===typeItem);
    }
  }

  getTypeListForSelectedProducts(type: string): string[] {
    if(type==='GARMENT'){
      return [...new Set(this.productList.map(item => item.garment))];
    }else if(type==='CATEGORY'){
      return [...new Set(this.productList.map(item => item.category))];
    } else if(type==='COLOR'){
      return [...new Set(this.productList.map(item => item.color))];
    }else if(type==='TREATMENT'){
      return [...new Set(this.productList.map(item => item.treatment))];
    } else if(type==='PRINT_EFFECT'){
      return [...new Set(this.productList.map(item => item.printEffect))];
    } else if(type==='BRAND_CATEGORY'){
      return [...new Set(this.productList.map(item => item.brandCategory))];
    }
    
  }

  ngOnDestroy(){
    if(screen.width < 1024) {
      document.getElementById("viewport").setAttribute("content", "width=device-width, initial-scale=1");
    }
  this.createCatalogSetting();
  }

  selectedForCatalog(event,product){
    this.refreshList();
    let selectedForCatalog=product as Product;
    if(event.target.checked){
      this.productCatalogService.addProduct(selectedForCatalog);
    }else{
      this.productCatalogService.removeProduct(product);
    }
    this.productList=this.productCatalogService.selectedProductsForCatalog;
    this.selectedProductForCatalogCount=this.productCatalogService.selectedProductsForCatalog.length;
    this.segregateProductsIntoPages(false);
    this.sortProductsInPages();
  }

  isProductSelectedForCatalog(id:string):boolean{
    return this.productCatalogService.isProductSelected(id);
  }
  refreshList(){
    if(this.groupType!=='NONE'){
      if(this.masterCatalogList.length>0){
        this.productCatalogService.selectedProductsForCatalog=[];
        this.masterCatalogList.forEach(list => {
          if(list.products!==undefined && list.products.length>0){
            list.products.forEach(product => {
              this.productCatalogService.addProduct(product);
            });
           
          }
        });
      }
    }
    this.productList=this.productCatalogService.selectedProductsForCatalog;
  }

  tabChanged(event){
    this.selectedTab=event.index;
  }
  groupLeftMove(fromIndex:number){
    this.groupMove(fromIndex,fromIndex-1);
    this.selectedTab=fromIndex;
    this.refreshList();
  }

  groupRightMove(fromIndex:number){
    this.groupMove(fromIndex,fromIndex+1);
    this.selectedTab=fromIndex+2;
    this.refreshList();
  }

  groupMove(fromIndex, toIndex) {
    let cloned = this.masterCatalogList.map(x => Object.assign({}, x));
    moveItemInArray(cloned,fromIndex,toIndex);
    this.masterCatalogList=cloned;
}

  selectedBackground(id:string){
    if(this.selectedBackgroundUrl===id){
      this.selectedBackgroundUrl='Not';
    }else{
    this.selectedBackgroundUrl=id;
    }
  }

  getImageURI(url:string):string{
    return url;
  }


  

 createBackendPdf(){
  this.creatingCatalog=true;

  let req:ProductCatalogRequest=this.createCatalogSetting();

   /*this.productList.forEach(element => {
    req.catalogProducts.push(element.productImages[0].fileSignedUrl);
   });*/
   req.catalogProductsGrouped=this.masterCatalogList;
   this.productCatalogService.createCatalog(req).subscribe(response => {
     this.downLoadFile(response, "application/pdf",req.pdfName);
     this.creatingCatalog=false;
     this.deleteBackendPdf(req.pdfName);
  });
 }

 deleteBackendPdf(pdfName:string){
  this.productCatalogService.deleteCatalogFile(pdfName).subscribe(response => {
 });
 }
 /**
     * Method is use to download file.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
  downLoadFile(data: any, type: string, fileName: String) {
    let blob = new Blob([data], { type: type });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName + ".pdf");
    }
    else {
      let url = window.URL.createObjectURL(blob);
      let pwa = window.open(url);
      if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert('Please disable your Pop-up blocker and try again.');
      }
    }

  } 
  /**************BACKGROUND UPLOAD STARTS */
  fileSelectedDragDrop(files: File[], category: string) {
    if (files.length > 0) {
      for (let index = 0; index < files.length; index++) {
        setTimeout(() => {
          const element = files[index];
          this.uploadSelectedFile(element,category);
        });
      }
    }
  }

  deleteFile(attachmentId:string,category:string){
    this.productCatalogService.deleteFile(attachmentId,category).subscribe((res)=>{
      let status:ServiceStatus=res as ServiceStatus;
      if(status.status==='SUCCESS'){
        this.getBackgroundList();
        this.toastrService.success(status.message);
      }else{
        this.toastrService.error(status.message);
      }
    },(err)=>{
      this.toastrService.error(err);
    });
  }

  uploadSelectedFile(element:File,filecategory: string) {
    let fileName=element.name;
    this.toastrService.info('Uploading '+fileName);
    this.productCatalogService.uploadFileForCatalogWithProgress(element,filecategory).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            //
            break;
          case HttpEventType.ResponseHeader:
            //
            break;
          case HttpEventType.UploadProgress:
            break;
          case HttpEventType.Response:
            //
            this.toastrService.success(fileName+' Uploaded Successfully');
            this.getBackgroundList(event.body.id);
            setTimeout(() => {
              
            }, 1500000);
        }
      }, (err) => {
        this.toastrService.error(' Error while uploading file - '+fileName);
        console.error('Error while upload' + err);
      }
    );
  }
  getBackgroundList(newImageUrl?:string) {
    var category:string='CATALOG_BG';
    this.productCatalogService.loadFileList(category).subscribe((res) => {
      this.backGroundUrlList = res as ProductAttachment[];
      if(this.backGroundUrlList!==undefined && this.backGroundUrlList.length>0){
        if(this.selectedBackgroundUrl && this.selectedBackgroundUrl!==null){
        }else{
        this.selectedBackgroundUrl=this.backGroundUrlList[0].id;
        }
      }
    },
      (err) => {
        console.error(err);
      });


  }
/**************BACKGROUND UPLOAD ENDS */
createCatalogSetting(): ProductCatalogRequest {
  let cs:ProductCatalogRequest=new ProductCatalogRequest;
  cs.catalogName=this.catalogName;
  cs.frontPageRequired=this.frontPageRequired;
  cs.printSkuCode=this.printSkuCode;
  cs.printTreatment=this.printTreatment;
  cs.printColor=this.printColor;
  cs.printApprovalStatus=this.printApprovalStatus;
  cs.compressed=this.compressed;
  cs.backgroundUrl=this.selectedBackgroundUrl;
  cs.border=this.imageBorder;
  cs.borderColor=this.borderColor;
  cs.imageBackGround=this.imageBackGround;
  cs.itemPerRowCount=this.itemsPerRow;
  cs.orientation=this.orientation;
  cs.pageSize=this.pageSize;
  cs.rowPerPageCount=this.rowsPerPage;
  cs.sortType=this.sortType;
  cs.groupType=this.groupType;
  cs.catalogFrontPageHeading=this.textDetailsArr[0];
  cs.catalogFrontPageSubHeading=this.textDetailsArr[1];
  cs.catalogFrontPageDesctiption=this.textDetailsArr[2];
  cs.catalogFrontPageMainImageUrl=this.catalogFrontPageMainImageUrl;
  let pdfNameTemp=this.newGuid();
  cs.pdfName=pdfNameTemp;

  this.productCatalogService.catalogSettings=cs;
  return cs;
}

  setCatalogSetting() {
    let cs: ProductCatalogRequest = this.productCatalogService.catalogSettings;
    if (cs !== undefined) {
      this.catalogName=cs.catalogName;
      this.selectedBackgroundUrl = cs.backgroundUrl
      this.imageBorder = cs.border;
      this.borderColor = cs.borderColor;
      this.imageBackGround = cs.imageBackGround;
      this.itemsPerRow = cs.itemPerRowCount;
      this.orientation = cs.orientation;
      this.pageSize = cs.pageSize;
      this.rowsPerPage = cs.rowPerPageCount;
      this.groupType=cs.groupType;
      this.sortType=cs.sortType;
      this.frontPageRequired=cs.frontPageRequired;
      this.printSkuCode=cs.printSkuCode;
      this.printColor=cs.printColor;
      this.printTreatment=cs.printTreatment;
      this.printApprovalStatus=cs.printApprovalStatus;
      this.compressed=cs.compressed;
      this.catalogFrontPageMainImageUrl=cs.catalogFrontPageMainImageUrl;
      if(cs.catalogFrontPageHeading===undefined){
        cs.catalogFrontPageHeading=new TextFormat(24,'Heading');
      }
      if(cs.catalogFrontPageSubHeading===undefined){
        cs.catalogFrontPageSubHeading=new TextFormat(16,'Sub Heading');
      }
      if(cs.catalogFrontPageDesctiption===undefined){
        cs.catalogFrontPageDesctiption=new TextFormat(12,'Description');
      }
      this.textDetailsArr=[cs.catalogFrontPageHeading,cs.catalogFrontPageSubHeading,cs.catalogFrontPageDesctiption];
      let sortTypeTemp=cs.sortType;
      this.segregateProductsIntoPages(true);
      this.sortType=sortTypeTemp;
      this.sortProductsInPages();
    }
  }

newGuid():string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

/********************************FILE Upload For Front Page********** */
frontPageFileSelectedDragDrop(files: File[], category: string) {
  if (files.length > 0) {
    for (let index = 0; index < files.length; index++) {
      var mimeType = files[index].type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
    }
    /*for (let index = 0; index < 1; index++) {*/
    //for (let index = 0; index < files.length; index++) {/// Use this for loop to allow multiple upload
      setTimeout(()=>{
      var reader = new FileReader();
      //this.imagePath = files;
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.catalogFrontPageMainImageUrl= reader.result;
      }
    });
    
  }
}

resetCatalogFrontPageMainImageUrl(){
  this.catalogFrontPageMainImageUrl=undefined;
}
/**********************************FILE Upload Ends *************8888 */

/****************PRODUCT APPROVAL STATUS COLROS*********** */
approvalStatusColor(status:string):string{
  if(this.sharedService.producatStatusColor.get(status)!==undefined){
     return this.sharedService.producatStatusColor.get(status);
  }else{
     return '';
  }

}
  handleThumbnailError(productAttachment: ProductAttachment, event) {
    if (isNaN(productAttachment.thumbnailErrorCount)) {
      productAttachment.thumbnailErrorCount = 0;
    }
    event.target.src = productAttachment.fileSignedUrl;
    var x: number = productAttachment.thumbnailErrorCount;
    productAttachment.thumbnailErrorCount = x + 1;
    if (productAttachment.thumbnailErrorCount < 2) {
      event.target.src = productAttachment.fileSignedUrl;
      productAttachment.thumbnailSignedUrl = productAttachment.fileSignedUrl;
    } else {
      event.target.src = "/assets/default-image.jpg"
    }

  }
/****************PRODUCT APPROVAL STATUS COLROSENDS*********** */

/*************SAVE CATLOG SETTINGS */
saveCatalog(){
  let req:ProductCatalogRequest=this.createCatalogSetting();
  //this.getCatalogName();
  const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    disableClose: true,
    data: {
      modalType:'TEXT_INPUT',
      maxlength:150,
      textInputRegex:'^[a-zA-Z0-9._-]*$',
      textInputDefault:this.catalogName,
      textInputRegexError:'Catalog Name must only contain alphabets, numbers, underscore and hyphen',
      title: 'Catalog Name',
      message: 'Please provide a name for saving the catalog.', 
      confirmBtnText: 'Save',
      cancelBtnText:'Cancel'
    }
  });
  confirmDialog.afterClosed().subscribe(catalogName => {
    if(catalogName){
      this.productCatalogService.isCatalogDraftExisting(catalogName).subscribe(res=>{
        if(res && res!==null && res!==null){
          const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
              title: 'Confirm',
              message: "The catalog draft '"+catalogName+"' is existing. Do you wish to overwrite/update?", 
              confirmBtnText: ' Yes ',
              cancelBtnText:'  No  '
            }
          });
          confirmDialog.afterClosed().subscribe(result => {
            if (result === true) {
              this.saveCatalogDraft(req, catalogName);
            }else{

            }
          });
        
        }else{
          this.saveCatalogDraft(req, catalogName);
        }
      });
      
    }
  });
   
}

  private saveCatalogDraft(req: ProductCatalogRequest, result: any) {
    req.catalogProductsGrouped = this.masterCatalogList;
    this.catalogName = result;
    this.productCatalogService.catalogSettings.catalogName = result;
    this.productCatalogService.saveCatalog(req, result).subscribe(res => {
      let serviceStatus: ServiceStatus = res as ServiceStatus;
      if (serviceStatus.status === 'SUCCESS') {
        this.toastrService.success('Catalog draft saved as ' + result);
      } else {
        this.toastrService.error('Catalog draft could not be saved ' + serviceStatus.message);
      }
    }, err => {
      this.toastrService.error('Error while saving catalog draft' + err);
    });
  }

loadCatalog(attachmentId:string){
  //this.creatingCatalog=true;
    //let req:ProductCatalogRequest=this.createCatalogSetting();
     /*this.productList.forEach(element => {
      req.catalogProducts.push(element.productImages[0].fileSignedUrl);
     });*/
     //req.catalogProductsGrouped=this.masterCatalogList;
     this.productCatalogService.loadCatalog(attachmentId).subscribe(response => {
        let serviceStatus:ServiceStatus=response as ServiceStatus;
        if(serviceStatus.status==='SUCCESS'){
          this.productCatalogService.catalogSettings=serviceStatus.object as ProductCatalogRequest;
          let tempCatalogList:CatalogList[]=this.productCatalogService.catalogSettings.catalogProductsGrouped;
          this.productCatalogService.selectedProductsForCatalog=[];
          this.masterCatalogList=[];
          for (let i = 0; i < tempCatalogList.length; i++) {
            const list = tempCatalogList[i];
            if(list.products!==undefined && list.products.length>0){
              for (let j = 0; j < list.products.length; j++) {
                const product = list.products[j];
                this.productCatalogService.addProduct(product);
              }
            }
          }
          this.productList = this.productCatalogService.selectedProductsForCatalog;
          this.setCatalogSetting();
          this.router.navigate(['/product-catalog/']);
          
          
          /*
          this.setCatalogSetting();
          this.refreshList();
          this.createCatalogSetting();
          alert("Loaded "+JSON.stringify(response));*/
        }else{
          this.toastrService.error(serviceStatus.message);
        }
    });
  }

  /*getCatalogName() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        modalType: 'TEXT_INPUT',
        title: 'Catalog Name',
        message: undefined,
        confirmBtnText: 'Save',
        cancelBtnText: 'Cancel'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      //
    });
  }*/

  loadCatalogDialog() {
    this.productCatalogService.getDraftCatalogList().subscribe(response => {
          const confirmDialog = this.dialog.open(SelectFromListDialogComponent, {
            disableClose: false,
            data: {
              title: 'Load catalog draft',
              modalType: 'CATALOG_ATTACHMENT',
              cancelBtnText:'Close',
              message: undefined,
              selectionList: response
            }
          });
          confirmDialog.afterClosed().subscribe(result => {
            if (result) {
              if(!result.delete){
                this.loadCatalog(result.id);
              }else if(result.delete){
                this.deleteCatalogDraft(result.id,result.catalogName);
              }
            } else {
            }
          });
        }, (err => {})
        );
  }
  deleteCatalogDraft(id: string,catalogName:string) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        title: 'Confirm',
        message: "The catalog draft '"+catalogName+"' will be deleted permanently. Do you wish to continue?", 
        confirmBtnText: ' Yes ',
        cancelBtnText:'  No  '
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.productCatalogService.deleteCatalogDraft(id).subscribe(res=>{
          let serviceStatus:ServiceStatus=res as ServiceStatus;
          if(serviceStatus.status==='SUCCESS'){
            if(this.catalogName===catalogName){
              this.catalogName='Catalog';
            }
            this.toastrService.success(catalogName+' draft deleted');
            this.loadCatalogDialog();
          }else{
            this.toastrService.error(serviceStatus.message);
          }
        }
    
        );
      }else{
        this.loadCatalogDialog();
      }
    });
    
  }

  resetCatalogSelection() {
    this.productCatalogService.selectedProductsForCatalog = [];
    this.productCatalogService.catalogSettings=new ProductCatalogRequest;
    this.catalogName='Catalog';
    this.productList = this.productCatalogService.selectedProductsForCatalog;
    //this.productCatalogService.catalogSettings.catalogName='Catalog';
    this.selectedProductForCatalogCount = this.productCatalogService.selectedProductsForCatalog.length;
    this.router.navigate(['/product-catalog/']);
 }
/**************SAVE CATALOG SETTINGS ENDS */


/****************************SCRAP STARTS********************************* */

/*******************************ATTEMPT SORTING AND MULTIPAGE */

todo = [
  'Get to work',
  'Pick up groceries',
  'Go home',
  'Fall asleep'
];

done = [
  'Get up',
  'Brush teeth',
  'Take a shower',
  'Check e-mail',
  'Walk dog'
];

drop(event: CdkDragDrop<string[]>) {
  if (event.previousContainer === event.container) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  } else {
    transferArrayItem(event.previousContainer.data,
                      event.container.data,
                      event.previousIndex,
                      event.currentIndex);
  }
}

/*******************************ATTEMPT SORTING AND MULTIPAGE */

makeid():string {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  
  for (var i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  
  return text;
  }

  toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url+'?'+this.makeid()+'/');
    xhr.responseType = 'blob';
    xhr.send();
}

convert(product:Product){
  this.toDataUrl(product.productImages[0].fileSignedUrl, function(myBase64) {
    product.base64ImageDataUri=myBase64;      
 });
}
convertBackgroundImage(url:string,catalogSettings:Product){
  this.toDataUrl(url, function(myBase64) {
    catalogSettings.base64ImageDataUri=myBase64;
 });
}

convertWithCanvas(product:Product,index:number){
  var data = document.getElementById('contentToConvert'+index);
 this.changeAllSrc(data);
  setTimeout(()=>{
  html2canvas(data, { allowTaint: true, useCORS: true }).then(canvas => {
    const contentDataURL = canvas.toDataURL('image/png');
    this.canvasLoadedArr.push(data.id);
    product.base64ImageDataUri=contentDataURL;
  });
});
}




/**CATALOGUES */
createCatalogue() {
  
  var data = document.getElementById('contentToConvert');
  html2canvas(data, { allowTaint: true, useCORS: true }).then(canvas => {
    // Few necessary setting options
    var imgWidth = 208;
    var pageHeight = 295;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;

    const contentDataURL = canvas.toDataURL('image/png');
    let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
    var position = 0;
    pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
    pdf.save('MYPdf.pdf'); // Generated PDF
  });
  
}

getPDF(){

var data = document.getElementById('contentToConvert');
this.changeAllSrc(data);
html2canvas(data, { allowTaint: true,useCORS: true, removeContainer: true,
  backgroundColor: 'black',
  scale: 1}).then(canvas => {
  var HTML_Width =canvas.width;
  var HTML_Height = canvas.height;
  var top_left_margin = 15;
  var PDF_Width = HTML_Width+(top_left_margin*2);
  var PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
  var canvas_image_width = HTML_Width;
  var canvas_image_height = HTML_Height;
  var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
  var imgData = canvas.toDataURL("image/jpeg", 1.0);
  let pdf = new jspdf('p', 'pt',  [PDF_Width, PDF_Height]);
  pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);
  for (var i = 1; i <= totalPDFPages; i++) { 
  pdf.addPage(PDF_Width, PDF_Height);
  pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin*4),canvas_image_width,canvas_image_height);
  }
    pdf.save("HTML-Document.pdf");
  });

  
}

getPDF2(){
  let pdf = new jspdf('p', 'pt',  'a4');
  var lineHeightSpacing=20;
  var lineWidthSpacing=30;
  var lineHeightPointer=20;
  var lineWidthPointer=30;
  var imageheight=160;
  var imageWidth=160;
  var rowItemCount:number=3;
  var pageItemCount:number=12;
  
  this.productList.forEach((element,index) => {
    this.creatingCatalog=true;

    
    pdf.addImage(element.base64ImageDataUri, 'JPG', lineWidthPointer, lineHeightPointer,imageWidth,imageheight);
    lineWidthPointer=lineWidthPointer+imageWidth+lineWidthSpacing;
    if( (index+1)%(rowItemCount)===0){
      lineHeightPointer=lineHeightPointer+imageheight+lineHeightSpacing;
      lineWidthPointer=lineWidthSpacing;
    }
    if((index+1)%pageItemCount==0){
      pdf.addPage();
      lineHeightPointer=lineHeightSpacing;
      lineWidthPointer=lineWidthSpacing;
    }
    this.creatingCatalog=false;
    //product.base64ImageDataUri
  });
 
  
    pdf.save("Pdf2.pdf");
    
}

getPDF3(){

let pdf = new jspdf('p', 'pt',  'a4');
var lineHeightSpacing=20;
var lineWidthSpacing=30;
var lineHeightPointer=20;
var lineWidthPointer=30;
var imageheight=160;
var imageWidth=160;
var rowItemCount:number=3;

this.productList.forEach((element,index) => {
  var data = document.getElementById('contentToConvert'+index);
  this.changeAllSrc(data);
  html2canvas(data, { allowTaint: true, useCORS: true }).then(canvas => {
    const contentDataURL = canvas.toDataURL('image/png');
    pdf.addImage(contentDataURL, 'JPG', lineWidthPointer, lineHeightPointer,imageWidth,imageheight);
  lineWidthPointer=lineWidthPointer+imageWidth+lineWidthSpacing;
  if( (index+1)%(rowItemCount)===0){
    lineHeightPointer=lineHeightPointer+imageheight+lineHeightSpacing;
    lineWidthPointer=lineWidthSpacing;
  }
  if((index+1)%12==0){
    pdf.addPage();
    lineHeightPointer=lineHeightSpacing;
    lineWidthPointer=lineWidthSpacing;
  }
    
  });
  
  
  //product.base64ImageDataUri
});


  pdf.save("abcDocument.pdf");
}
/*https://github.com/MrRio/jsPDF/blob/ddbfc0f0250ca908f8061a72fa057116b7613e78/jspdf.js#L59*/
/* 'letter'            : [612,   792],
'a4'  : [ 595.28,  841.89], 'a5'  : [ 419.53,  595.28],*/
getPDF4(){
let pdf = new jspdf('l', 'pt',  [612,   792]);
var width = pdf.internal.pageSize.getWidth();
var height = pdf.internal.pageSize.getHeight();
pdf.addImage(this.productList[0].base64ImageDataUri,'JPG',0,0,width,height);
var lineHeightSpacing=36;
var lineWidthSpacing=97;
var lineHeightPointer=36;
var lineWidthPointer=97;
var imageheight=250;
var imageWidth=250;
var rowItemCount:number=3;
var pageItemCount:number=6;

this.productList.forEach((element,index) => {
  this.creatingCatalog=true;

  
  pdf.addImage(element.base64ImageDataUri, 'JPG', lineWidthPointer, lineHeightPointer,imageWidth,imageheight);
  lineWidthPointer=lineWidthPointer+imageWidth+lineWidthSpacing;
  if( (index+1)%(rowItemCount)===0){
    lineHeightPointer=lineHeightPointer+imageheight+lineHeightSpacing;
    lineWidthPointer=lineWidthSpacing;
  }
  if((index+1)%pageItemCount==0){
    pdf.addPage();
    lineHeightPointer=lineHeightSpacing;
    lineWidthPointer=lineWidthSpacing;
  }
  this.creatingCatalog=false;
  //product.base64ImageDataUri
});


  pdf.save("Pdf4.pdf");
  
}

getPDF5(){
this.creatingCatalog=true;

//let pdf = new jspdf('l', 'pt',  [612,   792]);
let pdf = new jspdf('p', 'pt',  [612,   792]);
var width = pdf.internal.pageSize.getWidth();
var height = pdf.internal.pageSize.getHeight();
pdf.addImage(this.catalogBackgroundDataUrl.base64ImageDataUri,'JPG',0,0,width,height);
var rowItemCount:number=2;
var pageItemCount:number=6;
var factor:number=0.3;
if(rowItemCount==2){
  factor=0.1;
}
if(rowItemCount==3){
  factor=0.07;
}
if(rowItemCount==4){
  factor=0.05;
}
var lineHeightSpacing=height*factor;
var lineWidthSpacing=width*factor;
var lineHeightPointer=height*factor;
var lineWidthPointer=width*factor;
var rowCount=pageItemCount/rowItemCount;
var imageWidth=(width-((rowItemCount+1)*lineWidthSpacing))/rowItemCount;
var imageheight=(height-((rowCount+1)*lineHeightSpacing))/rowCount;



this.productList.forEach((element,index) => {
  this.creatingCatalog=true;
  pdf.addImage(element.base64ImageDataUri, 'JPG', lineWidthPointer, lineHeightPointer,imageWidth,imageheight);
  lineWidthPointer=lineWidthPointer+imageWidth+lineWidthSpacing;
  if( (index+1)%(rowItemCount)===0){
    lineHeightPointer=lineHeightPointer+imageheight+lineHeightSpacing;
    lineWidthPointer=lineWidthSpacing;
  }
  if((index+1)%pageItemCount==0){
    pdf.addPage();
    pdf.addImage(this.catalogBackgroundDataUrl.base64ImageDataUri,'JPG',0,0,width,height);
    lineHeightPointer=lineHeightSpacing;
    lineWidthPointer=lineWidthSpacing;
  }
  //this.creatingCatalog=false;
  //product.base64ImageDataUri
});
  pdf.save("Pdf4.pdf");
}

changeAllSrc(data: HTMLElement) {
  let varId=this.makeid();
var list= data.getElementsByTagName('img');
for (let index = 0; index < list.length; index++) {
  const element = list[index];
  element.src=element.src+'?'+varId+'/';
  element.crossOrigin = 'anonymous';
}


}

download() {
  //alert('Overflow ->'+document.getElementById("contentToConvert").style.overflow);
  //document.getElementsByTagName("html")[0].style.overflow = "hidden";
  //document.getElementsByTagName("body")[0].style.overflow = "hidden";
  if(screen.width < 1024) {
      document.getElementById("viewport").setAttribute("content", "width=512px");
  }
  const data = document.getElementById('contentToConvert');
  this.changeAllSrc(data);
  let html2canvasOptions = {
    allowTaint: true,
    removeContainer: true,
    backgroundColor: null,
    imageTimeout: 15000,
    logging: true,
    scale: 2,
    useCORS: true
  };
    // Few necessary setting options
    html2canvas(data,html2canvasOptions).then(canvas => {
    const contentDataURL = canvas.toDataURL('image/png')
    const imgWidth = 210;
    const pageHeight = 295;
    const imgHeight = canvas.height * imgWidth / canvas.width;
    let heightLeft = imgHeight;
    let pdf = new jspdf('p', 'mm', 'a4', true); // A4 size page of PDF
    let position = 0;

    pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, undefined,'FAST');
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, undefined,'FAST')
      heightLeft -= pageHeight;
    }
    pdf.save('resume.pdf'); // Generated PDF

    if(screen.width < 1024) {
      document.getElementById("viewport").setAttribute("content", "width=device-width, initial-scale=1");
    }
  });
}
/****************************SCRAP ENDS********************************* */
}
