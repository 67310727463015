<div class="container-fluid">
<!--<h4 class="align-middle mt-1">Sales</h4>
        <hr>-->
        <div class="row mt-2">
            <ng-container *ngFor="let chartVar of chartFromService">
                <div class="col-auto card shadow m-2"> 
                    <div class="d-flex justify-content-between mt-1">
                        <span (click)="chartVar.tabular=!chartVar.tabular;"> 
                        <div *ngIf="chartVar.tabular"><i class="far fa-chart-bar"></i></div>
                        <div *ngIf="!chartVar.tabular"><i class="fas fa-th"></i></div>
                        </span>
                        <div class="d-flex justify-content-center"> {{chartVar.title}}</div>
                        <div>&nbsp;</div>
                    </div>
                    
                    <google-chart [title]="chartVar.title" (ready)="onReady($event)" (select)="onSelect(chartVar.data,$event)" [type]="chartVar.tabular?'Table':chartVar.type" [data]="chartVar.data" 
                    [columns]="chartVar.columnNames" [options]="chartVar.options">
                    </google-chart>
                </div>
            </ng-container>
        
        </div>
</div>