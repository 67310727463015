import { Component, OnInit, AfterViewInit,  HostListener, Input, Output, EventEmitter} from '@angular/core';

import { NgForm } from '@angular/forms';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

import { Product, ProductAttachment, StatusDetails, SampleDetails, SkuTrackerRetailerInfo, ExclusiveCustomer, GarmentCategoryCombinations } from 'src/app/shared/model/product.model';
import { SearchCriteria } from 'src/app/shared/model/search-criteria.model';
import { Licensor } from 'src/app/shared/model/licensor.model';
import { ProductService } from 'src/app/shared/service/product.service';
import { LicensorService } from 'src/app/shared/service/licensor.service';
import { ServiceStatus } from 'src/app/shared/model/service-status.model';
import { Brand } from 'src/app/shared/model/brand.model';
import { FileDeleteCriteria } from 'src/app/shared/model/file-delete-criteria.model';
import { BrandService } from 'src/app/shared/service/brand.service';
import { Subscription, Observable } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { Metadata } from 'src/app/shared/model/metadata.model';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { ProductCatalogService } from 'src/app/shared/service/product-catalog.service';
import { UpcService } from 'src/app/shared/service/upc.service';
import { UpcDetail, Upc } from 'src/app/shared/model/upc-detail.model';
import { PdfFormService } from 'src/app/shared/service/pdf-form.service';
import { CanComponentDeactivate } from 'src/app/auth/guards/deactivate.gaurd';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { CustomerService } from 'src/app/shared/service/customer.service';
import { Customer } from 'src/app/shared/model/customer.model';
import { ComposeEmailService } from 'src/app/shared/service/compose-email.service';
import { ComposeEmail, EmailAttachment } from 'src/app/shared/model/compose-email.model';
import { EmailTemplateConfig } from 'src/app/shared/model/email-template-config.model';
import { PrinterMatrix } from 'src/app/shared/model/printer-matrix.model';
import { EmailLog } from 'src/app/shared/model/email-log.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { SalesOrder } from 'src/app/shared/model/sales/sales-order.model';
import { SalesService } from 'src/app/shared/service/sales/sales.service';
import { User } from 'src/app/shared/model/user.model';
import { VendorsService } from 'src/app/shared/service/vendors/vendors.service';
import { Printer } from 'src/app/shared/model/vendors/printer.model';
import { ProductMetadataService } from 'src/app/shared/service/product-metadata.service';
import { drop } from 'lodash';



@Component({
   selector: 'app-product',
   templateUrl: './product.component.html',
   styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit,AfterViewInit,CanComponentDeactivate {
  clonedProduct: boolean;
  newCollection:boolean;
   createAction: boolean;
   viewAction: boolean;
   editable: boolean;
   licensorList: Licensor[] = null;
   brandList: Brand[] = null;
   licensorHasInactiveBrand:boolean=false;
   selectedImgAccesUrl: string = null;
  selectedImgId:string='';
   //SelectedFileToUpload: File = null;
   SelectedImageToUpload: File = null;
   //SelectedMasterArtToUplaod: File = null;
   SelectedPngFrontToUplaod: File = null;
   SelectedPngBackToUplaod: File = null;
   SelectedSeperationToUplaod: File = null;
   SelectedApprovaDocToUplaod: File = null;
   imagesToUploadPostProductCreation: File[] = [];
   masterArtsToUploadPostProductCreation: File[] = [];
   pngFrontToUploadPostProductCreation: File[] = [];
   pngBackToUploadPostProductCreation: File[] = [];
   seperationsToUploadPostProductCreation: File[] = [];
   approvalDocsToUploadPostProductCreation: File[] = [];
   photoApprovalToUploadPostProductCreation: File[] = [];
   product: Product;
   collections:Product[];
   collectionMembers:Product[];
   searchCriteria: SearchCriteria;
   progress: number = 0;
   fileUploading = false;
   fileUploaded = false;
   uploadError: boolean;
   //LoadFileError: string;
   //LoadFileErrorFlag: boolean;
   currentTag: string = '';
   colorList = [];
   garmentList = [];
   garmentCategoryCombList:GarmentCategoryCombinations[] = [];
   approvalStatusList = [];
   artStatusList = [];
   treatmentList = [];
   categoryList = [];
   printEffectList = [];
   similarProducts: Product[];
   customerList:ExclusiveCustomer[]=[];
   currentCustomer : ExclusiveCustomer = new ExclusiveCustomer;
   SlideOptions = {
      items: 3, dots: false, nav: true, lazyLoad: true, loop: false, responsiveClass: true,
      responsive: {
         0: {
            items: 1
         },
         480: {
            items: 3
         },
         769: {
            items: 4
         }
      },
      navText: ['<i class="fa fa-angle-left fa-2x" aria-hidden="true"></i>', '<i class="fa fa-angle-right fa-2x" aria-hidden="true"></i>']
   };

   CarouselOptions = { items: 1, dots: false, nav: true };

   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;
   metadata: Metadata = new Metadata;
   upcDetail= new UpcDetail;
   selectedSizesForUpcCreate: Upc[] = [];
   previousApprovalStatus:string=undefined;
   //licComReqMandAppStatus:string[]=['Concept Requires Revision','Pre-Pro Samples Requires Revision','Concept Approved with Changes'];
   //licComReqOptAppStatus:string[]=['Concept Retired','Concept Rejected','Pre-Pro Samples Rejected','Pre-Pro Approved with Changes'];
   licComReqMandAppStatus:string[]=[];
   licComReqOptAppStatus:string[]=[];
   isApprovalFormAvailable=false;
   isSampleFormAvailable=false;
   isRetailerInfoAvailable=false;
   selectUnSelectAllRadio: string = null;
   newTempStatusDetails:StatusDetails=new StatusDetails;
   composeEmail: ComposeEmail = new ComposeEmail;
   showEmailEditor:boolean=false;
   approvalEmailLogs:EmailLog[]=[];
   associatedSalesOrders:SalesOrder[]=[];
   printerList:Printer[] = [];
   adhocProductCreation:boolean=false;
   adhocProductCreationType:string='';
   @Output() productCreated= new EventEmitter<string>();
   @Input()
    set createAdhocProductForEstimation(any:any){
      this.adhocProductCreation=true;
      this.adhocProductCreationType='Estimation';
      //this.product.origination='Estimation';
      
    }

  constructor(private router: Router,private sharedService: SharedService, private authService: AuthService, private productService: ProductService, private licensorService: LicensorService, private brandService: BrandService, private activatedRoute: ActivatedRoute, private location: Location, private toastrService: ToastrService, private metadataService: MetadataService, private productCatalogService:ProductCatalogService, private upcService: UpcService,private pdfFormServicec: PdfFormService,private dialog: MatDialog, private customerService: CustomerService,private composeEmailService: ComposeEmailService,
    private salesService:SalesService, private vendorService: VendorsService,private productMetadataService:ProductMetadataService) { 
    this.licComReqMandAppStatus=this.sharedService.licComReqMandAppStatus;
    this.licComReqOptAppStatus=this.sharedService.licComReqOptAppStatus;

    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
   }

   this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
         // trick the Router into believing it's last link wasn't previously loaded
         //this.router.navigated = false;
         // if you need to scroll back to top, here is the right place
         window.scrollTo(0, 0);
      }
  });
  }
   ngOnInit() {
   // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.resetForm();
      this.handleSubscriptions();
      this.evaluateAction();
      this.setMetadata();
      //this.getPrinterList();
      if (this.createAction) {
         this.loadLicensors();
         this.loadMetaData();
         if(this.adhocProductCreationType==='Estimation'){
            this.product.origination='Estimation';// Make sure to set it if product instance is insantiated
         }
      } else if (this.viewAction) {
         this.loadProduct();
         this.loadMetadataForClassificationAndType('PRODUCT','APPROVAL_STATUS');
         this.loadMetadataForClassificationAndType('PRODUCT','ART_STATUS');
      }
      window.scrollTo(0, 0);
   }

  ngAfterViewInit() {
    if (this.createAction||this.newCollection) {
      setTimeout(() => {
        this.focusOnId('skuId');
       },100);
      window.scrollTo(0, 0);
    }
  }

  clone(){
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        title: 'Create Duplicate Product',
        message: undefined, 
        confirmBtnText: 'Without files',
        cancelBtnText:' With files  ',
        allowDiscard:true
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
       if (result !== 'NO_ACTION') {
          if (result === true) {
             this.productCloned();
          } else {
             this.productCloneWithFiles();
          }
       }
    });
  }

   /*newProductCreation(){
     this.product=new Product;
     this.licensorList = null;
     this.brandList = null;
     this.createAction=true;
     this.viewAction=false;
     this.editable=true;
     this.clonedProduct=false;
     this.newCollection=false;
     this.collectionMembers=undefined;
     this.collection=undefined;
     this.previousApprovalStatus=undefined;
     this.searchCriteria = new SearchCriteria;
     this.selectedImgAccesUrl=undefined
     this.newTempStatusDetails=new StatusDetails;
     this.resetPostProductCreationUploads();
     this.similarProducts = [];
     this.loadLicensors();
     this.loadMetaData();
     this.resetUpc();
     window.scrollTo(0,0);
     setTimeout(() => {
      this.focusOnId('skuId');
     },100);
   }*/

ngOnDestroy(){
  this.handleCloneProductClosure(false,false);
  this.handleNewCollectionClosure(false,false);
  this.router.navigated = true;
}

   evaluateAction() {
      /*change also similarDesignSelected()*/
      console.log('adhocProductCreation',this.adhocProductCreation);
      const action = this.activatedRoute.snapshot.paramMap.get('action');
      if(this.adhocProductCreation){
         this.createAction = true;
         this.editable = true;
         
        }else if (action === 'create') {
         this.createAction = true;
         this.editable = true;
         //document.getElementById('backButton').focus();
      } else if (action === 'view') {
         this.viewAction = true;
      }
      else if (action === 'newCollection') {
        this.viewAction=true;
        this.newCollection=true;
     }
   }

   /*
   similarDesignSelected(similarProduct: Product,event?:any) {
      if(!event.ctrlKey){
        window.scrollTo(0, 0);
        this.handleCloneProductClosure(false);
        this.clonedProduct=false;
        this.newCollection=false;
        this.createAction = false;
        this.editable = false;
        this.viewAction = true;
        this.searchCriteria = new SearchCriteria;
        this.product = new Product;
        this.searchCriteria.licensorId = similarProduct.licensorName;
        this.searchCriteria.brandId = similarProduct.brandId;
        this.searchCriteria.productId = similarProduct.id;
        this.loadProduct();
      }
   }*/
  /* 
  productClonedDeprecated(){
    let searchCriteriaToClone = new SearchCriteria;
    searchCriteriaToClone.productId = this.product.id;
    this.toastrService.info('Creating Duplicate Product for '+this.product.sku);
    this.productService.cloneProduct(searchCriteriaToClone).subscribe((res) => {
      let status:ServiceStatus=res as ServiceStatus;
      if(status.status=='SUCCESS'){
        this.clonedProduct=true;
        this.createAction = false;
        this.editable=false;
        this.viewAction = true;
        this.searchCriteria = new SearchCriteria;
        this.searchCriteria.licensorId = this.product.licensorId;
        this.searchCriteria.brandId =this.product.brandId;
        this.searchCriteria.productId = status.id;
        this.product=new Product;
        this.loadProduct();  
        window.scrollTo(0, 0);
        this.toastrService.success(status.message);
      }else{
        this.toastrService.error(status.message);
      }
      
    },
    (err)=>{
      this.toastrService.error("Error while creating duplicate product. "+err);
    });
  }*/

  productCloneWithFiles(){
    let searchCriteriaToClone = new SearchCriteria;
    searchCriteriaToClone.productId = this.product.id;
    this.resetPostProductCreationUploads();
    this.resetUpc();
    this.toastrService.info('Creating Duplicate Product for '+this.product.sku);
    this.productService.cloneProductWithFiles(searchCriteriaToClone).subscribe((res) => {
      let status:ServiceStatus=res as ServiceStatus;
      if(status.status=='SUCCESS'){
        this.clonedProduct=true;
        this.createAction = false;
        this.editable=false;
        this.viewAction = true;
        this.searchCriteria = new SearchCriteria;
        this.newTempStatusDetails=new StatusDetails;
        this.searchCriteria.licensorId = this.product.licensorId;
        this.searchCriteria.brandId =this.product.brandId;
        this.searchCriteria.productId = status.id;
        this.product=new Product;
        this.loadProduct();  
        window.scrollTo(0, 0);
        this.toastrService.success(status.message);
      }else{
        this.toastrService.error(status.message);
      }
      
    },
    (err)=>{
      this.toastrService.error("Error while creating duplicate product. "+err);
    });
  }

  productCloned(){
    this.product.id=undefined;
    this.resetAttachments();
    this.resetSkuTrackerDetails();
    this.resetUpc();
    this.previousApprovalStatus=undefined;
    this.similarProducts=[];
    this.resetPostProductCreationUploads();
    this.licensorList = null;
    this.brandList = null;
    this.createAction=true;
    this.viewAction=false;
    this.editable=true;
    this.clonedProduct=false;
    this.newCollection=false
    this.collectionMembers=undefined;
     this.collections=undefined;
    this.searchCriteria = new SearchCriteria;
    this.newTempStatusDetails=new StatusDetails;
    this.selectedImgAccesUrl=undefined
    this.loadLicensors();
    this.loadLicensorWithOnlyBrands(this.product.licensorId);
    this.loadMetaData();
    this.product.sku=this.product.sku.slice(0,7);
    this.product.isCollectionMember=false;
    this.product.exclusiveCustomers=[];
    this.product.exclusiveToCustomers=false;
    this.product.approvalStatus='Not Submitted';
    this.statusChanged(this.product);
    this.product.priority=false;
    this.product.version=0;
    if(this.product.printerMatrix){
      this.product.printerMatrix.id=undefined;
      this.product.printerMatrix.parentId=undefined;
    }
    window.scrollTo(0,0);
    setTimeout(() => {
     this.focusOnId('skuId');
    },100);
  }


  private resetPostProductCreationUploads() {
    this.imagesToUploadPostProductCreation = [];
    this.masterArtsToUploadPostProductCreation = [];
    this.pngFrontToUploadPostProductCreation = [];
    this.pngBackToUploadPostProductCreation = [];
    this.seperationsToUploadPostProductCreation = [];
    this.approvalDocsToUploadPostProductCreation = [];
    this.photoApprovalToUploadPostProductCreation = [];
  }

  private resetAttachments() {
    this.product.productImages = [];
    this.product.seperations = [];
    this.product.masterArts = [];
    this.product.pngFront = [];
    this.product.pngBack = [];
    this.product.approvalDocs = [];
    this.product.photoApproval = [];
  }

   loadMetaData() {
    this.loadAllCustomerList();
    let metaDataList=['PRINT_EFFECT','APPROVAL_STATUS','ART_STATUS'];
    metaDataList.forEach(element => {
      this.loadMetadataForClassificationAndType('PRODUCT',element);
    });
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
        this.currentLoggedInUser = currentLoggedInUser;
     });
     this.productMetadataService.colorListObservable.subscribe(res => {
      this.colorList.length=0;
      this.colorList.push(...res);
      console.log("Subscribed Color List",this.colorList);
      });

      /*this.productMetadataService.categoryListObservable.subscribe(res => {
        this.categoryList.length=0;
        this.categoryList.push(...res);
        console.log("Subscribed Category List",this.categoryList);
      });*/

      this.productMetadataService.treatmentListObservable.subscribe(res => {
        this.treatmentList.length=0;
        this.treatmentList.push(...res);
        console.log("Subscribed Treatment List",this.treatmentList);
      });

      this.productMetadataService.garmentListObservable.subscribe(res => {
        this.garmentList.length=0;
        this.garmentList.push(...res);
        console.log("Subscribed Garment List",this.garmentList);
      });

      this.productMetadataService.garmentCategoryCombListObservable.subscribe(res => {
        this.garmentCategoryCombList.length=0;
        this.garmentCategoryCombList.push(...res);
        if(this.product && this.product.garment){
          this.setCategoryList(this.product.garment);
        }
        console.log("Subscribed Garment Categor Comb List",this.garmentCategoryCombList);
      });

      
   }

   edit() {
    this.productService.isCurrentVersion(this.product.id,this.product.version).subscribe(res=>{
      let serviceStatus:ServiceStatus=res as ServiceStatus;
      if(serviceStatus && serviceStatus!=null && serviceStatus.status==="SUCCESS"){
        this.proceedWithEdit();
      }else{
        //this.toastrService.info('Product has been modified by other user.Reloading before Edit'); 
        this.loadProduct(true);         
      }
    });
      
   }

  private proceedWithEdit() {
    this.editable = true;
    this.createAction = false;
    this.viewAction = false;
    this.loadMetaData();
    this.loadLicensors();
    this.loadLicensorWithOnlyBrands(this.product.licensorId);
    //Must always be last step
    this.previousApprovalStatus = this.product.approvalStatus;
    if(this.newCollection){
      this.resetNewCollection();
    }
  }

   viewStatusChangeModal(){
      this.previousApprovalStatus=this.product.approvalStatus;
   }
   
   cancelEdit() {
    if(this.clonedProduct){
      /*if(confirm("The duplicate product will be deleted if you cancel the update. Do you want to continue?")){
        this.handleCloneProductClosure(true);
      }*/
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: {
          title: 'Delete Duplicate Product',
          message: 'The duplicate product will be deleted if you cancel the update. Do you want to continue?', 
          confirmBtnText: '  Yes  ',
          cancelBtnText:'   No   '
        }
      });
      confirmDialog.afterClosed().toPromise().then(result => {
        if (result === true) {
          this.handleCloneProductClosure(false,true);
        }
      });
    }
    if(this.newCollection){
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: {
          title: 'Delete Collection',
          message: 'The collection will be deleted if you cancel the update. Do you want to continue?', 
          confirmBtnText: '  Yes  ',
          cancelBtnText:'   No   '
        }
      });
      confirmDialog.afterClosed().toPromise().then(result => {
        if (result === true) {
          this.handleNewCollectionClosure(false,true);
        }
      });
    }else {
      this.editable = false;
      this.createAction = false;
      this.viewAction = true;
      this.loadProduct();
    }

   }
   skuSave(product:Product){
    this.product.statusDetails.forEach(element => {
      if(element.id===undefined || element.id===null){
        this.newTempStatusDetails=element;
      }
    });
   }

   statusChanged(product:Product,event?:Event){
    if(!product.statusDetails|| product.statusDetails.length===0){
      product.statusDetails=[];
    }
    /*if(this.newTempStatusDetails.current){
      this.newTempStatusDetails.current=false;
    }*/
    /*this.product.statusDetails=this.product.statusDetails.filter(x => x!=this.newTempStatusDetails);
    this.newTempStatusDetails=new StatusDetails;
    this.newTempStatusDetails.status=product.approvalStatus;
    this.newTempStatusDetails.statusDate=new Date;
    product.statusDetails.push(this.newTempStatusDetails);*/
    
    let existingNewStatusEntry:boolean=false;
    this.product.statusDetails.forEach(element => {
      if(element.id===undefined || element.id===null){
        element.status=product.approvalStatus;
        element.statusDate=new Date;
        existingNewStatusEntry=true;
      }
    });
    //if(['Concept Pending','Pre-Pro Samples Submitted'].includes(product.approvalStatus)){
      if(!existingNewStatusEntry){
      let details:StatusDetails=new StatusDetails;
       details.status=product.approvalStatus;
       details.statusDate=new Date;
       product.statusDetails.push(details);
       this.newTempStatusDetails=details;
      }
      if(product.isCollection){
        this.sharedService.handleProductApprovalStatusChangeForCollection(this.newTempStatusDetails,product);
      }
  }

   skuEditCancel(product:Product){
    product.skuTrackerEditable=false;
    this.loadStatusAndSampleDetails(this.product);
   }

   loadStatusAndSampleDetails(product: Product){
    this.loadStatusDetails(product);
    this.loadSampleDetails(product);
    this.loadSkuTrackerRetailerInfo(product);
  }

   loadStatusDetails(product: Product) {
    //product.statusDetails=[];
    //this.clonedProduct||this.newCollection
    if(this.newCollection){
        product.approvalStatus='Not Submitted';
        //propogaing as new collection will create a pop up and change all child product
    }else if(this.clonedProduct){
      //do not load status details
      this.product.approvalStatus='Not Submitted';
      this.statusChanged(this.product);
    }else{
        this.productService.getStatusDetails(product.id).subscribe((res) => {
          product.statusDetails=res as StatusDetails[];
          if(product.statusDetails && product.statusDetails!=null && product.statusDetails.length>0){
            this.product.statusDetails.forEach(statusDetails => {
              if(statusDetails.current){
                this.newTempStatusDetails=statusDetails;
              }
              
            });
          }
        },
        (err) => {
          this.toastrService.error(JSON.stringify(err));
          //this.reloadProduct(product);
        });
      }
  }
  
  loadSampleDetails(product: Product) {
    product.sampleDetails=[];
    this.productService.getSampleDetails(product.id).subscribe((res) => {
      product.sampleDetails=res as SampleDetails[];
     },
       (err) => {
         this.toastrService.error(JSON.stringify(err));
         //this.reloadProduct(product);
       });
  }

  loadSkuTrackerRetailerInfo(product: Product) {
    product.skuTrackerRetailerInfo=[];
    this.productService.getSkuTrackerRetailerInfo(product.id).subscribe((res) => {
      product.skuTrackerRetailerInfo=res as SkuTrackerRetailerInfo[];
     },
       (err) => {
         this.toastrService.error(JSON.stringify(err));
         //this.reloadProduct(product);
       });
  }

  handleCloneProductClosure(goBack:boolean,redirectToProductList:boolean){
    if(this.clonedProduct){
      this.toastrService.info('Duplicate Product '+this.product.sku+' will be deleted as it was not saved');
      this.deleteProduct(goBack,redirectToProductList);
    }
  }
  handleNewCollectionClosure(goBack:boolean,redirectToProductList:boolean){
    if(this.newCollection){
      this.toastrService.info('Collection '+this.product.sku+' will be deleted as it was not saved');
      this.deleteProduct(goBack,redirectToProductList);
    }
  }
  setMetadata() {
    /*change also similarDesignSelected()*/
    this.searchCriteria.licensorId = this.activatedRoute.snapshot.paramMap.get('licensorId');
    this.searchCriteria.brandId = this.activatedRoute.snapshot.paramMap.get('brandId');
    this.searchCriteria.productId = this.activatedRoute.snapshot.paramMap.get('id');
    this.sanitizeMetadata();
    if (this.activatedRoute.snapshot.paramMap.get('sku') !== "undefined" && this.createAction) {
      this.searchCriteria.skuCode = this.activatedRoute.snapshot.paramMap.get('sku');
      this.product.sku = this.activatedRoute.snapshot.paramMap.get('sku');
      if (this.product.sku!==undefined && this.product.sku!==null && this.product.sku.length > 3) {
        this.product.sku = this.product.sku.substring(0, 3);
      }
      this.product.licensorId = this.searchCriteria.licensorId;//this.activatedRoute.snapshot.paramMap.get('licensorId');
      this.product.brandId = this.searchCriteria.brandId;//this.activatedRoute.snapshot.paramMap.get('brandId');
      this.loadBrandForSkuCode(this.product.sku);
    }
  }
  sanitizeMetadata() {
    if(this.searchCriteria.licensorId==='undefined'){
      this.searchCriteria.licensorId = undefined;
    }
    if(this.searchCriteria.brandId==='undefined'){
      this.searchCriteria.brandId = undefined;
    }
    if(this.searchCriteria.productId==='undefined'){
      this.searchCriteria.productId = undefined;
    }
  }

  loadProduct(proceedToEditAfterLoad?:boolean) {
    this.refreshSearchCriteria();
    this.productService.loadProduct(this.searchCriteria).subscribe((res) => {
      this.product = res as Product;
      if(this.product!==null && this.product!=undefined){
        this.setCategoryList(this.product.garment);
        if (this.viewAction) {
          this.loadStatusAndSampleDetails(this.product);
          this.productService.loadProductAttachmentsUrls(this.product,'PNL',this.searchCriteria.licensorId,this.searchCriteria.brandId);
          this.productService.loadProductImagesUrls(this.product,this.searchCriteria.licensorId,this.searchCriteria.brandId);
          this.loadCollection();
          this.loadCollectionMembers();
          this.productService.loadProductAttachmentsUrls(this.product,'MASTER_ARTS',this.searchCriteria.licensorId,this.searchCriteria.brandId);
          this.productService.loadProductAttachmentsUrls(this.product,'SEPERATIONS',this.searchCriteria.licensorId,this.searchCriteria.brandId);
          this.productService.loadProductAttachmentsUrls(this.product,'APPROVAL_DOCS',this.searchCriteria.licensorId,this.searchCriteria.brandId);
          this.productService.loadProductAttachmentsUrls(this.product,'PHOTO_APPROVAL',this.searchCriteria.licensorId,this.searchCriteria.brandId);
          this.productService.loadProductAttachmentsUrls(this.product,'PNG_FRONT',this.searchCriteria.licensorId,this.searchCriteria.brandId);
          this.productService.loadProductAttachmentsUrls(this.product,'PNG_BACK',this.searchCriteria.licensorId,this.searchCriteria.brandId);
          //this.loadUpcDetails();
          this.isPDFFormAvailable();
          this.loadExclusiveCustomers(this.product.id);
          this.loadPrinterMatrix();
          //this.loadApprovalEmailLogs();
          if (this.clonedProduct) {
            this.product.sku=this.product.sku.slice(0,7);
            this.proceedWithEdit();
          }else if (this.newCollection) {
            this.proceedWithEdit();
            
          }else if(proceedToEditAfterLoad){
            this.proceedWithEdit();
          }else {
            this.loadSimilarProduct();
          }
        }else{
          this.productService.loadProductAttachmentsUrls(this.product,'PNL',this.searchCriteria.licensorId,this.searchCriteria.brandId);
        }
    }else{
      this.product=new Product;
    }
    });
  }
  loadApprovalEmailLogs() {
    this.composeEmailService.getApprovalEmailLogs(this.product.id).subscribe(res=>{
      if(res && res!==null){
      this.approvalEmailLogs=res as EmailLog[];
      }else{
        this.approvalEmailLogs=[];
      }
    }

    );
  }
  
  loadPrinterMatrix() {
    this.productService.getPrinterMatrix(this.product.id).subscribe(res => {
      if (res && res != null) {
        this.product.printerMatrix=res as PrinterMatrix;
      }else{
        this.product.printerMatrix=new PrinterMatrix;
      }
    });
  }

  resetNewCollection() {
    this.product.description='';
    //this.product.licensorId='';
    this.product.brandId='';
    this.product.color='';
    //this.product.garment='';
    //this.product.category='';
    this.product.notes='';
    this.product.screenCount=0;
    //this.product.printEffect='';
    this.product.imageCode='';
    //this.product.treatment='';
    this.product.pantoneColors='';
    this.productCatalogService.selectedProductsForCatalog=[];
  }
  loadCollectionMembers() {
    if(this.product.isCollection){
      this.productService.loadCollectionMembers(this.searchCriteria).subscribe((res) => {
        if(res && res!==null){
          this.collectionMembers=res as Product[];
        }
      });
    }
  }
  loadCollection() {
    if(this.product.isCollectionMember){
      this.productService.loadCollection(this.searchCriteria).subscribe((res) => {
        if(res && res!==null){
          this.collections=res as Product[];
        }
      });
    }
  }
  isPDFFormAvailable() {
    this.pdfFormServicec.isPdfFormAvailable('PRODUCT','APPROVAL_FORM',this.product.licensorId,this.product.brandId,this.product.id).subscribe(
      res=>{
        let status:ServiceStatus = res as ServiceStatus;
        if(status.status==='AVAILABLE'){
            this.isApprovalFormAvailable=true;
        }else{
          this.isApprovalFormAvailable=false;
        }
      }
    );
    this.pdfFormServicec.isPdfFormAvailable('PRODUCT','SAMPLE_FORM',this.product.licensorId,this.product.brandId,this.product.id).subscribe(
      res=>{
        let status:ServiceStatus = res as ServiceStatus;
        if(status.status==='AVAILABLE'){
            this.isSampleFormAvailable=true;
        }else{
          this.isSampleFormAvailable=false;
        }
      }
    );
    this.pdfFormServicec.isPdfFormAvailable('PRODUCT','RETAILER_INFO_FORM',this.product.licensorId,this.product.brandId,this.product.id).subscribe(
      res=>{
        let status:ServiceStatus = res as ServiceStatus;
        if(status.status==='AVAILABLE'){
            this.isRetailerInfoAvailable=true;
        }else{
          this.isRetailerInfoAvailable=false;
        }
      }
    );
  }

  private refreshSearchCriteria() {
    var productId = this.searchCriteria.productId;
    if ((productId === null || productId === undefined) && (this.product.id !== null && this.product.id !== undefined)) {
      this.searchCriteria.productId = this.product.id;
    }
  }

    loadLicensors() {
      this.licensorService.loadLicensors().subscribe((res: Licensor[]) => {
         this.licensorList = this.sharedService.sortListByPropertyName(res, 'licensorName');
      });
   }

   onLicensorChange(event?) {
      let temp: Licensor[] = this.licensorList.filter(listElem => listElem.id === this.product.licensorId);
      this.product.licensorName = temp[0].licensorName;
      this.loadLicensorWithOnlyBrands(this.product.licensorId);
      this.brandList = null;
      this.product.brandId=undefined;
      this.product.brandName=undefined;
      this.product.brandCategory=undefined;
      this.product.pantoneColors=undefined;
      
   }

   loadLicensorWithOnlyBrands(licensorId: string) {
    this.licensorHasInactiveBrand=false;
      this.licensorService.loadLicensorWithOnlyBrands(licensorId,false).subscribe((res) => {
         let selectedlicensor = res as Licensor;
         this.brandList = this.sharedService.sortListByPropertyName(selectedlicensor.brands,'brandName');
         this.brandList.forEach(element => {
           if(!element.brandStatus){
            this.licensorHasInactiveBrand=true;
           }
         });
        
         // this.licensorList=res[];
      });
   }
   onBrandChange(event) {
      let temp: Brand[] = this.brandList.filter(listElem => listElem.id === this.product.brandId);
      this.product.brandName = temp[0].brandName;
      this.product.sku = temp[0].skuCode;
      this.product.brandCategory=temp[0].category;
      this.product.pantoneColors=temp[0].pantoneColors;
      
   }

   goBack() {
     if(this.editable || this.createAction){
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: {
          title: 'Cancel Edit',
          message: 'You have unsaved changes! If you confirm, your changes will be lost.',
        }
      });
      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) {
          this.editable=false;
          this.createAction=false;
          this.location.back();
        }
      });
    }else{
      this.location.back();
    }
      
  }
  deleteProduct(goBack:boolean,redirectToProductList:boolean){
    this.productService.deleteProduct(this.product.id).subscribe((res) => {
      this.clonedProduct=false;
      this.newCollection=false;
      if(goBack){
        this.editable = false;
        this.createAction = false;
        this.location.back();
      }if(redirectToProductList){
        this.editable = false;
        this.createAction = false;
        this.router.navigate(['/product-search']);
      }
     },
      (err) => {
        this.toastrService.error('Duplicate Product deletion error'+err);
      });
   }

   resetForm(form?: NgForm) {
      if (form != null) {
         form.resetForm();
      }
      if (this.searchCriteria == null)
         this.searchCriteria = new SearchCriteria;
      this.product = new Product;
      this.product.productImages = [];
   }

   addTag() {
      if (this.product.tagList === undefined) {
         this.product.tagList = [];
      }
      this.product.tagList.push(this.currentTag);
      this.currentTag = "";
      this.focusOnId('tagId');
   }

   focusOnId(arg0: string) {
      document.getElementById(arg0).focus();
   }

   removeTag(toRemove: string) {
      this.product.tagList.splice(this.product.tagList.indexOf(toRemove), 1);
      this.focusOnId('tagId');
   }

   editForm() {
      //this.editAction=true;
      this.createAction = true;
      this.viewAction = false;
      this.loadLicensors();
   }
   
  loadSimilarProduct(){
    let criteria=new SearchCriteria;
    criteria.brandId=this.product.brandId;
    criteria.productId=this.product.id;
    criteria.sku=this.product.sku;
    criteria.similarDesign=true;
    criteria.licensorId=this.product.licensorId;
    criteria.licensors=[];
    criteria.licensors.push(this.product.licensorName);
    criteria.brands=[];
    criteria.brands.push(this.product.brandName);
    criteria.garments=[];
    criteria.garments.push(this.product.garment);
    criteria.colors=[];
    criteria.colors.push(this.product.color);
    criteria.treatments=[];
    criteria.treatments.push(this.product.treatment);
    criteria.tags=this.product.tagList;
    this.productService.loadSimilarProduct(criteria).subscribe((res:Product[]) => {
      let similarProductsTemp=res as Product[];
      this.similarProducts = similarProductsTemp.filter(itemInList => itemInList.id !== this.product.id);
      //document.getElementById('backButton').focus();
    },
      (err) => {
        console.error(err);
      });
  }

  createOrUpdateProduct(){
    this.populateStatusChangeDetails();
    this.populateLocalFileList();
    if(this.editable&&!this.createAction){
      let redirect:boolean=false;
      if(this.clonedProduct){
        this.clonedProduct=false;
        this.previousApprovalStatus=undefined;// for status details to be created
      }
      if(this.newCollection){
        this.newCollection=false;
        redirect=true;
      }
      this.updateProduct(redirect);
    }else{
      this.createProduct();
      this.product.productName=this.product.sku;
    }
  }
  populateStatusChangeDetails() {
    if(this.product.approvalStatus!==this.previousApprovalStatus||this.createAction||this.clonedProduct||this.newCollection){
      this.product.statusDetails.forEach(element => {
        if(element.id===undefined || element.id===null){
          this.product.licensorComments=element.licensorComments;
          this.product.statusDate=element.statusDate;
        }
      });
    }
  }
  populateLocalFileList() {
    if (this.product.productImages.length > 0) {
      //this.setSortOrderForAttachment(false);
    }
    this.imagesToUploadPostProductCreation=this.populateLocalFileinList(this.product.productImages);
    this.masterArtsToUploadPostProductCreation=this.populateLocalFileinList(this.product.masterArts);
    this.seperationsToUploadPostProductCreation=this.populateLocalFileinList(this.product.seperations);
    this.approvalDocsToUploadPostProductCreation=this.populateLocalFileinList(this.product.approvalDocs);
    this.photoApprovalToUploadPostProductCreation=this.populateLocalFileinList(this.product.photoApproval);
    this.pngFrontToUploadPostProductCreation=this.populateLocalFileinList(this.product.pngFront);
    this.pngBackToUploadPostProductCreation=this.populateLocalFileinList(this.product.pngBack);
    
  }
  createProduct() {
    this.productService.createProduct(this.product).subscribe(async (res) => {
      let status:ServiceStatus=res as ServiceStatus;
      if(status.status=='SUCCESS'){
        this.product.id=status.id;
        let productReturned= status.object as Product;// never set product returned to current product
        this.product.version=productReturned.version;
        this.createAction=false;
        this.editable=false;
        this.viewAction=true;
        this.updateExclusiveCustomer();
        this.updatePrinterMatrix();
        this.loadStatusAndSampleDetails(this.product);
        await this.updateAttachments();
        if(this.adhocProductCreationType==='Estimation'){
            this.productCreated.emit(this.product.id);
        }else{
         this.loadAdditionalDetails();
        }
        this.toastrService.success(status.message);
      }else{
        this.toastrService.error(status.message);
      }
      
    },
      (err) => {
        this.toastrService.error(JSON.stringify(err));
      });

  }

//this.uploadLocalFileToRepo(list, category);
  private populateLocalFileinList(attachmentList: ProductAttachment[]):File[] {
    let list: File[] = [];
    if (attachmentList !== undefined && attachmentList.length > 0) {
      attachmentList.forEach(element => {
        if (element.islocal && !element.toDelete) {
          list.push(element.localfile);
          //element.localfile=undefined; This created issue when first create attempt has issues like sku exists. Then subsequent attempt could not find the file
          element.fileSignedUrl=undefined;
          element.thumbnailSignedUrl=undefined;
        }
      });
      
    }
    return list;
  }

  updateProduct(redirectPostUpdate:boolean){
    this.productCatalogService.checkAndRemoveForCatalog(this.product);
    //
    let tempProduct = JSON.parse(JSON.stringify(this.product));
    //
    tempProduct.masterArts=null;
    tempProduct.pngFront=null;
    tempProduct.pngBack=null;
    tempProduct.seperations=null;
    tempProduct.photoApproval=null;
    //this.setSortOrderForAttachment();
    this.productService.updateProduct(tempProduct).subscribe((res) => {
      let status:ServiceStatus=res as ServiceStatus;
      if(status.status=='SUCCESS'){
        this.updateExclusiveCustomer();
        this.updatePrinterMatrix();
        this.product.id=status.id;
        let productReturned= status.object as Product;// never set product returned to current product
        this.product.version=productReturned.version;
        this.editable=false;
        this.createAction=false;
        this.viewAction=true;
        this.updateAttachments();
        if(redirectPostUpdate){
          this.router.navigate(['/product/view/' + this.product.licensorId + '/' + this.product.brandId + '/' + this.product.id], { skipLocationChange: false });
        }else{
          this.loadStatusAndSampleDetails(this.product);
          this.loadAdditionalDetails();
        }
        //this.loadProduct();
        this.toastrService.success(status.message);
        
      }else{
        if(status.errorCode==='STALE_OBJECT_ERROR'){
          this.toastrService.error('Product has been modified by other user. Please refresh and make your changes again before save');  
        }else{
          this.toastrService.error(status.message);
        }
      }
      
    },
      (err) => {
        this.toastrService.error(JSON.stringify(err));
      });

  }
  updateExclusiveCustomer() {
    this.productService.updateExclusiveCustomer(this.product.id,this.product.exclusiveCustomers).subscribe((res) => {
      let status:ServiceStatus=res as ServiceStatus;
      if(status.status=='SUCCESS'){
      this.loadExclusiveCustomers(this.product.id);
      }else{
        this.toastrService.error(status.message);
      }
    });
  }

  updatePrinterMatrix() {
    if(this.product.printerMatrix){
      this.productService.updatePrinterMatrix(this.product.id,this.product.printerMatrix).subscribe((res) => {
        let status:ServiceStatus=res as ServiceStatus;
        if(status.status=='SUCCESS'){
        }else{
          this.toastrService.error(status.message);
        }
      });
    }
  }

   loadAdditionalDetails() {
      this.refreshSearchCriteria();
      this.productService.loadProduct(this.searchCriteria).subscribe((res) => {
        let productTemp = res as Product;
        if(productTemp!==null && productTemp!=undefined){
            this.product.convertedToSKU=productTemp.convertedToSKU;
        }
      });
   }
  async updateAttachments() {
   await this.deletMarkedFile(this.product.productImages);
   await this.deletMarkedFile(this.product.masterArts);
   await this.deletMarkedFile(this.product.seperations);
   await this.deletMarkedFile(this.product.approvalDocs);
   await this.deletMarkedFile(this.product.photoApproval);
   await this.deletMarkedFile(this.product.pngFront);
   await this.deletMarkedFile(this.product.pngBack);
   await this.uploadLocalFileToRepo(this.imagesToUploadPostProductCreation,'IMAGES');
   this.productService.loadProductImagesUrls(this.product,this.searchCriteria.licensorId,this.searchCriteria.brandId);
   this.handleApprovalStatusChange();
   this.productService.loadProductAttachmentsUrls(this.product,'MASTER_ARTS',this.searchCriteria.licensorId,this.searchCriteria.brandId);
   this.productService.loadProductAttachmentsUrls(this.product,'SEPERATIONS',this.searchCriteria.licensorId,this.searchCriteria.brandId);
   this.productService.loadProductAttachmentsUrls(this.product,'APPROVAL_DOCS',this.searchCriteria.licensorId,this.searchCriteria.brandId);
   this.productService.loadProductAttachmentsUrls(this.product,'PHOTO_APPROVAL',this.searchCriteria.licensorId,this.searchCriteria.brandId);
   this.productService.loadProductAttachmentsUrls(this.product,'PNG_FRONT',this.searchCriteria.licensorId,this.searchCriteria.brandId);
   this.productService.loadProductAttachmentsUrls(this.product,'PNG_BACK',this.searchCriteria.licensorId,this.searchCriteria.brandId);
   
   this.uploadLocalFileToRepoNonBlocking(this.masterArtsToUploadPostProductCreation, 'MASTER_ARTS');
   this.uploadLocalFileToRepoNonBlocking(this.seperationsToUploadPostProductCreation, 'SEPERATIONS');
   this.uploadLocalFileToRepoNonBlocking(this.approvalDocsToUploadPostProductCreation, 'APPROVAL_DOCS');
   this.uploadLocalFileToRepoNonBlocking(this.photoApprovalToUploadPostProductCreation, 'PHOTO_APPROVAL');
   this.uploadLocalFileToRepoNonBlocking(this.pngFrontToUploadPostProductCreation, 'PNG_FRONT');
   this.uploadLocalFileToRepoNonBlocking(this.pngBackToUploadPostProductCreation, 'PNG_BACK');
  
  }
  handleApprovalStatusChange() {
    if (this.product.approvalStatus !== this.previousApprovalStatus||this.createAction||this.clonedProduct||this.newCollection) {
      this.productService.approvalStatusChanged(this.product.id, this.previousApprovalStatus,this.product.propogateStatusToMembers).subscribe(res => {
        let serviceStatus: ServiceStatus = res as ServiceStatus;
        if (serviceStatus.status !== 'SUCCESS') {
          this.toastrService.error(serviceStatus.message);
        }else{
          this.loadCollectionMembers();
        }
      }
      );
    }
  }
  

   async deletMarkedFile(list: ProductAttachment[]) {
    if(list!==undefined && list.length>0){
      for (const element of list) {
        if (element.toDelete && !element.islocal) {
           await this.deleteFilesFromRepo(element.id, element.fileCategory);
        }
      }
  }
  }

  

  async uploadLocalFileToRepo(files: File[], category: string) {
    if (files.length > 0) {
      for (let index = 0; index < files.length; index++) {
          const element = files[index];
          await this.uploadSelectedFile(category,element);
      }
    }
  }
  uploadLocalFileToRepoNonBlocking(files: File[], category: string) {
    if (files.length > 0) {
      for (let index = 0; index < files.length; index++) {
          const element = files[index];
          this.uploadSelectedFileNonBlocking(category,element);
      }
    }
  }

  uploadSelectedFileNonBlocking(filecategory: string,selectedFileToUpload:File) {
    let fileName=selectedFileToUpload.name;
    this.toastrService.info('Uploading '+fileName);
    this.productService.uploadFileForProductWithProgress(this.product, selectedFileToUpload,filecategory).subscribe(
      (res)=>{
        if(res==='UPLOAD_SUCCESS'){
          this.toastrService.success(fileName+' Uploaded Successfully');
          this.productService.loadProductAttachmentsUrls(this.product,filecategory,this.searchCriteria.licensorId,this.searchCriteria.brandId);
        }else  if(res==='UPLOAD_FAILED'){
          this.toastrService.error(fileName+' - File upload failed');
        }
        }, (err) => {
        console.error('Error while upload' + err);
        this.toastrService.error(selectedFileToUpload.name+' Upload Failed:'+JSON.stringify(err));
        this.resetUploadFlags();
      }
    );
  }

  async uploadSelectedFile(filecategory: string,selectedFileToUpload:File) {
    this.fileUploading = true;
    //this.SelectedFileToUpload=this.getSelectedFileToUpload(filecategory);
    let fileName=selectedFileToUpload.name;
    this.toastrService.info('Uploading '+fileName);
    await this.productService.uploadFileForProductWithProgress(this.product, selectedFileToUpload,filecategory).toPromise().then(
      ()=>{
          this.toastrService.success(fileName+' Uploaded Successfully');
        }, (err) => {
        console.error('Error while upload' + err);
        this.toastrService.error(selectedFileToUpload.name+' Upload Failed:'+JSON.stringify(err));
        this.uploadError = true;
        this.resetUploadFlags();
      }
    );
  }


  async deleteFilesFromRepo(id: string, category: string) {
    if (!this.createAction /* && confirm("Do you really want to delete the file?")*/) {
      const obj: FileDeleteCriteria = new FileDeleteCriteria;
      obj.id = id;
      await this.productService.deleteFile(obj).toPromise().then((res) => {
         let status: ServiceStatus = res as ServiceStatus;
        if (status.status == 'SUCCESS') {
          this.toastrService.success(status.message);
          //this.refreshLocalListOfUrlsForCategory(category);
        }
        else {
          this.toastrService.error(status.message);
        }
        
      }, (err) => {
        this.toastrService.error('File Deletion Failed : ' + JSON.stringify(err));
      });
    }
  }

  
  deleteLocalFile(id: string, list: ProductAttachment[]): ProductAttachment[] {
    return list.filter(element => element.id != id);
  }

  resetUploadFlags() {
    this.fileUploading = false;
    this.fileUploaded = false;
  }

  onKeyupSkuCodeFilter(event, value) {
    if(event instanceof ClipboardEvent ){
      value=event.clipboardData.getData('Text');
    }

    if (this.createAction||this.newCollection) {
      if (event.key !== 'Shift') {
        if(value && value.trim().length>3){
          value=value.substring(0,3);
        }
        if (value && value.trim().length === 3) {
          this.loadBrandForSkuCode(value);
        } else {
          
        }
      }
    }
  }
  loadBrandForSkuCode(skuCode: string) {
    this.brandService.loadBrandForSkuCode(skuCode).subscribe((res) => {
      let brand = res as Brand;
      if (brand !== null) {
        this.brandList = [];
        this.brandList.push(brand);
        this.product.brandId=brand.id;
        this.product.licensorId=brand.licensorId;
        this.product.licensorName=brand.licensorName;
        this.product.brandName=brand.brandName;
        this.product.brandCategory=brand.category;
        this.product.pantoneColors=brand.pantoneColors;
      }
    });
  }

  loadMetadataForClassificationAndType(classifictaion:string,type:string){
    this.metadataService.getMetadataValueListByClassificationAndType(classifictaion,type).subscribe((res: ApiResponse) => {
      if(res.responseStatus.status==='SUCCESS'){
        let unsortedList=[];
        unsortedList.push(...res.responsePayload);
         let sortedList=this.sharedService.sort(res.responsePayload);
         switch (type) {
           case 'PRINT_EFFECT': this.printEffectList=sortedList; break;
           case 'APPROVAL_STATUS': this.approvalStatusList=unsortedList; break;// Not to be sorted
           case 'ART_STATUS':this.artStatusList=sortedList;
           default: break;
         }
         }
     });       
   }
  
  downloadFile(url: string) {
    var link = document.createElement('a');
    link.href = url;
    link.download = 'Download.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  onDropdownChange(metadatatype, metadataSubtype, dropDownselectedValue) {
      if (dropDownselectedValue === 'add') {
         this.metadata = new Metadata;
         this.metadata.metadataClassification = "PRODUCT";
         this.metadata.metadataType = metadatatype;
         this.metadata.metadataSubType = metadataSubtype;
         document.getElementById('metadataModalToggler').click();
      }
      //console.log('Dropdown Selected for category - ',dropDownselectedValue);
      if(metadatatype==='Garment'){
        this.product.category=undefined;
        this.setCategoryList(dropDownselectedValue);
      }
   }

   setCategoryList(garment:string){
    this.categoryList.length=0;
        if(this.garmentCategoryCombList && this.garmentCategoryCombList.length>0){
          let tempGarmentCategoryComb=this.garmentCategoryCombList.filter(x=>x.garment===garment);
          if(tempGarmentCategoryComb && tempGarmentCategoryComb.length>0 && tempGarmentCategoryComb[0].categoryList && tempGarmentCategoryComb[0].categoryList.length>0){
            this.categoryList.push(...tempGarmentCategoryComb[0].categoryList);
          }
        }
   }

   saveMetadata() {
      let metadata = new Metadata();
      metadata.metadataClassification = this.metadata.metadataClassification.toUpperCase();
      metadata.metadataType = this.metadata.metadataType.toUpperCase();
      metadata.metadataSubType = this.metadata?.metadataSubType?.toUpperCase();
      metadata.metadataValue = this.metadata.metadataValue;
      this.metadataService.createMetadata(metadata).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
           this.loadMetadataForClassificationAndType(metadata.metadataClassification,metadata.metadataType);
            this.toastrService.success(response.responseStatus.message);
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
      }, (err) => {
         this.toastrService.error(JSON.stringify(err));
      });
      this.reset();
   }

   closeMetadataModal() {
      this.reset();
   }
   
   reset(){
      if (this.metadata.metadataType.toUpperCase() === 'COLOR') {
         this.product.color = null;
      }
      if (this.metadata.metadataType.toUpperCase() === 'GARMENT') {
         this.product.garment = null;
      }
      if (this.metadata.metadataType.toUpperCase() === 'TREATMENT') {
         this.product.treatment = null;
      }
      if (this.metadata.metadataType.toUpperCase() === 'CATEGORY') {
         this.product.category = null;
      }
      if (this.metadata.metadataType.toUpperCase() === 'PRINT_EFFECT') {
         this.product.printEffect = null;
      }
   }

  
   /****************PRODUCT APPROVAL STATUS COLROS*********** */
    approvalStatusColor(status:string):string{
      if(this.sharedService.producatStatusColor.get(status)!==undefined){
        return this.sharedService.producatStatusColor.get(status);
      }else{
        return '';
      }

    }
/****************PRODUCT APPROVAL STATUS COLROSENDS*********** */
handleThumbnailError(productAttachment: ProductAttachment, event) {
  if (isNaN(productAttachment.thumbnailErrorCount)) {
    productAttachment.thumbnailErrorCount = 0;
  }
  event.target.src = productAttachment.fileSignedUrl;
  var x: number = productAttachment.thumbnailErrorCount;
  productAttachment.thumbnailErrorCount = x + 1;
  if (productAttachment.thumbnailErrorCount < 2) {
    event.target.src = productAttachment.fileSignedUrl;
    productAttachment.thumbnailSignedUrl = productAttachment.fileSignedUrl;
  } else {
    event.target.src = "/assets/default-image.jpg"
  }

  }

  /*********************** UPC START **********************/
   loadUpcDetails() {
      this.upcService.getUpcDetailByProductId(this.product.id).subscribe(response => {
         this.upcDetail = response.responsePayload;
         this.selectedSizesForUpcCreate = [];
      });
   }

   createOrGenerateUpcCodes(upcCodeCreationType: string) {
      this.prepareUpcListForCreateOrGenerate(upcCodeCreationType);
      if (upcCodeCreationType === 'AUTO') {
         this.generateUpcCodes();
      }if (upcCodeCreationType === 'MANUAL') {
         this.createUpcCodes();
      }else {
      }
   }

   generateUpcCodes() {
      this.populateUpcCreateConfirmMessage();
      if (confirm('Please confirm you want to generate UPCs for the below size(s):\n' + this.upcCreateConfirmationMessage)) {
         this.upcService.generateUpcCodes(this.selectedSizesForUpcCreate,this.product.sku).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.toastrService.success(response.responseStatus.message);
            }
            else {
               this.toastrService.error(response.responseStatus.message);
            }
            this.loadUpcDetails();
            this.selectedSizesForUpcCreate = [];
         }, (err) => {
            this.toastrService.error(JSON.stringify(err));
         });
      }
   }

   createUpcCodes() {
      this.populateUpcCreateConfirmMessage();
      if (confirm('Please confirm you want to create UPCs for the below size(s):\n' + this.upcCreateConfirmationMessage)) {
         this.upcService.createUpcCodes(this.selectedSizesForUpcCreate,this.product.sku).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.toastrService.success(response.responseStatus.message);
            }
            else {
               this.toastrService.error(response.responseStatus.message);
            }
            this.loadUpcDetails();
            this.selectedSizesForUpcCreate = [];
         }, (err) => {
            this.toastrService.error(JSON.stringify(err));
         });
      }
   }

   upcCreateConfirmationMessage: string;
   populateUpcCreateConfirmMessage(){
      this.upcCreateConfirmationMessage = '';
      this.selectedSizesForUpcCreate.forEach((element, index) => {
         this.upcCreateConfirmationMessage = this.upcCreateConfirmationMessage+'• '+element.size + '\n';
      });
   }

   prepareUpcListForCreateOrGenerate(upcCodeCreationType: string) {
      this.upcDetail.sizeListWithNoUpcCode.forEach((element, index) => {
         if (element.sizeSelected === true) {
            element.parentId = this.product.id;
            element.upcCodeCreationType = upcCodeCreationType;
            this.selectedSizesForUpcCreate.push(element);
         }
      });
   }

   resetUpc() {
      this.upcDetail = new UpcDetail;
      this.selectedSizesForUpcCreate = [];
   }
   onSelectOrUnselectAll(status: string) {
      this.selectUnSelectAllRadio = status;
      this.upcDetail.sizeListWithNoUpcCode.forEach((element, index) => {
         element.parentId = this.product.id;
         if (status === 'true') {
            element.sizeSelected = true;
         } else {
            element.sizeSelected = false;
            element.upcCode = '';
         }
      });
      this.setIsUpcSizeSelected();
   }

   upcSizeSelected: boolean = false;
   onSizeSelectionForUpcCreateOrGenerate(event, upc: Upc) {
      if (event.target.checked) {
         upc.sizeSelected = true;
      }
      if (!event.target.checked) {
         upc.sizeSelected = false;
      }
      this.setIsUpcSizeSelected();
   }

   setIsUpcSizeSelected() {
      let sizeSelecetedFlag: boolean = false;
      for (let index = 0; index < this.upcDetail.sizeListWithNoUpcCode.length; index++) {
         sizeSelecetedFlag = this.upcDetail.sizeListWithNoUpcCode[index].sizeSelected;
         if (sizeSelecetedFlag === true) {
            break;
         }
      }
      if (sizeSelecetedFlag == true) {
         this.upcSizeSelected = true;
      } else {
         this.upcSizeSelected = false;
      }
   }

   upcCodeCreationType: string;
   setUpcCodeCreationType(upcCodeCreationType: string) {
      this.upcCodeCreationType = upcCodeCreationType;
      this.onSelectOrUnselectAll('false');
   }

   updateUpcCode(upc){
      if (confirm('Are you sure you want to update UPC Code. ? \n')) {
         this.upcService.updateUpc(upc).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.toastrService.success(response.responseStatus.message);
            }
            else {
               this.toastrService.error(response.responseStatus.message);
            }
            this.loadUpcDetails();
            this.selectedSizesForUpcCreate = [];
         }, (err) => {
            this.toastrService.error(JSON.stringify(err));
         });
      }
   }

   deleteUpc(upc){
      if (confirm('Are you sure you want to delete UPC Code. ? \n')) {
         this.upcService.deleteUpc(upc.id).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.toastrService.success(response.responseStatus.message);
            }
            else {
               this.toastrService.error(response.responseStatus.message);
            }
            this.loadUpcDetails();
            this.selectedSizesForUpcCreate = [];
         }, (err) => {
            this.toastrService.error(JSON.stringify(err));
         });
      }
   }

   /*********************** UPC END **********************/

   resetSkuTrackerDetails(){
    this.product.approvalStatus=undefined;
    this.product.artStatus=undefined;
    this.product.statusDetails=[];
    this.product.statusDate=new Date;
    this.product.licensorComments=undefined;
    this.product.sampleDetails=[];
    this.product.skuTrackerRetailerInfo=[];
   }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if(this.editable){
      return false;
    }else{
    return true;
    }
  }

  /*getNextAvaiableSKUPrefix() {
    this.productService.getNextAvaiableSKUPrefix(this.product.brandId).subscribe(res => {
      let serviceStatus: ServiceStatus = res as ServiceStatus;
      if (serviceStatus.status === 'SUCCESS') {
        this.product.sku = serviceStatus.id;
        this.focusOnId('skuId');
      }else  if (serviceStatus.status === 'ERROR'){
        this.toastrService.error(serviceStatus.message);
      }
    });
  }*/

  getNextAvaiableSKUSeries() {
    this.productService.getNextAvaiableSKUSeries(this.product).subscribe(res => {
      let serviceStatus: ServiceStatus = res as ServiceStatus;
      if (serviceStatus.status === 'SUCCESS') {
        //
        this.product.sku = serviceStatus.id;
        this.focusOnId('skuId');
      }else  if (serviceStatus.status === 'ERROR'){
        this.toastrService.error(serviceStatus.message);
      }
    });
  }

  isVisualAvailable(){
    if(this.product.productImages?.length===0){
        return false;
    }else if(this.product.productImages?.length>0){
      let retVal:boolean=false;
      this.product.productImages.forEach(element => {
        if(!element.toDelete){
          retVal=true;
        }
        //element.toDelete && !element.islocal
      });
      return retVal;
    }
  }

  confirm(): boolean {
    return (!this.editable && !this.createAction);
  }

   loadAllCustomerList() {
      this.customerService.getCustomerListByTier(CustomerService.CUSTOMER_TIER_MASS).subscribe(res => {
         if (res && res != null && res.responsePayload.length > 0) {
           this.customerList=[];
            let customerArr: Customer[] = res.responsePayload as Customer[];
            customerArr.forEach(x => {
               let xCust:ExclusiveCustomer=new ExclusiveCustomer;
              xCust.customerId=x.id;
              xCust.customerName=x.customerName;
              this.customerList.push(xCust);
            });
            this.customerList = this.sharedService.sortListByPropertyName(this.customerList,'customerName');//--Need to filter on basis of cutomer Name later
         }
      });
   }

   loadExclusiveCustomers(productId: string){
      this.productService.getExclusiveCustomers(this.product.id).subscribe(res => {
         if (res && res != null && res.length > 0) {
            this.product.exclusiveCustomers = res;
         }
      });
    
   }

   addCustomer(){
      if (this.product.exclusiveCustomers === undefined || this.product.exclusiveCustomers === null) {
         this.product.exclusiveCustomers = [];
      }
      if(!this.checkIfItemExists(this.product.exclusiveCustomers, this.currentCustomer)){
        this.currentCustomer.createdOn=new Date;
         this.product.exclusiveCustomers.push(this.currentCustomer);
      }
      this.currentCustomer = new ExclusiveCustomer;
      if(this.product.exclusiveCustomers.length > 0){
         this.product.exclusiveToCustomers = true;
      }else{
         this.product.exclusiveToCustomers = false;
      }
   }

   removeCustomer(customer: ExclusiveCustomer) {
      this.product.exclusiveCustomers.splice(this.product.exclusiveCustomers.indexOf(customer), 1);
      if(this.product.exclusiveCustomers.length > 0){
         this.product.exclusiveToCustomers = true;
      }else{
         this.product.exclusiveToCustomers = false;
      }
   }

   checkIfItemExists(list: ExclusiveCustomer[], itemToCheck: ExclusiveCustomer) {
      if (list) {
         let temp = list.filter(itemInList => itemInList.customerId === itemToCheck.customerId && !itemInList.expired);
         return (temp.length == 1);
      } else {
         return false;
      }
   }


   sendProductEmail(){
     //this.createCatalogue();
      this.showEmailEditor=true;
      let emailConfig: EmailTemplateConfig = new EmailTemplateConfig;
      emailConfig.productIdList.push(this.product.id);
      emailConfig.templateName='productDetailsWithVisual';
      this.composeEmail=new ComposeEmail;
      this.getEmailTemplate(emailConfig);
   }

   sendApprovalEmail(){
    this.showEmailEditor=true;
    let emailConfig: EmailTemplateConfig = new EmailTemplateConfig;
    emailConfig.productId=this.product.id;
    emailConfig.brandId=this.product.brandId;
    emailConfig.licensorId=this.product.licensorId;
    emailConfig.sku=this.product.sku;
    emailConfig.templateName='productApprovalEmail';
    this.composeEmail=new ComposeEmail;
    this.getEmailTemplate(emailConfig);
    let availableAtt=[];
    availableAtt=availableAtt.concat(this.product.photoApproval).concat(this.product.approvalDocs);
    this.composeEmail.emailAttachments=this.sharedService.productAttachmentToEmailAttachment(availableAtt.filter(x=>x.isSelected));
   }

   sendPhotoApprovalEmail(){
    this.showEmailEditor=true;
    let emailConfig: EmailTemplateConfig = new EmailTemplateConfig;
    emailConfig.productId=this.product.id;
    emailConfig.brandId=this.product.brandId;
    emailConfig.licensorId=this.product.licensorId;
    emailConfig.sku=this.product.sku;
    emailConfig.templateName='productPhotoApprovalEmail';
    this.composeEmail=new ComposeEmail;
    this.getEmailTemplate(emailConfig);
    let availableAtt=[];
    availableAtt=availableAtt.concat(this.product.photoApproval).concat(this.product.approvalDocs);
    this.composeEmail.emailAttachments=this.sharedService.productAttachmentToEmailAttachment(availableAtt.filter(x=>x.isSelected));
   }

   getEmailTemplate(emailConfig:EmailTemplateConfig){
      this.composeEmailService.getEmailTemplate(emailConfig).subscribe(response => {
         let composeEmailTemp:ComposeEmail = response.responsePayload;
         this.composeEmail.emailContent=composeEmailTemp.emailContent;
         this.composeEmail.emailTo=composeEmailTemp.emailTo;
         this.composeEmail.emailCc=composeEmailTemp.emailCc;
         this.composeEmail.emailSubject=composeEmailTemp.emailSubject;
         this.composeEmail.emailTemplateConfig=emailConfig;
         document.getElementById('composeEmailToggler').click();
      });
   }

   resetCollectionAttachments(){
    this.refreshSearchCriteria();
    this.productService.resetCollectionAttachments(this.searchCriteria).subscribe(res =>{
      let status:ServiceStatus=res as ServiceStatus;
      if(status.status==='SUCCESS'){
         //let product:Product=status.object as Product;
         this.productService.loadProductImagesUrls(this.product,this.searchCriteria.licensorId,this.searchCriteria.brandId);
      }else{
         this.toastrService.error(status.message);
      }

   })
   }

   async emailSent(composeEmail:ComposeEmail){
     if(composeEmail && composeEmail.emailTemplateConfig){
       let config:EmailTemplateConfig=composeEmail.emailTemplateConfig;
       if(config.templateName==='productApprovalEmail'){
         this.productService.logApprovalEmail(composeEmail,this.product,'APPROVAL_DOCS');
         await this.productService.handleApprovalEmailSentForProduct(this.product,'EMAIL','APPROVAL_DOCS');
        
       }
       if(config.templateName==='productPhotoApprovalEmail'){
        this.productService.logApprovalEmail(composeEmail,this.product,'PHOTO_APPROVAL');
        await this.productService.handleApprovalEmailSentForProduct(this.product,'EMAIL','PHOTO_APPROVAL');
        this.loadProduct();
      }
     }
   }
   

  

getEnterCount(str:string,max:number){
  let rowCount=str.split(/\r\n|\r|\n/).length;
  if(rowCount>max){
     return max;
  }else{
     return rowCount;
  }
}
  
newGuid():string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

changePriority(product: Product) {
  if (this.userAccess?.canChangeProductPriority) {
     this.productService.changePriority(product.id, !product.priority).subscribe(res => {
        let serviceStatus: ServiceStatus = res as ServiceStatus;
        if (serviceStatus && serviceStatus.status === 'SUCCESS') {
           product.priority = !product.priority;
        } else {
           this.toastrService.error('Failed to change priority - ' + serviceStatus.message);
        }
     }, err => {
        this.toastrService.error('Error while changing priority - ' + err);
     });
  }
}

selectedTabChange(event) {
    switch (event.tab.textLabel) {
     case 'upcTab':
       this.loadUpcDetails();
        break;
     case 'associatedSalesOrdersTab':
        this.loadAssociatedSalesOrder();
        break;
     default:
        break;
  }
}
  loadAssociatedSalesOrder() {
    this.salesService.getAssociatedSalesOrdersForProduct(this.product.id).subscribe(response => {
      let res:ApiResponse=response as ApiResponse;
         this.associatedSalesOrders = res.responsePayload;      
      });
   
  }

  /*createCatalogue() {
    var elementTemp:HTMLElement = document.createElement('div');
    //elementTemp.innerHTML="<table><tr><td>Prakash</td></tr></table>";
    //var data = document.getElementById('containerId');
    html2canvas(elementTemp, { allowTaint: true, useCORS: true }).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png');
      
    });
    
  }*/

  pnlSelectionChangeForProduct(productAttachment:ProductAttachment){
    
    if(productAttachment.id && this.product.brandPnl && this.product.brandPnl.length>0){
      this.product.brandPnl.forEach(att => {
        att.liked=false;
      });
      productAttachment.liked=true;
      this.product.preferredPnlId=productAttachment.id;
    }

  }

  getPreferredPnl():ProductAttachment[]{
   return this.productService.getPreferredPnl(this.product);
  }

   async getPrinterList() {
      await this.vendorService.getPrinterList().toPromise().then(res => {
         if (res && res.responseStatus.status === 'SUCCESS' && res.responsePayload != null) {
            this.printerList = res.responsePayload;
         }
      });
   }
 
}