<div>
    <div class="row mt-2 d-flex justify-content-center">
       <h2 class="d-inline">
 
          Printer Capacity
 
       </h2>
    </div>
    <div>
        <div class="row">
            <div class="col-sm-8" *ngIf="currentLoggedInUser && currentLoggedInUser!==null">
            <app-search #searchComponent [placeholder]="'Order#, Customer Name, Printer Name, etc. (space delimited)'" [searchConfig]="searchConfig" [existingList]="printerCapacityViewPrinterGrouped" [sortColumns]="sortColumns"></app-search>
            </div>
            
        </div>
        <!--<div class="custom-control custom-checkbox mr-sm-2 ml-3">
            <input type="checkbox" class="custom-control-input ml-2" name="showWeekWiseDetails" id="showWeekWiseDetails" 
               [checked]="showWeekWiseDetails" [(ngModel)]="showWeekWiseDetails">
            <label class="custom-control-label font-weight-bold f-13" for="showWeekWiseDetails">
              Show Details
            </label>
         </div>
        -->
        <div>
            <ng-container>
                <table class=" f-10 table table-sm table-bordered  table-condensed" style="border: 0px solid #dee2e6;">
                              <thead class="thead-dark" style="position: sticky;top: 60px;">
                                 <tr class="text-center">
                                    <th style="width: 15% !important;">Printer</th>
                                    <th>Month/Year</th>
                                    <th>Total Print Qty</th>
                                    <th>Total Print Cost</th>
                                    
                                    <th>Total Sales</th>
                                 </tr>
                              </thead>
                              <tbody>
                                <!--<tr><td colspan="5" class="border-0" style="padding: 6px !important; "></td></tr> -->
                                    
                        <ng-container *ngFor="let pcvpg of printerCapacityViewPrinterGrouped; index as pcvpgIndex;">
                                
                                <ng-container  *ngFor="let pcvg of pcvpg.printerCapacityViewGroupedList; index as pcvgIndex;let firsPcvg = first;">
                                    
                                    
                                    <tr class="text-center border" ><!--[style.background-color]="pcvgIndex%2===1?'rgba(0,0,0,.05)':''"-->
                                    
                                        <!--<td *ngIf="firsPcv" class="f-12" style="border-left: 4px solid #859ba9;">{{pcvg.printerName}}</td>-->
                                        <td  [attr.rowspan]="pcvpg.printerCapacityViewGroupedList.length + getExpandedRowCount(pcvpg)" *ngIf="firsPcvg" class="f-12" style="border-left: 4px solid #859ba9;">
                                            <div class="mt-auto">
                                            {{pcvg.printerName}}
                                            <div class="mb-auto d-flex justify-content-center">
                                                <button  type="button" (click)="expandPrinterGroup(pcvpg,true)" class="btn btn-sm btn-outline-info f-9 " style="border: none;" >+ Expand All</button>
                                                <button  type="button" (click)="expandPrinterGroup(pcvpg,false)" class="btn btn-sm btn-outline-info f-9" style="border: none;" >- Collapse All</button>
                                            </div>
                                            </div>
                                        </td>
                                        <td (click)="pcvg.expanded=!pcvg.expanded" [style.background-color]="pcvg.expanded?'#eb7d6a42':''" >{{pcvg.orderShipMonthYear}}</td>
                                        <td (click)="pcvg.expanded=!pcvg.expanded" [style.background-color]="pcvg.expanded?'#eb7d6a42':''" >{{pcvg.totalPrintQty}}</td>
                                        <td (click)="pcvg.expanded=!pcvg.expanded" [style.background-color]="pcvg.expanded?'#eb7d6a42':''" >{{pcvg.totalPrinterCost}}</td>
                                        
                                        <td (click)="pcvg.expanded=!pcvg.expanded" [style.background-color]="pcvg.expanded?'#eb7d6a42':''">{{pcvg.totalSalesCost}}</td>
                                        
                                    </tr>
                                    <tr *ngIf="pcvg.expanded">

                                        <ng-container>
                                            
                                            <td colspan="4" style="background-color: #ff634712;" class="text-center">
                                            <table class=" f-10 table table-sm  mb-1 mx-auto" style="width:90%;border: 0px solid #dee2e6;">
                                                <thead class="thead-light">
                                                    <th>Printer Week</th>
                                                    <th>Order#</th>
                                                    <th>Customer</th>
                                                    <th>Coordinator</th>
                                                    <th>Sales Rep.</th>
                                                    <th>Customer Order Value</th>
                                                    <th>Printer Order Value</th>
                                                    <th>Printer Qty</th>
                                                    <th>Printer Ship Date</th>
                                                    <th>Printer Cancel Date</th>
                                                    <th>Production Status</th>
                                                    <th>Notes</th>
                                                </thead>
                                                <tbody>
                                                    <ng-container >
                                                        <ng-container *ngFor="let pcv of pcvg.printerCapacityViews; index as pcvIndex; let firsPcv = first;">
                                                        <tr>
                                                            <td>{{pcv.printerWeek}}</td>
                                                            <td>
                                                                <span *ngIf="!userAccess?.canViewSalesOrder">{{pcv.salesOrderNumber}}</span>
                                                                    <a [routerLink]="['/salesOrder/view/'+pcv.salesOrderId]" target="_blank" *ngIf="userAccess?.canViewSalesOrder">
                                                                        {{pcv.salesOrderNumber}}
                                                                    </a>
                                                                </td>
                                                            <td>{{pcv.customerName}}</td>
                                                            <td>{{pcv.coordinatorName}}</td>
                                                            <td>{{pcv.salesRepName}}</td>
                                                            <td>{{pcv.salesTotalCost}}</td>
                                                            <td>{{pcv.printerTotalCost}}</td>
                                                            <td>{{pcv.printerQty}}</td>
                                                            <td>{{pcv.printerActualShipDate | date:'mediumDate'}}</td>
                                                            <td>{{pcv.printerCancelDate | date:'mediumDate'}}</td>
                                                            <td>{{'sales.productionStatus.'+pcv.productionStatus| transform}}</td>
                                                            <td>
                                                               <div class="ml-1" (click)="pcv.expand?pcv.expand=!pcv.expand:pcv.expand=true;">
                                                                  <span *ngIf="!pcv.expand"><i class="fas fa-chevron-down fa-1x"></i></span>
                                                                  <span *ngIf="pcv.expand"><i class="fas fa-chevron-up fa-1x"></i></span>
                                                               </div>
                                                            </td>
                                                        </tr>

                                                         <tr *ngIf="pcv.expand" class="border-top-0 border" style="background-color: #ff634712;">
                                                            <td colspan="9999">
                                                               <div class="row f-10">
                                                                  <div class="col-12">
                                                                     <app-notes [editable]="true" [parentId]="pcv.salesOrderId" [type]="'SALES_ORDER'" [subType]="'PRINTER_CAPACITY'" [notesHeaderText]="''"></app-notes>
                                                                  </div>
                                                               </div>
                                                            </td>
                                                         </tr>

                                                    </ng-container>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                            </td>
                                        </ng-container>  
                                    </tr>
                                    <tr *ngIf="pcvg.expanded">
                                        <td colspan="999"></td>
                                    </tr>
                                    
                                    
                                </ng-container>
                                <tr><td colspan="5" class="border-0" style="padding: 6px !important; "></td></tr>    <!--background-color: #dee2e6;-->
                        </ng-container>
                                 
                                 
                              </tbody>
                           </table>
              </ng-container>
            <!--<ng-container *ngFor="let pcvg of printerCapacityViewGrouped">
                {{pcvg.printerName}} - {{pcvg.orderShipMonthYear}}
            </ng-container>-->
        </div>
    </div>







    <div class="floatBtn d-flex flex-column">
      <button type="button" class="btn btn-circle bg-success mb-2" *ngIf="userAccess?.canDownloadPrinterCapacity" (click)="createPrinterCapacityExcel()"><i class="fas fa-file-excel fa-2x" style="color:white"></i></button>
        <button type="button" class="btn btn-circle bg-secondary mb-2" matTooltip="Scroll To Top" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'});"  matTooltipPosition="left" matTooltipClass="bg-dark">
           <i class="fas fa-arrow-up fa-2x" style="color:white"></i>
        </button>
     </div>
</div>
