<div class="row mt-2 d-flex justify-content-center">
   <h4 class="d-inline">{{reportType}}</h4>
</div>
<hr>
<div class="mb-5">
<table id="reportTable" class="mt-4 p-3 mb-5 bg-white rounded table table-sm table-striped table-bordered table-condensed hover"  datatable
   [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
   <thead>
      <tr class="bg-dark text-white">
         <th  style="width: 1%; " *ngFor="let header of reportDetails?.reportHeaders">{{header}}</th>
      </tr>
   </thead>
   <tbody>
      
      <tr *ngFor="let reportData of reportDetails?.reportData">
         <ng-container *ngFor="let rowData of reportData">
         <td style="width: 1%;" class="text-wrap" *ngIf="rowData && rowData!==null && !isProductAttachment(rowData)">{{rowData}}</td>
         <td style="width: 1%;" *ngIf="!rowData || rowData===null"></td>
         <td style="width: 1%;" *ngIf="rowData && rowData!==null && isProductAttachment(rowData)">
            <app-image-with-preview [attachment]="rowData"></app-image-with-preview>
            <!--<img [src]="rowData.thumbnailSignedUrl" class="img-thumbnail img-fluid" 
                                        alt="Pending" style="display: block;  width:60px;  height:60px;"
                                        >--><!--(error)="handleThumbnailError(attachment,$event)" (mouseenter)="preview(attachment,true,$event);" (mouseleave) ="preview(attachment,false,$event)"-->
         </td>
      </ng-container>
      </tr>
   </tbody>
</table>
</div>

   <!--<tfoot>
         <tr>
            <th><input type="text" placeholder="Search ID" name="a" /></th>
            <th><input type="text" placeholder="Search Size" name="b" /></th>
            <th><input type="text" placeholder="Search UPC Code" name="v" /></th>
            <th><input type="text" placeholder="Search UPC Code" name="y" /></th>
            <th><input type="text" placeholder="Search UPC Code" name="z" /></th>
         </tr>
      </tfoot>-->

