import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { SearchComponent } from 'src/app/common/search/search.component';
import { SearchDetails } from 'src/app/shared/model/common/search-details.model';
import { TrimsInfo, TrimsOrderTrackerDetail, TrimsOrderTrackerDetailPrinterGrouped, TrimsOrderTrackerDetailsSalesOrderGrouped, TrimsOrderTrackerTrimsDetail } from 'src/app/shared/model/sales/fulfillment/trims-info.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { ProductionTrackerService } from 'src/app/shared/service/sales/fulfillment/production-tracker.service';
import { SearchService } from 'src/app/shared/service/search.service';

@Component({
  selector: 'app-trims-tracker-list',
  templateUrl: './trims-tracker-list.component.html',
  styleUrls: ['./trims-tracker-list.component.css']
})
export class TrimsTrackerListComponent implements OnInit {

  constructor(private searchService:SearchService, private authService:AuthService,
     private productionTrackerService:ProductionTrackerService, private toastrService:ToastrService) { }
  userAccessSubscription: Subscription;
  userAccess: UserAccess;

  currentLoggedInUserSubscription: Subscription;
  currentLoggedInUser: User;

  @ViewChild('searchComponent') searchComponent:SearchComponent<TrimsOrderTrackerDetailsSalesOrderGrouped>;
  searchConfig: SearchDetails;
  
  trimsOrderTrackerDetailsSalesOrderGrouped:TrimsOrderTrackerDetailsSalesOrderGrouped[]=[];
  sortColumns = [
    
    
  ];
  ngOnInit(): void {
    this.handleSubscriptions();
    if (!this.searchConfig) {
      this.searchConfig = this.searchService.trimsInfoViewListSearchDetails;
      //this.evaluateRequestFilter();
      //this.setFilterConfigs();
      
    }
  }

  handleSubscriptions() {
    this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
      this.userAccess = access;
    });
    this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
      this.currentLoggedInUser = currentLoggedInUser;
    });
  }

  search() {
    if (this.searchComponent) {
       this.searchComponent.search();
    }
 }

  addTrimVendor(tsog:TrimsOrderTrackerDetailsSalesOrderGrouped,tpg:TrimsOrderTrackerDetailPrinterGrouped){

     let trimVendor:TrimsOrderTrackerDetail=new TrimsOrderTrackerDetail();
     trimVendor.printerId=tpg.printerId;
     trimVendor.printerInfoId=tpg.printerInfoId;
     trimVendor.salesOrderId=tsog.salesOrderId;
     trimVendor.trimDetails=[];
     let trimsDetails:TrimsOrderTrackerTrimsDetail=new TrimsOrderTrackerTrimsDetail();
     trimVendor.trimDetails.push(trimsDetails);
     
     if(!tpg.trimsOrderTrackerDetails){
      tpg.trimsOrderTrackerDetails=[];
      tpg.trimsOrderTrackerDetailsCount=1;
     }
     tpg.trimsOrderTrackerDetails.push(trimVendor);

     tpg.trimsOrderTrackerDetailsCount=tpg.trimsOrderTrackerDetailsCount+1;
     tsog.salesTrimsOrderTrackerDetailsCount=tsog.salesTrimsOrderTrackerDetailsCount+1;
     console.log(tpg.trimsOrderTrackerDetails.length);

  }

  addTrimVendorTrimDetails(tsog:TrimsOrderTrackerDetailsSalesOrderGrouped,tpg:TrimsOrderTrackerDetailPrinterGrouped,trimVendor:TrimsOrderTrackerDetail){

    if(!trimVendor.trimDetails){
      trimVendor.trimDetails=[];
     }
    let trimsDetails:TrimsOrderTrackerTrimsDetail=new TrimsOrderTrackerTrimsDetail();
    trimsDetails.parentId=trimVendor.id;
    //trimsDetails.trim='Dummy';
    trimVendor.trimDetails.push(trimsDetails);
    
    tpg.trimsOrderTrackerDetailsCount=tpg.trimsOrderTrackerDetailsCount+1;
    tsog.salesTrimsOrderTrackerDetailsCount=tsog.salesTrimsOrderTrackerDetailsCount+1;
    

 }

 save(tsog:TrimsOrderTrackerDetailsSalesOrderGrouped){
  if(true/*this.valid()*/){
  
  this.productionTrackerService.saveTrimsTrackerForSalesOrder(tsog).subscribe(serviceStatus=>{
    if(serviceStatus && serviceStatus!=null && serviceStatus.status==='SUCCESS'){
      //this.getProductionTrackerInfo();
      tsog.editable=false;
      this.toastrService.success(serviceStatus.message);
      
    }else{
      if(serviceStatus.errorCode==='STALE_OBJECT_ERROR'){
        this.toastrService.error('Trims tracker has been modified by other user. Please refresh and make your changes again before save');  
      }else{
        this.toastrService.error(serviceStatus.message);
      }
    }
  });
}
}

}
