
<div class="d-flex justify-content-center align-items-center mt-3" *ngIf="selectedProductList">
    <div class="text-color-blue"><h3>Multiple Submissions&nbsp;</h3></div>
</div>
<div  class="separator-blue" *ngIf="selectedProductList && selectedProductList.length>0">{{selectedProductList[0].brandName}}</div>

<ng-container *ngFor="let product of selectedProductList">
    <app-submission-details [product]="product"></app-submission-details>
</ng-container>
<!--<ng-container>
    <button type="button" (click)="sendApprovalEmail(selectedProductList)" style="position: absolute;right:10%"
                                                         class="btn bg-navy-blue btn-sm mt-1 ml-2" *ngIf="userAccess?.canCreateApprovalForms"
                                                         matTooltip="Send Approval Email" matTooltipPosition="below" matTooltipClass="bg-dark">
                                                         <i class="fa fa-envelope text-light fa-lg "></i>
                                          </button>
</ng-container>-->
<ng-container>
    <div class="floatBtn d-flex flex-column">  
        <button id="sendEmail" type="button" class="btn btn-circle  mb-2 bg-navy-blue" (click)="sendApprovalEmail(selectedProductList)" 
        *ngIf="userAccess?.canCreateApprovalForms && selectedProductList && selectedProductList.length>0"
        matTooltip="Send Approval Email" matTooltipPosition="below" matTooltipClass="bg-dark">
        <i class="fa fa-envelope text-light fa-lg fa-2x"></i> 
        </button>
        <a (click)="goBack()" class="btn btn-circle"  style="background-color: tomato;"
    matTooltip="Exit" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-arrow-left  fa-2x"></i></a>
        
    </div>
</ng-container>
<ng-container>
    <button id="composeEmailToggler" type="button" style="display: none;" data-toggle="modal" data-target="#composeEmail"></button>
    <div *ngIf="showEmailEditor">
        <app-compose-email  [modalId]="'composeEmail'" [emailHeader]="'Email'" [composeEmail]="composeEmail" (onSend)="emailSent($event,selectedProductList)"></app-compose-email>
        </div>
</ng-container>

