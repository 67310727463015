import { EmailTemplateConfig } from './email-template-config.model';

export class ComposeEmail {
   emailTo: string [] = [];
   emailCc: string [] = [];
   emailBcc: string [] = [];
   emailSubject: string;
   emailContent: string;
   emailPriority: boolean = false;
   emailTemplateBased: boolean = false;
   emailAttachmentAsLink: boolean = true;
   emailTemplateName: string;
   emailAttachments: EmailAttachment[] = [];
   emailTemplateConfig:EmailTemplateConfig;
   
}

export class EmailAttachment{
   attachmentType: string;
   attachmentFileName: string;
   attachmentCategory: string;
   attachmentSelected: boolean;
   attachmentdId: string;
   attachmentSource: string;
   attachmentInline: boolean;
   attachmentExternal: boolean;
}
