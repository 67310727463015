import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ProductAttachment } from 'src/app/shared/model/product.model';
import { OrderDetailInfo } from 'src/app/shared/model/sales/fulfillment/order-detail-info.model';
import { PrinterInfo } from 'src/app/shared/model/sales/fulfillment/printer-info.model';
import { ProductionTracker } from 'src/app/shared/model/sales/fulfillment/production-tracker.model';
import { OrderDetail } from 'src/app/shared/model/sales/order-detail.model';
import { SalesOrder } from 'src/app/shared/model/sales/sales-order.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { FileHandlingService } from 'src/app/shared/service/file-handling.service';
import { ProductionTrackerService } from 'src/app/shared/service/sales/fulfillment/production-tracker.service';

@Component({
  selector: 'app-allocated-sales-order',
  templateUrl: './allocated-sales-order.component.html',
  styleUrls: ['./allocated-sales-order.component.css']
})
export class AllocatedSalesOrderComponent implements OnInit {
  

  constructor(private authService: AuthService, private toastrService: ToastrService,private activatedRoute: ActivatedRoute,private productionTrackerService:ProductionTrackerService,private fileHanldingService:FileHandlingService) { }

  userAccessSubscription: Subscription;
  userAccess: UserAccess;

  currentLoggedInUserSubscription: Subscription;
  currentLoggedInUser: User;
  editable: boolean = false;
  salesOrderId:string;
  printerId:string;
  productionTracker: ProductionTracker;
  salesOrder:SalesOrder=new SalesOrder;// Dummy, may be need to load whole salesorder later.Keeping it lite for now
  orderDetailInfoList:OrderDetailInfo[]=[];
  printerInfo:PrinterInfo;

  async ngOnInit() {
    this.handleSubscriptions();
    this.evaluateAction();
    await this.loadAllocatedSalesOrderDetails();
  }
 

  handleSubscriptions() {
    this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
       this.userAccess = access;
    });
    this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
       this.currentLoggedInUser = currentLoggedInUser;
    });
  }

  evaluateAction() {
    const activatedRoutePath = this.activatedRoute.snapshot.routeConfig.path;
    this.printerId=this.activatedRoute.snapshot.paramMap.get('printerId');
    this.salesOrderId=this.activatedRoute.snapshot.paramMap.get('id');
    this.salesOrder.id=this.salesOrderId;
    /*if (this.activatedRoute.snapshot.paramMap.get('action') === "view") {
       this.viewAction = true;
    } else if (this.activatedRoute.snapshot.paramMap.get('action') === "create") {
       this.cloneAction = false;
       this.createAction = true;
       this.editable = true;
    } else if (this.activatedRoute.snapshot.paramMap.get('action') === "edit") {
       this.cloneAction = false;
       this.createAction = false;
       this.editable = true;
    } else if (this.activatedRoute.snapshot.paramMap.get('action') === "clone") {
       this.cloneAction = true;
       this.createAction = true;
       this.editable = true;
    }*/
  }

  async loadAllocatedSalesOrderDetails() {
    //throw new Error('Method not implemented.');
    this.getProductionTrackerInfo();
    this.getPnlSPecialityAttachments();
  }
  getPnlSPecialityAttachments() {

    this.fileHanldingService.loadChildAttachmentsForSales(this.salesOrder,'SALES_PNL_SPECIALITY_FILES','pnlSpecialityAttachments');
  }

  getProductionTrackerInfo() {
    //this.productionTracker=new ProductionTracker(this.salesOrder.id);
    this.productionTrackerService.loadProductionTrackerForPrinterPortal(this.salesOrderId).subscribe(res=>{
      if(res && res!=null){
        this.productionTracker=res;
        if(this.productionTracker && this.productionTracker.printerInfoList 
          && this.productionTracker.printerInfoList.length>0){
            this.productionTracker.printerInfoList.forEach(pi => {
              if(pi.printerId===this.printerId){
                this.orderDetailInfoList=pi.orderDetailInfoList;
                this.printerInfo=pi;
              }
              
            });
            
          }
        console.log('Production Tracker for allocated Sales Order',res);
      }else{
        //this.toastrService.error('Error while loading the Production Tracker Info');
      }
    });
  }

}
