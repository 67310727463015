import { KeyValue } from "@angular/common";

export class SearchFilter {
	filterName: string;
	filterType: string;
	values: string[] = [];

	constructor(filterName, filterType, values) {
		this.filterName = filterName;
		this.filterType = filterType;
		this.values = values;
	}
}

export interface FilterConfig{
	filterType:string;
	label:string;
	currentSelection:any;
	fieldName:string;
	options?:any[];
	optionValueVar?:string;
	optionDislpayVar?:string;
	location?:string;
	filterSearchText?:string;
	selectedCount?:number;
	


}
