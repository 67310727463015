import { Notes } from "../../common/notes.model";
import { OrderDetailInfo } from "./order-detail-info.model";
import { TrimsInfo } from "./trims-info.model";

export class PrinterInfo {

    id:string;
    salesOrderId:string;
    printerName:string;
    printerId:string;
    philcosPrinterPO:string;
    printerPOType:string;
    invoiceValue:number;
    invoiceCurrency:String;
    printerRequestShipDate:Date;
    printerCancelDate:Date;
    printerActualShipDate:Date;
    xFactoryDate:Date;
    etaWarehouse:Date;
    printerWeek:number;
    printerNotes:Notes[]=[];
    deleteFlag:boolean=false;
    origination:string;
    version:number=0;
    orderDetailInfoList:OrderDetailInfo[]=[];
    trimsRequired:string;
    trimInfoList:TrimsInfo[]=[];
    printInfoStatus:string;
    artInfoStatus:string;
    ppSampleInfoStatus:string;
    topSampleInfoStatus:string;
    boxLabelsInfoStatus:string;
	polyBagsInfoStatus:string;
	finishingSheetInfoStatus:string;
	trimInfoStatus:string;
    totalSaleQty:number=0;
    obsolete:boolean;
    constructor(salesOrderId:string){
        this.origination='MANUAL';
        this.salesOrderId=salesOrderId;
        this.trimInfoList.push(new TrimsInfo(salesOrderId));
    }
}
