<style>
</style>
<head>
<title>{{'orgTitle'|env}}</title>

</head>
<div class="container-fluid">
<app-navbar></app-navbar>

<div [@routeAnimations]="prepareRoute(outlet)" >
    <router-outlet #outlet="outlet"></router-outlet>
</div>