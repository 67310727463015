<form name="costingSheetsForm" #costingSheetsForm="ngForm" autocomplete="off" novalidate class="mb-3">

   
   <div class="d-flex justify-content-center mt-3">
      <div>
         <h2>{{currentStyleCosting?.sku}}</h2>
      </div>
   </div>

   <div class="row m-1 overflow-auto mr-5" style="overflow-x: auto;" *ngIf="costingSheets">
      <ng-container *ngFor="let costingSheet of costingSheets; index as costingSheetIndex">
         <div class="col-4 mt-0 p-2" *ngIf="!costingSheet.deleteFlag" [style]="costingSheet?.approvalStatus==='APPROVED'?'background:#d7ffe2;':''">

            <table class="table table-sm table-striped table-bordered mb-0">
               <thead>
                  <!--<tr style="background-color:#1BDDB1;font-weight: 400;" class="text-white">#1BDDB1    === 4e3ce5ad -->
                  <tr class="text-white fw-400" [style]="costingSheet?.approvalStatus==='APPROVED'?'background:#17be98;':'background:#4e3ce5ad;'">
                     <th width="55%" class="f-11 fw-400" style="text-align: center;">Costing Sheet</th>
                     <th width="45%" class="f-11 fw-400" style="text-align: center;">Details</th>
                  </tr>
               </thead>

               <ng-container *ngIf="costingSheet.fullPackageOrder">
                  <tr>
                     <td><label class="mb-0 ml-1 label-medium-slight-dark">Full Package Order</label></td>
                     <td>
                        <span class="form-control form-control-sm" style="font-size: 11px; padding-left: .30rem;border-width: 0px;">
                           <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input ml-1" name="fullPackageOrder" id="fullPackageOrder" [disabled]="true" checked>
                              <label class="custom-control-label mr-2 py-1 text-wrap" style="font-size: .80rem; color: lightslategray;" for="fullPackageOrder">&nbsp;</label>
                           </div>
                        </span>
                     </td>
                  </tr>
               </ng-container>

               <ng-container *ngIf="!costingSheet.fullPackageOrder">
                  <tr>
                     <td><label class="mb-0 ml-1 label-medium-slight-dark">Blanks Supplier</label></td>
                     <td>
                        <span class="form-control form-control-sm" style="font-size: 11px; padding-left: .30rem;border-width: 0px;">
                           {{costingSheet.allocatedBlankSupplierNames}}
                        </span>
                     </td>
                  </tr>
               </ng-container>

               <tr>
                  <td><label class="mb-0 ml-1 label-medium-slight-dark">{{!costingSheet.fullPackageOrder? 'Printer' : 'Full Package Printer'}}</label></td>
                  <td>
                     <span class="form-control form-control-sm" style="font-size: 11px; padding-left: .30rem;border-width: 0px;">
                        {{costingSheet.allocatedPrinterNames}}
                     </span>
                  </td>
               </tr>

               <tr>
                  <td><label class="mb-0 ml-1 label-medium-slight-dark">Average Blanks Cost</label></td>
                  <td *ngIf="costingSheet.fullPackageOrder">
                     <span class="form-control form-control-sm" style="font-size: 11px; padding-left: .30rem;border-width: 0px;">Not Applicable</span>
                  </td>
                  <td *ngIf="!costingSheet.fullPackageOrder">
                     <span class="form-control form-control-sm" style="font-size: 11px; padding-left: .30rem;border-width: 0px;">{{costingSheet.averageBlanksCost}}</span>
                  </td>
               </tr>               

               <tr>
                  <td><label class="mb-0 ml-1 label-medium-slight-dark">{{!costingSheet.fullPackageOrder? 'Printing Cost' : 'Full Package Printing Cost'}}</label></td>
                  <td>
                     <span class="form-control form-control-sm" style="font-size: 11px; padding-left: .30rem;border-width: 0px;">{{costingSheet.totalPrintingCost}}</span>
                  </td>
               </tr>

               <tr>
                  <td><label class="mb-0 ml-1 label-medium-slight-dark">Selling Price Per Unit</label></td>
                  <td>
                     <span class="form-control form-control-sm" style="font-size: 11px; padding-left: .30rem;border-width: 0px;">{{currentStyleCosting.sellingPricePerUnit}}</span>
                  </td>
               </tr>

               <tr>
                  <td><label class="mb-0 ml-1 label-medium-slight-dark">Total Order Quantity</label></td>
                  <td>
                     <span class="form-control form-control-sm" style="font-size: 11px; padding-left: .30rem;border-width: 0px;">{{currentStyleCosting.totalQty}}</span>
                  </td>
               </tr>

               <tr>
                  <td><label class="mb-0 ml-1 label-medium-slight-dark">Total Blanks Quantity</label></td>
                  <td>
                     <span class="form-control form-control-sm" style="font-size: 11px; padding-left: .30rem;border-width: 0px;">{{currentStyleCosting.totalRequiredBlanksQuantity}}</span>
                  </td>
               </tr>               

            </table>


            <table class="table table-sm table-striped table-bordered">
               <thead>
                  <tr [style]="costingSheet?.approvalStatus==='APPROVED'?'background:#17be98;':'background:#4e3ce5ad;'" class="text-white fw-400">
                     <!-- 4e3ce5ad 000000ad ; :#4e3ce5ad -->
                     <th width="55%" class="f-11 fw-400" style="text-align: center;">Cost Description</th>
                     <th width="15%" class="f-11 fw-400" style="text-align: center;">Remarks</th>
                     <th width="15%" class="f-11 fw-400" style="text-align: center;">Unit Cost</th>
                     <th width="15%" class="f-11 fw-400" style="text-align: center;">Total Cost</th>
                  </tr>
               </thead>


               <ng-container *ngFor="let costTypeDetail of costingSheet.costTypeDetailList; index as costTypeDetailIndex">

                  <ng-container *ngIf="costTypeDetail.costType === 'HEADER'">
                     <tr>
                        <td colspan="4" style="font-size:9px;background-color:#d3d3d352; vertical-align: middle;">
                           <label class="mb-0 ml-1 font-weight-bold pr-2">{{costTypeDetail.description}}</label>
                        </td>
                     </tr>
                  </ng-container>

                  <ng-container *ngIf="costTypeDetail.costType === 'INDIVIDUAL' || costTypeDetail.costType === 'PERCENTAGE' || costTypeDetail.costType === 'MANUAL_PERCENTAGE'">
                     <tr>
                        <td style="text-align: right; font-size:9px; vertical-align: middle;">
                           <span style="color:red" *ngIf="costTypeDetail.hasError" [matTooltip]="costTypeDetail.errorMessage" matTooltipPosition="left" matTooltipClass="bg-dark"><i
                                 class="fas fa-info-circle fa-2x"></i></span><label class="mb-0 ml-1 pr-2 label-small-slight-dark">{{costTypeDetail.description}}</label>
                           <span style="align-self: center;" class="f-11" *ngIf="costTypeDetail.calculationType==='PERCENTAGE'">(%)</span>
                        </td>
                        <td>
                           <div class="d-flex">
                              <input type="number" min="1" max="9999" name="remarks_{{costingSheetIndex}}_{{costTypeDetailIndex}}" class="form-control cell-min form-control-sm f-11"
                                 style="text-align: right; font-size:9px;" required
                                 [disabled]="!editable || (costingSheet.approvalStatus==='APPROVED') || (costTypeDetail.subCategory === 'BLANK_COST') || (costTypeDetail.subCategory === 'PRINTING_COST')"
                                 [(ngModel)]="costTypeDetail.remarks" (change)="onRemarksChange(costTypeDetail, costingSheet)">
                              <span style="align-self: center;" class="f-9" *ngIf="costTypeDetail.calculationType==='PERCENTAGE'">&nbsp;%</span>
                           </div>
                        </td>
                        <td>
                           <input type="number" min="1" max="9999" name="unitCost_{{costingSheetIndex}}_{{costTypeDetailIndex}}" class="form-control cell-min form-control-sm f-11"
                              style="text-align: right; font-size:9px;" required
                              [disabled]="!editable || (costingSheet.approvalStatus==='APPROVED')  || costTypeDetail.remarks !==0 || (costTypeDetail.subCategory === 'BLANK_COST') || (costTypeDetail.category==='SELLING_COST')"
                              [(ngModel)]="costTypeDetail.unitCost" (change)="onUnitCostChange(costingSheet)">
                        </td>
                        <td>
                           <input type="number" min="1" max="9999" name="totalCost_{{costingSheetIndex}}_{{costTypeDetailIndex}}" class="form-control cell-min form-control-sm f-11"
                              style="text-align: right; font-size:9px; background-color: ivory;" required readonly [disabled]="true" [(ngModel)]="costTypeDetail.totalCost">
                        </td>
                     </tr>
                  </ng-container>

                  <ng-container *ngIf="costTypeDetail.costType === 'TOTAL'">
                     <tr>
                        <td colspan="2" style="text-align: right; font-size:9px;background-color: ivory; vertical-align: middle;">
                           <label class="mb-0 ml-1 font-weight-bold pr-2">{{costTypeDetail.description}}</label>
                        </td>
                        <td>
                           <input type="number" min="1" max="9999" name="unitCost_{{costingSheetIndex}}_{{costTypeDetailIndex}}" class="form-control cell-min form-control-sm font-weight-bold"
                              style="text-align: right; font-size:9px;background-color: ivory;" readonly [disabled]="true" [(ngModel)]="costTypeDetail.unitCost">
                        </td>
                        <td>
                           <input type="number" min="1" max="9999" name="totalCost_{{costingSheetIndex}}_{{costTypeDetailIndex}}" class="form-control cell-min form-control-sm font-weight-bold"
                              style="text-align: right; font-size:9px; background-color: ivory;" readonly [disabled]="true" [(ngModel)]="costTypeDetail.totalCost">
                        </td>
                     </tr>
                  </ng-container>

                  <ng-container *ngIf="costTypeDetail.costType === 'GRAND_TOTAL'">
                     <tr>
                        <td colspan="2" style="text-align: right; font-size:11px;background-color: beige; vertical-align: middle;">
                           <label class="mb-0 ml-1 font-weight-bold pr-2">{{costTypeDetail.description}}</label>
                        </td>
                        <td>
                           <input type="number" min="1" max="9999" name="unitCost_{{costingSheetIndex}}_{{costTypeDetailIndex}}" class="form-control cell-min form-control-sm f-11 font-weight-bold"
                              style="text-align: right; font-size:11px;background-color: beige;" readonly [disabled]="true" [(ngModel)]="costTypeDetail.unitCost">
                        </td>
                        <td>
                           <input type="number" min="1" max="9999" name="totalCost_{{costingSheetIndex}}_{{costTypeDetailIndex}}" class="form-control cell-min form-control-sm f-11 font-weight-bold"
                              style="text-align: right; font-size:11px; background-color: beige;" readonly [disabled]="true" [(ngModel)]="costTypeDetail.totalCost">
                        </td>
                     </tr>
                  </ng-container>

                  <ng-container *ngIf="costTypeDetail?.costType ==='GROSS_PROFIT'  && costTypeDetail.calculationType ==='ABSOLUTE_UNIT'">
                     <tr>
                        <td colspan="2" style="text-align: right; font-size:11px;background-color: #67f7c769; vertical-align: middle;">
                           <label class="mb-0 ml-1 font-weight-bold pr-2">{{costTypeDetail.description}}</label>
                        </td>
                        <td>
                           <input type="number" min="1" max="9999" step=".01" name="unitCost_{{costingSheetIndex}}_{{costTypeDetailIndex}}"
                              class="form-control cell-min form-control-sm f-11 font-weight-bold" style="text-align: right; font-size:11px;background-color: #67f7c769;" readonly [disabled]="true"
                              [(ngModel)]="costTypeDetail.unitCost">
                        </td>
                        <td>
                           <input type="number" min="1" max="9999" step=".01" name="totalCost_{{costingSheetIndex}}_{{costTypeDetailIndex}}"
                              class="form-control cell-min form-control-sm f-11 font-weight-bold" style="text-align: right; font-size:11px; background-color: #67f7c769;" readonly [disabled]="true"
                              [(ngModel)]="costTypeDetail.totalCost">
                        </td>
                     </tr>
                  </ng-container>

                  <ng-container *ngIf="costTypeDetail?.costType ==='GROSS_PROFIT'  && costTypeDetail.calculationType ==='PERCENTAGE'">
                     <tr>
                        <td colspan="2" style="text-align: right; font-size:11px;background-color: #67f7c769; vertical-align: middle;">
                           <label class="mb-0 ml-1 font-weight-bold pr-2">{{costTypeDetail.description}}</label>
                        </td>
                        <td colspan="2">
                           <div class="d-flex">
                              <input type="number" min="1" max="9999" name="totalCost_{{costingSheetIndex}}_{{costTypeDetailIndex}}" class="form-control cell-min form-control-sm f-11 font-weight-bold"
                                 style="text-align: right; font-size:11px; background-color:#67f7c769;" readonly [disabled]="true" [(ngModel)]="costTypeDetail.totalCost">
                              <span style="align-self: center;" class="f-11" *ngIf="costTypeDetail.calculationType==='PERCENTAGE'">&nbsp;%</span>
                           </div>
                        </td>
                     </tr>
                  </ng-container>

               </ng-container>



            </table>

         </div>
      </ng-container>
   </div>
</form>

<div class="floatBtn d-flex flex-column">

   <!--<a (click)="goBack()" class="btn btn-circle" style="background-color: tomato;" *ngIf="!editable" matTooltip="Exit" matTooltipPosition="left" matTooltipClass="bg-dark"><i
         class="fas fa-arrow-left  fa-2x"></i></a>
   -->

   <a (click)="close()" class="btn btn-circle mb-2 btn-dark"  *ngIf="!editable" matTooltip="Close" matTooltipPosition="left" matTooltipClass="bg-dark">
      <i class="fas fa-times fa-2x" style="color:white"></i></a>         

</div>