<form #MetadataForm="ngForm" autocomplete="off" #formVar="ngForm" novalidate>

   <div class="container-fluid">

      <div class="row mt-2 d-flex justify-content-center">
         <h2 class="d-inline">Metadata</h2>
      </div>
      <hr>

      <div class="row  mt-4 mb-5 shadow-lg p-3  bg-white rounded">

         <div class="col-lg-12">

            <!-- START: Tab Group -->
            <mat-tab-group [selectedIndex]="metadataClassificationTabIndex"
               (selectedTabChange)="metadataClassificationTabClicked($event)" #metadataClassificationTabGroup>

               <!-- START: Dynamic Classification Tabs -->
               <mat-tab label="{{metadataClassificationAlias}}"
                  *ngFor="let metadataClassificationAlias of metadataClassificationAliases;index as index1">
                  <ng-template mat-tab-label>
                     <h6>{{metadataClassificationAlias}}</h6> <span class="badge badge-pill badge-dark ml-2"></span>
                  </ng-template>
               </mat-tab>
               <!-- End: Dynamic Classification Tabs -->

               <!-- START: Add New Metadata Template Tab -->
               <mat-tab label="AddNewMetadataTemplate" *ngIf="userAccess?.isSupportAdmin">
                  <ng-template mat-tab-label>
                     <h6>Manage Metadata Template</h6> <span class="badge badge-pill badge-dark ml-2"></span>
                  </ng-template>
               </mat-tab>
               <!-- END: Add New Metadata Template Tab -->

            </mat-tab-group>
            <!-- END: Tab Group -->


            <div class="row mt-3" *ngIf="metadataList && !isAddNewMetadataTabSelected">
               <!-- ------------- START: SIDE BAR ------------------- -->
               <div class="col-2 list-group mt-2 mb-2 ">
                  <div >
                     <div class="text-center" >
                        <h5>Metadata Type</h5>
                     </div>
                     <hr>
                  
                     <div class="mt-1" *ngFor="let metadataTemplate of filteredMetadataTemplateListByClassification">
                        <a (click)="onMetadataTypeClicked(metadataTemplate.metadataTypeAlias,metadataTemplate.id)"
                           class="list-group-item list-group-item-action {{selectedMetadataTemplateId === metadataTemplate.id?'bg-dark text-white':'bg-default'}}">
                           {{metadataTemplate.metadataTypeAlias}}
                           {{metadataTemplate.metadataSubTypeAlias !==null ? ' - ': ''}}
                           {{metadataTemplate.metadataSubTypeAlias}} </a>
                     </div>
                  </div>
               </div>
               <!-- ------------- END: SIDE BAR --------------------- -->

               <!-- ------------- START: METADATA VALUE LIST ------------------- -->
               <div class="col-6 mt-2">

                     <div class="row" *ngIf="metadataList && !isAddNewMetadataTabSelected">
                        <div class="col">
                           <div cdkDropList (cdkDropListDropped)="drop($event)"
                              [cdkDropListDisabled]="!editable">
                              <div>
                                 <div class="text-center">
                                    <h5>{{selectedMetadataTypeValue}} Values
                                       <button type="button" (click)="addMetadata()"
                                          class="btn btn-circle btn-circle-sm d-inline ml-2" *ngIf="editable && userAccess?.canCreateMetadata"
                                          matTooltip="Add Metadata Value" matTooltipPosition="right" matTooltipClass="bg-dark">
                                          <i class="fas fa-plus fa-1x text-light"></i>
                                       </button></h5>
                                 </div>
                                 <hr>
                                 <div class="card example-box mt-1" *ngFor="let metadata of metadataList; index as counter;" cdkDrag>
                                    <div class="row form-group mb-1 mt-1" style="width: 100%;">
                                       <div class="col-2">
                                          <input name="metadataSortOrder{{counter}}" type="number"
                                             [(ngModel)]="metadata.sortOrder" class="form-control form-control font-sm"
                                             required #sortOrder="ngModel" [disabled]="!editable" style="width:50px;">
                                       </div>
                                       <div class="col-10">
                                          <div class="d-flex d-inline">
                                             <input name="metadataValue{{counter}}" [(ngModel)]="metadata.metadataValue"
                                                class="form-control form-control font-sm" #metadataValue="ngModel"
                                                placeholder="Enter Metadata Value" required maxlength="1000"
                                                [disabled]="!editable">
                                             <span>
                                                <a (click)="deleteMetadata(metadata)" *ngIf="editable && userAccess?.canDeleteMetadata"
                                                   class="form-control form-control-sm ml-2" style="border: 0px; cursor: pointer; margin-top: .3em;">
                                                   <i class="fas fa-trash fa-1x"
                                                   style="color: #dc3545"></i></a>
                                             </span>
                                             <svg width="24px" fill="lightgray" viewBox="0 0 24 24" *ngIf="editable" class="ml-2">
                                                <path
                                                   d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                                </path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                             </svg>
                                          </div>
                                       </div>
                                    </div>
                                    <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem">
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

               </div>
               <!-- ------------- END: METADATA VALUE LIST --------------------- -->
            </div>

            <!-- ------------- START: ADD METADATA TEMPLATE ----------- -->
            <div class="row mt-2" *ngIf="isAddNewMetadataTabSelected || selectedMetadataClassification===null">
               <div class="col-12">
                  <fieldset class="border p-2 mt-3 mb-4">
                     <legend class="fieldset-border">&nbsp;Add Metadata Template&nbsp;</legend>
                     <div class="overflow-hidden">

                        <div class="form-group row mt-4">
                           <label class="col-sm-2">Metadata Classification</label>
                           <div class="col-sm-3">
                              <input name="metadataClassification"
                                 [(ngModel)]="newMetadataTemplate.metadataClassification" class="form-control"
                                 #metadataClassification="ngModel" required [disabled]="!editable"
                                 [ngClass]="{'is-invalid':metadataClassification.touched && metadataClassification.errors}"
                                 maxlength="200">
                              <div
                                 *ngIf="metadataClassification?.invalid && (metadataClassification.dirty || metadataClassification.touched)"
                                 class="invalid-feedback">
                                 <div *ngIf="metadataClassification.errors.required">
                                    Metadata Classification is required.
                                 </div>
                              </div>
                           </div>
                           <label class="col-sm-2">Metadata Classification Alias</label>
                           <div class="col-sm-5">
                              <input name="metadataClassificationAlias"
                                 [(ngModel)]="newMetadataTemplate.metadataClassificationAlias" class="form-control"
                                 #metadataClassificationAlias="ngModel" required [disabled]="!editable" maxlength="200"
                                 [ngClass]="{'is-invalid':metadataClassificationAlias.touched && metadataClassificationAlias.errors}">
                              <div
                                 *ngIf="metadataClassificationAlias?.invalid && (metadataClassificationAlias.dirty || metadataClassificationAlias.touched)"
                                 class="invalid-feedback">
                                 <div *ngIf="metadataClassificationAlias.errors.required">
                                    Metadata Classification Alias is required.
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="form-group row">
                           <label class="col-sm-2">Metadata Type</label>
                           <div class="col-sm-3">
                              <input name="metadataType" [(ngModel)]="newMetadataTemplate.metadataType"
                                 class="form-control" #metadataType="ngModel" required [disabled]="!editable"
                                 maxlength="200" [ngClass]="{'is-invalid':metadataType.touched && metadataType.errors}">
                              <div *ngIf="metadataType?.invalid && (metadataType.dirty || metadataType.touched)"
                                 class="invalid-feedback">
                                 <div *ngIf="metadataType.errors.required">
                                    Metadata Type is required.
                                 </div>
                              </div>
                           </div>
                           <label class="col-sm-2">Metadata Type Alias</label>
                           <div class="col-sm-5">
                              <input name="metadataTypeAlias" [(ngModel)]="newMetadataTemplate.metadataTypeAlias"
                                 class="form-control" #metadataTypeAlias="ngModel" required [disabled]="!editable"
                                 maxlength="200"
                                 [ngClass]="{'is-invalid':metadataTypeAlias.touched && metadataTypeAlias.errors}">
                              <div
                                 *ngIf="metadataTypeAlias?.invalid && (metadataTypeAlias.dirty || metadataTypeAlias.touched)"
                                 class="invalid-feedback">
                                 <div *ngIf="metadataTypeAlias.errors.required">
                                    Metadata Type Alias is required.
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="form-group row">
                           <label class="col-sm-2">Metadata SubType</label>
                           <div class="col-sm-3">
                              <input name="metadataSubType" [(ngModel)]="newMetadataTemplate.metadataSubType"
                                 class="form-control" #metadataSubType="ngModel" [disabled]="!editable"
                                 maxlength="200"
                                 [ngClass]="{'is-invalid':metadataSubType.touched && metadataSubType.errors}">
                              <div
                                 *ngIf="metadataSubType?.invalid && (metadataSubType.dirty || metadataSubType.touched)"
                                 class="invalid-feedback">
                                 <div *ngIf="metadataSubType.errors.required">
                                    Metadata SubType is required.
                                 </div>
                              </div>
                           </div>
                           <label class="col-sm-2">Metadata SubType Alias</label>
                           <div class="col-sm-5">
                              <input name="metadataSubTypeAlias" [(ngModel)]="newMetadataTemplate.metadataSubTypeAlias"
                                 class="form-control" #metadataSubTypeAlias="ngModel" [disabled]="!editable"
                                 maxlength="200"
                                 [ngClass]="{'is-invalid':metadataSubTypeAlias.touched && metadataSubTypeAlias.errors}">
                              <div
                                 *ngIf="metadataSubTypeAlias?.invalid && (metadataSubTypeAlias.dirty || metadataSubTypeAlias.touched)"
                                 class="invalid-feedback">
                                 <div *ngIf="metadataSubTypeAlias.errors.required">
                                    Metadata SubType Alias is required.
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="form-group row">
                           <label class="col-sm-2 form-check-label">Is Editable By User&nbsp;<i class="fa fa-user"></i></label>
                           <div class="col-sm-3">
                              <div class="form-check">
                                 <input class="form-check-input" type="checkbox" name="editableByUser" id="editableByUser" 
                                    [(ngModel)]="newMetadataTemplate.editableByUser" [disabled]="!editable" >
                              </div>
                           </div>
                        </div>

                        <div class="row">
                           <div class="mb-2 col-sm-5">
                              <button type="button" class="btn btn-info btn-block" *ngIf="editable"
                                 (click)="createNewMetadataTemplate()">Submit</button>
                           </div>
                        </div>
                     </div>
                  </fieldset>
               </div>
            </div>
            <!-- ------------- END: ADD METADATA TEMPLATE ------------- -->

            <!-- ------------- START: EXISTING METADATA TEMPLATE LIST and EDIT----------- -->
            <div class="row mt-2" *ngIf="isAddNewMetadataTabSelected || selectedMetadataClassification===null">
               <div class="col-12">
                  <fieldset class="border p-2">
                     <legend class="fieldset-border">&nbsp;Metadata Template List&nbsp;</legend>
                     <div *ngIf="isAddNewMetadataTabSelected || selectedMetadataClassification===null">
                        <div class="row">
                           <div class="col">
                              <div class="row form-group mb-0" style="width: 100%;">
                                 <div class="col-2">
                                    <div class="d-flex d-inline">Metadata Classification</div>
                                 </div>
                                 <div class="col-2">
                                    <div class="d-flex d-inline">Metadata Classification Alias</div>
                                 </div>
                                 <div class="col-2">
                                    <div class="d-flex d-inline">Metadata Type</div>
                                 </div>
                                 <div class="col-2">
                                    <div class="d-flex d-inline">Metadata Type Alias</div>
                                 </div>
                                 <div class="col-2">
                                    <div class="d-flex d-inline">Metadata SubType</div>
                                 </div>
                                 <div class="col-1">
                                    <div class="d-flex d-inline">Metadata SubType Alias</div>
                                 </div>
                                 <div class="col-1">
                                    <div class="d-flex d-inline">Is Editable By User</div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <hr style="margin-top: 0.1rem">

                        <div class="row">
                           <div class="col">
                              <div>
                                 <div *ngFor="let metadataTemplate of metadataTemplateList; index as counter;">
                                    <div class="row form-group mb-0" style="width: 100%;">
                                       <div class="col-2">
                                          <div class="d-flex d-inline">
                                             <input name="metadataClassification{{counter}}"
                                                [(ngModel)]="metadataTemplate.metadataClassification"
                                                class="form-control form-control-sm font-sm"
                                                #metadataClassification="ngModel" maxlength="50" [disabled]="true">
                                          </div>
                                       </div>
                                       <div class="col-2">
                                          <div class="d-flex d-inline">
                                             <input name="metadataClassificationAlias{{counter}}"
                                                [(ngModel)]="metadataTemplate.metadataClassificationAlias"
                                                class="form-control form-control-sm font-sm"
                                                #metadataClassificationAlias="ngModel" maxlength="50"
                                                [disabled]="!editable">
                                          </div>
                                       </div>
                                       <div class="col-2">
                                          <div class="d-flex d-inline">
                                             <input name="metadataType{{counter}}"
                                                [(ngModel)]="metadataTemplate.metadataType"
                                                class="form-control form-control-sm font-sm" #metadataType="ngModel"
                                                maxlength="50" [disabled]="true">
                                          </div>
                                       </div>
                                       <div class="col-2">
                                          <div class="d-flex d-inline">
                                             <input name="metadataTypeAlias{{counter}}"
                                                [(ngModel)]="metadataTemplate.metadataTypeAlias"
                                                class="form-control form-control-sm font-sm"
                                                #metadataTypeAlias="ngModel" required maxlength="50"
                                                [disabled]="!editable">
                                          </div>
                                       </div>
                                       <div class="col-2">
                                          <div class="d-flex d-inline">
                                             <input name="metadataSubType{{counter}}"
                                                [(ngModel)]="metadataTemplate.metadataSubType"
                                                class="form-control form-control-sm font-sm" #metadataSubType="ngModel"
                                                maxlength="50" [disabled]="true">
                                          </div>
                                       </div>
                                       <div class="col-1">
                                          <div class="d-flex d-inline">
                                             <input name="metadataSubTypeAlias{{counter}}"
                                                [(ngModel)]="metadataTemplate.metadataSubTypeAlias"
                                                class="form-control form-control-sm font-sm"
                                                #metadataSubTypeAlias="ngModel" maxlength="50" [disabled]="!editable">
                                          </div>
                                       </div>
                                       <div class="col-1">
                                          <div class="d-flex justify-content-center">
                                             <div class="form-check">
                                                <input class="form-check-input form-check-label" style="margin-top: .6rem;" type="checkbox" name="editableByUser{{counter}}"
                                                   [(ngModel)]="metadataTemplate.editableByUser" [disabled]="!editable" >
                                                   
                                                   <a *ngIf="editable" (click)="deleteMetadataTemplate(metadataTemplate)" 
                                                      class="form-control form-control-sm text-right" style="border: 0px; cursor: pointer;"><i class="fas fa-trash fa-1x"
                                                      style="color: #dc3545"></i></a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem">
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </fieldset>
               </div>
            </div>
            <!-- ------------- END: EXISTING METADATA TEMPLATE LIST and EDIT------------- -->

         </div>

      </div>

   </div>

   <!--  MODAL for adding metadata -->
   <div class="modal fade" id="addNewMetadataModal" data-keyboard="false" data-backdrop="static" tabindex="-1"
      role="dialog" data-focus-on="input:first" aria-labelledby="addMetadataModalTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable" role="document">
         <div class="modal-content">
            <div class="modal-header" style="background-color: #f7f7f7;">
               <h6 class="modal-title" id="exampleModalLongTitle" style="font-weight: bold;">Add
                  {{newMetadata.metadataType}} {{newMetadata.metadataSubType}}</h6>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                  (click)="closeMetadataModal()">
                  <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div class="modal-body">
               <input name="metadataValue" class="form-control mb-2" type="text" #metadataInput required
                  placeholder="Enter Metadata Value..." [(ngModel)]="newMetadata.metadataValue" maxlength="1000">
            </div>
            <div class="modal-footer" style="background-color: #f7f7f7;">
               <button type="button" id="metadataModalCloseButton" class="btn btn-secondary btn-sm" data-dismiss="modal"
                  (click)="closeMetadataModal()">Close</button>
               <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal"
                  [disabled]="!newMetadata.metadataValue" (click)="saveMetadata()">Save</button>
            </div>
         </div>
      </div>
   </div>
   <button id="metadataModalToggler" type="button" style="display: none;" data-toggle="modal"
      data-target="#addNewMetadataModal"></button>

   <!-- Floating Buttons -->
   <div class="floatBtn d-flex flex-column">
      <button type="button" class="btn btn-circle bg-info mb-2"
         *ngIf="editable && !(isAddNewMetadataTabSelected || selectedMetadataClassification===null)"
         matTooltip="Update Metadata List" matTooltipPosition="left" matTooltipClass="bg-dark"
         (click)="updateMetadataList()"><i class="fas fa-save fa-2x" style="color:white"></i>
      </button>
      <button type="button" class="btn btn-circle bg-info mb-2"
         *ngIf="editable && (isAddNewMetadataTabSelected || selectedMetadataClassification===null)"
         matTooltip="Update Metadata Template List" matTooltipPosition="left" matTooltipClass="bg-dark"
         (click)="updateMetadataTemplateList()"><i class="fas fa-save fa-2x" style="color:white"></i>
      </button>
      <button type="button" class="btn btn-circle bg-dark mb-2" *ngIf="editable" matTooltip="Cancel Edit"
         matTooltipPosition="left" matTooltipClass="bg-dark" (click)="cancelEdit()"><i class="fas fa-times fa-2x"
            style="color:white"></i>
      </button>
      <a class="btn btn-circle bg-dark mb-2" (click)="edit()" *ngIf="!editable && userAccess?.canEditMetadata"
         matTooltip="Edit" matTooltipPosition="left" matTooltipClass="bg-dark">
         <i class="fas fa-pencil-alt fa-lg" style="color: white;"></i>
      </a>
      <a (click)="goBack()" class="btn btn-circle" style="background-color: tomato;" matTooltip="Exit"
         matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-arrow-left  fa-2x"></i></a>
   </div>


</form>