import { Product } from './product.model';

export class SearchCriteria {
    sku:string;
    skuCode:string;
    productId: string;
    licensorId: string;
    brandId: string;
    licensors: string[];
    brands: string[];
    colors: string[];
    garments: string[];
    treatments:string[];
    tags: string[];
    createdBylist: string[];
    searchString:string;
    productIdListString:string;
    approvalStatus:string[];
    artStatus:string[];
    categories:string[];
    brandCategories:string[];
    printEffects:string[];
    similarDesign:boolean;
    excludeInactiveBrandAndLicensor:boolean;
    excludeConfiguredSKU:boolean;
    excludeMock:boolean;
    excludeSku:boolean;
    excludeAdhocProduct:boolean;
    searchInSalesOrder:boolean;
    searchType:string;
    productIdArray:String[];
    loadMore:boolean;
	loadedCount:number;
	totalCount:number;
	loadMoreCount:number;
	previousSearchQuery:string;
	productSearchResult:Product[];
    selectedSortByName: string;
    sortMode: string;
    constructor(){
        
    }

}

