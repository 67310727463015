<div>
   <div class="row mt-2 d-flex justify-content-center">
      <h2 class="d-inline">
         PrePro Tracker
      </h2>
   </div>
   <div>
      <div class="row">
         <div class="col-sm-8" *ngIf="currentLoggedInUser && currentLoggedInUser!==null">
            <app-search #searchComponent [placeholder]="'Order#, Customer Name, Printer Name, etc. (space delimited)'" [searchConfig]="searchConfig" [existingList]="salesPreProTrackerViewList"
               [sortColumns]="sortColumns"></app-search>
         </div>

      </div>
      <div>
         <ng-container>
            <table class=" f-10 table table-sm table-bordered  table-condensed" style="border: 0px solid #dee2e6;">
               <thead class="thead-dark" style="position: sticky;top: 60px;">
                  <tr class="text-center">
                     <th style="width: 1% !important;border-left: 4px solid #343a40;">&nbsp;</th>
                     <th style="width: 15% !important;">Sales Order#</th>
                     <th>Customer</th>
                     <th>Coordiantor</th>
                     <th>Printer</th>
                     <th>Ship Date</th>
                     <th>Production Status</th>
                     <th style="width: 8%!important;">Action</th>
                  </tr>
               </thead>

               <tbody>
                  <ng-container *ngFor="let pptv of salesPreProTrackerViewList; index as pptvIndex;">
                     <tr class="text-center border">
                        <td style="border-left: 4px solid #859ba9;" [style.background-color]="pptv.expanded?'#eb7d6a42':''" class="align-middle  text-nowrap p-2"
                           (click)="showHidePreProTrackerViewDetails(pptv)">
                           <span *ngIf="!pptv.expanded"><i class="fas fa-chevron-down"></i></span>
                           <span *ngIf="pptv.expanded"><i class="fas fa-chevron-up"></i></span>
                        </td>
                        <td [style.background-color]="pptv.expanded?'#eb7d6a42':''" class="f-12">
                           <span *ngIf="!userAccess?.canViewSalesOrder">{{pptv.salesOrderNumber}}</span>
                           <a [routerLink]="['/salesOrder/view/'+pptv.salesOrderId]" target="_blank" *ngIf="userAccess?.canViewSalesOrder">
                              {{pptv.salesOrderNumber}}
                           </a>
                        </td>
                        <td (click)="showHidePreProTrackerViewDetails(pptv)" [style.background-color]="pptv.expanded?'#eb7d6a42':''">{{pptv.customerName}}</td>
                        <td (click)="showHidePreProTrackerViewDetails(pptv)" [style.background-color]="pptv.expanded?'#eb7d6a42':''">{{pptv.coordinatorName}}</td>
                        <td (click)="showHidePreProTrackerViewDetails(pptv)" [style.background-color]="pptv.expanded?'#eb7d6a42':''">{{pptv.printerName}}</td>
                        <td (click)="showHidePreProTrackerViewDetails(pptv)" [style.background-color]="pptv.expanded?'#eb7d6a42':''">{{pptv.orderShipDate | date:'mediumDate'}}</td>
                        <td (click)="showHidePreProTrackerViewDetails(pptv)" [style.background-color]="pptv.expanded?'#eb7d6a42':''">{{'sales.productionStatus.'+pptv.productionStatus| transform}}</td>
                        <td>
                           <div>
                              <button *ngIf="!pptv.editable && userAccess?.canEditPreProTracker" (click)="editPreProTrackerView(pptv)" type="button" class="btn btn-sm "><i
                                    class="fas fa-pencil-alt fa-sm"></i></button>
                              <button *ngIf="pptv.editable" (click)="savePreProTrackerView(pptv)" type="button" class="btn btn-sm btn-success"><i class="fas fa-save "></i></button>
                              <button *ngIf="pptv.editable" type="button" class="btn btn-sm ml-2 btn-dark" (click)="pptv.editable=false;relaodPreProTrackerView(pptv);"><i
                                    class="fas fa-window-close"></i></button>
                           </div>
                        </td>

                     </tr>
                     <tr *ngIf="pptv.expanded">

                        <ng-container>

                           <td colspan="8" class="text-center">
                              <table class=" f-10 table table-sm  mb-1 mx-auto" style="width:90%;border: 0px solid #dee2e6;">
                                 <thead class="thead-light">
                                    <th style="width: 1%!important;">Image</th>
                                    <th style="width: 10%!important;">Sku</th>
                                    <th style="width: 15%!important;">Approval Status</th>
                                    <th style="width: 73%!important;">Notes</th>
                                    <th style="width: 1%!important;">Email</th>
                                 </thead>
                                 <tbody>
                                    <ng-container>
                                       <ng-container *ngFor="let pptvd of pptv.salesPreProTrackers; index as pptvdIndex;">
                                          <tr>
                                             <td style="vertical-align: middle;" class="align-center">
                                                <a type="button" style="color: black;" matTooltip="View product" matTooltipPosition="below" matTooltipClass="bg-dark" target="_blank"
                                                   [routerLink]="['/product/view/'+pptvd.product.licensorId+'/'+pptvd.product.brandId+'/'+pptvd.product.id]">
                                                   <img [src]="pptvd.product.productImages[0]?.thumbnailSignedUrl" class="img-thumbnail img-fluid zoom-4 "
                                                      style="display: block;  width:60px;  height:80px; min-width: 80px;" [style.border-color]="'cadetblue'"
                                                      (error)="handleThumbnailError(pptvd.product.productImages[0],$event)" />
                                                </a>
                                             </td>
                                             <td>{{pptvd.product.sku}}</td>
                                             <td>{{pptvd.product.approvalStatus}}</td>
                                             <td style="text-align: left !important;">
                                                <div>
                                                   <textarea name="salesPreProNotes{{pptvIndex}}{{pptvdIndex}}" id="salesPreProNotes{{pptvIndex}}{{pptvdIndex}}" [(ngModel)]="pptvd.notes" rows="4"
                                                      class="form-control form-control-sm f-14 text-danger" [disabled]="!pptv.editable" maxlength="999">{{pptvd.notes}} </textarea>
                                                </div>
                                             </td>
                                             <th rowspan="999" style="width: 1%!important;" *ngIf="pptvdIndex===0">
                                                <button id="sendEmail" type="button" class="btn btn-sm mb-2 bg-navy-blue" (click)="sendSalesPreProEmail(pptv)" matTooltip="Send Style Email" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fa fa-envelope text-light fa-lg"></i></button>
                                             </th>

                                          </tr>
                                       </ng-container>
                                    </ng-container>
                                 </tbody>
                              </table>
                           </td>
                        </ng-container>
                     </tr>
                     <tr>
                        <td colspan="999"></td>
                     </tr>

                  </ng-container>

               </tbody>
            </table>
         </ng-container>

      </div>
   </div>
   <button id="composeEmailToggler" type="button" style="display: none;" data-toggle="modal" data-target="#composeEmail"></button>
   <div *ngIf="showEmailEditor">
      <app-compose-email [modalId]="'composeEmail'" [emailHeader]="'Email'" [composeEmail]="composeEmail"  [showHtmlEditor]="true" [showRichTextEditor]="false"></app-compose-email>
   </div>

</div>