import { ProductAttachment } from '../product.model';

export class ChargeBack {
   id: string;
   chargeBackNo: string;
   customerPoNumber: string;
   customerChargeBackNo: string;
   customerDocumentDate: Date;
   customerNo: string;
   customerName: string;
   customerTerritory: string;
   customerStatus: string;
   currency: string;
   customerChargeBackAmount: number;
   status: string;

   sageEntryDate: Date;
   category: string;
   reason: string;

   salesOrderId: string;
   salesOrderNo: string;

   vendorPoNo: string;
   vendorName: string;
   vendorStatus: string;
   amountRecovered: number;
   vendorId: string;
   vendorType: string;
   vendorPtInfoId: string;
   vendorDisplayName: string;

   chargeBackAttachments: ProductAttachment[] = [];
   cbDocumentsCount: number;
   cbNotesCount: number;

   version: number;

   // Front End 
   editable: boolean = false;
}


export class ChargeBackVendor {
   type: string;
   id: string;
   parentId: string;
   name: string;
   displayName: string;
   poNumber: string;
}