import { Injectable} from '@angular/core';
import { SearchCriteria } from '../model/search-criteria.model';
import { ProductSearchDetails } from '../model/product-search-details.model';


@Injectable({
   providedIn: 'root'
})

export class ProductSearchService {
  productSearchDetails:ProductSearchDetails=new ProductSearchDetails('PRODUCT_LIST',false,'updated_on','DESC');
  brandProductSearchDetails:ProductSearchDetails=new ProductSearchDetails('PRODUCT_LIST',false,'updated_on','DESC');
  skuSearchDetails:ProductSearchDetails=new ProductSearchDetails('SKU_TRACKER',true,'updated_on','DESC');

   
   constructor() {
   }

   ngOnInit() {
   }

   
   
}
