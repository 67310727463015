<div>
   <div class="row mt-2 d-flex justify-content-center">
      <h2 class="d-inline">
         Royalty Mismatch Summary
      </h2>
   </div>
   <div>
      <div class="row">
         <div class="col-sm-8" *ngIf="currentLoggedInUser && currentLoggedInUser!==null">
            <app-search #searchComponent [placeholder]="'Order#, Customer Name etc. (space delimited)'" [searchConfig]="searchConfig" [existingList]="royaltyMismatchViewList"
               [sortColumns]="sortColumns"></app-search>
         </div>
      </div>
   
      <div>
         <ng-container>
            <table class=" f-10 table table-sm table-bordered customtablehover table-condensed">
               <thead class="thead-dark" style="position: sticky;top: 0">
                  <tr class="text-center">
                     <th>Approval Date</th>
                     <th>Revision Status</th>
                     <th>Order#</th>
                     <th>Customer</th>
                     <th>Licensor</th>
                     <th>Brand</th>
                     <th>SKU</th>
                     <th>Quantity</th>
                     <th>Selling Price / Unit</th>
                     <th>Total Sales</th>
                     <th>Royalty %</th>
                     <th>Currency</th>
                     <th>Production Status</th>        
                  </tr>
               </thead>
               <tbody>
                  <ng-container *ngFor="let rmv of royaltyMismatchViewList; index as rmvIndex;">
                     <tr class="text-center border" [style.background-color]="rmvIndex%2===1?'rgba(0,0,0,.05)':''">
                        <td>{{rmv.approvalDate | date:'mediumDate'}}</td>
                        <td *ngIf="rmv.costingApprovalCount-1>0"> R{{rmv.costingApprovalCount-1}}</td>
                        <td *ngIf="rmv.costingApprovalCount-1&lt;=0"> &nbsp;</td>
                        <td>
                           <span *ngIf="!userAccess?.canViewSalesOrder">{{rmv.salesOrderNumber}}</span>
                           <a [routerLink]="['/salesOrder/view/'+rmv.salesOrderId]" target="_blank" *ngIf="userAccess?.canViewSalesOrder">
                              {{rmv.salesOrderNumber}}
                           </a>
                        </td>
                        <td>{{rmv.customerName}}</td>
                        <td>{{rmv.licensorName}}</td>
                        <td>{{rmv.brandName}}</td>
                        <td>{{rmv.sku}}</td>
                        <td  class="align-middle text-right text-nowrap pr-3">{{rmv.orderQty}}</td>
                        <td  class="align-middle text-right text-nowrap pr-3">{{rmv.sellingPricePerUnit | currency:rmv?.currency}}</td>
                        <td  class="align-middle text-right text-nowrap pr-3">{{rmv.totalSales | currency:rmv?.currency}}</td>
                        <td  class="align-middle text-right text-nowrap pr-3">{{rmv.approvedRoyalty}}%</td>
                        <td>{{rmv.currency}}</td>
                        <td>{{rmv.productionStatus}}</td>

                     </tr>
                  </ng-container>
               </tbody>
            </table>
         </ng-container>
      </div>

   </div>


   <div class="floatBtn d-flex flex-column">
      <button type="button" class="btn btn-circle bg-success mb-2" *ngIf="userAccess?.canDownloadRoyaltyMismatch" (click)="createRoyaltyMismatchExcel()"><i class="fas fa-file-excel fa-2x" style="color:white"></i></button>
      <button type="button" class="btn btn-circle bg-secondary mb-2" matTooltip="Scroll To Top" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'});" matTooltipPosition="left" matTooltipClass="bg-dark">
         <i class="fas fa-arrow-up fa-2x" style="color:white"></i>
      </button>
   </div>
</div>