<form #myParentForm="ngForm" >
   
   <div class="modal fade" id="{{modalId}}" tabindex="-1" data-backdrop="static" data-focus-on="input:first" role="dialog" aria-labelledby="title"
      aria-hidden="true">
      
      <div class="modal-dialog modal-dialog-scrollable " style="max-width: 50%;" role="document">
         <div class="modal-content">
            <div class="modal-header p-2" style="background-color:#f7f7f7">
               <h5 class="modal-title" id="longTitle" style="font-weight: bold;"><i class="fa fa-address-card"></i>&nbsp;&nbsp;{{header}} - {{addEditContactDetail.profile?.contactDetailsType}}</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                  (click)="closeAddEditContactDetail(addEditContactDetail);">
                  <span aria-hidden="true">&times;</span>
               </button>
            </div>

            <div class="modal-body">
               <div *ngIf="show">
                  <app-contact-details [myParentForm]="myParentForm" [addEditContactDetail]="addEditContactDetail" 
                     [editable]="editable" [licensorId]="licensorId"
                     [showProfile]="showProfile" [showPhone]="showPhone" [showEmail]="showEmail" [showAddress]="showAddress"
                     [multipleAddress]="multipleAddress" [multiplePhones]="multiplePhones" [multipleEmails]="multipleEmails"
                     [tabbed]='false' [showAddressType]="showAddressType">
                  </app-contact-details>
               </div>
            </div>
            <div class="modal-footer p-2" style="background-color: #f7f7f7;">
               <button type="button" class="btn btn-secondary" data-dismiss="modal" *ngIf="!editable">OK</button>
               <button type="button" class="btn btn-secondary" data-dismiss="modal"
               *ngIf="editable" (click)="closeAddEditContactDetail(addEditContactDetail);">Cancel</button>
               <button type="button" id="addEditModallOKButton" class="btn btn-primary" data-dismiss="modal"
                  *ngIf="editable"  (click)="saveAddEditContactDetail();" [disabled]="!myParentForm.valid || addEditContactDetail?.profile?.contactDetailsTypeList?.length===0">OK</button>
            </div>
         </div>
      </div>
   </div>

</form>