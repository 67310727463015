import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { PdfFormService } from 'src/app/shared/service/pdf-form.service';
import { PdfForm, PdfFormVariables } from 'src/app/shared/model/pdf-form.model';
import { PdfFormListComponent } from '../pdf-form-list/pdf-form-list.component';
import { LicensorService } from 'src/app/shared/service/licensor.service';
import { Licensor } from 'src/app/shared/model/licensor.model';
import { Brand } from 'src/app/shared/model/brand.model';
import { Location } from '@angular/common';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { ServiceStatus } from 'src/app/shared/model/service-status.model';
import { Product } from 'src/app/shared/model/product.model';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
  selector: 'app-pdf-form',
  templateUrl: './pdf-form.component.html',
  styleUrls: ['./pdf-form.component.css']
})
export class PdfFormComponent implements OnInit {
   createAction: boolean;
   viewAction: boolean;
   editable: boolean;
   userAccessSubscription: Subscription;
   userAccess: UserAccess;
  pdfFormId: string;
  pdfForm:PdfForm;
  licensorList:Licensor[];
  brandList:Brand[];
  categoryList:string[];
  subCategoryList:string[];
  configAsString:string=''
  varHeaders=[{name:'Fill | Display | Variable',colSize:2},{name:'Alias',colSize:1},{name:'Type',colSize:1},{name:'Sub-Type',colSize:1},{name:'Source',colSize:2},{name:'Delimter',colSize:1},{name:'Img Index / Margin',colSize:1},{name:'Sort Order|Object Index',colSize:1}];
  constructor(private metadataService: MetadataService,private authService:AuthService,private licensorService:LicensorService, private service: PdfFormService,private sharedService: SharedService,private activatedRoute: ActivatedRoute, private location: Location, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.handleSubscriptions();
      this.evaluateAction();
      this.setMetadata();
      if (this.createAction) {
        this.pdfForm=new PdfForm;
        this.loadLicensors();
        this.loadMetaData();
     } else if (this.viewAction) {
        this.loadPdfForm();
     }
     window.scrollTo(0, 0);
  }

  edit() {
    this.editable = true;
    this.createAction = false;
    this.viewAction = false;
    this.loadMetaData();
    this.loadLicensors();
    this.loadLicensorWithOnlyBrands(this.pdfForm.licensorId);     
 }

 loadMetaData() {
  let metaDataList=['CATEGORY','SUB_CATEGORY'];
  metaDataList.forEach(element => {
    this.loadMetadataForClassificationAndType('PDF_FORM',element);
  });
 }
 loadMetadataForClassificationAndType(classifictaion:string,type:string){
  this.metadataService.getMetadataValueListByClassificationAndType(classifictaion,type).subscribe((res: ApiResponse) => {
    if(res.responseStatus.status==='SUCCESS'){
       let sortedList=this.sharedService.sort(res.responsePayload);
       switch (type) {
         case 'CATEGORY': this.categoryList=sortedList; break;
         case 'SUB_CATEGORY': this.subCategoryList=sortedList; break;
         default: break;
       }
       }
   });       
 }


 cancelEdit() {
  this.editable = false;
  this.createAction = false;
  this.viewAction = true;
  this.loadPdfForm();
}

 createOrUpdatePdfForm(){
  if(this.editable&&!this.createAction){
    this.updatePdfForm();
  }else{
    this.createPdfForm();
  }
  this.editable=false;
  this.viewAction=true;
  this.createAction=false;
}
  createPdfForm() {
    this.service.createPdfForm(this.pdfForm).subscribe(res=>{
      let serviceStatus:ServiceStatus=res as ServiceStatus;
      if(serviceStatus.status==='SUCCESS'){
        this.pdfFormId=serviceStatus.id;
        this.uploadForm();
      }
    })
  }
  uploadForm() {
    this.service.uploadFiledWithProgress(this.pdfFormId,this.pdfForm.attachments[0].localfile,'upload').subscribe(res=>{
      let serviceStatus:ServiceStatus=res as ServiceStatus;
      if(serviceStatus.status==='SUCCESS'){
       this.loadPdfForm();
       this.toastrService.success('Uploaded PDF Form successfully');
      }else{
        this.toastrService.error('Failed to upload PDF');
      }
    },err=>{
      this.toastrService.error('Failed to upload PDF - '+err);
    });
  }
  updatePdfForm() {
    this.service.updatePdfForm(this.pdfForm).subscribe(res=>{
      let serviceStatus:ServiceStatus=res as ServiceStatus;
      if(serviceStatus.status==='SUCCESS'){
        this.toastrService.success('Pdf Form updated successfully');
        this.pdfFormId=serviceStatus.id;
        if( this.pdfForm.attachments[0].localfile!==undefined && this.pdfForm.attachments[0].localfile!==null){
          this.uploadForm();// New file has been uploaded
        }else{
          this.loadPdfForm();
        }
      }
    });
  }

  parsePdfForm(){
    if(this.pdfForm.attachments.length>1){
    this.pdfForm.attachments.shift();
    }
    let existingVars:PdfFormVariables[]=[];
    if(this.editable){
      existingVars=this.pdfForm.variables;
    }
    this.service.uploadFiledWithProgress(this.pdfFormId,this.pdfForm.attachments[0].localfile,'parse').subscribe(res=>{
      let result:PdfFormVariables[]=res as PdfFormVariables[];
      if(result && result!=null && result.length>0){
       this.pdfForm.variables=result;
       if(this.editable && !this.createAction){
         this.populateExistingVariables(existingVars);
       }
      }
    });
  }
  populateExistingVariables(existingVars: PdfFormVariables[]) {
    for (const x of this.pdfForm.variables) {
      for (const y of existingVars) {
        if(x.varName===y.varName){
          x.fillable=y.fillable;
          x.multiValueDelimeter=y.multiValueDelimeter;
          x.varNameAlias=y.varNameAlias;
          x.varValueImageArrIndex=y.varValueImageArrIndex;
          x.varValueSource=y.varValueSource;
          x.sortOrder=y.sortOrder;
          x.display=y.display;
          x.varType=y.varType;
          x.varSubType=y.varSubType;
          x.extendedMargin=y.extendedMargin;
          x.objectIndex=y.objectIndex;
          break;
        }
      }
    }
   
  }

  loadPdfForm() {
    this.service.loadPdfForm(this.pdfFormId).subscribe(res=>{
      this.pdfForm= res as PdfForm;
      if(this.pdfForm && this.pdfForm!==null && this.pdfForm.id!==null){
        this.loadLicensors();
        this.loadLicensorWithOnlyBrands(this.pdfForm.licensorId);
        if(this.pdfForm.attachments===null|| this.pdfForm.attachments===undefined){
            this.pdfForm.attachments=[];
        }
      }else{
        this.toastrService.error('Error in loading PDF form');
        this.goBack();
      }
    },err=>{
      this.toastrService.error('Error in loading PDF form - '+err);
      this.goBack();
    })
  }

  evaluateAction() {
    const action = this.activatedRoute.snapshot.paramMap.get('action');
    if (action === 'create') {
       this.createAction = true;
       this.editable = true;
    } else if (action === 'view') {
       this.viewAction = true;
    }
 }

 handleSubscriptions() {
  this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
     this.userAccess = access;
  });
}

setMetadata() {
  /*change also similarDesignSelected()*/
   this.pdfFormId = this.activatedRoute.snapshot.paramMap.get('id');
  
}

loadLicensors() {
  this.licensorService.loadLicensors().subscribe((res: Licensor[]) => {
     this.licensorList = this.sharedService.sortListByPropertyName(res, 'licensorName');
  });
}

  onLicensorChange(event?) {
    if (this.pdfForm.licensorId === 'ANY') {
      this.pdfForm.licensorName = this.pdfForm.licensorId;
      this.pdfForm.sku = this.pdfForm.licensorId;
      this.pdfForm.productId = this.pdfForm.licensorId;
      this.pdfForm.brandId = this.pdfForm.licensorId;
      this.pdfForm.brandName = this.pdfForm.licensorId;
      this.brandList = [];
      
    } else {
      let temp: Licensor[] = this.licensorList.filter(listElem => listElem.id === this.pdfForm.licensorId);
      this.pdfForm.licensorName = temp[0].licensorName;
      this.brandList = [];
      this.pdfForm.brandId = undefined;
      this.pdfForm.brandName = undefined;
      this.loadLicensorWithOnlyBrands(this.pdfForm.licensorId);
    }

  }

  loadLicensorWithOnlyBrands(licensorId: string) {
    if (licensorId !== 'ANY') {
      this.licensorService.loadLicensorWithOnlyBrands(licensorId,false).subscribe((res) => {
        let selectedlicensor = res as Licensor;
        if (selectedlicensor && selectedlicensor !== null) {
          this.brandList = this.sharedService.sortListByPropertyName(selectedlicensor.brands, 'brandName');
        }
        // this.licensorList=res[];
      });
    } else {
      this.brandList = [];
    }
  }
onBrandChange(event) {
  if(this.pdfForm.brandId==='ANY'){
    this.pdfForm.brandName = this.pdfForm.brandId;
    this.pdfForm.sku =  this.pdfForm.brandId;
    this.pdfForm.productId =  this.pdfForm.brandId;
  }else{
  let temp: Brand[] = this.brandList.filter(listElem => listElem.id === this.pdfForm.brandId);
  this.pdfForm.brandName = temp[0].brandName;
  this.pdfForm.sku = temp[0].brandName;
  this.pdfForm.productId = 'ANY';
  }

}

goBack() {
  this.location.back();
}
pdfFormAvailable(){
  if(this.pdfForm && this.pdfForm.attachments){

  }
  return false;
}
attachmentDeleted(){
  if(this.pdfForm && this.pdfForm.attachments){
    this.pdfForm.attachments=this.pdfForm.attachments.filter(x=> x.toDelete);
  }
  this.pdfForm.variables=[];
}

exportConfig(){
  this.configAsString=JSON.stringify(this.pdfForm.variables,null,2);
}
importConfig(){
  try {
    let existingVars:PdfFormVariables[]=JSON.parse(this.configAsString);
    //this.populateExistingVariables(existingVars);
    this.populateExistingVariables(existingVars)

  } catch (error) {
    console.error(error);
    this.toastrService.error('Invalid JSON config. Can not import the variable configuration');
  }
  
}






}
