import { CatalogList } from './product.model';

export class ProductCatalogRequest {
	catalogName:string='Catalog';
	frontPageRequired:boolean;
	printSkuCode:boolean;
	printApprovalStatus:boolean;
	printTreatment:boolean;
  	printColor:boolean;
	compressed:boolean;
	//catalogProducts:string[]=[];
	catalogProductsGrouped:CatalogList[]=[];
	itemPerRowCount:number=2;
	//itemPerPageCount:number=4;
	backgroundUrl:string='';
	rowPerPageCount:number;
	pageSize:string='';
	orientation:string='';
	border:string='';
	borderColor:string='';
	imageBackGround:string='';
	pdfName:string='';
	sortType:string='';
	groupType:string='';
	catalogFrontPageHeading:TextFormat;
	catalogFrontPageSubHeading:TextFormat;
	catalogFrontPageDesctiption:TextFormat;
	catalogFrontPageMainImageUrl:string|ArrayBuffer='';
}

export class TextFormat{
	text:string='';
	type:String='';
	color:string='BLACK';
	fontFamily:number=1;
	size:number;
	style:number=0;
	constructor(tempSize:number,tempType:string){
		this.size=tempSize;
		this.type=tempType;
	}
}
