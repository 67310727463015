import { validateHorizontalPosition } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { ProductionTracker } from 'src/app/shared/model/sales/fulfillment/production-tracker.model';
import { SalesOrder } from 'src/app/shared/model/sales/sales-order.model';

import { UserAccess } from 'src/app/shared/model/user-access.model';
import { BlanksSupplier } from 'src/app/shared/model/vendors/blanks-supplier.model';
import { Printer } from 'src/app/shared/model/vendors/printer.model';
import { ProductionTrackerService } from 'src/app/shared/service/sales/fulfillment/production-tracker.service';
import { SalesService } from 'src/app/shared/service/sales/sales.service';
import { SharedService } from 'src/app/shared/service/shared.service';
import { VendorsService } from 'src/app/shared/service/vendors/vendors.service';

@Component({
  selector: 'app-production-tracker',
  templateUrl: './production-tracker.component.html',
  styleUrls: ['./production-tracker.component.css']
})
export class ProductionTrackerComponent implements OnInit {
  private eventsSubscription: Subscription;
  @Input() events: Observable<string>;
  @Input() eventBasedLazyLoad:boolean=false;;
  @Input() salesOrder:SalesOrder;
  @Input() salesOrderId:string;
  @Input() userAccess:UserAccess;
  @Output() onSave = new EventEmitter<boolean>();
  productionTracker:ProductionTracker;
  blankInfoEditable:boolean;
  printerInfoEditable:boolean;
  routingInfoEditable:boolean;
  
  //blankSupplierList:BlanksSupplier[]=[];
  constructor(private vendorService:VendorsService, private toastrService:ToastrService,private sharedService:SharedService,private productionTrackerService:ProductionTrackerService,
    private salesService:SalesService, private dialog:MatDialog) { 
   
}

  
  ngOnInit() {
    if (!this.eventBasedLazyLoad) {
      this.initPostTrigger();
    } else {
      this.eventsSubscription = this.events.subscribe(event => {
        if (event === 'RELOAD') {
          // this.getVendorList();
          this.initPostTrigger();
        }
      })
    }
 }

  private initPostTrigger() {
    if (!this.salesOrder && this.salesOrderId) {
      this.salesService.getSalesOrder(this.salesOrderId).subscribe(response => {
        if (response.responseStatus.status === 'SUCCESS') {
          this.salesOrder = response.responsePayload as SalesOrder;
          this.getProductionTrackerInfo();
        }

      });
    } else {
      this.getProductionTrackerInfo();
    }
  }

 ngOnDestroy() {
  if (this.eventBasedLazyLoad) {
    this.eventsSubscription.unsubscribe();
  }
 }
  /*getVendorList() {
    this.vendorService.getBlanksSupplierList().subscribe(res=>{
      if(res && res.responseStatus.status==='SUCCESS' && res.responsePayload !=null){
        this.blankSupplierList=res.responsePayload;
        this.blankSupplierList = this.sharedService.sortListByPropertyName(this.blankSupplierList,'name');
      }
    });
  }*/

  edit(type:string){
    switch (type) {
      case 'BLANK_INFO':
        this.blankInfoEditable=true;
        break;
      case 'PRINTER_INFO':
        this.printerInfoEditable=true;
        break;
      case 'ROUTING_INFO':
        this.routingInfoEditable=true;
        break;
      default:
        break;
    }
   
    this.getProductionTrackerInfo();
  }
  cancel(type:string){
    switch (type) {
      case 'BLANK_INFO':
        this.blankInfoEditable=false;
        break;
      case 'PRINTER_INFO':
        this.printerInfoEditable=false;
        break;
      case 'ROUTING_INFO':
        this.routingInfoEditable=false;
        break;
      default:
        break;
    }
    this.getProductionTrackerInfo();
  }
  save(type:string){
    if(true/*this.valid()*/){
    this.productionTrackerService.saveProductionTracker(this.productionTracker).subscribe(serviceStatus=>{
      if(serviceStatus && serviceStatus!=null && serviceStatus.status==='SUCCESS'){
        this.getProductionTrackerInfo();
        this.onSave.emit(true);
        switch (type) {
          case 'BLANK_INFO':
            this.blankInfoEditable=false;
            break;
          case 'PRINTER_INFO':
            this.printerInfoEditable=false;
            break;
          case 'ROUTING_INFO':
            this.routingInfoEditable=false;
            break;
          default:
            break;
        }
      }else{
        if(serviceStatus.errorCode==='STALE_OBJECT_ERROR'){
          this.toastrService.error('Production tracker has been modified by other user. Please refresh and make your changes again before save');  
        }else{
          this.toastrService.error(serviceStatus.message);
        }
      }
    });
  }
  }
  
  getProductionTrackerInfo() {
    //this.productionTracker=new ProductionTracker(this.salesOrder.id);
    this.productionTrackerService.loadProductionTracker(this.salesOrder.id).subscribe(res=>{
      if(res && res!=null){
        this.productionTracker=res;
      }else{
        //this.toastrService.error('Error while loading the Production Tracker Info');
      }
    });
  }

  

  display(obj:any){
    //console.error('Audit',obj);
  }

  /**
 * Returns the week number for this date.  dowOffset is the day of week the week
 * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
 * the week returned is the ISO 8601 week number.
 * @param int dowOffset
 * @return int
 */

/*
  openPinterNotesModal(){
    const confirmDialog = this.dialog.open(NotesDialogComponent, {
      disableClose: true,
      data: {
         title: 'Cancel Create Sales Order',
         message: 'Sales Order is not saved. Are you sure you want to cancel ?',
         confirmBtnText: 'Yes',
         cancelBtnText: 'Cancel'
      }
   });
   confirmDialog.afterClosed().subscribe(res => {
      if (res) {
         this.router.navigate(['salesOrder/list']);
      } else {

      }
   });

  }*/

  isInvalid(formControl:ControlContainer):boolean{
 let retVal=true;
 //console.error('FORMCONTROL ->',formControl);
 return retVal;
  }

  /*addNotes(){
    const confirmDialog = this.dialog.open(NotesDialogComponent, {
      disableClose: true,
      data: {
        parentId:this.productionTracker.id,
         notesHeaderText: 'Sales Order# : '+this.productionTracker.salesOrderNumber,
         confirmBtnText:'Save',
         cancelBtnText:'Cancel',
         editable:this.editable
      }
   });
   confirmDialog.afterClosed().subscribe(res => {
      if (res) {
        // this.router.navigate(['salesOrder/list']);
      } else {

      }
   });
  }*/
}
