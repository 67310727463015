<div class="row mt-2 d-flex justify-content-center">
    <h2 class="d-inline">Production Tracker</h2>
   
 </div>
 <hr>
<div class="row" *ngIf="currentLoggedInUser && currentLoggedInUser!==null && searchConfig">
    <div class="col-12" >
       <app-search #searchComponent [placeholder]="'Search Order# Customer Printers Suppliers PO#'" [searchConfig]="searchConfig" [existingList]="productionTrackerList" [sortColumns]="sortColumns"></app-search>
    </div>
    <!--<div class="col-auto">
        <div class="d-flex d-inline ">
           <div>
              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Printer</label>
                <select class="form-control form-control-sm custom-select mr-1" id="selectedPrinter" name="selectedPrinter" [(ngModel)]="selectedPrinter" (change)="choosePrinter();search()">
                <option value="ALL">Show All</option>
                <option *ngFor="let printer of printerList" [ngValue]="printer.name">
                    {{printer.name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="col-auto">
        <div class="d-flex d-inline ">
           <div>
              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Print Status</label>
                <select class="form-control form-control-sm custom-select mr-1" id="selectedprintInfoStatus" name="selectedprintInfoStatus" [(ngModel)]="selectedprintInfoStatus" (change)="chooseprintInfoStatus();search()">
                    <option value="ALL">Show All</option>
                    <option value="DELAYED">Delayed</option>
                    <option value="ON_TIME">On Time</option>
                </select>
            </div>
        </div>
    </div>-->
    <!--
    <div class="col-auto">
        <mat-form-field appearance="fill">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Start date">
              <input matEndDate placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    </div>
    <div class="col-auto">
        <div class="d-flex d-inline ">
            <div>
               <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer Ship Date</label>
               <input type="text" class="form-control form-control-sm" #daterange
               ngxDaterangepickerMd
               [(ngModel)]="selected"
               [showCustomRangeLabel]="true"
               [alwaysShowCalendars]="true"
               [ranges]="ranges"
               [linkedCalendars]="true"
               
               [showClearButton]="true"
               (datesUpdated)="chooseCustomerShipDateRange(false,$event);search()"
               [locale]="locale"
               [showRangeLabelOnInput]="true"
               placeholder="Select Date Range..." readonly
               style="background-color: transparent; z-index: 500;"/>
             </div>
         </div>
    </div>-->
    <!--
    <div class="col-sm-2">
       <div class="d-flex d-inline ">
          <div>
             <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Status</label>
       <select class="form-control form-control-sm custom-select mr-1" id="selectedStatus" name="selectedStatus" [(ngModel)]="selectedStatus" (change)="changeStatusTypeAndSearch()">
          <option value="ALL">Show All</option>
          <option value="SUBMITTED">Show Submitted</option>
          <option value="DRAFT">Show Draft</option>
      </select>
      </div></div>
    </div>
    <div class="col-sm-2" *ngIf="selectedStatus!=='DRAFT'">
       <div class="d-flex d-inline ">
          <div>
             <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Production Status</label>
       <select class="form-control form-control-sm custom-select mr-1" id="selectedProductionStatus" name="selectedProductionStatus" [(ngModel)]="selectedProductionStatus" (change)="changeProductionStatusTypeAndSearch()">
          <option value="ALL">Show All</option>
          <option value="IN_PROGRESS">Show In-Progress</option>
          <option *ngFor="let productionStatus of productionStatusList" [ngValue]="productionStatus">
             {{productionStatus | replace:'_':' ' | titlecase}}</option>
      </select>
      </div></div>
    </div>-->

    
 </div>



<div *ngIf="currentLoggedInUser && currentLoggedInUser!==null && searchConfig">
    
    <mat-accordion class="example-headers-align" multi>
        
        <ng-container *ngFor="let productionTracker of productionTrackerList; index as productionTrackerIndex" >
            <mat-expansion-panel  [expanded]="productionTracker.accordionExpanded"  #exp  [style]="productionTracker.accordionExpanded?'border: 1px solid; margin-bottom:4px; margin-top:4px;':''" class="mb-2"
            (opened)="productionTrackerForSalesOpened(productionTracker)" (closed)="productionTracker.accordionExpanded=false;">
                <!--(opened)="productionTracker.accordionExpanded=true;" (closed)="productionTracker.accordionExpanded=false;"-->
                <mat-expansion-panel-header class="pb-2 pl-0" [style]="productionTracker.accordionExpanded?'background-color:#f8dc8140;':''" [collapsedHeight]="''" [expandedHeight]="''" [style.border-left]="productionTracker.printInfoStatus==='DELAYED'?'4px solid tomato':'4px solid green'">
                    <mat-panel-title>
                       <!--<div *ngIf="productionTracker.warehouseDetailVisible">{{productionTracker.itemCode}}</div>
                         
                          SalesOrder#	BlankSupplier	Printer	Customer Ship Date	Customer Cancel Date	Printer Requested Ship Date	Printer Requested Cancel Date
                          	Production Status	Fulfillment Status 	Delay/Time	Cushion	Week

                      
                    -->
                    <div class=" container-fluid  p-1">
                        <div class="row">
                            <div class="col fit">
                                <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Order #</label>
                                <div>
                                    <span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">
                                        <a  style="color: #333;" class="text-decoration-none" target="_blank" *ngIf="userAccess?.canViewSalesOrder" [routerLink]="['/salesOrder/view/'+productionTracker.salesOrderId]"  matTooltipPosition="below" matTooltipClass="bg-dark" matTooltip="View Sales Order">
                                            {{productionTracker?.salesOrderNumber}}
                                            <i class="fas fa-external-link-alt ml-1 showLinkIcon"></i>   
                                        </a>
                                        <ng-container *ngIf="!userAccess?.canViewSalesOrder">{{productionTracker?.salesOrderNumber}}</ng-container>
                                    </span>
                                </div>
                            </div>   
                        
                            <div class="col fit">
                                <label class="mb-0 ml-1" class="text-nowrap mb-0" style="font-size: .60rem; color: lightslategray;">Order Ship Date</label>
                                <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.customerShipDate | date:'mediumDate'}}</span></div>
                            </div>
                            <div class="col fit">
                                <label class="mb-0 ml-1" class="text-nowrap mb-0" style="font-size: .60rem; color: lightslategray;">Order Cancel Date</label>
                                <div><span class="form-control form-control-sm" class="text-nowrap" style=" background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.customerCancelDate | date:'mediumDate'}}</span></div>
                            </div>   
                            <div class="col fit">
                                <label class="mb-0 ml-1" class="text-nowrap mb-0" style="font-size: .60rem; color: lightslategray;">Printer Ship Date</label>
                                <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.printerRequestShipDate | date:'mediumDate'}}</span></div>
                            </div>
                            <div class="col fit">
                                <label class="mb-0 ml-1" class="text-nowrap mb-0" style="font-size: .60rem; color: lightslategray;">Printer Cancel Date</label>
                                <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.printerCancelDate | date:'mediumDate'}}</span></div>
                            </div>
                            <!--<div class="col fit">
                                <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Fullfillment Status</label>
                                <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.salesOrderNumber}}</span></div>
                            </div>-->
                            
                            <div class="col fit">
                                <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Cushion</label>
                                <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.cushion}} Days</span></div>
                            </div>
                            <div class="col fit">
                                <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Week</label>
                                <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.week}}</span></div>
                            </div>   
                            <div class="col fit">
                                <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Styles</label>
                                <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.styleCount}}</span></div>
                            </div> 
                            <div class="col fit">
                                <label class="mb-0 ml-1" class="text-nowrap mb-0" style="font-size: .60rem; color: lightslategray;">Print Status</label>
                                <div *ngIf="productionTracker.printInfoStatus!=='DETAILS_MISSING'"><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;"
                                    [style.color]="productionTracker.printInfoStatus==='DELAYED'?'tomato':'green'">{{productionTracker.printInfoStatus==='DELAYED'?'Delayed':'On Time'}}</span></div>
                                    <div *ngIf="productionTracker.printInfoStatus==='DETAILS_MISSING'">
                                        <span class="form-control form-control-sm text-warning text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">
                                            Details Missing</span></div>
                            </div> 
                            
                            <div class="col fit" >
                                <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer</label>
                                <div><span class="form-control form-control-sm" class="text-break" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.customerName}}</span></div>
                            </div>
                        
                            <div class="col fit" >
                                <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer PO(s)</label>
                                <div><span class="form-control form-control-sm" class="text-break" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{ (productionTracker?.customerPO?.length>15)? (productionTracker?.customerPO | slice:0:15)+'..':(productionTracker?.customerPO) }}</span></div>
                            </div>
                            
                            <div class="col fit" >
                                <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Coordinator</label>
                                <div><span class="form-control form-control-sm" class="text-break" style="background: transparent; font-size: 12px; border-width: 0px;">{{productionTracker?.coordinatorName}}</span></div>
                            </div>
                            <div class="col fit" >
                                <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Sales Rep</label>
                                <div><span class="form-control form-control-sm" class="text-break" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.salesRepName}}</span></div>
                            </div>

                            <div class="col-auto" >
                                <label class="mb-0 ml-1" class="text-nowrap mb-0" style="font-size: .60rem; color: lightslategray;">Blank Supplier(s)</label>
                                <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.blanksSuppliers?.includes(',')?'Multi':productionTracker?.blanksSuppliers}}</span></div>
                            </div>
                            <div class="col-auto" >
                                <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Printer(s)</label>
                                <div><span class="form-control form-control-sm" class="text-break" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.printers?.includes(',')?'Multi':productionTracker?.printers}}</span></div>
                            </div>
                            <!--<div class="col fit">
                                <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Fullfillment Status</label>
                                <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.salesOrderNumber}}</span></div>
                            </div>-->

                            
                         </div>
                         <div class=" d-flex pt-1 f-12">
                            <div class="d-flex flex-wrap flex-row" *ngIf="productionTracker.assignedStyleCount!==productionTracker.styleCount">
                                <div class="ml-2 text-nowrap">
                                    <span style="font-weight: 600;">Style Assignment Pending : {{productionTracker.assignedStyleCount?productionTracker.assignedStyleCount:0}} / {{productionTracker.styleCount}}</span> 
                                </div>
                            </div>    
                            <div class="d-flex flex-wrap flex-row" *ngIf="productionTracker.assignedStyleCount===productionTracker.styleCount">
                                <div  *ngIf="productionTracker.blanksInfoStatus" class=" mt-1 ml-2 badge badge-pill d-flex justify-content-center align-items-center" 
                                [class]="productionTracker.blanksInfoStatus==='DELIVERED'?'badge-success':'badge-danger'">
                                    <span *ngIf="productionTracker.blanksInfoStatus==='DELIVERED'" class="pr-1"><i class="fas fa-check-circle"></i></span>
                                    <span *ngIf="productionTracker.blanksInfoStatus!=='DELIVERED'" class="pr-1"><i class="fas fa-times-circle"></i></span>
                                    Blanks
                                </div>
                                <div  *ngIf="productionTracker.artInfoStatus" class=" mt-1 ml-2 badge badge-pill d-flex justify-content-center align-items-center" 
                                [class]="productionTracker.artInfoStatus==='SENT'?'badge-success':'badge-danger'">
                                    <span *ngIf="productionTracker.artInfoStatus==='SENT'" class="pr-1"><i class="fas fa-check-circle "></i></span>
                                    <span *ngIf="productionTracker.artInfoStatus!=='SENT'" class="pr-1"><i class="fas fa-times-circle "></i></span>
                                     Art
                                </div>
                               <div  *ngIf="productionTracker.ppSampleInfoStatus" class=" mt-1 ml-2 badge badge-pill d-flex justify-content-center align-items-center" 
                               [class]="productionTracker.ppSampleInfoStatus==='APPROVED'?'badge-success':'badge-danger'">
                                    <span *ngIf="productionTracker.ppSampleInfoStatus==='APPROVED'" class="pr-1"><i class="fas fa-check-circle "></i></span>
                                    <span *ngIf="productionTracker.ppSampleInfoStatus!=='APPROVED'" class="pr-1"><i class="fas fa-times-circle "></i></span>
                                    PP Samples
                                </div>
                                <div  *ngIf="productionTracker.topSampleInfoStatus" class="mt-1 ml-2 badge badge-pill d-flex justify-content-center align-items-center" 
                                [class]="productionTracker.topSampleInfoStatus==='APPROVED'?'badge-success':'badge-danger'">
                                    <span *ngIf="productionTracker.topSampleInfoStatus==='APPROVED'" class="pr-1"><i class="fas fa-check-circle "></i></span>
                                    <span *ngIf="productionTracker.topSampleInfoStatus!=='APPROVED'" class="pr-1"><i class="fas fa-times-circle "></i></span>
                                    Top Samples
                                </div>
                                <div  *ngIf="productionTracker.polyBagsInfoStatus" class=" mt-1 ml-2 badge badge-pill d-flex justify-content-center align-items-center" 
                                [class]="productionTracker.polyBagsInfoStatus==='DELIVERED'?'badge-success':'badge-danger'">
                                    <span *ngIf="productionTracker.polyBagsInfoStatus==='DELIVERED'" class="pr-1"><i class="fas fa-check-circle "></i></span>
                                    <span *ngIf="productionTracker.polyBagsInfoStatus!=='DELIVERED'" class="pr-1"><i class="fas fa-times-circle "></i></span>
                                    Polybags
                                </div>
                                <div  *ngIf="productionTracker.boxLabelsInfoStatus" class="mt-1 ml-2 badge badge-pill d-flex justify-content-center align-items-center" 
                                [class]="productionTracker.boxLabelsInfoStatus==='DELIVERED'?'badge-success':'badge-danger'">
                                    <span *ngIf="productionTracker.boxLabelsInfoStatus==='DELIVERED'" class="pr-1"><i class="fas fa-check-circle "></i></span>
                                    <span *ngIf="productionTracker.boxLabelsInfoStatus!=='DELIVERED'" class="pr-1"><i class="fas fa-times-circle "></i></span>
                                    Box Labels
                                </div>
                                <div  *ngIf="productionTracker.trimInfoStatus" class="mt-1 ml-2 badge badge-pill d-flex justify-content-center align-items-center" 
                                [class]="productionTracker.trimInfoStatus==='DELIVERED'?'badge-success':'badge-danger'">
                                    <span *ngIf="productionTracker.trimInfoStatus==='DELIVERED'" class="pr-1"><i class="fas fa-check-circle "></i></span>
                                    <span *ngIf="productionTracker.trimInfoStatus!=='DELIVERED'" class="pr-1"><i class="fas fa-times-circle "></i></span>
                                    Trims
                                </div>
                                <div  *ngIf="productionTracker.finishingSheetInfoStatus" class="mt-1 ml-2 badge badge-pill d-flex justify-content-center align-items-center" 
                                [class]="productionTracker.finishingSheetInfoStatus==='SENT'?'badge-success':'badge-danger'">
                                    <span *ngIf="productionTracker.finishingSheetInfoStatus==='SENT'" class="pr-1"><i class="fas fa-check-circle "></i></span>
                                    <span *ngIf="productionTracker.finishingSheetInfoStatus!=='SENT'" class="pr-1"><i class="fas fa-times-circle "></i></span>
                                    Finishing Sheets
                                </div>
                                
                            </div>
                            <div class="ml-auto">
                                <div class="ml-2 text-nowrap">
                                    <span style="font-weight: 600;">Production Status: </span> <em>{{'sales.productionStatus.'+productionTracker?.productionStatus | transform}}</em>
                                </div>
                            </div>
                        </div>   
                        
                    </div>
                    
                    </mat-panel-title>
                    
                 </mat-expansion-panel-header>
        
            <!--<mat-form-field appearance="fill">
                <mat-label>First name</mat-label>
                <input matInput>
            </mat-form-field>
        
            <mat-form-field appearance="fill">
                <mat-label>Age</mat-label>
                <input matInput type="number" min="1">
            </mat-form-field>-->
            <div *ngIf="productionTracker.accordionExpanded===true;">
                <hr>
                <app-production-tracker [salesOrderId]="productionTracker.salesOrderId" [userAccess]="userAccess" (onSave)="onProductionTrackerChnage(productionTrackerIndex,productionTracker.salesOrderId)"></app-production-tracker>
            </div>
        
            </mat-expansion-panel>
            
            <!--<mat-expansion-panel disabled>
            <mat-expansion-panel-header>
                <mat-panel-title>
                Destination
                </mat-panel-title>
                <mat-panel-description>
                Type the country name
                <mat-icon>map</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
        
            <mat-form-field appearance="fill">
                <mat-label>Country</mat-label>
                <input matInput>
            </mat-form-field>
            </mat-expansion-panel>
        
            <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                Day of the trip
                </mat-panel-title>
                <mat-panel-description>
                Inform the date you wish to travel
                <mat-icon>date_range</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            
            </mat-expansion-panel>-->
        </ng-container>
      </mat-accordion>

</div>