import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ReportService } from 'src/app/shared/service/report.service';
import { ReportDetails } from 'src/app/shared/model/report-details.model';
import { ActivatedRoute } from '@angular/router';

@Component({
   selector: 'app-basic-report',
   templateUrl: './basic-report.component.html',
   styleUrls: ['./basic-report.component.css']
})
export class BasicReportComponent implements OnInit, OnDestroy, AfterViewInit {

   constructor(private activatedRoute: ActivatedRoute, private reportService: ReportService) { }

   @ViewChild(DataTableDirective, { static: false })
   datatableElement: DataTableDirective;
   isDtInitialized: boolean = false;

   dtOptions: DataTables.Settings = {};
   dtTrigger: Subject<any> = new Subject();

   private routeParamSubscriber: any;

   reportDetails: ReportDetails;
   reportType: string;

   ngOnInit(): void {
      //
      this.routeParamSubscriber = this.activatedRoute.params.subscribe(params => {
         //
         this.reportType = params.reportType;
         this.initializeDatatable();
      });
   }

   initializeDatatable() {
      //
      this.dtOptions = {
         pagingType: 'full_numbers',
         pageLength: 100,
         processing: true,
      };
      this.loadReportDetails();
   }

   loadReportDetails() {
      //
      this.reportType = this.activatedRoute.snapshot.paramMap.get('reportType');
      //
      this.reportService.getReport(this.reportType).subscribe(response => {
         //
         this.reportDetails = new ReportDetails;
         this.reportDetails.reportHeaders = response.responsePayload.reportHeaders;
         this.reportDetails.reportData = response.responsePayload.reportData;
         //
         this.dtTrigger.next();
      });
   }

   ngAfterViewInit(): void {
   }

   ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
      this.routeParamSubscriber.unsubscribe();
   }

   isProductAttachment(val): boolean { return typeof val === 'object' && val.fileSignedUrl; }

}
