<div>
   <div class="row mt-2 d-flex justify-content-center">
      <h2 class="d-inline">
         File Closure Summary
      </h2>
   </div>
   <div>
      <div class="row">
         <div class="col-sm-8" *ngIf="currentLoggedInUser && currentLoggedInUser!==null">
            <app-search #searchComponent [placeholder]="'Order#, Customer Name etc. (space delimited)'" [searchConfig]="searchConfig" [existingList]="fileClosureSummaryViewList"
               [sortColumns]="sortColumns"></app-search>
         </div>
      </div>
   
   <!--
      <div>
         <ng-container>
            <table class=" f-10 table table-sm table-bordered customtablehover table-condensed">
               <thead class="thead-dark" style="position: sticky;top: 0">
                  <tr class="text-center">
                     <th rowspan="2">Order#</th>
                     <th rowspan="2">Customer</th>
                     <th rowspan="2">Sales Rep.</th>
                     <th rowspan="2">Coordinator</th>
                     <th rowspan="2">Production <br/> Status</th>
                     <th rowspan="2">Allocated <br/> Suppliers</th>
                     <th rowspan="2">Allocated <br/> Printers</th>
                     <th rowspan="2">Customer PO(s)</th>
                     <th colspan="3">Blanks Files</th>
                     <th colspan="2">Printer Files</th>
                     <th rowspan="2">Finishing <br/> Sheet</th>
                     <th colspan="3">File Closing</th>
                  </tr>
                  <tr class="text-center">
                     <th>Blanks PO</th>
                     <th>Blanks POD</th>
                     <th>Blanks Packing List</th>
                     <th>Printer PO</th>
                     <th>Printer Packing List</th>
                     <th>Order Processing Checklist</th>
                     <th>Invoices</th>
                     <th>Signed BOL/POD</th>
                  </tr>
               </thead>
               <tbody>
                  <ng-container *ngFor="let fcsv of fileClosureSummaryViewList; index as fcsvIndex;">
                     <tr class="text-center border" [style.background-color]="fcsvIndex%2===1?'rgba(0,0,0,.05)':''">
                        <td>{{fcsv.salesOrderNumber}}</td>
                        <td>{{fcsv.customerName}}</td>
                        <td>{{fcsv.salesRepName}}</td>
                        <td>{{fcsv.coordinatorName}}</td>
                        <td>{{'sales.productionStatus.'+fcsv.productionStatus | transform}}</td>
                        <td>{{fcsv.allocatedSupplierCount}}</td>
                        <td>{{fcsv.allocatedPrinterCount}}</td>
                        <td style="text-align: -webkit-center;">
                           <ng-container *ngIf="fcsv.customerPoOrDistributionListCount > 0 then customerPoSuccess else customerPoFail"></ng-container>
                           <ng-template #customerPoSuccess><span class=" badge badge-success" style="font-size:12.5px;"><i class="fas fa-check-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.customerPoOrDistributionListCount}}</span></ng-template>
                           <ng-template #customerPoFail><span class=" badge badge-danger" style="font-size:12.5px;"><i class="fas fa-times-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.customerPoOrDistributionListCount}}</span></ng-template>
                        </td>
                        <td  style="text-align: -webkit-center;">
                           <ng-container *ngIf="((fcsv.blanksPoCount > fcsv.allocatedSupplierCount) || (fcsv.blanksPoCount == fcsv.allocatedSupplierCount)) then blanksPoSuccess else blanksPoFail"></ng-container>
                           <ng-template #blanksPoSuccess><span class=" badge badge-success" style="font-size:12.5px;"><i class="fas fa-check-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.blanksPoCount}}</span></ng-template>
                           <ng-template #blanksPoFail><span class=" badge badge-danger" style="font-size:12.5px;"><i class="fas fa-times-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.blanksPoCount}}</span></ng-template>
                        </td>
                        <td  style="text-align: -webkit-center;">
                           <ng-container *ngIf="((fcsv.blanksPodCount > fcsv.allocatedSupplierCount) || (fcsv.blanksPodCount == fcsv.allocatedSupplierCount)) then blanksPodSuccess else blanksPodFail"></ng-container>
                           <ng-template #blanksPodSuccess><span class=" badge badge-success" style="font-size:12.5px;"><i class="fas fa-check-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.blanksPodCount}}</span></ng-template>
                           <ng-template #blanksPodFail><span class=" badge badge-danger" style="font-size:12.5px;"><i class="fas fa-times-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.blanksPodCount}}</span></ng-template>
                        </td>
                        <td  style="text-align: -webkit-center;">
                           <ng-container *ngIf="((fcsv.blanksPackingListCount > fcsv.allocatedSupplierCount) || (fcsv.blanksPackingListCount == fcsv.allocatedSupplierCount)) then blanksPlSuccess else blanksPlFail"></ng-container>
                           <ng-template #blanksPlSuccess><span class=" badge badge-success" style="font-size:12.5px;"><i class="fas fa-check-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.blanksPackingListCount}}</span></ng-template>
                           <ng-template #blanksPlFail><span class=" badge badge-danger" style="font-size:12.5px;"><i class="fas fa-times-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.blanksPackingListCount}}</span></ng-template>
                        </td>      
                        <td  style="text-align: -webkit-center;">
                           <ng-container *ngIf="((fcsv.printerPoCount > fcsv.allocatedPrinterCount) || (fcsv.printerPoCount == fcsv.allocatedPrinterCount)) then printerPoSuccess else printerPoFail"></ng-container>
                           <ng-template #printerPoSuccess><span class=" badge badge-success" style="font-size:12.5px;"><i class="fas fa-check-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.printerPoCount}}</span></ng-template>
                           <ng-template #printerPoFail><span class=" badge badge-danger" style="font-size:12.5px;"><i class="fas fa-times-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.printerPoCount}}</span></ng-template>
                        </td>
                        <td  style="text-align: -webkit-center;">
                           <ng-container *ngIf="((fcsv.printerPackingListCount > fcsv.allocatedPrinterCount) || (fcsv.printerPackingListCount == fcsv.allocatedPrinterCount)) then printerPlSuccess else printerPlFail"></ng-container>
                           <ng-template #printerPlSuccess><span class=" badge badge-success" style="font-size:12.5px;"><i class="fas fa-check-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.printerPackingListCount}}</span></ng-template>
                           <ng-template #printerPlFail><span class=" badge badge-danger" style="font-size:12.5px;"><i class="fas fa-times-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.printerPackingListCount}}</span></ng-template>
                        </td>                                                                      
                        <td>
                           <ng-container *ngIf="fcsv.finishingSheetCount > 0 then finishingSheetSuccess else finishingSheetFail"></ng-container>
                           <ng-template #finishingSheetSuccess><span class=" badge badge-success" style="font-size:12.5px;"><i class="fas fa-check-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.finishingSheetCount}}</span></ng-template>
                           <ng-template #finishingSheetFail><span class=" badge badge-danger" style="font-size:12.5px;"><i class="fas fa-times-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.finishingSheetCount}}</span></ng-template>                           
                        </td>
                        <td style="text-align: -webkit-center;">
                           <ng-container *ngIf="fcsv.orderProcessingChecklistCount > 0 then opcSuccess else opcFail"></ng-container>
                           <ng-template #opcSuccess><span class=" badge badge-success" style="font-size:12.5px;"><i class="fas fa-check-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.orderProcessingChecklistCount}}</span></ng-template>
                           <ng-template #opcFail><span class=" badge badge-danger" style="font-size:12.5px;"><i class="fas fa-times-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.orderProcessingChecklistCount}}</span></ng-template>
                        </td>  
                        <td style="text-align: -webkit-center;">
                           <ng-container *ngIf="fcsv.invoicesCount > 0 then invoiceSuccess else invoiceFail"></ng-container>
                           <ng-template #invoiceSuccess><span class=" badge badge-success" style="font-size:12.5px;"><i class="fas fa-check-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.invoicesCount}}</span></ng-template>
                           <ng-template #invoiceFail><span class=" badge badge-danger" style="font-size:12.5px;"><i class="fas fa-times-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.invoicesCount}}</span></ng-template>
                        </td>  
                        <td style="text-align: -webkit-center;">
                           <ng-container *ngIf="fcsv.signedBolOrPodCount > 0 then signedBolOrPodSuccess else signedBolOrPodFail"></ng-container>
                           <ng-template #signedBolOrPodSuccess><span class=" badge badge-success" style="font-size:12.5px;"><i class="fas fa-check-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.signedBolOrPodCount}}</span></ng-template>
                           <ng-template #signedBolOrPodFail><span class=" badge badge-danger" style="font-size:12.5px;"><i class="fas fa-times-circle fa-1x" style="color:white"></i>&nbsp;{{fcsv.signedBolOrPodCount}}</span></ng-template>
                        </td>                                                                              
                     </tr>
                  </ng-container>
               </tbody>
            </table>
         </ng-container>
      </div>
   -->

      <div>
         <ng-container>
            <table class=" f-10 table table-sm table-bordered customtablehover table-condensed">
               <thead class="thead-dark" style="position: sticky;top: 0">
                  <tr class="text-center">
                     <th rowspan="2">Order#</th>
                     <th rowspan="2">Customer</th>
                     <th rowspan="2">Sales Rep.</th>
                     <th rowspan="2">Coordinator</th>
                     <th rowspan="2">Production <br/> Status</th>
                     <th rowspan="2">Allocated <br/> Suppliers</th>
                     <th rowspan="2">Allocated <br/> Printers</th>
                     <th rowspan="2">Customer PO(s)</th>
                     <th colspan="3">Blanks Files</th>
                     <th colspan="2">Printer Files</th>
                     <th rowspan="2">Finishing <br/> Sheet</th>
                     <th colspan="3">File Closing</th>
                  </tr>
                  <tr class="text-center">
                     <th>Blanks PO</th>
                     <th>Blanks POD</th>
                     <th>Blanks Packing List</th>
                     <th>Printer PO</th>
                     <th>Printer Packing List</th>
                     <th>Order Processing Checklist</th>
                     <th>Invoices</th>
                     <th>Signed BOL/POD</th>
                  </tr>
               </thead>
               <tbody>
                  <ng-container *ngFor="let fcsv of fileClosureSummaryViewList; index as fcsvIndex;">
                     <tr class="text-center border" [style.background-color]="fcsvIndex%2===1?'rgba(0,0,0,.05)':''">
                        <td>
                           <span *ngIf="!userAccess?.canViewSalesOrder">{{fcsv.salesOrderNumber}}</span>
                           <a [routerLink]="['/salesOrder/view/'+fcsv.salesOrderId]" target="_blank" *ngIf="userAccess?.canViewSalesOrder">
                              {{fcsv.salesOrderNumber}}
                           </a>
                        </td>
                        <td>{{fcsv.customerName}}</td>
                        <td>{{fcsv.salesRepName}}</td>
                        <td>{{fcsv.coordinatorName}}</td>
                        <td>{{fcsv.productionStatus}}</td>
                        <td>{{fcsv.allocatedSupplierCount}}</td>
                        <td>{{fcsv.allocatedPrinterCount}}</td>
                        <td style="text-align: -webkit-center;">
                           <ng-container *ngIf="fcsv.customerPoOrDistributionListCount > 0 then customerPoSuccess else customerPoFail"></ng-container>
                           <ng-template #customerPoSuccess><span style="color: green;font-weight: 600;font-size: 13px;">&#10003;&nbsp;&nbsp;{{fcsv.customerPoOrDistributionListCount}}</span></ng-template>
                           <ng-template #customerPoFail><span style="color: red;font-weight: 600;font-size: 13px;">&#10007;&nbsp;&nbsp;{{fcsv.customerPoOrDistributionListCount}}</span></ng-template>
                        </td>
                        <td  style="text-align: -webkit-center;">
                           <ng-container *ngIf="((fcsv.blanksPoCount > fcsv.allocatedSupplierCount) || (fcsv.blanksPoCount == fcsv.allocatedSupplierCount)) then blanksPoSuccess else blanksPoFail"></ng-container>
                           <ng-template #blanksPoSuccess><span style="color: green;font-weight: 600;font-size: 13px;">&#10003;&nbsp;&nbsp;{{fcsv.blanksPoCount}}</span></ng-template>
                           <ng-template #blanksPoFail><span style="color: red;font-weight: 600;font-size: 13px;">&#10007;&nbsp;&nbsp;{{fcsv.blanksPoCount}}</span></ng-template>
                        </td>
                        <td  style="text-align: -webkit-center;">
                           <ng-container *ngIf="((fcsv.blanksPodCount > fcsv.allocatedSupplierCount) || (fcsv.blanksPodCount == fcsv.allocatedSupplierCount)) then blanksPodSuccess else blanksPodFail"></ng-container>
                           <ng-template #blanksPodSuccess><span style="color: green;font-weight: 600;font-size: 13px;">&#10003;&nbsp;&nbsp;{{fcsv.blanksPodCount}}</span></ng-template>
                           <ng-template #blanksPodFail><span style="color: red;font-weight: 600;font-size: 13px;">&#10007;&nbsp;&nbsp;{{fcsv.blanksPodCount}}</span></ng-template>
                        </td>
                        <td  style="text-align: -webkit-center;">
                           <ng-container *ngIf="((fcsv.blanksPackingListCount > fcsv.allocatedSupplierCount) || (fcsv.blanksPackingListCount == fcsv.allocatedSupplierCount)) then blanksPlSuccess else blanksPlFail"></ng-container>
                           <ng-template #blanksPlSuccess><span style="color: green;font-weight: 600;font-size: 13px;">&#10003;&nbsp;&nbsp;{{fcsv.blanksPackingListCount}}</span></ng-template>
                           <ng-template #blanksPlFail><span style="color: red;font-weight: 600;font-size: 13px;">&#10007;&nbsp;&nbsp;{{fcsv.blanksPackingListCount}}</span></ng-template>
                        </td>      
                        <td  style="text-align: -webkit-center;">
                           <ng-container *ngIf="((fcsv.printerPoCount > fcsv.allocatedPrinterCount) || (fcsv.printerPoCount == fcsv.allocatedPrinterCount)) then printerPoSuccess else printerPoFail"></ng-container>
                           <ng-template #printerPoSuccess><span style="color: green;font-weight: 600;font-size: 13px;">&#10003;&nbsp;&nbsp;{{fcsv.printerPoCount}}</span></ng-template>
                           <ng-template #printerPoFail><span style="color: red;font-weight: 600;font-size: 13px;">&#10007;&nbsp;&nbsp;{{fcsv.printerPoCount}}</span></ng-template>
                        </td>
                        <td  style="text-align: -webkit-center;">
                           <ng-container *ngIf="((fcsv.printerPackingListCount > fcsv.allocatedPrinterCount) || (fcsv.printerPackingListCount == fcsv.allocatedPrinterCount)) then printerPlSuccess else printerPlFail"></ng-container>
                           <ng-template #printerPlSuccess><span style="color: green;font-weight: 600;font-size: 13px;">&#10003;&nbsp;&nbsp;{{fcsv.printerPackingListCount}}</span></ng-template>
                           <ng-template #printerPlFail><span style="color: red;font-weight: 600;font-size: 13px;">&#10007;&nbsp;&nbsp;{{fcsv.printerPackingListCount}}</span></ng-template>
                        </td>                                                                      
                        <td>
                           <ng-container *ngIf="fcsv.finishingSheetCount > 0 then finishingSheetSuccess else finishingSheetFail"></ng-container>
                           <ng-template #finishingSheetSuccess><span style="color: green;font-weight: 600;font-size: 13px;">&#10003;&nbsp;&nbsp;{{fcsv.finishingSheetCount}}</span></ng-template>
                           <ng-template #finishingSheetFail><span style="color: red;font-weight: 600;font-size: 13px;">&#10007;&nbsp;&nbsp;{{fcsv.finishingSheetCount}}</span></ng-template>                           
                        </td>
                        <td style="text-align: -webkit-center;">
                           <ng-container *ngIf="fcsv.orderProcessingChecklistCount > 0 then opcSuccess else opcFail"></ng-container>
                           <ng-template #opcSuccess><span style="color: green;font-weight: 600;font-size: 13px;">&#10003;&nbsp;&nbsp;{{fcsv.orderProcessingChecklistCount}}</span></ng-template>
                           <ng-template #opcFail><span style="color: red;font-weight: 600;font-size: 13px;">&#10007;&nbsp;&nbsp;{{fcsv.orderProcessingChecklistCount}}</span></ng-template>
                        </td>  
                        <td style="text-align: -webkit-center;">
                           <ng-container *ngIf="fcsv.invoicesCount > 0 then invoiceSuccess else invoiceFail"></ng-container>
                           <ng-template #invoiceSuccess><span style="color: green;font-weight: 600;font-size: 13px;">&#10003;&nbsp;&nbsp;{{fcsv.invoicesCount}}</span></ng-template>
                           <ng-template #invoiceFail><span style="color: red;font-weight: 600;font-size: 13px;">&#10007;&nbsp;&nbsp;{{fcsv.invoicesCount}}</span></ng-template>
                        </td>  
                        <td style="text-align: -webkit-center;">
                           <ng-container *ngIf="fcsv.signedBolOrPodCount > 0 then signedBolOrPodSuccess else signedBolOrPodFail"></ng-container>
                           <ng-template #signedBolOrPodSuccess><span style="color: green;font-weight: 600;font-size: 13px;">&#10003;&nbsp;&nbsp;{{fcsv.signedBolOrPodCount}}</span></ng-template>
                           <ng-template #signedBolOrPodFail><span style="color: red;font-weight: 600;font-size: 13px;">&#10007;&nbsp;&nbsp;{{fcsv.signedBolOrPodCount}}</span></ng-template>
                        </td>                                                                              
                     </tr>
                  </ng-container>
               </tbody>
            </table>
         </ng-container>
      </div>


   </div>


   <div class="floatBtn d-flex flex-column">
      <button type="button" class="btn btn-circle bg-warning mb-2" *ngIf="userAccess?.canDownloadFileClosureSummary" (click)="createFileClosureSummaryExcel()"><i class="fas fa-file-download fa-2x"></i></button>
      <button type="button" class="btn btn-circle bg-secondary mb-2" matTooltip="Scroll To Top" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'});" matTooltipPosition="left" matTooltipClass="bg-dark">
         <i class="fas fa-arrow-up fa-2x" style="color:white"></i>
      </button>
   </div>
</div>