import { Component, OnInit, HostListener, Input, EventEmitter, Output } from '@angular/core';
import { Licensor } from 'src/app/shared/model/licensor.model';
import { Brand } from 'src/app/shared/model/brand.model';
import { Product, StatusDetails, ProductAttachment, SampleDetails, SkuTrackerRetailerInfo} from 'src/app/shared/model/product.model';
import { SearchCriteria } from 'src/app/shared/model/search-criteria.model';
import { LicensorService } from 'src/app/shared/service/licensor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/shared/service/product.service';
import { Location } from '@angular/common';
import { ProductCatalogService } from 'src/app/shared/service/product-catalog.service';
import { ProductSearchService } from 'src/app/shared/service/product-search.service';
import { BrandService } from 'src/app/shared/service/brand.service';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { trigger, style, state, transition, animate, query, stagger } from '@angular/animations';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { ToastrService } from 'ngx-toastr';
import { ServiceStatus } from 'src/app/shared/model/service-status.model';
import { ThrowStmt } from '@angular/compiler';
import { CustomerService } from 'src/app/shared/service/customer.service';
import { Customer } from 'src/app/shared/model/customer.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { ProductMetadataService } from 'src/app/shared/service/product-metadata.service';

declare var $:any;
export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
  )
]);

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true}
    )
  ])
]);

@Component({ 
selector: 'app-sku-tracker-list',
templateUrl: './sku-tracker-list.component.html',
styleUrls: ['./sku-tracker-list.component.css'],
   animations: [fadeAnimation, listAnimation]
})
export class SkuTrackerListComponent implements OnInit {
   licensorFilterListForSearch: string[] = [];
   brandFilterListForSearch: string[] = [];
   tagFilterListForSearch: string[] = [];
   colorFilterListForSearch: string[] = [];
   garmentFilterListForSearch: string[] = [];
   treatmentFilterListForSearch: string[] = [];
   categoryFilterListForSearch: string[] = [];
   brandCategoryFilterListForSearch: string[] = [];
   printEffectFilterListForSearch: string[] = [];
   approvalStatusFilterListForSearch: string[] = [];
   createdByFilterListForSearch: string[] = [];
   searchStringForSearch: string = "";
   productIdListString: string = "";
   
   licensorListModalFiltered: string[] = [];
   brandListModalFiltered: string[] = [];
   colorListModalFiltered: string[] = [];
   garmentListModalFiltered: string[] = [];
   treatmentListModalFiltered: string[] = [];
   tagListModalFiltered: string[] = [];
   createdByListModalFiltered: string[] = [];
   approvalStatusListModalFiltered: string[] = [];
   categoryListModalFiltered:string[]=[];
   brandCategoryListModalFiltered:string[]=[];
   printEffectListModalFiltered:string[]=[];

   licensorList: string[] = null;
   brandList: string[] = null;
   productList: Product[];
   filteredProductList: Product[];
   colorList = [];
   garmentList = [];
   approvalStatusList = [];
   treatmentList = [];
   categoryList=[];
   brandCategoryList=[];
   printEffectList=[];
   tagList = []
   createdByList = [];

   serachCriteria: SearchCriteria;

   LoadArtError: string;
   LoadArtErrorFlag: boolean;
   selectedProductForCatalogCount: number = 0;

  userAccessSubscription:Subscription;
  userAccess:UserAccess;
  //showLatestSearch:boolean=false;
  //type1:string='COLOR';
  filterTypes:string[]=['LICENSORS','BRANDS','BRAND_CATEGORY','CATEGORY','APPROVAL_STATUS','GARMENT','COLOR','TREATMENT','PRINT_EFFECT','TAGS'];
  //Same sequence as filterTypes
  filterTypesNames:string[]=['Licensors','Brands','Brand Categories','Product Categories','Approval Status','Garments','Colors','Treatments','Print Effects','Tags'];
  defaultDisplaySize:number=25;
  headers=['Sku','Status','Status Date','Licensor', 'Brand','Description'];
  //headers=['Sku','Status','Status Date','Licensor', 'Brand','Description','Customer','Order Qty','Wholesale Cost','Retailer Cost','Expected Shipped Date','Status','Comments'];
  submissionDetailHeaders=['SKU','Status','Status Date','User', 'Licensor Comments','Philcos Comments'];
  filterVisible:boolean=false;
  productChange:Product=new Product;
  indexChange:number;
  existingStatus:string;
  includeInactiveLicensorsAndBrands:boolean;
  sortColumns = [{ columnName: 'SKU', sortByName: 'sku' },
  { columnName: 'Last Updated', sortByName: 'updated_on' },
  { columnName: 'Status', sortByName: 'approval_status' }
  ];
  selectedSortByName: string;
  sortMode: string = 'ASC';
  embedded:boolean=false;
  totalSearchCount=0;
  showMoreDisabled: boolean;
  customerList:Customer[]=[];
  
  @Output() onSave = new EventEmitter<Product>();
   constructor(private router:Router,private toastrService:ToastrService,private authService: AuthService, private loaderService:LoaderService, private metadataService: MetadataService, public sharedService: SharedService, private productService: ProductService, private productSearchService: ProductSearchService, private LicensorService: LicensorService, private brandService: BrandService, private productCatalogService: ProductCatalogService, private activatedRoute: ActivatedRoute, private location: Location, private customerService:CustomerService,
    private productMetadataService:ProductMetadataService) { }

   ngOnInit() {
      this.handleSubscriptions();
      this.reloadSearchCriteriaFromService();
      this.loadProducts(false);
      this.loadFilterItem('APPROVAL_STATUS');
      this.loadCustomers();
      this.selectedProductForCatalogCount = this.productCatalogService.selectedProductsForCatalog.length;
      window.scrollTo(0, 0);
      $('[data-toggle="tooltip"]').tooltip();
      //$('[data-toggle="popover"]').popover();

   }

   @Input()
    set searchProductListOverride(productIdListString:string){
      //this.searchStringForSearch='';
      this.productIdListString=productIdListString;
      this.productSearchService.skuSearchDetails.resetAdvSearchCriteria();
      this.productSearchService.skuSearchDetails.productIdListString = this.productIdListString;
      this.embedded=true;
      this.search();
    }

    includeInactiveLicensorsAndBrandsChange(event){
      this.includeInactiveLicensorsAndBrands=false; 
      if(event.target.checked){
        this.includeInactiveLicensorsAndBrands=true; 
      }
      this.productSearchService.skuSearchDetails.excludeInactiveBrandAndLicensor=!this.includeInactiveLicensorsAndBrands;
      this.search();
    }

    loadCustomers() {
      this.customerService.getCustomerListByTier(CustomerService.CUSTOMER_TIER_MASS).subscribe(res=>{
        //
        if(res && res!=null && res.responsePayload.length>0){
         let customerArr:Customer[]=res.responsePayload as Customer[];
         customerArr.forEach(x=>{
           this.customerList.push(x);
           //
         });
         this.customerList=this.sharedService.sortListByPropertyName(this.customerList,'customerName');
        }
      });
    }

   loadFilterItem(type:string){
      if(type==='LICENSORS'){
         this.loadLicensors();
      }else if(type==='BRANDS'){
         this.loadBrands();
      }else  if(type==='TAGS'){
         this.loadTags();
      }else  if(type==='BRAND_CATEGORY'){
         this.loadMetaDataForType('BRAND','CATEGORY');
      }else {
        if(type!=='COLOR' && type!=='GARMENT' && type!=='CATEGORY' && type!=='TREATMENT'){
          this.loadMetaDataForType('PRODUCT',type);
       }
      }
      
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
      this.productMetadataService.colorListObservable.subscribe(res => {
        this.colorList.length=0;
        this.colorList.push(...res);
        console.log("Subscribed Color List",this.colorList);
        this.setMasterList('COLOR',this.colorList);
        this.setFilteredMasterListForModal('COLOR',this.colorList);
        
        });
  
        this.productMetadataService.categoryListObservable.subscribe(res => {
          this.categoryList.length=0;
          this.categoryList.push(...res);
          console.log("Subscribed Category List",this.categoryList);
          this.setMasterList('CATEGORY',this.categoryList);
        this.setFilteredMasterListForModal('CATEGORY',this.categoryList);
        });
  
        this.productMetadataService.treatmentListObservable.subscribe(res => {
          this.treatmentList.length=0;
          this.treatmentList.push(...res);
          console.log("Subscribed Treatment List",this.treatmentList);
          this.setMasterList('TREATMENT',this.treatmentList);
          this.setFilteredMasterListForModal('TREATMENT',this.treatmentList);
        });
  
        this.productMetadataService.garmentListObservable.subscribe(res => {
          this.garmentList.length=0;
          this.garmentList.push(...res);
          console.log("Subscribed Garment List",this.garmentList);
          this.setMasterList('GARMENT',this.garmentList);
          this.setFilteredMasterListForModal('GARMENT',this.garmentList);
        });
   }

   reloadSearchCriteriaFromService() {
      this.serachCriteria = this.productSearchService.skuSearchDetails.getSearchCriteria();
      this.licensorFilterListForSearch = this.productSearchService.skuSearchDetails.selectedLicensorsForSearch;
      this.brandFilterListForSearch = this.productSearchService.skuSearchDetails.selectedBrandsForSearch;
      this.tagFilterListForSearch = this.productSearchService.skuSearchDetails.selectedTagsForSearch;
      this.colorFilterListForSearch = this.productSearchService.skuSearchDetails.selectedColorsForSearch;
      this.garmentFilterListForSearch = this.productSearchService.skuSearchDetails.selectedGarmentsForSearch;
      this.treatmentFilterListForSearch = this.productSearchService.skuSearchDetails.selectedTreatmentsForSearch;
      this.approvalStatusFilterListForSearch = this.productSearchService.skuSearchDetails.selectedApprovalStatusListForSearch;
      this.createdByFilterListForSearch = this.productSearchService.skuSearchDetails.selectedCreatedByListForSearch;
      this.categoryFilterListForSearch=this.productSearchService.skuSearchDetails.selectedCategoriesForSearch;
      this.brandCategoryFilterListForSearch=this.productSearchService.skuSearchDetails.selectedBrandCategoriesForSearch;
      this.printEffectFilterListForSearch=this.productSearchService.skuSearchDetails.selectedPrintEffectsForSearch;
      this.searchStringForSearch = this.productSearchService.skuSearchDetails.searchString;
      this.filterVisible=this.productSearchService.skuSearchDetails.filterVisible;
      this.selectedSortByName = this.productSearchService.skuSearchDetails.selectedSortByName;
      this.sortMode = this.productSearchService.skuSearchDetails.sortMode;  
      this.includeInactiveLicensorsAndBrands=!this.productSearchService.skuSearchDetails.excludeInactiveBrandAndLicensor;
      /*if(!this.embedded){
        this.productIdListString='';
        this.productSearchService.skuSearchDetails.productIdListString='';
      }*/
       
      
   }

   async loadProducts(loadMore:boolean) {
     this.productSearchService.skuSearchDetails.loadMore=loadMore;
     this.productSearchService.skuSearchDetails.resetLoadMoreCount();
     await this.productService.loadProductForSearch(this.productSearchService.skuSearchDetails.getSearchCriteria()).toPromise().then((res:SearchCriteria) => {
        if(loadMore){
          this.productSearchService.skuSearchDetails.lastSelection=0;
          this.productList=this.productList.concat(res.productSearchResult);
       }else{
          this.productList = res.productSearchResult as Product[];
          this.totalSearchCount=res.totalCount;
       }
       this.productSearchService.skuSearchDetails.setResultMetadata(res)
       this.onSortColumnChange();
       this.showMoreDisabled=false;
      },
         (err) => {
            console.error(err);
            this.LoadArtErrorFlag = true;
            this.LoadArtError = err.message;
            this.showMoreDisabled=false;
         });
      this.selectedProductForCatalogCount = this.productCatalogService.selectedProductsForCatalog.length;
   }
   loadTags() {
      this.productService.loadTags().subscribe((res: string[]) => {
         let list = this.sharedService.sort(res);
         this.setMasterList('TAGS',list);
         this.setFilteredMasterListForModal('TAGS',list);
      });

   }
   loadBrands() {
      this.brandService.loadBrands([]).subscribe((res: Brand[]) => {
         let list = this.sharedService.sort(res.map(x=>x.brandName));
         this.setMasterList('BRANDS',list);
         this.setFilteredMasterListForModal('BRANDS',list);
      });
   }
   loadLicensors() {
      this.LicensorService.loadLicensors().subscribe((res: Licensor[]) => {
         let list = this.sharedService.sort(res.map(x=>x.licensorName));
         this.setMasterList('LICENSORS',list);
         this.setFilteredMasterListForModal('LICENSORS',list);
      });
   }
    loadMetaDataForType(classification, type) {
      this.metadataService.getMetadataValueListByClassificationAndType(classification, type).subscribe((res: ApiResponse) => {
         let typeTemp=type;
         if(classification==='BRAND' && type==='CATEGORY'){
            typeTemp='BRAND_CATEGORY';
         }
         if (res.responseStatus.status === 'SUCCESS') {
            let maserList=this.sharedService.sort(res.responsePayload);
            this.setMasterList(typeTemp,maserList);
            this.setFilteredMasterListForModal(typeTemp,maserList);
         }
      });
   }
   getFilteredListForSearch(type: any):string[] {
      if(type==='LICENSORS'){
         return this.licensorFilterListForSearch;
       }else if(type==='BRANDS'){
         return this.brandFilterListForSearch;
       }else if(type==='CATEGORY'){
         return this.categoryFilterListForSearch;
       }else if(type==='BRAND_CATEGORY'){
         return this.brandCategoryFilterListForSearch;
       }else if(type==='PRINT_EFFECT'){
         return this.printEffectFilterListForSearch
       }else if(type==='TAGS'){
         return this.tagFilterListForSearch;
       }else if(type==='APPROVAL_STATUS'){
         return this.approvalStatusFilterListForSearch;
       }else if(type==='TREATMENT'){
         return this.treatmentFilterListForSearch;
       }else if(type==='GARMENT'){
         return this.garmentFilterListForSearch;
       }else if(type==='COLOR'){
         return this.colorFilterListForSearch;
       }
   }
   setFilteredMasterListForModal(type: any, list: string[]) {
      if(type==='LICENSORS'){
         this.licensorListModalFiltered=list;
      }else if(type==='BRANDS'){
         this.brandListModalFiltered=list;
       }else if(type==='CATEGORY'){
         this.categoryListModalFiltered=list;
       }else if(type==='BRAND_CATEGORY'){
         this.brandCategoryListModalFiltered=list;
       }else if(type==='PRINT_EFFECT'){
         this.printEffectListModalFiltered=list;
       }else if(type==='TAGS'){
         this.tagListModalFiltered=list;
       }else if(type==='APPROVAL_STATUS'){
         this.approvalStatusListModalFiltered=list;
       }else if(type==='TREATMENT'){
         this.treatmentListModalFiltered=list;
       }else if(type==='GARMENT'){
         this.garmentListModalFiltered=list;
       }else if(type==='COLOR'){
         this.colorListModalFiltered=list;
       }
   }
   getFilteredMasterListForModal(type: any):string[] {
      if(type==='LICENSORS'){
         return this.licensorListModalFiltered;
      }else if(type==='BRANDS'){
         return this.brandListModalFiltered;
       }else if(type==='CATEGORY'){
         return this.categoryListModalFiltered;
       }else if(type==='BRAND_CATEGORY'){
         return this.brandCategoryListModalFiltered;
       }else if(type==='PRINT_EFFECT'){
         return this.printEffectListModalFiltered;
       }else if(type==='TAGS'){
         return this.tagListModalFiltered;
       }else if(type==='APPROVAL_STATUS'){
         return this.approvalStatusListModalFiltered;
       }else if(type==='TREATMENT'){
         return this.treatmentListModalFiltered;
       }else if(type==='GARMENT'){
         return this.garmentListModalFiltered;
       }else if(type==='COLOR'){
         return this.colorListModalFiltered;
       }
   }
   setMasterList(type: any, list: string[]) {
      if(type==='LICENSORS'){
         this.licensorList=list;
      }else if(type==='BRANDS'){
         this.brandList=list;
       }else if(type==='CATEGORY'){
         this.categoryList=list;
       }else if(type==='BRAND_CATEGORY'){
         this.brandCategoryList=list;
       }else if(type==='PRINT_EFFECT'){
         this.printEffectList=list;
       }else if(type==='TAGS'){
         this.tagList=list;
       }else if(type==='APPROVAL_STATUS'){
         this.approvalStatusList=list;
       }else if(type==='TREATMENT'){
         this.treatmentList=list;
       }else if(type==='GARMENT'){
         this.garmentList=list;
       }else if(type==='COLOR'){
         this.colorList=list;
       }
   }
   getMasterList(type: any):string[] {
      if(type==='LICENSORS'){
         return this.licensorList;
      } else if(type==='BRANDS'){
         return this.brandList;
       }else if(type==='CATEGORY'){
         return this.categoryList;
       }else if(type==='BRAND_CATEGORY'){
         return this.brandCategoryList;
       }else if(type==='PRINT_EFFECT'){
         return  this.printEffectList;
       }else if(type==='TAGS'){
         return  this.tagList;
       }else if(type==='APPROVAL_STATUS'){
         return  this.approvalStatusList;
       }else if(type==='TREATMENT'){
         return  this.treatmentList;
       }else if(type==='GARMENT'){
         return  this.garmentList;
       }else if(type==='COLOR'){
         return this.colorList;
       }
   }
   selectedAllForSearch(selectedAll,type:string){
    this.getFilteredMasterListForModal(type).forEach(element => {
       this.selectedForSearch(event,element,type);
       if (selectedAll) {
          this.productSearchService.skuSearchDetails.add(element,type);
       } else {
          this.productSearchService.skuSearchDetails.remove(element,type);
       }
    });
    this.reloadSearchCriteriaFromService();
 }

   selectedForSearch(event, item: string,type:string) {
      if (event.target.checked) {
         this.productSearchService.skuSearchDetails.add(item,type);
      } else {
         this.productSearchService.skuSearchDetails.remove(item,type);
      }
      this.reloadSearchCriteriaFromService();
   }

   isSelectedForSearch(id: string,type:string): boolean {
      return this.productSearchService.skuSearchDetails.isSelected(id,type);
   }

   onKeyupModalFilter(event, value,type) {
      if (value.trim().length > 0) {
         this.setFilteredMasterListForModal(type,this.getMasterList(type).filter(item => item.toUpperCase().indexOf(value.toUpperCase()) !== -1));
      } else {
         this.setFilteredMasterListForModal(type,this.getMasterList(type));
      }
   }

   resetAdvSearch() {
      this.productSearchService.skuSearchDetails.resetAdvSearchCriteria();
      this.serachCriteria = this.productSearchService.skuSearchDetails.getSearchCriteria();
      this.licensorFilterListForSearch = [];
      this.brandFilterListForSearch = [];
      this.tagFilterListForSearch = [];
      this.colorFilterListForSearch = [];
      this.garmentFilterListForSearch = [];
      this.treatmentFilterListForSearch = [];
      this.categoryFilterListForSearch=[];
      this.brandCategoryFilterListForSearch=[];
      this.printEffectFilterListForSearch=[];
      this.approvalStatusFilterListForSearch = [];
      this.createdByFilterListForSearch = [];
      this.productSearchService.skuSearchDetails.lastSelection=0;
      this.loadProducts(false);
   }

   search(event?) {
    this.productSearchService.skuSearchDetails.lastSelection=0;
      this.productSearchService.skuSearchDetails.searchString = this.searchStringForSearch;
      this.loadProducts(false);
   }
   
   searchInput(event){
      if(event?.target.value.length===0 && event.type !== 'focus'){
         this.search(event);
      }
   }

   
   resetSkuCodeSearch() {
      this.serachCriteria = this.productSearchService.skuSearchDetails.getSearchCriteria();
   }

   
   /*onKeyupProductFilter(event, value) {
      if (event.key !== 'Shift') {
         if (value.trim().length > 0) {
            this.filteredProductList = this.productList.filter(items => items.sku.toUpperCase().indexOf(value.toUpperCase()) > -1);
         } else {
            this.filteredProductList = this.productList;
         }
      }

   }*/
   loadMoreProducts(){
      /*let existingCount=this.filteredProductList.length;
      
      let additionalProducts:Product[]=this.productList.slice(existingCount,existingCount+this.defaultDisplaySize);
      this.filteredProductList=this.filteredProductList.concat(additionalProducts);*/
      this.loadProducts(true);
   }

   


/*******************************CATALOG RELATED STARTS************************** */
  
   childSelectionChanged(selection:boolean){
      this.selectedProductForCatalogCount = this.productCatalogService.selectedProductsForCatalog.length;
   }
   /*selectCurrentFilteredListForCatalog(){
      this.productList.forEach(element => {
      //this.filteredProductList.forEach(element => {
         if(element.approvalStatus!=='Concept Rejected' && element.productImages.length>0){
            this.productCatalogService.addProduct(element);
         }
      });
      this.selectedProductForCatalogCount = this.productCatalogService.selectedProductsForCatalog.length;
   }*/

   resetCatalogSelection() {
      this.productCatalogService.selectedProductsForCatalog = [];
      this.selectedProductForCatalogCount = this.productCatalogService.selectedProductsForCatalog.length;
   }
/*******************************CATALOG RELATED ENDS************************** */

   downloadSelectedImages(){
      this.productCatalogService.download();
      this.selectedProductForCatalogCount = this.productCatalogService.selectedProductsForCatalog.length;
   }
   @HostListener("window:scroll", ["$event"])
   onWindowScroll() {
      let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
      let max = document.documentElement.scrollHeight;
      if (pos > (max - 5)) {
        if(this.productSearchService?.skuSearchDetails.loadedCount<this.productSearchService?.skuSearchDetails.totalCount &&  this.productSearchService?.skuSearchDetails.loadedCount<500){
            //alert('alert');
            document.getElementById('showMoreId').click();
            this.showMoreDisabled=true;
            
         }
      }
   }

   /*SKU related*/
   showHideFilter(){
     this.filterVisible=!this.filterVisible;
     this.productSearchService.skuSearchDetails.filterVisible = this.filterVisible;
   }
   approvalStatusColor(status:string):string{
    if(this.sharedService.producatStatusColor.get(status)!==undefined){
       return this.sharedService.producatStatusColor.get(status);
    }else{
       return '';
    }
  }

  handleThumbnailError(productAttachment: ProductAttachment, event) {
    if(productAttachment){
    if (isNaN(productAttachment?.thumbnailErrorCount)) {
      productAttachment.thumbnailErrorCount = 0;
    }
    event.target.src = productAttachment.fileSignedUrl;
    var x: number = productAttachment.thumbnailErrorCount;
    productAttachment.thumbnailErrorCount = x + 1;
    if (productAttachment.thumbnailErrorCount < 2) {
      event.target.src = productAttachment.fileSignedUrl;
      productAttachment.thumbnailSignedUrl = productAttachment.fileSignedUrl;
    } else {
      event.target.src = "/assets/default-image.jpg"
    }
  }
  
  }
   skuSelected(product:Product,state:Element){
    if(state.classList.contains('collapsed')){
  // being expanded
      product.skuTrackerExpanded=true;
      this.loaderService.show();
     // setTimeout(()=>{
        //this.testSTringToDisplay=product.sku;
        this.loadStatusAndSampleDetails(product);
        this.loaderService.hide();
     // },2000);
    }else{
      product.skuTrackerExpanded=false;
    }
   }
  
   skuEdit(product:Product,state:HTMLElement,index:number){
    this.indexChange = index; 
    this.productService.isCurrentVersion(product.id,product.version).subscribe(res=>{
      let serviceStatus:ServiceStatus=res as ServiceStatus;
      if(serviceStatus && serviceStatus!=null && serviceStatus.status==="SUCCESS"){
        this.proceedWithEdit(product);
      }else{
        //this.toastrService.info('Product has been modified by other user.Reloading before Edit');
        this.reloadProductAndEdit(product,true);         
      }
    });
   }

  private proceedWithEdit(product: Product) {
    product.skuTrackerEditable = true;
    this.loadStatusAndSampleDetails(product);
    this.productChange = product;
    this.existingStatus = product.approvalStatus;
    document.getElementById('statusChangeModalId').click();
  }

   skuEditCancel(product:Product,index:number){
    product.skuTrackerEditable=false;
    this.reloadProduct(product);
   }

   reloadProductAndEdit(product:Product,proceedToEditAfterLoad:boolean){
    this.reloadProduct(product,proceedToEditAfterLoad);
   }
   reloadProduct(product:Product,proceedToEditAfterLoad?:boolean){
      let searchCriteria:SearchCriteria=new SearchCriteria;
      searchCriteria.productId=product.id;
      this.productService.loadProduct(searchCriteria).subscribe((res) => {
      product = res as Product;
      if( product!==null ){
         this.loadStatusAndSampleDetails(product);
      }
      this.filteredProductList[this.indexChange]=product;
      if(proceedToEditAfterLoad){
        this.proceedWithEdit(product);
      }
    });
   }

   skuSave(product:Product){
      this.productService.updateProduct(product).subscribe((res) => {
         let status:ServiceStatus=res as ServiceStatus;
         if(status.status=='SUCCESS'){
           this.toastrService.success(status.message);
           let productReturned= status.object as Product;// never set product returned to current product
           product.version=productReturned.version;
           this.handleApprovalStatusChange(product);
         }else{
           if(status.errorCode==='STALE_OBJECT_ERROR'){
            this.toastrService.error('Product has been modified by other user. Please refresh and make your changes again before save');  
          }else{
            this.toastrService.error(status.message);
          }
         }
         this.reloadProduct(product);
         this.onSave.emit(product);
       },
         (err) => {
           this.toastrService.error(JSON.stringify(err));
           this.reloadProduct(product);
         });
    product.skuTrackerEditable=false;
   }

   handleApprovalStatusChange(product:Product) {
      if (product.approvalStatus !== this.existingStatus) {
        this.productService.approvalStatusChanged(product.id, this.existingStatus,product.propogateStatusToMembers).subscribe(res => {
          let serviceStatus: ServiceStatus = res as ServiceStatus;
          if (serviceStatus.status !== 'SUCCESS') {
            this.toastrService.error(serviceStatus.message);
          }
        }
        );
      }
    }
  
   statusChanged(product:Product,event:Event){
     //alert(product.approvalStatus);
     if(!product.statusDetails|| product.statusDetails.length===0){
       product.statusDetails=[];
     }
     //if(['Concept Pending','Pre-Pro Samples Submitted'].includes(product.approvalStatus)){
       let details:StatusDetails=new StatusDetails;
        details.status=product.approvalStatus;
        details.statusDate=new Date;
        product.statusDetails.push(details);
       
        
     //}
   }
   
   loadStatusAndSampleDetails(product: Product){
    this.loadStatusDetails(product);
    this.loadSampleDetails(product);
    this.loadSkuTrackerRetailerInfo(product);
 }
  
    loadStatusDetails(product: Product) {
      product.statusDetails=[];
      this.productService.getStatusDetails(product.id).subscribe((res) => {
        product.statusDetails=res as StatusDetails[];
       },
         (err) => {
           this.toastrService.error(JSON.stringify(err));
           //this.reloadProduct(product);
         });
    }

   
    loadSampleDetails(product: Product) {
      product.sampleDetails=[];
      this.productService.getSampleDetails(product.id).subscribe((res) => {
        product.sampleDetails=res as SampleDetails[];
       },
         (err) => {
           this.toastrService.error(JSON.stringify(err));
           //this.reloadProduct(product);
         });
    }

    loadSkuTrackerRetailerInfo(product: Product) {
      product.skuTrackerRetailerInfo=[];
      this.productService.getSkuTrackerRetailerInfo(product.id).subscribe((res) => {
        product.skuTrackerRetailerInfo=res as SkuTrackerRetailerInfo[];
       },
         (err) => {
           this.toastrService.error(JSON.stringify(err));
           //this.reloadProduct(product);
         });
    }
   /*SKu Related Ends*/

   /*Sort Related*/
   /*onSortColumnChange(mode:string) {
      this.productList = this.sharedService.sortListByPropertyName(this.productList, this.selectedSortByName);
      if(mode && mode==='DESC'){
         this.productList.reverse();
      }      
      this.filteredProductList = this.productList.slice(0,this.defaultDisplaySize);
     
   }*/

   onSortColumnChange() {
    //let countToDisplay=this.defaultDisplaySize;
    let lastSelection=this.productSearchService.skuSearchDetails.lastSelection;
    lastSelection=lastSelection?lastSelection:0;
    /*if(lastSelection>this.defaultDisplaySize){
       countToDisplay=lastSelection+this.defaultDisplaySize;
    }
    if(this.productList.length>countToDisplay){
    this.filteredProductList = this.productList.slice(0,countToDisplay);
    }else{
       this.filteredProductList=this.productList.slice(0,this.productList.length);
    }  */
    this.filteredProductList=this.productList;
    if(this.filteredProductList.length>=lastSelection && lastSelection>3){
       setTimeout(()=>{
        lastSelection=lastSelection>1?lastSelection-1:0;
        if(document.getElementById('imageCard_'+lastSelection)){
          document.getElementById('imageCard_'+lastSelection).scrollIntoView({block: "start", behavior: "smooth"});
        }
       },800);

    }
 }

   sort(mode:string) {
      this.sortMode = mode;
      this.productSearchService.skuSearchDetails.lastSelection=0;
      this.productSearchService.skuSearchDetails.selectedSortByName=this.selectedSortByName;
      this.productSearchService.skuSearchDetails.sortMode=this.sortMode;  
      this.loadProducts(false);
     
   }

   changeSortCriteria(){
    this.productSearchService.skuSearchDetails.lastSelection=0;
    this.productSearchService.skuSearchDetails.selectedSortByName=this.selectedSortByName;
    this.loadProducts(false);
  }
   /*Sort Related Ends*/

   navigateToProduct(index:number,product:Product){
    this.productSearchService.skuSearchDetails.lastSelection=index
    //this.router.navigate(['/product/view/'+product.licensorId+'/'+product.brandId+'/'+product.id]);
 }

 changePriority(product: Product) {
  if (this.userAccess?.canChangeProductPriority) {
     this.productService.changePriority(product.id, !product.priority).subscribe(res => {
        let serviceStatus: ServiceStatus = res as ServiceStatus;
        if (serviceStatus && serviceStatus.status === 'SUCCESS') {
           product.priority = !product.priority;
        } else {
           this.toastrService.error('Failed to change priority - ' + serviceStatus.message);
        }
     }, err => {
        this.toastrService.error('Error while changing priority - ' + err);
     });
  }
}
}

