import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
// Admin - User Management Modules
import { UserListComponent } from './admin/user-management/user-list/user-list.component';
import { UserComponent } from './admin/user-management/user/user.component';
// Auth Modules Imports
import { AuthGuard } from './auth/guards/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
// Licensor Module Imports
import { LicensorListComponent } from './licensor-management/licensor-list/licensor-list.component';
import { LicensorComponent } from './licensor-management/licensor/licensor.component';
// Brand Module Imports
import { BrandListComponent } from './brand-management/brand-list/brand-list.component';
import { BrandComponent} from './brand-management/brand/brand.component';
// Product Management Module Imports
import { ProductComponent } from './product-management/product/product.component';
import { ProductListComponent } from './product-management/product-list/product-list.component';
import { CatalogViewComponent } from './product-catalog-management/catalog-view/catalog-view.component';
import { SubmissionSheetComponent } from './product-management/submission-sheet/submission-sheet.component';
import { MetadataComponent } from './metadata/metadata.component';
import { RolePermissionComponent } from './admin/user-management/role-permission/role-permission.component';
import { RoleListComponent } from './admin/user-management/role-list/role-list.component';
import { BasicReportComponent } from './report-management/basic-report/basic-report.component';
import { RedirectComponent } from './common/redirect/redirect.component';
import { SkuTrackerComponent } from './product-management/sku-tracker/sku-tracker.component';
import { PdfFormListComponent } from './pdf-form-management/pdf-form-list/pdf-form-list.component';
import { PdfFormComponent } from './pdf-form-management/pdf-form/pdf-form.component';
import { RightsOverviewComponent } from './brand-management/rights-overview/rights-overview.component';
import { SkuTrackerListComponent } from './product-management/sku-tracker-list/sku-tracker-list.component';
import { RoyaltyOverviewComponent } from './licensor-management/royalty-overview/royalty-overview.component';
import { SkuTrackerDashboardComponent } from './dashboard-management/sku-tracker-dashboard/sku-tracker-dashboard.component';
import { CustomerListComponent } from './customer-management/customer-list/customer-list.component';
import { CustomerComponent } from './customer-management/customer/customer.component';
import { DeactivateGuard } from './auth/guards/deactivate.gaurd';
import { SalesOrderComponent } from './sales-management/sales-order/sales-order.component';
import { SalesOrderListComponent } from './sales-management/sales-order-list/sales-order-list.component';
import { BlanksListComponent } from './blanks-management/blanks-list/blanks-list/blanks-list.component';
import { BlanksComponent } from './blanks-management/blanks/blanks/blanks.component';
import { SalesDashboardComponent } from './dashboard-management/sales-dashboard/sales-dashboard.component';
import { ProductionTrackerListComponent } from './sales-management/fulfillment/production-tracker-list/production-tracker-list.component';
import { AllocationSheetComponent } from './sales-management/costing/allocation/allocation-sheet/allocation-sheet.component';
import { CostingSheetComponent } from './sales-management/costing/costingSheet/costing-sheet/costing-sheet.component';
import { BlanksSupplierComponent } from './vendor-management/blanks-supplier/blanks-supplier.component';
import { BlanksSupplierListComponent } from './vendor-management/blanks-supplier-list/blanks-supplier-list.component';
import { PrinterListComponent } from './vendor-management/printer-list/printer-list.component';
import { PrinterComponent } from './vendor-management/printer/printer.component';
import { AllocatedSalesOrderComponent } from './sales-management/printer-portal/allocated-sales-order/allocated-sales-order.component';
import { AllocatedSalesOrderListComponent } from './sales-management/printer-portal/allocated-sales-order-list/allocated-sales-order-list.component';
import { MultipleSubmissionComponent } from './product-management/approval-submission/multiple-submission/multiple-submission.component';
import { TaskListComponent } from './task-management/task-list/task-list.component';
import { PrinterCapacityListComponent } from './sales-management/costing/allocation/printer-capacity/printer-capacity-list/printer-capacity-list.component';
import { FileClosureSummaryListComponent } from './sales-management/file-closure/file-closure-summary-list/file-closure-summary-list.component';
import { TrimsTrackerListComponent } from './sales-management/fulfillment/trims-tracker-list/trims-tracker-list.component';
import { RoyaltyMismatchListComponent } from './sales-management/costing/royalty/royalty-mismatch-list/royalty-mismatch-list.component' 
import { ArtTrackerListComponent } from './product-management/art-tracker-list/art-tracker-list.component';
import { ProductMetadataComponent } from './metadata/product-metadata/product-metadata.component';
import { ChargeBackListComponent } from './accounts-management/charge-back-list/charge-back-list.component';
import { ChargeBackComponent } from './accounts-management/charge-back/charge-back.component';
import { DashboardComponent } from './dashboard-management/dashboard/dashboard.component';
import { PreProTrackerListComponent } from './product-management/pre-pro-tracker-list/pre-pro-tracker-list.component';


const routes: Routes = [
// Home Route
// Task Management Routes
{ path: 'task/list', component: TaskListComponent,  canActivate: [AuthGuard]},
{ path: 'sku-tracker', component: SkuTrackerComponent,  canActivate: [AuthGuard]},
{ path: 'sku-tracker-list', component: SkuTrackerListComponent,  canActivate: [AuthGuard]},
{ path: 'art-tracker-list', component: ArtTrackerListComponent,  canActivate: [AuthGuard]},
{ path: 'prepro-tracker-list', component: PreProTrackerListComponent,  canActivate: [AuthGuard]},
{ path: 'product-catalog', component: CatalogViewComponent,  canActivate: [AuthGuard]},
{ path: 'product-catalog/:temp', component: CatalogViewComponent,  canActivate: [AuthGuard]},
{ path: 'product-search/viewCatalogPreview', component: CatalogViewComponent, canActivate: [AuthGuard]},
{ path: 'home', component: HomeComponent, data: {animation: 'HomePage'}, canActivate: [AuthGuard] },  

// Accounts Management
{ path: 'chargeback/list', component: ChargeBackListComponent},
{ path: 'chargeback', component: ChargeBackComponent},
{ path: 'chargeback/:action', component: ChargeBackComponent},
{ path: 'chargeback/:action/:id', component: ChargeBackComponent},

// User Management Routes
{ path: 'user/list', component: UserListComponent},
{ path: 'user/:action', component: UserComponent},
{ path: 'user/:action/:id', component: UserComponent},
{ path: 'role/list', component: RoleListComponent},
{ path: 'role/permission/:action/:id', component: RolePermissionComponent},

{ path: 'customer/list', component: CustomerListComponent},
{ path: 'customer/:action', component: CustomerComponent},
{ path: 'customer/:action/:id', component: CustomerComponent},

{ path: 'blanksSupplier/list', component: BlanksSupplierListComponent, canActivate: [AuthGuard]},
{ path: 'blanksSupplier/:action', component: BlanksSupplierComponent, canActivate: [AuthGuard]},
{ path: 'blanksSupplier/:action/:id', component: BlanksSupplierComponent, canActivate: [AuthGuard]},

{ path: 'printer/list', component: PrinterListComponent, canActivate: [AuthGuard]},
{ path: 'printer/:action', component: PrinterComponent, canActivate: [AuthGuard]},
{ path: 'printer/:action/:id', component: PrinterComponent, canActivate: [AuthGuard]},

{ path: 'pdf-form/list', component: PdfFormListComponent},
{ path: 'pdf-form/:action/:id', component: PdfFormComponent},

// Licensor Management Routes
{ path: 'licensor-list', component: LicensorListComponent , canActivate: [AuthGuard]},
{ path: 'licensor/:action', component: LicensorComponent , canActivate: [AuthGuard]},
{ path: 'licensor/:action/:id', component: LicensorComponent, canActivate: [AuthGuard]},

// Brand Management Routes
{ path: 'brand-list', component: BrandListComponent , canActivate: [AuthGuard]},
{ path: 'brand/:action/:id', component: BrandComponent, canActivate: [AuthGuard]},

// Product Management Routes
{ path: 'product-search', component: ProductListComponent,canActivate: [AuthGuard]},
/*{ path: 'create-artifact', component: ProductComponent , canActivate: [AuthGuard]},*/
{ path: 'product/:action/:licensorId/:brandId/:id', component: ProductComponent,canDeactivate: [DeactivateGuard],canActivate: [AuthGuard],
  data:{"permissions":[
    {subPath:"product/create",permission:"canCreateProduct"},
    {subPath:"product/view",permission:"canViewProductsSection"},
    {subPath:"product/newCollection",permission:"canCreateProduct"},
    
  ]} },
{ path: 'similarDesign/:action/:licensorId/:brandId/:id', component: ProductComponent,canDeactivate: [DeactivateGuard] },
{ path: 'preview-submission-sheet/:licensorId/:brandId/:id/:category/:subCategory', component: SubmissionSheetComponent },
{ path: 'preview-approval-form/:licensorId/:brandId/:id/:category/:subCategory', component: SubmissionSheetComponent },
/*{ path: 'artifact/:action/:licensorId/:brandId', component: ProductComponent },*/
{ path: 'createProduct/:action/:licensorId/:brandId/:sku', component: ProductComponent,canActivate: [AuthGuard] ,canDeactivate: [DeactivateGuard],
  data:{"permissions":[
    {subPath:"createProduct/create",permission:"canCreateProduct"}
  ]} 
},

// Blanks Management Routes
{ path: 'blanks/list', component: BlanksListComponent, canActivate: [AuthGuard]},
{ path: 'blanks/:action', component: BlanksComponent, canActivate: [AuthGuard]},
{ path: 'blanks/:action/:id', component: BlanksComponent, canActivate: [AuthGuard]},

// Sales Management Routes
{ path: 'production-tracker/list', component: ProductionTrackerListComponent, canActivate: [AuthGuard]},
{ path: 'salesOrder/list', component: SalesOrderListComponent, canActivate: [AuthGuard]},
{ path: 'costing/list/pending', component: SalesOrderListComponent, canActivate: [AuthGuard]
  ,data:{
   "requestFilterSalesOrderType":'Costing',
    "filter":'COSTING_GENERATION_PENDING'
  }
},
{ path: 'costing/list/pendingApproval', component: SalesOrderListComponent, canActivate: [AuthGuard]
  ,data:{
   "requestFilterSalesOrderType":'Costing',
    "filter":'COSTING_APPROVAL_PENDING'
  }
},
{ path: 'estimation/list', component: SalesOrderListComponent, canActivate: [AuthGuard],
   data:{
    "requestFilterSalesOrderType":'Estimation'
  }
},
{ path: 'estimation/pending/list', component: SalesOrderListComponent, canActivate: [AuthGuard],
   data:{
    "requestFilterSalesOrderType":'Estimation',
    "filter":'COSTING_GENERATION_PENDING'
  }
},
{ path: 'estimation/approval/pending/list', component: SalesOrderListComponent, canActivate: [AuthGuard],
   data:{
    "requestFilterSalesOrderType":'Estimation',
    "filter":'COSTING_APPROVAL_PENDING'
  }
},

{ path: 'salesOrder/:action', component: SalesOrderComponent, canActivate: [AuthGuard],
    data:{"permissions":[
      {subPath:"salesOrder/create",permission:"canCreateSalesOrder"},
    ]}
},

{ path: 'estimation/:action', component: SalesOrderComponent, canActivate: [AuthGuard],
    data:{"permissions":[
      {subPath:"estimation/create",permission:"canCreateSalesOrder"},
    ],
    "requestFilterSalesOrderType":'Estimation'
   }
},

{ path: 'salesOrder/:action/:id', component: SalesOrderComponent, canActivate: [AuthGuard],canDeactivate: [DeactivateGuard],
  data:{"permissions":[
    {subPath:"salesOrder/view",permission:"canViewSalesOrder"},
    {subPath:"salesOrder/edit",permission:"canEditSalesOrder"},
    {subPath:"salesOrder/clone",permission:"canCloneSalesOrder"},
  ]}
},

{ path: 'estimation/:action/:id', component: SalesOrderComponent, canActivate: [AuthGuard],canDeactivate: [DeactivateGuard],
      data:{"permissions":[
         {subPath:"estimation/view",permission:"canViewSalesOrder"},
         {subPath:"estimation/edit",permission:"canEditSalesOrder"},
         {subPath:"estimation/clone",permission:"canCloneSalesOrder"},
      ],
      "requestFilterSalesOrderType":'Estimation'
   }
},

{ path: 'costsheet/allocation/:action/:id', component: AllocationSheetComponent, canActivate: [AuthGuard],canDeactivate: [DeactivateGuard]},

{ path: 'costingsheet/:salesOrderId/:orderDetailId', component: CostingSheetComponent, canActivate: [AuthGuard]},

{ path: 'printercapacity/list', component: PrinterCapacityListComponent, canActivate: [AuthGuard]},

{ path: 'fileClosureSummary/list', component: FileClosureSummaryListComponent, canActivate: [AuthGuard]},

// Printer Portal

{ path: 'printer-portal/list', component: AllocatedSalesOrderListComponent, canActivate: [AuthGuard]},
{ path: 'printer-portal/:action/:printerId/:id', component: AllocatedSalesOrderComponent, canActivate: [AuthGuard]},

// Metadata Routes
{ path: 'metadata/list', component: MetadataComponent},

{ path: 'metadata/product', component: ProductMetadataComponent, canActivate: [AuthGuard]},

{ path: 'redirect/:redirectFullPath', component: RedirectComponent},

{ path: 'report/:reportType', component: BasicReportComponent},

{ path: 'report/overview/rights-overview', component: RightsOverviewComponent},
{ path: 'report/overview/royalty-overview', component: RoyaltyOverviewComponent},

{ path: 'dashboard/sku-tracker', component: SkuTrackerDashboardComponent},
{ path: 'dashboard/sales-dashboard', component: SalesDashboardComponent},

{ path: 'dashboard', component: DashboardComponent},

{ path: 'submissions/multi-approval', component: MultipleSubmissionComponent},
{ path: 'trims/tracker/list', component: TrimsTrackerListComponent},

{ path: 'royalty/mismatch/list', component: RoyaltyMismatchListComponent},

// Auth Routes
{ path: 'login', component:LoginComponent},
{ path: 'forgotpassword', component:ForgotPasswordComponent},
{ path: 'changepassword', component:ChangePasswordComponent},
{ path: 'login/resetpassword/:resetPwdToken', component:ChangePasswordComponent}, 
{ path: 'login/changepassword/:resetPwdToken', component:ChangePasswordComponent}, 
{ path: '', redirectTo: '/login', pathMatch: 'full' },
{ path: '**', redirectTo: '/login' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
}) 
export class AppRoutingModule { }
