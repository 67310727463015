import { CostTypeDetail } from "../../costsheet/cost-type-detail.model";
import { PrinterSizeDetails } from "./printer-size-details.model";

export class AllocatedPrinterDetails {
	combinationId:string;
    printerId:string;
	printerName:string;
	fullPackage:boolean;
	printerSizeDetails:PrinterSizeDetails[]=[];
	costTypeDetails:CostTypeDetail[]=[];
	jobType:string;
   currency:string;
   allocatedQty:number;
  	initialBlankDelivery: boolean;
	shipDate:Date;//top level
	cancelDate:Date;//top level
   constructor(combinationId:string){
	this.combinationId=combinationId;
}
}
