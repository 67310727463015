export class Blanks {
   id: string;
   skuCode: string;
   description: string;
   gsm: string;
   fabricContent: string;
   supplier: string;
   notes: string;
   status: boolean;
}
