import { AllocatedPrinterDetails } from "./allocated-printer-details.model";
import { PrinterSizeDetails } from "./printer-size-details.model";

export class AllocatedPrinterCombinationDetails {
	id:string;
	allocated:boolean;
    printerDetails:AllocatedPrinterDetails[]=[];
	combination:boolean;
	combinationName:string;
	averagePrinterSizeDetails:PrinterSizeDetails[]=[];
	coo:string;
	fullPackage:boolean;
	averagePrintCost:number;
	totalQtyAllocated:number;
	approvalStatus:string;
	intialDeliveryPrinterId:string;
	additionalNotes:string;
	constructor(id:string){
		
		this.id=id;
	}
}
