import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { Observable } from "rxjs/index";
import { environment } from 'src/environments/environment';
import { ComposeEmail } from '../model/compose-email.model';
import { EmailTemplateConfig } from '../model/email-template-config.model';

import { ServiceStatus } from '../model/service-status.model';
import { EmailLog } from '../model/email-log.model';

@Injectable({
   providedIn: 'root'
})
export class ComposeEmailService {
   

   readonly baseUrl = environment.apiEndPoint;

   constructor(private http: HttpClient) { }

   sendEmail(emailToSend: ComposeEmail): Observable<ApiResponse> {
      //
      return this.http.post<ApiResponse>(this.baseUrl + '/compose/email/send', emailToSend);
   }

   logApprovalEmail(emailLog: EmailLog) {
      return this.http.post<ServiceStatus>(this.baseUrl + '/compose/email/log', emailLog);
    }

   getUsersEmailList(): Observable<ApiResponse> {
      //
      return this.http.post<ApiResponse>(this.baseUrl + '/compose/user/email/list', null);
   }
   

   getEmailTemplate(emailConfig: EmailTemplateConfig): Observable<ApiResponse> {
      //
      return this.http.post<ApiResponse>(this.baseUrl + '/compose/email/template', emailConfig);
   }

   getApprovalEmailLogs(productId:string) {
      return this.http.get(this.baseUrl + '/compose/email/load/logs/PRODUCT/APPROVAL_EMAIL/'+productId);
   }

   
}