
<!--<form #productionTrackerForm="ngForm"  autocomplete="off" #formVar="ngForm" novalidate>  -->
  <div *ngIf="salesOrder && productionTracker">
  
<div class="row">
  <div class="col-9">
    <!--<div class="d-flex justify-content-end mr-4 mt-2" style="
    position: absolute;
    right: 1px;
    z-index: 999;">
    
    <button type="button" class="btn btn-sm btn-warning ml-2" (click)="edit()" *ngIf="!editable && userAccess?.canEditProductionTracker">Edit</button>
    <button type="button" class="btn btn-sm btn-success ml-2" (click)="save()" *ngIf="editable" [disabled]="!productionTrackerForm.valid">Save</button>
    <button type="button" class="btn btn-sm btn-dark ml-2" (click)="cancel()" *ngIf="editable">Cancel</button>
  </div>-->
        <mat-tab-group>
          <mat-tab>
            <ng-template mat-tab-label>
                <h6><span class="ml-2 font-weight-normal f-13">Blanks</span></h6>
                <!--<span class="text-right ml-2" *ngIf="editable && productionTrackerForm?.form?.controls?.blanksInfo?.invalid">
                  <i class="fa fa-exclamation-triangle" style="color:#dc3545;" aria-hidden="true"></i>
              </span>-->
            </ng-template>
            <div class="overflow-hidden" *ngIf="productionTracker">
              <app-blank-info [productionTracker]="productionTracker" [userAccess]="userAccess" [salesOrder]="salesOrder" [editable]="blankInfoEditable"
              (onBlankInfoEdit)="edit('BLANK_INFO')" (onBlankInfoCancelEdit)="cancel('BLANK_INFO')" (onBlankInfoSave)="save('BLANK_INFO')"></app-blank-info>
            </div>

          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <h6><span class="ml-2 font-weight-normal f-13">Printer</span></h6>
              <!--<span class="text-right ml-2" *ngIf="editable && (productionTrackerForm?.form?.controls?.printerInfo?.invalid || productionTrackerForm?.form?.controls?.trimInfo?.invalid)">
                <i class="fa fa-exclamation-triangle" style="color:#dc3545;" aria-hidden="true"></i>
            </span>-->
            </ng-template>
            <div class="overflow-hidden" *ngIf="productionTracker">
              <app-printer-info [productionTracker]="productionTracker" [userAccess]="userAccess" [salesOrder]="salesOrder" [editable]="printerInfoEditable"
              (onPrinterInfoEdit)="edit('PRINTER_INFO')" (onPrinterInfoCancelEdit)="cancel('PRINTER_INFO')" (onPrinterInfoSave)="save('PRINTER_INFO')"></app-printer-info>
            </div>

        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <h6><span class="ml-2 font-weight-normal f-13">Routing</span></h6>
            <!--<span class="text-right ml-2" *ngIf="editable && (productionTrackerForm?.form?.controls?.routingInfo?.invalid)">
              <i class="fa fa-exclamation-triangle" style="color:#dc3545;" aria-hidden="true"></i>
          </span>-->
          </ng-template>
          <div class="overflow-hidden" *ngIf="productionTracker">
            <app-routing-info [productionTracker]="productionTracker" [userAccess]="userAccess" [salesOrder]="salesOrder" [editable]="routingInfoEditable"
            (onRoutingInfoEdit)="edit('ROUTING_INFO')" (onRoutingInfoCancelEdit)="cancel('ROUTING_INFO')" (onRoutingInfoSave)="save('ROUTING_INFO')"></app-routing-info>
          </div>

      </mat-tab>
        </mat-tab-group>
    </div>
    <div class="col-3  border bg-light" style="border-radius: 4px;" *ngIf="productionTracker && productionTracker.id">
      <app-notes [editable]="true" [parentId]="productionTracker.id" [type]="'SALES_ORDER'" [subType]="'PRODUCTION_TRACKER'" [notesHeaderText]="'Notes'"></app-notes>
      <!--<textarea class="form-control" rows="20"></textarea>-->
    </div>
</div>
<!--
<div class="overflow-hidden mt-2" *ngIf="salesOrder">
  <div class="row">
    <div class="col-2" >
      <div class="list-group" id="list-tab" role="tablist">
        <a class="list-group-item list-group-item-action active" id="blanks-section-list" data-toggle="list" href="#blanks-section" role="tab" aria-controls="home">
          <span class="text-right ml-2" *ngIf="editable && !productionTrackerForm?.form?.controls?.blanksInfo?.valid">
            <i class="fa fa-exclamation-triangle" style="color:#dc3545;" aria-hidden="true"></i>
         </span>
          Blanks
        </a>
        <a class="list-group-item list-group-item-action" id="printers-section-list" data-toggle="list" href="#printers-section" role="tab" aria-controls="profile">
          <span class="text-right ml-2" *ngIf="editable && (productionTrackerForm?.form?.controls?.printerInfo?.invalid || productionTrackerForm?.form?.controls?.trimInfo?.invalid)">
            <i class="fa fa-exclamation-triangle" style="color:#dc3545;" aria-hidden="true"></i>
         </span>
          Printer
          
        </a>
        <a class="list-group-item list-group-item-action" id="styles-section-list" data-toggle="list" href="#styles-section" role="tab" aria-controls="messages">Styles</a>
        <a class="list-group-item list-group-item-action" id="samples-section-list" data-toggle="list" href="#samples-section" role="tab" aria-controls="samples">
          <span class="text-right ml-2" *ngIf="editable && !productionTrackerForm?.form?.controls?.sampleInfo?.valid">
            <i class="fa fa-exclamation-triangle" style="color:#dc3545;" aria-hidden="true"></i>
         </span>
          Samples
        </a>
        <a class="list-group-item list-group-item-action" id="trims-section-list" data-toggle="list" href="#trims-section" role="tab" aria-controls="trims">
          <span class="text-right ml-2" *ngIf="editable && !productionTrackerForm?.form?.controls?.trimInfo?.valid">
            <i class="fa fa-exclamation-triangle" style="color:#dc3545;" aria-hidden="true"></i>
         </span>
          Trims & Finishing Sheet
        </a>
      </div>
    </div>
    <div class="col-9 border shadow">
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="blanks-section" role="tabpanel" aria-labelledby="blanks-section-list">
          <app-blank-info [productionTracker]="productionTracker" [editable]="editable"></app-blank-info>
        </div>
        <div class="tab-pane fade" id="printers-section" role="tabpanel" aria-labelledby="printers-section-list">
            <app-printer-info [productionTracker]="productionTracker" [salesOrder]="salesOrder" [editable]="editable"></app-printer-info>
        </div>
        <div class="tab-pane fade" id="styles-section" role="tabpanel" aria-labelledby="styles-section-list">Art</div>
        <div class="tab-pane fade" id="samples-section" role="tabpanel" aria-labelledby="samples-section-list">
          <!-<app-sample-info [productionTracker]="productionTracker" [editable]="editable"></app-sample-info>->
        </div>
        <div class="tab-pane fade" id="trims-section" role="tabpanel" aria-labelledby="trims-section-list">
          <!-<app-trim-info [productionTracker]="productionTracker" [editable]="editable"></app-trim-info>->
        </div>
      </div>
    </div>
  </div>
</div>-->
<!--<button type="button" (click)="display(productionTrackerForm)">Display Form</button>-->
</div>
<div *ngIf="!salesOrder || !productionTracker">
  <p>Production Tracker not available for this Sales Order</p>
</div>


<!--</form>-->
