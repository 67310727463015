import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductAttachment } from 'src/app/shared/model/product.model';

@Component({
  selector: 'app-attachment-viewer-dialog',
  templateUrl: './attachment-viewer-dialog.component.html',
  styleUrls: ['./attachment-viewer-dialog.component.css']
})
export class AttachmentViewerDialogComponent implements OnInit {
  attachment:ProductAttachment=new ProductAttachment;
  constructor(public dialogRef: MatDialogRef<AttachmentViewerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
  }
}
