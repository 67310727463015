import { OrderDetail } from './order-detail.model';
import { SalesOrderActivity } from './sales-order-activity.model';
import { SampleShipmentDetail } from './sample-shipment-detail.model';
import { ProductAttachment } from '../product.model';
import { User } from '../user.model';

export class SalesOrder {
   id: string;
   orderType:string;
   salesOrderNumber: string;
   customerId: string;
   orderCreatedDate: Date;
   orderInitialSubmitDate: Date;
   salesRepName: string;
   philcosPoNumber: string;
   totalUnits: number;
   totalSampleUnits: number;
   totalCost: number;
   customerName: string;
   customerDepartment: string;
   customerBuyerName: string;
   customerPoNumbers: string;
   orderShipDate: Date;
   orderCancelDate: Date;
   createdBy: string;
   orderDetails: OrderDetail[] = [];
   customerPONumberList: string[] = [];
   status:string;
   productionStatus:string;
   productionStatusReason:string;
   productionStatusNote:string;
   currency:string;
   activityNotes: string[] = [];
   sampleShipmentDetailList : SampleShipmentDetail[] = [];

   customerPOAttachments: ProductAttachment[] = [];
   distributionListAttachments: ProductAttachment[] = [];
   
   estimationSpecSheet:ProductAttachment[] = [];
   pnlSpecialityAttachments: ProductAttachment[] = [];
   blanksPOAttachments: ProductAttachment[] = [];
   blanksPackagingAttachments: ProductAttachment[] = [];
   blanksPodAttachments: ProductAttachment[] = [];
   printerPOAttachments: ProductAttachment[] = [];
   printerPackagingAttachments: ProductAttachment[] = [];
   finishingSheetAttachments: ProductAttachment[] = [];
   orderProcessingChecklistAttachments: ProductAttachment[] = [];
   signedBolAttachments: ProductAttachment[] = [];
   invoicesAttachments: ProductAttachment[] = [];
   emailConfirmationAttachments: ProductAttachment[] = [];
   chargeBackCommunicationAttachments: ProductAttachment[] = [];
   customerDiscountsAttachments: ProductAttachment[] = [];
   freightQuotesAttachments: ProductAttachment[] = [];


   salesOrderHistoryExport: ProductAttachment[] = [];
   sharedSalesRepUserNameList:string[];
   sharedSalesRepsUserNames:string;
   productionCoordinatorId: string;
   singlePO: boolean = true;
   singlePoNumber: string;
   coordinatorName: string;
	coordinatorUserName: string;
   // UI Transient
   productionCoordinator: User;
   editable: boolean = false;
   editProductionStatus: boolean =false;
   submissionCount:number;

   flagged:boolean;
}
