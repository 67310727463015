import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { Observable } from "rxjs/index";
import { environment } from 'src/environments/environment';
import { BrandRights } from '../model/brand-rights.model';

@Injectable({
  providedIn: 'root'
})

export class BrandRightsService {
   readonly baseUrl = environment.apiEndPoint;

   constructor(private http: HttpClient) { }

   getBrandRightsList(): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/brandRights/list', null);
   }

   createOrUpdateBrandRightsList(brandRightsList: BrandRights[]): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/brandRights/list/save', brandRightsList);
   }

   getBrandRights(brandRightsId: string): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/brandRights/'+brandRightsId, null);
   }

   createOrUpdateBrandRights(brandRights: BrandRights): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/brandRights/save', brandRights);
   }

   deleteBrandRights(brandRightsId: string): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/brandRights/delete/'+brandRightsId, null);
   }
}
