import { Component, OnInit, Input } from '@angular/core';
import { ProductAttachment } from '../../model/product.model';

@Component({
  selector: 'app-image-with-preview',
  templateUrl: './image-with-preview.component.html',
  styleUrls: ['./image-with-preview.component.css']
})
export class ImageWithPreviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() attachment:ProductAttachment;
  @Input() disablePreview:boolean=false;
  @Input() defaultWidth:string='60px';
  @Input() defaultHeight:string='60px';
  fileSignedUrl:string;


  previewAttachment:ProductAttachment;
  previewImage:boolean;
  previewTop:string;
  previewLeft:string;
  previewPosition:string;
  preview(attachment:ProductAttachment,entering:boolean,event?:any){
    if(!this.disablePreview){
    let eventMouse=event as MouseEvent;
    //(eventMouse,eventMouse.pageX,eventMouse.pageY)
    this.previewImage=entering;
    if(entering){
      this.previewTop=eventMouse.clientY+10+'px';
      this.previewLeft=eventMouse.clientX+10+'px';
    this.previewAttachment=attachment;
    }
  }
  }

  handleThumbnailError(productAttachment: ProductAttachment, event) {
    if (isNaN(productAttachment.thumbnailErrorCount)) {
      productAttachment.thumbnailErrorCount = 0;
    }
    event.target.src = productAttachment.fileSignedUrl;
    var x: number = productAttachment.thumbnailErrorCount;
    productAttachment.thumbnailErrorCount = x + 1;
    if (productAttachment.thumbnailErrorCount < 2) {
      event.target.src = productAttachment.fileSignedUrl;
      productAttachment.thumbnailSignedUrl = productAttachment.fileSignedUrl;
    } else {
      event.target.src = "/assets/default-image.jpg"
    }

  }

}
