<div class="container-fluid">

   <div class="mt-2" *ngIf="dashboardConfigViewList?.length>0">
      <h3 style=" text-align: center;">{{dashboard?.title}}</h3>
   </div>

   <div  *ngIf="dashboardConfigViewList">

      <div class="mt-2" *ngIf="dashboardConfigViewList">
         <div class="row">
            <ng-container *ngFor="let dashboardConfigView of dashboardConfigViewList; index as index">
               <ng-container *ngIf="dashboardConfigView.dashletType === 'count'">
                  <!-- {{dashboardConfigView.dashletType}} - {{dashboardConfigView.dashletId}} -->
                  <div class="count-dashlet mx-2">
                     <app-dashlet-count [dashletId]="dashboardConfigView.dashletId" [dashletType]="dashboardConfigView.dashletType"></app-dashlet-count>
                  </div>
               </ng-container>
            </ng-container>
         </div>
      </div>

      <div class="mt-2">
         <div class="row">
            <ng-container *ngFor="let dashboardConfigView of dashboardConfigViewList; index as index">
               <ng-container *ngIf="dashboardConfigView.dashletType === 'chart'">
                  <div class="chart-dashlet mx-1 my-2">
                     <app-dashlet-chart [dashletId]="dashboardConfigView.dashletId" [dashletType]="dashboardConfigView.dashletType"></app-dashlet-chart>
                  </div>
               </ng-container>

               <ng-container *ngIf="dashboardConfigView.dashletType === 'table'">
                  <!--[style]="width>600?'width: -webkit-fill-available;max-width:500':'width: -webkit-fill-available;max-width:500'"-->
                  <div class="table-dashlet mx-1 my-2" >
                     <app-dashlet-table [dashletId]="dashboardConfigView.dashletId" [dashletType]="dashboardConfigView.dashletType"></app-dashlet-table>
                  </div>
               </ng-container>
            </ng-container>
         </div>
      </div>


      
   </div>

</div>