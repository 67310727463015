<div class="container">
   <div class="row mt-2 d-flex justify-content-center">
      <h2 class="d-inline">Customers</h2>
      <button type="button" [routerLink]="['/customer/create']" class="btn btn-circle btn-circle-md d-inline ml-4" *ngIf="userAccess?.canCreateCustomers"><i
            class="fas fa-plus fa-lg text-light"></i></button>
   </div>

   <hr>
   <div class="row">
      <div class="col-sm-6">
         <input id='filterInput' class="form-control mb-2" #search placeholder="Filter by Customer Name.." (keyup)="onKeyupCustomerNameFilter($event,search.value)">
      </div>
      <div class="col-sm-6 mt-2">
         Found {{filteredItemList.length}}
         {{search.value.length>0? 'Customers found for "'+search.value+'"' : 'Customers'}}
      </div>
   </div>

   <!-- GROUPED DISPLAY -->

   <div *ngFor="let groupedItems of groupedItemsList;index as l">
      <h5> {{groupedItems.key}} </h5>
      <hr style="margin-top: 0rem">
      <div class="row gallery-block cards-gallery mt-4">
         <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-4" *ngFor="let item of groupedItems.items;index as i">
            <div class=" card border-0 transform-on-hover h-100 color-2">
               <a [routerLink]="['/customer/view/'+item.id]">
                  <div class="card-body" style="padding: 0.25rem;">
                     <div class=" ml-1 mr-1">
                        <!--<div class=" d-flex justify-content-left">
                           <button class="btn bnt-lg text-white bg-dark">{{item.name}}</button>
                        </div>-->
                        <div class="mb-3 mt-2">
                           <h5 class="text-truncate ml-2 mr-2 card-text justify-content-center">
                              {{item.customerName}}
                           </h5>
                        </div>
                        <div class="text-truncate">Territory: {{item.territory}}</div>
                     </div>
                     <p class="card-text">{{item.status?'Active':'Inactive'}}</p>

                  </div>
               </a>
               <div class="d-flex justify-content-center">
                  <button class="btn  btn-circle btn-circle-sm d-inline" (click)="deleteCustomer(item)" *ngIf="userAccess.canDeleteCustomers">
                     <i class="fas fa-trash text-light"></i>
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>


   <hr>

   <div *ngIf="userAccess?.canViewProductionCoordinatorCustomerAssignment" class="mt-4 pb-3">
      <div class="row">
         <div class="col-sm-5">
            <input id='filterInput2' name="filterInput2" #search2 class="form-control mb-2" placeholder="Filter by Production Cordinator Name, Customer Name.." (keyup)="onKeyupProductionCoordinatorFilter($event,search2.value)">
         </div>

         <div class="col-sm-5">
            <label class="col-sm-4 text-right">Sort By:</label>
            <select class="form-control form-control-sm custom-select col-sm-6 mr-1" id="sortBy" name="sortBy" [(ngModel)]="selectedSortByName" (change)="onSortColumnChange($event.target.value)">
               <option *ngFor="let sortColumn of sortColumns" [ngValue]="sortColumn.sortByName">
                  {{sortColumn.columnName}}</option>
            </select>
            <span (click)="sortAsc()" *ngIf="selectedSortByName && sortMode==='asc'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1" style="color:black; vertical-align: middle;"></i></span>
            <span (click)="sortDesc()" *ngIf="selectedSortByName && sortMode==='asc'"><i class="fas fa-sort-amount-down fa-lg mr-1" style="color:grey; vertical-align: middle;"></i></span>
            <span (click)="sortAsc()" *ngIf="selectedSortByName && sortMode==='desc'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1" style="color:grey; vertical-align: middle;"></i></span>
            <span (click)="sortDesc()" *ngIf="selectedSortByName && sortMode==='desc'"><i class="fas fa-sort-amount-down fa-lg" style="color:black; vertical-align: middle;"></i></span>
   
         </div>

         <div class=" col d-flex justify-content-end" style="align-self: center;" *ngIf="(userAccess?.canViewProductionCoordinatorCustomerAssignment || userAccess?.isSupportAdmin)">
            <button type="button" class="btn btn-sm btn-warning" (click)="createProductionCoordinatorAccountDetailsExcel()"><i class="fas fa-file-download"></i> &nbsp;Export</button>
         </div>

      </div>

      <table class="table table-condensed table-sm customtablehover">
         <thead class="thead-dark">
            <tr class="f-12">
               <th style="width: auto" class=" align-middle text-center text-nowrap border">Customer</th>
               <th style="width: auto;" class=" align-middle text-center text-nowrap border">Production Coordinator</th>
               <th style="width: auto;" class=" align-middle text-center text-nowrap border">Sales Rep(s)</th>
            </tr>

         </thead>
         <tbody>
            <ng-container *ngFor="let customer of filteredItemList;">
               <tr class="f-12">
                  <td style="width: auto;" class="align-middle text-left text-nowrap pl-3">{{customer?.customerName}}</td>
                  <td style="width: auto;" class="align-middle text-left text-nowrap pl-3">{{customer?.productionCoordinator?.firstName}} {{customer?.productionCoordinator?.lastName}}</td>
                  <td style="width: auto;" class="align-middle text-left text-nowrap pl-3">
                     <ng-container *ngFor="let customerSalesPerson of customer?.customerSalesPersonList; index as i; first as isFirst; last as isLast;">
                        {{customerSalesPerson?.salesPerson?.firstName}} {{customerSalesPerson?.salesPerson?.lastName}}<span *ngIf="!isLast">,</span>
                     </ng-container>
                  </td>
               </tr>
            </ng-container>
         </tbody>
      </table>
   </div>


</div>

<div class="floatBtn d-flex flex-column">
   <button type="button" class="btn btn-circle bg-secondary mb-2" matTooltip="Scroll To Top" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'});" matTooltipPosition="left"
      matTooltipClass="bg-dark">
      <i class="fas fa-arrow-up fa-2x" style="color:white"></i>
   </button>
</div>