import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SalesPreProTrackerView } from 'src/app/shared/model/sales/prePro/sales-pre-pro-tracker-view.model';

@Injectable({
  providedIn: 'root'
})
export class SalesPreProTrackerService {

   constructor(private http: HttpClient) { }

   readonly rooturl = environment.apiEndPoint;

   getSalesOrderPreProTrackerDetails(salesPreProTrackerView: SalesPreProTrackerView) {
      return this.http.post<any>(this.rooturl + '/salesPreProTracker/sales/printer/prepro/details/get', salesPreProTrackerView);
   }
   
   saveSalesOrderPreProTrackerDetails(salesPreProTrackerView: SalesPreProTrackerView) {
      return this.http.post<any>(this.rooturl + '/salesPreProTracker/sales/printer/prepro/details/save', salesPreProTrackerView);
   }
}
