export class SizeOrderDetail {
   id: string;
   parentId: string;
   size: string;
   quantity: number;
   overrideSize: string;
   sizeOverrideFlag: boolean;
   prepackQuantity: number;
   constructor(){
      this.id = null;
      this.parentId = null;
      this.size = null;
      this.quantity = null;
      this.overrideSize = null;
      this.sizeOverrideFlag = false;
      this.prepackQuantity = null;
   }
}
