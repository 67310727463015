import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/service/auth.service';
import { Router, ActivatedRoute } from '@angular/router'
import { User } from 'src/app/shared/model/user.model';
import { UserManagementService } from 'src/app/shared/service/user-management.service';
import { ToastrService } from 'ngx-toastr';

@Component({
   selector: 'app-change-password',
   templateUrl: './change-password.component.html',
   styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {
   user: User;
   tokenUser: User;
   resetPasswordToken: string = null;
   isResetPassword: boolean = false;

   constructor(private authService: AuthService, private router: Router, private activatedRoute: ActivatedRoute,
      private userManagementService: UserManagementService, private toastrService: ToastrService) { }

   ngOnInit() {
      this.user = new User();
      this.getResetPasswordToken();
      this.getUserByToken();

   }

   getUserByToken() {
      this.userManagementService.getUserByToken(this.resetPasswordToken).subscribe(response => {
         this.tokenUser = response.responsePayload;
         if (this.tokenUser === null) {
            this.toastrService.error('Invalid Reset Token. Please contact Administrator.', '', { progressBar: true });
            this.router.navigate(['login']);
         }
      });
   }

   onFormSubmit() {
      if (this.resetPasswordToken != null) {
         if (this.user.password !== this.user.confirmpassword) {
            this.toastrService.error("Password and Confirm Password doesn't matches.");
            return;
         } else if (this.tokenUser == null) {
            console.error('Token does not exists in database.')
            this.toastrService.error('Token does not exists in database.');
            return;
         }
         else if (this.user.email != this.tokenUser.email) {
            console.error("Email/UserName and Token Doesn't Match");
            this.toastrService.error("Email is invalid.");
            return;
         } else {
            this.resetPassword();
         }
      }
   }

   resetPassword() {
      this.user.resetPasswordToken = this.resetPasswordToken;
      this.user.userName = this.user.email;
      this.user.password = this.authService.encryptPassword(this.user.password);
      this.user.confirmpassword = this.authService.encryptPassword(this.user.password);
      this.authService.resetpassword(this.user).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.router.navigate(['login']);
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      }, error => {
         console.error(error);
         this.toastrService.error(error);
      });
   }


   getResetPasswordToken(): string {
      const activatedRoutePath = this.activatedRoute.snapshot.routeConfig.path;
      const resetPwdToken = this.activatedRoute.snapshot.paramMap.get('resetPwdToken');
      if (activatedRoutePath.includes('resetpassword') || activatedRoutePath.includes('changepassword')) {
         this.isResetPassword = true;
         this.resetPasswordToken = resetPwdToken;
         return resetPwdToken;
      } else {
         this.isResetPassword = false;
         return null;
      }
   }

}
