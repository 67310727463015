<form #LicensorForm="ngForm" (ngSubmit)="createOrUpdateLicensor()" autocomplete="off" #formVar="ngForm" novalidate>

   <div class="container">

      <div class="d-flex justify-content-center mt-3">
         <div class="text-color-{{licensor.licensorStatus+'' | replace:' ':'_'|lowercase}}">
            <h2>{{licensor.licensorName}}&nbsp;</h2>
         </div>
         <div></div>
      </div>
      <div *ngIf="licensor.licensorStatus!==undefined"
         class="separator-{{licensor.licensorStatus+'' | replace:' ':'_'|lowercase}}">
         <h6>{{licensor.licensorStatus?'Active':'Inactive'}}</h6>
      </div>

      <div class="row  mt-4 shadow-lg p-3 mb-5 bg-white rounded">
         <div class="col-lg-12">
            <mat-tab-group><!-- animationDuration="0ms"-->

               <!-- LICENSOR DETAILS -->
               <mat-tab>
                  <ng-template mat-tab-label>
                     <h6>Licensor <span class="ml-2 font-weight-normal" *ngIf="LicensorForm.invalid">
                        <i class="fa fa-exclamation-triangle fa-xs" style="color:red" aria-hidden="true"></i></span></h6>
                  </ng-template>
                  <div class="overflow-hidden">
                  <!-- TAB LICENSOR DETAILS -->
                  <div class="row mt-4 ml-10">
                   <!--  
                     <div class="col-lg-12">
                        <div class="mb-0">
                           <h5>Licensor Details</h5>
                        </div>
                        <hr style="margin-top: 0rem">
                     </div>
                  -->
                     <div class="col-lg-3">
                        <div class="form-group">
                           <label>Licensor Name</label>
                           <input name="licensorName" [(ngModel)]="licensor.licensorName" class="form-control form-control-sm"
                              #licensorName="ngModel" required [disabled]="!editable"
                              [ngClass]="{'is-invalid':licensorName.touched && licensorName.errors}" maxlength="40">
                           <div *ngIf="licensorName?.invalid && (licensorName.dirty || licensorName.touched)"
                              class="invalid-feedback">
                              <div *ngIf="licensorName.errors.required">
                                 Licensor Name is required.
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-2">
                        <div class="form-group">
                           <label>Licensor Status</label>
                           <select class="form-control form-control-sm" [(ngModel)]="licensor.licensorStatus"
                              name="LicensorStatus" #LicensorStatus="ngModel" required [disabled]="!editable" (change)="onLicensorStatusChange()"
                              [ngClass]="{'is-invalid':LicensorStatus.touched && LicensorStatus.errors}">
                              <option [ngValue]=false>Inactive</option>
                              <option [ngValue]=true>Active</option>
                           </select>
                           <div
                              *ngIf="!viewAction && LicensorStatus?.invalid && (LicensorStatus.dirty || LicensorStatus.touched)"
                              class="invalid-feedback">
                              <div *ngIf="LicensorStatus.errors.required">
                                 Licensor Status is required.
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-2">
                        <div class="form-group">
                           <label>Licensor Color</label>
                           <input [(colorPicker)]="licensor.licensorColor" [(ngModel)]="licensor.licensorColor"
                              [style.background]="licensor.licensorColor" [cpPosition]="'right'"  cpDialogDisplay="popup"
                               name="licensorColor" class="form-control form-control-sm"
                              required [disabled]="!editable" #licensorColor="ngModel"  />
                           <div *ngIf="licensorColor?.invalid && (licensorColor.dirty || licensorColor.touched)"
                              class="invalid-feedback">
                              <div *ngIf="licensorColor.errors.required">
                                 Licensor Color is required.
                              </div>
                           </div>
                        </div>
                     </div>
                     
                     <div class="col-lg-2">
                        
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-lg-7">
                        <div class="form-group">
                           <label>Comments</label>
                           <textarea name="licensorComments" [(ngModel)]="licensor.comments" [rows]="getEnterCount(licensorComments.value,5)"
                              class="form-control form-control-sm"  #licensorComments="ngModel" [disabled]="!editable"
                              maxlength="1500"></textarea>
                        </div>
                     </div>
                     
                  </div>
               </div>
               </mat-tab>

               <!-- TAB CONTACT DETAILS -->
               <mat-tab>
                  <ng-template mat-tab-label>
                     <h6>Contact</h6><span class="badge badge-pill badge-dark ml-2">{{licensor.groupContactDetails?.length}}</span>
                  </ng-template>

                  <div class="overflow-hidden">

                     <div class="row mt-4">
                        <div class="col-lg-12" *ngIf="editable">
                           <div class="row mt-2 ml-1 d-flex justify-content-left" >
                              <h6 class="d-inline">Group Contact</h6>
                              <button type="button" (click)="addGroupContact()" 
                              class="btn btn-circle btn-circle-sm d-inline ml-2" 
                              matTooltip="Create New Group Contact" matTooltipPosition="right" matTooltipClass="bg-dark">
                              <i class="fas fa-plus fa-1x text-light"></i></button>
                           </div>
                           <hr style="margin-top: 0.5rem">
                        </div>
                     </div>

                  <div id="accordion">
                     <ng-container *ngFor="let groupContactDetail of licensor.groupContactDetails; index as groupContactDetailIndex">

                        <div class=" accordion f-12 mt-2" [attr.id]="'contactDetailsId'+groupContactDetailIndex">
                           <div class="card">
                              <div class="card-header p-0 bg-dark"    id="'contactDetailsHeader'+groupContactDetailIndex">
                                 <!-- #1BDDB1 32B0FC  097ec4  cadetblue lightslategrey style="background-color:rgba(0,0,0, 0.6);"   style="background-color:cornflowerblue;"-->
                                 
                                 <div class=" row d-flex d-inline justify-content-between">
                                    <div class="mb-0 col-11 d-flex d-inline" data-toggle="collapse" [attr.data-target]="'#contactDetails'+groupContactDetailIndex" 
                                    aria-expanded="true" [attr.aria-controls]="'collapse'+groupContactDetailIndex">
                                       <span class=" btn text-white text-left" type="button" >
                                          {{!editable?groupContactDetail.location:'Description - &nbsp;'}}
                                       </span>
                                       <div class="d-flex justify-content-center align-items-center" *ngIf="editable" (click)="$event.stopPropagation()">
                                          <input name="groupContactLocation_{{groupContactDetailIndex}}" [(ngModel)]="groupContactDetail.location" maxlength="50" class="form-control form-control-sm "
                                             #groupContactLocationVar="ngModel" [ngClass]="{'is-invalid':groupContactLocationVar.touched && groupContactLocationVar.errors}" required [disabled]="!editable">
                                          <div *ngIf="groupContactLocationVar?.invalid && (groupContactLocationVar.dirty || groupContactLocationVar.touched)" class="invalid-feedback">
                                             <div class="text-white ml-2" *ngIf="groupContactLocationVar.errors.required">Address Description is required.</div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-end col">
                                       <span class="ml-1 mr-4 text-white" style="cursor: pointer;"
                                          (click)="deleteGroupContactDetails(groupContactDetail)" *ngIf="editable"><i
                                       class="fas fa-trash fa-1x"></i></span>
                                    </div>
                                 </div>
                              </div>
            
                              <div [attr.id]="'contactDetails'+groupContactDetailIndex" [class]="groupContactDetailIndex===0?' collapse show p-2':'collapse p-2'" [attr.aria-labelledby]="'contactDetailsHeader'+groupContactDetailIndex" data-parent="#accordion">

                                 <!--<div class="row form-group ml-1" *ngIf="editable">
                                    <label class="col-2 mt-1">Address Description </label>
                                    <div class="col-6">
                                       <input name="groupContactLocation_{{groupContactDetailIndex}}" [(ngModel)]="groupContactDetail.location" maxlength="50" class="form-control form-control-sm"
                                          #groupContactLocationVar="ngModel" [ngClass]="{'is-invalid':groupContactLocationVar.touched && groupContactLocationVar.errors}" required [disabled]="!editable">
                                       <div *ngIf="groupContactLocationVar?.invalid && (groupContactLocationVar.dirty || groupContactLocationVar.touched)" class="invalid-feedback">
                                          <div *ngIf="groupContactLocationVar.errors.required">Address Description is required.</div>
                                       </div>
                                    </div>
                                 </div>-->
                                 <div *ngIf="editable">

                                    <div class="row f-12 mt-2" *ngIf="editable">
                                       <div class="col-lg-6 col-md-6 col-sm-6">
                                             <div class="card-body ml-4" style="padding: 0.25rem;">
                                                <label class="mb-0 label-medium">Company Name</label>
                                                <input name="groupContactCompanyName_{{groupContactDetailIndex}}" [(ngModel)]="groupContactDetail.companyName" maxlength="50" class="form-control form-control-sm "
                                                   #groupContactCompanyNameVar="ngModel">
                                             </div>
                                       </div>
                                    </div> 
                                    <hr>

                                    <div class="row f-12 mt-2" *ngIf="groupContactDetail.addressList?.length>0 || editable">
                                       <div class="col-lg-12 col-md-12 col-sm-12">
                                          <div class="card border-0">
                                                <div class="card-body" style="padding: 0.25rem;" *ngIf="groupContactDetail.addressList?.length>0 || editable">
                                                   <app-address [addressList]="groupContactDetail.addressList" [editable]="editable" [multipleAddress]="false" [contactType]="groupContactDetail.type" [showAddressType]='false'></app-address>
                                                </div>
                                          </div>
                                       </div>
                                    </div>
                                    <hr>
                                    <div class="row f-12" *ngIf="groupContactDetail.phoneList?.length>0 || editable">
                                       <div class="col-lg-12 col-md-12 col-sm-12">
                                          <div class="card border-0">
                                             <div class="card-body" style="padding: 0.25rem;" *ngIf="groupContactDetail.phoneList?.length>0 || editable">
                                                <app-phone [attr.id]="'groupContactDetailIndex'" [phoneList]="groupContactDetail.phoneList" [editable]="editable" [multiplePhones]="true" [contactType]="groupContactDetail.type"></app-phone>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <hr>
                                    <div class="row f-12" *ngIf="groupContactDetail.emailList?.length>0 || editable">
                                       <div class="col-lg-12 col-md-12 col-sm-12">
                                          <div class="card border-0">
                                             <div class="card-body" style="padding: 0.25rem;" *ngIf="groupContactDetail.emailList?.length>0 || editable">
                                                <app-email [attr.id]="'groupContactDetailIndex'" [emailList]="groupContactDetail.emailList" 
                                                [editable]="editable" [multipleEmails]="true" [contactType]="groupContactDetail.type"></app-email>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <hr>
                                 </div>
                                 
                                 <div class="row">
                                    <!--<div class="col-lg-12" *ngIf="editable">
                                       <div class="row mt-2 ml-1 d-flex justify-content-left" >
                                          <h5 class="d-inline">Contacts</h5>
                                          <button type="button" (click)="addContactDetail(groupContactDetail)" 
                                          class="btn btn-circle btn-circle-sm d-inline ml-2" 
                                          matTooltip="Create New Contact" matTooltipPosition="right" matTooltipClass="bg-dark">
                                          <i class="fas fa-plus fa-1x text-light"></i></button>
                                       </div>
                                       <hr style="margin-top: 0.5rem">
                                    </div>-->
                                    <div class="col-lg-4 col-md-12 border-right" *ngIf="!editable">
                                       
                                          <div class="ml-2">
                                             <div class="card border-0">
                                                <div *ngIf="groupContactDetail.companyName">
                                                   <p class="card-text f-14 font-weight-bold ml-2 mb-0 text-wrap">{{groupContactDetail.companyName}}</p>
                                                </div>
                                                <div *ngIf="groupContactDetail.addressList?.length>0">
                                                   <ng-container *ngFor="let address of groupContactDetail.addressList">
                                                      <!-- <p class="card-text f-14 font-weight-bold ml-2 mb-0 text-wrap">{{address.addressType}}</p> -->
                                                      <p class="card-text ml-2 mb-0 text-wrap">
                                                         <span *ngIf="address.apartmentNumber">{{address.apartmentNumber}} - </span>
                                                         <span *ngIf="address.streetNumber">{{address.streetNumber}} </span>
                                                         <span *ngIf="address.streetName">{{address.streetName}} </span>
                                                      </p>
                                                      <p class="card-text ml-2 mb-0 text-wrap">
                                                         <span *ngIf="address.city">{{address.city}} </span>
                                                         <span *ngIf="address.provinceName">{{address.provinceName}} </span>
                                                      </p>
                                                      <p class="card-text ml-2 mb-0 text-wrap">
                                                         <span *ngIf="address.countryName">{{address.countryName}}</span>
                                                         <span *ngIf="address.postalCode"> - {{address.postalCode}}</span>
                                                      </p>
                                                   </ng-container>
                                                </div>
                                                <div *ngIf="groupContactDetail.emailList?.length>0">
                                                   <ng-container *ngFor="let email of groupContactDetail.emailList">
                                                      <div>
                                                         <span class="card-text ml-2 mb-0 text-wrap">{{email.emailType}}  : </span>
                                                         <span class="card-text ml-2 mb-0 text-truncate">{{email.emailAddress}}</span>
                                                      </div>
                                                   </ng-container>
                                                </div>
                                                <div *ngIf="groupContactDetail.phoneList?.length>0">
                                                   <ng-container *ngFor="let phone of groupContactDetail.phoneList">
                                                      <div>
                                                         <span class="card-text ml-2 mb-0 text-wrap">{{phone.phoneType}}  : </span>
                                                         <span *ngIf="phone.countryCode">{{phone.countryCode}} </span>
                                                         <span *ngIf="phone.phoneNumber">{{phone.phoneNumber}} </span>
                                                         <span *ngIf="phone.phoneExtension">{{phone.phoneExtension}} </span>
                                                      </div>
                                                   </ng-container>
                                                </div>
                                                
                                             </div>
                                          
                                       </div>
                                       
   
                                    </div>
                                    <div class="col-{{editable?'12':'8'}}">
                                       <div class="row">
                                          <ng-container *ngFor="let details of groupContactDetail.contactDetailList">
                                          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 mb-1">
                                             <div class="card mb-2 h-100 {{editable?'':'border-0'}}">
                                                <div>
                                                   <div class="py-0" style="padding: .3rem .3rem;" *ngIf="editable">
                                                      <div class="row ">
                                                         <div class="col-4 d-flex justify-content-start">
                                                            <span class="btn btn-sm mr-1" style="cursor: pointer;"
                                                               (click)="deleteContactDetails(groupContactDetail,details)" *ngIf="editable"><i
                                                                  class="fas fa-trash fa-1x "></i></span>
                                                            <span class="btn btn-sm" style="cursor: pointer;"
                                                               (click)="viewOrEditContactDetail(groupContactDetail,details)" *ngIf="editable && details.id"><i
                                                                  class="fas fa-pencil-alt "></i></span>
                                                            <span class="btn btn-sm " style="cursor: pointer;"
                                                               (click)="viewOrEditContactDetail(groupContactDetail,details)" *ngIf="!editable"><i
                                                                  class="fas fa-expand-alt "></i></span>
                                                         </div>
                                                         <div class="col-8 text-truncate" style="align-self: center;">
                                                            &nbsp;
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div class="card-body" style="padding: 0.25rem;">
                                                      <p class="card-text ml-2 mb-0 f-14 font-weight-bold text-truncate">{{details.profile?.title}} {{details.profile?.firstName}}<span> {{details.profile?.middleName}}</span> {{details.profile?.lastName}}</p>
                                                      <div class=" ml-2 text-truncate text-muted" data-toggle="tooltip" data-placement="top" title="{{details.profile?.contactDetailsType}}">
                                                         {{details.profile?.contactDetailsType}} 
                                                         <span *ngIf="details.profile?.jobTitle">&nbsp;| {{details.profile?.jobTitle}}</span>
                                                      </div>
                                                      <span class="ml-2" *ngIf="details.profile?.submissionSheetReviewer">Submission Sheet Reviewer</span>
                                                      <div class="card-
                                                      text ml-2 text-wrap"><i class="fa fa-tag"></i>
                                                         <span *ngFor="let brand of details.profile?.associatedBrands;index as i">
                                                            &#8226;&nbsp;{{brand.brandName}}
                                                         </span>
                                                      </div>
                                                      <div *ngIf="details.phoneList?.length>0">
                                                         <p class="card-text ml-2 mb-0 text-truncate" *ngFor="let phone of details.phoneList">{{phone.phoneNumber}}</p>
                                                      </div>
                                                      <div *ngIf="details.emailList?.length>0">
                                                         <p class="card-text ml-2 mb-0 text-truncate" *ngFor="let email of details.emailList">{{email.emailAddress}}</p>
                                                      </div>
                                                      
                                                   </div>
                                                </div>
                                             </div>
                                             
                                          </div>
                                          </ng-container>
                                          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 mb-1"  *ngIf="editable">
                                             <div class="card h-100 mb-2 " style="background: #6c757d1a;" >
                                                <div class="card-body d-flex align-items-center justify-content-center" style="padding: 0.25rem;">
                                                   <button type="button" (click)="addContactDetail(groupContactDetail)" 
                                                   class="btn btn-sm" 
                                                   matTooltip="Create New Contact" matTooltipPosition="right" matTooltipClass="bg-dark">
                                                   <i class="fas fa-plus fa-2x"></i></button>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    
                                 </div>
            
                                 
                              </div>
                           </div>
                        </div>

                     </ng-container>
                  </div>

                  </div>
               </mat-tab>

               <!-- TAB Link DETAILS -->
               <mat-tab>
                  <ng-template mat-tab-label>
                     <h6>Link</h6> <span class="badge badge-pill badge-dark ml-2">{{licensor.linkDetails.length}}</span>
                  </ng-template>

                  <div class="overflow-hidden">
                     <div class="mt-4">
                        <div class="mb-1 justify-content-left">
                           <button type="button" *ngIf="editable" class="btn btn-info btn-sm mt-1" (click)="addLinkDetail()">+ Add</button>
                        </div>
                        <table class="table table-condensed table-sm table-hover">
                           <thead class="thead-dark">
                              <tr class="f-12">
                                 <th rowspan="2" style="width:5%;" *ngIf="editable" class="align-middle text-center text-nowrap  border">Actions</th>
                                 <th rowspan="2" style="width: 15%;" class=" align-middle text-center text-nowrap border">Link Type</th>
                                 <th rowspan="2" style="width: 35%;" class=" align-middle text-center text-nowrap border">Associated Brands</th>
                                 <th rowspan="2" style="width: 15%;" class=" align-middle text-center text-nowrap border">Link</th>
                                 <th colspan="3" style="width: 30%;" class=" align-middle text-center text-nowrap border">Credential</th>
                              </tr>
                              <tr class="f-12">
                                 <th style="width: 12%;" class=" align-middle text-center text-nowrap border">Username</th>
                                 <th style="width: 12%;" class=" align-middle text-center text-nowrap border">Password</th>
                                 <th style="width: 6%;" class=" align-middle text-center text-nowrap border" *ngIf="editable">Actions</th>
                              </tr>
                           </thead>
                  
                           <tbody>
                              <ng-container *ngFor="let linkDetails of licensor.linkDetails; index as linkDetailsIndex;">
                                 <tr class="f-12">
                                    <td style="white-space: nowrap; width: 5%;" class="text-center text-nowrap pt-2" *ngIf="editable">
                                       <a class="btn" style="border: 0px;" class="ml-1 mr-1" (click)="deleteLinkDetails(linkDetails)" >
                                          <i class="fas fa-trash fa-1x" style="color: #dc3545;cursor: pointer;"></i></a>
                                    </td>
                  
                                    <td *ngIf="!editable" style="width: auto;" class="text-left text-nowrap pt-2 pl-1">{{linkDetails.linkDetailsType}}</td>
                                    <td *ngIf="editable" style="width: auto;" class="text-left text-nowrap pt-2">
                                       <select class="form-control form-control" id="linkDetailsType_{{linkDetailsIndex}}" [(ngModel)]="linkDetails.linkDetailsType" name="linkDetailsType_{{linkDetailsIndex}}"
                                          #linkType="ngModel" required #linkTypeVar=ngModel [ngClass]="{'is-invalid':linkTypeVar.touched && linkTypeVar.errors}" style="font-size: 11px;">
                                          <option *ngFor="let linkType of linkTypeList" [ngValue]="linkType">
                                             {{linkType}}
                                          </option>
                                       </select>
                                       <div *ngIf="linkTypeVar?.invalid && (linkTypeVar.dirty || linkTypeVar.touched)" class="invalid-feedback">
                                          <div *ngIf="linkTypeVar.errors.required">Link Type is required.</div>
                                       </div>
                                    </td>
                  
                                    <!--<td *ngIf="!editable" class="text-left pl-3 pt-2">
                                       <ng-container *ngIf="linkDetails.associatedBrands?.length > 0"><i class="fas fa-tag"></i>&nbsp;</ng-container>
                                       <ng-container *ngFor="let brand of linkDetails.associatedBrands;">
                                          &#8226;&nbsp;{{brand.brandName}}
                                       </ng-container>
                                    </td>-->
                                    <td  style="width: 35%;">
                                      
                                       <div class="row">
                                          <div class="col-lg-6" *ngFor="let brand of linkDetails.associatedBrands;index as associatedBrandIndex">
                                             <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input ml-1" name="linkBrandName_{{linkDetailsIndex}}_{{associatedBrandIndex}}"
                                                   id="linkBrandName_{{linkDetailsIndex}}_{{associatedBrandIndex}}" [checked]="true" [disabled]="!editable"
                                                   (click)="removeBrandSelection(brand, linkDetails, linkDetailsIndex)">
                                                <label class="custom-control-label mr-2 text-wrap py-1" for="linkBrandName_{{linkDetailsIndex}}_{{associatedBrandIndex}}">
                                                   {{brand.brandName}}
                                                </label>
                                             </div>
                                          </div>
                                       </div>  
                                       <div class="row">
                                          <div class="col-lg-4 col-sm-6 col-md-6">
                                             <button *ngIf="editable" type="button" class=" p-0 btn btn-sm color-3 btn-block" (click)="openBrandSelectionModal(linkDetails)"><i class="fas fa-plus fa-xs"></i>
                                             </button>
                                          </div>
                                       </div>
                                       <div class="mt-2" *ngIf="!linkDetails.associatedBrands || (linkDetails.associatedBrands && linkDetails.associatedBrands.length===0)">
                                          <div>No Brand Association Available.</div>
                                       </div>
                                    </td>
                  
                                    <td *ngIf="!editable" class="text-left text-wrap pl-3 pt-2">
                                       <a href='{{linkDetails.url}}' target="_blank" *ngIf="linkDetails.url">Click Link</a>
                                    </td>
                                    <td *ngIf="editable" class="text-left pl-2 pr-2 pt-2">
                                       <input name="url_{{linkDetailsIndex}}" [(ngModel)]="linkDetails.url" class="form-control form-control" url="ngModel" required #urlVar="ngModel" required
                                          [ngClass]="{'is-invalid':urlVar.touched && urlVar.errors}" style="font-size: 11px;">
                                       <div *ngIf="urlVar?.invalid && (urlVar.dirty || urlVar.touched)" class="invalid-feedback">
                                          <div *ngIf="urlVar.errors.required">URL is required.</div>
                                       </div>
                                    </td>
                  
                                    <td colspan="3">
                                       <table width="100%" class="table table-condensed table-sm mb-0 table-striped">
                                          <tbody>
                                          <ng-container *ngFor="let linkDetailsCredential of linkDetails.linkDetailsCredentialsList; index as j">
                                             <tr>
                                                <td *ngIf="!editable" style="width: 40%;" class="align-middle text-left text-nowrap pl-3">{{linkDetailsCredential.username}}</td>
                                                <td *ngIf="editable" style="width: 40%;" class="align-middle text-left text-nowrap pl-2 pr-2 ">
                                                   <input type="text" name="username_{{linkDetailsIndex}}_{{j}}" class="form-control form-control-sm" [(ngModel)]="linkDetailsCredential.username"
                                                      style="font-size: 11px;">
                                                </td>
                  
                                                <td *ngIf="!editable" style="width: 40%;" class="text-nowrap">{{linkDetailsCredential.password}}</td>
                                                <td *ngIf="editable" style="width: 40%;" class="align-middle text-left text-nowrap pl-2 pr-2">
                                                   <input type="text" name="password_{{linkDetailsIndex}}_{{j}}" class="form-control form-control-sm" [(ngModel)]="linkDetailsCredential.password"
                                                      style="font-size: 11px;">
                                                </td>
                  
                                                <td style="white-space: nowrap;width: 20%;" class="align-middle text-center text-nowrap" *ngIf="editable">
                                                   <div class="d-flex d-inline">
                                                      <a class="btn" style="border: 0px;" class="ml-1 mr-1" (click)="deleteLinkCredential(linkDetails,linkDetailsCredential)" >
                                                         <i class="fas fa-trash fa-1x" style="color: #dc3545;cursor: pointer;"></i></a>
                                                   </div>
                                                </td>
                                             </tr>
                                          </ng-container>
                                       
                                          <tr *ngIf="editable">
                                             <td class="border-0">&nbsp;</td>
                                             <td class="border-0">&nbsp;</td>
                                             <td style="white-space: nowrap;width: 6%;" class="align-middle text-center text-nowrap border-0">
                                                <button type="button" *ngIf="editable" class="btn btn-info btn-sm mt-1" (click)="addLinkCredential(linkDetails)">+ Add</button>
                                             </td>
                                          </tr>
                                       </tbody>
                                       </table>
                                    </td>
                                 </tr>
                              </ng-container>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </mat-tab>

               <!-- Sample DETAILS -->
               <mat-tab>
                  <ng-template mat-tab-label>
                     <h6>Sample Details</h6>
                  </ng-template>
                  <div class="overflow-hidden">
               
                     <div class="row">
               
                        <div class="col-3 list-group mb-2 mt-4">
                           <div class="ml-3">
               
                              <div class="mt-1" *ngFor="let licensorSampleDetail of licensor.licensorSampleDetails; let i = index">
                                 <div class="d-flex d-inline">
                                    <a (click)="onLicensorSampleBrandClick(licensorSampleDetail, i)"
                                       class="list-group-item list-group-item-action {{selectSampleDetailsIndex === i?'bg-dark text-white':'bg-default'}}">
                                       {{licensorSampleDetail?.brandName}}
                                    </a>
                                    <div class="d-flex" style="align-items: center;">
                                       <div class="form-check">
                                          <a *ngIf="editable" (click)="deleteLicensorSampleDetail(licensorSampleDetail, i)" class="form-control form-control-sm text-right" style="border: 0px; cursor: pointer;"><i
                                                class="fas fa-trash fa-1x" style="color: #dc3545"></i></a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
               
                              <div *ngIf="editable">
                                 <div class="d-flex d-inline mt-2">
                                    <select class="form-control form-control-sm custom-select" [(ngModel)]="selectedBrandIdForLicensorSample" name="brandVar" [disabled]="!editable" (change)="onBrandChange($event)"
                                       #brandVar="ngModel" [ngClass]="{'is-invalid':brandVar.touched && brandVar.errors}">
                                       <option *ngIf="!anyBrandExists" value="ANY">Default</option>
                                       <option *ngFor="let brand of brandListForLicensorSample" [ngValue]="brand.id">
                                          {{brand.brandName}}</option>
                                    </select>
                                    <div *ngIf="brandVar?.invalid && (brandVar.dirty || brandVar.touched)" class="invalid-feedback">
                                       <div *ngIf="brandVar.errors.required">
                                          Brand is required.
                                       </div>
                                    </div>
                                    <button type="button" [disabled]="!brandVar?.value" class="btn btn-sm btn-info ml-1" (click)="addNewLicensorSampleDetail()">Add</button>
                                 </div>
               
                              </div>
                           </div>
                        </div>
               
                        <!-- Table -->
                        <div class="col-9">
               
                           <div class="row ml-5 mt-4">
                              <h6>{{licensor.licensorSampleDetails[selectSampleDetailsIndex]?.brandName !==null? 'Sample Details for Brand - '+licensor.licensorSampleDetails[selectSampleDetailsIndex]?.brandName:''}}
                              </h6>
                              
                           </div>
                           <hr>
                           <div class=" d-flex ml-5 mt-2">
               
                              <div *ngIf="!editable">
                                 <table *ngIf="licensor.licensorSampleDetails[selectSampleDetailsIndex]?.selectedSampleSizesList?.length > 0" class="table table-sm table-striped table-bordered table-condensed">
                                    <thead class="text-white bg-secondary">
                                       <tr>
                                          <th class="align-middle text-center text-nowrap">Size</th>
                                          <th class="align-middle text-center text-nowrap">Pre Production Qty</th>
                                          <th class="align-middle text-center text-nowrap">Final Production Qty</th>
                                          <th class="align-middle text-center text-nowrap">Photo Sample Qty</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr *ngFor="let licensorSample of licensor.licensorSampleDetails[selectSampleDetailsIndex]?.selectedSampleSizesList; let i = index">
                                          <td class="align-middle text-center text-nowrap">{{licensorSample.size}}</td>
                                          <td class="align-middle text-center text-nowrap">{{licensorSample.preProductionSampleQuantity}}</td>
                                          <td class="align-middle text-center text-nowrap">{{licensorSample.finalSampleQuantity}}</td>
                                          <td class="align-middle text-center text-nowrap">{{licensorSample.photoSampleQuantity}}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
               
                              <div *ngIf="editable">
                                 <table *ngIf="licensor.licensorSampleDetails[selectSampleDetailsIndex]?.allSampleSizesList?.length > 0" class="table table-sm table-striped table-bordered table-condensed">
                                    <thead class="text-white bg-secondary">
                                       <tr>
                                          <th class="align-middle text-center text-nowrap">Size</th>
                                          <th class="align-middle text-center text-nowrap">Pre Production Qty</th>
                                          <th class="align-middle text-center text-nowrap">Final Production Qty</th>
                                          <th class="align-middle text-center text-nowrap">Photo Sample Qty</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr *ngFor="let licensorSample of licensor.licensorSampleDetails[selectSampleDetailsIndex]?.allSampleSizesList; let i = index">
                                          <td class="align-middle text-center text-nowrap"> {{licensorSample.size}}</td>
                                          <td class="align-middle text-center text-nowrap">
                                             <input name="licensorSample.preProductionSampleQuantity{{i}}" [(ngModel)]="licensorSample.preProductionSampleQuantity" required class="form-control form-control-sm"
                                                type="number">
                                          </td>
                                          <td class="align-middle text-center text-nowrap">
                                             <input name="licensorSample.finalSampleQuantity{{i}}" [(ngModel)]="licensorSample.finalSampleQuantity" required class="form-control form-control-sm" type="number" />
                                          </td>
                                          <td class="align-middle text-center text-nowrap">
                                             <input name="licensorSample.photoSampleQuantity{{i}}" [(ngModel)]="licensorSample.photoSampleQuantity" required class="form-control form-control-sm" type="number" />
                                          </td>                                          
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
               
                           </div>
               
                        </div>
               
                        <div class="container h-100 mb-2" *ngIf="licensor.licensorSampleDetails?.length === 0">
                           <div class="row h-100">
                              <div class="col-sm-12">
                                 <div class="w-25 mx-auto">
                                    <p class="text-muted" style="width:max-content"><em>No Licensor Sample Details Available.</em></p>
                                 </div>
                              </div>
                           </div>
                        </div>
               
                     </div>
               
               
               
                  </div>
               
               </mat-tab>


               <mat-tab>
                  <ng-template mat-tab-label>
                     <h6>Files</h6>
                  </ng-template> 
                  <div class="overflow-hidden">
                     <div class="mt-3" *ngIf="userAccess?.canViewLicensorContractFiles">
                        <app-attachment-operations  [header]="'Licensor Contract Files'" [editable]="editable" 
                        [createAction]="createAction" [attachments]="licensor.licensorContractFiles" [canRenameFile]="true"
                        [canUpload]="userAccess?.canUploadLicensorContractFiles" [canDownload]="userAccess?.canDownloadLicensorContractFiles" 
                        [canDelete]="userAccess?.canDeleteLicensorContractFiles" [category]="'LICENSOR_CONTRACT_FILES'" [showHeader]="true"
                        ></app-attachment-operations><!--(onNewUpload)="parsePdfForm()" (onDelete)="attachmentDeleted()"-->
                     </div>
                     <div class="mt-3">
                        <app-attachment-operations  [header]="'Licensor Files'" [editable]="editable" 
                        [createAction]="createAction" [attachments]="licensor.licensorFiles" [canRenameFile]="true"
                        [canUpload]="userAccess?.canUploadLicensorFiles" [canDownload]="userAccess?.canDownloadLicensorFiles" 
                        [canDelete]="userAccess?.canDeleteLicensorFiles" [category]="'LICENSOR_FILES'" [showHeader]="true"
                        ></app-attachment-operations><!--(onNewUpload)="parsePdfForm()" (onDelete)="attachmentDeleted()"-->
                     </div>
                     <div class="mt-3">
                        <app-attachment-operations  [header]="'Artist SignOff Files'" [editable]="editable" 
                        [createAction]="createAction" [attachments]="licensor.licensorArtistSignOffFiles" [canRenameFile]="true"
                        [canUpload]="userAccess?.canUploadLicensorFiles" [canDownload]="userAccess?.canDownloadLicensorFiles" 
                        [canDelete]="userAccess?.canDeleteLicensorFiles" [category]="'LICENSOR_ARTIST_SIGN_OFF_FILES'" [showHeader]="true"
                        ></app-attachment-operations><!--(onNewUpload)="parsePdfForm()" (onDelete)="attachmentDeleted()"-->
                     </div>
                     <div class="mt-3">
                        <app-attachment-operations  [header]="'Factory Forms Files'" [editable]="editable" 
                        [createAction]="createAction" [attachments]="licensor.licensorFactoryFormsFiles" [canRenameFile]="true"
                        [canUpload]="userAccess?.canUploadLicensorFiles" [canDownload]="userAccess?.canDownloadLicensorFiles" 
                        [canDelete]="userAccess?.canDeleteLicensorFiles" [category]="'LICENSOR_FACTORY_FORMS_FILES'" [showHeader]="true"
                        ></app-attachment-operations><!--(onNewUpload)="parsePdfForm()" (onDelete)="attachmentDeleted()"-->
                     </div>                                          
                    
                  </div>
               </mat-tab>

               <mat-tab>
                  <ng-template mat-tab-label>
                     <h6>Trim Details</h6>
                  </ng-template>
                  <div class="overflow-hidden">
                     <table class="table table-condensed table-sm customtablehover mt-4">
                        <thead class="thead-dark">
                           <tr class="f-11">
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Brand</th>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Hangtag</th>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Hologram</th>
                           </tr>

                        </thead>
                        <tbody>
                           <ng-container *ngFor="let brand of licensor?.brands; index as brandIndex">
                              <tr >
                                 <td style="width: auto;" class="align-middle text-center text-nowrap">{{brand.brandName}}</td>
                                 <td style="width: auto;" class="align-middle text-left text-nowrap pl-3">
                                    <div class="d-inline">
                                       <div class="mb-1">
                                          <div class="d-inline ml-1 d-flex align-items-center">
                                             <div class="custom-control custom-checkbox">
                                                <input class="custom-control-input" type="checkbox" name="hangtag_{{brandIndex}}" [(ngModel)]="brand.trimHangtagRequired" [disabled]="!editable" id="hangtag_{{brandIndex}}">
                                                <label class="custom-control-label" for="hangtag_{{brandIndex}}">&nbsp;</label>
                                             </div>
                                             <div>
                                                <input name="trimHangtagName_{{brandIndex}}" [(ngModel)]="brand.trimHangtagName" class="form-control form-control-sm" #trimHangtagName="ngModel" [required]="brand.trimHangtagRequired"
                                                   [disabled]="!editable || (editable && brand.trimHangtagRequired === false)" [ngClass]="{'is-invalid':trimHangtagName.touched && trimHangtagName.errors}" maxlength="40">
                                                <div *ngIf="trimHangtagName?.invalid && (trimHangtagName.dirty || trimHangtagName.touched)" class="invalid-feedback">
                                                   <div *ngIf="trimHangtagName.errors.required">
                                                      Hangtag Name is required.
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>                                   
                                 </td>                                 
                                 <td style="width: auto;" class="align-middle text-center text-nowrap pl-4">
                                    <div class="d-inline">
                                       <div class="mb-1">
                                          <div class="d-inline ml-1 d-flex align-items-center">
                                             <div class="custom-control custom-checkbox">
                                                <input class="custom-control-input" type="checkbox" name="hologram_{{brandIndex}}" [(ngModel)]="brand.trimHologramRequired" [disabled]="!editable" id="hologram_{{brandIndex}}">
                                                <label class="custom-control-label" for="hologram_{{brandIndex}}">&nbsp;</label>
                                             </div>
                                             <div>
                                                <input name="trimHologramName_{{brandIndex}}" [(ngModel)]="brand.trimHologramName" class="form-control form-control-sm" #trimHologramName="ngModel" [required]="brand.trimHologramRequired"
                                                   [disabled]="!editable || (editable && brand.trimHologramRequired === false)" [ngClass]="{'is-invalid':trimHologramName.touched && trimHologramName.errors}" maxlength="40">
                                                <div *ngIf="trimHologramName?.invalid && (trimHologramName.dirty || trimHologramName.touched)" class="invalid-feedback">
                                                   <div *ngIf="trimHologramName.errors.required">
                                                      Hologram Name is required.
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>                                 
                                 </td>
                              </tr>
                           </ng-container>
                        </tbody>
                     </table>
                  </div>
               </mat-tab>
            </mat-tab-group>
            
         </div>
         
      </div>
      
         <div class="row d-flex justify-content-end">
            <div class="col-lg-3">
               <div class="form-group ml-0">
                  <button type="submit" class="btn btn-info btn-block" [disabled]="!LicensorForm.valid" *ngIf="editable && createAction">Submit</button>
                  <!--<button type="submit" class="btn btn-info btn-block"   *ngIf="editable && !createAction"
                                                      [disabled]="!LicensorForm.valid">Save</button>
                                                <button type="button" class="btn btn-dark btn-block" *ngIf="editable && !createAction"
                                                      (click)="cancelEdit()">Cancel</button>-->
               </div>
            </div>
         </div>


      <!-- BRANDS -->
      <div class="row  mt-5 shadow-lg p-3 mb-5 bg-white rounded" *ngIf="licensor.id">
         <div class="col-lg-12">
            <div class="row d-flex justify-content-center">
               <h4 class="d-inline">Brands For {{licensor.licensorName}}</h4>
               <button type="button" [routerLink]="['/brand/create/'+licensor.id]" class="btn btn-circle btn-circle-md d-inline ml-4" *ngIf="userAccess?.canCreateBrand" matTooltip="Create New Brand"
                  matTooltipPosition="right" matTooltipClass="bg-dark"><i class="fas fa-plus fa-lg text-light"></i></button>
            </div>
            <hr>
            <div class="row">
               <div class="col-sm-6">
                  <input class="form-control mb-2" #search placeholder="Filter by Brand Name/SKU..." (keyup)="onKeyupBrandFilter($event,search.value)">
               </div>
               <div class="col-sm-6 mt-2">
                  Found {{filteredBrandList.length}}
                  {{search.value.length>0? 'Brands found for "'+search.value+'"' : 'Brands'}}
               </div>
            </div>
            <div class="row gallery-block cards-gallery mt-4">
               <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-4" *ngFor="let brand of filteredBrandList;index as i">
                  <div class=" card border-0 transform-on-hover h-100" [style.background-color]="brand.licensorColor">
                     <div class="card-body" style="padding: 0.25rem;">
                        <a [routerLink]="['/brand/view/'+brand.id]">
                           <div class=" ml-1 mr-1">
                              <div class=" d-flex justify-content-left">
                                 <button class="btn bnt-lg text-white bg-dark" *ngIf="brand.brandStatus">{{brand.skuCode}}</button>
                                 <button class="btn bnt-lg text-white bg-danger" *ngIf="!brand.brandStatus">{{brand.skuCode}}</button>
                              </div>
                              <div class="mb-3">
                                 <h4 class="text-break  ml-2 mr-2 card-text justify-content-center">{{brand.brandName}}
                                 </h4>
      
                              </div>
                              <!--<div>SKU Code: {{brand.skuCode}}</div>-->
                              <div>Licensor: {{brand.licensorName}}</div>
                           </div>
                           <p class="card-text">{{brand.brandStatus?'Active':'Inactive'}}</p>
                        </a>
                        <div>
                           <button class="btn  btn-circle btn-circle-sm d-inline" (click)="deleteBrand(brand)" *ngIf="editable && userAccess.canDeleteBrand">
                              <i class="fas fa-trash text-light"></i>
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

   </div>
   
   <!-- MODAL for adding / editing contact details. -->
   <div >
      <app-contact-details-modal [editable]="editable" [show]="viewContactDetailsModal" [modalId]="'addEditContactDetailModal'" 
         [addEditContactDetail]="addEditContactDetail" (onSave)="saveAddEditContactDetail()" 
         [showProfile]="true" [showPhone]="true" [showEmail]="true" [showAddress]="false"
         [multipleAddress]="false" [multiplePhones]="true" [multipleEmails]="true"
         [licensorId]="licensor.id" [showAddressType]="false"
         (onCancel)="closeAddEditContactDetail()">
      </app-contact-details-modal>
   </div>

   <!-- Floating Buttons -->

   <div class="floatBtn d-flex flex-column">
      <button type="submit" class="btn btn-circle bg-info mb-2" *ngIf="editable && !createAction"
         matTooltip="Save Changes" matTooltipPosition="left" matTooltipClass="bg-dark" [disabled]="!LicensorForm.valid">
         <i class="fas fa-save fa-2x" style="color:white"></i>
      </button>
      <button type="button" class="btn btn-circle bg-dark" *ngIf="editable && !createAction" matTooltip="Cancel Edit"
         matTooltipPosition="left" matTooltipClass="bg-dark" (click)="cancelEdit()"><i class="fas fa-times fa-2x"
            style="color:white"></i>
      </button>
      <a class="btn btn-circle bg-dark mb-2" (click)="edit()" *ngIf="viewAction && userAccess?.canEditLicensor"
         matTooltip="Edit" matTooltipPosition="left" matTooltipClass="bg-dark">
         <i class="fas fa-pencil-alt fa-lg" style="color: white;"></i>
      </a>
      <a (click)="goBack()" class="btn btn-circle" style="background-color: tomato;" *ngIf="viewAction || createAction"
         matTooltip="Exit" matTooltipPosition="left" matTooltipClass="bg-dark"><i
            class="fas fa-arrow-left  fa-2x"></i></a>
   </div>
   <button id="metadataModalToggler" type="button" style="display: none;" data-toggle="modal" data-target="#addNewMetadataModal"></button>
   <button id="contactDetailModalToggler" type="button" style="display: none;" data-toggle="modal" data-target="#addEditContactDetailModal"></button>
   <button id="linkBrandSelectionModalToggler" type="button" style="display: none;" data-toggle="modal" data-target="#LinkBrandSelectionModal"></button>
   <button id="linkCredentialModalToggler" type="button" style="display: none;" data-toggle="modal" data-target="#LinkCredentialModal"></button>
   
   <div class="modal fade" style="z-index: 1099 !important;" id="LinkBrandSelectionModal" tabindex="-1" role="dialog" aria-labelledby="LinkBrandSelectionModalModalTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
         <div class="modal-content">
            <div class="modal-header">
               <h5 class="modal-title">Select Brands</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div class="modal-body">
               <div class="ml-2" *ngFor="let brand of brandListForLinkDetails">
                  <div class="row">
                     <label class="cb-container">
                        <h6 class="ml-4 mt-1">{{brand.brandName}}</h6>
                        <input type="checkbox" [checked]="isBrandSelected(brand)" (change)="onBrandSelection($event,brand)">
                        <span class="checkmark"></span>
                     </label>
                  </div>
                  <div *ngIf="brand.id ==='ANY'">
                     <hr>
                  </div>
               </div>
            </div>
            <div class="modal-footer">
               <div class="m-1 d-flex">
                  <button type="button" class="btn bg-dark text-white btn-block" (click)="onClickOkLinkBrandSelectionModal()">Ok</button>
               </div>
            </div>
         </div>
      </div>
   </div>


</form>
<!--<button type="button" class="btn btn-primary" (click)="logLicensor()">LOG</button>-->