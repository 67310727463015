<div>
   <div class="row mt-2 d-flex justify-content-center">
      <h2 class="d-inline">

         Trims Tracker

      </h2>
   </div>
   <div>
       <div class="row">
           <div class="col-sm-8" *ngIf="currentLoggedInUser && currentLoggedInUser!==null">
           <app-search #searchComponent [placeholder]="'Search...'" [searchConfig]="searchConfig" [existingList]="trimsOrderTrackerDetailsSalesOrderGrouped" [sortColumns]="sortColumns"></app-search>
           </div>
           
       </div>
       <div>
           <ng-container>
              
               <table class=" f-11 table table-sm table-bordered  table-condensed" style="width: 100%;border: 0px solid #dee2e6"><!--customtablehover-->
                             <thead class="thead-dark" style="position: sticky;top: 60px;">
                                <tr class="text-center">
                                   <th style="border-left: 4px solid #343a40;" style="width: 1%;">
                                      
                                   </th>
                                   <th style="width: 4%;">Sales Order#</th>
                                   <!--<th style="width: 4%;">Production Status</th>-->
                                   <th style="width: 4%;">Coordinator</th>
                                   <th style="width: 6%;">Customer</th>
                                   <!--<th style="width: 4%;">Customer PO#</th>-->
                                   <th style="width: 6%;">Printer</th>
                                   <th style="width: 4%;">Printer Placement Date</th>
                                   <!--<th style="width: 4%;">Printer PO#</th>-->
                                   <th style="width: 4%;">Total Units</th>
                                   <!--<th style="width: 4%;">Nipo#</th>-->
                                   <th style="width: 4%;">Ship To Address</th>
                                   <th style="width: 6%;">Mandatory Trims</th>
                                   <th style="width: 6%;">Trims Vendor</th>
                                   <th style="width: 6%;">Trims Ordered</th>
                                   <th style="width: 6%;">Trims Status</th>
                                   <th style="width: 4%;">Ordered Units</th>
                                   <th style="width: 4%;">Ordered Date</th>
                                   <th style="width: 4%;">Ordered #</th>
                                   <!--<th>UPC Stickers</th>
                                   <th>Box Label</th>
                                   <th>Hangtag</th>
                                   <th>Price Tickets</th>
                                   <th>Boxes</th>
                                   <th>Size Stickers</th>
                                   <th>Hangers</th>-->
                                   <th style="width: 4%;">Tracking#</th>
                                   <th style="width: 4%;">Invoice#</th>
                                   <th style="width: 4%;">Invoice Value</th>
                                   <th style="width: 4%;">Freight Charges</th>
                                   <th style="width: 4%;">Total Invoice Value</th>
                                   <th>Status</th>
                                </tr>
                             </thead>
                             <tbody>
                                
                                <tr><td colspan="999" class="border-0" style="padding: 6px !important; "></td></tr> 
                       <ng-container *ngFor="let tsog of trimsOrderTrackerDetailsSalesOrderGrouped; index as tsogIndex;let salesGroupfirst = first;">
                          
                             
                          <ng-container *ngFor="let tpg of tsog.trackerDetailPrinterGroupedList; index as tpgIndex;let printerGroupfirst = first;">
                             <ng-container *ngFor="let totd of tpg.trimsOrderTrackerDetails; index as totdIndex;let trimOrderfirst = first;">
                                <ng-container *ngFor="let tottd of totd.trimDetails; index as tottdIndex;let trimDetailfirst = first;">
                              <tr class="text-center border my-4" [style.background-color]="tsogIndex%2===1?'rgba(0,0,0,.05)':''" style="margin-bottom: 5px !important;">
                                <td [attr.rowspan]="tsog.salesTrimsOrderTrackerDetailsCount" *ngIf="printerGroupfirst && trimOrderfirst && trimDetailfirst" style="border-left: 4px solid black;">
                                   <div>
                                      <button *ngIf="!tsog.editable" (click)="tsog.editable=true;" type="button" class="btn btn-sm "><i  class="fas fa-pencil-alt fa-sm"></i></button>
                                      <button *ngIf="tsog.editable" (click)="save(tsog)" type="button" class="btn btn-sm "><i  class="fas fa-save fa-sm"></i></button>
                                      <button *ngIf="tsog.editable" type="button" class="btn btn-sm p-0" (click)="tsog.editable=false;search();"><i class="fas fa-window-close"></i></button>
                                   </div>
                                </td>
                               <td [attr.rowspan]="tsog.salesTrimsOrderTrackerDetailsCount" *ngIf="printerGroupfirst && trimOrderfirst && trimDetailfirst" >
                                {{tsog.salesOrderNumber}}
                                <div>
                                   {{'sales.productionStatus.'+tsog.productionStatus| transform}}
                                </div>
                               </td>
                               <!--<td  [attr.rowspan]="tsog.salesTrimsOrderTrackerDetailsCount" *ngIf="printerGroupfirst && trimOrderfirst && trimDetailfirst">{{'sales.productionStatus.'+tsog.productionStatus| transform}}</td>-->
                               <td  [attr.rowspan]="tsog.salesTrimsOrderTrackerDetailsCount" *ngIf="printerGroupfirst && trimOrderfirst && trimDetailfirst">{{tsog.productionCoordinator}}</td>
                               <td  [attr.rowspan]="tsog.salesTrimsOrderTrackerDetailsCount" *ngIf="printerGroupfirst && trimOrderfirst && trimDetailfirst">
                                {{tsog.customer}}
                                <div>
                                   PO: {{tsog.customerPoNum}}
                                </div>
                                </td>
                              <!-- <td  [attr.rowspan]="tsog.salesTrimsOrderTrackerDetailsCount" *ngIf="printerGroupfirst && trimOrderfirst && trimDetailfirst"></td>-->
                               <td [attr.rowspan]="tpg.trimsOrderTrackerDetailsCount" *ngIf="trimOrderfirst && trimDetailfirst">
                                {{tpg.printerName}}
                                <div>
                                   PO: {{tpg.printerPhilcosPoNum}}
                                </div>
                                </td>
                               <td [attr.rowspan]="tpg.trimsOrderTrackerDetailsCount" *ngIf="trimOrderfirst && trimDetailfirst">Jan 01, 1990</td>
                               <!--<td [attr.rowspan]="tpg.trimsOrderTrackerDetailsCount" *ngIf="trimOrderfirst && trimDetailfirst">{{tpg.printerPhilcosPoNum}}</td>-->
                               <td [attr.rowspan]="tpg.trimsOrderTrackerDetailsCount" *ngIf="trimOrderfirst && trimDetailfirst">{{tpg.totalPrintQty}}</td>
                               <!--<td [attr.rowspan]="tpg.trimsOrderTrackerDetailsCount" *ngIf="trimOrderfirst && trimDetailfirst">???</td>-->
                               <td [attr.rowspan]="tpg.trimsOrderTrackerDetailsCount" *ngIf="trimOrderfirst && trimDetailfirst">Ship To Address</td>
                               <td [attr.rowspan]="tpg.trimsOrderTrackerDetailsCount" *ngIf="trimOrderfirst && trimDetailfirst">
                                Mandatory Trims   
                                <div class="">
                                   <button *ngIf="tsog.editable"  type="button" class="btn btn-sm btn-outline-info f-9 " style="border: none;" (click)="addTrimVendor(tsog,tpg)">+ Trim Vendor</button>
                                </div>
                                </td>
                               <td [attr.rowspan]="totd.trimDetails.length" *ngIf="trimDetailfirst" style="background-color: #edeeef;" class="border">
                                <span *ngIf="!tsog.editable">{{totd.trimSupplier}}</span>
                                <select *ngIf="tsog.editable" class="form-control form-control-sm p-0" required style="font-size: 11px !important;" name="trimSupplier{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" 
                                      [(ngModel)]="totd.trimSupplier" 
                                      #trimSupplierVar="ngModel" 
                                      [ngClass]="{'is-invalid':trimSupplierVar && trimSupplierVar.errors}">
                                      <option class="f9" value="N/A" >&nbsp;</option>
                                         <option class="f9" value="WAHLUNG" >WAHLUNG</option>
                                         <option class="f9" value="Mr. Printer" >Mr. Printer</option>
                                         <option class="f9" value="Philcos" >Philcos</option>
                                         
                                      </select>

                                <div class="" *ngIf="totd.trimSupplier && (totd.trimSupplier!=='N/A')">
                                   <button *ngIf="tsog.editable"  type="button" class="btn btn-sm btn-outline-info f-9 " style="border: none;"  (click)="addTrimVendorTrimDetails(tsog,tpg,totd)">+ Trim</button>
                                </div>
                               </td>
                               <td>
                                
                                <span *ngIf="!tsog.editable">{{tottd.trim}}</span>
                                <select *ngIf="tsog.editable" class="form-control form-control-sm p-0" required style="font-size: 11px !important;" name="trim{{tsogIndex}}{{tpgIndex}}{{totdIndex}}{{tottdIndex}}" 
                                      [(ngModel)]="tottd.trim"  [disabled]="(!totd.trimSupplier || totd.trimSupplier==='N/A')"
                                      #trimVar="ngModel" 
                                      [ngClass]="{'is-invalid':trimVar && trimVar.errors}">
                                         <option class="f9" value="Hangtag" >Hangtag</option>
                                         <option class="f9" value="Individual Polybag" >Individual Polybag</option>
                                         <option class="f9" value="Bulk Polybag" >Bulk Polybag</option>
                                         
                                      </select>
                                </td>
                               <td>
                                <span *ngIf="!tsog.editable">{{tottd.trimStatus}}</span>
                                <select *ngIf="tsog.editable" class="form-control form-control-sm p-0" required style="font-size: 11px !important;" name="trimStatus{{tsogIndex}}{{tpgIndex}}{{totdIndex}}{{tottdIndex}}" 
                                      [(ngModel)]="tottd.trimStatus" [disabled]="(!totd.trimSupplier || totd.trimSupplier==='N/A')"
                                      #trimStatusVar="ngModel" 
                                      [ngClass]="{'is-invalid':trimStatusVar && trimStatusVar.errors}">
                                         <option class="f11" value="Ordered" >Ordered</option>
                                         <option class="f9" value="In Transit" >In Transit</option>
                                         <option class="f9" value="Delivered" >Delivered</option>
                                         <option class="f9" value="Re-Ordered" >Re-Ordered</option>
                                         <option class="f9" value="Not Required" >Not Required</option>
                                         <option class="f9" value="Un-used Trims" >Un-used Trims</option>
                                         <option class="f9" value="Liability" >Liability</option>
                                      </select>
                               </td>

                               
                               <td [attr.rowspan]="totd.trimDetails.length" *ngIf="trimDetailfirst">
                                <input name="trimsOrderedQty{{tsogIndex}}{{tpgIndex}}{{totdIndex}}{{tottdIndex}}" id="trimsOrderedQty{{tsogIndex}}{{tpgIndex}}{{totdIndex}}{{totdIndex}}" type="number" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #trimsOrderedQtyVar="ngModel"
                                   [(ngModel)]="tottd.trimsOrderedQty" [disabled]="!tsog.editable || (!totd.trimSupplier || totd.trimSupplier==='N/A')" required 
                                   [ngClass]="{'is-invalid':trimsOrderedQtyVar.touched && trimsOrderedQtyVar.errors}" >
                                
                                </td>
                               <td [attr.rowspan]="totd.trimDetails.length" *ngIf="trimDetailfirst">
                                 {{totd.trimOrderedDate | date:'mediumDate'}}
                                 <span *ngIf="tsog.editable">
                                    <div class="">
                                       <input matInput [matDatepicker]="orderDatePicker" id="orderDate{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" name="{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" [(ngModel)]="totd.trimOrderedDate" style="cursor: pointer;font-size: 11px;"
                                         class="form-control  form-control-sm" required #orderDateVar="ngModel" [ngClass]="{'is-invalid':orderDateVar.touched && orderDateVar.errors}" readonly [disabled]="!tsog.editable || (!totd.trimSupplier || totd.trimSupplier==='N/A')" (click)="orderDatePicker.open()">
                                       <mat-datepicker #orderDatePicker></mat-datepicker>
                                       <button class="btn btn-sm" type="button" [disabled]="!tsog.editable || (!totd.trimSupplier || totd.trimSupplier==='N/A')" (click)="orderDatePicker.open()"><i class="fas fa-calendar-alt"></i></button>
                                    </div>
                                    <mat-error *ngIf="orderDateVar?.errors?.required && (orderDateVar.dirty || orderDateVar.touched)" style="font-size: 80%;color: #dc3545">
                                       Order Date is required.
                                    </mat-error>
                                 </span>
                              </td>
                               <td [attr.rowspan]="totd.trimDetails.length" *ngIf="trimDetailfirst">???</td>
                               <!--<td>UPC Stickers</td>
                               <td>
                                      <select class="form-control form-control-sm p-0" required style="font-size: 10px !important;" name="boxLabelStatus{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" 
                                      [(ngModel)]="totd.boxLabelStatus" 
                                      #boxLabelStatusVar="ngModel" 
                                      [ngClass]="{'is-invalid':boxLabelStatusVar && boxLabelStatusVar.errors}">
                                         <option class="f9" value="Ordered" >Ordered</option>
                                         <option class="f9" value="In Transit" >In Transit</option>
                                         <option class="f9" value="Delivered" >Delivered</option>
                                         <option class="f9" value="Re-Ordered" >Re-Ordered</option>
                                         <option class="f9" value="Not Required" >Not Required</option>
                                         <option class="f9" value="Un-used Trims" >Un-used Trims</option>
                                         <option class="f9" value="Liability" >Liability</option>
                                      </select>
                                </td>
                               <td>
                                <select class="form-control form-control-sm p-0" required style="font-size: 10px !important;" name="hangtagsStatus{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" 
                                      [(ngModel)]="totd.hangtagsStatus" 
                                      #hangtagsStatusVar="ngModel" 
                                      [ngClass]="{'is-invalid':hangtagsStatusVar && hangtagsStatusVar.errors}">
                                      <option class="f9" value="Ordered" >Ordered</option>
                                      <option class="f9" value="In Transit" >In Transit</option>
                                      <option class="f9" value="Delivered" >Delivered</option>
                                      <option class="f9" value="Re-Ordered" >Re-Ordered</option>
                                      <option class="f9" value="Not Required" >Not Required</option>
                                      <option class="f9" value="Un-used Trims" >Un-used Trims</option>
                                      <option class="f9" value="Liability" >Liability</option>
                                      </select>
                               </td>
                               <td>
                                <select class="form-control form-control-sm p-0" required style="font-size: 10px !important;" name="priceTicketStatus{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" 
                                      [(ngModel)]="totd.priceTicketStatus" 
                                      #priceTicketStatusVar="ngModel" 
                                      [ngClass]="{'is-invalid':priceTicketStatusVar && priceTicketStatusVar.errors}">
                                      <option class="f9" value="Ordered" >Ordered</option>
                                      <option class="f9" value="In Transit" >In Transit</option>
                                      <option class="f9" value="Delivered" >Delivered</option>
                                      <option class="f9" value="Re-Ordered" >Re-Ordered</option>
                                      <option class="f9" value="Not Required" >Not Required</option>
                                      <option class="f9" value="Un-used Trims" >Un-used Trims</option>
                                      <option class="f9" value="Liability" >Liability</option>
                                      </select>
                               </td>
                               <td>
                                <select class="form-control form-control-sm p-0" required style="font-size: 10px !important;" name="boxesStatus{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" 
                                      [(ngModel)]="totd.boxesStatus" 
                                      #boxesStatusVar="ngModel" 
                                      [ngClass]="{'is-invalid':boxesStatusVar && boxesStatusVar.errors}">
                                      <option class="f9" value="Ordered" >Ordered</option>
                                      <option class="f9" value="In Transit" >In Transit</option>
                                      <option class="f9" value="Delivered" >Delivered</option>
                                      <option class="f9" value="Re-Ordered" >Re-Ordered</option>
                                      <option class="f9" value="Not Required" >Not Required</option>
                                      <option class="f9" value="Un-used Trims" >Un-used Trims</option>
                                      <option class="f9" value="Liability" >Liability</option>
                                      </select>
                               </td>
                               <td>
                                <select class="form-control form-control-sm p-0" required style="font-size: 10px !important;" name="sizeStickersStatus{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" 
                                      [(ngModel)]="totd.sizeStickersStatus" 
                                      #sizeStickersStatusVar="ngModel" 
                                      [ngClass]="{'is-invalid':sizeStickersStatusVar && sizeStickersStatusVar.errors}">
                                      <option class="f9" value="Ordered" >Ordered</option>
                                      <option class="f9" value="In Transit" >In Transit</option>
                                      <option class="f9" value="Delivered" >Delivered</option>
                                      <option class="f9" value="Re-Ordered" >Re-Ordered</option>
                                      <option class="f9" value="Not Required" >Not Required</option>
                                      <option class="f9" value="Un-used Trims" >Un-used Trims</option>
                                      <option class="f9" value="Liability" >Liability</option>
                                      </select>
                               </td>
                               <td>
                                <select class="form-control form-control-sm p-0" required style="font-size: 10px !important;" name="hangersStatus{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" 
                                [(ngModel)]="totd.hangersStatus" 
                                #hangersStatusVar="ngModel" 
                                [ngClass]="{'is-invalid':hangersStatusVar && hangersStatusVar.errors}">
                                <option class="f9" value="Ordered" >Ordered</option>
                                <option class="f9" value="In Transit" >In Transit</option>
                                <option class="f9" value="Delivered" >Delivered</option>
                                <option class="f9" value="Re-Ordered" >Re-Ordered</option>
                                <option class="f9" value="Not Required" >Not Required</option>
                                <option class="f9" value="Un-used Trims" >Un-used Trims</option>
                                <option class="f9" value="Liability" >Liability</option>
                                </select>
                                </td>-->
                               <td [attr.rowspan]="totd.trimDetails.length" *ngIf="trimDetailfirst">
                                <input name="trimTrackingNum{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" id="trimTrackingNum{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" type="text" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #trimTrackingNumVar="ngModel"
                                   [(ngModel)]="totd.trimTrackingNum" [disabled]="!tsog.editable || (!totd.trimSupplier || totd.trimSupplier==='N/A')"
                                   [ngClass]="{'is-invalid':trimTrackingNumVar.touched && trimTrackingNumVar.errors}" ></td>
                               <td [attr.rowspan]="totd.trimDetails.length" *ngIf="trimDetailfirst">
                                <input name="trimInvoiceNum{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" id="trimInvoiceNum{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" type="text" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #trimInvoiceNumVar="ngModel"
                                [(ngModel)]="totd.trimInvoiceNum" [disabled]="!tsog.editable || (!totd.trimSupplier || totd.trimSupplier==='N/A')"
                                [ngClass]="{'is-invalid':trimInvoiceNumVar.touched && trimInvoiceNumVar.errors}" >
                                </td>
                                <td [attr.rowspan]="totd.trimDetails.length" *ngIf="trimDetailfirst">
                                   <input name="trimsInvoiceValue{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" id="trimsInvoiceValue{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" type="number" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #trimsInvoiceValueVar="ngModel"
                                   [(ngModel)]="totd.trimsInvoiceValue" [disabled]="!tsog.editable || (!totd.trimSupplier || totd.trimSupplier==='N/A')"
                                   [ngClass]="{'is-invalid':trimsInvoiceValueVar.touched && trimsInvoiceValueVar.errors}" >
                                </td>
                           
                                <td [attr.rowspan]="totd.trimDetails.length" *ngIf="trimDetailfirst">
                                   <input name="freightCharges{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" id="freightCharges{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" type="number" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #freightChargesVar="ngModel"
                                   [(ngModel)]="totd.freightCharges" [disabled]="!tsog.editable || (!totd.trimSupplier || totd.trimSupplier==='N/A')"
                                   [ngClass]="{'is-invalid':freightChargesVar.touched && freightChargesVar.errors}" >
                                </td>		
  
                                <td [attr.rowspan]="totd.trimDetails.length" *ngIf="trimDetailfirst">  
                                   <input name="totalInvoiceValue{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" id="totalInvoiceValue{{tsogIndex}}{{tpgIndex}}{{totdIndex}}" type="number" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #totalInvoiceValueVar="ngModel"
                                   [(ngModel)]="totd.totalInvoiceValue" [disabled]="!tsog.editable || (!totd.trimSupplier || totd.trimSupplier==='N/A')"
                                   [ngClass]="{'is-invalid':totalInvoiceValueVar.touched && totalInvoiceValueVar.errors}" >
                                  </td>	
                               <td [attr.rowspan]="tsog.salesTrimsOrderTrackerDetailsCount" *ngIf="printerGroupfirst && trimOrderfirst && trimDetailfirst" >Status</td>   
                               </tr>
                               </ng-container>
                             </ng-container>   
                             </ng-container>   
                             <tr><td colspan="999" class="border-0" style="padding: 6px !important; "></td></tr>     <!--background-color: #dee2e6;-->
                          
                          
                       </ng-container>
                                
                                
                             </tbody>
                          </table>
                       
             </ng-container>
           <!--<ng-container *ngFor="let pcvg of printerCapacityViewGrouped">
               {{pcvg.printerName}} - {{pcvg.orderShipMonthYear}}
           </ng-container>-->
       </div>
   </div>







   <div class="floatBtn d-flex flex-column">
       <button type="button" class="btn btn-circle bg-secondary mb-2" matTooltip="Scroll To Top" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'});"  matTooltipPosition="left" matTooltipClass="bg-dark">
          <i class="fas fa-arrow-up fa-2x" style="color:white"></i>
       </button>
    </div>
</div>
