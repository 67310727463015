<div>
    <div class="row mt-2 d-flex justify-content-center">
       <h2 class="d-inline">
 
          Art Tracker
 
       </h2>
    </div>
    <div>
        <div class="row">
            <div class="col-sm-8" *ngIf="currentLoggedInUser && currentLoggedInUser!==null">
                <app-search #searchComponent [placeholder]="'Order#, Customer Name, Printer Name, etc. (space delimited)'" [searchConfig]="searchConfig" [existingList]="salesArtTrackerViewList" [sortColumns]="sortColumns"></app-search>
            </div>
            
        </div>
        <!--<div class="mb-2" *ngIf="salesArtTrackerViewList && salesArtTrackerViewList.length>0">
            <button  type="button" (click)="expandList(true)" class="btn btn-sm btn-outline-info f-11" style="border: none;" >+ Expand All</button>
            <button  type="button" (click)="expandList(false)" class="btn btn-sm btn-outline-info f-11" style="border: none;" >- Collapse All</button>
        </div>-->

        <div>
            <ng-container>
                <table class=" f-10 table table-sm table-bordered  table-condensed" style="border: 0px solid #dee2e6;">
                              <thead class="thead-dark" style="position: sticky;top: 60px;">
                                 <tr class="text-center">
                                    <th style="width: 1% !important;border-left: 4px solid #343a40;">&nbsp;</th>
                                    <th style="width: 15% !important;">Sales Order#</th>
                                    <th>Customer</th>
                                    <th>Coordiantor</th>
                                    <th>Printer</th>
                                    
                                    <th>Ship Date</th>
                                    <th>Production Status</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                <!--<tr><td colspan="5" class="border-0" style="padding: 6px !important; "></td></tr> -->
                                    
                        <ng-container *ngFor="let atv of salesArtTrackerViewList; index as atvIndex;">
                            <tr class="text-center border" >
                                <td style="border-left: 4px solid #859ba9;" [style.background-color]="atv.expanded?'#eb7d6a42':''" class="align-middle  text-nowrap p-2" 
                                 (click)="showHideArtTrackerViewDetails(atv)">
                                    <span *ngIf="!atv.expanded" ><i class="fas fa-chevron-down"></i></span>
                                    <span *ngIf="atv.expanded" ><i class="fas fa-chevron-up"></i></span>
                                 </td>
                                <td  [style.background-color]="atv.expanded?'#eb7d6a42':''" class="f-12" >
                                        <span *ngIf="!userAccess?.canViewSalesOrder">{{atv.salesOrderNumber}}</span>
                                            <a [routerLink]="['/salesOrder/view/'+atv.salesOrderId]" target="_blank" *ngIf="userAccess?.canViewSalesOrder">
                                                {{atv.salesOrderNumber}}
                                            </a>
                                        
                                </td>
                                <td (click)="showHideArtTrackerViewDetails(atv)" [style.background-color]="atv.expanded?'#eb7d6a42':''" >{{atv.customerName}}</td>
                                <td (click)="showHideArtTrackerViewDetails(atv)" [style.background-color]="atv.expanded?'#eb7d6a42':''" >{{atv.coordinatorName}}</td>
                                <td (click)="showHideArtTrackerViewDetails(atv)" [style.background-color]="atv.expanded?'#eb7d6a42':''" >{{atv.printerName}}</td>
                                <td (click)="showHideArtTrackerViewDetails(atv)" [style.background-color]="atv.expanded?'#eb7d6a42':''">{{atv.orderShipDate | date:'mediumDate'}}</td>
                                <td (click)="showHideArtTrackerViewDetails(atv)" [style.background-color]="atv.expanded?'#eb7d6a42':''">{{'sales.productionStatus.'+atv.productionStatus| transform}}</td>
                                <td>
                                    <div>
                                        <button *ngIf="!atv.editable && userAccess?.canEditArtTracker" (click)="editArtTrackerView(atv)" type="button" class="btn btn-sm "><i  class="fas fa-pencil-alt fa-sm"></i></button>
                                        <button *ngIf="atv.editable" (click)="saveArtTrackerView(atv)" type="button" class="btn btn-sm btn-success"><i  class="fas fa-save "></i></button>
                                        <button *ngIf="atv.editable" type="button" class="btn btn-sm ml-2 btn-dark" (click)="atv.editable=false;relaodArtTrackerView(atv);"><i class="fas fa-window-close"></i></button>
                                     </div>
                                </td>
                                
                            </tr>
                                    <tr *ngIf="atv.expanded">

                                        <ng-container>
                                            
                                            <td colspan="5"  class="text-center"><!--style="background-color: #ff634712;"-->
                                            <table class=" f-10 table table-sm  mb-1 mx-auto" style="width:90%;border: 0px solid #dee2e6;">
                                                <thead class="thead-light">
                                                    <th style="width: 1%!important;">Image</th>
                                                    <th style="width: 10%!important;">Sku</th>
                                                    <th style="width: 15%!important;">Approval Status</th>
                                                    <th style="width: 15%!important;">Art Status</th>
                                                    <th style="width: 30%!important;">Notes</th>
                                                    <th style="width: 1%!important;">Email</th>
                                                    
                                                    
                                                </thead>
                                                <tbody>
                                                    <ng-container >
                                                        <ng-container *ngFor="let atvd of atv.salesArtTrackers; index as atvdIndex;">
                                                            <tr>
                                                                <td style="vertical-align: middle;" class="align-center"><!--bg-color-{{approvalStatusColor(atvd.product.approvalStatus)}}-->
                                                                    <a type="button" style="color: black;" matTooltip="View product" matTooltipPosition="below" matTooltipClass="bg-dark" target="_blank"
                                                                        [routerLink]="['/product/view/'+atvd.product.licensorId+'/'+atvd.product.brandId+'/'+atvd.product.id]">
                                                                        <img [src]="atvd.product.productImages[0]?.thumbnailSignedUrl" 
                                                                        class="img-thumbnail img-fluid zoom-4 "
                                                                        style="display: block;  width:60px;  height:80px; min-width: 80px;"
                                                                        [style.border-color]="'cadetblue'"
                                                                        (error)="handleThumbnailError(atvd.product.productImages[0],$event)"
                                                                       />
                                                                    </a>   
                                                                    <!--<img [src]="atvd.product.productImages[0]?.thumbnailSignedUrl" 
                                                                        class="img-thumbnail img-fluid zoom-4 "
                                                                        style="display: block;  width:60px;  height:80px; min-width: 80px;"
                                                                        [style.border-color]="'cadetblue'"
                                                                        (error)="handleThumbnailError(atvd.product.productImages[0],$event)"
                                                                       />--><!-- matTooltip="&#8226; File Name : {{product.productImages[0]?.originalFileName}} &#13;&#8226; Uploaded On : {{product.productImages[0]?.uploadDate | date:'medium'}}" 
                                                                        matTooltipPosition="below" matTooltipClass="bg-dark allow-cr f-10"-->
                                                            </td>
                                                                <!--<td>
                                                                    <span *ngIf="!userAccess?.canViewSalesOrder">{{pcv.salesOrderNumber}}</span>
                                                                        <a [routerLink]="['/salesOrder/view/'+pcv.salesOrderId]" target="_blank" *ngIf="userAccess?.canViewSalesOrder">
                                                                            {{pcv.salesOrderNumber}}
                                                                        </a>
                                                                    </td>-->
                                                                <td>{{atvd.product.sku}}</td>
                                                                <td>{{atvd.product.approvalStatus}}</td>
                                                                <td>{{atvd.product.artStatus}}</td>
                                                                <td style="text-align: left !important;">
                                                                    <div>
                                                                    <textarea name="artNotes{{atvIndex}}{{atvdIndex}}" id="artNotes{{atvIndex}}{{atvdIndex}}"  
                                                                    [(ngModel)]="atvd.notes"
                                                                    rows="4"
                                                                    class="form-control form-control-sm f-14 text-danger" [disabled]="!atv.editable" maxlength="999">{{atvd.notes}}
                                                                    </textarea>
                                                                </div>
                                                                </td>
                                                                <th rowspan="999" style="width: 1%!important;" *ngIf="atvdIndex===0">
                                                                    <button id="sendEmail" type="button" class="btn btn-sm mb-2 bg-navy-blue" (click)="sendProductEmail(atv)"   matTooltip="Send Style Email" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fa fa-envelope text-light fa-lg"></i></button>
                                                                </th>
                                                                
                                                            </tr>
                                                        </ng-container>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                            </td>
                                        </ng-container>  
                                    </tr>
                                    <tr >
                                        <td colspan="999"></td>
                                    </tr>
                                    
                                    
                                
                                <!--<tr><td colspan="5" class="border-0" style="padding: 6px !important; "></td></tr>-->    <!--background-color: #dee2e6;-->
                        </ng-container>
                                 
                                 
                              </tbody>
                           </table>
              </ng-container>
            
        </div>
    </div>
    <button id="composeEmailToggler" type="button" style="display: none;" data-toggle="modal" data-target="#composeEmail"></button>
    <div *ngIf="showEmailEditor">
        <app-compose-email  [modalId]="'composeEmail'" [emailHeader]="'Email'" [composeEmail]="composeEmail" ></app-compose-email>
    </div>





    <!--<div class="floatBtn d-flex flex-column">
      <button type="button" class="btn btn-circle bg-success mb-2" *ngIf="userAccess?.canDownloadPrinterCapacity" (click)="createPrinterCapacityExcel()"><i class="fas fa-file-excel fa-2x" style="color:white"></i></button>
        <button type="button" class="btn btn-circle bg-secondary mb-2" matTooltip="Scroll To Top" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'});"  matTooltipPosition="left" matTooltipClass="bg-dark">
           <i class="fas fa-arrow-up fa-2x" style="color:white"></i>
        </button>
     </div>-->
</div>
