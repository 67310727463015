import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { ApiResponse } from '../model/api-response';
import { SharedService } from './shared.service';
import { MetadataColour } from '../model/product-metadata/metadata-colour.model';
import { MetadataSize } from '../model/product-metadata/metadata-size.model';
import { MetadataTreatment } from '../model/product-metadata/metadata-treatment.model';
import { MetadataGarment } from '../model/product-metadata/metadata-garment.model';
import { MetadataCategory } from '../model/product-metadata/metadata-category.model';
import { MetadataRoyaltyCategory } from '../model/product-metadata/metadata-royalty-category.model';
import { GarmentCategoryCombinations } from '../model/product.model';
import { MetadataProductConfig } from '../model/product-metadata/metadata-product-config.model';

@Injectable({
   providedIn: 'root'
})

export class ProductMetadataService {

  // colourListSubject: BehaviorSubject<MetadataColour[]>;
   //public colourListObservable: Observable<MetadataColour[]>;

   colorListSubject: BehaviorSubject<string[]>;
   public colorListObservable: Observable<string[]>;

   categoryListSubject: BehaviorSubject<string[]>;
   public categoryListObservable: Observable<string[]>;

   treatmentListSubject: BehaviorSubject<string[]>;
   public treatmentListObservable: Observable<string[]>;

   garmentListSubject: BehaviorSubject<string[]>;
   public garmentListObservable: Observable<string[]>;

   garmentCategoryCombListSubject: BehaviorSubject<GarmentCategoryCombinations[]>;
   public garmentCategoryCombListObservable: Observable<GarmentCategoryCombinations[]>;

   blanksColourListSubject: BehaviorSubject<string[]>;
   public blanksColourListObservable: Observable<string[]>;

   //sizesListSubject: BehaviorSubject<string[]>;
   //public sizesListObservable: Observable<string[]>;
   sizesMetadataListSubject: BehaviorSubject<MetadataSize[]>;
   public sizesMetadataListObservable: Observable<MetadataSize[]>;

   

   constructor(private http: HttpClient, private sharedService: SharedService) {
      this.initializeMetadataSubscription();
   }

   readonly rooturl = environment.apiEndPoint;

   initializeMetadataSubscription() {
      console.log('INTIALIZE PRODUCT METADATA');
      this.colorListSubject = new BehaviorSubject<string[]>([]);
      this.colorListObservable = this.colorListSubject.asObservable();

      this.categoryListSubject = new BehaviorSubject<string[]>([]);
      this.categoryListObservable = this.categoryListSubject.asObservable();

      this.treatmentListSubject = new BehaviorSubject<string[]>([]);
      this.treatmentListObservable = this.treatmentListSubject.asObservable();

      this.garmentListSubject = new BehaviorSubject<string[]>([]);
      this.garmentListObservable = this.garmentListSubject.asObservable();

      this.garmentCategoryCombListSubject = new BehaviorSubject<GarmentCategoryCombinations[]>([]);
      this.garmentCategoryCombListObservable = this.garmentCategoryCombListSubject.asObservable();

      this.blanksColourListSubject = new BehaviorSubject<string[]>([]);
      this.blanksColourListObservable = this.blanksColourListSubject.asObservable();

      //this.sizesListSubject = new BehaviorSubject<string[]>([]);
     // this.sizesListObservable = this.sizesListSubject.asObservable();

      this.sizesMetadataListSubject = new BehaviorSubject<MetadataSize[]>([]);
      this.sizesMetadataListObservable = this.sizesMetadataListSubject.asObservable();
  
      this.getProductMetadata();
   }

   getProductMetadata() {
    
      this.getColourList().subscribe(res=>{
        this.publishValues('COLOR',res,'name',this.colorListSubject);
        this.publishValues('BLANKS_COLOR',res,'name',this.blanksColourListSubject);
      });

      this.getCategoryList().subscribe(res=>{
         this.publishValues('CATEGORY',res,'name',this.categoryListSubject);
      });

      this.getTreatmentList().subscribe(res=>{
         this.publishValues('TREATMENT',res,'name',this.treatmentListSubject);
      });

      this.getProductConfigList().subscribe(res=>{
         this.publishValues('GARMENT',res,'productGarment',this.garmentListSubject,true);
         this.publichGarmentCategoryComb(res,'productGarment');
      })

     /* this.getGarmentList().subscribe(res=>{
         this.publishValues('GARMENT',res,'productGarment',this.garmentListSubject);
         this.publichGarmentCategoryComb(res,'productGarment');
      });*/

     /* this.getSizeList().subscribe(res=>{
         this.publishValues('SIZE',res,'size',this.sizesListSubject);
      });*/
      
      this.getSizeList().subscribe(res=>{
         this.publishSizeMetadataValues('SIZE',res,'size',this.sizesMetadataListSubject);
      });
   }
   publichGarmentCategoryComb(res: ApiResponse,propertyToMap:string) {
      let retVal:GarmentCategoryCombinations[]=[];
      if (res && res.responseStatus.status === 'SUCCESS' && res.responsePayload != null) {
         let metadataList: any[] = res.responsePayload;
        if (metadataList && metadataList != null && metadataList.length > 0) {
            let metadataValueList = metadataList.map(x => x[propertyToMap]);
            let uniqueValueList=[...new Set(metadataValueList)];
            if(uniqueValueList && uniqueValueList.length>0){
               uniqueValueList.forEach(val => {
                  let tempGarmentCategoryCombinations=new GarmentCategoryCombinations();
                  tempGarmentCategoryCombinations.garment=val;
                  
                  let tempList=res.responsePayload.filter(x=>x[propertyToMap]===val);
                  if(tempList && tempList.length>0){
                     let tempCategoryList=tempList.map(g=>g.productCategory);
                     tempCategoryList=this.sharedService.sort(tempCategoryList);
                     tempGarmentCategoryCombinations.categoryList=tempCategoryList;
                  }
                  retVal.push(tempGarmentCategoryCombinations);
               });
            }
        }
      }
      this.garmentCategoryCombListSubject.next(retVal);
   }

   private publishValues(type:string,res: ApiResponse,propertyToMap:string, targetBehaviourSubject:BehaviorSubject<any>,sort?:boolean) {
      if (res && res.responseStatus.status === 'SUCCESS' && res.responsePayload != null) {
        console.log(type, res);
        let metadataList: any[] = res.responsePayload;
        if (metadataList && metadataList != null && metadataList.length > 0) {
          let metadataValueList = metadataList.map(x => x[propertyToMap]);
          let uniqueValueList=[...new Set(metadataValueList)];
          if(sort){
            uniqueValueList=this.sharedService.sort(uniqueValueList);
          }
          targetBehaviourSubject.next(uniqueValueList);
        }
      }else{
         console.error(type,res);
      }
    }

    private publishSizeMetadataValues(type:string,res: ApiResponse,propertyToMap:string, targetBehaviourSubject:BehaviorSubject<any>,sort?:boolean) {
      if (res && res.responseStatus.status === 'SUCCESS' && res.responsePayload != null) {
        console.log(type, res);
        let metadataList: any[] = res.responsePayload;
        if (metadataList && metadataList != null && metadataList.length > 0) {
          
          targetBehaviourSubject.next(metadataList);
        }
      }else{
         console.error(type,res);
      }
    }
 

   getColourList(): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/colour/list', null);
   }

   getColourListByStatus(status: boolean): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/colour/list/'+status, null);
   }

   saveColourList(colourList: MetadataColour[]): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/colour/list/save', colourList);
   }



   getSizeList(): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/size/list', null);
   }

   getSizeListByStatus(status:boolean): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/size/list/'+status, null);
   }

   saveSizeList(sizeList: MetadataSize[]): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/size/list/save', sizeList);
   }



   getGarmentList(): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/garment/list', null);
   }


   getGarmentListByStatus(status:boolean): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/garment/list/'+status, null);
   }

   saveGarmentList(garmentList: MetadataGarment[]): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/garment/list/save', garmentList);
   }




   getTreatmentList(): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/treatment/list', null);
   }

   getTreatmentListByStatus(status:boolean): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/treatment/list/'+status, null);
   }

   saveTreatmentList(treatmentList: MetadataTreatment[]): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/treatment/list/save', treatmentList);
   }

   


   getCategoryList(): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/category/list', null);
   }

   getCategoryListByStatus(status:boolean): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/category/list/'+status, null);
   }

   saveCategoryList(categoryList: MetadataCategory[]): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/category/list/save', categoryList);
   }

   


   getRoyaltyCategoryList(): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/royalty/category/list', null);
   }

   getRoyaltyCategoryListByStatus(status:boolean): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/royalty/category/list/'+status, null);
   }

   saveRoyaltyCategoryList(royaltyCategoryList: MetadataRoyaltyCategory[]): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/royalty/category/list/save', royaltyCategoryList);
   }



   getProductConfigList(): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/config/list', null);
   }

   getProductConfigListByStatus(status:boolean): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/config/list/'+status, null);
   }

   saveProductConfigList(configList: MetadataProductConfig[]): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/metadata/product/config/list/save', configList);
   }


}
