export class Email {
   id: string;
   emailType: string;
   emailAddress: string;
   transientIndex:number;
   constructor(){
      this.id=null;
      this.emailType=null;
      this.emailAddress=null;
   }
}
