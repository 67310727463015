<form #confirmForm="ngForm" autocomplete="off">


    <div width="50%">
        <div class="d-flex justify-content-between align-items-center ">
            <h1 mat-dialog-title>Prepack & Quantity Detail</h1>
            <p>&nbsp;</p>
            <!--<span name="discardModal" type="button" class="ml-4 close" (click)="discardModalWithNoAction()"
                aria-label="Close" aria-hidden="true">&times;</span>-->
        </div>
        <hr>
        <div mat-dialog-content>
            <div class="row">
                <div class="col-sm-5">
                    <h6 class="ml-4 mt-1">Total Quantity</h6>
                </div>
                <div class="col-sm-4">
                    <input name="subOrderTotalQuantity" type="number" min="1" max="99999"
                        class="form-control  form-control-sm mt-1"
                        [(ngModel)]="currentSubOrderDetail.totalSubOrderQuantity" required
                        #totalSubOrderQuantityInput="ngModel" [disabled]="!editable"
                        [ngClass]="{'is-invalid':totalSubOrderQuantityInput.touched && totalSubOrderQuantityInput.errors}">
                    <div *ngIf="totalSubOrderQuantityInput?.invalid && (totalSubOrderQuantityInput.dirty || totalSubOrderQuantityInput.touched)"
                        class="invalid-feedback">
                        <div *ngIf="totalSubOrderQuantityInput.errors.required">Total Sub Order Quantity is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="ml-2" *ngFor="let sizeOrderDetail of currentSubOrderDetail.sizeOrderDetailList; index as index">
                <div class="row">
                    <div class="col-sm-5">
                        <h6 class="ml-4 mt-1">{{sizeOrderDetail.size}}</h6>
                    </div>
                    <div class="col-sm-4">
                        <input name="prepackQuantity_{{index}}" type="number" min="1" max="9999"
                            class="form-control  form-control-sm mt-1" [disabled]="!editable"
                            [(ngModel)]="sizeOrderDetail.prepackQuantity" required #prepackInput="ngModel"
                            [ngClass]="{'is-invalid':prepackInput.touched && prepackInput.errors}">
                        <div *ngIf="prepackInput?.invalid && (prepackInput.dirty || prepackInput.touched)"
                            class="invalid-feedback">
                            <div *ngIf="prepackInput.errors.required">Prepack Quantity is required.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div mat-dialog-actions>
        <div style="float:right;margin: 40px 0px 10px 0px;">
            <div class="m-1 d-flex">
                <button type="button" class="btn btn-primary text-white" *ngIf="editable"
                    (click)="onPrePackDetailModalOk()" [disabled]="!confirmForm.valid">Ok</button>
                <button type="button" class="btn btn-secondary text-white ml-2" *ngIf="editable"
                    (click)="onPrePackDetailModalCancel()">Cancel</button>
                <button type="button" class="btn bg-dark text-white btn-block" *ngIf="!editable"
                    (click)="discardModalWithNoAction()">Close</button>
            </div>
        </div>
    </div>
    



</form>