import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { BlankSummaryView } from 'src/app/shared/model/sales/costing/allocation/summary/blank-summary.model';
import { SalesHelperService } from 'src/app/shared/service/sales/sales-helper.service';
import { SalesService } from 'src/app/shared/service/sales/sales.service';
import { SharedService } from 'src/app/shared/service/shared.service';
import { CostingAllocation } from 'src/app/shared/model/sales/costing/allocation/costing-allocation.model';

@Component({
   selector: 'app-blank-summary',
   templateUrl: './blank-summary.component.html',
   styleUrls: ['./blank-summary.component.css']
})
export class BlankSummaryComponent implements OnInit {
   
   constructor(private salesService: SalesService, private salesHelperService: SalesHelperService, private sharedService: SharedService, 
      private toastrService: ToastrService) { }
   
   private eventsSubscription: Subscription;
   @Input() salesOrderNumber: string;
   @Input() previewOnly: boolean = false;
   blankSummariesViews: BlankSummaryView[] = [];
   @Input() salesOrderId: string;
   @Input() events: Observable<string>;
   @Input() costingAllocationForPreview: CostingAllocation;

   ngOnInit() {
      this.eventsSubscription = this.events.subscribe(event => {
         if (event === 'RELOAD') {
            this.loadBlankSummary();
         }
      })
   }

   ngOnDestroy() {
      this.eventsSubscription.unsubscribe();
   }

   loadBlankSummary() {
      if (!this.previewOnly) {
         this.salesService.getBlankSummary(this.salesOrderId).subscribe(res => {
            if (res.responseStatus.status === 'SUCCESS') {
               this.blankSummariesViews = this.salesHelperService.createBlankSummariesViews(res.responsePayload);
            }
         })
      } else {
         this.salesService.getBlankSummaryPreview(this.costingAllocationForPreview).subscribe(res => {
            if (res.responseStatus.status === 'SUCCESS') {
               this.blankSummariesViews = this.salesHelperService.createBlankSummariesViews(res.responsePayload);
               console.log('BS View', this.blankSummariesViews)
            }
         })
      }
   }

   createBlankSummaryExcel(blankSummaryView: BlankSummaryView) {
      this.salesService.createBlankSummaryExcel(this.salesOrderId, blankSummaryView.submissionCount, blankSummaryView.submissionApprovalCount, blankSummaryView.costingApprovalCount).subscribe(res => {
         if (res && res !== null) {
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = today.getFullYear();
            const todayStr = dd + '-' + mm + '-' + yyyy;
            const filename = 'BlankSummary_CS#' + this.salesOrderNumber + '_' + todayStr + ".xlsx"
            this.toastrService.success("Summary exported successfully");
            this.sharedService.downLoadFile(res, "application/vnd.ms-excel", filename);
         } else {
            this.toastrService.error("Export failed.");
         }
      }, err => {
         this.toastrService.error("Export failed. Error - " + err);
      });
   }

   purchaseOrderSummaryEventHandler(eventVal: string) {
      if ("RELOAD_SUMMARY" === eventVal) {
         this.loadBlankSummary();
      }
   }

}
