import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appLegalSuperScripts]'
})
export class LegalSuperScriptsDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input') onInput() {
    const input = this.el.nativeElement as HTMLInputElement;
    const value = input.value;
    if (value.includes('(TM)')) {
      input.value = value.replace('(TM)', '™');
    }
    if (value.includes('(tm)')) {
      input.value = value.replace('(tm)', '™');
    }
    if (value.includes('(R)')) {
      input.value = value.replace('(R)', '®');
    }
    if (value.includes('(r)')) {
      input.value = value.replace('(r)', '®');
    }
    if (value.includes('(C)')) {
      input.value = value.replace('(c)', '©');
    }
    if (value.includes('(c)')) {
      input.value = value.replace('(c)', '©');
    }
  }
}
