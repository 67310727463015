<!--PRINTER SECTION STARTS-->
<div >
    <!--<div ngModelGroup="printerInfo" #printerInfoCtrl="ngModelGroup">-->
    <form #printerInfoForm="ngForm"  autocomplete="off" novalidate><!-- ngModelGroup="blanksInfo" #blanksInfoCtrl="ngModelGroup"-->
        <!--<h6 class="mt-4">Printer Info</h6>
        <hr class="hr-min">-->
        <div class="d-flex justify-content-end mr-4 mt-2">
    
            <button type="button" class="btn btn-sm btn-warning ml-2" (click)="edit()" *ngIf="!editable && userAccess?.canEditProductionTracker">Edit</button>
            <button type="button" class="btn btn-sm btn-success ml-2" (click)="save()" *ngIf="editable" [disabled]="!printerInfoForm.valid">Save</button>
            <button type="button" class="btn btn-sm btn-dark ml-2" (click)="cancel()" *ngIf="editable">Cancel</button>
        </div>
        <div class="p-2 m-3" *ngIf="productionTracker.printerInfoList?.length===0  && !editable"><h6  class="text-muted f-13">No Printer info available</h6></div>
        <ng-container *ngFor="let printerInfo of productionTracker.printerInfoList; index as printerInfoIndex">
                <div *ngIf="printerInfo.printerId && printerInfo.deleteFlag===false" class="mt-2">
                    <h6 >{{printerInfo.printerName}}</h6>
                    <hr class="hr-min">
                </div>    
                <div class="p-2 m-3 border border-2 rounded " style="position: relative;" [style]="printerInfoIndex%2===1?'background:#fff7f0':''" *ngIf="printerInfo.deleteFlag===false"> 
                    <div class="overlay-with-text-top" *ngIf=" printerInfo.obsolete">Un-Assigned</div>
                    <div class="row "  ><!--background:#fff7f0-->
                        <div style="position: absolute;right:0%;" (click)="deletePrinterInfo(printerInfo)" *ngIf="editable && printerInfo.origination==='MANUAL'"><i class="fas fa-trash text-danger shadow"></i></div>
                        <div class="col-2">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Printer</label>
                            <select class="form-control form-control-sm custom-select" [(ngModel)]="printerInfo.printerId"  name="printer_{{printerInfoIndex}}"  style="font-size: 11px;"
                                #printerNameVar="ngModel" required  [ngClass]="{'is-invalid':printerNameVar.touched && printerNameVar.errors}" disabled
                                 (ngModelChange)="getPrinterName(printerInfo)"><!---->
                                <option *ngFor="let printer of printerList" [ngValue]="printer.id">
                                    {{printer.name}}</option>
                            </select>
                            <div *ngIf="printerNameVar?.invalid && (printerNameVar.dirty || printerNameVar.touched)" class="invalid-feedback">
                                <div *ngIf="printerNameVar.errors.required">
                                    Printer is required.
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-2">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Philcos PO#</label>
                            <input name="philcosPoNum_{{printerInfoIndex}}" class="form-control form-control-sm" style="font-size: 11px;" #philcosPoNum="ngModel" required 
                            [(ngModel)]="printerInfo.philcosPrinterPO" [ngClass]="{'is-invalid':philcosPoNum.touched && philcosPoNum.errors}"
                            [disabled]="!editable || printerInfo.obsolete">
                            <div *ngIf="philcosPoNum?.invalid && (philcosPoNum.dirty || philcosPoNum.touched)" class="invalid-feedback">
                            <div *ngIf="philcosPoNum.errors.required">
                                Philcos PO# is required.
                            </div>
                            </div>
                        </div>

                        <div class="col-2">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Invoice Value</label>
                            <input name="invoiceValue_{{printerInfoIndex}}" class="form-control form-control-sm" style="font-size: 11px;" #invoiceValue="ngModel"  
                            [(ngModel)]="printerInfo.invoiceValue" type="number" [ngClass]="{'is-invalid':invoiceValue.touched && invoiceValue.errors}"
                            [disabled]="!editable || printerInfo.obsolete">
                            <div *ngIf="invoiceValue?.invalid && (invoiceValue.dirty || invoiceValue.touched)" class="invalid-feedback">
                            <div *ngIf="invoiceValue.errors.required">
                                Invoice Value is required.
                            </div>
                            </div>
                        </div>

                        <div class="col-2">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Invoice Currency</label>
                            <select class="form-control form-control-sm custom-select" [(ngModel)]="printerInfo.invoiceCurrency"  name="invoiceCurrency_{{printerInfoIndex}}"  style="font-size: 11px;"
                                #invoiceCurrencyVar="ngModel" [required]="printerInfo.invoiceValue && printerInfo.invoiceValue>0"  [ngClass]="{'is-invalid':invoiceCurrencyVar.touched && invoiceCurrencyVar.errors}"
                                [disabled]="!editable || printerInfo.obsolete">
                                <option value="CAD">CAD</option>
                                <option value="USD">USD</option>
                            </select>
                            <div *ngIf="invoiceCurrencyVar?.invalid && (invoiceCurrencyVar.dirty || invoiceCurrencyVar.touched)" class="invalid-feedback">
                                <div *ngIf="invoiceCurrencyVar.errors.required">
                                    Invoice Currency is required.
                                </div>
                            </div>
                        </div>


                        <div class="col-2">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Printer PO Type</label>
                            <select class="form-control form-control-sm custom-select" [(ngModel)]="printerInfo.printerPOType"  name="printerPoType_{{printerInfoIndex}}"  style="font-size: 11px;"
                                #printerPoTypeVar="ngModel" required  [ngClass]="{'is-invalid':printerPoTypeVar.touched && printerPoTypeVar.errors}"
                                [disabled]="!editable || printerInfo.obsolete">
                                <option value="SOLID">Solid</option>
                                <option value="TREATMENT">Treatment</option>
                                <option value="EMBROIDERY">Embroidery</option>
                                <option value="FULL_PACKAGE">Full Package</option>
                            </select>
                            <div *ngIf="printerPoTypeVar?.invalid && (printerPoTypeVar.dirty || printerPoTypeVar.touched)" class="invalid-feedback">
                                <div *ngIf="printerPoTypeVar.errors.required">
                                    Printer PO Type is required.
                                </div>
                            </div>
                        </div>
                    
                        <div class="col-2">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Requested Ship Date</label>
                            <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="printerReqShipDate"  name="printerReqShipDate_{{printerInfoIndex}}" [(ngModel)]="printerInfo.printerRequestShipDate" style="cursor: pointer;font-size: 11px;"
                                class="form-control  form-control-sm" required #printerReqShipDateVar="ngModel" [ngClass]="{'is-invalid':printerReqShipDateVar.touched && printerReqShipDateVar.errors}"
                                (ngModelChange)="calculateWeek(printerInfo)" [disabled]="!editable || printerInfo.obsolete">
                            <mat-datepicker #printerReqShipDate></mat-datepicker>
                            <button class="btn btn-sm" type="button" (click)="printerReqShipDate.open()" [disabled]="!editable || printerInfo.obsolete"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <mat-error *ngIf="printerReqShipDateVar?.errors?.required && (printerReqShipDateVar.dirty || printerReqShipDateVar.touched)" style="font-size: 80%;color: #dc3545">
                            Printer Request Ship Date is required.
                            </mat-error>
                            <div *ngIf="printerReqShipDateVar?.invalid && (printerReqShipDateVar.dirty || printerReqShipDateVar.touched)" class="invalid-feedback">
                            <div *ngIf="printerReqShipDateVar.errors.required">Printer Request Ship Date is required.</div>
                            </div>
                        </div>

                        <div class="col-2">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Printer Week</label>
                            <input name="printerWeek_{{printerInfoIndex}}" class="form-control form-control-sm" style="font-size: 11px;" #printerWeek="ngModel" required 
                            [(ngModel)]="printerInfo.printerWeek" [ngClass]="{'is-invalid':printerWeek.touched && printerWeek.errors}" disabled>
                        
                        </div>
                        <div class="col-2">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Requested Cancel Date</label>
                            <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="printerReqCancelDate"  name="printerReqCancelDate_{{printerInfoIndex}}" [(ngModel)]="printerInfo.printerCancelDate" style="cursor: pointer;font-size: 11px;" [min]="printerInfo.printerRequestShipDate"
                                class="form-control  form-control-sm" required #printerReqCancelDateVar="ngModel" [ngClass]="{'is-invalid':printerReqCancelDateVar.touched && printerReqCancelDateVar.errors}" [disabled]="!editable || printerInfo.obsolete">
                            <mat-datepicker #printerReqCancelDate></mat-datepicker>
                            <button class="btn btn-sm" type="button" (click)="printerReqCancelDate.open()" [disabled]="!editable || printerInfo.obsolete"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <mat-error *ngIf="printerReqCancelDateVar?.errors?.required && (printerReqCancelDateVar.dirty || printerReqCancelDateVar.touched)" style="font-size: 80%;color: #dc3545">
                            Printer Cancel Ship Date is required.
                            </mat-error>
                            <div *ngIf="printerReqCancelDateVar?.invalid && (printerReqCancelDateVar.dirty || printerReqCancelDateVar.touched)" class="invalid-feedback">
                            <div *ngIf="printerReqCancelDateVar.errors.required">Printer Cancel Ship Date is required.</div>
                            </div>
                        </div>

                        <div class="col-2">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Actual Ship Date</label>
                            <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="printerActualShipDate"  name="printerActualShipDate_{{printerInfoIndex}}" [(ngModel)]="printerInfo.printerActualShipDate" style="cursor: pointer;font-size: 11px;"
                                class="form-control  form-control-sm"  #printerActualShipDateVar="ngModel" [ngClass]="{'is-invalid':printerActualShipDateVar.touched && printerActualShipDateVar.errors}" [disabled]="!editable || printerInfo.obsolete">
                            <mat-datepicker #printerActualShipDate></mat-datepicker>
                            <button class="btn btn-sm" type="button" (click)="printerActualShipDate.open()" [disabled]="!editable || printerInfo.obsolete"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <mat-error *ngIf="printerActualShipDateVar?.errors?.required && (printerActualShipDateVar.dirty || printerActualShipDateVar.touched)" style="font-size: 80%;color: #dc3545">
                                Printer Actual Ship Date is required.
                                </mat-error>
                                <div *ngIf="printerActualShipDateVar?.invalid && (printerActualShipDateVar.dirty || printerActualShipDateVar.touched)" class="invalid-feedback">
                                <div *ngIf="printerActualShipDateVar.errors.required">Printer Actual Ship Date is required.</div>
                                </div>
                        </div>

                        <div class="col-2">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">xFactory Date</label>
                            <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="xFactoryDate"  name="xFactoryDate_{{printerInfoIndex}}" [(ngModel)]="printerInfo.xFactoryDate" style="cursor: pointer;font-size: 11px;"
                                class="form-control  form-control-sm"  #xFactoryDateVar="ngModel" [ngClass]="{'is-invalid':xFactoryDateVar.touched && xFactoryDateVar.errors}" [disabled]="!editable || printerInfo.obsolete">
                            <mat-datepicker #xFactoryDate></mat-datepicker>
                            <button class="btn btn-sm" type="button" (click)="xFactoryDate.open()" [disabled]="!editable || printerInfo.obsolete"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                        </div>
                        
                        <div class="col-2">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">ETA Warehouse</label>
                            <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="etaWarehouse"  name="etaWarehouse_{{printerInfoIndex}}" [(ngModel)]="printerInfo.etaWarehouse" style="cursor: pointer;font-size: 11px;"
                                class="form-control  form-control-sm"  #etaWarehouseVar="ngModel" [ngClass]="{'is-invalid':etaWarehouseVar.touched && etaWarehouseVar.errors}" [disabled]="!editable || printerInfo.obsolete">
                            <mat-datepicker #etaWarehouse></mat-datepicker>
                            <button class="btn btn-sm" type="button" (click)="etaWarehouse.open()" [disabled]="!editable || printerInfo.obsolete"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                        </div>
                    
                        
                    </div>

                    <mat-accordion multi>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Trims & Finishing Sheet Info
                                </mat-panel-title>
                                <!--<mat-panel-description>
                                    This is a summary of the content
                                </mat-panel-description>-->
                            </mat-expansion-panel-header>
                            <div>
                                <app-trim-info [productionTracker]="productionTracker" [printerInfo]="printerInfo"
                                    [printerInfoIndex]="printerInfoIndex" [editable]="editable && !printerInfo.obsolete"></app-trim-info>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel > <!--(opened)="panelOpenState = true" (closed)="panelOpenState = false"-->
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Assigned Style(s) : {{getAssignedCount(printerInfo.orderDetailInfoList)}} 
                                </mat-panel-title>
                                <!--<mat-panel-description >
                                    Currently I am {{panelOpenState ? 'open' : 'closed'}}
                                </mat-panel-description>-->
                            </mat-expansion-panel-header>
                            <div >
                                <div class="d-flex align-items-center">
                                    <div class="mr-auto " >
                                        <h6 *ngIf="false">Assigned Style(s) : {{getAssignedCount(printerInfo.orderDetailInfoList)}} </h6>
                                    </div>
                                    <div class="p-2">
                                        <button (click)="attachOrderDetail(salesOrder,printerInfo)" *ngIf="editable && printerInfo.origination==='MANUAL'" class="btn btn-warning btn-sm f-11"><i class="fas fa-link"></i>Assign Styles</button>
                                    </div>
                                </div>
                                <hr class="hr-min">
                                <div *ngIf="printerInfo.orderDetailInfoList && printerInfo.orderDetailInfoList?.length>0">
                                    <div *ngFor="let orderDetailInfo of printerInfo.orderDetailInfoList; index as orderDetailInfoIndex"  >
                                        <ng-container *ngIf="orderDetailInfo.orderDetail">
                                       <app-order-detail-info [productionTracker]="productionTracker" [printerInfo]="printerInfo" [printerInfoIndex]="printerInfoIndex" 
                                        [orderDetailInfo]="orderDetailInfo" [orderDetailInfoIndex]="orderDetailInfoIndex" 
                                        [editable]="editable && orderDetailInfo.orderDetailStatus!=='CANCELLED' && !orderDetailInfo.obsolete"></app-order-detail-info>
                                    </ng-container>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                    
                    

                    
                </div>
            <!--
            <div class="row m-2 p-2">
                <h6>Printer Notes</h6>
                <hr>
                <div class="col-6">
                    <ng-container *ngFor="let printerNotes of printerInfo.printerNotes">
                        {{printerNotes}}
                    </ng-container>
                    <button type="button" class="btn btn-sm btn-info" (click)="openPinterNotesModal()">+ Printer Notes</button>
                </div>
            </div>
            -->
        </ng-container>
        <div class=" my-2 pl-4">
            <button type="button" class="btn btn-sm btn-info" (click)="addPrinterInfo()" 
            *ngIf="false && editable">Add Printer</button>
        </div>
    </form>

</div>
<!--PRINTER SECTION ENDS-->