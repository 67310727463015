<!-- EDITABLE MODE -->

<!--
   <div  *ngIf="editable">
      <div *ngIf="multipleEmails">
         <button type="button" class="btn btn-sm btn-primary mb-2" (click)="addEmail()">Add Email</button>
      </div>

      <div class="container border mb-2 p-3" *ngFor="let email of emailList; let emailIndex = index;">

         <div class="row form-group">
            <label class="col-sm-3 text-right"><i class="fa fa-envelope"></i>&nbsp;Email Type</label>
            <div class="col-sm-3">
               <select name="emailType_{{emailIndex}}" [(ngModel)]="emailList[emailIndex].emailType"
                  class="form-control form-control-sm" #emailTypeVar="ngModel" required
                  [ngClass]="{'is-invalid':emailTypeVar.touched && emailTypeVar.errors}">
                  <option *ngFor="let emailType of emailTypes" [ngValue]="emailType">{{emailType}}</option>
               </select>
               <div *ngIf="emailTypeVar?.invalid && (emailTypeVar.dirty || emailTypeVar.touched)" class="invalid-feedback">
                  <div *ngIf="emailTypeVar.errors.required">Email Type is required.</div>
               </div>
            </div>
            <div class="col-sm" *ngIf="multipleEmails">
               <button type="button" class="btn btn-danger btn-sm float-right" (click)="removeEmail(emailList[emailIndex])">Remove</button>
            </div>
         </div>

         <hr style="margin-top:0.5rem;">

         <div class="row form-group">
            <label class="col-sm-3 col-form-label text-right"><i class="fa fa-globe"></i>&nbsp;Email Address</label>
            <div class="col-sm">
               <input name="email_{{emailIndex}}" [(ngModel)]="emailList[emailIndex].emailAddress"
                  class="form-control form-control-sm" placeholder="Email Address" #emailVar="ngModel" required
                  [ngClass]="{ 'is-invalid':emailVar.touched && emailVar.invalid }" email placeholder="Email"
                  maxlength="50" />
               <div *ngIf="emailVar?.invalid" class="invalid-feedback">
                  <div *ngIf="emailVar.errors.email">Email must be a valid email address</div>
               </div>
            </div>
         </div>

      </div>

   </div>
-->

<!-- Editabe New-->
<div ngModelGroup="EmailMG" style="border: 0px;">

   <div class="container h-100 mb-2" *ngIf="emailList?.length===0 || emailList ==null">
      <div class="row h-100">
         <div class="col-sm-12">
             <div class="w-25 mx-auto"><p class="text-muted"><em>No Email(s) Available.</em></p></div>
         </div>
     </div>
   </div>

   <div *ngIf="editable">
      <div [class]="index===(emailList.length - 1)?'mb-1 p-1 ml-3':'border-bottom mb-1 p-1 ml-3'" *ngFor="let email of emailList; index as index">
         <div class="row form-group">
            <div class="col-sm-4">
               <label class="mb-0 label-medium">Email Type</label>
               <div>
                  <select name="emailType_{{email.id}}_{{email.transientIndex}}" [(ngModel)]="email.emailType" class="form-control form-control-sm" #emailTypeVar="ngModel" required
                     [ngClass]="{'is-invalid':emailTypeVar.touched && emailTypeVar.errors}" [disabled]="!editable">
                     <option *ngFor="let emailType of emailTypes" [ngValue]="emailType">{{emailType}}</option>
                  </select>
                  <div *ngIf="emailTypeVar?.invalid && (emailTypeVar.dirty || emailTypeVar.touched)" class="invalid-feedback">
                     <div *ngIf="emailTypeVar.errors.required">Email Type is required.</div>
                  </div>
               </div>
            </div>

            <div class="col-sm-7">
               <label class="mb-0 label-medium">Email Address</label>
               <div>
                  <input name="email_{{email.id}}_{{email.transientIndex}}" [(ngModel)]="email.emailAddress" class="form-control form-control-sm" placeholder="" #emailVar="ngModel" required
                     [ngClass]="{ 'is-invalid':emailVar.touched && emailVar.invalid }" email placeholder="" maxlength="50"  [disabled]="!editable"/>
                  <div *ngIf="emailVar?.invalid" class="invalid-feedback">
                     <div *ngIf="emailVar.errors.email">Email must be a valid email address</div>
                  </div>
               </div>
            </div>

            <div class="col-sm-1 p-0" *ngIf="multipleEmails && editable" style="align-self: flex-end;">
               <label>&nbsp;</label>
               <a class="btn" style="border: 0px;" (click)="removeEmail(index)" class="ml-2"><i class="fas fa-trash fa-1x"
                     style="color: #dc3545"></i></a>
            </div>
         </div>
      </div>
      <div *ngIf="(multipleEmails && editable) || (!multipleEmails && editable && (emailList?.length===0 || emailList ==null))" class="row col-sm-auto">
         <a (click)="addEmail()" class="btn btn-info btn-sm text-white"><i class="fas fa-plus fa-1x"></i>&nbsp;Email</a>
      </div>

   </div>


   <!-- NON - EDITABLE MODE

   <div *ngIf="!editable">
      <div class="container mb-2" *ngFor="let email of emailList; let emailIndex = index;">

         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label text-right"><i class="fa fa-envelope"></i>&nbsp;Email Type:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{email.emailType}} </span>
            </div>
         </div>

         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label text-right"><i class="fa fa-globe"></i>&nbsp;Email Address:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{email.emailAddress}} </span>
            </div>
         </div>

         <hr>
      </div>
   </div>
   -->

   <div *ngIf="!editable" class="row mt-2">
      <ng-container *ngFor="let email of emailList">
         <div class="col-4 mb-1">
            <div class="card mb-2 h-100 border-0">
               <div>
                  <div class="card-body" style="padding: 0.25rem;">
                     <p class="card-text f-14 font-weight-bold ml-2 mb-0 text-wrap">{{email.emailType}}</p>
                     <p class="card-text ml-2 mb-0 text-truncate">{{email.emailAddress}}</p>
                  </div>
               </div>
            </div>
            
         </div>
      </ng-container>
   </div>


</div>