import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { User } from '../model/user.model';
import { environment } from 'src/environments/environment';
import { SharedService } from './shared.service';
import * as bcrypt from 'bcryptjs';
import { RoleService } from './role.service';
import { UserAccess } from '../model/user-access.model';
import { Permission } from '../model/permission.model';

@Injectable({
   providedIn: 'root'
})

export class AuthService {
   readonly basePath = environment.apiEndPoint;
   redirectUrl: string;

   private isUserLogggedInSubject: BehaviorSubject<boolean>;
   private currentUserSubject: BehaviorSubject<User>;
   private userAccessSubject: BehaviorSubject<UserAccess>;
   private filterEventSubject: BehaviorSubject<any>;



   public isUserLoggedIn: Observable<boolean>;
   public currentUser: Observable<User>;
   public userAccess: Observable<UserAccess>;
   public filterEvent: Observable<any>;

   constructor(private roleService: RoleService,private router: Router, private http: HttpClient, private sharedService: SharedService) {
      this.isUserLogggedInSubject = new BehaviorSubject<boolean>(this.hasToken());
      this.isUserLoggedIn = this.isUserLogggedInSubject.asObservable();

      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('loggedInUser')));
      this.currentUser = this.currentUserSubject.asObservable();

      this.userAccessSubject = new BehaviorSubject<UserAccess>(null);
      this.userAccess = this.userAccessSubject.asObservable();

      this.filterEventSubject = new BehaviorSubject<any>(null);
      this.filterEvent = this.filterEventSubject.asObservable();

      if (this.currentUserValue) {
         this.loadUserRolesAndPermissions(this.currentUserValue.id);
      }
   }

   public get currentUserValue(): User {
      return this.currentUserSubject.value;
   }

   setIsLoggedIn(value: boolean) {
      this.isUserLogggedInSubject.next(value);
   }

   setCurrentUser(user: User) {
      this.currentUserSubject.next(user);
   }

   setUserAccess(access: UserAccess) {
      this.userAccessSubject.next(access);
   }

   setFilterEvent(eventData: any) {
      this.filterEventSubject.next(eventData);
   }

   // Http Options
   httpOptions = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json'
      })
   };

   // Handle API errors
   handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
         // A client-side or network error occurred. Handle it accordingly.
         console.error('An error occurred:', error.error.message);
      } else {
         // The backend returned an unsuccessful response code.
         // The response body may contain clues as to what went wrong,
         console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
      }
      console.error(error);
      // return an observable with a user-facing error message
      return throwError('Something bad happened; please try again later.');
   }

   // Verify user credentials on server to get token
   login(user): Observable<ApiResponse> {
      //("Auth Service - Inside Login...")
      return this.http.post<ApiResponse>(this.basePath + '/login', user, this.httpOptions)
         .pipe(
            retry(1),
            catchError(this.handleError)
         );
   }

   changepassword(data): Observable<ApiResponse> {
      //("Auth Service - Inside changepassword...")
      return this.http.post<ApiResponse>(environment.apiEndPoint + '/login/password/change', data, this.httpOptions)
         .pipe(
            catchError(this.handleError)
         );
   }

   resetpassword(data): Observable<ApiResponse> {
      //("Auth Service - Inside resetpassword...")
      return this.http.post<ApiResponse>(this.basePath + '/login/password/reset', data, this.httpOptions)
         .pipe(
            catchError(this.handleError)
         );
   }

   forgotpassword(data): Observable<ApiResponse> {
      //("Auth Service - forgotpassword...")
      return this.http.post<ApiResponse>(this.basePath + '/login/password/forgot', data, this.httpOptions)
         .pipe(
            catchError(this.handleError)
         );
   }

   // After login save token and other values(if any) in localStorage
   setUser(response: any) {
      //
      let access_token = response.responsePayload.jwt;
      let user = this.decodeAndGetUser(access_token);
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('loggedInUser', JSON.stringify(user));
      this.setIsLoggedIn(true);
      this.setCurrentUser(user);
      this.loadUserRolesAndPermissions(user.id);
      //this.router.navigate(['home']);
      if(user.orgType==='Internal'){
         //this.router.navigate(['product-search']);
         this.router.navigate(['dashboard']);
      }else if(user.orgType==='Printer'){
         //this.router.navigate(['printer-portal/list']);
         this.router.navigate(['dashboard']);
      }

     
      //
   }

   
   unloadUserRolesAndPermissions() {
      this.setIsLoggedIn(false);
      this.setUserAccess(null);
   }

   loadRolesAndPermissions(){
      this.loadUserRolesAndPermissions(this.currentUserValue.id);
   }
   /**
    * 
    * @param userId 
    */
    loadUserRolesAndPermissions(userId: string) {
      this.roleService.loadUserRolesAndPermissions(userId).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let userRoles = response.responsePayload[0];
            let userPermissions: Permission[] = response.responsePayload[1];
            let isUserSupportAdmin=this.sharedService.isSupportAdminUser(userRoles);
            let access: UserAccess = this.sharedService.createUserAccess(userPermissions,isUserSupportAdmin);
            this.setUserAccess(access);
            if (this.redirectUrl) {
               this.router.navigate([this.redirectUrl],{  state: { directUrlAccess: true } });
               this.redirectUrl = undefined;
             }
         }
      }, error => {
         console.error(error);
      });
   }

   // Checking if token is set
   public hasToken() {
      return !!localStorage.getItem('access_token');
   }

   isUserLoggedIns() {
      return localStorage.getItem('access_token') != null;
   }

   // After clearing localStorage redirect to login screen
   logout() {
      localStorage.clear();
      localStorage.removeItem('access_token');
      this.unloadUserRolesAndPermissions();
      this.router.navigate(['login']);
   }

  

   decodeAndGetUser(access_token): User {
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(access_token);
      //
      return decodedToken.user;
   }

   encryptPassword(plainPassword) {
      // const salt = bcrypt.genSaltSync(10);
      // 
      //const result = bcrypt.compareSync(this.user.password, usr.password);
      //
      const hashedPassword = bcrypt.hashSync(plainPassword, '$2a$10$NVxlH0Sob/4jX.corYD7gO');
      //
      return hashedPassword;
   }

}
