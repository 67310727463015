import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SizeOrderDetail } from 'src/app/shared/model/sales/size-order-detail.model';
import { SubOrderDetail } from 'src/app/shared/model/sales/sub-order-detail.model';
import { SizeSelectionModalComponent } from '../size-selection-modal/size-selection-modal.component';

@Component({
  selector: 'app-packaging-type-selection-modal',
  templateUrl: './packaging-type-selection-modal.component.html',
  styleUrls: ['./packaging-type-selection-modal.component.css']
})
export class PackagingTypeSelectionModalComponent implements OnInit {
  editable: boolean = false;
  currentSubOrderDetail: SubOrderDetail;
  constructor(public dialogRef: MatDialogRef<SizeSelectionModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private toastrService:ToastrService) {
    if (data.currentSubOrderDetail) {
      this.currentSubOrderDetail = data.currentSubOrderDetail;
      this.editable = data.editable;
    }
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('30%');
  }

  onPrePackDetailModalOk() {
    let totalSubOrderQuantity: number = this.currentSubOrderDetail.totalSubOrderQuantity;
    let totalPrePackRatio: number = 0;
    let prepackDetail: string = '';
    for (let index = 0; index < this.currentSubOrderDetail.sizeOrderDetailList.length; index++) {
      let sizeOrderDetail: SizeOrderDetail = this.currentSubOrderDetail.sizeOrderDetailList[index];
      totalPrePackRatio += sizeOrderDetail.prepackQuantity;
      prepackDetail = prepackDetail + sizeOrderDetail.prepackQuantity;
      if (index !== (this.currentSubOrderDetail.sizeOrderDetailList.length - 1)) {
        prepackDetail = prepackDetail + '/'
      }
    }

    if (totalSubOrderQuantity % totalPrePackRatio !== 0) {
      this.toastrService.error('Total Quantity does not match and Prepack Ratio.');
      return false;
    } else {
      this.dialogRef.close('CONFIRM');
    }

  }

  onPrePackDetailModalCancel() {
    this.dialogRef.close('CANCEL');
  }
  discardModalWithNoAction() {
    this.dialogRef.close('CLOSE');
  }

}
