import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SearchComponent } from 'src/app/common/search/search.component';
import { SearchDetails } from 'src/app/shared/model/common/search-details.model';
import { FilterConfig } from 'src/app/shared/model/common/search-filter.model';
import { PrinterCapacityViewGrouped, PrinterCapacityViewPrinterGrouped } from 'src/app/shared/model/sales/costing/allocation/printer-capacity/printer-capacity-view-grouped.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { SearchService } from 'src/app/shared/service/search.service';
import { SearchCriteriaBuilder } from 'src/app/shared/model/common/search-criteria-builder.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { RoyaltyMismatchService } from 'src/app/shared/service/sales/royalty-mismatch/royalty-mismatch.service';
import { PrinterCapacityService } from 'src/app/shared/service/sales/costing/allocation/pinter-capacity/printer-capacity.service';

@Component({
  selector: 'app-printer-capacity-list',
  templateUrl: './printer-capacity-list.component.html',
  styleUrls: ['./printer-capacity-list.component.css']
})
export class PrinterCapacityListComponent implements OnInit {

  userAccessSubscription: Subscription;
  userAccess: UserAccess;

  currentLoggedInUserSubscription: Subscription;
  currentLoggedInUser: User;

  //printerCapacityViewGrouped: PrinterCapacityViewGrouped[] = [];
  printerCapacityViewPrinterGrouped:PrinterCapacityViewPrinterGrouped[]=[];
  searchConfig: SearchDetails;
  showWeekWiseDetails:boolean=false;
  sortColumns = [
    { columnName: 'PRINTER_ACTUAL_SHIP_DATE', sortByName: 'Printer Ship Date' },
    { columnName: 'PRINTER_NAME', sortByName: 'Printer' },
    
  ];
  @ViewChild('searchComponent') searchComponent: SearchComponent<PrinterCapacityViewGrouped>;


  constructor(private authService: AuthService,private searchService:SearchService, private sharedService: SharedService, private toastrService: ToastrService, private printerCapacityService: PrinterCapacityService) { }

  ngOnInit(): void {
    this.handleSubscriptions();
    if (!this.searchConfig) {
      this.searchConfig = this.searchService.printerCapacityGroupedListSearchDetails;
      //this.evaluateRequestFilter();
      this.setFilterConfigs();
      
    }
  }
  getPrinterGroupTotalRows(printerGroup:PrinterCapacityViewPrinterGrouped){
    let retVal:number=1;
    if(printerGroup.printerCapacityViewGroupedList){
      let counter:number=0;
      printerGroup.printerCapacityViewGroupedList.forEach(pcvg => {
        counter=counter+pcvg.printerCapacityViews.length;
      });
      retVal=counter;
    }
    return retVal;
  }

  getExpandedRowCount(printerGroup:PrinterCapacityViewPrinterGrouped){
    let retVal:number=0;
    if(printerGroup.printerCapacityViewGroupedList){
      let counter:number=0;
      printerGroup.printerCapacityViewGroupedList.forEach(pcvg => {
        if(pcvg.expanded){
          counter=counter+2;
        }
      });
      retVal=counter;
    }
    return retVal;

  }

  expandPrinterGroup(printerGroup:PrinterCapacityViewPrinterGrouped,expand:boolean){
    if(printerGroup.printerCapacityViewGroupedList){
      printerGroup.printerCapacityViewGroupedList.forEach(pcvg => {
       pcvg.expanded=expand;
      });
    }
  }

  handleSubscriptions() {
    this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
      this.userAccess = access;
    });
    this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
      this.currentLoggedInUser = currentLoggedInUser;
    });
  }

  setFilterConfigs() {
    if (!this.searchConfig.filterConfigs) {
      this.searchConfig.filterConfigs = [];
      let printerShipDate: FilterConfig = {
        filterType: 'DATE_RANGE',
        label: 'Printer Ship Date',
        currentSelection: undefined,
        fieldName: 'PRINTER_ACTUAL_SHIP_DATE'
      };
      this.searchConfig.filterConfigs.push(printerShipDate);
    }
  }


   createPrinterCapacityExcel() {
      let criteria: SearchCriteriaBuilder = JSON.parse(JSON.stringify(this.searchConfig.searchCriteria));
      criteria.loadAll = true;
      criteria.loadMore == false;
      criteria.loadedCount = 0;
      this.printerCapacityService.createPrinterCapacityExcel(criteria).subscribe(res => {
         if (res && res !== null && res.size > 0) {
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = today.getFullYear();
            const todayStr = dd + '-' + mm + '-' + yyyy;
            const filename = 'PrinterCapacity' + '_' + todayStr + ".xlsx"
            this.toastrService.success("Printer Capacity Exported Successfully.");
            this.sharedService.downLoadFile(res, "application/vnd.ms-excel", filename);
         } else {
            this.toastrService.error("Printer Capacity Export Failed.");
         }
      }, err => {
         this.toastrService.error("Printer Capacity Export Failed. Error - " + err);
      });
   }

}
