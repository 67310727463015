import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../model/api-response';
import { ExternalLinks } from '../../model/common/external-links.model';


@Injectable({
  providedIn: 'root'
})
export class ExternalLinksService {

  constructor(private http: HttpClient) { }

  readonly rooturl = environment.apiEndPoint;

  getExternalLinksList(parentId: string,type:string,subType:string): Observable<ApiResponse> {
     return this.http.get<any>(this.rooturl + '/externalLinks/list/'+parentId+'/'+type+'/'+subType);
  }

  saveExternalLinksList(externalLinksList: ExternalLinks[],parentId:string) {
     return this.http.post<any>(this.rooturl + '/externalLinks/save/'+parentId, externalLinksList);
  }

 
}
