import { Component, OnInit, Inject, Input, EventEmitter, Output } from '@angular/core';
import { SalesService } from 'src/app/shared/service/sales/sales.service';
import { ToastrService } from 'ngx-toastr';
import { PurchaseOrderView, PurchaseOrderViewList } from 'src/app/shared/model/sage/purchase-order-view.model';
import { ProductAttachment } from 'src/app/shared/model/product.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { SharedService } from 'src/app/shared/service/shared.service';
import { PurchaseOrderDetailView } from 'src/app/shared/model/sage/purchase-order-detail-view.model';

@Component({
   selector: 'app-purchase-order',
   templateUrl: './purchase-order.component.html',
   styleUrls: ['./purchase-order.component.css']
})
export class PurchaseOrderComponent implements OnInit {

   constructor(private salesService: SalesService, private toastrService: ToastrService,private dialog: MatDialog, private sharedService:SharedService) { }
   @Input() userAccess:UserAccess;
   @Input() summaryId: string;
   @Input() summaryType: string;
   @Input() allowPoCreation: boolean;
   @Input() latestSummary:boolean;
   
   
   

   @Output() event = new EventEmitter<string>();
   //povdLocationMasterSelector:string;
   

   purchaseOrderViewList:PurchaseOrderViewList;
   //purchaseOrderView: PurchaseOrderView;
   purchaseOrderViewListOriginal: PurchaseOrderViewList;
   //printerPOAttachments:ProductAttachment[]=[];
   ngOnInit(): void {
      this.getPurchaseOrderView(this.summaryId);
   }

   private getPurchaseOrderView(summaryId: string) {
      if (this.summaryType === 'PRINTER') {
         this.salesService.getPrinterPurchaseOrderView(summaryId).subscribe(res => {
            if (res.responseStatus.status === 'SUCCESS') {
               this.purchaseOrderViewList = res.responsePayload;
               //this.sort(this.purchaseOrderViewList);
               this.purchaseOrderViewListOriginal=cloneDeep(res.responsePayload);
               if(this.purchaseOrderViewList.purchaseOrderViews!==null){
                  this.purchaseOrderViewList.purchaseOrderViews.forEach(pov => {
                     if(pov && pov!=null && pov.companyCode){
                        this.poCompanyCodeChanged(pov,true);
                        /*if(this.purchaseOrderView.details && this.purchaseOrderView.details!==null&& this.purchaseOrderView.details.length>0){
                           this.povdLocationMasterSelector=this.purchaseOrderView.details[0].location;
                        }*/
                     }
                  });
               }
               
               
            }
         })
      }
      /*if (this.summaryType === 'BLANKS_SUPPLIER') {
         this.salesService.getBlanksSupplierPurchaseOrderView(summaryId).subscribe(res => {
            if (res.responseStatus.status === 'SUCCESS') {
               purchaseOrderView = res.responsePayload;
            }
         })
      }*/
   }
   sort(purchaseOrderViewList: PurchaseOrderViewList) {
     if(purchaseOrderViewList && purchaseOrderViewList.purchaseOrderViews && purchaseOrderViewList.purchaseOrderViews.length>1){
      

      let inActivePoViews:PurchaseOrderView[]=purchaseOrderViewList.purchaseOrderViews.filter(x=>x.poStatus==='PO_CANCELLATION_SUCCESS');
      let activePoViews:PurchaseOrderView[]=purchaseOrderViewList.purchaseOrderViews.filter(x=>x.poStatus!=='PO_CANCELLATION_SUCCESS');
      let retVal=[]
      if(activePoViews.length>0 || inActivePoViews.length>0){
         if(activePoViews.length>0){
            retVal.push(...activePoViews);
         }
         if(inActivePoViews.length>0){
            inActivePoViews=this.sharedService.sortListByPropertyName(inActivePoViews,'poCreationDate','DESC');
            retVal.push(...inActivePoViews);
         }
         purchaseOrderViewList.purchaseOrderViews=retVal;
      }
      /*
      const sortOrder = ['PO_CANCELLATION_SUCCESS'];
      purchaseOrderViewList.purchaseOrderViews.sort((a, b) => {
         // Get the index of each element in the sortOrder array
         const indexA = sortOrder.indexOf(a.poStatus);
         const indexB = sortOrder.indexOf(b.poStatus);
       
         // Compare the indices to determine the order
         return indexA - indexB;
       });*/
     }

   }


   createPO(purchaseOrderView:PurchaseOrderView) {
      if (this.summaryType === 'PRINTER') {
         this.createPrinterPurchaseOrder(this.summaryId, purchaseOrderView);
      }

      if (this.summaryType === 'BLANKS_SUPPLIER') {
         this.createBlanksSupplierPurchaseOrder(this.summaryId, purchaseOrderView);
      }
   }

   updatePO(purchaseOrderView:PurchaseOrderView) {
      if (this.summaryType === 'PRINTER') {
        /* if (purchaseOrderView.companyCode !== purchaseOrderViewOriginal.companyCode) {

            const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
               disableClose: true,
               data: {
                  title: 'Company Code Changed - Cancel & Create New PO' ,
                  message: 'Company Code is being modified from '+ purchaseOrderViewOriginal.companyCode+' to ' +purchaseOrderView.companyCode+
                  '<BR> This change will cancel the '+purchaseOrderView.poNumber +' in '+purchaseOrderViewOriginal.companyCode+' and create a NEW PO in '+purchaseOrderView.companyCode,
                  confirmBtnText: 'Yes',
                  cancelBtnText: 'Cancel'
               }
            });
            confirmDialog.afterClosed().subscribe(res => {
               if (res) {
                  this.toastrService.error('Implement Cancel & Create feature');
               } else {

               }
            });
         }else{*/
            this.updatePrinterPurchaseOrder(this.summaryId, purchaseOrderView);
         //}
      }
   }

   cancelPO(purchaseOrderView:PurchaseOrderView){
      if (this.summaryType === 'PRINTER') {
         const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
               maxlength: 250,
               modalType: 'TEXTAREA_INPUT',
               textInputRegex: '^(.|\\s)*\\S(.|\\s)*$',
               textInputDefault: '',
               textInputRegexError: 'Reason is required',
               title: 'Cancel  ' +purchaseOrderView.poNumber,
               message: 'Are you sure you want to cancel ' +purchaseOrderView.poNumber+ '? If Yes, Please provide a reason.',
               confirmBtnText: 'Yes',
               cancelBtnText: 'Cancel'
            }
         });
         confirmDialog.afterClosed().subscribe(res => {
            if (res) {
               purchaseOrderView.cancellationReason=res;
               this.cancelPrinterPurchaseOrder(this.summaryId, purchaseOrderView);
            } else {
   
            }
         });
         
      }
   }

   poCompanyCodeChanged(purchaseOrderView:PurchaseOrderView,retainPurchaseOrderValues?:boolean){
      this.salesService.getPOMetadata(purchaseOrderView.companyCode).subscribe(res => {
         purchaseOrderView.shipToList=[];
         purchaseOrderView.shipViaList=[];
         purchaseOrderView.billToList=[];
         
         if(!retainPurchaseOrderValues){
            purchaseOrderView.shipToCode=undefined;
            purchaseOrderView.shipViaCode=undefined;
            purchaseOrderView.billToCode=undefined;
            purchaseOrderView.povdLocationMasterSelector=undefined;
            this.purchaseOrderDetailLocationMasterSelectionChanged(purchaseOrderView);
         }
         
         if (res.responseStatus.status === 'SUCCESS') {
            //this.toastrService.success(res.responseStatus.message);
            //console.log('Get PO Metdata response: ',res.responsePayload);
            let metadataList=res.responsePayload;
            if(metadataList && metadataList.length>0){
               metadataList.forEach(metadata => {
                  if(metadata.metadataType==='po_ship_via'){
                     purchaseOrderView.shipViaList=JSON.parse(metadata.metadataJson);
                  }
                  if(metadata.metadataType==='po_ship_to'){
                     purchaseOrderView.shipToList=JSON.parse(metadata.metadataJson);
                  }
                  if(metadata.metadataType==='po_bill_to'){
                     purchaseOrderView.billToList=JSON.parse(metadata.metadataJson);
                  }
               });
            }
            //console.log(this.shipTo,this.shipVia,this.billTo);
         } else {
            this.toastrService.error(res.responseStatus.message);
         }
      }, err => {
         this.toastrService.error("Purchase Order Metadata retrieval failed. Error - " + err);
      });
   }

   private createPrinterPurchaseOrder(summaryId: string, purchaseOrderView: PurchaseOrderView) {
      this.salesService.createPrinterPurchaseOrder(summaryId, purchaseOrderView, purchaseOrderView.companyCode).subscribe(res => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.event.emit('RELOAD_SUMMARY');
            this.toastrService.success(res.responseStatus.message);
            //console.log(res.responsePayload);
         } else {
            this.toastrService.error(res.responseStatus.message);
         }
      }, err => {
         this.toastrService.error("Purchase Order Creation Failed. Error - " + err);
      });
   }

   private updatePrinterPurchaseOrder(summaryId: string, purchaseOrderView: PurchaseOrderView) {
      this.salesService.updatePrinterPurchaseOrder(summaryId, purchaseOrderView, purchaseOrderView.companyCode).subscribe(res => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.event.emit('RELOAD_SUMMARY');
            this.toastrService.success(res.responseStatus.message);
            //console.log(res.responsePayload);
         } else {
            this.toastrService.error(res.responseStatus.message);
         }
      }, err => {
         this.toastrService.error("Purchase Order Update Failed. Error - " + err);
      });
   }
   
   private cancelPrinterPurchaseOrder(summaryId: string, purchaseOrderView: PurchaseOrderView) {
      this.salesService.cancelPrinterPurchaseOrder(summaryId, purchaseOrderView, purchaseOrderView.companyCode).subscribe(res => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.event.emit('RELOAD_SUMMARY');
            this.toastrService.success(res.responseStatus.message);
            //console.log(res.responsePayload);
         } else {
            this.toastrService.error(res.responseStatus.message);
         }
      }, err => {
         this.toastrService.error("Purchase Order Cancellation Failed. Error - " + err);
      });
   }

   addManualPurchaseOrderViewDetail( purchaseOrderView: PurchaseOrderView){
      if(!purchaseOrderView.details|| purchaseOrderView.details===null){
         purchaseOrderView.details=[];
      }
      let newPodv:PurchaseOrderDetailView=new PurchaseOrderDetailView();
      newPodv.location=purchaseOrderView.povdLocationMasterSelector;
      newPodv.entryMethod="MANUAL";
      newPodv.editingInProgress=true;
      
      if(purchaseOrderView.details.length>0){
         newPodv.currency=purchaseOrderView.details[0].currency;
      }
      purchaseOrderView.details.push(newPodv);
   }

   removeManualPurchaseOrderViewDetail(purchaseOrderView: PurchaseOrderView, podvToRemove:PurchaseOrderDetailView){
      purchaseOrderView.details=purchaseOrderView.details.filter(x=>x!==podvToRemove);
   }


   private createBlanksSupplierPurchaseOrder(summaryId: string, purchaseOrderView: PurchaseOrderView) {
      this.salesService.createBlanksSupplierPurchaseOrder(summaryId, purchaseOrderView, 'PHILCOS_USA').subscribe(res => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.event.emit('RELOAD_SUMMARY');
            this.toastrService.success(res.responseStatus.message);
            //console.log(res.responsePayload);
         } else {
            this.toastrService.error(res.responseStatus.message);
         }
      }, err => {
         this.toastrService.error("Purchase Order Creation Failed. Error - " + err);
      });
   }

   purchaseOrderDetailLocationMasterSelectionChanged(psView:PurchaseOrderView){
      psView.details.forEach(psvDetails => {
         psvDetails.location=psView.povdLocationMasterSelector;
      });
   }

}
