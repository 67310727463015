<form name="notesForm" #notesForm="ngForm" autocomplete="off">

    <ng-container *ngIf="parentId && parentId !=='' && userAccess && currentLoggedInUser" style="background:white">
 
       <div class="mt-1">
          <h6 class="f-11">
             <span  *ngIf="notesHeaderText && notesHeaderText!==''">{{notesHeaderText}}</span>
             <span *ngIf="notesHeaderSubText1 !=undefined && notesHeaderSubText1 !==''"> - {{notesHeaderSubText1}}</span>
             <span *ngIf="notesHeaderSubText2 !=undefined && notesHeaderSubText2 !==''"> - {{notesHeaderSubText2}}</span>
          </h6>
       </div>
      
 
 
       <div class="p-1">
          <div class="f-11 card-body border"  #notesScrollSection [style]="'max-height: calc('+vh+'vh); overflow-y: auto; overflow-x: hidden;background:white;border-radius:5px'">
             <div class="">
                <ng-container *ngIf="notesList && notesList.length>0">
                   <ng-container *ngFor="let notes of notesList">
                      <div>
                         
                         <div class="mb-1">
                            <div class="">
                                <div class="f-9 d-flex justify-content-end align-items-center">
                                    <div class="text-muted"><em>{{notes.createdBy}}</em></div>
                                    <div class="text-muted mx-2"><em>{{notes.createdDate | date:'medium'}}</em></div>
                                    
                                </div>
                                
                            </div>
                            <div class="card card-body px-2 py-1" [style]="notes.priority===10?'background: #ff840426;':''">
                               
                              <!--<span *ngIf="!notes.priority===10">
                                 <i class="far fa-star fa-lg"
                                     style="color: #6c757d24;" 
                                     onmouseover="this.style.color='tomato'" 
                                     onmouseout="this.style.color='#6c757d24'">
                                 </i>
                             </span>
                              <span *ngIf="notes.priority===10">
                                    <i class="fas fa-star fa-lg" style="color: tomato;"  >
                                    </i>
                              </span>-->
                                <div class="d-flex align-items-start">
                                    <div class="d-flex justify-content-start" style="white-space: pre-wrap;">
                                            <span *ngIf="!notes.id" class="mr-2" (click)="deleteNotes(notes)"><i class="fas fa-trash" style="color:red;cursor: pointer;"></i></span>
                                        <span style="padding-right: 10px;">{{notes?.description}}</span>
                                    </div>
                                 </div>
                                    <div class="f-10  nofocus"  style="position: absolute; right: 0%;">
                                        <!--<span  [matMenuTriggerFor]="menu" class=" p-3" aria-label="icon-button with a options">
                                            <i class="fas fa-ellipsis-v "></i>
                                        </span>
                                        <mat-menu #menu="matMenu" xPosition="before">
                                        <button class="nofocus f-10" (click)="togglePriority(notes)" mat-menu-item>{{notes.priority===10?'Mark Low Priority':'Mark High Priority'}}</button>
                                        </mat-menu>-->
                                        <div (click)="togglePriority(notes)" class="px-2">
                                             <span *ngIf="notes.priority!==10">
                                                <i class="far fa-star fa-lg" style="color: #6c757d24;" onmouseover="this.style.color='tomato'"
                                                   onmouseout="this.style.color='#6c757d24'">
                                                </i>
                                             </span>
                                             <span *ngIf="notes.priority===10">
                                                <i class="fas fa-star fa-lg" style="color: tomato;">
                                                </i>
                                             </span>
                                    </div>

                                    </div>
                                
                               
                            </div>
                            
                         </div>
                      </div>
                      
                   </ng-container>
                </ng-container>
                <ng-container *ngIf="!notesList || notesList.length===0">
                   <ul>
                      <div class="text-muted text font-italic font-weight-bold" style="text-align-last: center;">Notes Not Available</div>
                   </ul>
                </ng-container>
             </div>
          </div>
 
 
 
          <div class="px-1 pt-2" *ngIf="editable">
             <div class="row d-flex align-items-center mx-1">
                <div class="col-10 pr-0">
                   <div>
                      <!--<label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Notes</label>-->
                      <textarea name="notes" [rows]="getEnterCount(descriptionVar.value,3,1)" [(ngModel)]="description" #descriptionVar="ngModel" class="form-control form-control-sm" maxlength="4500"
                          required></textarea>
                      <!-- [ngClass]="{'is-invalid':descriptionVar.touched && descriptionVar.errors}"
                         <div *ngIf="descriptionVar?.invalid && (descriptionVar.dirty || descriptionVar.touched)" class="invalid-feedback">
                         <div *ngIf="descriptionVar.errors.required">
                            Notes is required.
                         </div>
                      </div>-->
                   </div>
                </div>
                <div class="col-2 pl-0 d-flex align-self-end" >
                   <div class="align-self-end">
                      <button type="button" class="btn btn-info btn-block f-11" [disabled]="notesForm.invalid" (click)="addNotes()"><i class="fas fa-plus"></i></button>
                   </div>
                   
                </div>
             </div>
          </div>
 
        </div>
 
       
 
    </ng-container>
 
 
 
   
 
 </form>