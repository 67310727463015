import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/service/auth.service';
import { VendorsService } from 'src/app/shared/service/vendors/vendors.service';
import { BlanksSupplier } from 'src/app/shared/model/vendors/blanks-supplier.model';

@Component({
   selector: 'app-blanks-supplier-list',
   templateUrl: './blanks-supplier-list.component.html',
   styleUrls: ['./blanks-supplier-list.component.css']
})
export class BlanksSupplierListComponent implements OnInit {

   constructor(private sharedService: SharedService, private service: VendorsService,
      private activatedRoute: ActivatedRoute, private authService: AuthService, private toastrService: ToastrService) { }

   groupedItemsList: any;
   itemList: any[] = [];
   filteredItemList: any[] = [];
   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   filterEventsubscription: Subscription;
   editable: boolean;


   ngOnInit() {
      this.handleSubscriptions();
      this.loadItems();
      window.scrollTo(0, 0);
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
      this.filterEventsubscription = this.authService.filterEvent.subscribe(filterEventData => {
         if (filterEventData != null) {
            this.onKeyupBlanksSupplierNameFilter(filterEventData.event, filterEventData.value);
         }
      });
   }

   onKeyupBlanksSupplierNameFilter(event, value) {
      if (event.key !== 'Shift') {
         if (value.trim().length > 0) {
            this.filteredItemList = this.itemList.filter(items => items.name.toUpperCase().indexOf(value.toUpperCase()) > -1);
         } else {
            this.filteredItemList = this.itemList;
         }
      }
      this.groupList();
   }

   loadItems() {
      this.service.getBlanksSupplierList().subscribe(response => {
         this.itemList = response.responsePayload;
         this.filteredItemList = this.itemList;
         this.filteredItemList = this.sharedService.sortListByPropertyName(this.filteredItemList, 'name');
         this.groupList();
      }, err => {
         console.error('Load Item List Error : ' + JSON.stringify(err) + ' with status - ');
      });
   }

   deleteItem(item: any) {
   }


   groupList() {
      let grouped = this.filteredItemList.reduce((groups, item) => {
         const letter = item['name'].charAt(0).toUpperCase();
         groups[letter] = groups[letter] || [];
         groups[letter].push(item);
         return groups;
      },
         {}
      );
      let groupedList = Object.keys(grouped).map(key => ({ key, items: grouped[key] }));
      this.groupedItemsList = groupedList;
   }


   deleteBlanksSupplier(blanksSupplier: BlanksSupplier) {
      if (confirm('Are you sure you want to delete the Blanks Supplier?')) {
         this.service.deleteBlanksSupplier(blanksSupplier).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.loadItems();
               this.toastrService.success("Successfully deleted the blanksSupplier - " + blanksSupplier.name);
            } else {
               this.toastrService.error("Error during deleting the blanksSupplier - " + blanksSupplier.name);
            }
         });
      }
   }


}
