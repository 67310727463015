import { Brand } from './brand.model';

export class Royalty {
   id: string;
   licensorId: string;
   licensorName: string;
   brandId: string;
   brandName: string;
   notes: string;
   productCategories: string;

   productCategoryList: string[];

   brandList: Brand[];
   editable: boolean = true;

   royaltyFeeDetails: RoyaltyFeeDetail[];
   allRoyaltyFeeDetails: RoyaltyFeeDetail[] = [];
   royaltyTierDetails: RoyaltyTierDetail[]
   allRoyaltyTierDetails: RoyaltyTierDetail[] = [];
   hasError: boolean;

   constructor() {
      this.productCategoryList = [];
   }
}

export class RoyaltyFeeDetail {
   id: string;
   licensorId: string;
   brandId: string;
   feeType: string;
   feeValue: number;
   parentId: string;
   public toString = (): string => {
      return this.feeType;
   }
}

export class RoyaltyTierDetail {
   id: string;
   licensorId: string;
   brandId: string;
   tierType: string;
   tierValue: number;
   parentId: string;
}
