import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from 'src/app/shared/service/dashboard.service';
import { DashletDetails } from 'src/app/shared/model/dashoard/dashlet-details.model';

@Component({
   selector: 'app-dashlet-chart',
   templateUrl: './dashlet-chart.component.html',
   styleUrls: ['./dashlet-chart.component.css']
})
export class DashletChartComponent implements OnInit {

   constructor(private dashboardService: DashboardService) { }

   @Input() dashletId: string;
   @Input() dashletType: string;
   dashletDetails: DashletDetails;
   width = document.documentElement.clientWidth;
   height = document.documentElement.clientHeight;

   ngOnInit(): void {
      this.loadDashlet();
   }

   ngOnDestroy() {
   }

   loadDashlet() {
      this.dashboardService.getDashlet(this.dashletId, this.dashletType).subscribe((res) => {
         this.dashletDetails = res as DashletDetails;
         this.dashletDetails.chart = JSON.parse(this.dashletDetails.chart);
         if(this.width && this.width<900){
            this.dashletDetails.chart.options.width=this.width - 40;
            this.dashletDetails.chart.options.height=this.height*.28;
            this.dashletDetails.chart.options.chartArea.width=(this.width) - 50;
            this.dashletDetails.chart.options.chartArea.height=this.height*.25;
         }else{
            this.dashletDetails.chart.options.width=(this.width *.32) - 30;
            this.dashletDetails.chart.options.height=this.height*.28;
            this.dashletDetails.chart.options.chartArea.width=(this.width *.32) - 40;
            this.dashletDetails.chart.options.chartArea.height=this.height*.27;
         }
      });
   }

   onSelect(data, event) {
      console.log('Dashboard event ->', event);
      const { row, column } = event.selection[0];
      const montAndyear = data[row][0];
      const sales = data[row][column];
      console.log('selection', montAndyear, sales);
      /*let selectedItem;
      if (column === 1) {
           selectedItem = "current";
      }
      if (column === 2) {
           selectedItem = "target";
      }*/

   }

   onReady(event) {

   }

}
