import { SearchCriteriaBuilder } from "./search-criteria-builder.model";
import { FilterConfig, SearchFilter } from "./search-filter.model";

export class SearchDetails {
    searchType:string;
    searchCriteria:SearchCriteriaBuilder;
    filterConfigs:FilterConfig[];
    filterConfigsSelectedCount:number;
    defaultNonDisplayFilterConfigs:FilterConfig[];
    lastSelection: number;
    loadMoreCount:number=25;
    private defaultSort:string;
    private defaultSortMode:string;

    constructor(searchType:string,defaultSort?:string,defaultSortMode?:string,loadMoreCount?:number){/*type:string,selectedSortByName:string,sortMode:string*/
        this.searchType=searchType;
        if(defaultSort){
        this.defaultSort=defaultSort;
        }
        if(defaultSortMode){
            this.defaultSortMode=defaultSortMode;
        }
        if(loadMoreCount){
            this.loadMoreCount=loadMoreCount;
        }
        this.reset();
        
    }

    reset() {
        
        this.searchCriteria=new SearchCriteriaBuilder(this.searchType);
        this.searchCriteria.selectedSortByName=this.defaultSort;
        this.searchCriteria.sortMode=this.defaultSortMode;
        this.resetLoadMoreCount();
     }
     resetLoadMoreCount() {
        this.searchCriteria.loadMoreCount = this.loadMoreCount;
     }

     setResultMetadata(searchresult:SearchCriteriaBuilder){
        
        this.searchCriteria.totalCount=searchresult.totalCount;
        this.searchCriteria.loadMore=searchresult.loadMore;
        this.searchCriteria.loadMoreCount=searchresult.loadMoreCount;
        this.searchCriteria.loadedCount=searchresult.loadedCount;
        this.searchCriteria.previousSearchQuery=searchresult.previousSearchQuery;
    }

    setFilterInList(filterName:string,filterType:string,values:string[]){
        let filter = this.setFilter( filterName, filterType, values, this.searchCriteria.filterInList);    
    }
    setFilterNotInList(filterName:string,filterType:string,values:string[]){
        let filter = this.setFilter( filterName, filterType, values, this.searchCriteria.filterNotInList);    
    }

    setFilterLikeInListWithAnd(filterName:string,filterType:string,values:string[]){
        let filter = this.setFilter( filterName, filterType, values, this.searchCriteria.filterLikeInListWithAnd);    
    }
    setFilterLikeInListWithOr(filterName:string,filterType:string,values:string[]){
        let filter = this.setFilter( filterName, filterType, values, this.searchCriteria.filterLikeInListWithOr);    
    }
    /*setFilterCustomDateRange(filterName:string,filterType:string,values:string[]){
        let filter = this.setFilter( filterName, filterType, values, this.searchCriteria.filterCustomDateRange);    
    }*/
    setFilterDateRange(filterName:string,filterType:string,values:string[]){
        let filter = this.setFilter( filterName, filterType, values, this.searchCriteria.filterDateRange);    
    }



    private setFilter(filterName: string, filterType: string, values: string[], filterList: SearchFilter[]) {
        let filter = this.getExistingFilter(filterList, filterName);    
        if (filter) {
            filter.filterName = filterName;
            filter.filterType = filterType;
            filter.values = values;
        } else {
            filter = new SearchFilter(filterName, filterType, values);
            filterList.push(filter);
        }
        return filter;
    }

    getExistingFilter(filterList: SearchFilter[], filterName: string) {
        let filter:SearchFilter;
        filterList.forEach(searchFilter => {
            if (searchFilter.filterName === filterName) {
                filter = searchFilter;
            }
        });
        return filter;
    }

    

    

    
}
