import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ContactDetails } from 'src/app/shared/model/contact-details.model';
import { NgForm } from '@angular/forms';

@Component({
   selector: 'app-contact-details',
   templateUrl: './contact-details.component.html',
   styleUrls: ['./contact-details.component.css']
})

export class ContactDetailsComponent implements OnInit {

   constructor() { }
   @Input()
   addEditContactDetail: ContactDetails;
   @Input() myParentForm: NgForm;
   @Input() editable: boolean;
   @Input() showProfile: boolean;
   @Input() showPhone: boolean;
   @Input() showEmail: boolean;
   @Input() showAddress: boolean;
   @Input() multipleEmails: boolean;
   @Input() multipleAddress: boolean;
   @Input() multiplePhones: boolean;
   @Input() tabbed: boolean = false;
   @Input() licensorId: string;
   @Input() showAddressType: boolean = true;
   
   contactDetailComponents: string[] = ['Profile', 'Phone', 'Email', 'Address'];
   contactDetailComponentsMG: string[] = ['ProfileMG', 'PhoneMG', 'EmailMG', 'AddressMG'];

   selectedComponent: string = this.contactDetailComponents[0];

   ngOnInit(): void {
      this.selectedComponent = this.contactDetailComponents[0];
   }

   onComponentClicked(componentSelected: string) {
      this.selectedComponent = componentSelected;
   }
}
