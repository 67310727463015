
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-from-list-dialog',
  templateUrl: './select-from-list-dialog.component.html',
  styleUrls: ['./select-from-list-dialog.component.css']
})
export class SelectFromListDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SelectFromListDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  selectedList:any[]=[];
  ngOnInit(): void {
  }

  select(item:any) {
    this.dialogRef.close(item);
}

confirmMultiSelection() {
  this.dialogRef.close(this.selectedList);
}

confirmSingleSelection() {
  this.dialogRef.close(this.selectedList);
}
  close() {
    this.dialogRef.close();
}

delete(item:any){
  item.delete=true;
  this.dialogRef.close(item);
}

selectedItems(event:any,item:any,list:any[]){
  if (event.target.checked) {
    this.add(item,list);
 } else {
    this.remove(item,list);
 }
}

selectedItemsForSingleSelect(event:any,item:any,list:any[]){
  if(list && list.length>0){
    list.length=0;
  }
  if (event.target.checked) {
    this.add(item,list);
 } 
}

add(item: string,list:any[]) {
  if (!this.isSelected(item,list)) {
     list.push(item);
  }
}

remove(item: string,list:any[]) {
  //
  let tempList= list.filter(itemInList => itemInList !== item);
  list.length = 0;
  if(tempList && tempList.length>0){
    list.push(...tempList);
  }
}

isSelected(item: string, list: any[]): boolean {
  let temp: any[] = list.filter(itemInList => itemInList === item);
  return (temp.length == 1);
}

onKeyupModalFilter(value:string) {
  if (value.trim().length > 0) {
     
  } else {
     
  }
}

}
