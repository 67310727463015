export class PurchaseOrderDetailView {
   itemNumber: string;
   itemDescription: string;
   quantityOrdered: number;
   unitCost: number;
   currency:string;
   location: string;
   expectedArrival: string;
   entryMethod:string;
   //transient
   editingInProgress:boolean;
}
