import { Address } from './address.model';
import { Phone } from './phone.model';
import { Email } from './email.model';
import { ContactDetails } from './contact-details.model';
export class GroupContactDetails {
   id: string;
   type: string;
   subType: string;
   addressList: Address[] = [];
   phoneList: Phone[] = [];
   emailList: Email[] = [];
   location: string;
   companyName: string;
   contactDetailList: ContactDetails[] = [];
   constructor(){
      this.addressList = [];
   }
}
