import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserManagementService } from 'src/app/shared/service/user-management.service';
import { User } from 'src/app/shared/model/user.model';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/shared/service/shared.service';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
   selector: 'app-user-list',
   templateUrl: './user-list.component.html',
   styleUrls: ['./user-list.component.css']
})

export class UserListComponent implements OnInit, OnDestroy {
   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   users: User[];
   filteredUserList: User[];
   userStatusRadio: string = "true";

   constructor(private sharedService: SharedService, private router: Router, private userManagementService: UserManagementService,private authService:AuthService) {
   }

   ngOnInit() {
      this.handleSubscriptions();
      this.getUsers();
      window.scrollTo(0, 0);
   }

   handleSubscriptions() {
      // Subscriptions
      //
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
         //
      });
      //
   }

   getUsers() {
      this.userManagementService.getUsers().subscribe(response => {
         this.users = response.responsePayload;
         this.filteredUserList = this.getUsersByStatus();
         this.filteredUserList =this.sharedService.sortListByPropertyName(this.filteredUserList,'firstName');
      });
   }

   addUser(): void {
      this.router.navigate(['user/create']);
   };

   viewUser(user: User): void {
      this.router.navigate(['user/view/' + user.id.toString()]);
   };

   editUser(user: User): void {
      this.router.navigate(['user/edit/' + user.id.toString()]);
   };

   deleteUser(user: User): void {
      this.userManagementService.deleteUser(user.id)
         .subscribe(data => {
            this.users = this.users.filter(u => u !== user);
         })
   };

   ngOnDestroy(): void {
      this.userAccessSubscription.unsubscribe();
   }

   onKeyupUserFilter(event, value) {
      if (event.key !== 'Shift') {
         if (value.trim().length > 0) {
            this.filteredUserList = this.getUsersByStatus().filter(items => items.firstName.toUpperCase().indexOf(value.toUpperCase()) > -1);

         } else {
            this.filteredUserList = this.getUsersByStatus();
         }
      }
   }

   getUsersByStatus():User[]{
      
      return this.users.filter(item => ((item.active && this.userStatusRadio==="true") || (!item.active && this.userStatusRadio==="false")));
   }

   statusChange(status:string){
      this.userStatusRadio=status;
      this.filteredUserList=this.getUsersByStatus();
   }



}
