import { Component, OnInit, Input } from '@angular/core';
import { Email } from 'src/app/shared/model/email.model';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
   selector: 'app-email',
   templateUrl: './email.component.html',
   styleUrls: ['./email.component.css'],
   viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class EmailComponent implements OnInit {

   constructor(private metadataService: MetadataService) { }

   emailTypes: string[];
   @Input() editable: boolean;
   @Input() multipleEmails: boolean = true;
   @Input() contactType: string;

   @Input()
   emailList: Email[];

   ngOnInit(): void {
      this.loadEmailTypes();
   }

   private loadEmailTypes() {
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('PROFILE', 'EMAIL', 'TYPE').subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.emailTypes = response.responsePayload;
         }
      }, error => {
         console.error(error);
      });
   }

   addEmail() {
      let email = new Email;
      email.transientIndex=this.emailList.length;
      this.emailList.push(email);

   }

   removeEmail(index: number) {
      //if (this.emailList.length !== 1) {
      this.emailList.splice(index, 1);
      //this.emailList=this.emailList.filter(x=>x!=emailToRemove);
      //}
   }

}
