<div *ngIf="!tabbed">
   <div class="row f-12">

   <div class="col-12" *ngIf="showProfile">
   <!--   
      <div >
         <fieldset class="border p-2 shadow" style="background:white;">
            <legend class="fieldset-border"><i class="fa fa-user"></i>&nbsp;Profile</legend>
            <app-profile [profile]="addEditContactDetail?.profile" [editable]="editable" [contactType]="addEditContactDetail.type"></app-profile>
         </fieldset>
      </div>
   -->
      <div class="card mb-4 shadow">
         <div>
            <div class="card-header text-white bg-secondary" style="padding: .3rem .3rem;">
               <div class="row">
                  <div class="col-12 text-truncate" style="align-self: center;font-size: .90rem;">
                     &nbsp;<i class="fa fa-user"></i>&nbsp;&nbsp;Profile
                  </div>
               </div>
            </div>
            <div class="card-body" style="padding: 0.25rem;">
               <app-profile [profile]="addEditContactDetail?.profile" [editable]="editable"  [licensorId]="licensorId" [contactType]="addEditContactDetail.type"></app-profile>
            </div>
         </div>
      </div>

   </div>

   <div class="col-12" *ngIf="showPhone">
   <!--   
      <div >
         <fieldset class="border p-2 shadow" style="background:white;">
            <legend class="fieldset-border"><i class="fa fa-phone"></i>&nbsp;Phone Details</legend>
            <app-phone [phoneList]="addEditContactDetail.phoneList" [editable]="editable" [multiplePhones]="multiplePhones" [contactType]="addEditContactDetail.type"></app-phone>
         </fieldset>
      </div>
   -->
      <div class="card mb-4 shadow">
         <div>
            <div class="card-header text-white bg-secondary" style="padding: .3rem .3rem;">
               <div class="row">
                  <div class="col-12 text-truncate" style="align-self: center;font-size: .90rem;">
                     &nbsp;<i class="fa fa-phone"></i>&nbsp;&nbsp;Phone Details
                  </div>
               </div>
            </div>
            <div class="card-body" style="padding: 0.25rem;">
               <app-phone [phoneList]="addEditContactDetail.phoneList" [editable]="editable" [multiplePhones]="multiplePhones" 
               [contactType]="addEditContactDetail.type"></app-phone>
            </div>
         </div>
      </div>


   </div>

   <div class="col-12" *ngIf="showEmail">
   <!--   
      <div >
         <fieldset class="border p-2 shadow" style="background:white;">
            <legend class="fieldset-border"><i class="fa fa-envelope"></i>&nbsp;Email Details</legend>
            <app-email [emailList]="addEditContactDetail.emailList" [editable]="editable" [multipleEmails]="multipleEmails" 
            [contactType]="addEditContactDetail.type"></app-email>
         </fieldset>
      </div>
   -->

      <div class="card mb-4 shadow">
         <div>
            <div class="card-header text-white bg-secondary" style="padding: .3rem .3rem;">
               <div class="row">
                  <div class="col-10 text-truncate" style="align-self: center;font-size: .90rem;">
                     &nbsp;<i class="fa fa-envelope"></i>&nbsp;&nbsp;Email Details
                  </div>
               <!--   
                  <div class="col-2 d-flex justify-content-end">
                     <span>
                        <button class="btn btn-dark btn-sm collapseElem collapsed" type="button" data-toggle="collapse" 
                        [attr.data-target]="'#CollapseEmail'" aria-expanded="false" [attr.aria-controls]="'CollapseEmail'">
                        <i class="fas fa-chevron-down fa-1x"></i></button>
                     </span>                           
                  </div>
               -->
               </div>
            </div>
            <div class="card-body" style="padding: 0.25rem;">
               <app-email [emailList]="addEditContactDetail.emailList" [editable]="editable" [multipleEmails]="multipleEmails" 
               [contactType]="addEditContactDetail.type"></app-email>            
            </div>
         </div>
      </div>
      
   </div>

   <div class="col-12" *ngIf="showAddress">
   <!--         
      <div >
         <fieldset class="border p-2">
            <legend class="fieldset-border">Address Details</legend>
            <app-address [addressList]="addEditContactDetail.addressList" [editable]="editable" [multipleAddress]="multipleAddress" 
            [contactType]="addEditContactDetail.type"></app-address>
         </fieldset>
      </div>
      </div>
   -->
      <div class="card mb-4 shadow">
         <div>
            <div class="card-header text-white bg-secondary" style="padding: .3rem .3rem;">
               <div class="row">
                  <div class="col-10 text-truncate" style="align-self: center;font-size: .90rem;">
                     &nbsp;<i class="fa fa-envelope"></i>&nbsp;&nbsp;Address Details
                  </div>
               </div>
            </div>
            <div class="card-body" style="padding: 0.25rem;">
               <app-address [addressList]="addEditContactDetail.addressList" [editable]="editable" [multipleAddress]="multipleAddress" [showAddressType]="showAddressType"
               [contactType]="addEditContactDetail.type"></app-address>
            </div>
         </div>
      </div>

   </div>

</div>



<!--
<div *ngIf="!tabbed">
   <div class="row">

      <div class="col-3 list-group mb-2">
         <div class="ml-3">
            <div class="mt-1" *ngFor="let cdComp of contactDetailComponents; index as index">
               <a (click)="onComponentClicked(cdComp)" class="list-group-item list-group-item-action 
                  {{selectedComponent === cdComp?'bg-dark text-white':'bg-default'}}">
                  {{cdComp}}
                  <span class="text-right" *ngIf="!myParentForm.form.controls[contactDetailComponentsMG[index]]?.valid">
                     <i class="fa fa-exclamation-triangle" style="color:#dc3545;" aria-hidden="true"></i>
                  </span>
               </a>
            </div>
         </div>
      </div>

      <div class="col">

         <div [style]="selectedComponent!==contactDetailComponents[0]?'display: none;':'display: block;'">
            <app-profile [profile]="addEditContactDetail?.profile" [editable]="editable" [contactType]="addEditContactDetail.type"></app-profile>
         </div>

         <div [style]="selectedComponent!==contactDetailComponents[1]?'display: none;':'display: block;'">
            <app-phone [phoneList]="addEditContactDetail.phoneList" [editable]="editable" [multiplePhones]="true" [contactType]="addEditContactDetail.type"></app-phone>
         </div>

         <div [style]="selectedComponent!==contactDetailComponents[2]?'display: none;':'display: block;'">
            <app-email [emailList]="addEditContactDetail.emailList" [editable]="editable" [multipleEmails]="true" [contactType]="addEditContactDetail.type"></app-email>
         </div>

         <div [style]="selectedComponent!==contactDetailComponents[3]?'display: none;':'display: block;'">
            <app-address [addressList]="addEditContactDetail.addressList" [editable]="editable" [multipleAddress]="true" [contactType]="addEditContactDetail.type"></app-address>
         </div>

      </div>

   </div>

</div>

-->

<!--
<div *ngIf="tabbed">
   <mat-tab-group>
      <mat-tab>
         <ng-template mat-tab-label>
            <h6>New Profile <span class="ml-2 font-weight-normal">
                  <i class="fa fa-exclamation-triangle fa-xs" style="color:red" aria-hidden="true"></i></span></h6>
         </ng-template>
         <div class="overflow-hidden">
            <app-profile [profile]="addEditContactDetail?.profile" [editable]="editable"></app-profile>
         </div>
      </mat-tab>

      <mat-tab>
         <ng-template mat-tab-label>
            <h6>Phone <span class="ml-2 font-weight-normal">
                  <i class="fa fa-exclamation-triangle fa-xs" style="color:red" aria-hidden="true"></i></span></h6>
         </ng-template>
         <div class="overflow-hidden">
            <app-phone [phones]="addEditContactDetail.phoneList" [editable]="editable" [multiplePhones]="true">
            </app-phone>
         </div>
      </mat-tab>

      <mat-tab>
         <ng-template mat-tab-label>
            <h6>Email <span class="ml-2 font-weight-normal">
                  <i class="fa fa-exclamation-triangle fa-xs" style="color:red" aria-hidden="true"></i></span></h6>
         </ng-template>
         <div class="overflow-hidden">
            <app-email [emails]="addEditContactDetail.emailList" [editable]="editable" [multipleEmails]="true">
            </app-email>
         </div>
      </mat-tab>

      <mat-tab>
         <ng-template mat-tab-label>
            <h6>Address <span class="ml-2 font-weight-normal">
                  <i class="fa fa-exclamation-triangle fa-xs" style="color:red" aria-hidden="true"></i></span></h6>
         </ng-template>
         <div class="overflow-hidden">
            <app-address [addresses]="addEditContactDetail.addressList" [editable]="editable" [multipleAddress]="true">
            </app-address>
         </div>
      </mat-tab>
   </mat-tab-group>
</div>
-->