<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
    <!-- This is the tree node template for leaf nodes -->
    <!-- There is inline padding applied to this node using styles.
      This padding value depends on the mat-icon-button width.-->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        {{node.description}}
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node">
          <button mat-icon-button matTreeNodeToggle class="nofocus"
                  [attr.aria-label]="'Toggle ' + node.description">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          {{node.description}} {{node.added?'Added':''}} {{node.removed?'Removed':''}} {{node.modified?'Changed':''}}
        </div>
        
        <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
            role="group">
          <ng-container matTreeNodeOutlet>
                <table class="w-50 table mb-0 table-sm table-striped table-bordered table-condensed f-11" *ngIf="node.changes && node.changes.length>0">
                    <thead>
                        <tr>
                            <th>Field</th>
                            <th>Old Value</th>
                            <th>New Value</th>
                            <!--<th>Change Type</th>-->
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let change of node.changes">
                            <tr>
                                <td >{{change.propertyName}}</td>
                                <td>{{change.left}}</td>
                                <td>{{change.right}}</td>
                                <!--<td>{{change.changeType}}</td>-->
                            </tr>
                        </ng-container>
                    </tbody>
                </table>  

                <table class="w-50 table mb-0 f-11" *ngIf="node.groupedChanges && node.groupedChanges.length>0">
                  
                  <tbody>
                      <ng-container *ngFor="let groupedChange of node.groupedChanges; index as groupChangeIndex">
                        <tr>
                          <td>{{groupedChange.description}}</td>
                          <td>
                              <table class="w-100 table-sm table-striped table-condensed" *ngIf="groupedChange.changes && groupedChange.changes.length>0">
                                <thead>
                                  <tr>
                                      
                                      <th>Field</th>
                                      <th>Old Value</th>
                                      <th>New Value</th>
                                      <!--<th>Change Type</th>-->
                                  </tr>
                              </thead>
                                <tr *ngFor="let change of groupedChange.changes">
                                  <td>{{change.propertyName}}</td>
                                  <td>{{change.left}}</td>
                                  <td>{{change.right}}</td>
                                  <!--<td>{{change.changeType}}</td>-->
                                </tr>
                              </table>
                          </td>
                        </tr>

                          
                      </ng-container>
                  </tbody>
              </table>  

              <!--
              <div class="mb-0 f-11" *ngIf="node.groupedChanges && node.groupedChanges.length>0">
                <div class="row m-1">
                  <div class="col-2"></div>
                  <div class="col-10">
                    <div class="row">
                      <div class="col-4">Field</div>
                      <div class="col-4">Old Value</div>
                      <div class="col-4">New Value</div>
                    </div>
                  </div>
                </div>  
                <div>
                    <ng-container *ngFor="let groupedChange of node.groupedChanges">
                     
                      <div class="row m-1 border">
                        <div class="col-2">{{groupedChange.description}}</div>
                        <div class="col-10">
                            <ng-container class="row" *ngIf="groupedChange.changes && groupedChange.changes.length>0">
                              <div class="row" *ngFor="let change of groupedChange.changes">
                                <div class="col-4">{{change.propertyName}}</div>
                                <div class="col-4">{{change.left}}</div>
                                <div class="col-4">{{change.right}}</div>
                                
                              </div>
                            </ng-container>
                          </div>
                        </div>

                        
                    </ng-container>
                  </div>
                </div> -->

            </ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree>