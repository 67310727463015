import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ProductionTracker } from 'src/app/shared/model/sales/fulfillment/production-tracker.model';
import { RoutingInfo } from 'src/app/shared/model/sales/fulfillment/routing-info.model';
import { SalesOrder } from 'src/app/shared/model/sales/sales-order.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';

@Component({
  selector: 'app-routing-info',
  templateUrl: './routing-info.component.html',
  styleUrls: ['./routing-info.component.css']/*,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]*/

})
export class RoutingInfoComponent implements OnInit {

  @Input() salesOrder:SalesOrder;
  @Input() userAccess:UserAccess;
  @Input() productionTracker:ProductionTracker;
  @Output() onRoutingInfoEdit = new EventEmitter<boolean>();
  @Output() onRoutingInfoCancelEdit = new EventEmitter<boolean>();
  @Output() onRoutingInfoSave = new EventEmitter<boolean>();
  @Input() editable:boolean=false;
  constructor() { }

  ngOnInit(): void {
    if(!this.productionTracker.routingInfoList){
      this.productionTracker.routingInfoList=[];
    }
  }

  addRoutingInfo(){
    this.productionTracker.routingInfoList.push(new RoutingInfo(this.productionTracker.salesOrderId));
  };

  deleteRoutingInfo(routingInfo:RoutingInfo){
    routingInfo.deleteFlag=true;
  };

  getNonDeletedCount():number{
    let retVal=0;
    if(this.productionTracker.routingInfoList){
      let temp=this.productionTracker.routingInfoList.filter(x=>x.deleteFlag===false);
      retVal=temp.length;
    }
    return retVal;
  }

  edit(){
    this.onRoutingInfoEdit.next(true);
  }
  cancel(){
    this.editable=false;
    this.onRoutingInfoCancelEdit.next(true);
  }
  save(){
      this.onRoutingInfoSave.next(true);
  }
}
