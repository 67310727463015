<div style="position: relative;" >
    <div class="overlay-with-text" *ngIf=" orderDetailInfo.obsolete && orderDetailInfo.orderDetailStatus!=='CANCELLED'">Un-Assigned</div>
    <div class="overlay-with-text" *ngIf="orderDetailInfo.orderDetailStatus==='CANCELLED'">Cancelled</div>
    <div ngModelGroup="orderDetailInfo_{{printerInfoIndex}}_{{orderDetailInfoIndex}}" #orderDetailInfoCtrl="ngModelGroup" class="form-ngModelGroup">
        
        <div class=" p-2 m-3 border rounded" [style]="orderDetailInfoIndex%2===1?'':''" *ngIf="orderDetailInfo.deleteFlag===false">
            <div class="row">
                <div class="col-auto">
                    <div style="position: relative;">
                        <div style="position: absolute;" *ngIf="orderDetailInfo.orderDetailStatus==='CANCELLED'" >
                            <img src="assets/cancelledBanner.png" 
                                class="" width="auto" height="70">
                        </div>
                        <div style="position: absolute;" *ngIf="orderDetailInfo.orderDetailStatus!=='CANCELLED' && orderDetailInfo.obsolete" >
                            <img src="assets/unassignedBanner.png" 
                                class="" width="auto" height="70">
                        </div>
                        <img [src]="orderDetailInfo.orderDetail.product.productImages[0]?.thumbnailSignedUrl" 
                        class="img-thumbnail img-fluid zoom-4 "
                        style="display: block;  width:60px;  height:100px; min-width: 100px;"
                        [style.border-color]="'cadetblue'"
                        (error)="handleThumbnailError(orderDetailInfo.orderDetail.product.productImages[0],$event)" />
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="col-3">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">SKU</label>
                            <div *ngIf="editable">
                                <input name="sku_{{printerInfoIndex}}_{{orderDetailInfoIndex}}" class="form-control form-control-sm" style="font-size: 11px;" #sku="ngModel" required 
                                [(ngModel)]="orderDetailInfo.orderDetail.product.sku" [ngClass]="{'is-invalid':sku.touched && sku.errors}"
                                disabled>
                                <div *ngIf="sku?.invalid && (sku.dirty || sku.touched)" class="invalid-feedback">
                                <div *ngIf="sku.errors.required">
                                    SKU is required.
                                </div>
                                </div>
                            </div>
                            <div *ngIf="!editable">
                                <a type="button" class="f-10 btn btn-link showLink no-wrap" target="_blank" style="color: black;" matTooltip="View product" matTooltipPosition="below" matTooltipClass="bg-dark"
                                             [routerLink]="['/product/view/'+orderDetailInfo.orderDetail.product.licensorId+'/'+orderDetailInfo.orderDetail.product.brandId+'/'+orderDetailInfo.orderDetail.product.id]">
                                            {{orderDetailInfo.orderDetail.product.sku}}
                                                <i class="fas fa-external-link-alt ml-1 showLinkIcon"></i>   
                                            </a> 
                            </div>
                        </div>

                        <div class="col-3">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Art Sent Date</label>
                            <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="artSentDate"  name="artSentDate_{{printerInfoIndex}}_{{orderDetailInfoIndex}}" [(ngModel)]="orderDetailInfo.artSentDate" style="cursor: pointer;font-size: 11px;"
                                class="form-control  form-control-sm" #artSentDateVar="ngModel" [ngClass]="{'is-invalid':artSentDateVar.touched && artSentDateVar.errors}"
                                disabled ><!--[disabled]="!editable"-->
                            <mat-datepicker #artSentDate></mat-datepicker>
                            <button class="btn btn-sm" type="button" (click)="artSentDate.open()" [disabled]="!editable"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <mat-error *ngIf="artSentDateVar?.errors?.required && (artSentDateVar.dirty || artSentDateVar.touched)" style="font-size: 80%;color: #dc3545">
                            Art Sent Date is required.
                            </mat-error>
                            <div *ngIf="artSentDateVar?.invalid && (artSentDateVar.dirty || artSentDateVar.touched)" class="invalid-feedback">
                                <div *ngIf="artSentDateVar.errors.required">Art Sent Date is required.</div>
                            </div>
                        </div>

                        <div class="col-3 d-flex">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" name="ppSample_{{printerInfoIndex}}_{{orderDetailInfoIndex}}"
                                   [(ngModel)]="orderDetailInfo.ppSamplesRequired" [disabled]="!editable">
                                <label class="form-check-label f-10" style="color:black" for="ppSample_{{printerInfoIndex}}_{{orderDetailInfoIndex}}">PP Samples Required</label>
                            </div>
                        </div>
                        <div class="col-3 d-flex">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" name="topSample_{{printerInfoIndex}}_{{orderDetailInfoIndex}}"
                                   [(ngModel)]="orderDetailInfo.topSamplesRequired" [disabled]="!editable">
                                <label class="form-check-label f-10" style="color:black" for="topSample_{{printerInfoIndex}}_{{orderDetailInfoIndex}}">Top Samples Required</label>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-3" *ngIf="orderDetailInfo.ppSamplesRequired">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">PP Sample Qty</label>
                            <input type="number" name="ppSampleQty_{{printerInfoIndex}}_{{orderDetailInfoIndex}}" class="form-control form-control-sm" style="font-size: 11px;" #ppSampleQty="ngModel" 
                            [(ngModel)]="orderDetailInfo.ppSampleQty" [ngClass]="{'is-invalid':ppSampleQty.touched && ppSampleQty.errors}"
                            [disabled]="!editable">
                            <div *ngIf="ppSampleQty?.invalid && (ppSampleQty.dirty || ppSampleQty.touched)" class="invalid-feedback">
                            <div *ngIf="ppSampleQty.errors.required">
                                PP Sample is required.
                            </div>
                            </div>
                        </div>
                        
                    
                        <div class="col-3" *ngIf="orderDetailInfo.ppSamplesRequired">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">PP Sample Printer Deadline</label>
                            <div class="d-flex d-inline">
                            <input matInput [matDatepicker]="ppSamplePrinterDeadline"  name="ppSamplePrinterDeadline_{{printerInfoIndex}}_{{orderDetailInfoIndex}}" [(ngModel)]="orderDetailInfo.ppSamplePrinterDeadline" style="cursor: pointer;font-size: 11px;"
                                class="form-control  form-control-sm" #ppSamplePrinterDeadlineVar="ngModel" [ngClass]="{'is-invalid':ppSamplePrinterDeadlineVar.touched && ppSamplePrinterDeadlineVar.errors}"
                                [disabled]="!editable">
                            <mat-datepicker #ppSamplePrinterDeadline></mat-datepicker>
                            <button class="btn btn-sm" type="button" (click)="ppSamplePrinterDeadline.open()" [disabled]="!editable"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <mat-error *ngIf="ppSamplePrinterDeadlineVar?.errors?.required && (ppSamplePrinterDeadlineVar.dirty || ppSamplePrinterDeadlineVar.touched)" style="font-size: 80%;color: #dc3545">
                            PP Sample Deadline is required.
                            </mat-error>
                            <div *ngIf="ppSamplePrinterDeadlineVar?.invalid && (ppSamplePrinterDeadlineVar.dirty || ppSamplePrinterDeadlineVar.touched)" class="invalid-feedback">
                                <div *ngIf="ppSamplePrinterDeadlineVar.errors.required">PP Sample Date is required.</div>
                            </div>
                        </div>
                        <div class="col-3" *ngIf="orderDetailInfo.ppSamplesRequired">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">PP Sample Status</label>
                            <select class="form-control form-control-sm custom-select" [(ngModel)]="orderDetailInfo.ppSampleStatus"  name="ppSampleStatus_{{printerInfoIndex}}_{{orderDetailInfoIndex}}"  style="font-size: 11px;"
                                #ppSampleStatusVar="ngModel" required  [ngClass]="{'is-invalid':ppSampleStatusVar.touched && ppSampleStatusVar.errors}"
                                [disabled]="!editable">
                                <option value="NOT_SUBMITTED">Not Submitted</option>
                                <option value="PENDING_BUYER_APPROVAL">Pending Buyer Approval</option>
                                <option value="RESUBMISSION_REQUIRED">Resubmission Required</option>
                                <option value="APPROVED">Approved</option>
                            </select>
                            <div *ngIf="ppSampleStatusVar?.invalid && (ppSampleStatusVar.dirty || ppSampleStatusVar.touched)" class="invalid-feedback">
                                <div *ngIf="ppSampleStatusVar.errors.required">
                                    PP Sample Status is required.
                                </div>
                            </div>
                        </div>
                        <!--<div class="col-2" *ngIf="orderDetailInfo.ppSamplesRequired">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">PP Sample Tracking #</label>
                            <input name="ppSampleTrackingNum_{{printerInfoIndex}}_{{orderDetailInfoIndex}}" class="form-control form-control-sm" style="font-size: 11px;" #ppSampleTrackingNum="ngModel" 
                            [(ngModel)]="orderDetailInfo.ppSampleTrackingNum" [ngClass]="{'is-invalid':ppSampleTrackingNum.touched && ppSampleTrackingNum.errors}"
                            [disabled]="!editable">
                            <div *ngIf="ppSampleTrackingNum?.invalid && (ppSampleTrackingNum.dirty || ppSampleTrackingNum.touched)" class="invalid-feedback">
                            <div *ngIf="ppSampleTrackingNum.errors.required">
                                PP Sample Tracking # is required.
                            </div>
                            </div>
                        </div>-->
                        <div class="col-3" *ngIf="orderDetailInfo.ppSamplesRequired">
                            <app-tracking-link [editable]="editable" [linksArray]="orderDetailInfo.ppTrackingLinks" [type]="'ORDER_DETAIL_INFO'" [subType]="'PP_SAMPLE_TRACKING'"></app-tracking-link>
                        </div>
                    </div>
                    <div class="row">
                        <!-- Top Sample-->
                        
                    
                        <div class="col-3" *ngIf="orderDetailInfo.topSamplesRequired">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Top Sample Qty</label>
                            <input type="number" name="topSampleQty_{{printerInfoIndex}}_{{orderDetailInfoIndex}}" class="form-control form-control-sm" style="font-size: 11px;" #topSampleQty="ngModel" 
                            [(ngModel)]="orderDetailInfo.topSampleQty" [ngClass]="{'is-invalid':topSampleQty.touched && topSampleQty.errors}"
                            [disabled]="!editable">
                            <div *ngIf="topSampleQty?.invalid && (topSampleQty.dirty || topSampleQty.touched)" class="invalid-feedback">
                            <div *ngIf="topSampleQty.errors.required">
                                Top Sample is required.
                            </div>
                            </div>
                        </div>

                        
                    
                        <div class="col-3" *ngIf="orderDetailInfo.topSamplesRequired">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Top Sample Date</label>
                            <div class="d-flex d-inline">
                                <input matInput [matDatepicker]="topSamplePrinterDeadline"  name="topSamplePrinterDeadline_{{printerInfoIndex}}_{{orderDetailInfoIndex}}" [(ngModel)]="orderDetailInfo.topSamplePrinterDeadline" style="cursor: pointer;font-size: 11px;"
                                    class="form-control  form-control-sm" #topSamplePrinterDeadlineVar="ngModel" [ngClass]="{'is-invalid':topSamplePrinterDeadlineVar.touched && topSamplePrinterDeadlineVar.errors}"
                                    [disabled]="!editable">
                                <mat-datepicker #topSamplePrinterDeadline></mat-datepicker>
                                <button class="btn btn-sm" type="button" (click)="topSamplePrinterDeadline.open()" [disabled]="!editable"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <mat-error *ngIf="topSamplePrinterDeadlineVar?.errors?.required && (topSamplePrinterDeadlineVar.dirty || topSamplePrinterDeadlineVar.touched)" style="font-size: 80%;color: #dc3545">
                            Top Sample Deadline is required.
                            </mat-error>
                            <div *ngIf="topSamplePrinterDeadlineVar?.invalid && (topSamplePrinterDeadlineVar.dirty || topSamplePrinterDeadlineVar.touched)" class="invalid-feedback">
                                <div *ngIf="topSamplePrinterDeadlineVar.errors.required">Top Sample Date is required.</div>
                            </div>
                        
                        </div>

                        <div class="col-3" *ngIf="orderDetailInfo.topSamplesRequired">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Top Sample Status</label>
                            <select class="form-control form-control-sm custom-select" [(ngModel)]="orderDetailInfo.topSampleStatus"  name="topSampleStatus_{{printerInfoIndex}}_{{orderDetailInfoIndex}}"  style="font-size: 11px;"
                                #topSampleStatusVar="ngModel" required  [ngClass]="{'is-invalid':topSampleStatusVar.touched && topSampleStatusVar.errors}"
                                [disabled]="!editable">
                                <option value="NOT_SUBMITTED">Not Submitted</option>
                                <option value="PENDING_BUYER_APPROVAL">Pending Buyer Approval</option>
                                <option value="RESUBMISSION_REQUIRED">Resubmission Required</option>
                                <option value="APPROVED">Approved</option>
                            </select>
                            <div *ngIf="topSampleStatusVar?.invalid && (topSampleStatusVar.dirty || topSampleStatusVar.touched)" class="invalid-feedback">
                                <div *ngIf="topSampleStatusVar.errors.required">
                                    Top Sample Status is required.
                                </div>
                            </div>
                        </div>

                        <!--<div class="col-2" *ngIf="orderDetailInfo.topSamplesRequired">
                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Top Sample Tracking #</label>
                            <input name="topSampleTrackingNum_{{printerInfoIndex}}_{{orderDetailInfoIndex}}" class="form-control form-control-sm" style="font-size: 11px;" #topSampleTrackingNum="ngModel" 
                            [(ngModel)]="orderDetailInfo.topSampleTrackingNum" [ngClass]="{'is-invalid':topSampleTrackingNum.touched && topSampleTrackingNum.errors}"
                            [disabled]="!editable">
                            <div *ngIf="topSampleTrackingNum?.invalid && (topSampleTrackingNum.dirty || topSampleTrackingNum.touched)" class="invalid-feedback">
                            <div *ngIf="topSampleTrackingNum.errors.required">
                                Top Sample Tracking # is required.
                            </div>
                            </div>
                        </div>-->

                        <div class="col-3" *ngIf="orderDetailInfo.topSamplesRequired">
                            <app-tracking-link [editable]="editable" [linksArray]="orderDetailInfo.topTrackingLinks" [type]="'ORDER_DETAIL_INFO'" [subType]="'TOP_SAMPLE_TRACKING'"></app-tracking-link>
                        </div>
                        <!--Top Sample Ends-->


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>