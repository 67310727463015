export class EmailTemplateConfig {
   productId: string;
   brandId:string;
   templateName: string;
   licensorId:string;
   sku:string;
   productIdList: string[]=[];
   productSkuList: string[]=[];
   selectedAttachmentIds:string[]=[];
   salesOrderNumber:string;
   salesOrderId:string;
   idList: string[]=[];
   
}
