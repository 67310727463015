<form #pdfFormForm="ngForm" (ngSubmit)="createOrUpdatePdfForm()" autocomplete="off" #formVar="ngForm" novalidate>
    <div class="container-fluid" *ngIf="pdfForm">
    <div>
            <div class="d-flex justify-content-center mt-3">
                    <div class="text-color-purple"><h2>{{pdfForm.name}}&nbsp;</h2></div>
                    <div></div>
            </div>
            
            <div class="separator-purple"><h6>Configure Pdf Form</h6></div>

            <!-- Datails Starts-->
            <div class="row col-md-4 ml-1">
                <mat-tab-group>
                    <mat-tab>
                        <ng-template mat-tab-label>
                        <h4>Details</h4>
                        </ng-template>
                        <div class="ml-2 mt-4 overflow-hidden">
                        <input type="hidden" name="pdfFormId" [(ngModel)]="pdfForm.id" #pdfFormId>
                        <div class="form-group row">
                            <label class="col-sm-4">Name</label>
                            <div class="col-sm-8">
                                        <input id="nameId" name="name" [(ngModel)]="pdfForm.name"
                                            class="form-control form-control-sm" #nameVar="ngModel" required  [disabled]="!editable"
                                    [ngClass]="{'is-invalid':nameVar.touched && nameVar.errors}"
                                    maxlength="40" minlength="4">
                                            <div *ngIf="nameVar?.invalid && (nameVar.dirty || nameVar.touched)" class="invalid-feedback">
                                                    <div *ngIf="nameVar.errors.required">
                                                                Name is required.
                                                    </div>
                                                    <div *ngIf="nameVar.value?.length > 0 && nameVar.value?.length&lt;4">
                                                                Minimum required Name length is 4
                                                    </div>
                                                    
                                            </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4">Category</label>
                                    <div class="col-sm-8" *ngIf="!editable">
                                            <input name="Category" [(ngModel)]="pdfForm.category" class="form-control form-control-sm" readonly>
                                    </div>
                                    <div class="col-sm-8" *ngIf="editable">
                                            <select class="form-control form-control-sm custom-select" [(ngModel)]="pdfForm.category"
                                                name="category" #category="ngModel" required
                                                [ngClass]="{'is-invalid':category.touched && category.errors}">
                                                <option *ngFor="let categoryItem of categoryList" [ngValue]="categoryItem">
                                                            {{categoryItem}}</option>
                                            </select>
                                            <div *ngIf="category?.invalid && (category.dirty || category.touched)"
                                                class="invalid-feedback">
                                                <div *ngIf="category.errors.required">
                                                            Category is required.
                                                </div>
                                            </div>
                        
                                    </div>
                        
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4">Sub Category</label>
                                    <div class="col-sm-8" *ngIf="!editable">
                                            <input name="SubCategory" [(ngModel)]="pdfForm.subCategory" class="form-control form-control-sm" readonly>
                                    </div>
                                    <div class="col-sm-8" *ngIf="editable">
                                            <select class="form-control form-control-sm custom-select" [(ngModel)]="pdfForm.subCategory"
                                                name="subCategory" #subCategory="ngModel" required
                                                [ngClass]="{'is-invalid':subCategory.touched && subCategory.errors}">
                                                <option *ngFor="let subCategoryItem of subCategoryList" [ngValue]="subCategoryItem">
                                                            {{subCategoryItem}}</option>
                                            </select>
                                            <div *ngIf="subCategory?.invalid && (subCategory.dirty || subCategory.touched)"
                                                class="invalid-feedback">
                                                <div *ngIf="subCategory.errors.required">
                                                            Category is required.
                                                </div>
                                            </div>
                        
                                    </div>
                        
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4">Licensor</label>
                                    <div class="col-sm-8">
                                            <select class="form-control form-control-sm custom-select" [(ngModel)]="pdfForm.licensorId"
                                                name="licensorVar" #licensorVar="ngModel" (change)="onLicensorChange($event)" required
                                                [ngClass]="{'is-invalid':licensorVar.touched && licensorVar.errors}" [disabled]="!editable">
                                                <option value="ANY">Any Licensor</option>
                                                <option *ngFor="let licensor of licensorList" [ngValue]="licensor.id">
                                                            {{licensor.licensorName}}</option>
                                            </select>
                                            <div *ngIf="licensorVar?.invalid && (licensorVar.dirty || licensorVar.touched)"
                                                class="invalid-feedback">
                                                <div *ngIf="licensorVar.errors.required">
                                                            Licensor is required.
                                                </div>
                                            </div>
                        
                                    </div>
                        
                        </div>
                        
                        <div class="form-group row">
                            <label class="col-sm-4">Brand</label>
                            
                            <div class="col-sm-8" >
                                    <select class="form-control form-control-sm custom-select" [(ngModel)]="pdfForm.brandId"
                                            name="brandVar" [disabled]="!pdfForm.licensorId || !editable" (change)="onBrandChange($event)"
                                            #brandVar="ngModel" required [ngClass]="{'is-invalid':brandVar.touched && brandVar.errors}" >
                                        <option value="ANY">Any Brand</option>   
                                        <option *ngFor="let brand of brandList" [ngValue]="brand.id">
                                                    {{brand.brandName}}</option>
                                    </select>
                                    <div *ngIf="brandVar?.invalid && (brandVar.dirty || brandVar.touched)"
                                                class="invalid-feedback">
                                                <div *ngIf="brandVar.errors.required">
                                                            Brand is required.
                                                </div>
                                    </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4">File Type</label>
                            
                            <div class="col-sm-8" >
                                    <select class="form-control form-control-sm custom-select" [(ngModel)]="pdfForm.fileType"
                                            name="fileTypeVar" [disabled]="!pdfForm.licensorId || !editable" (change)="onBrandChange($event)"
                                            #fileTypeVar="ngModel" required [ngClass]="{'is-invalid':fileTypeVar.touched && fileTypeVar.errors}" >
                                        <option value="PDF">pdf</option>   
                                        <option value="PPT">ppt</option>
                                        <option value="XLS">xls</option>
                                    </select>
                                    <div *ngIf="fileTypeVar?.invalid && (fileTypeVar.dirty || fileTypeVar.touched)"
                                                class="invalid-feedback">
                                                <div *ngIf="fileTypeVar.errors.required">
                                                            File Type is required.
                                                </div>
                                    </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4">Form Flattening</label>
                            
                            <div class="col-sm-8" >
                                    <select class="form-control form-control-sm custom-select" [(ngModel)]="pdfForm.formFlattening"
                                            name="formFlatteningVar" [disabled]="!pdfForm.licensorId || !editable" (change)="onBrandChange($event)"
                                            #formFlatteningVar="ngModel" required [ngClass]="{'is-invalid':formFlatteningVar.touched && formFlatteningVar.errors}" >
                                        <option value="true">Yes</option>   
                                        <option value="false">No</option>
                                    </select>
                                    <div *ngIf="formFlatteningVar?.invalid && (formFlatteningVar.dirty || formFlatteningVar.touched)"
                                                class="invalid-feedback">
                                                <div *ngIf="formFlatteningVar.errors.required">
                                                            Form Flattening Value is required.
                                                </div>
                                    </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                                    <div class="mb-4 col-sm-4"></div>
                                    <div class="mb-4 col-sm-8">
                                    <button type="submit" class="btn btn-info btn-block" *ngIf="editable && createAction"
                                    [disabled]="!pdfFormForm.valid || pdfForm.attachments.length===0">Submit</button>
                                    </div>
                        </div> 
                    </div>
                </mat-tab>
            </mat-tab-group>
                <div class="floatBtn d-flex flex-column">
                    <button type="button" (click)="createOrUpdatePdfForm()" class=" btn btn-circle bg-info mb-2"
                    *ngIf="editable && !createAction" matTooltip="Save Changes" matTooltipPosition="left" matTooltipClass="bg-dark"
                    [disabled]="!pdfFormForm.valid || pdfForm.attachments.length===0">
                    <i class="fas fa-save fa-2x" style="color:white"></i>
                    </button>
                    <button type="button" class="btn btn-circle bg-dark" *ngIf="editable && !createAction" matTooltip="Cancel Edit"
                    matTooltipPosition="left" matTooltipClass="bg-dark" (click)="cancelEdit()"><i class="fas fa-times fa-2x"
                        style="color:white"></i>
                    </button>
                    <a class="btn btn-circle bg-dark mb-2" (click)="edit()" *ngIf="viewAction && userAccess?.canEditPdfForms"
                    matTooltip="Edit Pdf Form" matTooltipPosition="left" matTooltipClass="bg-dark"> <i class="fas fa-pencil-alt fa-lg"
                        style="color: white;"></i></a>
                    <a (click)="goBack()" class="btn btn-circle" *ngIf="viewAction || createAction" style="background-color: tomato;"
                    matTooltip="Exit" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-arrow-left  fa-2x"></i></a>
                    <!--fa-file-pdf-->
                </div>
            </div>
            <!--Details ENDS-->



           
            <div class="row mt-3">
                    <!--Image & File section starts-->
                    <div class="col-md-12 ">
                            <div class="mt-0">
                                    <app-attachment-operations  [header]="'PDF Form'" [editable]="editable" 
                                    [createAction]="createAction" [attachments]="pdfForm.attachments" 
                                    [canUpload]="userAccess?.canCreatePdfForms || userAccess?.canEditPdfForms" [canDownload]="userAccess?.canEditPdfForms" 
                                    [canDelete]="userAccess?.canCreatePdfForms || userAccess?.canEditPdfForms" [category]="'PDF Forms'"
                                    (onNewUpload)="parsePdfForm()" (onDelete)="attachmentDeleted()"></app-attachment-operations>
                            </div>
                            <div class="mt-4" *ngIf="pdfForm.variables && pdfForm.variables.length!==0">
                                <h4>Varibale Details</h4>
                                
                                <hr>
                                <div class="row">
                                    <div class="col-sm-{{varHead.colSize}} bg-dark text-white border border-white"  *ngFor="let varHead of varHeaders">
                                            {{varHead.name}}
                                    </div>
                                </div>

                                <div class="row mb-1" *ngFor="let varTemp of pdfForm.variables;index as index">
                                     <div class="col-sm-2 d-flex">
                                        <div class="custom-control custom-checkbox">
                                            <input class="custom-control-input" type="checkbox" name="isfillable{{index}}" 
                                               [(ngModel)]="varTemp.fillable" [disabled]="!editable" id="customCheck{{index}}">
                                               <label class="custom-control-label" for="customCheck{{index}}">&nbsp;</label>
                                         </div>
                                         <div class="custom-control custom-checkbox">
                                            <input class="custom-control-input" type="checkbox" name="isDisplay{{index}}" 
                                               [(ngModel)]="varTemp.display" [disabled]="!editable" id="display{{index}}">
                                               <label class="custom-control-label" for="display{{index}}">&nbsp;</label>
                                         </div>
                                         <label class="f-13">{{varTemp.varName}}</label>
                                    </div>
                                    
                                    <div class="col-sm-1 pl-1 pr-1">
                                            <input id="alias{{index}}" name="alias{{index}}" [(ngModel)]="varTemp.varNameAlias" class="form-control form-control-sm f-13" 
                                                #aliasVar="ngModel" required  [disabled]="!editable" [ngClass]="{'is-invalid':aliasVar.touched && aliasVar.errors}"
                                                maxlength="50">
                                                <div *ngIf="aliasVar?.invalid && (aliasVar.dirty || aliasVar.touched)" class="invalid-feedback">
                                                           <div *ngIf="aliasVar.errors.required">
                                                                    Alias is required.
                                                           </div>
                                                  </div>
                                    </div>
                                    <div class="col-sm-1 pl-1 pr-1" >
                                        <input name="type{{index}}" [(ngModel)]="varTemp.varType"
                                        class="form-control form-control-sm f-13" #varTypeVar [disabled]="!editable" >
                                    </div>
                                    <div class="col-sm-1 pl-1 pr-1" >
                                        <input name="subType{{index}}" [(ngModel)]="varTemp.varSubType"
                                        class="form-control form-control-sm f-13" #varSubTypeVar [disabled]="!editable" >
                                    </div>
                                   <!-- <div class="col-sm-2 pl-1 pr-1" *ngIf="editable">
                                        <select class="form-control form-control-sm custom-select f-13" [(ngModel)]="varTemp.varType"
                                            name="type{{index}}" #varTypeVar="ngModel" required [ngClass]="{'is-invalid':varTypeVar.touched && varTypeVar.errors}">
                                            <option  value="image">Image</option>
                                            <option  value="text">Text</option>
                                        </select>
                                                <div *ngIf="varTypeVar?.invalid && (varTypeVar.dirty || varTypeVar.touched)"
                                                            class="invalid-feedback">
                                                            <div *ngIf="varTypeVar.errors.required">
                                                                        Type is required.
                                                            </div>
                                                </div>
                                    </div>-->
                                    <!--Source Starts-->
                                    <div class="col-sm-2 pl-1 pr-1" *ngIf="varTemp.varType!==('IMAGE') && varTemp.varType!=='CHECK'">
                                        <input id="source{{index}}" name="source{{index}}" [(ngModel)]="varTemp.varValueSource" class="form-control form-control-sm f-13" 
                                            #sourceVar="ngModel" required  [disabled]="!editable" [ngClass]="{'is-invalid':sourceVar.touched && sourceVar.errors}"
                                            maxlength="200">
                                            <div *ngIf="sourceVar?.invalid && (sourceVar.dirty || sourceVar.touched)" class="invalid-feedback">
                                                       <div *ngIf="sourceVar.errors.required">
                                                                Source is required.
                                                       </div>
                                              </div>
                                    </div>
                                    <div class="col-sm-2 pl-1 pr-1" *ngIf="varTemp.varType==='CHECK'">
                                        <select class="form-control form-control-sm custom-select f-13" [(ngModel)]="varTemp.varValueSource"
                                            name="source{{index}}" #sourceVar="ngModel" [disabled]="!editable" required [ngClass]="{'is-invalid':sourceVar.touched && sourceVar.errors}">
                                            <option  value="false">FALSE</option>
                                            <option  value="true">TRUE</option>
                                        </select>
                                                <div *ngIf="sourceVar?.invalid && (sourceVar.dirty || sourceVar.touched)"
                                                            class="invalid-feedback">
                                                            <div *ngIf="sourceVar.errors.required">
                                                                        Source is required.
                                                            </div>
                                                </div>
                                    </div>
                                    <div class="col-sm-2 pl-1 pr-1" *ngIf="varTemp.varType===('IMAGE')">
                                        <select class="form-control form-control-sm custom-select f-13" [(ngModel)]="varTemp.varValueSource"
                                            name="source{{index}}" #sourceVar="ngModel" [disabled]="!editable" required [ngClass]="{'is-invalid':sourceVar.touched && sourceVar.errors}">
                                            <option  value="IMAGES">IMAGES</option>
                                            <option  value="PNL">PNL</option>
                                            <option  value="LOGO">LOGO</option>
                                            <option  value="PHOTO_APPROVAL">APPROVAL PHOTOS</option>
                                        </select>
                                                <div *ngIf="sourceVar?.invalid && (sourceVar.dirty || sourceVar.touched)"
                                                            class="invalid-feedback">
                                                            <div *ngIf="sourceVar.errors.required">
                                                                        Source is required.
                                                            </div>
                                                </div>
                                    </div>

                                    <!--Source Ends-->
                                     <!--MultiValue / Blanks Starts-->
                                    <div class="col-sm-1 pl-1 pr-1">
                                        <input id="delimeter{{index}}" name="delimeter{{index}}" [(ngModel)]="varTemp.multiValueDelimeter" class="form-control form-control-sm f-13" 
                                            #delimeterVar="ngModel" required  [disabled]="!editable || !varTemp.varValueSource?.includes('~')" [ngClass]="{'is-invalid':delimeterVar.touched && delimeterVar.errors}"
                                            maxlength="20">
                                            <div *ngIf="delimeterVar?.invalid && (delimeterVar.dirty || delimeterVar.touched)" class="invalid-feedback">
                                                       <div *ngIf="delimeterVar.errors.required">
                                                                Multi Value Delimeter is required.
                                                       </div>
                                              </div>
                                    </div>
                                    <!--<div class="col-sm-1 pl-1 pr-1" *ngIf="varTemp.varType==='IMAGE'">
                                        <select class="form-control form-control-sm custom-select f-12" [(ngModel)]="varTemp.blanksAllowed"
                                            name="blankImage{{index}}" #blankVar="ngModel" [disabled]="!editable" required [ngClass]="{'is-invalid':blankVar.touched && blankVar.errors}">
                                            <option  value="Y">Blanks</option>
                                            <option  value="N">No Blanks</option>
                                        </select>
                                                <div *ngIf="blankVar?.invalid && (blankVar.dirty || blankVar.touched)"
                                                            class="invalid-feedback">
                                                            <div *ngIf="blankVar.errors.required">
                                                                        Balnk Allowed is required.
                                                            </div>
                                                </div>
                                    </div>-->

                                    

                                    <!--MultiValue / Blanks Ends-->
                                    <div class="col-sm-1 pl-1 pr-1" *ngIf="varTemp.varType===('IMAGE')">
                                        <input id="imageIndex{{index}}" type="number" name="imageIndex{{index}}" [(ngModel)]="varTemp.varValueImageArrIndex" class="form-control form-control-sm f-13" 
                                            #imageIndexVar="ngModel" required  [disabled]="!editable || varTemp.varType!==('IMAGE')" [ngClass]="{'is-invalid':imageIndexVar.touched && imageIndexVar.errors}"
                                            maxlength="20">
                                            <div *ngIf="imageIndexVar?.invalid && (imageIndexVar.dirty || imageIndexVar.touched)" class="invalid-feedback">
                                                       <div *ngIf="imageIndexVar.errors.required">
                                                                Image Index is required.
                                                       </div>
                                              </div>
                                    </div>
                                    <div class="col-sm-1 pl-1 pr-1" *ngIf="varTemp.varType!==('IMAGE')"><!-- || varTemp.varType==='CHECK'-->
                                        <input id="margin{{index}}" type="text" name="margin{{index}}" [(ngModel)]="varTemp.extendedMargin" class="form-control form-control-sm f-13" 
                                            #textMargin="ngModel"   [disabled]="!editable || (varTemp.varType==='CHECK')" [ngClass]="{'is-invalid':textMargin.touched && textMargin.errors}"
                                            maxlength="20">
                                            <div *ngIf="textMargin?.invalid && (textMargin.dirty || textMargin.touched)" class="invalid-feedback">
                                                       <div *ngIf="textMargin.errors.required">
                                                                Margin is required.
                                                       </div>
                                              </div>
                                    </div>
                                    <div class="col-sm-1 pl-1 pr-1">
                                        <div class="row">
                                            <div class="col-sm-6 pr-1">
                                                <input id="sortOrder{{index}}" type="text" name="sortOrder{{index}}" [(ngModel)]="varTemp.sortOrder" class="form-control form-control-sm f-13" 
                                                #sortOrderVar="ngModel"   [disabled]="!editable" [ngClass]="{'is-invalid':sortOrderVar.touched && sortOrderVar.errors}"
                                                maxlength="4" required>
                                                <div *ngIf="sortOrderVar?.invalid && (sortOrderVar.dirty || sortOrderVar.touched)" class="invalid-feedback">
                                                        <div *ngIf="sortOrderVar.errors.required">
                                                                    Sort Order is required.
                                                        </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 pl-1">
                                                <input id="objectIndex{{index}}" type="text" name="objectIndex{{index}}" [(ngModel)]="varTemp.objectIndex" class="form-control form-control-sm f-13" 
                                                #objectIndexVar="ngModel"   [disabled]="!editable || !varTemp.varSubType?.includes('ASSOCIATED')" [ngClass]="{'is-invalid':objectIndexVar.touched && objectIndexVar.errors}"
                                                maxlength="4" [required]="varTemp.varSubType?.includes('ASSOCIATED')">
                                                <div *ngIf="objectIndexVar?.invalid && (objectIndexVar.dirty || objectIndexVar.touched)" class="invalid-feedback">
                                                        <div *ngIf="objectIndexVar.errors.required">
                                                                    Object index is required.
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                    


                            </div>

                            <div *ngIf="userAccess?.canCreatePdfForms || userAccess?.canEditPdfForms">
                                <input type="checkbox" (change)="true" class="ml-2" #adv> Advance Options
                                <div  *ngIf="adv.checked" >
                                <button type="button"class="btn btn-info btn-sm" (click)="exportConfig()">Export</button>
                                <button type="button" class="btn btn-info btn-sm ml-2" (click)="importConfig()">Import</button>
                                <textarea name="configAsString" id="configAsString" [(ngModel)]="configAsString"
                                    class="form-control form-control-sm f-13" [disabled]="!editable"
                                        rows="10"
                                       >
                                    </textarea>
                                    </div>
                            </div>
                    </div>
                    <!--Image & File section starts-->

                   

                 </div>

            </div>

            <div class="mb-2">&nbsp;</div>
    </div>
    
</form>