import { AllocatedBlankSupplierDetails } from "./allocated-blank-supplier-details.model";
import { BlankSizeDetails } from "./blank-size-details.model";

export class AllocatedBlankSupplierCombinationDetails {
	id:string;
	allocated:boolean;
    bSupplierDetails:AllocatedBlankSupplierDetails[]=[];
	combination:boolean;
	combinationName:string;
	averageBlankSizeDetails:BlankSizeDetails[]=[];
	averageBlankCost:number;
	totalQtyAllocated:number;
	approvalStatus:string;
	constructor(id:string){
		this.id=id;
	}
	
}
