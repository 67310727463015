import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/service/auth.service';
import { User } from 'src/app/shared/model/user.model';
import { ToastrService } from 'ngx-toastr';


@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
   styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
   user: User;
   submitted = false;
   isServerErrorPresent: boolean;
   serverErrors: string[];
   appConfig: any;

   constructor(private authService: AuthService,
      private toastrService: ToastrService) {
      this.authService.logout();
   }

   ngOnInit() {
      this.user = new User();
      this.authService.logout();
      this.serverErrors = [];
      this.isServerErrorPresent = false;
   }

   /**
    * 
    */
   onFormSubmit() {
      this.isServerErrorPresent = false;
      this.serverErrors.length = 0;
      this.user.userName = this.user.email;
      this.user.password = this.authService.encryptPassword(this.user.password);
      this.authService.login(this.user).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.authService.setUser(response);
         } else {
            console.error("Login Component - Login Service Error...", response.responseStatus.message);
            this.isServerErrorPresent = true;
            this.serverErrors.push(response.responseStatus.message);
            this.toastrService.error(response.responseStatus.message, '', { progressBar: true });
         }
      }, error => {
         console.error(error);
      });

   }

}

