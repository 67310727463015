import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductAttachment } from 'src/app/shared/model/product.model';
import { OrderDetailInfo } from 'src/app/shared/model/sales/fulfillment/order-detail-info.model';
import { PrinterInfo } from 'src/app/shared/model/sales/fulfillment/printer-info.model';
import { OrderDetail } from 'src/app/shared/model/sales/order-detail.model';
import { SalesOrder } from 'src/app/shared/model/sales/sales-order.model';
import { SalesHelperService } from 'src/app/shared/service/sales/sales-helper.service';

@Component({
  selector: 'app-printer-order-detail-link-modal',
  templateUrl: './printer-order-detail-link-modal.component.html',
  styleUrls: ['./printer-order-detail-link-modal.component.css']
})
export class PrinterOrderDetailLinkModalComponent implements OnInit {

  salesOrder:SalesOrder;
  printerInfo:PrinterInfo;


  constructor(public dialogRef: MatDialogRef<PrinterOrderDetailLinkModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private salesHelperService:SalesHelperService) {
    if(this.data.salesOrder){
      this.salesOrder= this.data.salesOrder;
    }
    if(this.data.printerInfo){
      this.printerInfo= this.data.printerInfo;
    }
   }

  ngOnInit(): void {
  }

  save() {
    this.dialogRef.close(true);
  }
  close() {
    this.dialogRef.close(false);
  }

  discardModalWithNoAction(){
   this.dialogRef.close('NO_ACTION');
  }

  isAssignable(orderDetailId){
    return this.isNotAttached(orderDetailId) || this.isMarkedForDeletion(orderDetailId)
  }
  isNotAttached(orderDetailId:string):boolean{
    let retVal=true;
    this.printerInfo.orderDetailInfoList.forEach(element => {
      if(element.orderDetailId===orderDetailId){
        retVal=false;
      }
    });
    return retVal;
  }

  isMarkedForDeletion(orderDetailId:string):boolean{
    let retVal=false;
    this.printerInfo.orderDetailInfoList.forEach(element => {
      if(element.orderDetailId===orderDetailId && element.deleteFlag===true){
        retVal=true;
      }
    });
    return retVal;
  }
  /***Any chnage to this logic must also be implemented in backend for auto update of tracker on costing approval ***/
  attachOrderDetailToPrinter(orderDetail:OrderDetail){
    if(this.isNotAttached(orderDetail.id)){
      let info:OrderDetailInfo=new OrderDetailInfo();
      info.orderDetailId=orderDetail.id;
      info.orderDetail=orderDetail;
      info.salesOrderId=orderDetail.parentId;
      info.orderDetailStatus='ACTIVE';
      info.printerId=this.printerInfo.printerId;
      info.printerInfoId=this.printerInfo.id;
      info.ppSamplesRequired=orderDetail.ppSampleRequired;
      if(info.ppSamplesRequired){
        info.ppSampleStatus='NOT_SUBMITTED';
        info.ppSampleQty=this.salesHelperService.getTotalSubOrderSampleQtyForOrderDetail(orderDetail,'PP_SAMPLE');
      }
      info.topSamplesRequired=orderDetail.topSampleRequired;
      if(info.topSamplesRequired){
        info.topSampleStatus='NOT_SUBMITTED';
        info.topSampleQty=this.salesHelperService.getTotalSubOrderSampleQtyForOrderDetail(orderDetail,'TOP_SAMPLE');
      }
      info.origination='MANUAL';
      info.deleteFlag=false;
      if(!this.printerInfo.orderDetailInfoList || this.printerInfo.orderDetailInfoList===null){
        this.printerInfo.orderDetailInfoList=[];
      }
      this.printerInfo.orderDetailInfoList.push(info);
    }
     if(this.isMarkedForDeletion(orderDetail.id)){
      this.printerInfo.orderDetailInfoList.forEach(element => {
        if(element.orderDetailId===orderDetail.id && element.deleteFlag===true){
          element.deleteFlag=false;
        }
      });
    }
  }

  unAttachOrderDetailToPrinter(orderDetail:OrderDetail){
    this.printerInfo.orderDetailInfoList.forEach(element => {
      
        if(element.orderDetailId===orderDetail.id && element.deleteFlag===false){
          element.deleteFlag=true;
        }
      
    });
  }
  
  aassignAllOrderDetail(){
    this.salesOrder.orderDetails.forEach(orderDetail => {
      if(orderDetail.status!=='CANCELLED'){
        this.attachOrderDetailToPrinter(orderDetail);
      }
    });
  }

  unAssignAllOrderDetail(){
    this.salesOrder.orderDetails.forEach(orderDetail => {
      if(orderDetail.status!=='CANCELLED'){
        this.unAttachOrderDetailToPrinter(orderDetail);
      }
    });
  }


  handleThumbnailError(productAttachment: ProductAttachment, event) {
    if(productAttachment){
    if (isNaN(productAttachment?.thumbnailErrorCount)) {
      productAttachment.thumbnailErrorCount = 0;
    }
    event.target.src = productAttachment.fileSignedUrl;
    var x: number = productAttachment.thumbnailErrorCount;
    productAttachment.thumbnailErrorCount = x + 1;
    if (productAttachment.thumbnailErrorCount < 2) {
      event.target.src = productAttachment.fileSignedUrl;
      productAttachment.thumbnailSignedUrl = productAttachment.fileSignedUrl;
    } else {
      event.target.src = "/assets/default-image.jpg"
    }
    }
  }
}
