import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../shared/service/shared.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MetadataService } from '../shared/service/metadata.service';
import { UserAccess } from '../shared/model/user-access.model';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { Metadata, MetadataTemplate } from '../shared/model/metadata.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { User } from '../shared/model/user.model';
import { AuthService } from '../shared/service/auth.service';

@Component({
   selector: 'app-metadata',
   templateUrl: './metadata.component.html',
   styleUrls: ['./metadata.component.css']
})

export class MetadataComponent implements OnInit {

   constructor(private sharedService: SharedService,private authService:AuthService, private activatedRoute: ActivatedRoute,
      private location: Location, private toastrService: ToastrService, private metadataService: MetadataService) { }

   currentUserSubscription: Subscription;
   currentUser: User;
   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   isSupportAdminUser: boolean = false;
   editable: boolean = false;
   isAddNewMetadataTabSelected = false;

   metadataTemplateList: MetadataTemplate[];
   filteredMetadataTemplateListByClassification: MetadataTemplate[];
   metadataList: Metadata[];

   metadataClassificationAliases: string[];
   metadataClassificationAliasLookup: any;

   selectedMetadataClassification: string;
   selectedMetadataTemplateId: string;// This is id of Metadata Template Object Selected in Metadata Template Type Side Bar or Drop down.
   selectedMetadataTypeValue: string;

   newMetadata: Metadata = new Metadata;
   newMetadataTemplate: MetadataTemplate = new MetadataTemplate;

   metadataClassificationTabIndex: number

   ngOnInit(): void {
      this.handleSubscriptions();
      this.evaluateAction();
      this.metadataClassificationTabIndex = 0;
      this.getMetadataTemplateList();
      this.selectedMetadataClassification = null;
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
         //
      });
      this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
         this.currentUser = user;
         //
         this.isSupportAdminUser = this.sharedService.isSupportAdminUser(this.currentUser.userRoles);
         //
      });
   }

   evaluateAction() {
   }

   goBack() {
      this.location.back();
   }

   edit() {
      this.editable = true;
   }

   cancelEdit() {
      this.editable = false;
      if(this.selectedMetadataTemplateId && this.selectedMetadataTemplateId !==undefined && this.selectedMetadataTemplateId !== null){
         let selectedMetadataTemplateObject = this.getSelectedMetadataTemplateObject(this.selectedMetadataTemplateId);
         //
         this.loadMetadataListByClassificationAndTypeAndSubType(selectedMetadataTemplateObject.metadataClassificationAlias, selectedMetadataTemplateObject.metadataType, selectedMetadataTemplateObject.metadataSubType);
      }else{
         this.getMetadataTemplateList();
      }
   }

   getMetadataTemplateList() {
      this.metadataService.getMetadataTemplateList().subscribe(response => {
         this.metadataTemplateList = response.responsePayload;
         //
         this.metadataTemplateList = this.sharedService.sortListByPropertyName(this.metadataTemplateList, 'metadataClassification');
         this.setup();
      });
   }

   setup() {
      this.createAliasLookupMapForMetadataClassification(this.metadataTemplateList);
      this.getMetadataClassificationAliasList();
      this.metadataClassificationTabIndex = 0;
   }

   createAliasLookupMapForMetadataClassification(metadataTemplateList) {
      //
      var result = metadataTemplateList.reduce(function (map, obj) {
         map[obj.metadataClassificationAlias] = obj.metadataClassification;
         return map;
      }, {});
      //
      this.metadataClassificationAliasLookup = result;
   }

   getMetadataClassificationAliasList() {
      let metadataClassificationAliasList = this.metadataTemplateList.map(metadataTemplate => metadataTemplate.metadataClassificationAlias);
      //
      let uniqueMetadataClassificationAliasList = [...new Set(this.metadataTemplateList.map(item => item.metadataClassificationAlias))];
      //
      this.metadataClassificationAliases = this.sharedService.sort(uniqueMetadataClassificationAliasList);
      //
   }

   getMetadataTypeAliasList(classificationAlias) {
      //('Classification Alias Value = ', classificationAlias)
      let testingLookUp = this.metadataClassificationAliasLookup[classificationAlias]
      //('Corresponding Classification Type Value = ', testingLookUp)
      //
      let localMetadataTemplateFilteredList = this.metadataTemplateList.filter(items => items.metadataClassificationAlias === classificationAlias);
      //('Local Metadata Template Filtered List = ', localMetadataTemplateFilteredList)
      let sortedLocalMetadataTemplateFilteredList = this.sharedService.sortListByPropertyName(localMetadataTemplateFilteredList,'metadataTypeAlias');
      //('Local Metadata Template Sorted Filtered List = ', sortedLocalMetadataTemplateFilteredList)
      this.filteredMetadataTemplateListByClassification = sortedLocalMetadataTemplateFilteredList;
      this.selectedMetadataTemplateId = this.filteredMetadataTemplateListByClassification[0].id;
      this.onMetadataTypeClicked(this.filteredMetadataTemplateListByClassification[0].metadataTypeAlias, this.selectedMetadataTemplateId);
   }

   loadMetadataListByClassificationAndTypeAndSubType(metadataClassificationAlias, metadataType, metadataSubtype) {
      if (metadataSubtype == null || metadataSubtype == undefined) {
         this.getMetadataListByClassificationAndType(metadataClassificationAlias, metadataType);
      }
      else {
         this.getMetadataListByClassificationAndTypeAndSubType(metadataClassificationAlias, metadataType, metadataSubtype);
      }
   }

   getMetadataListByClassificationAndType(classificationAlias, metadataType) {
      let classification = this.metadataClassificationAliasLookup[classificationAlias];
      this.metadataService.getMetadataListByClassificationAndType(classification, metadataType).subscribe(response => {
         this.metadataList = response.responsePayload;
         //
      });
   }

   getMetadataListByClassificationAndTypeAndSubType(classificationAlias, metadataType, metadataSubtype) {
      let classification = this.metadataClassificationAliasLookup[classificationAlias];
      this.metadataService.getMetadataListByClassificationAndTypeAndSubType(classification, metadataType, metadataSubtype).subscribe(response => {
         this.metadataList = response.responsePayload;
         //
      });
   }

   drop(event: CdkDragDrop<string[]>) {
      //
      //
      moveItemInArray(this.metadataList, event.previousIndex, event.currentIndex);
      this.setMetadataSortOrder();
   }

   setMetadataSortOrder() {
      if (this.metadataList != undefined && this.metadataList != null && this.metadataList.length > 0) {
         for (let index = 0; index < this.metadataList.length; index++) {
            this.metadataList[index].sortOrder = index;
         }
      }
   }

   metadataClassificationTabClicked(event: MatTabChangeEvent) {
      //
      this.editable = false;
      let selectedTabIndex = event.index;
      let selectedClassificationTabLabel = event.tab.textLabel;
      this.selectedMetadataClassification = selectedClassificationTabLabel;
      //
      if (selectedClassificationTabLabel !== 'AddNewMetadataTemplate') {
         this.isAddNewMetadataTabSelected = false;
         this.getMetadataTypeAliasList(this.selectedMetadataClassification);
      }
      if (selectedClassificationTabLabel === 'AddNewMetadataTemplate') {
         this.isAddNewMetadataTabSelected = true;
      }
   }

   onMetadataTypeClicked(selectedMetadataTypeValue, selectedMetadataTemplateId) {
      this.selectedMetadataTemplateId = selectedMetadataTemplateId;
      //
      let selectedMetadataTemplateObject = this.getSelectedMetadataTemplateObject(selectedMetadataTemplateId);
      this.selectedMetadataTypeValue = selectedMetadataTypeValue;
      //
      this.loadMetadataListByClassificationAndTypeAndSubType(selectedMetadataTemplateObject.metadataClassificationAlias, selectedMetadataTemplateObject.metadataType, selectedMetadataTemplateObject.metadataSubType);
   }

   getSelectedMetadataTemplateObject(selectedMetadataTemplateTypeId) {
      //
      //
      let selectedMetadataTemplateObject = this.filteredMetadataTemplateListByClassification.find(obj => obj.id == selectedMetadataTemplateTypeId);
      return selectedMetadataTemplateObject;
   }

   addMetadata() {
      //let metadataForCloning = this.metadataList[0];
      let metadataForAddNewMetadata = this.getSelectedMetadataTemplateObject(this.selectedMetadataTemplateId);
      //
      this.newMetadata = new Metadata();
      this.newMetadata.sortOrder = (this.metadataList?.length) + 1;
      this.newMetadata.metadataClassification = metadataForAddNewMetadata.metadataClassification;
      this.newMetadata.metadataType = metadataForAddNewMetadata.metadataType;
      this.newMetadata.metadataSubType = metadataForAddNewMetadata.metadataSubType;
      document.getElementById('metadataModalToggler').click();
   }

   saveMetadata() {
      this.editable = false;
      //
      this.metadataService.createMetadata(this.newMetadata).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
         this.loadMetadataListByClassificationAndTypeAndSubType(this.selectedMetadataClassification, this.newMetadata.metadataType, this.newMetadata.metadataSubType);
      }, (err) => {
         this.toastrService.error(JSON.stringify(err));
      });
   }

   updateMetadataList() {
      this.metadataService.updateMetadataList(this.metadataList).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
         this.editable = false;
      });
   }

   updateMetadataTemplateList() {
      this.metadataService.updateMetadataTemplateList(this.metadataTemplateList).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
         this.editable = false;
         this.getMetadataTemplateList();
      });
   }

   deleteMetadata(metadata: Metadata) {
      if(confirm('Are you sure you want to delete Metadata?\n')){
         //this.metadataList = this.metadataList.filter(obj => obj !== metadata);
         this.metadataService.deleteMetadata(metadata.id).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.toastrService.success(response.responseStatus.message);
            }
            else {
               this.toastrService.error(response.responseStatus.message);
            }
            this.loadMetadataListByClassificationAndTypeAndSubType(this.selectedMetadataClassification, metadata.metadataType, metadata.metadataSubType);
            this.editable = false;
         }, (err) => {
            this.toastrService.error(JSON.stringify(err));
         });
      }
   }

   createNewMetadataTemplate() {
      this.editable = false;
      //
      this.metadataService.createMetadataTemplate(this.newMetadataTemplate).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
         this.getMetadataTemplateList();
         this.newMetadataTemplate = new MetadataTemplate;
      }, (err) => {
         this.toastrService.error(JSON.stringify(err));
      });
   }

   deleteMetadataTemplate(metadataTemplate: MetadataTemplate) {
      if(confirm('Are you sure you want to delete Metadata Template?\n')){
         this.metadataService.deleteMetadataTemplate(metadataTemplate.id).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.toastrService.success(response.responseStatus.message);
            }
            else {
               this.toastrService.error(response.responseStatus.message);
            }
            this.getMetadataTemplateList();
            this.editable = false;
         }, (err) => {
            this.toastrService.error(JSON.stringify(err));
         });
      }
   }

   closeMetadataModal() {
      this.reset();
   }

   reset() {
   }

}
