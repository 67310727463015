<ng-container *ngIf="dashletDetails && dashletDetails.idList">
   <div class="card mt-2" [style.background-color]="dashletDetails?.idList?.length > 2?'':''">
      <div class="h-100 d-flex justify-content-center" style="border:0px;">
         <div class="p-2">
            <div class="d-flex justify-content-center">
               <h1 class="align-text-top align-middle" style="font-size: 3rem; cursor: pointer;" (click)="handleClick(dashletDetails)">{{dashletDetails.idList.length}}</h1>
            </div>
            <div class="d-flex justify-content-center">
               <span class="f-11">{{dashletDetails.title}}</span>
            </div>
         </div>
      </div>
      <!--   
      <div class="d-flex justify-content-start align-items-end">
         <div class="text-nowrap text-muted f-11 mt-1">
            {{dashletDetails.footerDesc}}
         </div>
      </div>
   -->
   </div>
</ng-container>