import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../model/api-response';
import { HttpClient } from '@angular/common/http';
import { Task } from '../../model/task/task.model';

@Injectable({
   providedIn: 'root'
})
export class TaskService {

   constructor(private http: HttpClient) { }

   readonly rooturl = environment.apiEndPoint;

   completeTaskById(taskId: string) {
      return this.http.post<any>(this.rooturl + '/task/complete/' + taskId, null);
   }


   completeTask(task: Task) {
      return this.http.post<any>(this.rooturl + '/task/complete', task);
   }

   reassignTask(task: Task) {
      return this.http.post<any>(this.rooturl + '/task/reassign', task);
   }

   loadTaskDetails(taskId:string):Observable<ApiResponse>{
      return this.http.get<ApiResponse>(this.rooturl + '/taskDetails/load/'+taskId);
   }
   saveTaskDetails(task:Task){
      return this.http.post<any>(this.rooturl + '/taskDetails/save', task);
   }
   
}
