import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../model/api-response';
import { HttpClient } from '@angular/common/http';
import { Notes } from '../../model/common/notes.model';

@Injectable({
   providedIn: 'root'
})
export class NotesService {
  

   constructor(private http: HttpClient) { }

   readonly rooturl = environment.apiEndPoint;

   getNotesList(parentId: string,type:string,subType:string): Observable<ApiResponse> {
      return this.http.get<any>(this.rooturl + '/notes/list/'+parentId+'/'+type+'/'+subType);
   }

   saveNotesList(notesList: Notes[],parentId:string) {
      return this.http.post<any>(this.rooturl + '/notes/save/'+parentId, notesList);
   }

   updateNotes(notes: Notes) {
      return this.http.post<any>(this.rooturl + '/notes/update', notes);
    }

   
}
