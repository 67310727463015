<div class="container-fluid">
    <div class="floatBtn d-flex flex-column">    
        <!--<button type="button"  (click)="resetCatalogSelection()"  class=" btn btn-circle bg-dark mb-2" *ngIf="(productList.length)>0 && !creatingCatalog"  matTooltip="Reset product selection for catalog" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-undo-alt fa-lg" style="color: white;"></i></button>-->
        <a (click)="createBackendPdf()" class="btn btn-circle bg-dark mb-2" *ngIf="(productList.length)>0 && !creatingCatalog" matTooltip="Create Catalog" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-file-pdf fa-2x" style="color: white;"></i></a>
        <a [routerLink]="['/product-search']" style="background-color: tomato;" class="btn btn-circle" *ngIf="!creatingCatalog" matTooltip="Product Search" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-arrow-left  fa-2x"></i></a><!--fa-file-pdf-->
    </div>
    
    <div class="row mt-1 pt-2">
    
    <div class="col-lg-2 col-sm-3 col-md-3 shadow f-13"><!-- overflow-auto" style="height: calc(100vh);-->
        <div class="mt-2 mb-2">
            <div class="list-group list-group-item bg-dark  text-white">
                <i class="fas fa-sliders-h"></i>&nbsp;Catalog Settings
            </div>
        </div>
        <div class="row">
            <div class="col-sm-7 d-flex align-items-center">
                <!--<h5 class="mt-2">Background</h5>-->
                <label class="mt-1 ml-1 mr-1">Background</label>
                <button type="button" class="btn btn-circle btn-circle-sm " *ngIf="!backgroundEditable" (click)="editBackground()"><i class="fas fa-pencil-alt" style="color: white;"></i></button>
                <button type="button" class="btn btn-circle btn-circle-sm  bg-info" *ngIf="backgroundEditable" (click)="saveEditBackground()"><i class="fas fa-save" style="color: white;"></i></button>
                        
            </div>
                <div class="col-sm ml-1 mt-1 "  *ngIf="advanced">
                    <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="fileSelectedDragDrop($event,'CATALOG_BG')" style="height: 30px;" *ngIf="backgroundEditable">
                                <input hidden type="file" #fileInput multiple (change)="fileSelectedDragDrop($event.target.files,'CATALOG_BG')">
                        </div>
                </div>
        </div>
        <div class="overflow-auto mb-2 mt-1" style="height: 10rem;">
            <ul class="list-group list-group-flush">
                <li class="list-group-item-custom {{selectedBackgroundUrl.indexOf(bgImg)>-1?'':''}}" 
                    [style]="bgImg.id===selectedBackgroundUrl? 'border-color:tomato;border-width:initial' : ''"
                    
                    *ngFor="let bgImg of backGroundUrlList"
                    style="cursor: pointer;">
                    <div class="d-flex  align-items-center  justify-content-end">
                    <img [src]="bgImg.thumbnailSignedUrl" (click)="selectedBackground(bgImg.id)"
                     width="100%" height="40px"> 
                    <button type="button" class="btn btn-circle btn-circle-sm position-absolute mr-2" *ngIf="backgroundEditable"
                                                                                (click)="deleteFile(bgImg.id,'CATALOG_BG')"><i
                                                                                        class="fas fa-trash text-light"></i></button>
                    </div>
                </li>
            </ul>
        </div>
        <hr>
        <div class="d-flex justify-content-between input-group-text f-13 bg-transparent ">
            <div class="d-flex justify-content-start">
                <span class=" " style="background-color:transparent">Cover Page</span>
            </div>
            <div class="d-flex justify-content-end align-items-center">
                <label class="switch  d-flex justify-content-end" style="margin-bottom: 0px;">
                <input type="checkbox" [(ngModel)]="frontPageRequired" name="frontPageRequired" class="pull-left"  (change)="frontPageToggle()">
                <span class="slider round"></span>
                </label>
            </div>
        </div>
        <div class="d-flex justify-content-between input-group-text f-13 bg-transparent ">
            <div class="d-flex justify-content-start">
                <span class=" " style="background-color:transparent">Print SKU Code</span>
            </div>
            <div class="d-flex justify-content-end align-items-center">
                <label class="switch  d-flex justify-content-end" style="margin-bottom: 0px;">
                <input type="checkbox" [(ngModel)]="printSkuCode" name="printSkuCode" class="pull-left" >
                <span class="slider round"></span>
                </label>
            </div>
        </div>
        <div class="d-flex justify-content-between input-group-text f-13 bg-transparent ">
            <div class="d-flex justify-content-start">
                <span class=" " style="background-color:transparent">Print Color</span>
            </div>
            <div class="d-flex justify-content-end align-items-center">
                <label class="switch  d-flex justify-content-end" style="margin-bottom: 0px;">
                <input type="checkbox" [(ngModel)]="printColor" name="printColor" class="pull-left" >
                <span class="slider round"></span>
                </label>
            </div>
        </div>
        <div class="d-flex justify-content-between input-group-text f-13 bg-transparent ">
            <div class="d-flex justify-content-start">
                <span class=" " style="background-color:transparent">Print Treatment</span>
            </div>
            <div class="d-flex justify-content-end align-items-center">
                <label class="switch  d-flex justify-content-end" style="margin-bottom: 0px;">
                <input type="checkbox" [(ngModel)]="printTreatment" name="printTreatment" class="pull-left" >
                <span class="slider round"></span>
                </label>
            </div>
        </div>
        <div class="d-flex justify-content-between input-group-text f-13 bg-transparent ">
            <div class="d-flex justify-content-start">
                <span class=" " style="background-color:transparent">Print Status</span>
            </div>
            <div class="d-flex justify-content-end align-items-center">
                <label class="switch  d-flex justify-content-end" style="margin-bottom: 0px;">
                <input type="checkbox" [(ngModel)]="printApprovalStatus" name="printApprovalStatus" class="pull-left" >
                <span class="slider round"></span>
                </label>
            </div>
        </div>
        <div class="d-flex justify-content-between input-group-text f-13 bg-transparent">
            <div class="d-flex justify-content-start">
                <span class=" " style="background-color:transparent">Compress Images</span>
            </div>
            <div class="d-flex justify-content-end align-items-center">
                <label class="switch  d-flex justify-content-end" style="margin-bottom: 0px;">
                <input type="checkbox" [(ngModel)]="compressed" name="compressed" class="pull-left">
                <span class="slider round"></span>
                </label>
            </div>
        </div>
        
        <div *ngIf="advanced">
            <div class="form-group"><!--style="margin-bottom: 0rem;"-->
                <label for="groupBy" class=" col-form-label f-13 col-form-label-sm ml-1">Group By</label>
                <div class="">
                    <select class="form-control custom-select-sm custom-select f-13 " id="groupBy" name="segregationType" 
                    #segregationType (change)="segregateProductsIntoPages(true)" [(ngModel)]="groupType">
                        <option value='NONE'>None</option>
                        <option value='BRAND_CATEGORY'>Brand Category</option>
                        <option value='CATEGORY'>Product Category</option>
                        <option value='GARMENT'>Garment</option>
                        <option value='COLOR'>Color</option>
                        <option value='TREATMENT'>Treatment</option>
                        <option value='PRINT_EFFECT'>Print Effect</option>
                    </select>
                </div>
            </div> 
            <div class="form-group"><!--style="margin-bottom: 0rem;"-->
                <label for="sortBy" class=" col-form-label f-13 col-form-label-sm ml-1">Sort By</label>
                <div class="">
                    <select class="form-control custom-select-sm custom-select f-13 " id="sortBy" name="sortTypeName" 
                    #sortTypeVar (change)="sortProductsInPages()"  [(ngModel)]="sortType">
                        <option value='NONE'>None</option>
                        <option value='SKU'>SKU</option>
                        <option value='BRAND_NAME'>Brand Name</option>                       
                        <option value='BRAND_CATEGORY'>Brand Category</option>
                        <option value='CATEGORY'>Product Category</option>
                        <option value='GARMENT'>Garment</option>
                        <option value='COLOR'>Color</option>
                        <option value='TREATMENT'>Treatment</option>
                        <option value='PRINT_EFFECT'>Print Effect</option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <label for="itemsPerRow" class=" col-form-label f-13 col-form-label-sm ml-1">Images Per Row</label>
                <div class="">
                        <select class="form-control custom-select-sm custom-select f-13 "
                                name="itemsPerRow"  [(ngModel)]="itemsPerRow"
                                #itemPerRowVar>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                        </select><!--[(ngModel)]="product.approvalStatus"-->
                </div>
            </div>
            
            <div class="form-group">
                <label  for="rowsPerPage" class=" col-form-label f-13 col-form-label-sm ml-1">Rows Per Page</label>
                <div class="" >
                        <select class="form-control custom-select-sm custom-select f-13 "
                                name="rowsPerPage" [(ngModel)]="rowsPerPage"
                                #rowsPerPageVar>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                        </select><!--[(ngModel)]="product.approvalStatus"-->
                </div>
            </div>
            <div class="form-group">
                <label  for="border" class=" col-form-label f-13 col-form-label-sm ml-1">Image Border</label>
                <div class="" >
                        <select class="form-control custom-select-sm custom-select f-13 "
                                name="border" [(ngModel)]="imageBorder"
                                #borderVar>
                                <option value="NA">N/A</option>
                                <option value="thin">Thin</option>
                                <option value="thick">Thick</option>
                                <option value="thickest">Thickest</option>
                        </select><!--[(ngModel)]="product.approvalStatus"-->
                </div>
            </div>
            <div class="form-group">
                <label  for="borderColor" class=" col-form-label f-13 col-form-label-sm ml-1">Border Color</label>
                <div class="" >
                        <select class="form-control custom-select-sm custom-select f-13 " 
                                name="borderColor" [(ngModel)]="borderColor"
                                #borderColorVar>
                                <option value="black">Black</option>
                                <option value="white">White</option>
                        </select><!--[(ngModel)]="product.approvalStatus"-->
                </div>
            </div>

            <div class="form-group">
                <label  for="pageSize" class=" col-form-label f-13 col-form-label-sm ml-1">Page Size</label>
                <div class="" >
                        <select class="form-control custom-select-sm custom-select f-13 "
                                name="pageSize" [(ngModel)]="pageSize"
                                #pageSizeVar>
                                <option value="A4">A4</option>
                                <option value="LETTER">Letter</option>
                        </select><!--[(ngModel)]="product.approvalStatus"-->
                </div>
            </div>

            <div class="form-group">
                <label  for="orientation"  class=" col-form-label f-13 col-form-label-sm ml-1">Orientation</label>
                <div class="" >
                        <select class="form-control custom-select-sm custom-select f-13 "
                                name="orientation" [(ngModel)]="orientation"
                                #orientationVar>
                                <option value="L">Landscape</option>
                                <option value="P">Portrait</option>
                        </select><!--[(ngModel)]="product.approvalStatus"-->
                </div>
            </div>
            <div class="form-group">
                <label  for="imageBackGround" class=" col-form-label f-13 col-form-label-sm ml-1 ">Image Background</label>
                <div class="" >
                        <select class="form-control custom-select-sm custom-select f-13 "
                                name="imageBackGround" [(ngModel)]="imageBackGround"
                                #imageBackGroundVar>
                                <option value="transparent">Transparent</option>
                                <option value="white-solid">Solid</option>
                                <option value="white-opaque">Opaque</option>
                        </select><!--[(ngModel)]="product.approvalStatus"-->
                </div>
            </div>
        </div>
            
       
        
        
    </div>
    <div class="col-sm-9 col-lg-10 col-md-9">
        <div class="">
            <div style="position: absolute;left: 0;" class="ml-2 mt-1">
                <button type="button" (click)="loadCatalogDialog()" style="height: fit-content;" class="btn btn-sm btn-warning"  matTooltip="Load catalog draft" matTooltipPosition="below" matTooltipClass="bg-dark"><i class="fas fa-file-import"></i> Load</button>
                <button type="button" (click)="resetCatalogSelection()" style="height: fit-content;" class="btn btn-sm btn-secondary ml-2" *ngIf="(productList.length)>0 && !creatingCatalog" matTooltip="Reset current product selection" matTooltipPosition="below" matTooltipClass="bg-dark">Reset</button>
                <button type="button" (click)="saveCatalog()" style="height: fit-content;" *ngIf="(productList.length)>0 && !creatingCatalog" class="btn btn-success ml-2 btn-sm" matTooltip="Save catalog draft" matTooltipPosition="below" matTooltipClass="bg-dark" ><i class="fas fa-save"></i> Save</button>
            </div>
            
            <div class="row d-flex justify-content-center">
                <h2 class="d-inline">{{catalogName}}</h2>
                
            </div>
            
            
            

        </div>
        <hr class="my-1">
        <div class="ml-3" *ngIf="creatingCatalog">Creating Catalog.......</div>
        <div class="col-sm" *ngIf="productList.length>0" id="contentToConvert">
                <div *ngIf="false" >         
                    <section class="gallery-block cards-gallery">
                        <div class="row mt-2 justify-content-center">
                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-4 ml-1 mr-1"
                                *ngFor="let product of productList;index as i" >
                                <div class="card mt-2 transform-on-hover h-80" id="contentToConvert{{i}}">
                                    <div class=" card-body d-flex justify-content-center align-items-center"
                                        style="height:250px;">
                                        <a class="lightbox"
                                            [routerLink]="['../product/view/'+product.licensorId+'/'+product.brandId+'/'+product.id]">
                                            <img [src]="getImageURI(product.productImages[0].fileSignedUrl)"
                                                class="card-img-top img-auto-size" *ngIf="product.productImages[0]">
                                        </a>
                                        <a class="lightbox"
                                            [routerLink]="['../product/view/'+product.licensorId+'/'+product.brandId+'/'+product.id]">
                                            <img src="assets/default-image.jpg" 
                                            class="card-img-top img-auto-size" *ngIf="!product.productImages[0]">
                                        </a>
                                    </div>
                                    <div class="card-footer">
                                        <div class="d-flex justify-content-center m-0">
                                            <div class="d-inline" style="display: block; height:1rem;">
                                                <label class="cb-container">&nbsp;
                                                    <input type="checkbox" (change)="selectedForCatalog($event,product)" [checked]="isProductSelectedForCatalog(product.id)">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <h6 class="d-inline">{{product.sku}}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    </div>
                   
                    <!-- ATTEMPT MULTIPAGE 
                    
                    <div cdkDropListGroup>
                       
                        <div *ngFor="let list of masterCatalogList;index as index">
                            
                            <div class="example-container" #container>
                                
                                <h2>{{list.classificationValue}}</h2>
                                <div class="d-flex justify-content-between" #scrollBox id=scroll{{index}}>
                                    <button type="button" class="btn btn-outline-info" >&lt;</button>
                                    <div class="overflow-auto" style="width:75vw">
                                        
                                            <div
                                            cdkDropList
                                            
                                            [cdkDropListData]="list.products"
                                            
                                            cdkDropListOrientation="horizontal" class="icon-drag-list"
                                            (cdkDropListDropped)="drop($event)">
                                            
                                                <div class="example-box" *ngFor="let product of list.products" cdkDrag>
                                                    <img [src]="getImageURI(product.productImages[0].fileSignedUrl)"
                                                                                        class="card-img-top img-auto-size" *ngIf="product.productImages[0]">      
                                                </div>
                                            
                                            </div>
                                    </div>
                                    <button type="button" class="btn btn-outline-info">&gt;</button>
                                </div>
                                
                            </div>
                            
                        </div>
                      </div>
                     

                        <--<div class="example-container overflow-auto">
                            <h2>To do</h2>
                        
                            <div
                            cdkDropList
                            #todoList="cdkDropList"
                            [cdkDropListData]="productList"
                            [cdkDropListConnectedTo]="[doneList]"
                            class="example-list"
                            (cdkDropListDropped)="drop($event)">
                            <div class="example-box" *ngFor="let product of productList" cdkDrag>
                                <img [src]="getImageURI(product.productImages[0].fileSignedUrl)"
                                                                    class="card-img-top img-auto-size" *ngIf="product.productImages[0]">      
                            </div>
                            </div>
                        </div>
                        
                        <div class="example-container">
                            <h2>Done</h2>
                        
                            <div
                            cdkDropList
                            #doneList="cdkDropList"
                            [cdkDropListData]="done"
                            [cdkDropListConnectedTo]="[todoList]"
                            class="example-list"
                            (cdkDropListDropped)="drop($event)">
                            <div class="example-box" *ngFor="let item of done" cdkDrag>{{item}}</div>
                            </div>
                        </div>-->
                        <!-- ATTEMPT MULTIPAGE AND SORTING-->
        
        <mat-tab-group  [selectedIndex]="selectedTab" (selectedTabChange)="tabChanged($event)">
            <mat-tab label="Cover" [disabled]="!frontPageRequired" >
                <div class="overflow-hidden">
                    <div class="row mt-2">
                        <div class="col-sm-6">
                            <h6>Content</h6>
                            <hr>
                            <div *ngFor="let textDetails of textDetailsArr">
                                <div class="row">
                                    <div class="col-sm-6 ml-1">
                                        <div class="form-group">
                                            <label for="heading" class=" col-form-label f-13 col-form-label-sm ml-1">{{textDetails.type}}</label>
                                            <textarea name="heading" [(ngModel)]="textDetails.text" class="form-control form-control-sm"
                                            rows="1" #heading="ngModel" maxlength="250"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label  for="headingTextColor" class=" col-form-label f-13 col-form-label-sm ml-1">Text Color</label>
                                            <div class="" >
                                                    <select class="form-control custom-select-sm custom-select f-13 " 
                                                            name="headingTextColor" [(ngModel)]="textDetails.color"
                                                            #headingTextColorVar>
                                                            <option value="BLACK">Black</option>
                                                            <option value="WHITE">White</option>
                                                    </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label  for="headingTextSize" class=" col-form-label f-13 col-form-label-sm ml-1">Text Size</label>
                                            <div class="" >
                                                    <select class="form-control custom-select-sm custom-select f-13 " 
                                                            name="headingTextSize" [(ngModel)]="textDetails.size"
                                                            #headingTextSizeVar>
                                                            <option [value]="size" *ngFor="let size of fontSizeArr">{{size}}</option>
                                                    </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <h4>Visual</h4>
                            <hr>
                            <div class="mt-1 mb-1" >
                                <div class="uploadfilecontainer" *ngIf="catalogFrontPageMainImageUrl===undefined" (click)="fileInput.click()" appDragDrop (onFileDropped)="frontPageFileSelectedDragDrop($event,'CATALOG_FRONT_IMAGE')" style=" width:240px;height: 50px;">
                                            <input hidden type="file" #fileInput  (change)="frontPageFileSelectedDragDrop($event.target.files,'CATALOG_FRONT_IMAGE')">
                                    </div>
                            </div>
                            <div  class=" card d-flex align-items-center justify-content-center border border-dark" style="width:240px;height:240px;" *ngIf="catalogFrontPageMainImageUrl!==undefined">
                                <div *ngIf="catalogFrontPageMainImageUrl!==undefined">
                                    <img [src]="catalogFrontPageMainImageUrl" class="img-auto-size">
                                    <button type="button" class="btn btn-circle btn-circle-sm card-img-overlay d-flex flex-column ml-1 mt-1" 
                                    (click)="resetCatalogFrontPageMainImageUrl()"><i
                                            class="fas fa-trash text-light"></i></button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{list.classificationValue}}" *ngFor="let list of masterCatalogList;index as index1">
                <ng-template mat-tab-label>
                    {{list.classificationValue}} <span class="badge badge-pill badge-dark ml-2">{{list.products.length}}</span>
                  </ng-template>
                  <div>
                      <button type="button" class="btn" style="background: transparent;" (click)="groupLeftMove(index1)" [disabled]="index1===0" matTooltip="Move {{list.classificationValue}} before {{masterCatalogList[(index1-1)]?.classificationValue}}" matTooltipClass="bg-dark"><i class="fas fa-chevron-left fa-lg"></i></button>
                      <button type="button" class="btn" style="background: transparent;" (click)="groupRightMove(index1)" [disabled]="index1===(masterCatalogList.length -1)"  matTooltip="Move {{list.classificationValue}} after {{masterCatalogList[(index1+1)]?.classificationValue}}" matTooltipClass="bg-dark"><i class="fas fa-chevron-right fa-lg"></i></button>
                  </div>
                  <div class="overflow-hidden">
                <section class="gallery-block cards-gallery">
                    <div class="row mt-2 justify-content-center">
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3"
                            *ngFor="let product of list.products" >
                            <div class="card mt-2 transform-on-hover h-80" >
                                <a class="lightbox"
                                [routerLink]="['../product/view/'+product.licensorId+'/'+product.brandId+'/'+product.id]">
                                    <div class=" card-body d-flex justify-content-center align-items-center"
                                        style="height:250px;">
                                    <img [src]="product.productImages[0].thumbnailSignedUrl" (error)="handleThumbnailError(product.productImages[0],$event)"
                                                class="card-img-top img-auto-size" *ngIf="product.productImages[0]">
                                    <img src="assets/default-image.jpg" 
                                            class="card-img-top img-auto-size" *ngIf="!product.productImages[0]">
                                        
                                    </div>
                                </a>
                                <div class="card-footer card-footer-{{approvalStatusColor(product.approvalStatus)}}">
                                    <span (click)="selectedForCatalog($event,product)" style="cursor: pointer;" *ngIf="(product.approvalStatus!=='Rejected' && product.productImages.length>0)"
                                        matTooltip="{{isProductSelectedForCatalog(product.id)?'Remove from ':'Add to '}}catalog selection" matTooltipClass="bg-dark">
                                        <div class="d-flex justify-content-center m-0">
                                            <div class="d-flex justify-content-left" style="display: block; height:15px;" >
                                                <label class="cb-container">&nbsp;
                                                    <input type="checkbox" disabled (change)="selectedForCatalog($event,product)"
                                                        [checked]="isProductSelectedForCatalog(product.id)">
                                                    <span class="checkmark-sm"></span>
                                                </label>
                                            </div>
                                            <h6 class="d-inline m-0 text-break">{{product.sku}}</h6>
                                        </div>
                                    </span>
                                    <span *ngIf="( product.approvalStatus==='Rejected' || product.productImages.length===0)">
                                        <div class="d-flex justify-content-center m-0">
                                            <div class="d-flex justify-content-left" style="display: block; height:15px;" ></div>
                                            <h6 class="d-inline m-0 text-break">{{product.sku}}</h6>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            </mat-tab>
            
        </mat-tab-group>
    </div>
    </div>
    

    
    </div>
    
</div>
