import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { PrinterInfo } from 'src/app/shared/model/sales/fulfillment/printer-info.model';
import { ProductionTracker } from 'src/app/shared/model/sales/fulfillment/production-tracker.model';
import { TrimsInfo } from 'src/app/shared/model/sales/fulfillment/trims-info.model';
import { SalesOrder } from 'src/app/shared/model/sales/sales-order.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';

@Component({
  selector: 'app-trim-info',
  templateUrl: './trim-info.component.html',
  styleUrls: ['./trim-info.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class TrimInfoComponent implements OnInit {
  @Input() salesOrder:SalesOrder;
  @Input() userAccess:UserAccess;
  @Input() productionTracker:ProductionTracker;
  @Input() printerInfo:PrinterInfo;
  @Input() printerInfoIndex:number=0;
  @Input() trimInfoIndex: number=0;
  @Input() editable:boolean=false;


constructor(private sharedService:SharedService) { 

}

  ngOnInit(): void {
   // this.getVendorList();
  }

  
  addTrimInfo(){
    this.printerInfo.trimInfoList.push(new TrimsInfo(this.productionTracker.salesOrderId));
  };
  deleteTrimInfo(trimInfo:TrimsInfo){
    trimInfo.deleteFlag=true;
  };
 
}
