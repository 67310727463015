import { Component, OnInit } from '@angular/core';
import { transition, animate, style, state, trigger, query, stagger } from '@angular/animations';
import { SkuDashboardDetails } from 'src/app/shared/model/dashoard/sku-dashboard-details.model';
import { Product } from 'src/app/shared/model/product.model';
import { ProductService } from 'src/app/shared/service/product.service';
import { ProductSearchService } from 'src/app/shared/service/product-search.service';

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
  )
]);

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true}
    )
  ])
]);

@Component({
  selector: 'app-sku-tracker-dashboard',
  templateUrl: './sku-tracker-dashboard.component.html',
  styleUrls: ['./sku-tracker-dashboard.component.css'],
  animations: [fadeAnimation, listAnimation]
})
export class SkuTrackerDashboardComponent implements OnInit {

  dashBoardStats:SkuDashboardDetails[]=[];
  selectedItemIndex:number=-1;
  searchProductListOverride:string='';
  constructor(private productService:ProductService,private productSearchService:ProductSearchService) { }

  ngOnInit(): void {
    this.loadDashBoardData();
    this.selectedItemIndex=this.productSearchService.skuSearchDetails.dashBoardSelectedIndex;
  }
  ngOnDestroy(){
    this.productSearchService.skuSearchDetails.productIdListString='';
  }
  loadDashBoardData() {
    this.productService.getSkuDashBoardDetails().subscribe((res)=>{
      this.dashBoardStats=res as SkuDashboardDetails[];
      if(this.selectedItemIndex!==-1){
      this.setSelectedItemIndex(this.selectedItemIndex);
      }
    });
    /*for (let index = 0; index < 4; index++) {
      let details=new SkuDashboardDetails;
      details.iconType='far fa-clock fa-2x';
      details.footerDesc="Last 2 weeks";
      details.skuList=[];
      details.state="warning";
      details.title="Conept Pending";
      this.dashBoardStats.push(details);
    }*/
  }
  setSelectedItemIndex(index:number){
    this.selectedItemIndex=-1;
    this.searchProductListOverride='';
    if(this.dashBoardStats && this.dashBoardStats[index] 
      && this.dashBoardStats[index].skuList 
      && this.dashBoardStats[index].skuList.length>0){
        this.dashBoardStats[index].skuList.forEach(element => {
          this.searchProductListOverride=this.searchProductListOverride+element+' ';
    });
    this.searchProductListOverride=this.searchProductListOverride.slice(0,this.searchProductListOverride.length-1);
    this.selectedItemIndex=index;
    this.productSearchService.skuSearchDetails.dashBoardSelectedIndex=this.selectedItemIndex;
  }
  }
  onSave(product:ProductSearchService){
    //alert('Slu Saved');
    this.loadDashBoardData();
    //this.setSelectedItemIndex(this.selectedItemIndex);
  }
}
