import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ViewChild } from '@angular/core';
import { RoleService } from 'src/app/shared/service/role.service';
import { Role } from 'src/app/shared/model/role.model';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Permission } from 'src/app/shared/model/permission.model';
import { PermissionService } from 'src/app/shared/service/permission.service';
import { AuthService } from 'src/app/shared/service/auth.service';


@Component({
   selector: 'app-role-permission',
   templateUrl: './role-permission.component.html',
   styleUrls: ['./role-permission.component.css']
})

export class RolePermissionComponent implements OnInit {

   constructor(private sharedService: SharedService, private activatedRoute: ActivatedRoute,
      private location: Location, private toastrService: ToastrService, private roleService: RoleService,
      private permissionService: PermissionService,private authService:AuthService) { }

   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   editable: boolean = false;

   selectedRoleId: string;
   selectedRole: Role = new Role;

   allPermissionsList: Permission[];
   unselectedPermissionsList: Permission[] = [];
   newOrSelectedPermission: Permission = new Permission;

   @ViewChild('addEditPermissionForm') addEditPermissionForm: any;

   ngOnInit() {
      this.editable = false;
      this.handleSubscriptions();
      this.evaluateAction();
      // this.loadAllPermissions();
      this.getRoleById(this.selectedRoleId);
      window.scrollTo(0, 0);
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
         //
      });
   }

   evaluateAction() {
      //
      let roleId = this.activatedRoute.snapshot.paramMap.get('id');
      this.selectedRoleId = roleId;
   }

   edit() {
      this.editable = true;
   }

   cancelEdit() {
      this.editable = false;
      this.getRoleById(this.selectedRoleId);
   }

   goBack() {
      this.location.back();
   }

   sort(list: string[]): string[] {
      if (list != null) {
         let sortedList: string[] = list.sort(function (a, b) {
            var textA = a.toUpperCase();
            var textB = b.toUpperCase();
            return (textA.charAt(0) < textB.charAt(0)) ? -1 : (textA.charAt(0) > textB.charAt(0)) ? 1 : 0;
         });
         return sortedList;
      }
   }

   getObjectById(list, id) {
      return list.find(x => x.id === id);
   }

   drop(event: CdkDragDrop<string[]>) {
      //
      //
      if (event.previousContainer === event.container) {
         moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
         transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      }
   }

   /********************************** Start: ROLE **********************************/

   getRoleById(id: string) {
      this.unselectedPermissionsList = [];
      this.roleService.loadRoleById(id).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.selectedRole = response.responsePayload;
         }
         //
         this.sharedService.sortListByPropertyName(this.selectedRole.rolePermissions,'securityDescription');
         this.loadAllPermissions();

      }, error => {
         console.error(error);
      });
   }

   updateRole() {
      this.editable = false;
      //
      this.roleService.updateRole(this.selectedRole).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let updatedRole: Role = response.responsePayload;
            //
            this.toastrService.success(response.responseStatus.message);
            this.getRoleById(updatedRole.id);
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
      }, (err) => {
         this.toastrService.error(JSON.stringify(err));
      });
   }

   closeAddNewRoleModal() {
   }

   /********************************** End: ROLE **********************************/

   /********************************** Start: PERMISSIONS *************************/

   loadAllPermissions() {
      this.permissionService.loadPermissions().subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.allPermissionsList = response.responsePayload;
         }
         this.filterSelectedPermissionsFromAllPermissionList();
      }, error => {
         console.error(error);
      });
   }

   filterSelectedPermissionsFromAllPermissionList() {
      //
      //
      this.unselectedPermissionsList = [];
      //('After Clearing Unselected Permission List = ', this.unselectedPermissionsList)
      this.allPermissionsList.forEach(permission => {
         let permissionId = permission.id;
         //
         if (!this.isPermissionSelected(this.selectedRole.rolePermissions, permissionId)) {
            this.unselectedPermissionsList.push(permission);
         }
         this.sharedService.sortListByPropertyName(this.unselectedPermissionsList,'securityDescription');
      });
      //
   }

   isPermissionSelected(selectedPermissionsList: Permission[], id: string): boolean {
      if (selectedPermissionsList) {
         let permissionObjectExisits = this.getObjectById(selectedPermissionsList, id);
         //
         if (permissionObjectExisits !== undefined) {
            //
            return true;
         } else {
            //
            return false;
         }
      }
   }

   addOrEditPermission(permission: Permission) {
      if (permission !== null && permission !== undefined && permission.id != null && permission.id != undefined) {
         this.newOrSelectedPermission = permission;
      } else {
         this.addEditPermissionForm.reset();
      }
      document.getElementById('addEditPermissionModalToggler').click();
   }

   createOrUpdatePermission(permission: Permission) {
      if (permission.id === null) {
         this.createNewPermission(permission);
      } else {
         this.updatePermission(permission);
      }
   }

   createNewPermission(newPermission: Permission) {
      //this.editable = false;
      //
      this.permissionService.createPermission(newPermission).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let createdPermission: Permission = response.responsePayload;
            //
            this.toastrService.success(response.responseStatus.message);
            //this.getRoleById(this.selectedRole.id);
            this.loadAllPermissions();
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
      }, (err) => {
         this.toastrService.error(JSON.stringify(err));
      });
   }

   updatePermission(updatePermission: Permission) {
      //this.editable = false;
      //
      this.permissionService.updatePermission(updatePermission).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let udatedPermission: Role = response.responsePayload;
            //
            this.toastrService.success(response.responseStatus.message);
            //this.getRoleById(this.selectedRole.id);
            this.loadAllPermissions();
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
      }, (err) => {
         this.toastrService.error(JSON.stringify(err));
      });
   }

   updateRoleWithSelectedPermissions() {
      //
      //
      this.editable = false;
      //
      this.roleService.updateRoleWithSelectedPermissions(this.selectedRole).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let udatedRole: Role = response.responsePayload;
            //
            this.toastrService.success(response.responseStatus.message);
            this.getRoleById(udatedRole.id);
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
      }, (err) => {
         this.toastrService.error(JSON.stringify(err));
      });
   }
   
   deletePermission(permission:Permission) {
      if(confirm('Are you sure you want to delete Permission?\nThis permission will also be deleted from all the roles.')){
         this.permissionService.deletePermission(permission).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.toastrService.success(response.responseStatus.message);
            }
            else {
               this.toastrService.error(response.responseStatus.message);
            }
            this.loadAllPermissions();
            this.editable = false;
         }, (err) => {
            this.toastrService.error(JSON.stringify(err));
         });
      }
   }

   closeAddEditPermissionModal() {
   }

   /********************************** End: PERMISSIONS   *************************/

}
