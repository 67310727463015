<div class="row mt-2 d-flex justify-content-center">
    <h2 class="d-inline">Print Assignments</h2>
   
 </div>
 <hr>
<div class="row" *ngIf="currentLoggedInUser && currentLoggedInUser!==null && searchConfig ">
    <div class="col-12" >
       <app-search #searchComponent [placeholder]="'Search Order# Customer Printers Suppliers PO#'" [searchConfig]="searchConfig" [existingList]="productionTrackerList" [sortColumns]="sortColumns"></app-search>
    </div>
</div>



<div *ngIf="currentLoggedInUser && currentLoggedInUser!==null && searchConfig">

    <table class="table table-condensed table-sm customtablehover">
        <thead class="thead-dark">
           <tr class="f-11">
              <!--<th style="width: 5%;" class=" align-middle text-center text-nowrap border">Actions</th>-->
              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Sales Order#</th>
              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Customer</th>
              <!--<th style="width: auto;" class=" align-middle text-center text-nowrap border">Cust PO#'s</th>-->
              <!--<th style="width: auto;" class=" align-middle text-center text-nowrap border">Sales Rep</th>-->
              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Prod. Coordinator</th>
              
              <!--<th style="width: auto;" class=" align-middle text-center text-nowrap border">Total Units</th>
              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Total Amount</th>-->
              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Printer</th>
              <!--<th style="width: auto;" class=" align-middle text-center text-nowrap border">Blanks Supplier(s)</th>-->
              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Ship Date</th>
              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Cancel Date</th>
              
              <!--<th style="width:auto;" class=" align-middle text-center text-nowrap border">Print Status</th>-->
              <!--<th style="width: 1%;" class=" align-middle text-center text-nowrap border">&nbsp;</th>-->
           </tr>

        </thead>
        <tbody>
            <ng-container  *ngFor="let productionTracker of productionTrackerList; index as productionTrackerIndex">
                <tr class="f-11">
                    <td style="width: auto;" class="align-middle text-left text-nowrap">
                        <div>
                            <span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">
                                <a  style="color: #333;" class="text-decoration-none" target="_blank" *ngIf="userAccess?.canViewPrinterPortalList" [routerLink]="['/printer-portal/view/'+productionTracker?.printerInfoList[0]?.printerId+'/'+productionTracker.salesOrderId]"  matTooltipPosition="below" matTooltipClass="bg-dark" matTooltip="View Sales Order">
                                    {{productionTracker?.salesOrderNumber}}
                                    <i class="fas fa-external-link-alt ml-1 showLinkIcon"></i>   
                                </a>
                                <ng-container *ngIf="!userAccess?.canViewPrinterPortalList">{{productionTracker?.salesOrderNumber}}</ng-container>
                            </span>
                        </div>
                    </td>
                    <td style="width: auto;" class="align-middle text-left text-nowrap">{{productionTracker?.customerName}}</td>
                    <td style="width: auto;" class="align-middle text-left text-nowrap">{{productionTracker?.coordinatorName}}</td>
                    <td style="width: auto;" class="align-middle text-left text-nowrap">{{productionTracker?.printerInfoList[0]?.printerName}}</td>
                    <!--<td style="width: auto;" class="align-middle text-left text-nowrap">{{productionTracker?.blanksSuppliers}}</td>-->
                    
                    <td style="width: auto;" class="align-middle text-left text-nowrap">{{productionTracker?.printerInfoList[0]?.printerRequestShipDate | date:'mediumDate'}}</td>
                    <td style="width: auto;" class="align-middle text-left text-nowrap">{{productionTracker?.printerInfoList[0]?.printerCancelDate | date:'mediumDate'}}</td>
                    
                    <!--<td style="width: auto;" class="align-middle text-left text-nowrap">
                        <div>
                            <div *ngIf="productionTracker.printInfoStatus!=='DETAILS_MISSING'"><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;"
                                [style.color]="productionTracker.printInfoStatus==='DELAYED'?'tomato':'green'">{{productionTracker.printerInfoList[0]?.printInfoStatus==='ON_TRACK'?'On Time':'Delayed'}}</span></div>
                                <div *ngIf="productionTracker.printInfoStatus==='DETAILS_MISSING'">
                                    <span class="form-control form-control-sm text-warning text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">
                                        Details Missing</span></div>
                        </div> 
                    </td>-->
                    
                </tr>
            </ng-container>

        </tbody>
    </table>

    <!--<ng-container  *ngFor="let productionTracker of productionTrackerList; index as productionTrackerIndex">
        <div class="row mb-2">
            <div class="col fit">
                <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Order #</label>
                <div>
                    <span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">
                        <a  style="color: #333;" class="text-decoration-none" target="_blank" *ngIf="userAccess?.canViewSalesOrder" [routerLink]="['/printer-portal/view/'+productionTracker.salesOrderId]"  matTooltipPosition="below" matTooltipClass="bg-dark" matTooltip="View Sales Order">
                            {{productionTracker?.salesOrderNumber}}
                            <i class="fas fa-external-link-alt ml-1 showLinkIcon"></i>   
                        </a>
                        <ng-container *ngIf="!userAccess?.canViewSalesOrder">{{productionTracker?.salesOrderNumber}}</ng-container>
                    </span>
                </div>
            </div>   
        
            <div class="col fit">
                <label class="mb-0 ml-1" class="text-nowrap mb-0" style="font-size: .60rem; color: lightslategray;">Printer Ship Date</label>
                <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.printerInfoList[0]?.printerRequestShipDate | date:'mediumDate'}}</span></div>
            </div>
            <div class="col fit">
                <label class="mb-0 ml-1" class="text-nowrap mb-0" style="font-size: .60rem; color: lightslategray;">Printer Cancel Date</label>
                <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.printerInfoList[0]?.printerCancelDate | date:'mediumDate'}}</span></div>
            </div>
           
            <div class="col fit">
                <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Styles</label>
                <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.styleCount}}</span></div>
            </div> 
            <div class="col fit">
                <label class="mb-0 ml-1" class="text-nowrap mb-0" style="font-size: .60rem; color: lightslategray;">Print Status</label>
                <div *ngIf="productionTracker.printInfoStatus!=='DETAILS_MISSING'"><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;"
                    [style.color]="productionTracker.printInfoStatus==='DELAYED'?'tomato':'green'">{{productionTracker.printerInfoList[0]?.printInfoStatus==='ON_TRACK'?'On Time':'Delayed'}}</span></div>
                    <div *ngIf="productionTracker.printInfoStatus==='DETAILS_MISSING'">
                        <span class="form-control form-control-sm text-warning text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">
                            Details Missing</span></div>
            </div> 
            
            <div class="col fit" >
                <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer</label>
                <div><span class="form-control form-control-sm" class="text-break" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.customerName}}</span></div>
            </div>
        
            <div class="col fit" >
                <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Coordinator</label>
                <div><span class="form-control form-control-sm" class="text-break" style="background: transparent; font-size: 12px; border-width: 0px;">{{productionTracker?.coordinatorName}}</span></div>
            </div>
            <div class="col fit" >
                <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Sales Rep</label>
                <div><span class="form-control form-control-sm" class="text-break" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.salesRepName}}</span></div>
            </div>

            <div class="col-auto" >
                <label class="mb-0 ml-1" class="text-nowrap mb-0" style="font-size: .60rem; color: lightslategray;">Blank Supplier(s)</label>
                <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.blanksSuppliers}}</span></div>
            </div>
            <div class="col-auto" >
                <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Printer(s)</label>
                <div><span class="form-control form-control-sm" class="text-break" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.printerInfoList[0]?.printerName}}</span></div>
            </div>
         
            
         </div>
         <hr>
    </ng-container>-->
</div>