import { ProductAttachment } from './product.model';

export class PdfForm {
    id:string;
	name:string;
	licensorId:string;
	brandId:string;
	productId:string;
	category:string;
	subCategory:string;
	attachmentId:string;
	licensorName:string;
	brandName:string;
	sku:string;
	attachments:ProductAttachment[]=[];
	variables:PdfFormVariables[]=[];
	formFlattening:boolean;
	fileType:string;
}

export class PdfFormVariables {
    id:string;
	pdfFormId:string;
	varName:string;
	varNameAlias:string;
	varType:string;
	varSubType: string;
	varValueSource:string;
	multiValueDelimeter:string;
	varValueImageArrIndex:number;
	fillable:boolean;
	display:boolean;
	//blanksAllowed:string;
	extendedMargin:string;
	sortOrder:number;
	objectIndex:number;
}