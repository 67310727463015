<div class="container">
    <div class="d-flex justify-content-center align-items-center mt-3" *ngIf="printerInfo">
        <div class="text-color-blue"><h3>{{printerInfo.printerName}}&nbsp;</h3></div>
    </div>
    <div  class="separator-blue"></div><!--{{printerInfo.printInfoStatus==='ON_TRACK'?'On Time':'Delayed'}}-->
    <!--<div class="mt-3">
        <h6>Order Details</h6>
        <hr>
    </div>-->
    <div class=" container-fluid  p-1">
        <div class="">
      <div class="card card-body p-2" [style.background]="'rgba(248, 220, 129, 0.25)'" >
      
                <div class="row">
                    <div class="col ">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Order #</label>
                        <div>
                            <span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">
                                {{productionTracker?.salesOrderNumber}}
                            </span>
                        </div>
                    </div>   
                
                    <!--<div class="col fit">
                        <label class="mb-0 ml-1" class="text-nowrap mb-0" style="font-size: .60rem; color: lightslategray;">Order Ship Date</label>
                        <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.customerShipDate | date:'mediumDate'}}</span></div>
                    </div>
                    <div class="col fit">
                        <label class="mb-0 ml-1" class="text-nowrap mb-0" style="font-size: .60rem; color: lightslategray;">Order Cancel Date</label>
                        <div><span class="form-control form-control-sm" class="text-nowrap" style=" background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.customerCancelDate | date:'mediumDate'}}</span></div>
                    </div> -->  
                    <div class="col ">
                        <label class="mb-0 ml-1" class="text-nowrap mb-0" style="font-size: .60rem; color: lightslategray;">Ship Date</label>
                        <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.printerRequestShipDate | date:'mediumDate'}}</span></div>
                    </div>
                    <div class="col ">
                        <label class="mb-0 ml-1" class="text-nowrap mb-0" style="font-size: .60rem; color: lightslategray;">Cancel Date</label>
                        <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.printerCancelDate | date:'mediumDate'}}</span></div>
                    </div>
                    <!--<div class="col fit">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Fullfillment Status</label>
                        <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.salesOrderNumber}}</span></div>
                    </div>-->
                    
                    <!--<div class="col fit">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Cushion</label>
                        <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.cushion}} Days</span></div>
                    </div>-->
                    <!--<div class="col fit">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Week</label>
                        <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.week}}</span></div>
                    </div>
                    <div class="col fit">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Styles</label>
                        <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.styleCount}}</span></div>
                    </div>--> 
                    <!--<div class="col fit">
                        <label class="mb-0 ml-1" class="text-nowrap mb-0" style="font-size: .60rem; color: lightslategray;">Print Status</label>
                        <div *ngIf="productionTracker.printInfoStatus!=='DETAILS_MISSING'"><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;"
                            [style.color]="productionTracker.printInfoStatus==='DELAYED'?'tomato':'green'">{{productionTracker.printInfoStatus==='DELAYED'?'Delayed':'On Time'}}</span></div>
                            <div *ngIf="productionTracker.printInfoStatus==='DETAILS_MISSING'">
                                <span class="form-control form-control-sm text-warning text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">
                                    Details Missing</span></div>
                    </div> -->
                    
                    <div class="col " >
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer</label>
                        <div><span class="form-control form-control-sm" class="text-break" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.customerName}}</span></div>
                    </div>
                
                    <!--<div class="col fit" >
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer PO(s)</label>
                        <div><span class="form-control form-control-sm" class="text-break" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{ (productionTracker?.customerPO?.length>15)? (productionTracker?.customerPO | slice:0:15)+'..':(productionTracker?.customerPO) }}</span></div>
                    </div>-->
                    
                    <div class="col " >
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Coordinator</label>
                        <div><span class="form-control form-control-sm" class="text-break" style="background: transparent; font-size: 12px; border-width: 0px;">{{productionTracker?.coordinatorName}}</span></div>
                    </div>
                    <!--<div class="col fit" >
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Sales Rep</label>
                        <div><span class="form-control form-control-sm" class="text-break" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.salesRepName}}</span></div>
                    </div>-->

                    <!--<div class="col-auto" >
                        <label class="mb-0 ml-1" class="text-nowrap mb-0" style="font-size: .60rem; color: lightslategray;">Blank Supplier(s)</label>
                        <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.blanksSuppliers?.includes(',')?'Multi':productionTracker?.blanksSuppliers}}</span></div>
                    </di-->
                    <!--<div class="col-auto" >
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Printer(s)</label>
                        <div><span class="form-control form-control-sm" class="text-break" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.printers?.includes(',')?'Multi':productionTracker?.printers}}</span></div>
                    </div>-->
                    <!--<div class="col fit">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Fullfillment Status</label>
                        <div><span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">{{productionTracker?.salesOrderNumber}}</span></div>
                    </div>-->

                    
                </div>
        </div>
        <!--<div class=" d-flex pt-1 f-12">
            <div class="d-flex flex-wrap flex-row" *ngIf="productionTracker.assignedStyleCount!==productionTracker.styleCount">
                <div class="ml-2 text-nowrap">
                    <span style="font-weight: 600;">Style Assignment Pending : {{productionTracker.assignedStyleCount?productionTracker.assignedStyleCount:0}} / {{productionTracker.styleCount}}</span> 
                </div>
            </div>    
            <div class="d-flex flex-wrap flex-row" *ngIf="productionTracker.assignedStyleCount===productionTracker.styleCount">
                <div  *ngIf="productionTracker.blanksInfoStatus" class=" mt-1 ml-2 badge badge-pill d-flex justify-content-center align-items-center" 
                [class]="productionTracker.blanksInfoStatus==='DELIVERED'?'badge-success':'badge-danger'">
                    <span *ngIf="productionTracker.blanksInfoStatus==='DELIVERED'" class="pr-1"><i class="fas fa-check-circle"></i></span>
                    <span *ngIf="productionTracker.blanksInfoStatus!=='DELIVERED'" class="pr-1"><i class="fas fa-times-circle"></i></span>
                    Blanks
                </div>
                <div  *ngIf="productionTracker.artInfoStatus" class=" mt-1 ml-2 badge badge-pill d-flex justify-content-center align-items-center" 
                [class]="productionTracker.artInfoStatus==='SENT'?'badge-success':'badge-danger'">
                    <span *ngIf="productionTracker.artInfoStatus==='SENT'" class="pr-1"><i class="fas fa-check-circle "></i></span>
                    <span *ngIf="productionTracker.artInfoStatus!=='SENT'" class="pr-1"><i class="fas fa-times-circle "></i></span>
                    Art
                </div>
            <div  *ngIf="productionTracker.ppSampleInfoStatus" class=" mt-1 ml-2 badge badge-pill d-flex justify-content-center align-items-center" 
            [class]="productionTracker.ppSampleInfoStatus==='APPROVED'?'badge-success':'badge-danger'">
                    <span *ngIf="productionTracker.ppSampleInfoStatus==='APPROVED'" class="pr-1"><i class="fas fa-check-circle "></i></span>
                    <span *ngIf="productionTracker.ppSampleInfoStatus!=='APPROVED'" class="pr-1"><i class="fas fa-times-circle "></i></span>
                    PP Samples
                </div>
                <div  *ngIf="productionTracker.topSampleInfoStatus" class="mt-1 ml-2 badge badge-pill d-flex justify-content-center align-items-center" 
                [class]="productionTracker.topSampleInfoStatus==='APPROVED'?'badge-success':'badge-danger'">
                    <span *ngIf="productionTracker.topSampleInfoStatus==='APPROVED'" class="pr-1"><i class="fas fa-check-circle "></i></span>
                    <span *ngIf="productionTracker.topSampleInfoStatus!=='APPROVED'" class="pr-1"><i class="fas fa-times-circle "></i></span>
                    Top Samples
                </div>
                <div  *ngIf="productionTracker.polyBagsInfoStatus" class=" mt-1 ml-2 badge badge-pill d-flex justify-content-center align-items-center" 
                [class]="productionTracker.polyBagsInfoStatus==='DELIVERED'?'badge-success':'badge-danger'">
                    <span *ngIf="productionTracker.polyBagsInfoStatus==='DELIVERED'" class="pr-1"><i class="fas fa-check-circle "></i></span>
                    <span *ngIf="productionTracker.polyBagsInfoStatus!=='DELIVERED'" class="pr-1"><i class="fas fa-times-circle "></i></span>
                    Polybags
                </div>
                <div  *ngIf="productionTracker.boxLabelsInfoStatus" class="mt-1 ml-2 badge badge-pill d-flex justify-content-center align-items-center" 
                [class]="productionTracker.boxLabelsInfoStatus==='DELIVERED'?'badge-success':'badge-danger'">
                    <span *ngIf="productionTracker.boxLabelsInfoStatus==='DELIVERED'" class="pr-1"><i class="fas fa-check-circle "></i></span>
                    <span *ngIf="productionTracker.boxLabelsInfoStatus!=='DELIVERED'" class="pr-1"><i class="fas fa-times-circle "></i></span>
                    Box Labels
                </div>
                <div  *ngIf="productionTracker.trimInfoStatus" class="mt-1 ml-2 badge badge-pill d-flex justify-content-center align-items-center" 
                [class]="productionTracker.trimInfoStatus==='DELIVERED'?'badge-success':'badge-danger'">
                    <span *ngIf="productionTracker.trimInfoStatus==='DELIVERED'" class="pr-1"><i class="fas fa-check-circle "></i></span>
                    <span *ngIf="productionTracker.trimInfoStatus!=='DELIVERED'" class="pr-1"><i class="fas fa-times-circle "></i></span>
                    Trims
                </div>
                <div  *ngIf="productionTracker.finishingSheetInfoStatus" class="mt-1 ml-2 badge badge-pill d-flex justify-content-center align-items-center" 
                [class]="productionTracker.finishingSheetInfoStatus==='SENT'?'badge-success':'badge-danger'">
                    <span *ngIf="productionTracker.finishingSheetInfoStatus==='SENT'" class="pr-1"><i class="fas fa-check-circle "></i></span>
                    <span *ngIf="productionTracker.finishingSheetInfoStatus!=='SENT'" class="pr-1"><i class="fas fa-times-circle "></i></span>
                    Finishing Sheets
                </div>
                
            </div>
            <div class="ml-auto">
                <div class="ml-2 text-nowrap">
                    <span style="font-weight: 600;">Production Status: </span> <em>{{'sales.productionStatus.'+productionTracker?.productionStatus | transform}}</em>
                </div>
            </div>
        </div>   -->
        
    </div>
    <div class="mt-3">
        <h6>Printing Assignment</h6>
        <hr>
    </div>
    <div class="mt-2">
        <div *ngFor="let orderDetailInfo of orderDetailInfoList">
        <ng-container *ngIf="orderDetailInfo.orderDetailStatus!=='CANCELLED' && !orderDetailInfo.obsolete">
            <app-style-details [orderDetailInfo]="orderDetailInfo" [salesOrder]="salesOrder"></app-style-details>
        </ng-container>
    </div>
    </div>
</div>