import { Injectable } from '@angular/core';
import { ProductService } from './product.service';
import { ProductAttachment } from '../model/product.model';
import { ToastrService } from 'ngx-toastr';
import { FileDeleteCriteria } from '../model/file-delete-criteria.model';
import { ServiceStatus } from '../model/service-status.model';
import { map, catchError } from 'rxjs/operators';
import { HttpEventType, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LoaderService } from './loader.service';
import { environment } from 'src/environments/environment';
import { SharedService } from './shared.service';
import { SalesOrder } from '../model/sales/sales-order.model';

@Injectable({
   providedIn: 'root'
})
export class FileHandlingService {

   readonly rooturl = environment.apiEndPoint;
   constructor(private http: HttpClient, private loaderService: LoaderService,private sharedService: SharedService,  private toastrService: ToastrService) {
   }

   loadChildAttachmentsForSales(salesOrder: SalesOrder,category: string,objAttributeName:string) {
      let attachment=this.getSalesTempAttachmentWithParentId(salesOrder,category);
      this.loadAttachmentsUrls(salesOrder, attachment, objAttributeName);
   }

   private getSalesTempAttachmentWithParentId(salesOrder: SalesOrder,category:string) {
      let attachment: ProductAttachment = new ProductAttachment;
      attachment.parentId = salesOrder.id;
      attachment.fileCategory = category;
      return attachment;
   }


   loadAttachmentsUrls(object: any, attachment: ProductAttachment, objAttributeName: string) {
      this.loadAttachments(attachment).subscribe((res) => {
         if(res && res!==null){
            this.sharedService.loadMimeTypes(res);
         }
         switch (attachment.fileCategory) {
            case 'LICENSOR_FILES': object[objAttributeName] = res as ProductAttachment[]; break;
            case 'LICENSOR_CONTRACT_FILES': object[objAttributeName] = res as ProductAttachment[]; break;
            case 'LICENSOR_ARTIST_SIGN_OFF_FILES': object[objAttributeName] = res as ProductAttachment[]; break;
            case 'LICENSOR_FACTORY_FORMS_FILES': object[objAttributeName] = res as ProductAttachment[]; break;
            case 'BRAND_FILES': object[objAttributeName] = res as ProductAttachment[]; break;
            case 'BRAND_TREND_FILES': object[objAttributeName] = res as ProductAttachment[]; break;
            case 'SALES_CUSTOMER_PO_FILES': object[objAttributeName] = res as ProductAttachment[]; break;
            default: object[objAttributeName] = res as ProductAttachment[]; break;
         }
      },
         (err) => {
            console.error(err);
         });
   }

   loadAttachments(attachment: ProductAttachment):Observable<ProductAttachment[]> {
      return this.http.post<ProductAttachment[]>(this.rooturl + "/fileHandler/loadAttachments", attachment);
   }

   loadAttachmentById(attachment: ProductAttachment) {
      return this.http.post(this.rooturl + "/fileHandler/loadAttachment", attachment);
   }

   public populateLocalFileinList(attachmentList: ProductAttachment[]): File[] {
      let list: File[] = [];
      if (attachmentList !== undefined && attachmentList.length > 0) {
         attachmentList.forEach(element => {
            if (element.islocal && !element.toDelete) {
               list.push(element.localfile);
               element.localfile = undefined;
               element.fileSignedUrl = undefined;
               element.thumbnailSignedUrl = undefined;
            }
         });

      }
      return list;
   }

   uploadLocalFileToRepoNonBlocking(files: File[], object: any, varName: string, attachment: ProductAttachment, objAttributeName: string) {
      
      if (files.length > 0) {
         for (let index = 0; index < files.length; index++) {
            const element = files[index];
            this.uploadSelectedFileNonBlocking(object, varName, element, attachment, objAttributeName);
         }
      }
   }

   uploadLocalFileToRepoNonBlockingGroupedAttachments(attachmentList: ProductAttachment[], object: any, varName: string, uploadDetialsOrig: ProductAttachment, objAttributeName: string) {
      if(attachmentList && attachmentList.length>0){
         attachmentList.forEach(attachmentTemp => {
            let dummyList:ProductAttachment[]=[];
            dummyList.push(attachmentTemp);
            let files = this.populateLocalFileinList(dummyList);
            console.log('uploadLocalFileToRepoNonBlockingGroupedAttachments - Extracted FIle - ', files);
            if (files.length > 0) {
               for (let index = 0; index < files.length; index++) {
                  const element = files[index];
                  let uploadDetialsTemp=new ProductAttachment();
                  let uploadDetials=Object.assign(uploadDetialsTemp,uploadDetialsOrig);
                  uploadDetials.groupReferenceId=attachmentTemp.groupReferenceId;
                  this.uploadSelectedFileNonBlocking(object, varName, element, uploadDetials, objAttributeName);
               }
            }
         });
      }
   }

   private uploadSelectedFileNonBlocking(object: any, varName: string, selectedFileToUpload: File, attachment: ProductAttachment, objAttributeName: string) {
      let fileName = selectedFileToUpload.name;
      this.toastrService.info('Uploading ' + fileName);
      this.uploadFileWithProgress(object, varName, selectedFileToUpload, attachment,true).subscribe(
         (res) => {
            if (res === 'UPLOAD_SUCCESS') {
               this.toastrService.success(fileName + ' Uploaded Successfully');
               this.loadAttachmentsUrls(object, attachment, objAttributeName);
            } else if (res === 'UPLOAD_FAILED') {
               this.toastrService.error(fileName + ' - File upload failed');
            }
         }, (err) => {
            console.error('Error while upload' + err);
            this.toastrService.error(selectedFileToUpload.name + ' Upload Failed:' + JSON.stringify(err));
            // this.resetUploadFlags();
         }
      );
   }
   async uploadLocalFileToRepo(files: File[], category: string, object: any, varName: string, attachment: ProductAttachment) {
      if (files.length > 0) {
         for (let index = 0; index < files.length; index++) {
            const element = files[index];
            await this.uploadSelectedFile(object, varName, category, element, attachment);
         }
      }
   }

   async uploadSelectedFile(object: any, varName: string, filecategory: string, selectedFileToUpload: File, attachment: ProductAttachment) {
      //this.fileUploading = true;
      //this.SelectedFileToUpload=this.getSelectedFileToUpload(filecategory);
      let fileName = selectedFileToUpload.name;
      this.toastrService.info('Uploading ' + fileName);
      await this.uploadFileWithProgress(object, varName, selectedFileToUpload, attachment,false).toPromise().then(
         () => {
            this.toastrService.success(fileName + ' Uploaded Successfully');
         }, (err) => {
            console.error('Error while upload' + err);
            this.toastrService.error(selectedFileToUpload.name + ' Upload Failed:' + JSON.stringify(err));
            //this.uploadError = true;
            //this.resetUploadFlags();
         }
      );
   }
   async deleteMarkedFile(list: ProductAttachment[], createAction: boolean) {
      if (list !== undefined && list.length > 0) {
         for (const element of list) {
            if (element.toDelete && !element.islocal) {
               await this.deleteFilesFromRepo(element.id, element.fileCategory, createAction);
            }
         }
      }
   }

   async deleteFilesFromRepo(id: string, category: string, createAction: boolean) {
      if (!createAction /* && confirm("Do you really want to delete the file?")*/) {
         const obj: FileDeleteCriteria = new FileDeleteCriteria;
         obj.id = id;
         await this.deleteFile(obj).toPromise().then((res) => {
            let status: ServiceStatus = res as ServiceStatus;
            if (status.status == 'SUCCESS') {
               this.toastrService.success(status.message);
               //this.refreshLocalListOfUrlsForCategory(category);
            }
            else {
               this.toastrService.error(status.message);
            }

         }, (err) => {
            this.toastrService.error('File Deletion Failed : ' + JSON.stringify(err));
         });
      }
   }


   deleteLocalFile(id: string, list: ProductAttachment[]): ProductAttachment[] {
      return list.filter(element => element.id != id);
   }

   deleteFile(criteria: FileDeleteCriteria) {
      return this.http.post(this.rooturl + "/fileHandler/file/delete", criteria);
   }

   uploadFileWithProgress(object: any, varName: string, file: File, attachment: ProductAttachment,nonBlocking:boolean) {
      let id = Math.random().toString(36).substr(2, 9);
      
      const fd = new FormData();
      fd.append('file', file, file.name);
      fd.append('attachmentJson', JSON.stringify(attachment));
      let partailUrl="/fileHandler/file/upload";
      if(nonBlocking){
         partailUrl="/fileHandler/NonBlocking/file/upload";
      }
      return this.http.post(this.rooturl + partailUrl, fd, {
         reportProgress: true,
         observe: 'events'
      }).pipe(map((event) => {

         switch (event.type) {
            case HttpEventType.Sent:
               this.loaderService.showProgressBar(object[varName], file.name, id);
               break;
            case HttpEventType.UploadProgress:
               const progress = Math.round(100 * event.loaded / event.total);
               //
               this.loaderService.updateProgress(object[varName], file.name, id, progress);
               break;
            case HttpEventType.Response:
               //
               //
               let serviceStatus = event.body as ServiceStatus;
               if (serviceStatus && serviceStatus.status === 'SUCCESS') {
                  this.loaderService.hideProgressBar(object[varName], file.name, id);
                  return 'UPLOAD_SUCCESS';
               } else {
                  this.loaderService.hideProgressBar(object[varName], file.name, id);
                  return 'UPLOAD_FAILED';
               }
            default:
               //  
               // return `Unhandled event: ${event.type}`;
               break;
         }
      })
      ).pipe(
         catchError(this.errorMgmt)
      );
   }

   errorMgmt(error: HttpErrorResponse) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
      } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.error('Error in file upload:', errorMessage);
      return throwError(errorMessage);
   }


   renameAttachment(attachment:ProductAttachment){
      return this.http.post(this.rooturl + "/fileHandler/file/rename",attachment);
   }


}


