<div class="d-flex justify-content-between">
    <div>&nbsp;</div>
    <h6 mat-dialog-title>{{data.attachment.originalFileName}}</h6>
    <span name="closeBtn" type="button"   [mat-dialog-close]="false">
        <i class="fas fa-times"></i>
    </span>
</div>
<div *ngIf="data.attachment && data.attachment.originalFileName.toUpperCase().endsWith('.PDF')" >
   
      <hr>
      <div  *ngIf="data.attachment" ><!--mat-dialog-content-->
        <pdf-viewer [src]="data.attachment.fileSignedUrl"   [original-size]="false"></pdf-viewer>
      </div>
</div>

<div *ngIf="data.attachment && !data.attachment.originalFileName.toUpperCase().endsWith('.PDF')">
    <div  class="row d-flex justify-content-center align-items-center">
        <div class="card" style="width:95%;height:500px;">
               <div class="card-body d-flex justify-content-center align-items-center" >
                <img [src]="data.attachment.fileSignedUrl"
                        class="img-fluid img-auto-size-500" alt="Error"><!-- style="z-index: 1;"-->
                </div> 
                

        </div>
    </div>        
</div>
