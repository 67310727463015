export class PrinterSizeDetails {
   size: string;
   cost: number;
   qty: number;
}

export class PrinterSizeDetailsForSummary {

   size: string;
   cost: number;
   qty: number;
   extraQty: number;
   jobType: string;
   fullPackage: boolean;
   sku: string;
   sample: boolean;
   sampleDesc: string;
   topSample:number;
	ppSample:number;
	licensorSample:number;
   printerBlanksDistributionSizeDetailsList:PrinterBlanksDistributionSizeDetails[]=[];
}

export class PrinterBlanksDistributionSizeDetails {
   size:string;
	cost:number;
	qty:number;
	overrideSize:string;
	blankCode:string;
   supplierId:string;;
	supplierName:string;;
	coo:string;;
}

export class PrinterBlanksDistributionGrouped {
   sku:string;
   distributionCount:number=0;
   printerSizeDetails:PrinterSizeDetailsForSummary[]=[];
}



