export class MetadataGarment {
   constructor(status: boolean, sortOrder: number) {
      this.status = status;
      this.sortOrder = sortOrder;
   }
   id: string;
   name: string;
   shortName: string;
   status: boolean;
   sortOrder: number
}
