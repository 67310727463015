import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/shared/service/dashboard.service';

@Component({
  selector: 'app-sales-dashboard',
  templateUrl: './sales-dashboard.component.html',
  styleUrls: ['./sales-dashboard.component.css']
})
export class SalesDashboardComponent implements OnInit {

  constructor(private dashboardService:DashboardService) { }
  chartFromService:any[]=[];
  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.dashboardService.getSalesDashBoardDetails().subscribe(res=>{
      let resArr=res as any[];
      resArr.forEach(chartRef => {
        let chart=JSON.parse(chartRef.chart);
        chart.data=chartRef.data;
        this.chartFromService.push(chart);
      });
    });
   
  }

  onReady(event){
    
  }
  onSelect(data,event) {
    
    console.log('Sales Dashboard event ->',event);
    const { row, column } = event.selection[0];
    const montAndyear = data[row][0];
    const sales=data[row][column];
    console.log('selection',montAndyear,sales);
    /*let selectedItem;
    if (column === 1) {
         selectedItem = "current";
    }
    if (column === 2) {
         selectedItem = "target";
    }*/
    
}

}
