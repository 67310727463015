import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { SearchComponent } from 'src/app/common/search/search.component';
import { SearchDetails } from 'src/app/shared/model/common/search-details.model';
import { FilterConfig } from 'src/app/shared/model/common/search-filter.model';
import { ComposeEmail } from 'src/app/shared/model/compose-email.model';
import { EmailTemplateConfig } from 'src/app/shared/model/email-template-config.model';
import { ProductAttachment } from 'src/app/shared/model/product.model';
import { SalesArtTrackerView } from 'src/app/shared/model/sales/art/sales-art-tracker-view.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { Printer } from 'src/app/shared/model/vendors/printer.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { ComposeEmailService } from 'src/app/shared/service/compose-email.service';
import { SalesArtTrackerService } from 'src/app/shared/service/sales/art/sales-art-tracker.service';
import { SearchService } from 'src/app/shared/service/search.service';
import { VendorsService } from 'src/app/shared/service/vendors/vendors.service';

@Component({
  selector: 'app-art-tracker-list',
  templateUrl: './art-tracker-list.component.html',
  styleUrls: ['./art-tracker-list.component.css']
})
export class ArtTrackerListComponent implements OnInit {

  constructor(private authService: AuthService,private searchService:SearchService,
    private salesArtTrackerService:SalesArtTrackerService,
    private toastrService:ToastrService,
    private composeEmailService: ComposeEmailService,
    private vendorService:VendorsService) { }
  userAccessSubscription: Subscription;
  userAccess: UserAccess;

  currentLoggedInUserSubscription: Subscription;
  currentLoggedInUser: User;

  printerList:Printer[]=[];
  searchConfig: SearchDetails;
  sortColumns = [
    { columnName: 'SALES_ORDER_NUMBER', sortByName: 'Sales Order #' },
    //{ columnName: 'APPROVAL_STATUS', sortByName: 'Approval Status' },
    { columnName: 'ORDER_SHIP_DATE', sortByName: 'Ship Date' },
    { columnName: 'PRINTER_NAME', sortByName: 'Printer Name' },
    
  ];
  @ViewChild('searchComponent') searchComponent: SearchComponent<any>;
  showEmailEditor:boolean=false;
  composeEmail: ComposeEmail = new ComposeEmail;
  salesArtTrackerViewList:SalesArtTrackerView[]=[];
  ngOnInit(): void {
    this.handleSubscriptions();
    if (!this.searchConfig) {
      this.searchConfig = this.searchService.salesArtTrackerViewListSearchDetails;
      this.setFilterConfigs();
   }
    /*this.salesArtTrackerViewList=

    [
      {
        "salesOrderId" : "4028b881815b023801815ed54fe30052",
        "salesOrderNumber" : "202206000005",
        "customerName" : "Mark's",
        "coordinatorName" : "Sales1 Philcos",
        "printerId" : "20005",
        "printerName" : "Fusebox",
        "orderShipDate" : "2022-06-07 00:00:00"
      },
      {
        "salesOrderId" : "4028b881815b023801815ed54fe30052",
        "salesOrderNumber" : "202206000005",
        "customerName" : "Mark's",
        "coordinatorName" : "Sales1 Philcos",
        "printerId" : "20001",
        "printerName" : "HPO",
        "orderShipDate" : "2022-06-07 00:00:00"
      },
      {
        "salesOrderId" : "4028b8818382b8cd018382c013800006",
        "salesOrderNumber" : "202209000021",
        "customerName" : "Mark's",
        "coordinatorName" : "Sales1 Philcos",
        "printerId" : "20003",
        "printerName" : "JJ Printing",
        "orderShipDate" : "2022-09-29 00:00:00"
      },
      {
        "salesOrderId" : "4028b8818229829a018229aa0d480308",
        "salesOrderNumber" : "202211000022",
        "customerName" : "Mark's",
        "coordinatorName" : "Sales1 Philcos",
        "printerId" : "20002",
        "printerName" : "Factory 1",
        "orderShipDate" : "2022-06-29 00:00:00"
      },
      {
        "salesOrderId" : "4028b88184a83ff00184aab4cadd0048",
        "salesOrderNumber" : "202211000024",
        "customerName" : "Walmart",
        "coordinatorName" : "Production User",
        "printerId" : "20002",
        "printerName" : "Factory 1",
        "orderShipDate" : "2022-11-24 00:00:00"
      },
      {
        "salesOrderId" : "4028b88184c931630184cf0cb9a60035",
        "salesOrderNumber" : "202212000025",
        "customerName" : "Walmart",
        "coordinatorName" : "Production User",
        "printerId" : "20002",
        "printerName" : "Factory 1",
        "orderShipDate" : "2022-12-25 00:00:00"
      },
      {
        "salesOrderId" : "4028b8818598007201859870c71c0056",
        "salesOrderNumber" : "202301000001",
        "customerName" : "Mark's",
        "coordinatorName" : "Sales1 Philcos",
        "printerId" : "20002",
        "printerName" : "Factory 1",
        "orderShipDate" : "2022-08-12 00:00:00"
      },
      {
        "salesOrderId" : "4028b88185a7e02d0185a7e3c02a0003",
        "salesOrderNumber" : "202301000002",
        "customerName" : "Mark's",
        "coordinatorName" : "Sales1 Philcos",
        "printerId" : "20005",
        "printerName" : "Fusebox",
        "orderShipDate" : "2022-08-12 00:00:00"
      },
      {
        "salesOrderId" : "4028b88181c76b330181c76b90830003",
        "salesOrderNumber" : "202207000014",
        "customerName" : "Mark's",
        "coordinatorName" : "Sales1 Philcos",
        "printerId" : "20005",
        "printerName" : "Fusebox",
        "orderShipDate" : "2022-06-30 00:00:00"
      },
      {
        "salesOrderId" : "4028b88181c76b330181c76b90830003",
        "salesOrderNumber" : "202207000014",
        "customerName" : "Mark's",
        "coordinatorName" : "Sales1 Philcos",
        "printerId" : "20001",
        "printerName" : "HPO",
        "orderShipDate" : "2022-06-30 00:00:00"
      },
      {
        "salesOrderId" : "4028b88185acbdb70185acbf48710000",
        "salesOrderNumber" : "202301000004",
        "customerName" : "Mark's",
        "coordinatorName" : "Sales1 Philcos",
        "printerId" : "20002",
        "printerName" : "Factory 1",
        "orderShipDate" : "2022-08-12 00:00:00"
      }
    ]*/
  }

  handleSubscriptions() {
    this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
      this.userAccess = access;
    });
    this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
      this.currentLoggedInUser = currentLoggedInUser;
    });
    this.vendorService.printerListObservable.subscribe(res => {
      if(res && res!=null && res.length>0){
        this.printerList.length=0;
        this.printerList.push(...res);
      }
      //console.log("PTBI PrinterList",this.printerList);
   });
  }

  setFilterConfigs() {
    if (!this.searchConfig.filterConfigs) {
      this.searchConfig.filterConfigs = [];
      //Printer List
        let printerConfig:FilterConfig={
          filterType:'IN_CHECKLIST',
          label:'Printer',
          currentSelection:'ALL',
          options: this.printerList,
          optionDislpayVar:'name',
          optionValueVar:'name',
          fieldName:'printer_name'
        };
        this.searchConfig.filterConfigs.push(printerConfig);
      //Date Range
       
       let approvateDateFilterConfig: FilterConfig = {
          filterType: 'DATE_RANGE',
          label: 'Ship Date',
          currentSelection: undefined,
          fieldName: 'ORDER_SHIP_DATE'
       };
       this.searchConfig.filterConfigs.push(approvateDateFilterConfig);
    }
 }
 
  showHideArtTrackerViewDetails(atv:SalesArtTrackerView){
    if(!atv.expanded){
      this.salesArtTrackerService.getSalesOrderArtTrackerDetails(atv).subscribe(res=>{
        if (res.responseStatus.status === 'SUCCESS') {
          atv.salesArtTrackers=res.responsePayload;
          atv.expanded=true;
        }else{
          this.toastrService.error('Operation failed while retrieving art details for the Sales Order');
        }
      },err=>{
        this.toastrService.error('Error while retrieving art details for the Sales Order');
      });
      
      
    }else{
      atv.expanded=false;
    }
  }

  handleThumbnailError(productAttachment: ProductAttachment, event) {
    if(productAttachment){
    if (isNaN(productAttachment?.thumbnailErrorCount)) {
      productAttachment.thumbnailErrorCount = 0;
    }
    event.target.src = productAttachment.fileSignedUrl;
    var x: number = productAttachment.thumbnailErrorCount;
    productAttachment.thumbnailErrorCount = x + 1;
    if (productAttachment.thumbnailErrorCount < 2) {
      event.target.src = productAttachment.fileSignedUrl;
      productAttachment.thumbnailSignedUrl = productAttachment.fileSignedUrl;
    } else {
      event.target.src = "/assets/default-image.jpg"
    }
  }
}
editArtTrackerView(artTrackerView:SalesArtTrackerView){
  if(!artTrackerView.expanded){
    this.showHideArtTrackerViewDetails(artTrackerView);
  }
  artTrackerView.editable=true;
}
saveArtTrackerView(artTrackerView:SalesArtTrackerView){
  this.salesArtTrackerService.saveSalesOrderArtTrackerDetails(artTrackerView).subscribe(res=>{
    if (res.responseStatus.status === 'SUCCESS') {
      artTrackerView.expanded=false;
      artTrackerView.editable=false;
      this.showHideArtTrackerViewDetails(artTrackerView);
    }else{
      this.toastrService.error('Operation failed while saving art details for the Sales Order');
    }
  },err=>{
    this.toastrService.error('Error while saving art details for the Sales Order');
  });
}

relaodArtTrackerView(artTrackerView:SalesArtTrackerView){
  artTrackerView.expanded=false;
  artTrackerView.editable=false;
  this.showHideArtTrackerViewDetails(artTrackerView);
}



sendProductEmail(atv:SalesArtTrackerView){
  //this.createCatalogue();
   this.showEmailEditor=true;
   let emailConfig: EmailTemplateConfig = new EmailTemplateConfig;
   let productIds=atv.salesArtTrackers.map(atvd => atvd.productId);
   emailConfig.productIdList=productIds;
   emailConfig.templateName='productDetailsWithVisualForArtTracker';
   emailConfig.salesOrderNumber=atv.salesOrderNumber;
   emailConfig.salesOrderId=atv.salesOrderId;
   this.composeEmail=new ComposeEmail;
   this.getEmailTemplate(emailConfig);
}

getEmailTemplate(emailConfig:EmailTemplateConfig){
  this.composeEmailService.getEmailTemplate(emailConfig).subscribe(response => {
     let composeEmailTemp:ComposeEmail = response.responsePayload;
     this.composeEmail.emailContent=composeEmailTemp.emailContent;
     this.composeEmail.emailTo=composeEmailTemp.emailTo;
     this.composeEmail.emailCc=composeEmailTemp.emailCc;
     this.composeEmail.emailBcc=composeEmailTemp.emailBcc;
     this.composeEmail.emailSubject=composeEmailTemp.emailSubject;
     document.getElementById('composeEmailToggler').click();
  });
}

expandList(expand:boolean){
  if(this.salesArtTrackerViewList && this.salesArtTrackerViewList.length>0){
    this.salesArtTrackerViewList.forEach(satv => {
      satv.expanded=expand;
    });
    
  }
}

}
