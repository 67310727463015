<div>
   <div class="row mt-2 d-flex justify-content-center">
      <h2 class="d-inline">

         {{requestFilterSalesOrderType && requestFilterSalesOrderType ==='Estimation'? 'Estimations': 'Sales Orders'}}

      </h2>
      <div *ngIf="!isSelectionMode">
      <ng-container *ngIf="requestFilterSalesOrderType !=='Estimation'">
         <button type="button" [routerLink]="['/salesOrder/create']" *ngIf="userAccess?.canCreateSalesOrder" class="btn btn-circle btn-circle-md d-inline ml-4"
            matTooltip="Create New Sales Order" matTooltipPosition="right" matTooltipClass="bg-dark"><i class="fas fa-plus fa-lg text-light"></i></button>
      </ng-container>
      <ng-container *ngIf="requestFilterSalesOrderType ==='Estimation'">
         <button type="button" [routerLink]="['/estimation/create']" *ngIf="userAccess?.canCreateSalesOrder" class="btn btn-circle btn-circle-md d-inline ml-4"
            matTooltip="Create New Estimation Order" matTooltipPosition="right" matTooltipClass="bg-dark"><i class="fas fa-plus fa-lg text-light"></i></button>
      </ng-container>      
      </div>     
   </div>

   <div class="mt-2 mb-2 bg-white rounded">
      <div class="col">
         <!--<mat-tab-group (selectedTabChange)="selectedTabChange($event.index)">
            
            <mat-tab>
               <ng-template mat-tab-label>
                  <span class="ml-2 font-weight-normal" >
                     <h6>Submitted</h6>
                  </span>
               </ng-template>

               <div class="overflow-hidden mt-2">-->
                  <div class="row">
                     <div class="col-sm-8" *ngIf="currentLoggedInUser && currentLoggedInUser!==null">
                        <app-search #searchComponent [placeholder]="'Order#, Customer Name, PO#, Co-ordinator, etc. (space delimited)'" [searchConfig]="searchConfig" [existingList]="salesOrderList" [sortColumns]="sortColumns"></app-search>
                     </div>
                     <div class="col-sm-2">
                        <div class="d-flex d-inline ">
                           <div>
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Status</label>
                        <select class="form-control form-control-sm custom-select mr-1" id="selectedStatus" name="selectedStatus" [(ngModel)]="selectedStatus" (change)="changeStatusTypeAndSearch()"><!--[(ngModel)]="excludeProductType" -->
                           <option value="ALL">Show All</option>
                           <option value="SUBMITTED">Show Submitted</option>
                           <option value="DRAFT">Show Draft</option>
                       </select>
                       </div></div>
                     </div>
                     <ng-container *ngIf="requestFilterSalesOrderType !=='Estimation'">
                     <div class="col-sm-2" *ngIf="selectedStatus!=='DRAFT'">
                        <div class="d-flex d-inline ">
                           <div>
                              <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Production Status</label>
                        <select class="form-control form-control-sm custom-select mr-1" id="selectedProductionStatus" name="selectedProductionStatus" [(ngModel)]="selectedProductionStatus" (change)="changeProductionStatusTypeAndSearch()"><!--[(ngModel)]="excludeProductType" -->
                           <option value="ALL">Show All</option>
                           <option value="IN_PROGRESS">Show In-Progress</option>
                           <option *ngFor="let productionStatus of productionStatusList" [ngValue]="productionStatus.key">
                              {{'sales.productionStatus.'+productionStatus.key | transform}}</option>
                       </select>
                       </div></div>
                     </div>
                     </ng-container>
                  </div>
                  <div>

                     <!--<div class="row mt-2 mb-2">
                        <div class="col-sm-6">
                           <label class="col-sm-2 text-right">Sort By:</label>
                           <select class="form-control form-control-sm custom-select col-sm-3 mr-1" id="sortBy" style="font-size: 11px;" name="submitted_sortBy" [(ngModel)]="selectedSortByName"
                              (change)="onSortColumnChange($event.target.value,'SUBMITTED')">
                              <option *ngFor="let sortColumn of sortColumns" [ngValue]="sortColumn.sortByName">
                                 {{sortColumn.columnName}}</option>
                           </select>
                           <span (click)="sortAsc('SUBMITTED')" *ngIf="selectedSortByName && sortMode==='ASC'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1"
                                 style="color:black; vertical-align: middle;"></i></span>
                           <span (click)="sortDesc('SUBMITTED')" *ngIf="selectedSortByName && sortMode==='ASC'"><i class="fas fa-sort-amount-down fa-lg mr-1"
                                 style="color:grey; vertical-align: middle;"></i></span>
                           <span (click)="sortAsc('SUBMITTED')" *ngIf="selectedSortByName && sortMode==='DESC'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1"
                                 style="color:grey; vertical-align: middle;"></i></span>
                           <span (click)="sortDesc('SUBMITTED')" *ngIf="selectedSortByName && sortMode==='DESC'"><i class="fas fa-sort-amount-down fa-lg"
                                 style="color:black; vertical-align: middle;"></i></span>
                        </div>
                        <div class="col-sm-5">
                           <div class="row">
                              <span class="col-sm-2 text-right align-middle" style="align-self: center;">Filter:</span>
                              <input name="submitted_filter" type="search" class="f-12 form-control col-sm-8" #search2 placeholder="Filter by Sales Order#, Customer Name.."
                                 (keyup)="onKeyupFilterSubmitted($event,search2.value)">
                           </div>
                        </div>
                     </div>-->
                     

                     <table class="table table-condensed table-sm customtablehover">
                        <thead class="thead-dark" [style]="!isSelectionMode?'position: sticky;top: 60px;':''">
                           <tr class="f-11">
                              <th style="width: 5%;" class=" align-middle text-center text-nowrap border">Actions</th>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">{{requestFilterSalesOrderType && requestFilterSalesOrderType ==='Estimation'? 'Estimation#': 'Sales Order#'}}</th>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Order Date</th>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Initial Submit Date</th>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Customer</th>
                              <ng-container *ngIf="requestFilterSalesOrderType !=='Estimation'">
                                 <th style="width: auto;" class=" align-middle text-center text-nowrap border">Cust PO#'s</th>
                              </ng-container>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Sales Rep</th>
                              <ng-container *ngIf="requestFilterSalesOrderType !=='Estimation'">
                                 <th style="width: auto;" class=" align-middle text-center text-nowrap border">Prod. Coordinator</th>
                              </ng-container>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Ship Date</th>
                              <ng-container *ngIf="requestFilterSalesOrderType !=='Estimation'">                              
                                 <th style="width: auto;" class=" align-middle text-center text-nowrap border">Cancel Date</th>
                              </ng-container>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Total Units</th>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Total Amount</th>
                              <th style="width: 1%;" class=" align-middle text-center text-nowrap border">{{requestFilterSalesOrderType && requestFilterSalesOrderType ==='Estimation'? 'Status': 'Production Status'}}</th>
                              <th style="width: 1%;" class=" align-middle text-center text-nowrap border">&nbsp;</th>
                           </tr>

                        </thead>
                        <tbody>
                           <ng-container *ngFor="let salesOrder of salesOrderList; index as submittedSalesOrderIndex">
                              <tr class="f-11">
                                 <td style="white-space: nowrap;width: 5%;">
                                    <div class="d-flex d-inline">
                                       <div class="d-flex justify-content-left" style="display: block; height:15px;" *ngIf="isSelectionMode" >
                                          <label class="cb-container">&nbsp;
                                             <input type="checkbox" (change)="selectionChanged(salesOrder)" [checked]="isSalesOrderSelected(salesOrder.id)">
                                             <span class="checkmark-sm"></span>
                                          </label>
                                       </div>
                                       <div *ngIf="!isSelectionMode">
                                       <!-- Closing cloning for estimation because cloning of estimation was creating sales order-->
                                       <a [routerLink]="['/salesOrder/clone/'+salesOrder.id]" *ngIf="userAccess?.canCloneSalesOrder && salesOrder?.orderType !=='Estimation'" class="btn btn-info" style="border: 0px;" class="ml-1 mr-1"><i class="fas fa-clone fa-1x"
                                          style="color: grey;cursor: pointer;"></i></a>
                                          <a class="btn" style="border: 0px;" class="ml-1 mr-1" *ngIf="userAccess?.canDeleteSalesOrder && salesOrder.salesOrderNumber==='DRAFT' && salesOrder.status ==='DRAFT'"
                                             (click)="deleteSalesOrder(salesOrder)"><i class="fas fa-trash fa-1x" style="color: #dc3545;cursor: pointer;"></i></a>
                                       </div>
                                       <!--
                                       <a class="btn" style="border: 0px;" class="ml-1 mr-1"><i class="fas fa-copy fa-1x"
                                          style="color: black;cursor: pointer;"></i></a> -->
                                    </div>
                                 </td>
                                 <ng-container *ngIf="requestFilterSalesOrderType !=='Estimation'">                                 
                                    <td style="width: auto;" class="align-middle text-center text-nowrap">
                                       <ng-container *ngIf="!isSelectionMode">
                                       <a [routerLink]="['/salesOrder/view/'+salesOrder.id]">
                                          {{salesOrder.salesOrderNumber}}
                                       </a>
                                       </ng-container>
                                       <ng-container *ngIf="isSelectionMode">
                                             {{salesOrder.salesOrderNumber}}
                                       </ng-container>
                                       <!--
                                       <sup><span *ngIf="salesOrder.submissionCount===1" class="badge badge-primary f-9">New Submission</span></sup>
                                       <sup><span *ngIf="salesOrder.submissionCount &gt; 1" class="badge badge-warning f-9">Re-Submission</span></sup>
                                       -->
                                    </td>
                                 </ng-container>
                                 <ng-container *ngIf="requestFilterSalesOrderType ==='Estimation'">                                 
                                    <td style="width: auto;" class="align-middle text-center text-nowrap">
                                       <a [routerLink]="['/estimation/view/'+salesOrder.id]">{{salesOrder.salesOrderNumber}}</a>
                                    </td>
                                 </ng-container>                                 
                                 <td style="width: auto;" class="align-middle text-center text-nowrap">{{salesOrder.orderCreatedDate | date:'mediumDate'}}</td>
                                 <td style="width: auto;" class="align-middle text-center text-nowrap">{{salesOrder?.orderInitialSubmitDate | date:'mediumDate'}}</td>
                                 <td style="width: auto;" class="align-middle text-left text-nowrap pl-3">{{salesOrder.customerName}}</td>
                                 <ng-container *ngIf="requestFilterSalesOrderType !=='Estimation'">
                                    <td style="width: auto;" class="align-middle text-left text-nowrap pl-3">
                                       <span *ngIf="salesOrder.customerPoNumbers">
                                          {{ (salesOrder.customerPoNumbers.length>15)? (salesOrder.customerPoNumbers | slice:0:15)+'..':(salesOrder.customerPoNumbers) }}
                                       </span>
                                    </td>
                                 </ng-container>
                                 <td style="width: auto;" class="align-middle text-left text-nowrap pl-3">{{salesOrder.salesRepName}}</td>
                                 <ng-container *ngIf="requestFilterSalesOrderType !=='Estimation'">
                                    <td style="width: auto;" class="align-middle text-left text-nowrap pl-3">
                                       <ng-container *ngIf="!salesOrder.editable">
                                          <div class="d-flex justify-content-between" style="align-items: center;">
                                             <span>{{salesOrder?.coordinatorName}}</span>
                                             <button type="button" class="btn btn-sm" *ngIf="userAccess?.canReassignSalesOrder && salesOrder.status==='SUBMITTED'" (click)="editProductionCoordinator(salesOrder)"><i class="fas fa-pencil-alt fa-sm"></i></button>
                                          </div>
                                       </ng-container>
                                       <ng-container *ngIf="salesOrder.editable && userAccess?.canReassignSalesOrder">
                                          <div class="d-flex">
                                             <select class="form-control form-control-sm" [(ngModel)]="salesOrder.productionCoordinatorId" name="productionCoordinator_{{submittedSalesOrderIndex}}" style="width:auto; font-size: 0.7rem;"
                                                #productionCoordinatorVar="ngModel">
                                                <option *ngFor="let user of productionUserList" [ngValue]="user.id">
                                                   {{user.firstName}} {{user.lastName}}</option>
                                             </select>
                                             <button type="button" class="btn btn-sm" (click)="saveProductionCoordinatorChange(salesOrder, submittedSalesOrderIndex)"><i class="fas fa-save"></i></button>
                                             <button type="button" class="btn btn-sm p-0" (click)="cancelProductionCoordinatorChange(salesOrder)"><i class="fas fa-window-close"></i></button>
                                          </div>
                                       </ng-container>
                                    </td>
                                 </ng-container>
                                 <td style="width: auto;" class="align-middle text-center text-nowrap">{{salesOrder.orderShipDate | date:'mediumDate'}}</td>
                                 <ng-container *ngIf="requestFilterSalesOrderType !=='Estimation'">
                                    <td style="width: auto;" class="align-middle text-center text-nowrap">{{salesOrder.orderCancelDate | date:'mediumDate'}}</td>
                                 </ng-container>
                                 <td style="width: auto;" class="align-middle text-right text-nowrap pr-3">{{salesOrder.totalUnits}}</td>
                                 <td style="width: auto;" class="align-middle text-right text-nowrap pr-3">{{salesOrder.totalCost | currency:salesOrder?.currency}}</td>
                                 <td style="width: auto;" class="align-middle text-right text-nowrap pr-3 bg-color-{{productionStatusReasonColor(salesOrder.productionStatusReason)}}">
                                    <span *ngIf="salesOrder.orderType !=='Estimation'">{{'sales.productionStatus.'+salesOrder?.productionStatus | transform}}</span>
                                    <span *ngIf="salesOrder.orderType ==='Estimation'">{{'estimation.productionStatus.'+salesOrder?.productionStatus | transform}}</span>
                                    <ng-container *ngIf="salesOrder?.productionStatus==='COSTING_GENERATION_PENDING'">
                                       <span *ngIf="!salesOrder.flagged" (click)="flagSalesOrder(salesOrder, true);">&nbsp;&nbsp;<i class="far fa-flag fa-lg" style="opacity: 40%;"></i></span>
                                       <span *ngIf="salesOrder.flagged" (click)="flagSalesOrder(salesOrder, false);">&nbsp;&nbsp;<i class="fas fa-flag fa-lg" style="color:red;"></i></span>
                                    </ng-container>
                                    <div class="f-8 text-muted" *ngIf="isManualCosting(salesOrder)"> Manual Costing</div>
                                 </td>
                                 <td style="width: auto;" class="align-middle text-right text-nowrap pr-3" 
                                 (click)="salesOrder.expand?salesOrder.expand=!salesOrder.expand:salesOrder.expand=true; loadActivities(salesOrder, salesOrder.id,salesOrder.expand, 'UNSUBMITTED');"
                                 *ngIf="salesOrder.productionStatusReason || salesOrder.productionStatusNote">
                                    <span *ngIf="!salesOrder.expand" ><i class="fas fa-chevron-down"></i></span>
                                    <span *ngIf="salesOrder.expand" ><i class="fas fa-chevron-up"></i></span>
                                 </td>
                              </tr>
                              <tr *ngIf="salesOrder.expand "  class="border-top-0 border">
                                 <td colspan="9999">
                                    <div class="my-1 mx-2 f-10" style="width: calc(70vw);">
                                       <div><li><b><i>Status Reason:</i></b> {{salesOrder.productionStatusReason}}</li></div>
                                       <div>{{salesOrder.productionStatusNote}}</div>
                                    </div>
                                    <ng-container *ngFor="let unsubmittedActivityNote of salesOrder.activityNotes; index as i">
                                       <div class="my-1 mx-2 f-10" style="width: calc(70vw);">
                                          <div [innerHTML]="unsubmittedActivityNote"></div>
                                       </div>
                                    </ng-container>
                                 </td>
                              </tr>
                           </ng-container>
                        </tbody>
                     </table>

                  </div>
           <!--    </div>

            </mat-tab>
            <mat-tab>
               <ng-template mat-tab-label>
                  <span class="ml-2 font-weight-normal" >
                     <h6>Draft</h6>
                  </span>
               </ng-template>

               <div class="overflow-hidden mt-2">
                  <div>

                     <div class="row mt-2 mb-2">
                        <div class="col-sm-6">
                           <label class="col-sm-2 text-right">Sort By:</label>
                           <select class="form-control form-control-sm custom-select col-sm-3 mr-1" id="sortBy" style="font-size: 11px;" name="draft_sortBy" [(ngModel)]="selectedSortByName"
                              (change)="onSortColumnChange($event.target.value,'DRAFT')">
                              <option *ngFor="let sortColumn of sortColumns" [ngValue]="sortColumn.sortByName">
                                 {{sortColumn.columnName}}</option>
                           </select>
                           <span (click)="sortAsc('DRAFT')" *ngIf="selectedSortByName && sortMode==='ASC'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1"
                                 style="color:black; vertical-align: middle;"></i></span>
                           <span (click)="sortDesc('DRAFT')" *ngIf="selectedSortByName && sortMode==='ASC'"><i class="fas fa-sort-amount-down fa-lg mr-1"
                                 style="color:grey; vertical-align: middle;"></i></span>
                           <span (click)="sortAsc('DRAFT')" *ngIf="selectedSortByName && sortMode==='DESC'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1"
                                 style="color:grey; vertical-align: middle;"></i></span>
                           <span (click)="sortDesc('DRAFT')" *ngIf="selectedSortByName && sortMode==='DESC'"><i class="fas fa-sort-amount-down fa-lg"
                                 style="color:black; vertical-align: middle;"></i></span>
                        </div>
                        <div class="col-sm-5">
                           <div class="row">
                              <span class="col-sm-2 text-right align-middle" style="align-self: center;">Filter:</span>
                              <input type="search" class="f-12 form-control col-sm-8" #search1 placeholder="Filter by Sales Order#, Customer Name..." (keyup)="onKeyupFilterDraft($event,search1.value)">
                           </div>
                        </div>
                     </div>

                     <table class="table table-condensed table-sm customtablehover">
                        <thead class="thead-dark">
                           <tr class="f-11">
                              <th style="width:5%;" class="align-middle text-center text-nowrap  border">Actions</th>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Sales Order#</th>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Order Date</th>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Initial Submit Date</th>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Customer</th>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Sales Rep</th>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Ship Date</th>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Cancel Date</th>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Total Units</th>
                              <th style="width: auto;" class=" align-middle text-center text-nowrap border">Total Amount</th>
                           </tr>
                        </thead>
                        <tbody>
                           <ng-container *ngFor="let salesOrder of filteredDraftSalesOrderList; index as salesOrderIndex">
                              <tr class="f-11">
                                 <td style="white-space: nowrap;width: 5%;">
                                    <div class="d-flex d-inline">
                                       <a class="btn" style="border: 0px;" class="ml-1 mr-1" *ngIf="userAccess?.canDeleteSalesOrder" (click)="deleteSalesOrder(salesOrder)"><i class="fas fa-trash fa-1x"
                                             style="color: #dc3545;cursor: pointer;"></i></a>
                                       <a [routerLink]="['/salesOrder/edit/'+salesOrder.id]" *ngIf="userAccess?.canEditSalesOrder" class="btn btn-warning" style="border: 0px;" class="ml-1 mr-1"><i class="fas fa-pencil-alt fa-1x"
                                             style="color: grey;cursor: pointer;"></i></a>
                                       <a [routerLink]="['/salesOrder/clone/'+salesOrder.id]" *ngIf="userAccess?.canCloneSalesOrder" class="btn btn-info" style="border: 0px;" class="ml-1 mr-1"><i class="fas fa-clone fa-1x"
                                          style="color: grey;cursor: pointer;"></i></a>
                                       !--
                                       <a class="btn" style="border: 0px;" class="ml-1 mr-1"><i class="fas fa-copy fa-1x"
                                          style="color: black;cursor: pointer;"></i></a> --
                                    </div>
                                 </td>
                                 <td style="width: auto;" class="align-middle text-center text-nowrap">
                                    <a [routerLink]="['/salesOrder/view/'+salesOrder.id]">
                                       {{salesOrder.salesOrderNumber}}
                                    </a>
                                 </td>
                                 <td style="width: auto;" class="align-middle text-center text-nowrap">{{salesOrder.orderCreatedDate | date:'mediumDate'}}</td>
                                 <td style="width: auto;" class="align-middle text-center text-nowrap">{{salesOrder?.orderInitialSubmitDate | date:'mediumDate'}}</td>
                                 <td style="width: auto;" class="align-middle text-left text-nowrap pl-3">{{salesOrder.customerName}}</td>
                                 <td style="width: auto;" class="align-middle text-left text-nowrap pl-3">{{salesOrder.salesRepName}}</td>
                                 <td style="width: auto;" class="align-middle text-center text-nowrap">{{salesOrder.orderShipDate | date:'mediumDate'}}</td>
                                 <td style="width: auto;" class="align-middle text-center text-nowrap">{{salesOrder.orderCancelDate | date:'mediumDate'}}</td>
                                 <td style="width: auto;" class="align-middle text-right text-nowrap pr-3">{{salesOrder.totalUnits}}</td>
                                 <td style="width: auto;" class="align-middle text-right text-nowrap pr-3">{{salesOrder.totalCost | currency:salesOrder?.currency}}</td>
                              </tr>
                           </ng-container>
                        </tbody>
                     </table>

                  </div>
               </div>

            </mat-tab>
         </mat-tab-group>-->
      </div>
   </div>

   <div class="floatBtn d-flex flex-column">
      <button type="button" class="btn btn-circle bg-secondary mb-2" matTooltip="Scroll To Top" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'});"  matTooltipPosition="left" matTooltipClass="bg-dark">
         <i class="fas fa-arrow-up fa-2x" style="color:white"></i>
      </button>
   </div>

</div>