import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Brand, BrandPnlSpeciality } from '../model/brand.model';
import { catchError } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { FileDeleteCriteria } from '../model/file-delete-criteria.model';
import { MetadataService } from './metadata.service';

@Injectable({
   providedIn: 'root'
})

export class BrandService {

   constructor(private http: HttpClient, private metadataService:MetadataService) { 
      this.initializeMetadataSubscription();
   }

   readonly rooturl = environment.apiEndPoint;
   availablePnlSpecialityFromMetadataSubject: BehaviorSubject<BrandPnlSpeciality[]>;
   public availablePnlSpecialityFromMetadataObservable: Observable<BrandPnlSpeciality[]>;
   /*loadBrand(brandId: string) {
      return this.http.get(this.rooturl + "/brand/load/" + brandId);
   }*/

   initializeMetadataSubscription() {

      this.availablePnlSpecialityFromMetadataSubject = new BehaviorSubject<BrandPnlSpeciality[]>([]);
      this.availablePnlSpecialityFromMetadataObservable = this.availablePnlSpecialityFromMetadataSubject.asObservable();
      this.getAvailablePnlSpecality();
   }

   getAvailablePnlSpecality(){
      this.loadBrandPnlSpeciality().subscribe(response => {
         if(response && response!=null &&  response.length>0){
            let responseArr:BrandPnlSpeciality[]=response;
            this.availablePnlSpecialityFromMetadataSubject.next(responseArr);
         }
         
      }, err => {
         console.error('Error while loading Available Pnl Specialities List from Metadata : ' + JSON.stringify(err));
      });
   }

   

   loadBrand(brandId: string,includeAttachments:boolean,includeProducts:boolean) {
      if(includeProducts){
       return this.http.get(this.rooturl + "/brand/load/" + brandId);
      }else{
         return this.loadBrandWithoutProduct(brandId,includeAttachments);
      }
   }

   loadBrandWithoutProduct(brandId: string,includeAttachments:boolean) {
      return this.http.get(this.rooturl + "/brand/loadWithoutProduct/" + brandId+"/"+includeAttachments);
   }

   loadBrandForSkuCode(skuCode: string) {
      return this.http.get(this.rooturl + "/brand/loadForSkuCode/" + skuCode);
   }

   loadBrandList(): Observable<Brand[]> {
      return this.http.get<Brand[]>(this.rooturl + "/brand/list");
   }

   loadBrandPnlSpeciality(): Observable<BrandPnlSpeciality[]> {
      return this.http.get<BrandPnlSpeciality[]>(this.rooturl + "/brand/list/brandPnlSpeciality");
   }

   

   loadBrands(selectedLicensors: string[]): Observable<Brand[]> {
      return this.http.post<Brand[]>(this.rooturl + "/brand/filteredList", selectedLicensors);
      //return this.http.get<Brand[]>(this.rooturl+"/licensor/list");
   }

   createBrand(brand: Brand) {
      return this.http.post(this.rooturl + "/brand/create", brand);
   }

   updateBrand(brand: Brand) {
      return this.http.post(this.rooturl + "/brand/update", brand);
   }

   uploadImagesForBrandWithProgress(brand: Brand, file: File, category: string) {
      const fd = new FormData();
      fd.append('file', file, file.name);
      //fd.append('brand', JSON.stringify(brand));
      return this.http.post(this.rooturl + "/brand/uploadImage/" + category + "/" + brand.id, fd, {
         reportProgress: true,
         observe: 'events'
      }).pipe(
         catchError(this.errorMgmt)
      );
   }

   errorMgmt(error: HttpErrorResponse) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
      } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.error(errorMessage);
      return throwError(errorMessage);
   }

   deleteFile(criteria: FileDeleteCriteria) {
      return this.http.post(this.rooturl + "/brand/file/delete", criteria);
   }

   loadBrandsListWithBrandRights(): Observable<Brand[]> {
      return this.http.get<Brand[]>(this.rooturl + "/brand/load/brandRights");
   }

}
