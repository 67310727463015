import { Address } from './address.model';
import { Phone } from './phone.model';
import { Email } from './email.model';
import { Profile } from './profile.model';

export class ContactDetails {
    id: string;
    type: string;
    addressList: Address[] = [];
    phoneList: Phone[] = [];
    emailList: Email[] = [];
    profile: Profile = new Profile;

    constructor(){
       this.profile = new Profile;
    }
}
