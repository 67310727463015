<div>
   <div class="row mt-2 d-flex justify-content-center">
      <h2 class="d-inline">Blanks</h2>
      <button type="button" [routerLink]="['/blanks/create']" *ngIf="userAccess?.canCreateBlanks" class="btn btn-circle btn-circle-md d-inline ml-4" matTooltip="Create New Blanks" matTooltipPosition="right"
         matTooltipClass="bg-dark"><i class="fas fa-plus fa-lg text-light"></i></button>
   </div>
   <hr>

   <div class="mt-2 shadow-lg mb-2 bg-white rounded">
      <div class="col">
         <mat-tab-group>
            <mat-tab>
               <ng-template mat-tab-label>
                  <span class="ml-2 font-weight-normal">
                     <h5>Detail</h5>
                  </span>
               </ng-template>

               <div class="overflow-hidden mt-2">
                  <div>
                     <div class="row mt-2 mb-2">
                        <div class="col-sm-6">
                           <label class="col-sm-2 text-right">Sort By:</label>
                           <select class="form-control form-control-sm custom-select col-sm-3 mr-1" id="sortBy" style="font-size: 11px;" name="draft_sortBy" [(ngModel)]="selectedSortByName"
                              (change)="onSortColumnChange($event.target.value)">
                              <option *ngFor="let sortColumn of sortColumns" [ngValue]="sortColumn.sortByName">
                                 {{sortColumn.columnName}}</option>
                           </select>
                           <span (click)="sortAsc()" *ngIf="selectedSortByName && sortMode==='ASC'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1"
                                 style="color:black; vertical-align: middle;"></i></span>
                           <span (click)="sortDesc()" *ngIf="selectedSortByName && sortMode==='ASC'"><i class="fas fa-sort-amount-down fa-lg mr-1"
                                 style="color:grey; vertical-align: middle;"></i></span>
                           <span (click)="sortAsc()" *ngIf="selectedSortByName && sortMode==='DESC'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1"
                                 style="color:grey; vertical-align: middle;"></i></span>
                           <span (click)="sortDesc()" *ngIf="selectedSortByName && sortMode==='DESC'"><i class="fas fa-sort-amount-down fa-lg" style="color:black; vertical-align: middle;"></i></span>
                        </div>
                        <div class="col-sm-5">
                           <div class="row">
                              <span class="col-sm-2 text-right align-middle" style="align-self: center;">Filter:</span>
                              <input type="search" class="f-12 form-control col-sm-8" #search placeholder="Filter by Sku Code .." (keyup)="onKeyupFilter($event,search.value)">
                           </div>
                        </div>
                     </div>

                     <table class="table table-sm table-striped table-bordered table-condensed">
                        <thead class="text-white bg-secondary">
                           <tr>
                              <th style="width:5%; padding: 1px;" class="align-middle text-center text-nowrap" *ngIf="userAccess?.canEditRoyalty">Actions</th>
                              <th style="width: auto; padding: 1px;" class=" align-middle text-center text-nowrap">SKU Code</th>
                              <th style="width: auto; padding: 1px;" class=" align-middle text-center text-nowrap">Description</th>
                              <th style="width: auto; padding: 1px;" class=" align-middle text-center text-nowrap">Supplier</th>
                              <th style="width: auto; padding: 1px;" class=" align-middle text-center text-nowrap">GSM</th>
                              <th style="width: auto; padding: 1px;" class=" align-middle text-center text-nowrap">Fabric Content</th>
                           </tr>
                        </thead>
                        <tbody>
                           <ng-container *ngFor="let blanks of filteredBlanksList; index as blanksIndex">
                              <tr class="f-13">
                                 <td style="white-space: nowrap;width: 5%;" *ngIf="userAccess?.canEditRoyalty">
                                    <div class="d-flex d-inline">
                                       <a class="btn" style="border: 0px;" class="ml-1 mr-1" (click)="deleteBlanks(blanks)" *ngIf="userAccess?.canDeleteBlanks"><i class="fas fa-trash fa-1x"
                                             style="color: #dc3545;cursor: pointer;" ></i></a>
                                       <a [routerLink]="['/blanks/edit/'+blanks.id]" class="btn btn-warning" style="border: 0px;" class="ml-1 mr-1" *ngIf="userAccess?.canEditBlanks"><i class="fas fa-pencil-alt fa-1x"
                                             style="color: grey;cursor: pointer;"></i></a>
                                    </div>
                                 </td>
                                 <td style="width: auto;" class="align-middle text-center text-nowrap">
                                    <a [routerLink]="['/blanks/view/'+blanks.id]">
                                       {{blanks.skuCode}}
                                    </a>
                                 </td>
                                 <td style="width: auto;" class="align-middle text-center text-nowrap">{{blanks.description}}</td>
                                 <td style="width: auto;" class="align-middle text-center text-nowrap">{{blanks.supplier}}</td>
                                 <td style="width: auto;" class="align-middle text-center text-nowrap">{{blanks.gsm}}</td>
                                 <td style="width: auto;" class="align-middle text-center text-nowrap">{{blanks.fabricContent}}</td>
                              </tr>
                           </ng-container>
                        </tbody>
                     </table>

                  </div>
               </div>

            </mat-tab>

         </mat-tab-group>
      </div>
   </div>

</div>
