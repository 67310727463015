import { ResolvedStaticSymbol } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { OrderDetailInfo } from 'src/app/shared/model/sales/fulfillment/order-detail-info.model';
import { PrinterInfo } from 'src/app/shared/model/sales/fulfillment/printer-info.model';
import { ProductionTracker } from 'src/app/shared/model/sales/fulfillment/production-tracker.model';
import { SalesOrder } from 'src/app/shared/model/sales/sales-order.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { Printer } from 'src/app/shared/model/vendors/printer.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { VendorsService } from 'src/app/shared/service/vendors/vendors.service';
import { PrinterOrderDetailLinkModalComponent } from '../printer-order-detail-link-modal/printer-order-detail-link-modal.component';

@Component({
  selector: 'app-printer-info',
  templateUrl: './printer-info.component.html',
  styleUrls: ['./printer-info.component.css']/*,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]*/
})
export class PrinterInfoComponent implements OnInit {
  @Input() salesOrder:SalesOrder;
  @Input() userAccess:UserAccess;
  @Input() productionTracker:ProductionTracker;
  @Input() printerInfoIndex: number=0;
  @Output() onPrinterInfoEdit = new EventEmitter<boolean>();
  @Output() onPrinterInfoCancelEdit = new EventEmitter<boolean>();
  @Output() onPrinterInfoSave = new EventEmitter<boolean>();
  @Input() editable:boolean=false;
printerList:Printer[]=[];

constructor(private vendorService:VendorsService,private sharedService:SharedService,private dialog: MatDialog, private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.handleSubscriptions();
    //this.getVendorList();
  }

  handleSubscriptions() {
    // Subscriptions
    this.vendorService.printerListObservable.subscribe(res => {
       this.printerList = res;
       console.log("PTBI PrinterList",this.printerList);
    });
   
  }
 /* getVendorList() {
  
    this.vendorService.getPrinterList().subscribe(res=>{
      if(res && res.responseStatus.status==='SUCCESS' && res.responsePayload !=null){
        this.printerList=res.responsePayload;
        this.printerList = this.sharedService.sortListByPropertyName(this.printerList,'name');
      }
    });
  }*/
  addPrinterInfo(){
    this.productionTracker.printerInfoList.push(new PrinterInfo(this.productionTracker.salesOrderId));
  };
  deletePrinterInfo(printerInfo:PrinterInfo){
    printerInfo.deleteFlag=true;
    printerInfo.trimInfoList.forEach(trimInfo => {
      trimInfo.deleteFlag=true;
    });
    printerInfo.orderDetailInfoList.forEach(OrderDetailInfo => {
      OrderDetailInfo.deleteFlag=true;
    });
  };
  calculateWeek(printerInfo:PrinterInfo){
    if(printerInfo && printerInfo.printerRequestShipDate && printerInfo.printerRequestShipDate!=null){
      printerInfo.printerWeek=this.sharedService.getWeek(1,printerInfo.printerRequestShipDate);
    }
  }

  getPrinterName(printerInfo:PrinterInfo):string{
    let retVal:string='';
    if(this.printerList){
      this.printerList.forEach(printer => {
        if(printer.id===printerInfo.printerId){
          printerInfo.printerName=printer.name;
          retVal= printer.name;
        }
      });
    }
    return retVal
  }

  attachOrderDetail(salesOrder:SalesOrder,printerInfo:PrinterInfo){
    const confirmDialog = this.dialog.open(PrinterOrderDetailLinkModalComponent, {
      disableClose: true,
      data: {
        salesOrder:salesOrder,
        printerInfo: printerInfo
      },
      width:"80%"
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
      }
    });
  }

  getAssignedCount(orderDetailInfoList:OrderDetailInfo[]):number{
    let retVal=0;
    if(orderDetailInfoList){
      let temp=orderDetailInfoList.filter(x=>x.deleteFlag===false && x.obsolete===false &&  x.orderDetailStatus!=='CANCELLED');
      retVal=temp.length;
    }
    return retVal;
  }

  edit(){
    this.onPrinterInfoEdit.next(true);
  }
  cancel(){
    this.editable=false;
    this.onPrinterInfoCancelEdit.next(true);
  }
  save(){
    if(this.valid()){
      this.onPrinterInfoSave.next(true);
    }
  }
  valid() {
    let retVal=true;
    if(this.productionTracker && this.productionTracker.printerInfoList && this.productionTracker.printerInfoList.length>0){
      let missingOrderDetailAssignmentForPrinter=false;
      this.productionTracker.printerInfoList.forEach(printerInfo => {
        if(!printerInfo.deleteFlag &&  printerInfo.orderDetailInfoList && printerInfo.orderDetailInfoList.length===0){
          missingOrderDetailAssignmentForPrinter=true;
        }
      });
      if(missingOrderDetailAssignmentForPrinter){
        this.toastrService.error("Please assign styles for each printer in the printer section");
        retVal=false;
      }
    }
    return retVal;
  }

  dummy(){
    console.log('Dummy');
  }
}
