<!-- EDITABLE MODE -->
<!--
   <div *ngIf="editable">
      <div *ngIf="multiplePhones">
         <button type="button" class="btn btn-sm btn-primary mb-2" (click)="addPhone()">Add Phone</button>
      </div>

      <div class="container border mb-2 p-3" *ngFor="let phone of phoneList; let phoneIndex = index;">
         <div class="row form-group">
            <label class="col-sm-3"><i class="fa fa-phone"></i>&nbsp;Phone Type</label>
            <div class="col-sm-3">
               <select name="phoneType_{{phoneIndex}}" [(ngModel)]="phoneList[phoneIndex].phoneType"
                  class="form-control form-control-sm" placeholder="Phone" #phoneTypeVar="ngModel" required
                  [ngClass]="{'is-invalid':phoneTypeVar.touched && phoneTypeVar.errors}">
                  <option *ngFor="let phoneType of phoneTypes" [ngValue]="phoneType">{{phoneType}}</option>
               </select>
               <div *ngIf="phoneTypeVar?.invalid && (phoneTypeVar.dirty || phoneTypeVar.touched)"
                  class="invalid-feedback">
                  <div *ngIf="phoneTypeVar.errors.required">Phone Type is required.</div>
               </div>
            </div>
            <div class="col-sm" *ngIf="multiplePhones">
               <button type="button" class="btn btn-danger btn-sm float-right"
                  (click)="removePhone(phoneList[phoneIndex])">Remove</button>
            </div>
         </div>

         <hr style="margin-top:0.5rem;">

         <div class="row form-group">
            <label class="col-sm-3 col-form-label"><i class="fa fa-globe"></i>&nbsp;Country Code</label>
            <div class="col-sm">
               <input name="countryCode_{{phoneIndex}}" [(ngModel)]="phoneList[phoneIndex].countryCode"
                  class="form-control form-control-sm" placeholder="Country Code" #countryCodeVar="ngModel" required
                  [ngClass]="{'is-invalid':countryCodeVar.touched && countryCodeVar.errors}">
               <div *ngIf="countryCodeVar?.invalid && (countryCodeVar.dirty || countryCodeVar.touched)"
                  class="invalid-feedback">
                  <div *ngIf="countryCodeVar.errors.required">Country Code # is required.</div>
               </div>
            </div>
         </div>

         <div class="row form-group">
            <label class="col-sm-3 col-form-label"><i class="fa fa-phone"></i>&nbsp;Phone Number</label>
            <div class="col-sm">
               <input name="phoneNumber_{{phoneIndex}}" [(ngModel)]="phoneList[phoneIndex].phoneNumber"
                  class="form-control  form-control-sm" placeholder="Phone Number" #phoneNumberVar="ngModel" required
                  [ngClass]="{'is-invalid':phoneNumberVar.touched && phoneNumberVar.errors}">
               <div *ngIf="phoneNumberVar?.invalid && (phoneNumberVar.dirty || phoneNumberVar.touched)"
                  class="invalid-feedback">
                  <div *ngIf="phoneNumberVar.errors.required">Phone Number is required.</div>
               </div>
            </div>
         </div>

         <div class="row form-group">
            <label class="col-sm-3 col-form-label"><i class="fa fa-phone"></i>&nbsp;Phone Extension</label>
            <div class="col-sm">
               <input name="phoneExtension_{{phoneIndex}}" [(ngModel)]="phoneList[phoneIndex].phoneExtension"
                  class="form-control  form-control-sm" placeholder="Phone Extension" #phoneExtensionVar="ngModel"
                  [required]="false" [ngClass]="{'is-invalid':phoneExtensionVar.touched && phoneExtensionVar.errors}">
               <div *ngIf="phoneExtensionVar?.invalid && (phoneExtensionVar.dirty || phoneExtensionVar.touched)"
                  class="invalid-feedback">
                  <div *ngIf="phoneExtensionVar.errors.required">Phone Extension is required.</div>
               </div>
            </div>
         </div>

      </div>

   </div>

-->

<div ngModelGroup="PhoneMG" style="border: 0px;">
   
   <div class="container h-100 mb-2" *ngIf="phoneList?.length===0 || phoneList ==null">
      <div class="row h-100">
         <div class="col-sm-12">
             <div class="w-25 mx-auto"><p class="text-muted"><em>No Phone(s) Available.</em></p></div>
         </div>
     </div>
   </div>


   <div *ngIf="editable">

      <div [class]="index===(phoneList.length - 1)?'mb-1 p-1 ml-3':'border-bottom mb-1 p-1 ml-3'" *ngFor="let phone of phoneList; index as index">
         <div class="row form-group">
            <div class="col-3">
               <label class="mb-0 label-medium">Phone Type</label>
               <div>
                  <select name="phoneType_{{phone.id}}_{{phone.transientIndex}}" [(ngModel)]="phone.phoneType" class="form-control form-control-sm" #phoneTypeVar="ngModel" required
                     [ngClass]="{'is-invalid':phoneTypeVar.touched && phoneTypeVar.errors}" [disabled]="!editable">
                     <option *ngFor="let phoneType of phoneTypes" [ngValue]="phoneType">{{phoneType}}</option>
                  </select>
                  <div *ngIf="phoneTypeVar?.invalid && (phoneTypeVar.dirty || phoneTypeVar.touched)" class="invalid-feedback">
                     <div *ngIf="phoneTypeVar.errors.required">Phone Type is required.</div>
                  </div>
               </div>
            </div>

            <div class="col-3">
               <label class="mb-0 label-medium">Country Code</label>
               <div>
                  <input name="countryCode_{{phone.id}}_{{phone.transientIndex}}" [(ngModel)]="phone.countryCode" class="form-control form-control-sm" #countryCodeVar="ngModel"
                     [ngClass]="{'is-invalid':countryCodeVar.touched && countryCodeVar.errors}"  [disabled]="!editable">
                  <div *ngIf="countryCodeVar?.invalid && (countryCodeVar.dirty || countryCodeVar.touched)" class="invalid-feedback">
                     <div *ngIf="countryCodeVar.errors.required">Country Code # is required.</div>
                  </div>
               </div>
            </div>

            <div class="col-3">
               <label class="mb-0 label-medium">Phone #</label>
               <div>
                  <input name="phoneNumber_{{phone.id}}_{{phone.transientIndex}}" [(ngModel)]="phone.phoneNumber" class="form-control  form-control-sm" #phoneNumberVar="ngModel"
                     required [ngClass]="{'is-invalid':phoneNumberVar.touched && phoneNumberVar.errors}" [disabled]="!editable">
                  <div *ngIf="phoneNumberVar?.invalid && (phoneNumberVar.dirty || phoneNumberVar.touched)" class="invalid-feedback">
                     <div *ngIf="phoneNumberVar.errors.required">Phone Number is required.</div>
                  </div>
               </div>
            </div>

            <div class="col-2">
               <label class="mb-0 label-medium">Ext.</label>
               <div>
                  <input name="phoneExtension_{{phone.id}}_{{phone.transientIndex}}" [(ngModel)]="phone.phoneExtension" class="form-control  form-control-sm"
                     #phoneExtensionVar="ngModel" [required]="false" [ngClass]="{'is-invalid':phoneExtensionVar.touched && phoneExtensionVar.errors}"  [disabled]="!editable">
                  <div *ngIf="phoneExtensionVar?.invalid && (phoneExtensionVar.dirty || phoneExtensionVar.touched)" class="invalid-feedback">
                     <div *ngIf="phoneExtensionVar.errors.required">Phone Extension is required.</div>
                  </div>
               </div>
            </div>

            <div class="col-sm-1 p-0" *ngIf="multiplePhones && editable"  style="align-self: flex-end;">
               <label>&nbsp;</label>
               <a class="btn" style="border: 0px;" (click)="removePhone(index)" class="ml-2"><i class="fas fa-trash fa-1x"
                     style="color: #dc3545"></i></a>
            </div>
         </div>

      </div>
      
      <div *ngIf="(multiplePhones && editable) || (!multiplePhones && editable && (phoneList?.length===0 || phoneList ==null))" class="row col-sm-auto">
         <a (click)="addPhone()" class="btn btn-info btn-sm text-white"><i class="fas fa-plus fa-1x"></i>&nbsp;Phone</a>
      </div>

   </div>


   <!-- NON - EDITABLE MODE

   <div *ngIf="!editable">
      <div class="container mb-2" *ngFor="let phone of phoneList; let phoneIndex = index;">

         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label text-right"><i class="fa fa-phone"></i>&nbsp;Phone Type:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{phone.phoneType}} </span>
            </div>
         </div>

         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label text-right"><i class="fa fa-globe"></i>&nbsp;Country Code:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{phone.countryCode}} </span>
            </div>
         </div>

         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label text-right"><i class="fa fa-phone"></i>&nbsp;Phone #:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{phone.phoneNumber}} </span>
            </div>
         </div>

         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label text-right"><i class="fa fa-phone"></i>&nbsp;Extension:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{phone.phoneExtension}} </span>
            </div>
         </div>
         <hr>
      </div>
   </div>
   -->


   <div *ngIf="!editable" class="row mt-2">
      <ng-container *ngFor="let phone of phoneList">
         <div class="col-4 mb-1">
            <div class="card mb-2 h-100 border-0">
               <div>
                  <div class="card-body" style="padding: 0.25rem;">
                     <p class="card-text f-14 font-weight-bold ml-2 mb-0 text-wrap">{{phone.phoneType}}</p>
                     <p class="card-text ml-2 mb-0 text-truncate">
                        <span *ngIf="phone.countryCode">{{phone.countryCode}} </span>
                        <span *ngIf="phone.phoneNumber">{{phone.phoneNumber}} </span>
                        <span *ngIf="phone.phoneExtension">{{phone.phoneExtension}} </span>
                     </p>
                  </div>
               </div>
            </div>
            
         </div>
      </ng-container>
   </div>

</div>