import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductionTracker } from 'src/app/shared/model/sales/fulfillment/production-tracker.model';
import { TrimsOrderTrackerDetailsSalesOrderGrouped } from 'src/app/shared/model/sales/fulfillment/trims-info.model';
import { ServiceStatus } from 'src/app/shared/model/service-status.model';
import { Printer } from 'src/app/shared/model/vendors/printer.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductionTrackerService {
  
  

  constructor(private http: HttpClient) { }

  readonly rooturl = environment.apiEndPoint;

  saveProductionTracker(productionTracker: ProductionTracker) {
    return this.http.post<ServiceStatus>(this.rooturl + '/sales/fulfillment/productionTracker/save', productionTracker);
  }

  loadProductionTracker(salesOrderId: string) {
    return this.http.post<ProductionTracker>(this.rooturl + '/sales/fulfillment/productionTracker/load', salesOrderId);
  }

  loadProductionTrackerForPrinterPortal(salesOrderId: string) {
    return this.http.post<ProductionTracker>(this.rooturl + '/sales/fulfillment/productionTracker/printerPortal/load', salesOrderId);
  }
  
 
  loadAllProductionTracker() {
    throw new Error('Method not implemented.');
  }


  saveTrimsTrackerForSalesOrder(tsog: TrimsOrderTrackerDetailsSalesOrderGrouped) {
    return this.http.post<ServiceStatus>(this.rooturl + '/sales/fulfillment/trimsOrderTracker/save', tsog);
  }

  getParticipatingPrinters(salesOrderId: string) {
    return this.http.post<Printer[]>(this.rooturl + '/sales/fulfillment/productionTracker/participating/printers', salesOrderId);
 }
}
