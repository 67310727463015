export class EmailLog {
    id:string;
    parentId:string;
    type:string;
    subType:string;
    category:string;
    subCategory:string;
    sentDate:Date;
    sentBy:string;
    emailTo: string;
    emailCc: string;
    emailBcc: string;
    emailSubject: string;
    emailAttachments:string;
}
