<form #skuListForm="ngForm">
<div class="container-fluid f-13">
    <div class="floatBtn d-flex flex-column">    
        <!--<button type="button" (click)="downloadSelectedImages()"  class=" btn btn-circle bg-info mb-2" *ngIf="selectedProductForCatalogCount>0"  matTooltip="Download selected product images as zip" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-file-archive fa-2x" style="color: white;"></i></button>
        <button type="button" (click)="resetCatalogSelection()"  class=" btn btn-circle bg-dark mb-2" *ngIf="selectedProductForCatalogCount>0"  matTooltip="Reset product selection for catalog" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-undo-alt fa-lg" style="color: white;"></i></button>
        <button type="button" (click)="selectCurrentFilteredListForCatalog()"  class=" btn btn-circle bg-warning mb-2" *ngIf="userAccess?.canCreateCatalog && filteredProductList?.length>0"  matTooltip="Select all in current list" matTooltipPosition="left" matTooltipClass="bg-dark"><span><img style=" max-width:50%;" src="/assets/select-all.png"></span></button>
        <a [routerLink]="['/product-catalog']" class="btn btn-circle mb-2" style="background-color: tomato;" *ngIf="selectedProductForCatalogCount>0" matTooltip="Preview Catalog" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-eye  fa-2x" ></i></a>-->
        <button type="button" class="btn btn-circle bg-secondary mb-2" matTooltip="Scroll To Top" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'});"  matTooltipPosition="left" matTooltipClass="bg-dark">
          <i class="fas fa-arrow-up fa-2x" style="color:white"></i>
       </button>
    </div>
    
        <div class="row">
            <div class="col-sm-3 col-lg-2 col-md-3 border" [style]="filterVisible?'display:block':'display:none'" *ngIf="!embedded">
                <div>
                    <div class="list-group list-group-item bg-dark text-white mt-2">
                        <div class="d-flex justify-content-between padding-0">
                            <div><i class="fas fa-filter mr-1"></i></div>
                            <h6>Filters</h6>
                            <button type="button" class="btn btn-sm bg-white py-0" (click)="resetAdvSearch()"   matTooltip="Reset applied filters" matTooltipPosition="right" matTooltipClass="bg-dark">
                                <i class="fas fa-undo fa-sm"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div >
                        <!--GENERIC STARTS-->
                        <div *ngFor="let type1 of filterTypes;index as typeInex">
                            <div class="row m-1">
                                <button class="btn btn-sm color-3 btn-block"  (click)="loadFilterItem(type1)" type="button"
                                    data-toggle="modal" attr.data-target="#{{type1}}FilterModal">{{filterTypesNames[typeInex]}}
                                    <i class="fas fa-plus"></i>
                                </button>
                            </div>
                            <div class="row ml-1">
                                <div class="col-sm-11" *ngFor="let item of getFilteredListForSearch(type1);index as index">
                                    <div class="custom-control custom-checkbox mr-sm-2">
                                        <input type="checkbox" class="custom-control-input " id="{{type1+index}}"  (change)="selectedForSearch($event,item,type1)"
                                        [checked]="isSelectedForSearch(item,type1)">
                                        <label class="custom-control-label text-break" for="{{type1+index}}">{{item}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--GENERIC ENDS-->
                    </div>
                    <div class="m-1 d-flex">
                        <button class="btn bg-dark text-white btn-block" (click)="search()" type="button"
                            >Apply Filters</button>
                    </div>
                </div>
                <div >
                    
                    <div class="custom-control custom-checkbox mr-sm-2" >
                        <input type="checkbox" class="custom-control-input " id="includeInactiveLicensorsAndBrandsId"   (change)="includeInactiveLicensorsAndBrandsChange($event)"
                        [checked]="includeInactiveLicensorsAndBrands" ><!---->
                        <label class="custom-control-label text-break align-middle" for="includeInactiveLicensorsAndBrandsId"><small>Include Inactive Brands & Licensors</small></label>
                    </div>

                </div>
            </div>
            <div class="col-sm col-lg col-md mt-1">
                <section class="gallery-block cards-gallery">
                    <div class="container-fluid">
                    <div><!--*ngIf="!embedded"-->
                        <div class="row d-flex justify-content-center" *ngIf="!embedded">
                            <h3 class="d-inline">SKU Tracker</h3>
                            <!--<button type="button"
                                [routerLink]="['/createProduct/create/'+serachCriteria.licensorId+'/'+serachCriteria.brandId+'/undefined']"
                                class="btn btn-circle btn-circle-md d-inline ml-4" *ngIf="userAccess?.canCreateProduct"
                                matTooltip="Create New Product" matTooltipPosition="right" matTooltipClass="bg-dark">
                                <i class="fas fa-plus fa-lg text-light"></i>
                            </button>-->
                        </div>
                        <hr *ngIf="!embedded">
                        <div class="row">
                            <div class="col-sm-6">
                                <input type="search" name="SkuCode" [(ngModel)]="searchStringForSearch" class="form-control " (keyup.enter)="searchBtn.click()" 
                                    id="inlineFormInputGroupUsername" placeholder="Search by attributes" #SkuCode   (input)="searchInput($event)">
                            </div>
                            <div >
                                <button class="btn  {{!(searchStringForSearch.length>0)?'bg-dark text-white':'bg-dark text-white'}} btn-block " (click)="search($event)" [disabled]="!(searchStringForSearch.length>0)"
                                   #searchBtn type="button">Search</button>
                            </div>
                            <div class="ml-2 d-flex align-items-center" >
                                <a class="badge badge-pill bg-dark" matTooltip="For all products &#13;&#8226; with Color - Blue&#13; Search : BLUE &#13;&#8226; with Color - Blue & Garment Type - Tee&#13; Search : BLUE TEE &#13;&#8226; with Color - Blue & Garment Type - Tee & Category - Baby  &#13; Search : BLUE TEE BABY" 
                                matTooltipPosition="right" matTooltipClass="allow-cr extended-tooltip"><i class="fas fa-info" ></i></a>
                            </div>
                            <div class="ml-2 d-flex align-items-center">
                                <button type="button" class="f-13 btn btn-link" style="color: tomato;" (click)="showHideFilter()" #showHideBtn *ngIf="!embedded">{{filterVisible?'Hide ':'Show '}} Filter</button>
                            </div>
                            <div class="col-sm-4">
                              <label class="col-sm-4 text-right">Sort By:</label>
                              <select class="form-control form-control-sm custom-select col-sm-5 mr-1" id="sortBy" name="sortBy" [(ngModel)]="selectedSortByName" (change)="changeSortCriteria()">
                                 <option *ngFor="let sortColumn of sortColumns" [ngValue]="sortColumn.sortByName">
                                    {{sortColumn.columnName}}</option>
                              </select>
                              <span (click)="sort('ASC')" *ngIf="selectedSortByName && sortMode==='ASC'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1" style="color:black; vertical-align: middle;"></i></span>
                              <span (click)="sort('DESC')" *ngIf="selectedSortByName && sortMode==='ASC'"><i class="fas fa-sort-amount-down fa-lg mr-1" style="color:grey; vertical-align: middle;"></i></span>
                              <span (click)="sort('ASC')" *ngIf="selectedSortByName && sortMode==='DESC'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1" style="color:grey; vertical-align: middle;"></i></span>
                              <span (click)="sort('DESC')" *ngIf="selectedSortByName && sortMode==='DESC'"><i class="fas fa-sort-amount-down fa-lg" style="color:black; vertical-align: middle;"></i></span>
                           </div>
                            <div class="col-sm-6 mt-1">
                                <p class="mx-2">Showing {{productList?.length}} of {{totalSearchCount}} Product(s)<small class="text-muted">&nbsp;&nbsp;&nbsp;Enhance search criteria for more products.</small></p>
                            </div>
                        </div>
                    </div>

                        <div class="row" ><!--[@listAnimation]="filteredProductList.length"-->
                            <!--SKU Starts-->
                            <table id="reportTable"    class="table table-sm table-bordered table-condensed hover">
                                <thead class="thead-dark">
                                   <tr class="f-16">
                                     <th style="white-space: nowrap;width: 1%;">&nbsp;</th>
                                      <th style="width: auto;"  class=" align-middle text-center text-nowrap">Image</th>
                                      <th style="width: auto;"  class=" align-middle text-center text-nowrap" *ngFor="let header of headers">{{header}}<!--<span  style="cursor: pointer;"><i class="fas fa-filter fa-sm ml-1"></i></span>--></th>
                                     
                                      <!--<th style="white-space: nowrap;width: 1%;">&nbsp;</th>-->
                                   </tr>
                                </thead>
                                <tbody>
                                    <ng-container class="row ml-2" *ngFor="let product of filteredProductList;index as index">
                                        
                                   <tr class="f-13" id="imageCard_{{index}}">
                                    <td style="white-space: nowrap;width: 1%;"   ><!--[style]="'border-left-style: solid;border-left-width: thick;border-left-color:'+approvalStatusColor(product.approvalStatus)"-->
                                        <div class="d-flex flex-column">
                                        <button style="display: none;" (click)="skuSelected(product,bt)" #bt class="btn btn-dark btn-sm collapseElem collapsed" type="button" data-toggle="collapse" [attr.data-target]="'#Collapse'+index" aria-expanded="false" [attr.aria-controls]="'Collapse'+index"><i class="fas fa-angle-down"></i></button>
                                        <button (click)="bt.click()" *ngIf="product.skuTrackerExpanded && !product.skuTrackerEditable" class="btn btn-dark btn-sm" type="button"><i class="fas fa-minus"></i></button>
                                        <button (click)="bt.click()" *ngIf="!product.skuTrackerExpanded && !product.skuTrackerEditable" class="btn btn-dark btn-sm" type="button"><i class="fas fa-chevron-down"></i></button>
                                        <button (click)="skuEdit(product,bt,index)" *ngIf="!product.skuTrackerEditable && userAccess?.canEditSkuTracker" class="btn btn-secondary btn-sm mt-2" type="button"><i class="fas fa-pen"></i></button>
                                        <button (click)="skuSave(product)" [disabled]="!nameCtrl.valid" *ngIf="product.skuTrackerEditable" class="btn btn-info btn-sm" type="button"><i class="fas fa-save"></i></button>
                                        <button (click)="skuEditCancel(product,index)" *ngIf="product.skuTrackerEditable" class="btn btn-dark btn-sm mt-2" type="button"><i class="fas fa-times"></i></button>
                                        
                                        </div>
                                    </td>
                                      <td style="width: 60px;" class=" align-middle bg-color-{{approvalStatusColor(product.approvalStatus)}}">
                                            <img [src]="product.productImages[0]?.thumbnailSignedUrl" 
                                                class="img-thumbnail img-fluid zoom-4 "
                                                style="display: block;  width:60px;  height:60px; min-width: 60px;"
                                                [style.border-color]="'cadetblue'"
                                                (error)="handleThumbnailError(product.productImages[0],$event)"
                                               /><!-- matTooltip="&#8226; File Name : {{product.productImages[0]?.originalFileName}} &#13;&#8226; Uploaded On : {{product.productImages[0]?.uploadDate | date:'medium'}}" 
                                                matTooltipPosition="below" matTooltipClass="bg-dark allow-cr f-10"-->
                                    </td>
                                      <td style="width: auto;" class="align-middle text-left text-nowrap bg-color-{{approvalStatusColor(product.approvalStatus)}}">
                                        <div class="d-flex  align-items-center ml-2">
                                            <div (click)="changePriority(product)" class="d-flex justify-content-center align-items-center"  *ngIf="product.id"
                                                [matTooltip]="product.priority?'Remove priority':'Mark as priority'" matTooltipPosition="right" [matTooltipDisabled]="!userAccess?.canChangeProductPriority" matTooltipClass="bg-tomato"
                                                                    style="display: block; height:1rem;width: 1rem;">
                                                                <span *ngIf="!product.priority && userAccess?.canChangeProductPriority">
                                                                    <i class="far fa-star fa-lg"
                                                                        style="color: #6c757d24;" 
                                                                        onmouseover="this.style.color='tomato'" 
                                                                        onmouseout="this.style.color='#6c757d24'">
                                                                    </i>
                                                                </span>
                                                                    <span *ngIf="product.priority">
                                                                        <i class="fas fa-star fa-lg" style="color: tomato;"  >
                                                                        </i>
                                                                    </span>
                                                </div>
                                            <a type="button" class="f-13 btn btn-link showLink" style="color: black;" matTooltip="View product" matTooltipPosition="below" matTooltipClass="bg-dark"
                                            (click)="navigateToProduct(index,product)" [routerLink]="['/product/view/'+product.licensorId+'/'+product.brandId+'/'+product.id]">
                                            {{product.sku}}
                                                <i class="fas fa-external-link-alt ml-1 showLinkIcon"></i>   
                                            </a> 
                                        </div><!--[routerLink]="['/product/view/'+product.licensorId+'/'+product.brandId+'/'+product.id]"-->
                                    </td>
                                      
                                      <td style="width: auto;" class="align-middle text-left pl-3 text-nowrap" *ngIf="!product.skuTrackerEditable" class="bg-color-{{approvalStatusColor(product.approvalStatus)}}">{{product.approvalStatus}}</td>
                                      <td style="width: auto;" class="align-middle" *ngIf="product.skuTrackerEditable"><div>
                                            <select style="width: auto;" id="as{{index}}" name="as{{index}}" class="f-13 form-control form-control-sm custom-select" [(ngModel)]="product.approvalStatus"
                                                             name="approvalStatusVar" #approvalStatusVar="ngModel" required (change)="statusChanged(product,$event)"
                                                             [ngClass]="{'is-invalid':approvalStatusVar.touched && approvalStatusVar.errors}"
                                                             [disabled]="!nameCtrl.valid"
                                                             >
                                                             <option *ngFor="let approvalStatus1 of approvalStatusList" [ngValue]="approvalStatus1">
                                                                                                 {{approvalStatus1}}</option>
                                            </select>
                                            </div>
                                      </td><!--[style]="'border-left-width: thick;border-left-color:'+approvalStatusColor(product.approvalStatus)"-->
                                      <td style="width: auto;" class="align-middle text-left pl-3 text-nowrap">
                                        {{product.statusDate | date:'mediumDate'}}
                                        <!--<mat-form-field color="accent" appearance="fill">
                                        <mat-label>Status Date</mat-label>
                                        <input matInput [matDatepicker]="picker2">
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2 color="primary"></mat-datepicker>
                                      </mat-form-field>-->
                                    </td>
                                    <td style="width: auto;" class="align-middle text-left pl-3 text-nowrap showLink">
                                        <a  style="color: #333;" class="text-decoration-none" target="_blank" [routerLink]="['/licensor/view/'+product.licensorId]"  matTooltipPosition="below" matTooltipClass="bg-dark" matTooltip="View Licensor">
                                            {{product.licensorName}}
                                            <i class="fas fa-external-link-alt ml-1 showLinkIcon"></i>   
                                        </a>
                                    </td>
                                      <td style="width: auto;" class="align-middle text-left pl-3 text-nowrap showLink">
                                        <a  style="color: #333;" class="text-decoration-none" target="_blank" [routerLink]="['/brand/view/'+product.brandId]"  matTooltipPosition="below" matTooltipClass="bg-dark" matTooltip="View Brand">
                                            {{product.brandName}}
                                            <i class="fas fa-external-link-alt ml-1 showLinkIcon"></i>   
                                         </a>
                                    </td>
                                      <td style="width: auto;" class="align-middle text-left pl-3   ">{{product.description}}</td>
                                      
                                    <!--<td style="white-space: nowrap;width: 1%;" class="align-middle"  >
                                        <div class="d-flex align-items-center justify-content-center">
                                            <span (click)="skuEditCancel(product,index)"><i class="fas fa-search-minus fa-lg text-secondary"></i></span>
                                        </div>
                                    </td>-->
                                
                                   </tr>
                                   <!--Details Starts-->
                                   
                                   <tr class="shadow-lg" >
                                       <td colspan="8" style="padding: 0rem;">
                                   <div class="mx-1" ngModelGroup="MG{{index}}"  #nameCtrl="ngModelGroup">
                                            <div class="collapse multi-collapse" [attr.id]="'Collapse'+index">
                                                <!--<mat-tab-group>
                                                    <mat-tab>
                                                       <ng-template mat-tab-label>
                                                          <h6>SKU Approval Stages</h6>
                                                       </ng-template>
                                                       <div class="table-responsive">
                                                        <table id="detailsTable" class="mt-1 table table-sm table-bordered table-striped table-condensed hover">
                                                            <thead>
                                                               <tr class="bg-dark text-white">
                                                                <th style="white-space: nowrap;width: 1%;">&nbsp;</th>
                                                                  <th style="width: auto;" class="align-middle text-center text-nowrap" *ngFor="let submissionDetailHeader of submissionDetailHeaders">{{submissionDetailHeader}}</th>
                                                               </tr>
                                                            </thead>
                                                            <tbody class="bg-light">
                                                                <ng-container class="row ml-2 mt-1" *ngFor="let sd of product.statusDetails;index as index">
                                                                <tr>
                                                                  <td style="white-space: nowrap;width: 1%;;" class="align-middle text-center"><i class="fas fa-play" style="color: tomato;" *ngIf="sd.current"></i></td>
                                                                  <td style="width: auto;" class="align-middle text-center text-nowrap">{{sd.sku}}</td>
                                                                  <td style="width: auto;" class="align-middle text-center text-nowrap">{{sd.status}}</td>
                                                                  <td style="width: auto;" class="align-middle text-center text-nowrap">{{sd.statusDate | date:'mediumDate'}}</td>
                                                                  <td style="width: auto;" class="align-middle text-center text-nowrap">{{sd.createdBy}}</td>
                                                                  <td style="width: auto;" class="align-middle text-center text-wrap" *ngIf="!product.skuTrackerEditable">{{sd.licensorComments}}</td>
                                                                  <td style="width: auto;" class="align-middle text-center" *ngIf="product.skuTrackerEditable">
                                                                        <textarea name="licensoComments{{index}}" id="licensoComments{{index}}" [(ngModel)]="sd.licensorComments" rows="{{(sd.licensorComments?.length / 50)>1?(sd.licensorComments?.length / 50)+1:1}}"
                                                                        class="form-control form-control-sm f-13" [disabled]="!product.skuTrackerEditable" maxlength="2500"
                                                                            #licensoCommentsVar="ngModel" [required]="sharedService.licComReqMandAppStatus.includes(sd.status)"
                                                                            [ngClass]="{'is-invalid':licensoCommentsVar.touched && licensoCommentsVar.errors}">
                                                                        </textarea>
                                                                    </td>
                                                                    <td style="width: auto;" class="align-middle text-center text-wrap" *ngIf="!product.skuTrackerEditable">{{sd.philcosComments}}</td>
                                                                    <td style="width: auto;" class="align-middle text-center" *ngIf="product.skuTrackerEditable">
                                                                        <textarea name="philcosComments{{index}}" id="philcosComments{{index}}" [(ngModel)]="sd.philcosComments" rows="{{(sd.philcosComments?.length / 50)>1?(sd.philcosComments?.length / 50)+1:1}}"
                                                                        class="form-control form-control-sm f-13" [disabled]="!product.skuTrackerEditable" maxlength="2500"
                                                                            #philcosCommentsVar="ngModel"
                                                                            [ngClass]="{'is-invalid':philcosCommentsVar.touched && philcosCommentsVar.errors}">
                                                                        </textarea>
                                                                    </td>
                                                               </tr>
                                                            
                                                                </ng-container>
                                                               
                                                            </tbody>
                                                            </table>
                                                        </div>
                                                    </mat-tab>
                                                    <mat-tab>
                                                        <ng-template mat-tab-label>
                                                           <h6>Sample Worklfow</h6>
                                                        </ng-template>
                                                        <p>To Implement</p>
                                                     </mat-tab>
                                                </mat-tab-group>-->
                                                <app-sku-tracker [editable]="false"  [product]="product" [customerList]="customerList" [uniqueId]="'NON_MODAL'"></app-sku-tracker>
                                            
                                            </div>
                                        </div>
                                        </td>
                            
                                    </tr>
                                    
                                    <!--Details Ends-->
                                    </ng-container>
                                   
                                </tbody>
                             </table>
                            <!--SKU Ends-->
                        </div>
                        <hr>
                        <div class="row justify-content-center">
                            <button type="button"  id="showMoreId" class="btn bg-dark text-white" [disabled]="showMoreDisabled" *ngIf="filteredProductList?.length!==totalSearchCount" (click)="loadMoreProducts()">Show More</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    
    
    <!-- ******************************************************************************MODAL**********************************************************-->
        <!--GENERIC MODAL STARTS-->
        <!-- GENERIC Modal -->
        <div class="modal fade" id="{{type}}FilterModal" tabindex="-1" role="dialog" aria-labelledby="FilterModalTitle"
            aria-hidden="true" *ngFor="let type of filterTypes;index as typeIndex">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">{{filterTypesNames[typeIndex]}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <input class="form-control mb-2" #modalSearchInput
                            (keyup)="onKeyupModalFilter($event,modalSearchInput.value,type)" placeholder="Find..">
                        <div class="mb-2 d-flex justify-content-end">
                            <a (click)=" selectedAllForSearch(true,type)" class="btn btn-sm btn-warning bg-white">Select All</a>
                            <a (click)=" selectedAllForSearch(false,type)" class=" btn btn-sm btn-danger bg-white ml-2">Unselect All</a>
                        </div>
                        <div class="ml-2" *ngFor="let item of getFilteredMasterListForModal(type)">
                            <div class="row">
                                <label class="cb-container">
                                    <h6 class="ml-4 mt-1">{{item}}</h6>
                                    <input type="checkbox" (change)="selectedForSearch($event,item,type)"
                                        [checked]="isSelectedForSearch(item,type)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="m-1 d-flex">
                            <button type="button" class="btn bg-dark text-white btn-block" (click)="search()" 
                            data-dismiss="modal">Apply Filters</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- GENERIC MODAL ENDS-->
        <!--Status Change Modal-->
        <button id="statusChangeModalId" type="button" style="display: none;" data-toggle="modal" data-target="#skuStatusChange">Click</button>
        <app-status-change [editable]="'true'" [userAccess]="userAccess" [modalId]="'skuStatusChange'" [product]="productChange" (onCancel)="skuEditCancel($event,indexChange)" (onSave)="skuSave($event)" [approvalStatusList]="approvalStatusList" [existingStatus]="existingStatus"></app-status-change><!--(onSave)="saveAddEditContactDetail()"-->
        <!--Statsu Change Modal Ends-->
    </div>
</form>