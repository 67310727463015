<ng-container *ngIf="blankSummariesViews?.length>0">

   <mat-tab-group>
      <ng-container *ngFor="let bsv of blankSummariesViews; index as bsvIndex">
         <mat-tab>
            <ng-template mat-tab-label>
               <h6><span class="ml-2 font-weight-normal f-11">{{bsv.createdOn |date:'mediumDate'}}
                     <div *ngIf="!bsv.isCurrent">(Previous {{bsv.approvalReason}})</div>
                     <div *ngIf="bsv.isCurrent">(Current)</div>
                  </span></h6>
            </ng-template>

            <div class="overflow-hidden">

               <div class=" col-sm-6 d-flex justify-content-end mt-2">
                  <button type="button" class="btn btn-sm btn-warning" (click)="createBlankSummaryExcel(bsv)"><i class="fas fa-file-download"></i> &nbsp;Export</button>
               </div>

               <ng-container *ngFor="let bs of bsv.blankSummaries; index as bsIndex">
                  <div class="card card-header mt-2">
                     <h6 class="mb-0">{{bs.supplierName}} to {{bs.printerName}}</h6>
                  </div>
                  <hr class="mt-0 mb-0">
                  <div class=" card card-body pt-1">

                     <mat-tab-group>

                        <!-- START: Purchase Order Summary 
                        <mat-tab>
                           <ng-template mat-tab-label>
                              <h6><span class="ml-2 font-weight-normal f-11">PO Summary</span></h6>
                           </ng-template>

                           <div class="ml-2 mt-2 overflow-hidden">
                              <app-purchase-order [summaryId]="bs.id" [summaryType]="'BLANKS_SUPPLIER'" [allowPoCreation]="bs.latest" (event)="purchaseOrderSummaryEventHandler($event)">
                              </app-purchase-order>
                           </div>
                        </mat-tab>
                        END: Purchase Order Summary -->

                        <!-- START: Blanks Summary -->
                        <mat-tab>
                           <ng-template mat-tab-label>
                              <h6><span class="ml-2 font-weight-normal f-11">Summary</span></h6>
                           </ng-template>
                           <div class="ml-2 mt-2 overflow-hidden">
                              <table class=" f-11 table table-sm table-bordered " style="width: 50% !important;" [style]="!bsv.isCurrent?'outline: none;border: 3px solid red;':''"
                                 [style.opacity]="!bsv.isCurrent?'0.7':'10'">
                                 <thead class="thead-dark">
                                    <tr class="text-center">
                                       <th>Blank Code</th>
                                       <th>Size</th>
                                       <th>Qty</th>
                                       <th>Cost</th>
                                       <th>Currency</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <ng-container *ngFor="let bsd of bs.blankSummaryDetail.blankSizeDetails">
                                       <tr class="text-center">
                                          <td>{{bsd.blankCode}}</td>
                                          <td>{{bsd.overrideSize}}</td>
                                          <td>{{bsd.qty}}</td>
                                          <td>{{bsd.cost}}</td>
                                          <td>{{bs.blankSummaryDetail.supplierCurrency}}</td>
                                       </tr>
                                    </ng-container>
                                    <tr class="text-center f-12 text-white" style="background-color: #abb5c0;">
                                       <td colspan="2" class="text-white">Total</td>
                                       <td>{{bs.blankSummaryDetail.totalQty}}</td>
                                       <td>{{bs.blankSummaryDetail.totalCost}}</td>
                                       <td>{{bs.blankSummaryDetail.supplierCurrency}}</td>

                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </mat-tab>
                        <!-- END: Blanks Summary -->

                     </mat-tab-group>
                  </div>
               </ng-container>
            </div>
         </mat-tab>
      </ng-container>
   </mat-tab-group>

</ng-container>