export class Printer {
   id: string;
   name: string;
   status: boolean;
   notes: string;
   chargeBackEmails:string;
   chargeBackEmailAddressedTo:string;
   countryCode:string;
	currency:string;
	shipVia:string;
	exFactoryDaysAdjust:number;
   lastModifiedDate:Date;
	lastModifiedBy:string;
   vendorInfoList:VendorInfo[]=[];
}

export class VendorInfo {
   id: string;
   parentId:string;
   vendorCode: string;
   companyCode: string;
   active:boolean;
   lastModifiedDate:Date;
	lastModifiedBy:string;
   //vendorDetails:any;
}

