import { Injectable } from '@angular/core';
import { ProgressDetails } from '../model/product.model';


@Injectable({
  providedIn: 'root'
})

export class LoaderService {
  showLoader = false;
  showLoaderElemArr=[];
  hideLoaderElemArr=[];
  //showProgress =false;
  filesUploading:ProgressDetails[]=[];
  //filesUploaded:ProgressDetails[]=[];
  //filesUploadFailed:ProgressDetails[]=[];
  showProgressArea:boolean=true;
  constructor() {}

  show() {
    this.showLoaderElemArr.push({});
    if(this.showLoaderElemArr.length>0){
    this.showLoader = true;
    }else{
      this.showLoader = false;
    }
  }

  hide() {
    if(this.showLoaderElemArr.length>0){
      this.showLoaderElemArr.pop();
    }
    if(this.showLoaderElemArr.length>0){
    this.showLoader = true;
    }else{
      this.showLoader = false;
    }
  }

  showProgressBar(parentId:string,fileName:string,id:string){
    //
    let att:ProgressDetails=new ProgressDetails;
    att.fileName=fileName;
    att.id=id;
    att.progress=0;
    att.parentId=parentId;
    this.filesUploading.push(att);
  }
  hideProgressBar(parentId:string,fileName:string,id:string){
    //
    //this.showProgress = false;
    this.filesUploading=this.filesUploading.filter(x=> x.id!=id);
  }
  updateProgress(parentId:string,fileName:string,id:string,progress:number){
    //
    this.filesUploading.forEach(element => {
      if(element.id===id){
        let previous=element.progress;
        element.progress=progress;
        let newValue=element.progress;
        //(previous+" --> "+ newValue)
      }
    });
  }

  toggleProgressBar(){
    this.showProgressArea=!this.showProgressArea;
 }
 
}
