import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { PdfForm } from '../model/pdf-form.model';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PdfFormService {
 
  readonly rooturl = environment.apiEndPoint;
  constructor(private http: HttpClient) {
  }

  loadPdfForms(){
    return this.http.get(this.rooturl + "/pdfForm/list");
  }
  loadPdfForm(pdfFormId: string) {
    return this.http.get(this.rooturl + "/pdfForm/load/"+pdfFormId);
  }
  parsePdfForm(pdfForm: PdfForm) {
    return this.http.post(this.rooturl + "/pdfForm/parseFile",pdfForm);
  }
  createPdfForm(pdfForm: PdfForm) {
    return this.http.post(this.rooturl + "/pdfForm/create",pdfForm);
  }
  updatePdfForm(pdfForm: PdfForm) {
    return this.http.post(this.rooturl + "/pdfForm/update",pdfForm);
  }
  isPdfFormAvailable(category:string,subCategory:string,licensorId:string,brandId:string,productId:string){
    return this.http.get(this.rooturl + "/pdfForm/isPdfFormAvailable/"+category+"/"+subCategory+"/"+licensorId+"/"+brandId+"/"+productId);
  }

  uploadFiledWithProgress(pdfFormId: string, file: File,operation:string) {
    //
    const fd = new FormData();
    fd.append('file', file, file.name);
    //fd.append('brand', JSON.stringify(brand));
    return this.http.post(this.rooturl + "/pdfForm/"+operation+"/"+pdfFormId, fd);
 }

 deletePdfForms(pdfFormId: string) {
  return this.http.get(this.rooturl + "/pdfForm/delete/"+pdfFormId);
}
}
