import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { OrderDetailSizeQuantity } from 'src/app/shared/model/sales/order-detail.model';

@Component({
  selector: 'app-size-selection-modal',
  templateUrl: './size-selection-modal.component.html',
  styleUrls: ['./size-selection-modal.component.css']
})
export class SizeSelectionModalComponent implements OnInit {


  ordeDetailSizeQuantityListForModal: OrderDetailSizeQuantity[]=[];
  /*message: string;
  textInput:string;
  allowDiscard:boolean=false;*/
  constructor(public dialogRef: MatDialogRef<SizeSelectionModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private toastrService:ToastrService) {
    if(data.ordeDetailSizeQuantityListForModal){
      this.ordeDetailSizeQuantityListForModal=data.ordeDetailSizeQuantityListForModal;
    }
   }
  ngOnInit(): void {
    this.dialogRef.updateSize('30%');
  }

  sizeSelected() {
    if(this.ordeDetailSizeQuantityListForModal && this.ordeDetailSizeQuantityListForModal.length>0){
      let list=this.ordeDetailSizeQuantityListForModal.filter(x=>x.selected)
      if(list.length===0){
        this.toastrService.error('Please Select atleast one size.');
      }else{
        this.dialogRef.close(true);
      }
    }else{
      this.dialogRef.close(false);
    }
  }
  

  discardModalWithNoAction(){
    this.dialogRef.close(false);
  }
}
