<div class="container">
   <div class="row mt-2 d-flex justify-content-center">
      <h2 class="d-inline">Brands</h2>
      <button type="button" [routerLink]="['/brand/create/null']" class="btn btn-circle btn-circle-md d-inline ml-4"
         *ngIf="userAccess?.canCreateBrand"><i class="fas fa-plus fa-lg text-light"></i></button>
   </div>

   <hr>
   <div class="row" *ngIf="hideBrandFilter!=='true'">
      <div class="col-sm-6">
         <input id='brandFilterInput' class="form-control mb-2" #search placeholder="Filter by Brand Name/SKU..."
            (keyup)="onKeyupBrandFilter($event,search.value)">
      </div>
      <div class="col-sm-6 mt-2">
         Found {{filteredBrandList.length}}
         {{search.value.length>0? 'Brands found for "'+search.value+'"' : 'Brands'}}
      </div>
   </div>


   <!--<button class="btn  btn-circle btn-circle-sm d-inline" (click)="deletebrand(brand)" *ngIf="editable">
      <i class="fas fa-trash text-light"></i>
   </button>-->


   <!-- GROUPED DISPLAY -->

   <div *ngFor="let groupedBrands of groupedBrandList;index as l">
      <h5> {{groupedBrands.key}} </h5>
      <hr style="margin-top: 0rem">
      <div class="row gallery-block cards-gallery mt-4">
         <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-4" *ngFor="let brand of groupedBrands.brands;index as i">
            <div class=" card border-0 transform-on-hover h-100" [style.background-color]="brand.licensorColor">
               <a [routerLink]="['/brand/view/'+brand.id]">
                  <div class="card-body" style="padding: 0.25rem;">
                     <div class=" ml-1 mr-1">
                        <div class=" d-flex justify-content-left">
                           <button class="btn bnt-lg text-white bg-dark" *ngIf="brand.brandStatus">{{brand.skuCode}}</button>
                           <button class="btn bnt-lg text-white bg-danger" *ngIf="!brand.brandStatus">{{brand.skuCode}}</button>
                           <!--{{brand.brandName.charAt(0).toUpperCase()}}-->
                        </div>
                        <div class="mb-3">
                           <h5 class="text-truncate ml-2 mr-2 card-text justify-content-center">
                              {{brand.brandName}}
                           </h5>
                        </div>
                        <!--<div>SKU Code: {{brand.skuCode}}</div>-->
                        <div class="text-truncate">Licensor: {{brand.licensorName}}</div>
                     </div>
                     <p class="card-text">{{brand.brandStatus?'Active':'Inactive'}}</p>
                     <!--<button class="btn  btn-circle btn-circle-sm d-inline" (click)="deletebrand(brand)" *ngIf="editable">
                                <i class="fas fa-trash text-light"></i>
                        </button>-->
                  </div>
               </a>
            </div>
         </div>
      </div>
   </div>


</div>

<div class="floatBtn d-flex flex-column">
   <button type="button" class="btn btn-circle bg-secondary mb-2" matTooltip="Scroll To Top" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'});"  matTooltipPosition="left" matTooltipClass="bg-dark">
      <i class="fas fa-arrow-up fa-2x" style="color:white"></i>
   </button>
</div>