import { Component, OnInit } from '@angular/core';
import { LicensorService } from 'src/app/shared/service/licensor.service';
import { Licensor } from 'src/app/shared/model/licensor.model';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ServiceStatus } from 'src/app/shared/model/service-status.model';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
   selector: 'app-licensor-list',
   templateUrl: './licensor-list.component.html',
   styleUrls: ['./licensor-list.component.css']
})

export class LicensorListComponent implements OnInit {

   constructor(private sharedService: SharedService,private authService:AuthService, private service: LicensorService,private toastrService: ToastrService) { }

   licensorList: Licensor[] = null;
   filteredLicensorList: Licensor[] = null;
   groupedLicensorList: any;

   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   hideBrandFilter = "true";

   ngOnInit() {
      this.handleSubscriptions();
      this.loadLicensors();
      window.scrollTo(0, 0);
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
   }

   loadLicensors() {
      this.service.loadLicensors().subscribe((res: Licensor[]) => {
         this.licensorList = res;
         this.filteredLicensorList = this.licensorList;
         this.filteredLicensorList=this.sharedService.sortListByPropertyName(this.filteredLicensorList,'licensorName');
         this.groupList();
      })
   }

   onKeyupLicensorFilter(event, value) {
      var eventData = { event, value };
      this.authService.setFilterEvent(eventData);
      if (event.key !== 'Shift') {
         if (value.trim().length > 0) {
            this.filteredLicensorList = this.licensorList.filter(items => items.licensorName.toUpperCase().indexOf(value.toUpperCase()) > -1);
         } else {
            this.filteredLicensorList = this.licensorList;
         }
      }
      this.groupList();
   }

   /**
    * This method groups the list.
    */
   groupList() {
      let grouped = this.filteredLicensorList.reduce((groups, licensor) => {
         const letter = licensor.licensorName.charAt(0).toUpperCase();
         groups[letter] = groups[letter] || [];
         groups[letter].push(licensor);
         return groups;
      },
         {}
      );
      let groupedList = Object.keys(grouped).map(key => ({ key, licensors: grouped[key] }));
      //
      //
      this.groupedLicensorList = groupedList;
   }

   deleteLincesor(licensor:Licensor){
      if(confirm('Are you sure you want to delete the Licensor?\n1. All brand and products under this licensor will be deleted.')){
      this.service.deleteLicensor(licensor.id).subscribe((res) => {
         let serviceStatus: ServiceStatus = JSON.parse(JSON.stringify(res));
         let status: ServiceStatus = res as ServiceStatus;
         if (serviceStatus.status === 'SUCCESS') {
            this.loadLicensors();
         } else {
            this.toastrService.error(serviceStatus.message);
         }
         this.toastrService.success(serviceStatus.message);
      }, (err) => {
         this.toastrService.error(JSON.stringify(err));
         console.error('Linceosr Delete Error : ' + JSON.stringify(err));
      });
   }
   }

}
