import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SearchCriteriaBuilder } from 'src/app/shared/model/common/search-criteria-builder.model';

@Injectable({
   providedIn: 'root'
})

export class FileClosureService {

   constructor(private http: HttpClient) { }

   readonly rooturl = environment.apiEndPoint;

   createFileClosureSummaryExcel(searchCriteria: SearchCriteriaBuilder) {
      return this.http.post(this.rooturl + '/fileClosure/export/excel', searchCriteria, { responseType: 'blob' });
   }

   getFileClosureSummaryView(salesOrderId: string) {
      return this.http.post(this.rooturl + '/fileClosure/summary/' + salesOrderId, null);
   }
}
