<div class="container" >


  <div class="row gallery-block cards-gallery mt-4" style="height: auto;">

    <div class="col-lg-6 mb-4 mt-4" *ngIf="userAccess?.canViewLicensorsSection">
        <a [routerLink]="['/licensor-list']" >
        <div class="card h-100 justify-content-center border-0 transform-on-hover h-color-0">
            <div>
                <div class="card-body">
                    <h5>Licensor</h5>
                    <h5>Management</h5>
                </div>
            </div>
        </div>
        </a>
    </div>

      <div class="col-lg-6 mb-4 mt-4" *ngIf="userAccess?.canViewBrandsSection">
        <a [routerLink]="['/brand-list']" >
          <div class="card h-100 justify-content-center border-0 transform-on-hover h-color-1">
            <div>
                <div class="card-body" >
                    <h5>Brand</h5>
                    <h5>Management</h5>
                </div>
            </div>
          </div>
        </a>
      </div>


      <div class="col-lg-6 mb-4 mt-4" *ngIf="userAccess?.canViewProductsSection">
        <a [routerLink]="['/product-search']" >
          <div class="card border-0 justify-content-center transform-on-hover h-100 h-color-2" >
            <div>
              <div class="card-body" >
                  <h5>Product</h5>
                  <h5>Management</h5>
              </div>
            </div>
          </div>
        </a>
      </div>


    <div class="col-lg-6 mb-4 mt-4" *ngIf="userAccess?.canViewUsersSection">
      <a [routerLink]="['/user/list']" >
        <div class=" card border-0 justify-content-center transform-on-hover h-100 h-color-3">
          <div>
            <div class="card-body" >
                <h5>User</h5>
                <h5>Management</h5>
            </div>
          </div>
        </div>
      </a>
    </div>


  </div>   




</div>