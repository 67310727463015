import { Component, Input, OnInit } from '@angular/core';
import { Brand } from 'src/app/shared/model/brand.model';
import { Product, ProductAttachment, SubmissionSheetVariableDetails } from 'src/app/shared/model/product.model';
import { SharedService } from 'src/app/shared/service/shared.service';

@Component({
  selector: 'app-image-selection',
  templateUrl: './image-selection.component.html',
  styleUrls: ['./image-selection.component.css']
})
export class ImageSelectionComponent implements OnInit {

  @Input() variable:SubmissionSheetVariableDetails;
  @Input() product:Product;
  @Input() brand:Brand;
  @Input() productImages:ProductAttachment[]=[];
  @Input() imageWidthPx:number=60;
  @Input() imageHeightPx:number=60;
  @Input() modal:boolean=false;
  selectionBorderWidth:string='1.5px';
  constructor(private sharedService:SharedService) {
    
   }

  ngOnInit(): void {
    if(this.modal===true){
      this.selectionBorderWidth='3px';
    }
  }

  selectVariableImage(attachment:ProductAttachment, variable:SubmissionSheetVariableDetails, event?) {
    if(attachment.fileSignedUrl== variable.fileSignedUrl){
      variable.fileSignedUrl = 'blank';
      variable.thumbnailSignedUrl = 'blank';
      variable.productAttachmentId= 'blank';
    }else{
    this.sharedService.setSelectedVariableImage(attachment,variable);
    }
  }

  
  handleThumbnailError(productAttachment: ProductAttachment, event) {
    if (isNaN(productAttachment.thumbnailErrorCount)) {
      productAttachment.thumbnailErrorCount = 0;
    }
    event.target.src = productAttachment.fileSignedUrl;
    var x: number = productAttachment.thumbnailErrorCount;
    productAttachment.thumbnailErrorCount = x + 1;
    if (productAttachment.thumbnailErrorCount < 2) {
      event.target.src = productAttachment.fileSignedUrl;
      productAttachment.thumbnailSignedUrl = productAttachment.fileSignedUrl;
    } else {
      event.target.src = "/assets/default-image.jpg"
    }

  }

  previewAttachment:ProductAttachment;
  previewImage:boolean;
  previewTop:string;
  previewLeft:string;
  previewPosition:string;
  preview(attachment:ProductAttachment,entering:boolean,event?:any){
    let eventMouse=event as MouseEvent;
    this.previewImage=entering;
    if(entering){
      if(eventMouse.clientY-370>10){
        this.previewTop=eventMouse.clientY-350+'px';
      }else{
        this.previewTop=eventMouse.clientY+10+'px';
      }
     
      this.previewLeft=eventMouse.clientX+10+'px';
    this.previewAttachment=attachment;
    }
  }
}
