import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { DataTablesModule } from 'angular-datatables';
import { AngularEditorModule } from '@kolkov/angular-editor';

// Navbar
import { NavbarComponent } from './navbar/navbar.component';
// Footer
import { FooterComponent } from './footer/footer.component'
// Auth
import { LoginComponent } from './auth/login/login.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
// Home
import { HomeComponent } from './home/home.component';
// Admin ==> User Management
import { UserListComponent } from './admin/user-management/user-list/user-list.component';
import { UserComponent } from './admin/user-management/user/user.component';
// Licensor
import { LicensorListComponent } from './licensor-management/licensor-list/licensor-list.component';
import { LicensorComponent } from './licensor-management/licensor/licensor.component';
// Brand
import { BrandListComponent } from './brand-management/brand-list/brand-list.component';
import { BrandComponent } from './brand-management/brand/brand.component';
// Product
import { ProductComponent } from './product-management/product/product.component';
import { ProductListComponent } from './product-management/product-list/product-list.component';

import { CatalogViewComponent } from './product-catalog-management/catalog-view/catalog-view.component';

import { DragDropDirective } from './directives/drag-drop.directive';

import { httpInterceptorProviders } from './interceptors';
import {OwlModule} from 'ngx-owl-carousel';
import { DragDropModule} from '@angular/cdk/drag-drop';

import { ReplacePipe } from './replace.pipe';
import { ColorPickerModule } from 'ngx-color-picker';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTabsModule} from '@angular/material/tabs';
import { MatDatepickerModule} from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTreeModule } from '@angular/material/tree';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import { DateValueAccessorModule} from 'angular-date-value-accessor';
import { EditorModule} from 'primeng/editor';
import { MetadataComponent } from './metadata/metadata.component';
import { RolePermissionComponent } from './admin/user-management/role-permission/role-permission.component';
import { RoleListComponent } from './admin/user-management/role-list/role-list.component';
import { ProductImageCardComponent } from './product-management/product-image-card/product-image-card.component';
import { AttachmentOperationsComponent } from './common/attachment-operations/attachment-operations.component';
import { SubmissionSheetComponent } from './product-management/submission-sheet/submission-sheet.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BasicReportComponent } from './report-management/basic-report/basic-report.component';
import { AddressComponent } from './profile-management/address/address.component';
import { PhoneComponent } from './profile-management/phone/phone.component';
import { EmailComponent } from './profile-management/email/email.component';
import { ProfileComponent } from './profile-management/profile/profile.component';
import { ContactDetailsComponent } from './profile-management/contact-details/contact-details.component';
import { ContactDetailsModalComponent } from './profile-management/contact-details-modal/contact-details-modal.component';
import { RedirectComponent } from './common/redirect/redirect.component';
import { SkuTrackerComponent } from './product-management/sku-tracker/sku-tracker.component';
import { PdfFormListComponent } from './pdf-form-management/pdf-form-list/pdf-form-list.component';
import { PdfFormComponent } from './pdf-form-management/pdf-form/pdf-form.component';
import { RightsOverviewComponent } from './brand-management/rights-overview/rights-overview.component';
import { RoyaltyOverviewComponent } from './licensor-management/royalty-overview/royalty-overview.component';
import { SkuTrackerListComponent } from './product-management/sku-tracker-list/sku-tracker-list.component';
import { StatusChangeComponent } from './product-management/status-change/status-change.component';
import { SkuTrackerDashboardComponent } from './dashboard-management/sku-tracker-dashboard/sku-tracker-dashboard.component';
import { CustomerListComponent } from './customer-management/customer-list/customer-list.component';
import { CustomerComponent } from './customer-management/customer/customer.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ComposeEmailComponent } from './email/compose-email/compose-email.component';
import { ImageWithPreviewComponent } from './shared/components/image-with-preview/image-with-preview.component';
import { PrinterMatrixComponent } from './product-management/printer/printer-matrix/printer-matrix.component';
import { SelectFromListDialogComponent } from './common/select-from-list-dialog/select-from-list-dialog.component';
import { SalesOrderComponent } from './sales-management/sales-order/sales-order.component';
import { SalesOrderListComponent } from './sales-management/sales-order-list/sales-order-list.component';
import { BlanksListComponent } from './blanks-management/blanks-list/blanks-list/blanks-list.component';
import { BlanksComponent } from './blanks-management/blanks/blanks/blanks.component';
import { SalesDashboardComponent } from './dashboard-management/sales-dashboard/sales-dashboard.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { AttachmentViewerDialogComponent } from './common/attachment-viewer-dialog/attachment-viewer-dialog.component';
import { SearchComponent } from './common/search/search.component';
import { ChangeTreeComponent } from './shared/components/change-tree/change-tree.component';
import { OrderDetailComponent } from './sales-management/order-detail/order-detail.component';
import { SizeSelectionModalComponent } from './sales-management/modal/size-selection-modal/size-selection-modal.component';
import { PackagingTypeSelectionModalComponent } from './sales-management/modal/packaging-type-selection-modal/packaging-type-selection-modal.component';
import { ProductionTrackerComponent } from './sales-management/fulfillment/production-tracker/production-tracker.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { PrinterInfoComponent } from './sales-management/fulfillment/printer-info/printer-info.component';
import { BlankInfoComponent } from './sales-management/fulfillment/blank-info/blank-info.component';
import { ImageSelectionComponent } from './product-management/submission-sheet/image-selection/image-selection.component';
import { EnvPipe } from './env.pipe.';
import { TransformPipe } from './transform.pipe';

import { TrimInfoComponent } from './sales-management/fulfillment/trim-info/trim-info.component';
import { ProductionTrackerListComponent } from './sales-management/fulfillment/production-tracker-list/production-tracker-list.component';
import { OrderDetailInfoComponent } from './sales-management/fulfillment/order-detail-info/order-detail-info.component';
import { PrinterOrderDetailLinkModalComponent } from './sales-management/fulfillment/printer-order-detail-link-modal/printer-order-detail-link-modal.component';
import { TrackingLinkModalComponent } from './sales-management/fulfillment/tracking-link-modal/tracking-link-modal.component';
import { TrackingLinkComponent } from './sales-management/fulfillment/tracking-link/tracking-link.component';
import { RoutingInfoComponent } from './sales-management/fulfillment/routing-info/routing-info.component';
import { NotesDialogComponent } from './common/notes-dialog/notes-dialog.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FilterModalComponent } from './common/search/filter/filter-modal/filter-modal.component';
import { NotesComponent } from './common/notes/notes.component';
import { CustomMinDirective } from './directives/custom-min-validator.directive';
import { CustomMaxDirective } from './directives/custom-max-validator.directive';

import { AllocationSheetComponent } from './sales-management/costing/allocation/allocation-sheet/allocation-sheet.component';
import { CostingSheetComponent } from './sales-management/costing/costingSheet/costing-sheet/costing-sheet.component';
import { FormCustomValidationDirective } from './directives/form-custom-validator.directive';
import { BlanksSupplierComponent } from './vendor-management/blanks-supplier/blanks-supplier.component';
import { BlanksSupplierListComponent } from './vendor-management/blanks-supplier-list/blanks-supplier-list.component';
import { PrinterComponent } from './vendor-management/printer/printer.component';
import { PrinterListComponent } from './vendor-management/printer-list/printer-list.component';
import { BlankSummaryComponent } from './sales-management/costing/allocation/blank-summary/blank-summary.component';
import { PrinterSummaryComponent } from './sales-management/costing/allocation/printer-summary/printer-summary.component';
import { OrderDetailViewerDialogComponent } from './common/order-detail-viewer-dialog/order-detail-viewer-dialog.component';
import { AllocatedSalesOrderListComponent } from './sales-management/printer-portal/allocated-sales-order-list/allocated-sales-order-list.component';
import { AllocatedSalesOrderComponent } from './sales-management/printer-portal/allocated-sales-order/allocated-sales-order.component';
import { StyleDetailsComponent } from './sales-management/printer-portal/allocated-sales-order/style-details/style-details.component';
import { SubmissionDetailsComponent } from './product-management/approval-submission/submission-details/submission-details.component';
import { MultipleSubmissionComponent } from './product-management/approval-submission/multiple-submission/multiple-submission.component';
import { TaskListComponent } from './task-management/task-list/task-list.component';
import { SkuPrinterAssignmentModalComponent } from './sales-management/costing/allocation/sku-printer-assignment-modal/sku-printer-assignment-modal.component';
import { PrinterCapacityListComponent } from './sales-management/costing/allocation/printer-capacity/printer-capacity-list/printer-capacity-list.component';
import { FileClosureSummaryListComponent } from './sales-management/file-closure/file-closure-summary-list/file-closure-summary-list.component';
import { TrimsTrackerListComponent } from './sales-management/fulfillment/trims-tracker-list/trims-tracker-list.component';
import { RoyaltyMismatchListComponent } from './sales-management/costing/royalty/royalty-mismatch-list/royalty-mismatch-list.component';
import { ArtTrackerListComponent } from './product-management/art-tracker-list/art-tracker-list.component';
import { ProductMetadataComponent} from './metadata/product-metadata/product-metadata.component';
import { ChargeBackListComponent } from './accounts-management/charge-back-list/charge-back-list.component';
import { ChargeBackComponent } from './accounts-management/charge-back/charge-back.component';

import { CreateItemAuditComponent } from './sage-management/create-item-audit/create-item-audit.component';
import { DashboardComponent } from './dashboard-management/dashboard/dashboard.component';
import { DashletChartComponent } from './dashboard-management/dashlet-chart/dashlet-chart.component';
import { DashletCountComponent } from './dashboard-management/dashlet-count/dashlet-count.component';
import { DashletTableComponent } from './dashboard-management/dashlet-table/dashlet-table.component';
import { PreProTrackerListComponent } from './product-management/pre-pro-tracker-list/pre-pro-tracker-list.component';
import { PurchaseOrderComponent } from './sage-management/purchase-order/purchase-order.component';
import { LegalSuperScriptsDirective } from './directives/legal-super-scripts.directive';



export const jwtOptionsFactory = () => ({
  tokenGetter: () => localStorage.getItem('access_token'),
/*  whitelistedDomains: applicationconfig.jwtConfig.whitelistDomains,
  blacklistedRoutes: applicationconfig.jwtConfig.blacklistedRoutes,
  headerName: applicationconfig.jwtConfig.headerName,
  skipWhenExpired: applicationconfig.jwtConfig.skipTokenWhenExpired
*/
  whitelistedDomains: environment.jwtConfig.whitelistDomains,
  blacklistedRoutes: environment.jwtConfig.blacklistedRoutes,
  headerName: environment.jwtConfig.headerName,
  skipWhenExpired: environment.jwtConfig.skipTokenWhenExpired
});

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    HomeComponent,
    UserListComponent,
    UserComponent,
    LicensorListComponent,
    LicensorComponent,
    BrandListComponent,
    BrandComponent,
    ProductListComponent,
    ProductComponent,
    CatalogViewComponent,
    DragDropDirective,
    CustomMinDirective,
    CustomMaxDirective,
    FormCustomValidationDirective,
    ReplacePipe,
    EnvPipe,
    TransformPipe,
    SubmissionSheetComponent,
    ProductImageCardComponent,
    AttachmentOperationsComponent,
    MetadataComponent,
    RolePermissionComponent,
    RoleListComponent,
    BasicReportComponent,
    AddressComponent,
    PhoneComponent,
    EmailComponent,
    ProfileComponent,
    ContactDetailsComponent,
    ContactDetailsModalComponent,
    RedirectComponent,
    SkuTrackerComponent,
    PdfFormListComponent,
    PdfFormComponent,
    RightsOverviewComponent,
    RoyaltyOverviewComponent,
    SkuTrackerListComponent,
    StatusChangeComponent,
    SkuTrackerDashboardComponent,
    CustomerListComponent,
    CustomerComponent,
    ConfirmDialogComponent,
    ImageWithPreviewComponent,
    ComposeEmailComponent,
    PrinterMatrixComponent,
    SelectFromListDialogComponent,
    SalesOrderComponent,
    SalesOrderListComponent,
    BlanksListComponent,
    BlanksComponent,
    SalesDashboardComponent,
    AttachmentViewerDialogComponent,
    SearchComponent,
    ChangeTreeComponent,
    OrderDetailComponent,
    SizeSelectionModalComponent,
    PackagingTypeSelectionModalComponent,
    ProductionTrackerComponent,
    PrinterInfoComponent,
    BlankInfoComponent,
    ImageSelectionComponent,
    TrimInfoComponent,
    ProductionTrackerListComponent,
    OrderDetailInfoComponent,
    PrinterOrderDetailLinkModalComponent,
    TrackingLinkModalComponent,
    TrackingLinkComponent,
    RoutingInfoComponent,
    NotesDialogComponent,
    FilterModalComponent,
    NotesComponent,
    AllocationSheetComponent,
    CostingSheetComponent,
    BlanksSupplierComponent,
    BlanksSupplierListComponent,
    PrinterComponent,
    PrinterListComponent,
    BlankSummaryComponent,
    PrinterSummaryComponent,
    OrderDetailViewerDialogComponent,
    AllocatedSalesOrderComponent,
    AllocatedSalesOrderListComponent,
    StyleDetailsComponent,
    SubmissionDetailsComponent,
    MultipleSubmissionComponent,
    TaskListComponent,
    SkuPrinterAssignmentModalComponent,
    PrinterCapacityListComponent,
    FileClosureSummaryListComponent,
    TrimsTrackerListComponent,
    RoyaltyMismatchListComponent,
    ArtTrackerListComponent,
    ProductMetadataComponent,
    ChargeBackListComponent,
    ChargeBackComponent,
    CreateItemAuditComponent,
    DashboardComponent,
    DashletChartComponent,

    DashletCountComponent,
    DashletTableComponent,
    PreProTrackerListComponent,
    PurchaseOrderComponent,
    LegalSuperScriptsDirective

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    OwlModule,
    DragDropModule,
    BrowserAnimationsModule,
    ColorPickerModule,
    ToastContainerModule, 
    MatTooltipModule,
    MatTabsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDialogModule, 
    MatExpansionModule,
    MatButtonModule,
    MatFormFieldModule,
    MatTreeModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    DateValueAccessorModule,
    PdfViewerModule,
    DataTablesModule,
    EditorModule,
    GoogleChartsModule,
    ToastrModule.forRoot({preventDuplicates: true}),
    AngularEditorModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
          provide: JWT_OPTIONS,
          useFactory: jwtOptionsFactory//,
          //deps: [ConfigService]
      }
  })
  ],
  entryComponents: [
    ConfirmDialogComponent
  ],
  providers: [
    httpInterceptorProviders,
    TransformPipe 
  ],
  bootstrap: [AppComponent]
})


export class AppModule {
}



