<div class="container">
    <div class="row mt-2 d-flex justify-content-center">
       <h2 class="d-inline">PDF Forms</h2>
       <button type="button" [routerLink]="['/pdf-form/create/null']" class="btn btn-circle btn-circle-md d-inline ml-4"
          *ngIf="userAccess?.canCreatePdfForms"><i class="fas fa-plus fa-lg text-light"></i></button>
    </div>
 
    <hr>
    <div class="row" >
       <div class="col-sm-6">
          <input id='filterInput' class="form-control mb-2" #search type="search" placeholder="Filter by Pdf Form Name.."
             (keyup)="onKeyupBrandFilter($event,search.value)">
       </div>
       <div class="col-sm-6 mt-2">
          Found {{filteredItemList.length}}
          {{search.value.length>0? 'Pdf Forms found for "'+search.value+'"' : 'Pdf Forms'}}
       </div>
    </div>
    <!-- GROUPED DISPLAY -->
 
    <div *ngFor="let groupedItems of groupedItemsList;index as l">
       <h5> {{groupedItems.key}} </h5>
       <hr style="margin-top: 0rem">
       <div class="row gallery-block cards-gallery mt-4">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-4" *ngFor="let item of groupedItems.items;index as i">
             <div class=" card border-0 transform-on-hover h-100 color-2" >
                <a [routerLink]="['/pdf-form/view/'+item.id]">
                   <div class="card-body" style="padding: 0.25rem;">
                      <div class=" ml-1 mr-1">
                         <!--<div class=" d-flex justify-content-left">
                            <button class="btn bnt-lg text-white bg-dark">{{item.name}}</button>
                         </div>-->
                         <div class="mb-3 mt-2">
                            <h5 class="text-truncate ml-2 mr-2 card-text justify-content-center">
                               {{item.name}}
                            </h5>
                         </div>
                         <!--<div>SKU Code: {{brand.skuCode}}</div>-->
                         <div class="text-truncate">Licensor: {{item.licensorName}}</div>
                         <div class="text-truncate">Brand: {{item.brandName}}</div>
                         <div class="text-truncate">{{item.category}} - {{item.subCategory}}</div>
                      </div>
                      <!--<p class="card-text">{{brand.brandStatus?'Active':'Inactive'}}</p>-->
                      
                   </div>
                </a>
                <div class="d-flex justify-content-center">
                <button class="btn  btn-circle btn-circle-sm d-inline" (click)="deletePdfFrom(item)" *ngIf="userAccess.canDeletePdfForms">
                  <i class="fas fa-trash text-light"></i>
               </button>
            </div>
             </div>
          </div>
       </div>
    </div>
 
 
 </div>
 
 <div class="floatBtn d-flex flex-column">
    <button type="button" class="btn btn-circle bg-secondary mb-2" matTooltip="Scroll To Top" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'});"  matTooltipPosition="left" matTooltipClass="bg-dark">
       <i class="fas fa-arrow-up fa-2x" style="color:white"></i>
    </button>
 </div>