import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SearchCriteriaBuilder } from 'src/app/shared/model/common/search-criteria-builder.model';


@Injectable({
   providedIn: 'root'
})
export class PrinterCapacityService {

   constructor(private http: HttpClient) { }

   readonly rooturl = environment.apiEndPoint;

   createPrinterCapacityExcel(searchCriteria: SearchCriteriaBuilder) {
      return this.http.post(this.rooturl + '/printerCapacity/export/excel', searchCriteria, { responseType: 'blob' });
   }
}
