import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/service/shared.service';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Product, ProductAttachment, SubmissionSheetDetails, SubmissionSheetVariableDetails } from 'src/app/shared/model/product.model';
import { ProductService } from 'src/app/shared/service/product.service';
import { SearchCriteria } from 'src/app/shared/model/search-criteria.model';
import { BrandService } from 'src/app/shared/service/brand.service';
import { Brand } from 'src/app/shared/model/brand.model';
import { Location, formatDate } from '@angular/common';
import { PdfFormVariables } from 'src/app/shared/model/pdf-form.model';
import { AuthService } from 'src/app/shared/service/auth.service';
@Component({
  selector: 'app-submission-sheet',
  templateUrl: './submission-sheet.component.html',
  styleUrls: ['./submission-sheet.component.css']
})
export class SubmissionSheetComponent implements OnInit {
  userAccessSubscription: Subscription;
  userAccess: UserAccess;
  productId: string;
  product: Product;
  submissionSheetDetails: SubmissionSheetDetails;
  formMissing:boolean=false;
  brand: Brand;
  category:string='';
  subCategory:string='';
  productImages:ProductAttachment[]=[];
  variableForImageModal:SubmissionSheetVariableDetails=new SubmissionSheetVariableDetails;
  constructor(private router: Router,private sharedService: SharedService,private authService: AuthService, private productService: ProductService, private brandService: BrandService,private activatedRoute: ActivatedRoute, private toastrService: ToastrService,private location: Location ) { }

  ngOnInit(): void {
    this.handleSubscriptions();
    this.setMetadata();
    window.scrollTo(0,0);

  }
  /*defaultSelection() {
    if (this.product.brandPnl !== undefined && this.product.brandPnl !== null && this.product.brandPnl.length>0) {
      this.selectedPnlAccesUrl = this.product.brandPnl[0].fileSignedUrl;
    }
    if (this.product.productImages !== undefined && this.product.productImages !== null && this.product.productImages.length>0) {
      this.selectedVisualAccesUrl = this.product.productImages[0].fileSignedUrl;
      this.selectedProofAccesUrl = this.product.productImages[0].fileSignedUrl;
      if (this.product.productImages.length > 1) {
        this.selectedProofAccesUrl = this.product.productImages[1].fileSignedUrl;
      }
    }

  }*/

  handleSubscriptions() {
    this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
      this.userAccess = access;
    });
  }

  setMetadata() {
    if (this.activatedRoute.snapshot.paramMap.get('id') !== "undefined") {
    this.productId = this.activatedRoute.snapshot.paramMap.get('id');
    this.category = this.activatedRoute.snapshot.paramMap.get('category');
    this.subCategory = this.activatedRoute.snapshot.paramMap.get('subCategory');
      this.loadProduct();
    }
  }

   loadProduct() {
    let searchCriteria: SearchCriteria = new SearchCriteria;
    searchCriteria.productId = this.productId;
    this.productService.loadProduct(searchCriteria).subscribe(async (res) => {
    this.product = res as Product;
    await this.loadBrand();
    this.loadSubmissionSheetDetails();
    }, (err) => {
     this.toastrService.error('Error while loading product')
    });
  }
  loadSubmissionSheetDetails() {
    this.productService.getSubmissionSheet(this.product.licensorId, this.product.brandId, this.product.id, this.category, this.subCategory).subscribe(res => {
      this.submissionSheetDetails = res as SubmissionSheetDetails;
      if (this.submissionSheetDetails && this.submissionSheetDetails.pdfFormId) {
        this.submissionSheetDetails.sku=this.product.sku;
        if(this.product.isCollection){
          //this.submissionSheetDetails.sku=this.product.description;
        }
        this.formMissing = false;
        //('form found', this.submissionSheetDetails);
        this.loadTextDetails();
        this.loadCheckDetails();
        this.loadAttachments('PNL');
        this.loadAttachments('IMAGES');
        this.loadAttachments('SEPERATIONS');
        this.loadAttachments('PHOTO_APPROVAL');
        this.loadImagesConfig(true);

      } else {
        this.formMissing = true;
      }
    });

  }
  loadImagesConfig(setDefault:boolean) {
    this.submissionSheetDetails.submissionSheetVariableDetails.forEach(varDeatils => {
      if (varDeatils.images && varDeatils.images!=null && varDeatils.images.length>0) {
          this.sharedService.loadMimeTypes(varDeatils.images);
          varDeatils.images=varDeatils.images.filter(x=>x.mimeType.startsWith('image'));
          if(setDefault){
            if (varDeatils.images.length > varDeatils.varValueImageArrIndex) {
              this.sharedService.setSelectedVariableImage(varDeatils.images[varDeatils.varValueImageArrIndex], varDeatils);
            } else {
              this.sharedService.setSelectedVariableImage(varDeatils.images[0], varDeatils);
            }
           
          }
      }
    });
  }
  loadTextDetails() {
    this.submissionSheetDetails.submissionSheetVariableDetails.forEach(varDeatils => {
      if (this.product !== undefined && this.product !== null) {
        if ('TEXT' === varDeatils.varType && varDeatils.varSubType==='TEXT') {
          this.populateTextDetails(varDeatils);
        }
        if('DATE' === varDeatils.varType && 'DATE' === varDeatils.varSubType){
          this.populateDate(varDeatils);
        }
      }
    });
  }

  populateDate(varDeatils: SubmissionSheetVariableDetails) {
    let retVal='';
    if (varDeatils.varValueSource.startsWith('$')) {
      if (varDeatils.varValueSource.includes('$date')) {
       varDeatils.varValue=(new Date()).toISOString();
     }
    } 
  }

  populateTextDetails(varDeatils: SubmissionSheetVariableDetails) {
    if(varDeatils.varValueSource.includes('~')){
      let valueStr:string='';
      let multiSource:string[]=varDeatils.varValueSource.split('~');
      for (let index = 0; index < multiSource.length; index++) {
        const element = multiSource[index];
        let value=this.getValueFromSource(element.trim())
        if(value!==''){
          valueStr=valueStr+value;
          if(index<multiSource.length-1){
            if(value!==''){
              if(varDeatils.multiValueDelimeter==='\\n'){
                valueStr=valueStr+'\n';
              }else{
              valueStr=valueStr+varDeatils.multiValueDelimeter;
              }
            }
          }
        }
      }
      if(valueStr.endsWith(varDeatils.multiValueDelimeter)){
        valueStr=valueStr.slice(0,valueStr.length-varDeatils.multiValueDelimeter.length);
      }
      varDeatils.varValue=valueStr;
    }else{
      varDeatils.varValue=this.getValueFromSource(varDeatils.varValueSource.trim());
    }
    
  }
  getValueFromSource(source: string): string {
    
    let retVal='';
    if (source.startsWith('$')) {
      if (source.includes('$date')) {
        let format = source.slice(6,source.length);
        let formattedDt = formatDate(new Date(), format, 'en_US')
        retVal = formattedDt;
     }
    } else {
    switch(source) { 
      case "pnlDescription": { 
          retVal=this.brand.pnlDescription;        
         break; 
      }
      default: { 
        if(source.trim().startsWith('<') && source.trim().endsWith('>')){
          retVal=source.slice(1,source.length-1);
        }else{
          if(this.product[source] && this.product[source].toUpperCase()!=='NONE'){
            retVal=this.product[source];
          }else{
            retVal='';
          }
         
        }
         break; 
      } 
   }
  } 
  //
   return retVal;

  }

  
  loadCheckDetails() {
    this.submissionSheetDetails.submissionSheetVariableDetails.forEach(varDeatils => {
      if (this.product !== undefined && this.product !== null) {
        if ('CHECK' === varDeatils.varType && 'CHECK' === varDeatils.varSubType) {
          varDeatils.varValue=varDeatils.varValueSource;
        }
      }
    });
  }

  async loadBrand(){
    await this.brandService.loadBrandWithoutProduct(this.product.brandId,true).toPromise().then((res)=>{
     this.brand=res as Brand;
     this.sharedService.loadMimeTypes(this.brand.logos);
    });
  }

  loadAttachments(type: string) {
    this.productService.loadUrlsOfFileCatgeoryForProductId(type, this.product.licensorId, this.product.brandId, this.product.id).subscribe((res) => {
      this.sharedService.loadMimeTypes(res as ProductAttachment[]);
      if ('PNL' === type) {
        this.product.brandPnl = res as ProductAttachment[];
        this.product.brandPnl=this.product.brandPnl.filter(x=>x.mimeType.startsWith('image'));
      }
      if ('IMAGES' === type) {
        this.product.productImages = res as ProductAttachment[];
      }
      if ('SEPERATIONS' === type) {
        this.product.seperations = res as ProductAttachment[];
        this.setProductImages();
      }
      if ('PHOTO_APPROVAL' === type) {
        this.product.photoApproval = res as ProductAttachment[];
      }
      //this.defaultSelection();
      this.getAttachments();
      
    },
      (err) => {
        this.toastrService.error('Error wile loading product attachments : ' + type + ' - ' + err);
      });
  }


  
  

  getAttachments() {
    this.submissionSheetDetails.submissionSheetVariableDetails.forEach(varDeatils => {
      this.setDefaultVariableImageSelection(varDeatils);
    });
  }
setDefaultVariableImageSelection(varDeatils:SubmissionSheetVariableDetails){
  
  if(varDeatils.varSubType==='IMAGE'){
    if (this.product !== undefined && this.product !== null) {
      if ('PNL' === varDeatils.varValueSource) {
        if (this.product.brandPnl !== undefined && this.product.brandPnl !== null && this.product.brandPnl.length>0) {
          if (this.product.brandPnl.length > varDeatils.varValueImageArrIndex) {
            this.sharedService.setSelectedVariableImage(this.product.brandPnl[varDeatils.varValueImageArrIndex], varDeatils);
          } else {
            this.sharedService.setSelectedVariableImage(this.product.brandPnl[0], varDeatils);
          }
        }
        return this.product.brandPnl;
      } else if ('IMAGES' === varDeatils.varValueSource && 'IMAGE' === varDeatils.varType) {
        if (this.product.productImages !== undefined && this.product.productImages !== null && this.product.productImages.length>0) {
          if (this.product.productImages.length > varDeatils.varValueImageArrIndex) {
            this.sharedService.setSelectedVariableImage(this.product.productImages[varDeatils.varValueImageArrIndex], varDeatils);
          } else {
            this.sharedService.setSelectedVariableImage(this.product.productImages[0], varDeatils);
          }
        }
        return this.product.productImages;
      } else if ('PHOTO_APPROVAL' === varDeatils.varValueSource) {
        if (this.product.photoApproval !== undefined && this.product.photoApproval !== null && this.product.photoApproval.length>0) {
          if (this.product.photoApproval.length > varDeatils.varValueImageArrIndex) {
            this.sharedService.setSelectedVariableImage(this.product.photoApproval[varDeatils.varValueImageArrIndex], varDeatils);
          } else {
            this.sharedService.setSelectedVariableImage(this.product.photoApproval[0], varDeatils);
          }
        }
        return this.product.photoApproval;
      }else if ('LOGO' === varDeatils.varValueSource) {
        if (this.brand.logos !== undefined && this.brand.logos !== null && this.brand.logos.length>0) {
          if (this.brand.logos.length > varDeatils.varValueImageArrIndex) {
            this.sharedService.setSelectedVariableImage(this.brand.logos[varDeatils.varValueImageArrIndex], varDeatils);
          } else {
            this.sharedService.setSelectedVariableImage(this.brand.logos[0], varDeatils);
          }
        }
        return this.brand.logos;
      } 
    }
  }

}


  createSubmissionSheet(){
    this.productService.createSubmissionSheet(this.submissionSheetDetails).subscribe((res)=>{
      let serviceResult:SubmissionSheetDetails= res as SubmissionSheetDetails;
      if(serviceResult?.status==='SUCCESS'){
          this.submissionSheetDetails=serviceResult;
          this.loadImagesConfig(false);
          this.toastrService.success('Submission sheet preview generated');
      }else{
        this.toastrService.error('Submission sheet preview failed - '+serviceResult?.statusDesc);
      }
    },(err)=>{
      console.error(err);
      this.toastrService.error('Submission Sheet Preview Failed - '+err);
    });
  }

  confirmSubmissionSheet(type:string){
    this.submissionSheetDetails.fileFormatToSave=type;
    this.productService.confirmSubmissionSheet(this.submissionSheetDetails).subscribe((res)=>{
      let serviceResult:SubmissionSheetDetails= res as SubmissionSheetDetails;
      if(serviceResult.status==='SUCCESS'){
          this.submissionSheetDetails=serviceResult;
          this.toastrService.success('Submission Sheet Created');
          //this.goBack();
          //this.router.navigate(['product-search']);
          this.navigateBackToProduct();

          
      }else{
        this.toastrService.error('Submission Sheet Creation Failed with status - '+serviceResult.status);
      }
      
    },(err)=>{
      console.error(err);
      this.toastrService.error('Submission Sheet Creation Failed - '+err);
    });
  }

  private navigateBackToProduct() {
    this.router.navigate(['/product/view/' + this.product.licensorId + '/' + this.product.brandId + '/' + this.product.id], { skipLocationChange: true });
  }

  selectedCheckboxVariable(event,variable:SubmissionSheetVariableDetails){
    variable.varValue='false';
    if(event.target.checked){
      variable.varValue='true';
    }
  }
  isSelectedCheckboxVariable(variable:SubmissionSheetVariableDetails){
    if(variable.varValue==='true'){
      return true;
    }else{
      return false;
    }
  }
  goBack() {
    this.navigateBackToProduct();
    //this.location.back();
  }

  setProductImages(){
    
    let retVal=Object.assign(this.product.productImages);
    if(this.product.seperations && this.product.seperations!==null){
      this.product.seperations.forEach(element => {
        if(element.originalFileName.toLowerCase().endsWith('.jpeg') 
        || element.originalFileName.toLowerCase().endsWith('.jpg')
        ||element.originalFileName.toLowerCase().endsWith('.png')){
          retVal.push(element);
        }
      });
    }
    this.productImages=retVal;
  }
  selectVariableForImageModal(variable:SubmissionSheetVariableDetails){
    this.variableForImageModal=variable;
    document.getElementById('imageModalId').click();

  }
  
}
