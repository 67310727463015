import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Product, ProductAttachment } from 'src/app/shared/model/product.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
  selector: 'app-submission-details',
  templateUrl: './submission-details.component.html',
  styleUrls: ['./submission-details.component.css']
})
export class SubmissionDetailsComponent implements OnInit {

  constructor(private authService:AuthService) { }



  userAccessSubscription: Subscription;
  userAccess: UserAccess;

  currentLoggedInUserSubscription: Subscription;
  currentLoggedInUser: User;
  editable: boolean = false;
  //orderDetailId:string;
  @Input() product:Product;
  
  

  async ngOnInit() {
    this.handleSubscriptions();
    

  }
 

  handleSubscriptions() {
    this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
       this.userAccess = access;
    });
    this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
       this.currentLoggedInUser = currentLoggedInUser;
    });
  }

  handleThumbnailError(productAttachment: ProductAttachment, event) {
    if(productAttachment){
    if (isNaN(productAttachment?.thumbnailErrorCount)) {
      productAttachment.thumbnailErrorCount = 0;
    }
    event.target.src = productAttachment.fileSignedUrl;
    var x: number = productAttachment.thumbnailErrorCount;
    productAttachment.thumbnailErrorCount = x + 1;
    if (productAttachment.thumbnailErrorCount < 2) {
      event.target.src = productAttachment.fileSignedUrl;
      productAttachment.thumbnailSignedUrl = productAttachment.fileSignedUrl;
    } else {
      event.target.src = "/assets/default-image.jpg"
    }
    }
  }

}
