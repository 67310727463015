   <!-- EDITABLE MODE -->

<!--
   <div *ngIf="editable">
      <div class="container border mb-2 p-3">
         <div class="row form-group">
            <label class="col-sm-4 col-form-label text-right"><i class="fa fa-user"></i>&nbsp;Title</label>
            <div class="col-sm">
               <input name="title" [(ngModel)]="profile.title" class="form-control  form-control-sm"
                  placeholder="Title" #titleVar="ngModel" required
                  [ngClass]="{'is-invalid':titleVar.touched && titleVar.errors}">
               <div *ngIf="titleVar?.invalid && (titleVar.dirty || titleVar.touched)" class="invalid-feedback">
                  <div *ngIf="titleVar.errors.required">Title is required.</div>
               </div>
            </div>
         </div>
         <div class="row form-group">
            <label class="col-sm-4 col-form-label text-right"><i class="fa fa-user"></i>&nbsp;First Name</label>
            <div class="col-sm">
               <input name="firstName" [(ngModel)]="profile.firstName" class="form-control  form-control-sm"
                  placeholder="First Name" #firstNameVar="ngModel" required
                  [ngClass]="{'is-invalid':firstNameVar.touched && firstNameVar.errors}">
               <div *ngIf="firstNameVar?.invalid && (firstNameVar.dirty || firstNameVar.touched)"
                  class="invalid-feedback">
                  <div *ngIf="firstNameVar.errors.required">First Name is required.</div>
               </div>
            </div>
         </div>
         <div class="row form-group">
            <label class="col-sm-4 col-form-label text-right"><i class="fa fa-user"></i>&nbsp;Middle Name</label>
            <div class="col-sm">
               <input name="middleName" [(ngModel)]="profile.middleName" class="form-control  form-control-sm"
                  placeholder="Middle Name" #middleNameVar="ngModel" required
                  [ngClass]="{'is-invalid':middleNameVar.touched && middleNameVar.errors}">
               <div *ngIf="middleNameVar?.invalid && (middleNameVar.dirty || middleNameVar.touched)"
                  class="invalid-feedback">
                  <div *ngIf="middleNameVar.errors.required">Middle Name is required.</div>
               </div>
            </div>
         </div>
         <div class="row form-group">
            <label class="col-sm-4 col-form-label"><i class="fa fa-user"></i>&nbsp;Last Name</label>
            <div class="col-sm">
               <input name="lastName" [(ngModel)]="profile.lastName" class="form-control  form-control-sm"
                  placeholder="Last Name" #lastNameVar="ngModel" required
                  [ngClass]="{'is-invalid':lastNameVar.touched && lastNameVar.errors}">
               <div *ngIf="lastNameVar?.invalid && (lastNameVar.dirty || lastNameVar.touched)" class="invalid-feedback">
                  <div *ngIf="lastNameVar.errors.required">Last Name is required.</div>
               </div>
            </div>
         </div>
      </div>
   </div>

-->
<div ngModelGroup="ProfileMG" style="border: 0px;">

   <div>
      <div class="mb-0 p-1 ml-3">

         <div class="row form-group">
            <div class="col-sm-4 d-inline">
               <label class="col-form-label ">Contact Type</label>
            </div>
            <div class="col-sm-7 py-1 d-inline" >
               <div class="row" >
                  <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let contactDetailsType of contactTypeList;index as i">
                     <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input ml-1" name="profile_{{profile.id}}_contactDetailsType_{{i}}" id="profile_{{profile.id}}_contactDetailsType_{{i}}" 
                        [checked]="isItemSelected(contactDetailsType)" [disabled]="!editable" (change)="onContactTypeSelection($event,contactDetailsType)">
                        <label class="custom-control-label mr-2 py-1" for="profile_{{profile.id}}_contactDetailsType_{{i}}">
                           {{contactDetailsType}}
                        </label>
                     </div>
                  </div>
               </div>
               <div *ngIf="profile.contactDetailsTypeList.length === 0" style="color:#DC3545; font-size: 80%;">
                  <div>Select Contact Type.</div>
               </div>
            </div>
         </div>

         <div class="row form-group">
            <label class="col-sm-4 col-form-label ">Title</label>
            <div class="col-sm" >
               <input name="profile_{{profile.id}}_title" [(ngModel)]="profile.title" class="form-control  form-control-sm"
                  placeholder="" #titleVar="ngModel" required [disabled]="!editable"
                  [ngClass]="{'is-invalid':titleVar.touched && titleVar.errors}">
               <div *ngIf="titleVar?.invalid && (titleVar.dirty || titleVar.touched)" class="invalid-feedback">
                  <div *ngIf="titleVar.errors.required">Title is required.</div>
               </div>
            </div>
         </div>

         <div class="row form-group">
            <label class="col-sm-4 col-form-label " >First Name</label>
            <div class="col-sm" >
               <input name="profile_{{profile.id}}_firstName" [(ngModel)]="profile.firstName" class="form-control  form-control-sm"
                  placeholder="" #firstNameVar="ngModel" required [disabled]="!editable"
                  [ngClass]="{'is-invalid':firstNameVar.touched && firstNameVar.errors}">
               <div *ngIf="firstNameVar?.invalid && (firstNameVar.dirty || firstNameVar.touched)"
                  class="invalid-feedback">
                  <div *ngIf="firstNameVar.errors.required">First Name is required.</div>
               </div>
            </div>
         </div>

         <div class="row form-group">
            <label class="col-sm-4 col-form-label " >Middle Name</label>
            <div class="col-sm" >
               <input name="profile_{{profile.id}}_middleName" [(ngModel)]="profile.middleName" class="form-control  form-control-sm"
                  placeholder="" #middleNameVar="ngModel" [disabled]="!editable"
                  [ngClass]="{'is-invalid':middleNameVar.touched && middleNameVar.errors}">
               <div *ngIf="middleNameVar?.invalid && (middleNameVar.dirty || middleNameVar.touched)"
                  class="invalid-feedback">
                  <div *ngIf="middleNameVar.errors.required">Middle Name is required.</div>
               </div>
            </div>
         </div>
         
         <div class="row form-group">
            <label class="col-sm-4 col-form-label " >Last Name</label>
            <div class="col-sm" >
               <input name="profile_{{profile.id}}_lastName" [(ngModel)]="profile.lastName" class="form-control  form-control-sm"
                  placeholder="" #lastNameVar="ngModel" [disabled]="!editable"
                  [ngClass]="{'is-invalid':lastNameVar.touched && lastNameVar.errors}">
               <div *ngIf="lastNameVar?.invalid && (lastNameVar.dirty || lastNameVar.touched)" class="invalid-feedback">
                  <div *ngIf="lastNameVar.errors.required">Last Name is required.</div>
               </div>
            </div>
         </div>

         <div class="row form-group" *ngIf="contactType !=='LICENSOR'">
            <label class="col-sm-4 col-form-label " >Date of Birth</label>
            <div class="col-sm d-flex d-inline" >
               <input matInput [matDatepicker]="picker" name="profile_{{profile.id}}_dateOfBirth" [(ngModel)]="profile.dateOfBirth" class="form-control  form-control-sm"
                  placeholder="" #dateOfBirthVar="ngModel" [disabled]="!editable"
                  [ngClass]="{'is-invalid':dateOfBirthVar.touched && dateOfBirthVar.errors}">
                  <mat-datepicker #picker></mat-datepicker>
                  <button class="btn btn-sm" (click)="picker.open()"><i class="fas fa-calendar-alt"></i></button>   
            </div>
            <div *ngIf="dateOfBirthVar?.invalid && (dateOfBirthVar.dirty || dateOfBirthVar.touched)" class="invalid-feedback">
               <div *ngIf="dateOfBirthVar.errors.required">Date of Birth is required.</div>
            </div>
         </div>

         <div class="row form-group">
            <label class="col-sm-4 col-form-label ">Job Title</label>
            <div class="col-sm">
               <input name="profile_{{profile.id}}_jobtitle" [(ngModel)]="profile.jobTitle" class="form-control  form-control-sm"
                  placeholder="" #jobTitleVar="ngModel" [disabled]="!editable"
                  [ngClass]="{'is-invalid':jobTitleVar.touched && jobTitleVar.errors}">
               <div *ngIf="jobTitleVar?.invalid && (jobTitleVar.dirty || jobTitleVar.touched)" class="invalid-feedback">
                  <div *ngIf="jobTitleVar.errors.required">Job Title is required.</div>
               </div>
            </div>
         </div>

         <div class="form-group row" *ngIf="contactType ==='LICENSOR'">
            <label class="col-sm-4 form-check-label mt-1">Submission Sheet Reviewer</label>
            <div class="col-sm-1">
               <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="profile_{{profile.id}}_submissionSheetReviewer" id="profile_{{profile.id}}_submissionSheetReviewer" [disabled]="!editable"
                     [(ngModel)]="profile.submissionSheetReviewer" #submissionSheetReviewer (click)="submissionSheetReviewerSelected(submissionSheetReviewer.checked)">
               </div>
            </div>
            
            <div class="col-sm py-1" *ngIf="submissionSheetReviewer.checked">
               <div class="d-flex d-inline align-items-center">
                  <div class="mr-2">
                     <input type="radio" id="profile_{{profile.id}}_emailTo" [(ngModel)]="profile.emailRecipient" [disabled]="!editable"
                     name="profile_{{profile.id}}_emailRadio" value='TO' class="mr-2" >
                     <label for="emailTo"><span></span>&nbsp;Email To</label>
                  </div>
                  <div>
                     <input type="radio" id="profile_{{profile.id}}_emailCc" [(ngModel)]="profile.emailRecipient" [disabled]="!editable"
                     name="profile_{{profile.id}}_emailRadio" value='CC' class="mr-2" >
                     <label for="emailCc"><span></span>&nbsp;Email Cc</label>
                  </div>
               </div>
            </div>
         </div>

         <div class="row form-group" *ngIf="contactType ==='LICENSOR'">
            <label class="col-sm-4 col-form-label"><i class="fa fa-tag"></i>&nbsp;Brand Name
               
            </label>
            <!--
            <div class="col-sm">
               <select class="form-control form-control-sm" id="profile_{{profile.id}}_brandName"
                     [(ngModel)]="profile.brandName" name="profile_{{profile.id}}_brandName" #brandName="ngModel"
                     required #brandName=ngModel [disabled]="!editable"
                     [ngClass]="{'is-invalid':brandName.touched && brandName.errors}">
                     <option value="Default">Default</option>
                     <hr>
                     <option *ngFor="let brand of filteredBrandList" [ngValue]="brand.brandName">
                        {{brand.brandName}}</option>
                  </select>
                  <div *ngIf="brandName?.invalid && (brandName.dirty || brandName.touched)"
                     class="invalid-feedback">
                     <div *ngIf="brandName.errors.required">Brand Name is required.</div>
                  </div>
            </div>
         -->
            <div class="col-sm-7 py-2 d-inline">
               <div class="row" >
                  <div class="col-lg-6" *ngFor="let brand of profile.associatedBrands;index as i">
                     <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input ml-1" name="brandName_{{profile.id}}_{{i}}" id="brandName_{{profile.id}}_{{i}}" 
                        [checked]="isBrandSelected(brand)" [disabled]="!editable" (change)="onBrandSelection($event,brand)">
                        <label class="custom-control-label mr-2 py-1 text-wrap" for="brandName_{{profile.id}}_{{i}}">
                           {{brand.brandName}}
                        </label>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col-lg-4 col-sm-6 col-md-6">
                     <button *ngIf="editable" type="button" class=" p-0 btn btn-sm color-3 btn-block" (click)="openBrandSelectionModal()"><i class="fas fa-plus fa-xs"></i>
                     </button>
                  </div>
               </div>
               <div class="mt-2" *ngIf="profile.associatedBrands?.length === 0">
                  <div>No Brand Association Available.</div>
               </div>
            </div>

         </div>

      </div>
   </div>

   <!-- NON - EDITABLE MODE

   <div *ngIf="!editable">
      <div class="container mb-2">
         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label text-right"><i class="fa fa-user"></i>&nbsp;Contact Type:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{profile?.contactDetailsType}} </span>
            </div>
         </div>
         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label text-right"><i class="fa fa-user"></i>&nbsp;Title:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{profile?.title}} </span>
            </div>
         </div>
         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label text-right"><i class="fa fa-user"></i>&nbsp;First Name:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{profile?.firstName}} </span>
            </div>
         </div>
         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label text-right"><i class="fa fa-user"></i>&nbsp;Middle Name:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{profile?.middleName}} </span>
            </div>
         </div>
         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label text-right"><i class="fa fa-user"></i>&nbsp;Last Name:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{profile?.lastName}} </span>
            </div>
         </div>
         <div class="form-group text-wrap" style="margin-bottom: 0rem;" *ngIf="contactType !=='LICENSOR'">
            <div class="row">
               <label class="col-4 col-form-label text-right"><i class="fa fa-user"></i>&nbsp;Date of Birth:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{profile?.dateOfBirth | date:'mediumDate'}} </span>
            </div>
         </div>      
         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label text-right"><i class="fa fa-user"></i>&nbsp;Job Title:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{profile?.jobTitle}} </span>
            </div>
         </div>
         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 form-check-label text-right"><i class="fa fa-user"></i>&nbsp;Submission Sheet Reviewer:</label>
               <div class="col-sm-2">
                  <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="submissionSheetReviewer" id="submissionSheetReviewer" 
                     [disabled]="true" [checked]="profile.submissionSheetReviewer" #submissionSheetReviewer>
                  </div>
               </div>
               <div class="col-sm" *ngIf="submissionSheetReviewer.checked">
                  <div class="d-flex d-inline align-items-center">
                     <div class="mr-2">
                        <input type="radio" id="emailTo" [(ngModel)]="profile.emailRecipient"
                        name="emailRadio" value='TO' class="mr-2" disabled>
                        <label for="emailTo"><span></span>&nbsp;Email To</label>
                     </div>
                     <div>
                        <input type="radio" id="emailCc" [(ngModel)]="profile.emailRecipient" 
                        name="emailRadio" value='CC' class="mr-2" disabled>
                        <label for="emailCc"><span></span>&nbsp;Email Cc</label>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   -->
</div>

<button id="brandSelectionModalToggler" type="button" style="display: none;" data-toggle="modal" data-target="#BrandSelectionModal"></button>

<div class="modal fade" id="BrandSelectionModal" tabindex="-1" role="dialog" aria-labelledby="BrandSelectionModalModalTitle" aria-hidden="true">
   <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title">Select Brands</h5>
            <button type="button" class="close" (click)="onClickOkBrandSelectionModal()" aria-label="Close">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <div class="ml-2" *ngFor="let brand of filteredBrandList">
               <div class="row">
                  <label class="cb-container">
                     <h6 class="ml-4 mt-1">{{brand.brandName}}</h6>
                     <input type="checkbox" [checked]="isBrandSelected(brand)" (change)="onBrandSelection($event,brand)">
                     <span class="checkmark"></span>
                  </label>
               </div>
               <div *ngIf="brand.id ==='ANY'">
                  <hr>
               </div>
            </div>
         </div>
         <div class="modal-footer">
            <div class="m-1 d-flex">
               <button class="btn bg-dark text-white btn-block" (click)="onClickOkBrandSelectionModal()">Ok</button>
            </div>
         </div>
      </div>
   </div>
</div>
