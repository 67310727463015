import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Product, StatusDetails, SampleDetails, SkuTrackerRetailerInfo } from 'src/app/shared/model/product.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { NgForm, ControlContainer } from '@angular/forms';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { LicensorService } from 'src/app/shared/service/licensor.service';
import { LicensorCostMatrix, LicensorSampleDetails } from 'src/app/shared/model/licensor.model';
import { ToastrService } from 'ngx-toastr';
import { Customer } from 'src/app/shared/model/customer.model';


@Component({
  selector: 'app-sku-tracker',
  templateUrl: './sku-tracker.component.html',
  styleUrls: ['./sku-tracker.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SkuTrackerComponent implements OnInit {
  constructor(public sharedService:SharedService,private licensorService:LicensorService,private toastrService:ToastrService) { }
  submissionDetailHeaders=['SKU','Status','Status Date','User', 'Licensor Comments','Philcos Comments'];
  //sampleDetailsGroupHeaders=[{name:'Pre-Pro. Photo Sample',colspan:2},{name:'Pre-Pro Sample',colspan:2},{name:'Final Sample',colspan:2},{name:'Production Team',colspan:1},{name:'Customer',colspan:2},{name:'Sample Received',colspan:2},{name:'Shipping',colspan:4}];
  //sampleDetailsHeaders=['Submission Date','Response Date','Qty','Request Date','Qty','Request Date','Request To','Name','Order Due Date','Qty','Date','Qty','Date','Tracking#','Company'];
  sampleDetailsGroupHeaders=[{name:'Sample Requested',colspan:5},{name:'Sample Received',colspan:2},{name:'Pre-Pro Photo Submission',colspan:3},{name:'Pre-Pro Submission',colspan:5},{name:'Final Submission',colspan:5}];
  sampleDetailsGroupHeadersMinimal=[{name:'Sample Requested',colspan:5},{name:'Sample Received',colspan:2},{name:'Pre-Pro Photo Submission',colspan:3},{name:'Pre-Pro Submission',colspan:3},{name:'Final Submission',colspan:3}];
  sampleDetailsHeaders=['Date','Customer','Order Due Date','Production','Qty','Date','Qty','Date','Qty','Response Date','Date','Qty','Tracking#','Courier','Response Date','Date','Qty','Tracking#','Courier','Response Date'];
  sampleDetailsHeadersMinimal=['Date','Customer','Order Due Date','Production','Qty','Date','Qty','Date','Qty','Response Date','Date','Qty','Response Date','Date','Qty','Response Date'];
  sampleDetailsDistribution="Licensor's Samples Requirement \n";
  skuTrackerRetailerInfoHeaders=['SKU','Customer','Order Qty','Wholesale Cost','Retailer Cost','Expected Shipped Date','Status','Comments'];//'Actual Shipped Date'
  showTrackingDeatils:boolean=false;
  @Input() header: string = 'SKU Approval Details';
  @Input() uniqueId: string = 'MODAL';
  @Input() product: Product;
  @Input() userAccess:UserAccess;
  @Input() existingStatus:string;
  @Input() approvalStatusList:[]=[];
  @Input() customerList:Customer[]=[];
  @Input() courierList:string[]=[];
  @Input() modalId: string;
  @Input() editable: boolean=false;
  //@ViewChild('addEditContactForm') addEditContactForm: any;
 

  ngOnInit(): void {
   //
  }
  
 

  removeStatus(sd:StatusDetails){
    //
    this.product.statusDetails=this.product.statusDetails.filter(x =>sd!=x);
    this.product.approvalStatus=this.existingStatus;
  }
  removeSample(sd:SampleDetails){
    //
    if(!sd.id){
    this.product.sampleDetails=this.product.sampleDetails.filter(x =>sd!=x);
    }else{
      sd.delete=true;
    }
  }
  removeOrder(sd:SkuTrackerRetailerInfo){
    //
    if(!sd.id){
      this.product.skuTrackerRetailerInfo=this.product.skuTrackerRetailerInfo.filter(x =>sd!=x);
    }else{
      sd.delete=true;
    }
  }

  addNewSampleDetailsEntry(){
    if(!this.product.sampleDetails){
      this.product.sampleDetails=[];
    }
    let sd=new SampleDetails;
    /*sd.productId=this.product.id;
    sd.licensorId=this.product.licensorId;
    sd.brandId=this.product.licensorId;*/
    sd.createdOn=new Date;
    sd.sampleReqDate=new Date;
    this.product.sampleDetails.push(sd);
    this.licensorService.getSampleDetailsWithSummation(this.product.licensorId,this.product.brandId).subscribe((resp) =>{
      let sampleDetails:LicensorSampleDetails = resp as LicensorSampleDetails;
      if(sampleDetails && sampleDetails!==null){
        sd.preProSamplesQty=sampleDetails.sumOfPreProductionSampleQty;
        sd.finalSamplesQty=sampleDetails.sumOfFinalSampleQty;
        sd.preProPhotoSamplesQty=sampleDetails.sumOfPreProPhotoSampleQty;
        sd.sampleReqQty=sd.preProSamplesQty+sd.finalSamplesQty+sd.preProPhotoSamplesQty;
        //create Tool Tip
        this.sampleDetailsDistribution=this.sampleDetailsDistribution;


      }else{
      this.toastrService.error('Licensor sampling details not configured for '+this.product.licensorName);
      }
   });
  }

  addNewRetailerInfoEntry(){
    if(!this.product.skuTrackerRetailerInfo){
      this.product.skuTrackerRetailerInfo=[];
    }
    let sd=new SkuTrackerRetailerInfo;
    sd.sku=this.product.sku;
    /*sd.productId=this.product.id;
    sd.licensorId=this.product.licensorId;
    sd.brandId=this.product.licensorId;*/
    sd.createdOn=new Date;
    this.product.skuTrackerRetailerInfo.push(sd);
    this.licensorService.getLicensorCost(this.product.licensorId,this.product.brandId,this.product.category,this.product.garment).subscribe((resp) =>{
      let costMatrix:LicensorCostMatrix = resp as LicensorCostMatrix;
      if(costMatrix && costMatrix!==null){
        sd.retailCost=costMatrix.retailCost;
        sd.wholesaleCost=costMatrix.wholesaleCost;
      }else{
      this.toastrService.error('Licensor cost details not configured for '+this.product.licensorName);
      }
   });
  }

  includeShippingDetailsChange(event){
    this.showTrackingDeatils=false; 
    if(event.target.checked){
      this.showTrackingDeatils=true; 
    }
  }



}
