import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { BrandPnlSpeciality } from 'src/app/shared/model/brand.model';

import { Customer } from 'src/app/shared/model/customer.model';
import { OrderDetail, OrderDetailSizeQuantity } from 'src/app/shared/model/sales/order-detail.model';
import { SalesOrder } from 'src/app/shared/model/sales/sales-order.model';
import { SizeOrderDetail } from 'src/app/shared/model/sales/size-order-detail.model';
import { SubOrderDetail } from 'src/app/shared/model/sales/sub-order-detail.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { BrandService } from 'src/app/shared/service/brand.service';
import { SalesHelperService } from 'src/app/shared/service/sales/sales-helper.service';
import { SharedService } from 'src/app/shared/service/shared.service';
import { PackagingTypeSelectionModalComponent } from '../modal/packaging-type-selection-modal/packaging-type-selection-modal.component';
import { SizeSelectionModalComponent } from '../modal/size-selection-modal/size-selection-modal.component';
import { ProductMetadataService } from 'src/app/shared/service/product-metadata.service';
import { MetadataSize } from 'src/app/shared/model/product-metadata/metadata-size.model';


@Component({
   selector: 'app-order-detail',
   templateUrl: './order-detail.component.html',
   styleUrls: ['./order-detail.component.css'],
   viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class OrderDetailComponent implements OnInit,AfterViewInit {

   constructor(private toastrService: ToastrService, 
      private salesHelperService: SalesHelperService, private dialog: MatDialog, private sharedService: SharedService,
      private brandService:BrandService, private productMetadataService:ProductMetadataService) { }
  

   @Output() event = new EventEmitter<string>();
   @Output() cloneEvent = new EventEmitter<OrderDetail>();
   @Output() changeSkuEvent = new EventEmitter<OrderDetail>();
   @Input() salesOrder: SalesOrder;
   @Input() orderDetail: OrderDetail;
   @Input() orderDetailIndex: number;
   @Input() editable: boolean;
   @Input() createAction: boolean;
   @Input() colspanIncrementCount: number = 0;
   @Input() showSubOrderDetailPoCustomer: boolean = false;
   @Input() poCustomerList: Customer[];
   @Input() salesOrderForm: NgForm;
   @Input() collapseOnInit: boolean=false;;
   @Input() userAccess:UserAccess;

   blanksColourListFromMetadata: string[];
   blanksQualityListFromMetadata: string[];
   packagingTypeListFromMetadata: string[] = [];
   customerPoTypeListFromMetadata: string[] = [];
   trimsListFromMetadata: string[] = [];
   //sizesListFromMetadata: string[] = [];
   sizesMetadataList: MetadataSize[] = [];
   ordeDetailSizeQuantityListForModal: OrderDetailSizeQuantity[];
   brandPnlSpecialities:BrandPnlSpeciality[];
   
   testJson:string='{"availablePnlSpecialities": [{"licensorId": "4028b8817f8ac20b017f8ae40baa0000","brandId": "4028b8817f8ac20b017f8ae49c8a0005","brandPnlSpecialities": [{"filePrefix": "JMHMX.CB Short Sleeve (1)","displayName": "JMH WIth Philcos Logo"}]}]}';
   

   ngOnInit(): void {
      this.handleSubscriptions();
      //this.pnlSpeciality=JSON.parse((this.testJson)) as PnlSPeciality;
      //console.log(this.pnlSpeciality);
   }
   ngAfterViewInit(): void {
      if(this.collapseOnInit){
         if(document.getElementById('expandOrderDetail_'+this.orderDetailIndex)!==undefined){
            document.getElementById('expandOrderDetail_'+this.orderDetailIndex).click();
         }
      }
   }

   handleSubscriptions() {
      // Subscriptions
      this.productMetadataService.blanksColourListObservable.subscribe(res => {
         this.blanksColourListFromMetadata = res;
      });

      this.salesHelperService.blanksQualityListFromMetadata.subscribe(res => {
         this.blanksQualityListFromMetadata = res;
      });

      this.salesHelperService.packagingTypeListFromMetadata.subscribe(res => {
         this.packagingTypeListFromMetadata = res;
      });

      this.salesHelperService.customerPoTypeListFromMetadata.subscribe(res => {
         this.customerPoTypeListFromMetadata = res;
      });

      this.salesHelperService.trimsListFromMetadata.subscribe(res => {
         this.trimsListFromMetadata = res;
      });

      this.productMetadataService.sizesMetadataListObservable.subscribe(res => {
         this.sizesMetadataList = res;
      });
      this.brandService.availablePnlSpecialityFromMetadataObservable.subscribe(res=>{
         if(res && res!=null && res.length>0){
            this.brandPnlSpecialities=[];
            res.forEach(pnlSpecialityTemp => {
               if(pnlSpecialityTemp.brandId===this.orderDetail.product.brandId 
                  && pnlSpecialityTemp.licensorId===this.orderDetail.product.licensorId){
                     this.brandPnlSpecialities.push(pnlSpecialityTemp);
                     //console.log('PnlSPeciality for '+this.orderDetail.product.sku,this.pnlSpeciality);
                  }
            });
         }
      })
   }

   reCalculateFullSalesOrder() {
      this.event.emit('RECALCULATE_FULL_SALES_ORDER');
   }

   updateSalesOrder(salesOrder: SalesOrder) {
      this.event.emit('UPDATE_SALES_ORDER');
   }

   cloneOrderDetail(orderDetail: OrderDetail) {
      this.cloneEvent.emit(orderDetail);
   }

   changeProductSku(orderDetail: OrderDetail) {
      this.changeSkuEvent.emit(orderDetail);
   }

   onDropdownChangeSizeOverride(orderDetail: OrderDetail, orderDetailSizeQuantity: OrderDetailSizeQuantity) {
      this.salesHelperService.propogateSizeOverrideDetail(orderDetail, orderDetailSizeQuantity.size, orderDetailSizeQuantity.overrideSize, true);
   }

   onSizeSelectionFromModal(currentOrderDetail: OrderDetail, closeModalRequired: boolean) {
      this.salesHelperService.onSizeSelectionFromModal(this.salesOrder, this.ordeDetailSizeQuantityListForModal, this.packagingTypeListFromMetadata, currentOrderDetail, closeModalRequired);
   }

   /************************************* START: SUB ORDER DETAILS *************************/

   addNewSubOrderDetailForSales(orderDetail: OrderDetail) {
      if (orderDetail.subOrderDetailList === null || orderDetail.subOrderDetailList === undefined) {
         orderDetail.subOrderDetailList = [];
      }
      let newSubOrderDetail = this.salesHelperService.createNewSubOrderDetailForSales(this.salesOrder, orderDetail, this.packagingTypeListFromMetadata);
      orderDetail.subOrderDetailList.push(newSubOrderDetail);
      orderDetail.subOrderDetailList = this.sortSubOrderDetailList(orderDetail);
   }

   deleteSubOrderDetail(orderDetail: OrderDetail, subOrderDetail: SubOrderDetail) {
      if (!this.canSubOrderDetailBeDeleted(orderDetail)) {
         this.toastrService.error('Cannot Delete Sub Order Detail. Only Last SubDetail.');
         return;
      }
      subOrderDetail.deleteFlag = true;
      this.reCalculateFullSalesOrder();
   }

   cancelSubOrderDetail(orderDetail: OrderDetail, subOrderDetail: SubOrderDetail) {
      if (confirm('Are you sure you want to cancel the sub order detail for this style  ?')) {
         this.salesHelperService.updateAndPropogateSubOrderDetailStatus(subOrderDetail, 'CANCELLED');
         this.reCalculateFullSalesOrder();
         this.updateSalesOrder(this.salesOrder);
      }
   }

   onChangeSubOrderDetailQuantity(salesOrder: SalesOrder, orderDetail: OrderDetail, subOrderDetail: SubOrderDetail) {
      this.reCalculateFullSalesOrder();
   }

   canSubOrderDetailBeDeleted(orderDetail: OrderDetail): boolean {
      let totalSubOrderDetailCount: number = orderDetail.subOrderDetailList?.length;
      let totalSubOrderDetailDeletedCount: number = this.totalSubOrderDetailDeletedCount(orderDetail);
      if (totalSubOrderDetailCount - totalSubOrderDetailDeletedCount > 1) {
         return true;
      } else {
         return false;
      }
   }

   totalSubOrderDetailDeletedCount(orderDetail: OrderDetail): number {
      let subOrderDeletedCount: number = 0;
      orderDetail.subOrderDetailList?.forEach((subOrderDetail, index) => {
         if (subOrderDetail.deleteFlag) {
            subOrderDeletedCount = subOrderDeletedCount + 1;
         }
      });
      return subOrderDeletedCount;
   }

   sortSubOrderDetailList(orderDetail: OrderDetail) {
      let sortedList = [];
      if (orderDetail.subOrderDetailList !== null && orderDetail.subOrderDetailList !== undefined) {
         sortedList = orderDetail.subOrderDetailList.sort(function (a, b) {
            var itemA = a.sortOrder;
            var itemB = b.sortOrder;
            return (itemA < itemB) ? -1 : (itemA > itemB) ? 1 : 0;
         });
      }
      return sortedList;
   }

   getQuantityBasedOnPrepack(totalSubOrderQuantity: number, prepackQuantity: number, totalPrePackRatio: number): number {
      return (totalSubOrderQuantity * prepackQuantity) / totalPrePackRatio;
   }


   /************************************* END: SUB ORDER DETAILS ****************************/


   /************************************* START: SIZE ORDER DETAILS *************************/

   onClickSelectSizes(orderDetail: OrderDetail, orderDetailIndex: string) {
      this.ordeDetailSizeQuantityListForModal = this.salesHelperService.prepareSizeListForModal(orderDetail, this.sizesMetadataList);
      const confirmDialog = this.dialog.open(SizeSelectionModalComponent, {
         disableClose: true,
         data: {
            ordeDetailSizeQuantityListForModal: this.ordeDetailSizeQuantityListForModal
         }
      });
      confirmDialog.afterClosed().subscribe(res => {
         if (res) {
            this.onSizeSelectionFromModal(this.orderDetail, false);
         } else {
            //alert('Cancelled');
         }
      });
   }

   doesSizeExistsForAnOrderDetail(sizeFromModal: string): boolean {
      let orderDetail = this.orderDetail;
      for (let index = 0; index < orderDetail?.orderDetailSizeQuantityList?.length; index++) {
         let sizeOrderFromDetail = orderDetail.orderDetailSizeQuantityList[index].size;
         if (sizeFromModal === sizeOrderFromDetail) {
            return true;
         }
      }
      return false;
   }


   /************************************* END: SIZE ORDER DETAILS *****************************/

   onPackagingTypeChangeOrPrepackClick(orderDetail: OrderDetail, orderDetailIndex: number, subOrderDetail: SubOrderDetail, subOrderDetailIndex: number, packagingType: string, newValue?: string) {
      let prePackModalCopy = JSON.parse(JSON.stringify(subOrderDetail));//must be saved before setting new vlue for cancel to work
      subOrderDetail.packagingType = newValue;
      if (newValue === 'Prepack') {
         subOrderDetail.packagingType = newValue;
         const confirmDialog = this.dialog.open(PackagingTypeSelectionModalComponent, {
            disableClose: true,
            data: {
               currentSubOrderDetail: subOrderDetail,
               editable: this.editable
            }
         });
         confirmDialog.afterClosed().subscribe(res => {
            if (res && res === 'CANCEL') {
               this.salesOrder.orderDetails[orderDetailIndex].subOrderDetailList[subOrderDetailIndex] = JSON.parse(JSON.stringify(prePackModalCopy));
               subOrderDetail = JSON.parse(JSON.stringify(prePackModalCopy));
            } else if (res && res === 'CONFIRM') {
               // alert('CONFIRM');
               this.onPrePackDetailModalOk(subOrderDetail);
            } else {
               //alert('CLOSE');
            }
         });
      } else {
         subOrderDetail.prepackDetail=undefined;
         subOrderDetail.sizeOrderDetailList.forEach(element => {
            element.prepackQuantity = 0;
         });
      }
   }

   onPrePackDetailModalOk(currentSubOrderDetail: SubOrderDetail) {
      let totalSubOrderQuantity: number = currentSubOrderDetail.totalSubOrderQuantity;
      let totalPrePackRatio: number = 0;
      let prepackDetail: string = '';
      for (let index = 0; index < currentSubOrderDetail.sizeOrderDetailList.length; index++) {
         let sizeOrderDetail: SizeOrderDetail = currentSubOrderDetail.sizeOrderDetailList[index];
         totalPrePackRatio += sizeOrderDetail.prepackQuantity;
         prepackDetail = prepackDetail + sizeOrderDetail.prepackQuantity;
         if (index !== (currentSubOrderDetail.sizeOrderDetailList.length - 1)) {
            prepackDetail = prepackDetail + '/'
         }
      }

      if (totalSubOrderQuantity % totalPrePackRatio !== 0) {
         this.toastrService.error('Total Quantity does not match and Prepack Ratio.');
         return false;
      }

      currentSubOrderDetail.prepackDetail = prepackDetail;

      for (let index = 0; index < currentSubOrderDetail.sizeOrderDetailList.length; index++) {
         let sizeOrderDetail: SizeOrderDetail = currentSubOrderDetail.sizeOrderDetailList[index];
         sizeOrderDetail.quantity = this.getQuantityBasedOnPrepack(totalSubOrderQuantity, sizeOrderDetail.prepackQuantity, totalPrePackRatio);
      }
      this.reCalculateFullSalesOrder();
   }

   /************************************* END: SUB ORDER DETAILS ****************************/


   /************************************* START: ORDER DETAIL *******************************/

   deleteOrderDetail(orderDetailToDelete: OrderDetail) {
      if (!this.canOrderDetailBeDeleted()) {
         this.toastrService.error('Cannot Delete Only Last Order.');
         return;
      } else {
         this.deleteConfirmDialog(orderDetailToDelete);
      }
   }

   deleteConfirmDialog(orderDetailToDelete: OrderDetail) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
         disableClose: true,
         data: {
            title: 'Delete Order Detail',
            message: 'Are you sure you want to delete the Order Detail - ' + orderDetailToDelete.product.sku + '?',
            confirmBtnText: 'Yes',
            cancelBtnText: 'Cancel'
         }
      });
      confirmDialog.afterClosed().subscribe(res => {
         if (res) {
            orderDetailToDelete.deleteFlag = true;
            this.reCalculateFullSalesOrder();
         } else {

         }
      });
   }


   cancelOrderDetail(orderDetail: OrderDetail) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
         disableClose: true,
         data: {
            title: 'Cancel Order Detail',
            message: 'Please note, Order detail once cancelled cannot be reverted. Are you sure you want to cancel the Order Detail - ' + orderDetail.product.sku + '?',
            confirmBtnText: 'Yes',
            cancelBtnText: 'No'
         }
      });
      confirmDialog.afterClosed().subscribe(res => {
         if (res) {
            this.salesHelperService.updateAndPropogateOrderDetailStatus(orderDetail, 'CANCELLED', 'CANCELLED');
            this.reCalculateFullSalesOrder();
         } else {

         }
      });
   }
   /*cancelOrderDetail(orderDetail: OrderDetail) {
      if (confirm('Are you sure you want to cancel the order detail for this style  ?')) {
        
         //this.updateSalesOrder(this.salesOrder);
      }
   }*/

   onOrderDetailSingleCostChange(salesOrder: SalesOrder, orderDetail: OrderDetail) {
      this.reCalculateFullSalesOrder();
   }

   onOrderDetailSingleRetailCostChange(salesOrder: SalesOrder, orderDetail: OrderDetail) {
      this.reCalculateFullSalesOrder();
   }

   onOrderDetailIndividualCostChange(salesOrder: SalesOrder, orderDetail: OrderDetail) {
      this.reCalculateFullSalesOrder();
   }

   canOrderDetailBeDeleted(): boolean {
      let totalOrderDetailCount: number = this.salesOrder.orderDetails?.length;
      let totalOrderDetailDeletedCount: number = this.salesHelperService.totalOrderDetailDeletedCount(this.salesOrder);
      if (totalOrderDetailCount - totalOrderDetailDeletedCount > 1) {
         return true;
      } else {
         return false;
      }
   }

   onTrimSelection(event: any, item: string, orderDetail: OrderDetail) {
      if (event.target.checked) {
         if (!this.checkIfItemExists(orderDetail.trimsList, item)) {
            orderDetail.trimsList.push(item);
         }
      } else {
         orderDetail.trimsList = orderDetail.trimsList.filter(itemInList => itemInList !== item);
      }
   }

   checkIfItemExists(list: string[], item: string) {
      if (list) {
         let temp = list.filter(itemInList => itemInList === item);
         return (temp.length == 1);
      } else {
         return false;
      }
   }

   doesOrderDetailContainsTrim(orderDetail: OrderDetail, trim: string) {
      let trimsListFromOrder: string[] = orderDetail.trimsList;
      for (let index = 0; index < trimsListFromOrder.length; index++) {
         if (trimsListFromOrder[index] === trim) {
            return true;
         }
      }
      return false;
   }
   getAddlTrimsList(orderDetail: OrderDetail):string[]{
      let trimsListFromOrder: string[] = orderDetail.trimsList;
      let addlTrims=trimsListFromOrder.filter(x=>!this.trimsListFromMetadata.includes(x))
      //console.log('Addl Trims',addlTrims);
      return addlTrims;
   }
   onAddlTrimEntered(item: string, orderDetail: OrderDetail) {
      if (item) {
         if (!this.checkIfItemExists(orderDetail.trimsList, item)) {
            orderDetail.trimsList.push(item);
         }
      } else {
         orderDetail.trimsList = orderDetail.trimsList.filter(itemInList => itemInList !== item);
      }
   }
   
   onChangeIndividualCostCheckBox(event: any, orderDetail: OrderDetail) {
      if (event.target.checked) {
         orderDetail.cost = 0;
         for (let index = 0; index < orderDetail.orderDetailSizeQuantityList.length; index++) {
            let orderDetailSizeQuantity: OrderDetailSizeQuantity = orderDetail.orderDetailSizeQuantityList[index];
            orderDetailSizeQuantity.cost = null;
         }
      } else {
         orderDetail.cost = null;
         for (let index = 0; index < orderDetail.orderDetailSizeQuantityList.length; index++) {
            let orderDetailSizeQuantity: OrderDetailSizeQuantity = orderDetail.orderDetailSizeQuantityList[index];
            orderDetailSizeQuantity.cost = 0;
         }
      }
   }

   /************************************* END: ORDER DETAIL *******************************/


   /**************** START: PRODUCT APPROVAL STATUS COLOURS *******************************/

   approvalStatusColor(status: string): string {
      if (this.sharedService.producatStatusColor.get(status) !== undefined) {
         return this.sharedService.producatStatusColor.get(status);
      } else {
         return '';
      }
   }

   /**************** END: PRODUCT APPROVAL STATUS COLOURS *******************************/

   onChangeSizeOverrideCheckBox(event: any, orderDetail: OrderDetail) {
      let overrideSize: boolean;
      if (event.target.checked) {
         overrideSize = true;
      } else {
         overrideSize = false;
         this.salesHelperService.propogateSizeOverrideDetail(orderDetail, null, null, overrideSize);
      }
   }

   resetSampleQty(type: string, orderDetail: OrderDetail) {
      //console.log('PP SAmple Requ value in method: '+ orderDetail.ppSampleRequired);
      if (
         (type === 'PP_SAMPLE' && !orderDetail.ppSampleRequired)
         || (type === 'TOP_SAMPLE' && !orderDetail.topSampleRequired)
      ) {
         orderDetail.subOrderDetailList.forEach(subOrderDetail => {
            if (subOrderDetail.type === type) {
               subOrderDetail.sizeOrderDetailList.forEach(SizeOrderDetail => {
                  SizeOrderDetail.quantity = 0;
               });
            }
         });
      }
      this.reCalculateFullSalesOrder();
   }

   resetPnlSpecialityRequired(orderDetail: OrderDetail){
      orderDetail.pnlSpecialityType=undefined;
      orderDetail.pnlSpeciality=undefined;
   }

   isLicensorPreProApprovalMessageRequired(product){
      return this.sharedService.isLicensorPreProApprovalMessageRequired(product);
    //  return product.licensingPreProApprovalRequired && !(this.sharedService.licPreProApprovalNotRequiredStatus.includes(product.approvalStatus));
   }
}
