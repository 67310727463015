import { Component, Input, OnInit } from '@angular/core';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import { ChangeContainer } from '../../model/common/change-container.model';


/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */
 

const TREE_DATA: ChangeContainer[] = [{
	"description": "Sales Order# 202111000010",
	"category": "SALES_ORDER",
	"subCategory": "RESUBMISSION",
	"subType": "",
	"level": 1,
	"objectId": "4028b8817cfdd4a6017d020898f40013",
	"presentInPrevious": true,
	"presentInNew": true,
	"changes": [{
			"commitMetadata": {
				"present": false
			},
			"changeType": "PROPERTY_VALUE_CHANGED",
			"propertyName": "totalCost",
			"left": 470,
			"right": 488,
			"propertyAdded": false,
			"propertyValueChanged": true,
			"propertyNameWithPath": "totalCost",
			"propertyRemoved": false,
			"affectedGlobalId": {
				"typeName": "SalesOrder",
				"cdoId": "4028b8817cfdd4a6017d020898f40013"
			},
			"affectedLocalId": "4028b8817cfdd4a6017d020898f40013",
			"affectedObject": {
				"present": true
			}
		}
	],
	"groupedChanges": [],
	"changeContainers": [{
			"description": "Order for SKU# B291234098",
			"category": "ORDER_DETAIL",
			"subCategory": "RESUBMISSION",
			"subType": "",
			"level": 2,
			"objectId": "4028b8817d89200d017d892aa6e10044",
			"presentInPrevious": true,
			"presentInNew": true,
			"changes": [{
					"commitMetadata": {
						"present": false
					},
					"changeType": "PROPERTY_VALUE_CHANGED",
					"propertyName": "cost",
					"left": 11,
					"right": 12,
					"propertyAdded": false,
					"propertyValueChanged": true,
					"propertyNameWithPath": "cost",
					"propertyRemoved": false,
					"affectedGlobalId": {
						"typeName": "OrderDetail",
						"cdoId": "4028b8817d89200d017d892aa6e10044"
					},
					"affectedLocalId": "4028b8817d89200d017d892aa6e10044",
					"affectedObject": {
						"present": true
					}
				}, {
					"commitMetadata": {
						"present": false
					},
					"changeType": "PROPERTY_VALUE_CHANGED",
					"propertyName": "totalCost",
					"left": 198,
					"right": 216,
					"propertyAdded": false,
					"propertyValueChanged": true,
					"propertyNameWithPath": "totalCost",
					"propertyRemoved": false,
					"affectedGlobalId": {
						"typeName": "OrderDetail",
						"cdoId": "4028b8817d89200d017d892aa6e10044"
					},
					"affectedLocalId": "4028b8817d89200d017d892aa6e10044",
					"affectedObject": {
						"present": true
					}
				}
			],
			"groupedChanges": [],
			"changeContainers": [],
			"type": "MODIFIED",
			"modified": true,
			"removed": false,
			"added": false
		}
	],
	"type": "MODIFIED",
	"modified": true,
	"removed": false,
	"added": false
}
];


@Component({
  selector: 'app-change-tree',
  templateUrl: './change-tree.component.html',
  styleUrls: ['./change-tree.component.css']
})
export class ChangeTreeComponent implements OnInit {

	@Input() changeContainerArr:ChangeContainer[];
  ngOnInit(): void {
	this.dataSource.data = this.changeContainerArr;
	console.log('DATA -> ',this.dataSource.data, this.changeContainerArr);
  }
  
  treeControl = new NestedTreeControl<ChangeContainer>(node => node.changeContainers);
  dataSource = new MatTreeNestedDataSource<ChangeContainer>();

  constructor() {
    
  }

  hasChild = (_: number, node: ChangeContainer) => (!!node.changeContainers && node.changeContainers.length > 0) || (!!node.changes && node.changes.length > 0) || (!!node.groupedChanges && node.groupedChanges.length > 0);

}
