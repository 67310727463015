import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';


import { Licensor, LicensorCostMatrix, LicensorSampleDetails } from '../model/licensor.model';
import { Brand } from '../model/brand.model';
import { SearchCriteria } from '../model/search-criteria.model';
import { ApiResponse } from '../model/api-response';
import { SalesOrder } from '../model/sales/sales-order.model';
//import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LicensorService {
  

  //readonly rooturl="http://192.168.0.19:8080";
  readonly rooturl=environment.apiEndPoint;
  constructor(private http:HttpClient) { }
  createLicensor(licensor: Licensor) {
    return this.http.post(this.rooturl+"/licensor/create",licensor);
  }
  updateLicensor(licensor: Licensor) {
    return this.http.post(this.rooturl+"/licensor/update",licensor);
  }
  loadLicensors(): Observable<Licensor[]>{
    return this.http.get<Licensor[]>(this.rooturl+"/licensor/list");
  }

  createBrand(brand: Brand) {
    return this.http.post(this.rooturl+"/brand/create",brand);
  }
  
  deleteBrand(brandId:string){
    return this.http.get(this.rooturl+"/brand/delete/"+brandId);
  }

  deleteLicensor(licensorId:string){
    return this.http.get(this.rooturl+"/licensor/delete/"+licensorId);
  }
  loadLicensorWithBrands(licensorId:string){
    return this.http.get(this.rooturl+"/licensor/licensorWithBrands/"+licensorId);
  }
  
  loadLicensorWithOnlyBrands(licensorId:string,includeAttachments:boolean){
    return this.http.get(this.rooturl+"/licensor/licensorWithOnlyBrands/"+licensorId+"/"+includeAttachments);
  }
  /*loadLicensorWithBrandAndProduct(searchCriteria:SearchCriteria){
    return this.http.get(this.rooturl+"/licensor/licensorWithBrandAndProduct/"+searchCriteria.licensorId+"/"+searchCriteria.brandId+"/"+searchCriteria.productId);
  }*/
  
   getDefaultSampleSizeList(): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.rooturl + '/licensor/sample/list/default', null);
   }

   getSampleDetailsWithSummation(licensorId, brandId) {
      return this.http.get<LicensorSampleDetails>(this.rooturl + '/licensor/licensorSampleDetailsWithSummation/'+licensorId+'/'+brandId);
   }

   getLicensorCost(licensorId:string,brandId:string, category:string,garment:string) {
    return this.http.get<LicensorCostMatrix>(this.rooturl + '/licensor/licensorCost/'+licensorId+'/'+brandId+'/'+category+'/'+garment);
  }

   getSampleListByLicensorIdAndBrandId(licensorId, brandId): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.rooturl + '/licensor/sample/list/' + licensorId + '/' + brandId, null);
   }

   getLicensorSampleListForSalesOrder(salesOrder: SalesOrder): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.rooturl + '/licensor/sample/list/salesOrder', salesOrder);
   }
   
  
}
