import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SalesOrder } from 'src/app/shared/model/sales/sales-order.model';
import { OrderDetail } from 'src/app/shared/model/sales/order-detail.model';

@Component({
   selector: 'app-order-detail-viewer-dialog',
   templateUrl: './order-detail-viewer-dialog.component.html',
   styleUrls: ['./order-detail-viewer-dialog.component.css']
})
export class OrderDetailViewerDialogComponent implements OnInit {


   salesOrder: SalesOrder;
   orderDetail: OrderDetail;
   orderDetailIndex: number;

   constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<OrderDetailViewerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = false;

      if (data.salesOrder) {
         this.salesOrder = data.salesOrder;
      }
      if (data.orderDetail) {
         this.orderDetail = data.orderDetail;
      }
      if (data.orderDetailIndex) {
         this.orderDetailIndex = data.orderDetailIndex;
      }

   }

   ngOnInit(): void {
   }

}
