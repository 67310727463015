import { Component, OnInit, Input } from '@angular/core';
import { BrandService } from 'src/app/shared/service/brand.service';
import { ActivatedRoute } from '@angular/router';
import { Brand } from 'src/app/shared/model/brand.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
   selector: 'app-brand-list',
   templateUrl: './brand-list.component.html',
   styleUrls: ['./brand-list.component.css']
})
export class BrandListComponent implements OnInit {

   constructor(private sharedService: SharedService, private service: BrandService, 
      private activatedRoute: ActivatedRoute,private authService:AuthService) { }

   groupedBrandList: any;
   brandList: Brand[] = [];
   filteredBrandList: Brand[] = [];
   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   filterEventsubscription: Subscription;
   editable: boolean;
   @Input() hideBrandFilter: String;

   ngOnInit() {
      this.handleSubscriptions();
      this.loadLicensors();
      window.scrollTo(0, 0);
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
      this.filterEventsubscription = this.authService.filterEvent.subscribe(filterEventData => {
         if (filterEventData != null) {
            this.onKeyupBrandFilter(filterEventData.event, filterEventData.value);
         }
      });
   }

   loadLicensors() {
      this.service.loadBrandList().subscribe((res: Brand[]) => {
         this.brandList = res;
         this.filteredBrandList = this.brandList;
         this.filteredBrandList =this.sharedService.sortListByPropertyName(this.filteredBrandList,'brandName')
         this.groupList();
      }, err => {
         console.error('Load Brand List Error : ' + JSON.stringify(err) + ' with status - ');
      });
   }

   onKeyupBrandFilter(event, value) {
      if (event.key !== 'Shift') {
         if (value.trim().length > 0) {
            this.filteredBrandList = this.brandList.filter(items => 
               items.brandName.toUpperCase().indexOf(value.toUpperCase()) > -1 
            ||  items.skuCode.toUpperCase().indexOf(value.toUpperCase())>-1
            ||  items.licensorName.toUpperCase().indexOf(value.toUpperCase())>-1);
         } else {
            this.filteredBrandList = this.brandList;
         }
      }
      this.groupList();
   }

   deletebrand(brand: Brand) {
   }

   /**
    * This method groups the list.
    */
   groupList() {
      let grouped = this.filteredBrandList.reduce((groups, brand) => {
         const letter = brand.brandName.charAt(0).toUpperCase();
         groups[letter] = groups[letter] || [];
         groups[letter].push(brand);
         return groups;
      },
         {}
      );
      let groupedList = Object.keys(grouped).map(key => ({ key, brands: grouped[key] }));
      this.groupedBrandList = groupedList;
   }

}
