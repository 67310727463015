export class Address {
   id: string;
   addressType: string;
   streetNumber: string;
   streetName: string;
   apartmentNumber: string;
   city: string;
   provinceCode: string;
   provinceName: string;
   countryCode: string;
   countryName: string;
   postalCode: string;
   transientIndex: number;

   constructor(){
      this.id=null;
      this.addressType=null;
      this.streetNumber=null;
      this.streetName=null;
      this.apartmentNumber=null;
      this.city=null;
      this.provinceCode=null;
      this.provinceName=null;
      this.countryCode=null;
      this.countryName=null;
      this.postalCode=null;
   }
}
