export class RoutingInfo {
    id:string;
    salesOrderId:string;

    routingType:string;
    routingDeadline:Date;
    routingStatus:string;

    deleteFlag:boolean=false;
    origination:string;
    version:number=0;

    constructor(salesOrderId:string){
        this.origination='MANUAL';
        this.salesOrderId=salesOrderId;
    }
}
