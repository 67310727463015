import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SearchCriteriaBuilder } from 'src/app/shared/model/common/search-criteria-builder.model';
import { ChargeBack } from '../../model/accounts/charge-back.model';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../model/api-response';
import { FileHandlingService } from '../file-handling.service';
import { ProductAttachment } from '../../model/product.model';
import { ToastrService } from 'ngx-toastr';

@Injectable({
   providedIn: 'root'
})
export class ChargeBackService {
   constructor(private http: HttpClient, private fileHanldingService: FileHandlingService, private toastrService: ToastrService) { }

   readonly rooturl = environment.apiEndPoint;

   getChargeBackById(id: string): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.rooturl + '/chargeback/' + id, null);
   }

   createChargeBackExcel(searchCriteria: SearchCriteriaBuilder) {
      return this.http.post(this.rooturl + '/chargeback/export/excel', searchCriteria, { responseType: 'blob' });
   }

   createChargeBack(cb: ChargeBack): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/chargeback/create', cb);
   }

   updateChargeBack(cb: ChargeBack): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/chargeback/update', cb);
   }

   deleteChargeBack(cb: ChargeBack): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/chargeback/delete', cb);
   }


   /* ***************************** START: SERVICES FOR Charge Back SELECTION ***************************** */

   selectedChargeBackList: ChargeBack[] = [];

   isChargeBackSelected(itemId: string): boolean {
      if (this.selectedChargeBackList && this.selectedChargeBackList.length > 0) {
         let temp: ChargeBack[] = this.selectedChargeBackList.filter(itemList => itemList.id === itemId);
         if (temp.length == 1) {
            return true;
         } else {
            return false;
         }
      } else {
         return false;
      }
   }

   selectionChanged(cb: ChargeBack) {
      if (!this.isChargeBackSelected(cb.id)) {
         this.selectedChargeBackList.push(cb);
      } else {
         this.selectedChargeBackList = this.selectedChargeBackList.filter(item => item.id !== cb.id);
      }
   }

   resetSelectedChargeBackList() {
      this.selectedChargeBackList = [];
   }

   isCBDocumentAvailable(customerStatusListForRequiredFieldValidation: string[], cb: ChargeBack): boolean {
      if (customerStatusListForRequiredFieldValidation.includes(cb?.customerStatus) && this.getChargeBackDocumentCount(cb) === 0) {
         this.toastrService.error("Please upload ChargeBack Document.");
         return false;
      }
      return true;
   }

   /* ***************************** END: SERVICES FOR Charge Back SELECTION ***************************** */


   /******************************* START: File Handling **************************************************/

   filesUploadPostUpdate: File[] = [];

   loadAttachments(chargeBack: ChargeBack) {
      let attachment: ProductAttachment = new ProductAttachment;
      attachment.parentId = chargeBack.salesOrderId;
      attachment.fileCategory = 'SALES_CHARGE_BACK_COMMUNICATION_FILES';
      this.fileHanldingService.loadAttachmentsUrls(chargeBack, attachment, 'chargeBackAttachments');
   }

   uploadAttachments(updatedChargeBack: ChargeBack, originalChargeBack: ChargeBack, createAction: boolean) {
      this.deleteAttachments(originalChargeBack, createAction);
      let attachment: ProductAttachment = new ProductAttachment;
      attachment.parentId = originalChargeBack.salesOrderId;
      attachment.fileCategory = 'SALES_CHARGE_BACK_COMMUNICATION_FILES';
      if (originalChargeBack.chargeBackAttachments && originalChargeBack.chargeBackAttachments.length > 0) {
         originalChargeBack.chargeBackAttachments.forEach(att => {
            //console.log('Uploading file - group referendce Id check', att);
            if (!att.groupReferenceId) {//New file uplaoded
               //console.log('Uploading file - setting group refeence', att);
               att.groupReferenceId = updatedChargeBack.id;
            }
            if (att.islocal) {
               //let index:number=parseInt(att.id)+1;
               let tempFile = new File([att.localfile], updatedChargeBack.chargeBackNo + '_' + att.originalFileName, { type: att.localfile.type });
               //console.log('create new file', tempFile, att.localfile);
               att.localfile = tempFile;
               //att.localfile.name=this.chargeBack.chargeBackNo+'_'+att.originalFileName;
            }
            //console.log('Uploading file - After stting group refeence', att);
         });
      }
      this.fileHanldingService.uploadLocalFileToRepoNonBlockingGroupedAttachments(originalChargeBack.chargeBackAttachments, originalChargeBack, 'salesOrderNo', attachment, 'chargeBackAttachments');
   }

   async deleteAttachments(chargeBack: ChargeBack, createAction: boolean) {
      await this.fileHanldingService.deleteMarkedFile(chargeBack.chargeBackAttachments, createAction);
   }

   getGroupItemForChargeBack(chargeBack: ChargeBack) {
      let retVal = [];
      retVal.push(chargeBack);
      return retVal;
   }

   /******************************* END: File Handling **************************************************/

   getChargeBackDocumentCount(cb: ChargeBack): number {
      let docCount: number = 0;;
      if (cb.chargeBackAttachments && cb.chargeBackAttachments.length > 0) {
         let currentCBAttArr = cb.chargeBackAttachments.filter(x => x.groupReferenceId === cb.id);
         let deletedList = cb.chargeBackAttachments.filter(x => x.toDelete === true);
         docCount = (currentCBAttArr.length - deletedList.length);
         if (docCount < 0) {
            docCount = 0;
         }
      }
      return docCount;
   }

}
