<!--BLANKS SECTION STARTS-->
<div >
    <form #blankInfoForm="ngForm"  autocomplete="off" novalidate><!-- ngModelGroup="blanksInfo" #blanksInfoCtrl="ngModelGroup"-->
        <!--<h6 class="mt-4">Blanks Info</h6>
        <hr class="hr-min">-->
        <div class="d-flex justify-content-end mr-4 mt-2">
    
            <button type="button" class="btn btn-sm btn-warning ml-2" (click)="edit()" *ngIf="!editable && userAccess?.canEditProductionTracker">Edit</button>
            <button type="button" class="btn btn-sm btn-success ml-2" (click)="save()" *ngIf="editable" [disabled]="!blankInfoForm.valid">Save</button>
            <button type="button" class="btn btn-sm btn-dark ml-2" (click)="cancel()" *ngIf="editable">Cancel</button>
        </div>
        <div class="p-2 m-3" *ngIf="productionTracker.fullPackageOrder || getBlankSupplierCount(productionTracker.blanksInfoList)===0">
            <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" name="fullPackageOrder_{{productionTracker.id}}" id="fullPackageOrder" [(ngModel)]="productionTracker.fullPackageOrder" [disabled]="!editable">
                <label class="custom-control-label" for="fullPackageOrder">Full Package Order</label>
            </div>
        </div>
        <div *ngIf="!productionTracker.fullPackageOrder">
            <div class="p-2 m-3" *ngIf="getBlankSupplierCount(productionTracker.blanksInfoList)===0  && !editable"><h6  class="text-muted f-13">No Blanks info available</h6></div>
            <ng-container *ngFor="let blanksInfo of productionTracker.blanksInfoList; index as blanksInfoIndex">
                <div *ngIf="blanksInfo.supplierId && blanksInfo.deleteFlag===false" class="mt-2">
                    <h6 >{{blanksInfo.blankSupplierName}} {{blanksInfo.printerName?' to '+blanksInfo.printerName:'' }}</h6>
                    <hr class="hr-min">
                </div> 
                <div class="p-2 m-3 border border-3 rounded " style="position: relative;" [style]="blanksInfoIndex%2===1?'':''" *ngIf="blanksInfo.deleteFlag===false">
                    <div class="overlay-with-text-top" *ngIf=" blanksInfo.obsolete">Un-Assigned</div>
                    <div class="row">
                    <div style="position: absolute;right:0%;" (click)="deleteBlankInfo(blanksInfo)" *ngIf="editable && blanksInfo.origination==='MANUAL'"><i class="fas fa-trash text-danger shadow"></i></div>
                    <div class="col-2" *ngIf="blanksSupplierList && blanksSupplierList.length>0">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Blanks Supplier</label>
                        <select class="form-control form-control-sm custom-select" [(ngModel)]="blanksInfo.supplierId"  name="blanks_{{blanksInfoIndex}}"  style="font-size: 11px;"
                            #blanksNameVar="ngModel" required  [ngClass]="{'is-invalid':blanksNameVar.touched && blanksNameVar.errors}" disabled
                             (ngModelChange)="getBlankName(blanksInfo)"><!--[disabled]="!editable || blanksInfo.obsolete"-->
                            <option *ngFor="let blanks of blanksSupplierList" [ngValue]="blanks.id">
                                {{blanks.name}}</option>
                        </select>
                        <div *ngIf="blanksNameVar?.invalid && (blanksNameVar.dirty || blanksNameVar.touched)" class="invalid-feedback">
                            <div *ngIf="blanksNameVar.errors.required">
                                Blanks Supplier is required.
                            </div>
                        </div>
                    </div>

                    <div class="col-2" *ngIf="printerList && printerList.length>0">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Printer</label>
                        <select class="form-control form-control-sm custom-select" [(ngModel)]="blanksInfo.printerId"  name="printer_{{blanksInfoIndex}}"  style="font-size: 11px;"
                            #printerNameVar="ngModel" required  [ngClass]="{'is-invalid':printerNameVar.touched && printerNameVar.errors}" disabled
                             (ngModelChange)="getPrinterName(blanksInfo)"><!--[disabled]="!editable || blanksInfo.obsolete"-->
                            <option *ngFor="let printer of printerList" [ngValue]="printer.id">
                                {{printer.name}}</option>
                        </select>
                        <div *ngIf="printerNameVar?.invalid && (printerNameVar.dirty || printerNameVar.touched)" class="invalid-feedback">
                            <div *ngIf="printerNameVar.errors.required">
                                Printer is required.
                            </div>
                        </div>
                    </div>

                    <div class="col-2">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Blanks Status</label>
                        <select class="form-control form-control-sm custom-select" [(ngModel)]="blanksInfo.blanksStatus"  name="blanksStatus_{{blanksInfoIndex}}"  style="font-size: 11px;"
                            #blanksStatusVar="ngModel" required  [ngClass]="{'is-invalid':blanksStatusVar.touched && blanksStatusVar.errors}"
                            [disabled]="!editable || blanksInfo.obsolete">
                            <option value="ORDER_PENDING">Order Pending</option>
                            <option value="ORDER_PLACED">Order Placed</option>
                            <option value="IN_TRANSIT">In Transit</option>
                            <option value="DELIVERED">Delivered</option>
                            <option value="SHIPPED_WITH_SHORTAGE">Shipped With Shortage</option>
                            <option value="SHIPPED_WITH_BACKORDER">Shipped With Backorder</option>
                        </select>
                        <div *ngIf="blanksStatusVar?.invalid && (blanksStatusVar.dirty || blanksStatusVar.touched)" class="invalid-feedback">
                            <div *ngIf="blanksStatusVar.errors.required">
                                Trims Delivery Status is required.
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-2">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Philcos PO#</label>
                        <input name="philcosBlanksPO_{{blanksInfoIndex}}" class="form-control form-control-sm" style="font-size: 11px;" #philcosBlanksPO="ngModel" required 
                        [(ngModel)]="blanksInfo.philcosBlanksPO" [ngClass]="{'is-invalid':philcosBlanksPO.touched && philcosBlanksPO.errors}"
                        [disabled]="!editable || blanksInfo.blanksStatus==='ORDER_PENDING' || blanksInfo.obsolete">
                        <div *ngIf="philcosBlanksPO?.invalid && (philcosBlanksPO.dirty || philcosBlanksPO.touched)" class="invalid-feedback">
                        <div *ngIf="philcosBlanksPO.errors.required">
                            Blanks PO# is required.
                        </div>
                        </div>
                    </div>


                    <!--<div class="col-2">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Blanks Tracking#</label>
                        <input name="blanksTrackingNo_{{blanksInfoIndex}}" class="form-control form-control-sm" style="font-size: 11px;" #blanksTrackingNo="ngModel" required 
                        [(ngModel)]="blanksInfo.blanksTrackingNo" [ngClass]="{'is-invalid':blanksTrackingNo.touched && blanksTrackingNo.errors}"
                        [disabled]="!editable">
                        <div *ngIf="blanksTrackingNo?.invalid && (blanksTrackingNo.dirty || blanksTrackingNo.touched)" class="invalid-feedback">
                        <div *ngIf="blanksTrackingNo.errors.required">
                            Blanks Tracking# is required.
                        </div>
                        </div>
                    </div>-->
                    <div class="col-2">
                        <app-tracking-link [editable]="editable" [linksArray]="blanksInfo.blanksTrackingLinks" [type]="'BLANKS_INFO'" [subType]="'BLANKS_TRACKING'"></app-tracking-link>
                    </div>
                    <!--
                    <div class="col-2">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Invoice Value</label>
                        <input name="invoiceValue_{{blanksInfoIndex}}" class="form-control form-control-sm" style="font-size: 11px;" #invoiceValue="ngModel" required 
                        [(ngModel)]="blanksInfo.invoiceValue" [ngClass]="{'is-invalid':invoiceValue.touched && invoiceValue.errors}"
                        [disabled]="!editable">
                        <div *ngIf="invoiceValue?.invalid && (invoiceValue.dirty || invoiceValue.touched)" class="invalid-feedback">
                        <div *ngIf="invoiceValue.errors.required">
                            Invoice Value is required.
                        </div>
                        </div>
                    </div>

                    <div class="col-2">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Invoice Currency</label>
                        <select class="form-control form-control-sm custom-select" [(ngModel)]="blanksInfo.invoiceCurrency"  name="invoiceCurrency_{{blanksInfoIndex}}"  style="font-size: 11px;"
                            #invoiceCurrencyVar="ngModel" required  [ngClass]="{'is-invalid':invoiceCurrencyVar.touched && invoiceCurrencyVar.errors}"
                            [disabled]="!editable">
                            <option value="CAD">CAD</option>
                            <option value="USD">USD</option>
                        </select>
                        <div *ngIf="invoiceCurrencyVar?.invalid && (invoiceCurrencyVar.dirty || invoiceCurrencyVar.touched)" class="invalid-feedback">
                            <div *ngIf="invoiceCurrencyVar.errors.required">
                                Invoice Currency is required.
                            </div>
                        </div>
                    </div>
                -->

                
                
                    <div class="col-2">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Blanks ETA</label>
                        <div class="d-flex d-inline">
                        <input matInput [matDatepicker]="blanksEta"  name="blanksEta_{{blanksInfoIndex}}" [(ngModel)]="blanksInfo.blanksEta" style="cursor: pointer;font-size: 11px;"
                            class="form-control  form-control-sm" required #blanksEtaVar="ngModel" [ngClass]="{'is-invalid':blanksEtaVar.touched && blanksEtaVar.errors}"
                            [disabled]="!editable || blanksInfo.blanksStatus==='ORDER_PENDING' || blanksInfo.obsolete">
                        <mat-datepicker #blanksEta></mat-datepicker>
                        <button class="btn btn-sm" type="button" (click)="blanksEta.open()" [disabled]="!editable"><i class="fas fa-calendar-alt"></i></button>
                        </div>
                        <mat-error *ngIf="blanksEtaVar?.errors?.required && (blanksEtaVar.dirty || blanksEtaVar.touched)" style="font-size: 80%;color: #dc3545">
                        Blanks ETA is required.
                        </mat-error>
                        <div *ngIf="blanksEtaVar?.invalid && (blanksEtaVar.dirty || blanksEtaVar.touched)" class="invalid-feedback">
                        <div *ngIf="blanksEtaVar.errors.required">Blanks ETA is required.</div>
                        </div>
                    </div>

                    <div class="col-2">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Printer Delivery Date</label>
                        <div class="d-flex d-inline">
                        <input matInput [matDatepicker]="printerDeliveryDate"  name="printerDeliveryDate_{{blanksInfoIndex}}" [(ngModel)]="blanksInfo.printerDeliveryDate" style="cursor: pointer;font-size: 11px;"
                            class="form-control  form-control-sm"  #printerDeliveryDateVar="ngModel" [ngClass]="{'is-invalid':printerDeliveryDateVar.touched && printerDeliveryDateVar.errors}"
                            [disabled]="!editable || blanksInfo.obsolete">
                        <mat-datepicker #printerDeliveryDate></mat-datepicker>
                        <button class="btn btn-sm" type="button" (click)="printerDeliveryDate.open()" [disabled]="!editable"><i class="fas fa-calendar-alt"></i></button>
                        </div>
                        <mat-error *ngIf="printerDeliveryDateVar?.errors?.required && (printerDeliveryDateVar.dirty || printerDeliveryDateVar.touched)" style="font-size: 80%;color: #dc3545">
                        Printer Delivery Date is required.
                        </mat-error>
                        <div *ngIf="printerDeliveryDateVar?.invalid && (printerDeliveryDateVar.dirty || printerDeliveryDateVar.touched)" class="invalid-feedback">
                        <div *ngIf="printerDeliveryDateVar.errors.required">Printer Delivery Date is required.</div>
                        </div>
                    </div>

                </div>
                </div>
            
            </ng-container>
            <div class=" my-2 pl-4">
                <button type="button" class="btn btn-sm btn-info" (click)="addBlankInfo()" *ngIf="false && editable">Add Blanks Supplier</button>
            </div>
        </div>
    </form>
</div>
<!--BLANKS SECTION ENDS-->