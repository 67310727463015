export class Metadata {
    id: string;
    metadataClassification: string;
    metadataType: string;
    metadataSubType: string;
    metadataValue: string;
    sortOrder:number;

    constructor(){
        this.id = null;
        this.metadataClassification = null;
        this.metadataType = null;
        this.metadataSubType = null;
        this.metadataValue = null;
    }
}


export class MetadataTemplate {
   id: string;
   metadataClassification: string;
   metadataType: string;
   metadataSubType: string;
   metadataClassificationAlias: string;
   metadataTypeAlias: string;
   metadataSubTypeAlias: string;
   editableByUser: boolean;

   constructor(){
       this.id = null;
       this.metadataClassification = null;
       this.metadataType = null;
       this.metadataSubType = null;
       this.metadataClassificationAlias = null;
       this.metadataTypeAlias = null;
       this.metadataSubTypeAlias = null;
   }
}
