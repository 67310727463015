<h5 mat-dialog-title>Add Edit Links</h5>
<hr>
<form #linkForm="ngForm" novalidate>
    <mat-dialog-content class="mat-typography">
        <div *ngIf="linksArray"> 
        
            <ng-container *ngFor="let linkItem of linksArray; index as linkIndex">
                <div class="row" *ngIf="!linkItem.deleteFlag">

                    <div class="col-2">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Description</label>
                        <input name="description_{{linkIndex}}" class="form-control form-control-sm"
                            style="font-size: 11px;" #descVar="ngModel" [(ngModel)]="linkItem.description"
                            [ngClass]="{'is-invalid':descVar.touched && descVar.errors}" [disabled]="!editable" required>
                        <div *ngIf="descVar?.invalid && (descVar.dirty || descVar.touched)" class="invalid-feedback">
                            <div *ngIf="descVar.errors.required">
                                Description is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-8">
                        <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Url</label>
                        <input name="url_{{linkIndex}}" class="form-control form-control-sm"
                            style="font-size: 11px;" #urlVar="ngModel" [(ngModel)]="linkItem.url" oninput="this.value = this.value.trim()"
                            [ngClass]="{'is-invalid':urlVar.touched && urlVar.errors}" [disabled]="!editable">
                        <div *ngIf="urlVar?.invalid && (urlVar.dirty || urlVar.touched)" class="invalid-feedback">
                            <div *ngIf="urlVar.errors.required">
                                Url is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-1 d-flex align-items-end">
                        <span (click)="linkItem.deleteFlag=true;" *ngIf="editable"><i class="fas fa-trash text-danger shadow"></i></span>
                    </div>

                </div>
            </ng-container>
            <button type="button" class="btn btn-info btn-sm f-10 mt-3" (click)="addLinkItem()">+ Add</button>
        
        </div>
    </mat-dialog-content>
    <hr>
    <mat-dialog-actions align="end">
        <!--<button mat-button mat-dialog-close>Cancel</button>-->
        <button mat-button [mat-dialog-close]="true" class="btn btn-sm btn-warning" [disabled]="!linkForm.valid" cdkFocusInitial>Save</button>
      </mat-dialog-actions>
    </form>