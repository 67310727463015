<div class="row mt-2 d-flex justify-content-center">
   <h4 class="d-inline">Royalty Overview</h4>
</div>
<hr>

<form #royaltyForm="ngForm" autocomplete="off" novalidate>
   <div class="row mt-2 mb-2">
   <!-- Moved the Add New button to float buttons   
      <div class="col-sm-1" *ngIf="userAccess?.canCreateRoyalty">
         <a (click)="addNewRoyalty()" class="btn btn-info btn-sm text-white"><i class="fas fa-plus fa-1x"></i>Add</a>
      </div>
   -->
      <div class="col-sm-6">
         <label class="col-sm-2 text-right">Sort By:</label>
         <select class="form-control form-control-sm custom-select col-sm-3 mr-1" id="sortBy" name="sortBy" [(ngModel)]="selectedSortByName" (change)="onSortColumnChange($event.target.value)">
            <option *ngFor="let sortColumn of sortColumns" [ngValue]="sortColumn.sortByName">
               {{sortColumn.columnName}}</option>
         </select>
         <span (click)="sortAsc()" *ngIf="selectedSortByName && sortMode==='ASC'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1" style="color:black; vertical-align: middle;"></i></span>
         <span (click)="sortDesc()" *ngIf="selectedSortByName && sortMode==='ASC'"><i class="fas fa-sort-amount-down fa-lg mr-1" style="color:grey; vertical-align: middle;"></i></span>
         <span (click)="sortAsc()" *ngIf="selectedSortByName && sortMode==='DESC'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1" style="color:grey; vertical-align: middle;"></i></span>
         <span (click)="sortDesc()" *ngIf="selectedSortByName && sortMode==='DESC'"><i class="fas fa-sort-amount-down fa-lg" style="color:black; vertical-align: middle;"></i></span>

      </div>
      <div class="col-sm-5">
         <div class="row">
            <span class="col-sm-2 text-right align-middle" style="align-self: center;">Filter:</span>
            <input type="search" class="form-control col-sm-8" #search placeholder="Filter by Licensor Name, Brand Name etc .." (keyup)="onKeyupFilter($event,search.value)">
         </div>
      </div>
   </div>

   <table class="table table-sm table-striped table-bordered table-condensed">
      <thead class="text-white bg-secondary">
         <tr>
            <th  class="align-middle text-center text-nowrap" style="width:1%" *ngIf="userAccess?.canEditRoyalty"></th>
            <th  class="align-middle text-center text-nowrap" style="width:15%">Licensor Name</th>
            <th  class="align-middle text-center text-nowrap" style="width:15%">Brand Name</th>
            <th  class="align-middle text-center text-nowrap" style="width:15%">Product Category</th>
            <th  class="align-middle text-center text-nowrap" style="width:15%">Tier Details (%)</th>
            <th  class="align-middle text-center text-nowrap" style="width:15%">Fee Details (%)</th>
            <th  class="align-middle text-center text-nowrap" style="width:20%">Notes</th>
         </tr>
      </thead>

      <tbody>
         <ng-container *ngFor="let royalty of filteredRoyaltyList; index as royaltyIndex">
            <tr [ngModelGroup]="royaltyIndex" #royaltyRowGroup="ngModelGroup" [style]="royalty.hasError?'background-color: rgb(253 180 180);':''">
               <td style="white-space: nowrap;width: 1%;" *ngIf="userAccess?.canEditRoyalty" >
                  <div class="d-flex flex-column">
                     <button *ngIf="!royalty.editable && userAccess?.canEditRoyalty" type="button" id="save_{{royaltyIndex}}" (click)="editRoyalty(royalty,royaltyIndex)"
                        class="mb-1 btn btn-sm btn-warning mr-1 ml-1"><i class="fas fa-pencil-alt"></i></button>
                     <button *ngIf="royalty.editable" [disabled]="!royaltyRowGroup.valid|| royalty.productCategoryList?.length === 0" type="button" (click)="saveRoyalty(royalty,royaltyIndex)"
                        class="mb-1 btn btn-sm btn-info mr-1 ml-1"> <i class="fas fa-save" style="color:white"></i></button>
                     <button *ngIf="royalty.editable && royalty.id && userAccess?.canDeleteRoyalty" type="button" (click)="deleteRoyalty(royalty,royaltyIndex)" 
                        class="mb-1 btn btn-sm btn-danger mr-1 ml-1"><i class="fas fa-trash"></i></button>
                     <button *ngIf="royalty.editable" type="button" (click)="cancelRoyalty(royalty,royaltyIndex)" 
                        class="mb-1 btn btn-dark btn-sm mr-1 ml-1"><i class="fas fa-times"></i></button>
                     <button *ngIf="!royalty.editable" type="button" (click)="cloneRoyalty(royalty,royaltyIndex)" 
                        class="mb-1 btn btn-dark btn-sm mr-1 ml-1"><i class="fas fa-copy"></i></button>
                  </div>
               </td>

               <td *ngIf="!royalty.editable">
                  {{royalty.licensorName}}
               </td>
               <td *ngIf="royalty.editable">
                  <select class="form-control form-control-sm custom-select" [(ngModel)]="royalty.licensorId" id="licensor_{{royaltyIndex}}" name="licensor_{{royaltyIndex}}" #licensorVar="ngModel"
                     (change)="onLicensorChange($event,royalty, royaltyIndex)" required [ngClass]="{'is-invalid':licensorVar.touched && licensorVar.errors}">
                     <option *ngFor="let licensor of licensorList" [ngValue]="licensor.id">
                        {{licensor.licensorName}}</option>
                  </select>
                  <div *ngIf="licensorVar?.invalid && (licensorVar.dirty || licensorVar.touched)" class="invalid-feedback">
                     <div *ngIf="licensorVar.errors.required">
                        Licensor is required.
                     </div>
                  </div>
               </td>

               <td *ngIf="!royalty.editable">
                  {{royalty.brandName}}
               </td>
               <td *ngIf="royalty.editable">
                  <select class="form-control form-control-sm custom-select" [(ngModel)]="royalty.brandId" id="brand_{{royaltyIndex}}" name="brand_{{royaltyIndex}}" [disabled]="!royalty.licensorId"
                     (change)="onBrandChange($event)" #brandVar="ngModel" required [ngClass]="{'is-invalid':brandVar.touched && brandVar.errors}">
                     <option *ngFor="let brand of royalty.brandList" [ngValue]="brand.id">
                        {{brand.brandName}}</option>
                  </select>
                  <div *ngIf="brandVar?.invalid && (brandVar.dirty || brandVar.touched)" class="invalid-feedback">
                     <div *ngIf="brandVar.errors.required">
                        Brand is required.
                     </div>
                  </div>
               </td>

               <td *ngIf="!royalty.editable">
               <!--
                  <ng-container *ngFor="let productCategory of royalty.productCategoryList; index as productCategoryIndex">
                     &#8226;{{productCategory}}
                  </ng-container>
               -->
                  <div *ngFor="let productCategory of royalty.productCategoryList; index as prodCatIndex">
                     <div class="d-inline custom-control custom-checkbox mr-sm-2 col-2">
                        <input type="checkbox" class="custom-control-input " name="productCategory_{{royaltyIndex}}_{{prodCatIndex}}" id="productCategory_{{royaltyIndex}}_{{prodCatIndex}}" 
                        [checked]="true" [disabled]="true">
                        <label class="custom-control-label text-break pt-1" style="color:#212529" for="productCategory_{{royaltyIndex}}_{{prodCatIndex}}">{{productCategory}}</label>
                     </div>
                  </div>
               </td>

               <td *ngIf="royalty.editable">
                  <button *ngIf="royalty.editable" type="button" class="mt-1 btn btn-sm color-3 btn-block" style="line-height:0.25rem" (click)="onProductCategoryAddOrEdit(royalty)">
                     <i class="fas fa-plus fa-xs"></i>
                  </button>
                  <div *ngFor="let productCategory of royalty.productCategoryList; index as productCategoryIndex">
                     <div class="d-inline custom-control custom-checkbox mr-sm-2">
                        <input type="checkbox" class="custom-control-input " name="productCategory_{{royaltyIndex}}_{{productCategoryIndex}}" id="productCategory_{{royaltyIndex}}_{{productCategoryIndex}}" [checked]="true"
                           (click)="onProductCategorySelection($event,productCategory,royalty)" [disabled]="!royalty.editable">
                        <label class="custom-control-label text-break" for="productCategory_{{royaltyIndex}}_{{productCategoryIndex}}">{{productCategory}}</label>
                     </div>
                  </div>
                  <div *ngIf="royalty.productCategoryList?.length === 0" style="color:#DC3545; font-size: 80%;">
                     <div>Select Product Categories.</div>
                  </div>
               </td>

               <td *ngIf="!royalty.editable">
                  <ng-container *ngFor="let royaltyTierDetail of royalty.royaltyTierDetails; index as royaltyTierDetailIndex">
                     <!--<div class="d-flex d-inline mb-1" style="border-bottom: 1px solid blueviolet; border-width:0.25px">-->
                     <div class="d-flex d-inline mb-1">
                        <div class="col-sm-6">{{royaltyTierDetail.tierType}}:</div>
                        <div class="col-sm-6">{{royaltyTierDetail.tierValue}}%</div>
                     </div>
                  </ng-container>
               </td>
               <td *ngIf="royalty.editable">
                  <ng-container *ngFor="let royaltyTierDetail of royalty.allRoyaltyTierDetails; index as royaltyTierDetailIndex">
                     <span class="form-group row d-flex d-inline mb-1">
                        <div class="col-sm-6">{{royaltyTierDetail.tierType}}:</div>
                        <div class="col-sm-6">
                           <input name="royaltyTierDetail.tierValue{{royaltyTierDetailIndex}}" [(ngModel)]="royaltyTierDetail.tierValue" min="0" value="0" step=".01" required
                              class="form-control form-control-sm" type="number">
                        </div>
                     </span>
                  </ng-container>
               </td>

               <td *ngIf="!royalty.editable">
                  <ng-container *ngFor="let royaltyFeeDetail of royalty.royaltyFeeDetails; index as royaltyFeeDetailIndex">
                     <span class="d-flex d-inline mb-1">
                        <div class="col-sm-6">{{royaltyFeeDetail.feeType}}:</div>
                        <div class="col-sm-6">{{royaltyFeeDetail.feeValue}}%</div>
                     </span>
                  </ng-container>
               </td>
               <td *ngIf="royalty.editable" class="">
                  <ng-container *ngFor="let royaltyFeeDetail of royalty.allRoyaltyFeeDetails; index as royaltyFeeDetailIndex">
                     <div class="form-group row d-flex d-inline mb-1">
                        <div class="col-sm-6">{{royaltyFeeDetail.feeType}}:</div>
                        <div class="col-sm-6">
                           <input name="royaltyFeeDetail.feeValue{{royaltyFeeDetailIndex}}" [(ngModel)]="royaltyFeeDetail.feeValue" min="0" value="0" step=".01" required
                              class="form-control form-control-sm" type="number">
                        </div>
                     </div>
                  </ng-container>
               </td>

               <td *ngIf="!royalty.editable" style="white-space: pre-wrap;">
                  {{royalty.notes}}
               </td>
               <td *ngIf="royalty.editable">
                  <textarea name="notes_{{royaltyIndex}}" id="notes_{{royaltyIndex}}" rows="{{(royalty?.notes?.length / 25)>2?(royalty?.notes?.length / 25):2}}" [(ngModel)]="royalty.notes"
                     class="form-control form-control-sm" #notesVar="ngModel" [disabled]="!royalty.editable" [ngClass]="{'is-invalid':notesVar.touched && notesVar.errors}" maxlength="250"></textarea>
                  <div *ngIf="notesVar?.invalid && (notesVar.dirty || notesVar.touched)" class="invalid-feedback">
                     <div *ngIf="notesVar.errors.required">
                        Notes is required.
                     </div>
                  </div>
               </td>

            </tr>
         </ng-container>
      </tbody>
   </table>



</form>

<!-- Product Category Selection Modal -->
<div class="modal fade" id="ProductCategorySelectionModal" tabindex="-1" role="dialog" aria-labelledby="ProductCategorySelectionModalTitle" aria-hidden="true">
   <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="ProductCategorySelectionLongTitle">Product Category</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <div class="row mt-2 mb-2 border-bottom">
               <div class="mr-4 col-sm-3">
                  <input type="radio" id="selectAllProductCategory" [(ngModel)]="selectUnSelectAllRadio" style="transform: scale(1.75);"
                     class="mr-2" name="productCategoryRadio" value="true"
                     (click)="onSelectOrUnselectAll('true','ProductCategory')">
                  <label for="selectAllCategory"><span></span>&nbsp;Select All</label>
               </div>
               <div>
                  <input type="radio" id="unselectAllProductCategory" [(ngModel)]="selectUnSelectAllRadio" style="transform: scale(1.75);"
                     class="mr-2" name="productCategoryRadio" value="false"
                     (click)="onSelectOrUnselectAll('false','ProductCategory')">
                  <label for="unselectAllCategory"><span></span>&nbsp;UnSelect All</label>
               </div>
            </div>
            <div class="ml-2" *ngFor="let item of productCategoryList">
               <div class="row">
                  <label class="cb-container">
                     <h6 class="ml-4 mt-1">{{item}}</h6>
                     <input type="checkbox" [checked]="isItemSelected(item,'ProductCategory')" (change)="onProductCategorySelection($event,item, null)">
                     <span class="checkmark"></span>
                  </label>
               </div>
            </div>
         </div>
         <div class="modal-footer">
            <div class="m-1 d-flex">
               <button class="btn bg-dark text-white btn-block" data-dismiss="modal">Ok</button>
            </div>
         </div>
      </div>
   </div>
</div>

<button id="productCategoryModalToggler" type="button" style="display: none;" data-toggle="modal" data-target="#ProductCategorySelectionModal"></button>


<div class="floatBtn d-flex flex-column">
   <!--
   <button type="button" class=" btn btn-circle bg-info mb-2" [disabled]="!royaltyForm.valid" *ngIf="editMode" (click)="saveRoyaltyList()" matTooltip="Save Changes" matTooltipPosition="left"
      matTooltipClass="bg-dark">
      <i class="fas fa-save fa-2x" style="color:white"></i>
   </button>
   <button type="button" class=" btn btn-circle bg-dark mb-2" *ngIf="editMode" matTooltip="Cancel Changes" matTooltipPosition="left" matTooltipClass="bg-dark" (click)="cancelEditMode()"><i
         class="fas fa-times fa-2x" style="color:white"></i>
   </button>
   -->
   <a (click)="addNewRoyalty()" class="btn btn-circle text-white mb-2" *ngIf="userAccess?.canCreateRoyalty" style="background-color: #17a2b8;" matTooltip="Create New Royalty" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-plus fa-2x text-light"></i></a>
   <button type="button" class="btn btn-circle bg-secondary mb-2" matTooltip="Scroll To Top" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'});"  matTooltipPosition="left" matTooltipClass="bg-dark">
      <i class="fas fa-arrow-up fa-2x" style="color:white"></i>
   </button>
   <a (click)="goBack()" class=" btn btn-circle" style="background-color: tomato;" matTooltip="Exit" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-arrow-left  fa-2x"></i></a>
</div>