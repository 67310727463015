<div *ngIf="loaderService.showLoader" class="loader" [@fadeInOut]></div>

<!--<div *ngIf="loaderService.filesUploading.length>0 && loaderService.showProgressArea"  class="floatProgress d-flex flex-column shadow-lg" style="width: 20%;">
  <button type="button" (click)="loaderService.toggleProgressBar()" class="btn btn-sm btn-dark round">Hide - {{showProgressArea}}</button>
    <div *ngFor="let file of loaderService.filesUploading" class="m-2">
      <div class="progress form-group mb-1">
              <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" [style.width.%]="file.progress">({{file.progress}})%</div>
      </div>
      <div style="opacity: 50%;"> 
              <p class="text-muted text-wrap f-10 pb-0 mb-0" *ngIf="file.progress!==100">Uploading Stage 1 - {{file.fileName}}</p>
              <p class="text-muted text-wrap f-10 pb-0 mb-0" *ngIf="file.progress===100">Uploading Stage 2 - {{file.fileName}}</p>
      </div>
      <hr class="hr-min">
    </div>

</div>-->

<div *ngIf="loaderService.filesUploading.length>0" [style]="loaderService.showProgressArea?'opacity:100%;':'opacity:40%;'" class="floatProgress d-flex flex-column shadow-lg" style="width: auto;">
<button type="button"  (click)="loaderService.toggleProgressBar()" matTooltip="Click to Minimize / Maximize" matTooltipPosition="above" matTooltipClass="bg-dark" class="btn btn-sm btn-dark">Uploading {{loaderService.filesUploading.length}} file(s) <span *ngIf="loaderService.filesUploading.length>0" class="spinner-grow text-warning" style="vertical-align: middle;" (click)="loaderService.toggleProgressBar()"></span> </button>
<ng-container *ngFor="let file of loaderService.filesUploading" class="m-2">
  <div *ngIf="loaderService.showProgressArea" class="m-2">
    <div class="progress form-group mb-1">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" *ngIf="file.progress!==100" [style.width.%]="file.progress" >Stage 1 - Uploading ({{file.progress}})%</div>
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" *ngIf="file.progress===100" [style.width.%]="file.progress">Stage 2 - Processing...</div>
    </div>
    <div> 
      <p class="text-muted text-wrap f-10 pb-0 mb-0 mx-2" ><b>{{file.parentId}} : </b>{{file.fileName}}</p>
    </div>
    <hr class="hr-min">
  </div>
</ng-container>
</div>


<nav class="navbar navbar-expand-lg navbar-dark sticky-top bg-dark nav-border" [style]="width>600?'max-height:60px !important;':''">
  <img class="logo-auto-size-70" src="{{'brandIcon'|env}}" alt="Icon" >
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto ml-3" *ngIf="(isUserLoggedIn)">
    <!--  
      <li class="nav-item" [routerLinkActive]="['active']" >
        <a class="nav-link" [routerLink]="['/home']"><h6>Home</h6></a> 
      </li>
    -->
      <!--<li class="nav-item" *ngIf='userAccess?.canViewProductsSection' [class.active]="isProductSectionActive">
        <a class="nav-link" [routerLink]="['/product-search']"><h5>Products</h5></a>
      </li>    
      
      <li class="nav-item" *ngIf='userAccess?.canCreateCatalog' [class.active]="isCatalogSectionActive" >
        <a class="nav-link " [routerLink]="['/product-catalog']"><h5>Catalog</h5></a>         
      </li>-->

      <li class="nav-item dropdown" [class.active]="isDashboardSectionActive" >
         <a class="nav-link d-flex justify-content-end align-items-center" data-toggle="dropdown" [routerLink]="['/dashboard']" role="button">
            <h5><i class="fa fa-home" aria-hidden="true"></i></h5>
         </a>
      </li>  

      <li class="nav-item dropdown" [class.active]="isTaskSectionActive" *ngIf="userAccess?.canViewTaskManagement">
         <a class="nav-link dropdown-toggle d-flex justify-content-end align-items-center" data-toggle="dropdown" href="#" role="button">
            <h5>Task</h5>
         </a>
         <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/task/list']" *ngIf='userAccess?.canViewTaskManagement' [class.active]="isTaskListSectionActive">Tasks</a>
         </div>
      </li>  


      <li class="nav-item dropdown" [class.active]="isArtSectionActive" *ngIf="userAccess?.canViewProductsSection || userAccess?.canCreateCatalog">
        <a class="nav-link dropdown-toggle d-flex justify-content-end align-items-center" data-toggle="dropdown" href="#" role="button" >
           <h5>Art</h5>
        </a>
        <div class="dropdown-menu">
           <a class="dropdown-item" [routerLink]="['/product-search']" *ngIf='userAccess?.canViewProductsSection' [class.active]="isProductSectionActive">Products</a>
           <div class="dropdown-divider" *ngIf='userAccess?.canViewProductsSection'></div>
           <a class="dropdown-item" [routerLink]="['/product-catalog']" *ngIf='userAccess?.canCreateCatalog' [class.active]="isCatalogSectionActive">Catalog</a>
           <div class="dropdown-divider" *ngIf='userAccess?.canViewArtTracker'></div>
           <a class="dropdown-item" [routerLink]="['/art-tracker-list']" *ngIf='userAccess?.canViewArtTracker' [class.active]="isArtTrackerSectionActive">Art Tracker</a>
        </div>
     </li>
   
      <!-- START: LICENSING MENU -->     
      <li class="nav-item dropdown" [class.active]="isLicensingSectionActive || isRoyaltySectionActive" *ngIf="userAccess?.canViewLicensorsSection||userAccess?.canViewBrandsSection||userAccess?.canViewSkuTracker||userAccess?.canViewSkuDashbaord||userAccess?.canViewBrandRights || userAccess?.canViewRoyalty">
        <a class="nav-link dropdown-toggle d-flex justify-content-end align-items-center" data-toggle="dropdown" href="#" role="button" >
          <h5>Licensing</h5>
        </a>
        <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/licensor-list']" *ngIf='userAccess?.canViewLicensorsSection' [class.active]="isLicensorSectionActive">Licensors / Brands</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewLicensorsSection'></div>
            <!--<a class="dropdown-item" [routerLink]="['/brand-list']" *ngIf='userAccess?.canViewBrandsSection' [class.active]="isBrandSectionActive">Brands</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewBrandsSection'></div>-->
            <a class="dropdown-item" [routerLink]="['/sku-tracker-list']" *ngIf='userAccess?.canViewSkuTracker' [class.active]="isSkuTrackerSectionActive">SKU Tracker</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewPreProTracker'></div>
            <a class="dropdown-item" [routerLink]="['/prepro-tracker-list']" *ngIf='userAccess?.canViewPreProTracker' [class.active]="isPreProTrackerSectionActive">PrePro Tracker</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewSkuTracker'></div>
            <a class="dropdown-item" [routerLink]="['/dashboard/sku-tracker']" *ngIf='userAccess?.canViewSkuDashbaord' [class.active]="isSkuTrackerDashboardSectionActive">Dashboard</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewSkuDashbaord'></div>
            <a class="dropdown-item" [routerLink]="['/report/overview/rights-overview']" *ngIf='userAccess?.canViewBrandRights' [class.active]="isRightsOverviewActive">Brand Rights Overview</a>
           <div class="dropdown-divider" *ngIf='userAccess?.canViewBrandRights'></div>
           <a class="dropdown-item" [routerLink]="['/report/overview/royalty-overview']" *ngIf='userAccess?.canViewRoyalty' [class.active]="isRoyaltyOverviewActive">Royalty Overview</a>
        </div>
      </li>
      <!-- END: LICENSING MENU -->      

      <!-- START: REPORTS MENU -->    
      <li class="nav-item dropdown" [class.active]="isReportSectionActive" *ngIf="userAccess?.canViewUpcReport || userAccess?.canViewLicensorSampleReport">
         <a class="nav-link dropdown-toggle d-flex justify-content-end align-items-center" data-toggle="dropdown" href="#" role="button" >
            <h5>Reports</h5>
         </a>
         <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/redirect/report~UPC_OVERVIEW']" *ngIf='userAccess?.canViewUpcReport' [class.active]="isUpcOverviewSectionActive"><i class="fa fa-file mr-2"></i>UPC Overview</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewUpcReport'></div>
            <a class="dropdown-item" [routerLink]="['/redirect/report~LICENSOR_SAMPLE_OVERVIEW']" *ngIf='userAccess?.canViewLicensorSampleReport' [class.active]="isLicensorSampleOverviewSectionActive"><i class="fa fa-file mr-2"></i>Licensor Samples Overview</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewLicensorSampleReport'></div>
            <a class="dropdown-item" [routerLink]="['/redirect/report~UNIVERSAL MUSIC RETAILER REPORT']" *ngIf='userAccess?.canViewSkuTracker' ><i class="fa fa-file mr-2"></i>Universal Music Retailer Report</a>
            
         </div>
      </li>
      <!-- START: REPORTS MENU -->      

      <!-- START: SALES MENU -->      
      <li class="nav-item dropdown" [class.active]="isSalesSectionActive|| isSalesDashboardSectionActive"
         *ngIf="userAccess?.canViewSalesOrder || userAccess?.canViewSalesDashbaord">
         <a class="nav-link dropdown-toggle d-flex justify-content-end align-items-center" data-toggle="dropdown" href="#" role="button">
            <h5>Sales</h5>
         </a>
         <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/salesOrder/list']" *ngIf='userAccess?.canViewSalesOrder' [class.active]="isSalesSectionActive">Sales Orders</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewSalesOrder'></div>
            <a class="dropdown-item" [routerLink]="['/dashboard/sales-dashboard']" *ngIf='userAccess?.canViewSalesDashbaord' [class.active]="isSalesDashboardSectionActive">Dashboard</a>
         </div>
      </li>  
      <!-- END: SALES MENU -->      

      <!-- START: COSTING MENU -->      
      <li class="nav-item dropdown" [class.active]="isCostingSectionActive" *ngIf="userAccess?.canViewSalesOrder">
         <a class="nav-link dropdown-toggle d-flex justify-content-end align-items-center" data-toggle="dropdown" href="#" role="button">
            <h5>Costing</h5>
         </a>
         <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/costing/list/pending']" *ngIf='userAccess?.canViewSalesOrder' >Costing Pending</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewSalesOrder'></div>
            <a class="dropdown-item" [routerLink]="['/costing/list/pendingApproval']" *ngIf='userAccess?.canViewSalesOrder' >Costing Approval Pending</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewPrinterCapacity'></div>
            <a class="dropdown-item" [routerLink]="['/printercapacity/list']" *ngIf='userAccess?.canViewPrinterCapacity' [class.active]="isPrinterCapacitySectionActive">Printer Capacity</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewRoyaltyMismatchList'></div>
            <a class="dropdown-item" [routerLink]="['/royalty/mismatch/list']" *ngIf='userAccess?.canViewRoyaltyMismatchList' [class.active]="isRoyaltyMismatchSectionActive">Royalty Mismatch</a>
         </div>
      </li>
      <!-- END: COSTING MENU -->      

      <!-- START: ESTIMATION MENU -->
      <li class="nav-item dropdown" [class.active]="isEstimationSectionActive" *ngIf="userAccess?.canViewEstimationSection">
         <a class="nav-link dropdown-toggle d-flex justify-content-end align-items-center" data-toggle="dropdown" href="#" role="button">
            <h5>Estimation</h5>
         </a>
         <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/estimation/list']" *ngIf='userAccess?.canViewEstimationSection'  [class.active]="isEstimationListSectionActive">Estimations</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewEstimationSection'></div>
            <a class="dropdown-item" [routerLink]="['/estimation/pending/list']" *ngIf='userAccess?.canViewEstimationSection'  [class.active]="isEstimationPendingListSectionActive">Estimations Pending</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewEstimationSection'></div>
            <a class="dropdown-item" [routerLink]="['/estimation/approval/pending/list']" *ngIf='userAccess?.canViewEstimationSection'  [class.active]="isEstimationApprovalPendingListSectionActive">Estimations Complete</a>
         </div>
      </li>
      <!-- END: ESTIMATION MENU -->      

      <!-- START: PRODUCTION MENU -->
      <li class="nav-item dropdown" *ngIf="userAccess?.canViewProductionTrackerList || userAccess?.canViewFileClosureSummary" [class.active]="isProductionSectionActive">
         <a class="nav-link dropdown-toggle d-flex justify-content-end align-items-center" data-toggle="dropdown" href="#" role="button">
            <h5>Production</h5>
         </a>
         <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/production-tracker/list']" *ngIf='userAccess?.canViewProductionTrackerList' [class.active]="isProductionTrackerSectionActive">Production Tracker</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewFileClosureSummary'></div>
            <a class="dropdown-item" [routerLink]="['/fileClosureSummary/list']" *ngIf='userAccess?.canViewFileClosureSummary' [class.active]="isFileClosureSummarySectionActive">File Closure Summary</a>
         </div>
      </li>    
      <!-- END: PRODUCTION MENU -->      
      
      <!-- START: PRINTER PORTAL MENU -->      
      <li class="nav-item dropdown" *ngIf="userAccess?.canViewPrinterPortalList" [class.active]="isPrinterPortalSectionActive"> <!--*ngIf="userAccess?.canViewPrinterPortalList" -->
         <a class="nav-link dropdown-toggle d-flex justify-content-end align-items-center" data-toggle="dropdown" href="#" role="button">
            <h5>Printer</h5>
         </a>
         <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/printer-portal/list']" *ngIf="userAccess?.canViewPrinterPortalList" >Print Assigments</a><!--*ngIf='userAccess?.canViewPrinterPortalList'-->
         </div>
      </li>    
      <!-- END: PRINTER PORTAL MENU -->            

      <!--<li class="nav-item dropdown" [class.active]="isRoyaltySectionActive" *ngIf="userAccess?.canViewBrandRights || userAccess?.canViewRoyalty">
        <a class="nav-link dropdown-toggle d-flex justify-content-end align-items-center" data-toggle="dropdown" href="#" role="button" >
           <h5>Royalty</h5>
        </a>
        <div class="dropdown-menu">
           <a class="dropdown-item" [routerLink]="['/report/overview/rights-overview']" *ngIf='userAccess?.canViewBrandRights' [class.active]="isRightsOverviewActive"><i class="fa fa-file mr-2"></i>Brand Rights Overview</a>
           <div class="dropdown-divider" *ngIf='userAccess?.canViewBrandRights'></div>
           <a class="dropdown-item" [routerLink]="['/report/overview/royalty-overview']" *ngIf='userAccess?.canViewRoyalty' [class.active]="isRoyaltyOverviewActive"><i class="fa fa-file mr-2"></i>Royalty Overview</a>
        </div>
     </li>-->
      <li class="nav-item dropdown" [class.active]="isAccountsSectionActive" *ngIf='userAccess?.canViewChargebackSummary'>
         <a class="nav-link dropdown-toggle d-flex justify-content-end align-items-center" data-toggle="dropdown" href="#" role="button">
            <h5>Accounts</h5>
         </a>
         <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/chargeback/list']" [class.active]="isChargeBackSectionActive" *ngIf='userAccess?.canViewChargebackSummary'>
               <i class="fa fa-cog mr-2"></i>Charge Back</a>
         </div>
      </li>

      <li class="nav-item dropdown" [class.active]="isAdminSectionActive" *ngIf='userAccess?.canViewMetadata || userAccess?.canViewProductMetadata || userAccess?.canViewUsersSection || userAccess?.canViewCustomers || userAccess?.isSupportAdmin || userAccess?.canViewPdfForms'>
         <a class="nav-link dropdown-toggle d-flex justify-content-end align-items-center" data-toggle="dropdown" href="#" role="button" >
            <h5>Admin</h5>
         </a>
         <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/metadata/list']" [class.active]="isMetadataSectionActive" *ngIf='userAccess?.canViewMetadata'>
               <i class="fa fa-cog mr-2"></i>Metadata</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewMetadata'></div>

            <a class="dropdown-item" [routerLink]="['/metadata/product']" [class.active]="isProductMetadataSectionActive" *ngIf='userAccess?.canViewProductMetadata'>
               <i class="fa fa-cog mr-2"></i>Product Metadata</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewProductMetadata'></div>

            <a class="dropdown-item" [routerLink]="['/user/list']" [class.active]="isUserSectionActive" *ngIf='userAccess?.canViewUsersSection'>
               <i class="fa fa-users mr-2"></i>User Management</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewUsersSection'></div>
            <a class="dropdown-item" [routerLink]="['/customer/list']" [class.active]="isCustomerSectionActive" *ngIf='userAccess?.canViewCustomers'>
               <i class="fa fa-users mr-2"></i>Customer Management</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewVendors'></div>
            <!--<a class="dropdown-item" [routerLink]="['/blanks/list']" [class.active]="isCustomerSectionActive" *ngIf='userAccess?.canViewCustomers'>
               <i class="fa fa-tshirt mr-2"></i>Blanks Management</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewCustomers'></div>-->
            <a class="dropdown-item" [routerLink]="['/printer/list']" [class.active]="isPrinterSectionActive" *ngIf='userAccess?.canViewVendors'>
               <i class="fa fa-tshirt mr-2"></i>Printers</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewVendors'></div> 
            <a class="dropdown-item" [routerLink]="['/blanksSupplier/list']" [class.active]="isBlanksSupplierSectionActive" *ngIf='userAccess?.canViewVendors'>
               <i class="fa fa-tshirt mr-2"></i>Blanks Suppliers</a>
            <div class="dropdown-divider" *ngIf='userAccess?.canViewVendors'></div>                        
            <a class="dropdown-item" [routerLink]="['/role/list']" [class.active]="isRoleSectionActive" *ngIf='userAccess?.isSupportAdmin'>
               <i class="fa fa-scroll mr-2"></i>Roles & Permisssions</a>
            <div class="dropdown-divider" *ngIf='userAccess?.isSupportAdmin'></div>
            <a class="dropdown-item" [routerLink]="['/pdf-form/list']" [class.active]="isPdfFormActive" *ngIf='userAccess?.canViewPdfForms'>
              <i class="fa fa-scroll mr-2"></i>Pdf Forms</a>
         </div>
      </li>
    </ul>

    <ul *ngIf="(isUserLoggedIn)" class="nav navbar-nav ml-auto w-100 justify-content-end">
       <!--<li class="nav-item" *ngIf="usdToCad && userAccess?.isSupportAdmin">
         <a class="nav-link f-10" disabled><h6> 1 USD &#8594; {{usdToCad}} CAD</h6></a> 
       </li>-->
      <li class="nav-item dropdown active">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-user mr-1"></i> {{currentUser.firstName | uppercase }} | {{currentUser.lastName | uppercase }} 
        </a>
        <div class="dropdown-menu dropdown-menu-sm-right dropleft" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" *ngIf="(isUserLoggedIn)" (click)="logout()">
             <span><i class="fas fa-sign-out-alt fa-1x"></i></span>&nbsp;Logout</a>
        </div>
      </li>
    </ul>

  </div>
</nav>