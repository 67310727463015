<form name="chargeBackForm" #chargeBackForm="ngForm" autocomplete="off" novalidate class="mb-3">

   <div class="container p-2">

      <ng-container *ngIf="true">

         <div class="mt-3 col-sm-12 card-header border">
            <h4>Charge Back Details</h4>
            <hr class="mt-1">

            <div class="row ml-10">
               <div class="col-lg-2">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Philcos CB#</label>
                  <!--<span class="form-control form-control-sm" style="background: transparent; font-size: 13px; padding-left: .30rem;border-width: 0px;">{{chargeBack.chargeBackNo}}</span> -->
                  <input type="text" name="chargeBackNo" class="form-control form-control-sm" [(ngModel)]="chargeBack.chargeBackNo" style="font-size: 11px;" [disabled]="true">
               </div>
               <!--
               <div class="col-lg-2">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer PO#</label>
                  <input type="text" name="poNumber" class="form-control form-control-sm" [(ngModel)]="chargeBack.customerPoNumber" style="font-size: 11px;" [disabled]="!editable">
               </div>
               -->
               <div class="col-lg-2">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer PO#</label>
                  <select class="form-control form-control-sm custom-select" [(ngModel)]="chargeBack.customerPoNumber" id="customerPoNumber" name="customerPoNumber" style="font-size: 11px;"
                     #cbCustomerPoNumberVar="ngModel" [ngClass]="{'is-invalid':cbCustomerPoNumberVar.touched && cbCustomerPoNumberVar.errors}" [disabled]="!editable" [required]="true">
                     <option *ngFor="let customerPONumber of customerPONumberList" [ngValue]="customerPONumber">
                        {{customerPONumber}}</option>
                  </select>
                  <div *ngIf="cbCustomerPoNumberVar?.invalid && (cbCustomerPoNumberVar.dirty || cbCustomerPoNumberVar.touched)" class="invalid-feedback">
                     <div *ngIf="cbCustomerPoNumberVar.errors.required">
                        Customer PO# is required.
                     </div>
                  </div>
               </div>


               <div class="col-lg-2">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer #</label>
                  <input type="text" name="customerNo" class="form-control form-control-sm" [(ngModel)]="chargeBack.customerNo" style="font-size: 11px;" [disabled]="!editable"
                     [required]="customerStatusListForRequiredFieldValidation.includes(chargeBack?.customerStatus)">
               </div>

               <div class="col-lg-2">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer Name</label>
                  <input type="text" name="customerName" class="form-control form-control-sm" [(ngModel)]="chargeBack.customerName" style="font-size: 11px;" [disabled]="true">
               </div>

               <div class="col-lg-2">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer CB#</label>
                  <input type="text" name="customerChargeBackNo" class="form-control form-control-sm" [(ngModel)]="chargeBack.customerChargeBackNo" style="font-size: 11px;" [disabled]="!editable"
                     [required]="customerStatusListForRequiredFieldValidation.includes(chargeBack?.customerStatus)">
               </div>

               <div class="col-lg-2">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer CB Amount</label>
                  <input type="number" name="cbAmount" class="form-control form-control-sm" #cbAmountVar [(ngModel)]="chargeBack.customerChargeBackAmount"
                     [required]="customerStatusListForRequiredFieldValidation.includes(chargeBack?.customerStatus)" style="text-align: right; font-size: 11px;" [disabled]="!editable"
                     [ngClass]="{'is-invalid':cbAmountVar.touched && cbAmountVar.invalid}">
               </div>
            </div>

            <div class="row ml-10 mt-2">
               <div class="col-lg-2">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Currency</label>
                  <input type="text" name="currency" class="form-control form-control-sm" [(ngModel)]="chargeBack.currency" style="font-size: 11px;" [disabled]="true">
               </div>

               <div class="col-lg-2">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Document Date</label>
                  <div class="d-flex d-inline">
                     <input matInput [matDatepicker]="cbDocumentDatePicker" id="cbDocumentDate" name="cbDocumentDate" [(ngModel)]="chargeBack.customerDocumentDate"
                        style="cursor: pointer;font-size: 11px;" class="form-control  form-control-sm" [required]="customerStatusListForRequiredFieldValidation.includes(chargeBack?.customerStatus)"
                        #cbDocDateVar="ngModel" [ngClass]="{'is-invalid':cbDocDateVar.touched && cbDocDateVar.errors}" [disabled]="!editable">
                     <mat-datepicker #cbDocumentDatePicker></mat-datepicker>
                     <button class="btn btn-sm" type="button" (click)="cbDocumentDatePicker.open()"><i class="fas fa-calendar-alt"></i></button>
                  </div>
                  <mat-error *ngIf="cbDocDateVar?.errors?.required && (cbDocDateVar.dirty || cbDocDateVar.touched)" style="font-size: 80%;color: #dc3545">
                     Document Date is required.
                  </mat-error>
                  <div *ngIf="cbDocDateVar?.invalid && (cbDocDateVar.dirty || cbDocDateVar.touched)" class="invalid-feedback">
                     <div *ngIf="cbDocDateVar.errors.required">Document Date is required.</div>
                  </div>
               </div>

               <div class="col-lg-2">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Sage Entry Date</label>
                  <div class="d-flex d-inline">
                     <input matInput [matDatepicker]="sageEntryDatePicker" id="sageEntryDate" name="sageEntryDate" [(ngModel)]="chargeBack.sageEntryDate" style="cursor: pointer;font-size: 11px;"
                        class="form-control  form-control-sm" #sageEntryDateVar="ngModel" [ngClass]="{'is-invalid':sageEntryDateVar.touched && sageEntryDateVar.errors}" [disabled]="!editable">
                     <mat-datepicker #sageEntryDatePicker></mat-datepicker>
                     <button class="btn btn-sm" type="button" (click)="sageEntryDatePicker.open()"><i class="fas fa-calendar-alt"></i></button>
                  </div>
                  <mat-error *ngIf="sageEntryDateVar?.errors?.required && (sageEntryDateVar.dirty || sageEntryDateVar.touched)" style="font-size: 80%;color: #dc3545">
                     Sage Entry Date is required.
                  </mat-error>
                  <div *ngIf="sageEntryDateVar?.invalid && (sageEntryDateVar.dirty || sageEntryDateVar.touched)" class="invalid-feedback">
                     <div *ngIf="sageEntryDateVar.errors.required">Sage Entry Date is required.</div>
                  </div>
               </div>

               <div class="col-lg-2">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Category</label>
                  <select class="form-control form-control-sm custom-select" [(ngModel)]="chargeBack.category" id="category" name="category" style="font-size: 11px;" #cbCategoryVar="ngModel"
                     [required]="customerStatusListForRequiredFieldValidation.includes(chargeBack?.customerStatus)" [ngClass]="{'is-invalid':cbCategoryVar.touched && cbCategoryVar.errors}"
                     [disabled]="!editable">
                     <option *ngFor="let category of cbCategoryList" [ngValue]="category">
                        {{category}}</option>
                  </select>
                  <div *ngIf="cbCategoryVar?.invalid && (cbCategoryVar.dirty || cbCategoryVar.touched)" class="invalid-feedback">
                     <div *ngIf="cbCategoryVar.errors.required">
                        Category is required.
                     </div>
                  </div>
               </div>

               <div class="col-lg-2">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Reason</label>
                  <select class="form-control form-control-sm custom-select" [(ngModel)]="chargeBack.reason" id="reason" name="reason" style="font-size: 11px;" #cbReasonVar="ngModel"
                     [required]="customerStatusListForRequiredFieldValidation.includes(chargeBack?.customerStatus)" [ngClass]="{'is-invalid':cbReasonVar.touched && cbReasonVar.errors}"
                     [disabled]="!editable">
                     <option *ngFor="let reason of cbReasonList" [ngValue]="reason">
                        {{reason}}</option>
                  </select>
                  <div *ngIf="cbReasonVar?.invalid && (cbReasonVar.dirty || cbReasonVar.touched)" class="invalid-feedback">
                     <div *ngIf="cbReasonVar.errors.required">
                        Reason is required.
                     </div>
                  </div>
               </div>

               <div class="col-lg-2">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Customer Status</label>
                  <select class="form-control form-control-sm custom-select" [(ngModel)]="chargeBack.customerStatus" id="customerStatus" name="customerStatus" style="font-size: 11px;"
                     #cbCustomerStatusVar="ngModel" required [ngClass]="{'is-invalid':cbCustomerStatusVar.touched && cbCustomerStatusVar.errors}" [disabled]="!editable">
                     <option *ngFor="let customerStatus of cbCustomerStatusList" [ngValue]="customerStatus">
                        {{customerStatus}}</option>
                  </select>
                  <div *ngIf="cbCustomerStatusVar?.invalid && (cbCustomerStatusVar.dirty || cbCustomerStatusVar.touched)" class="invalid-feedback">
                     <div *ngIf="cbCustomerStatusVar.errors.required">
                        Customer Status is required.
                     </div>
                  </div>
               </div>
            </div>

            <div class="row ml-10 mt-2">
               <div class="col-lg-2">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Sales Order #</label>
                  <span class="form-control form-control-sm" style="background: transparent; font-size: 13px; padding-left: .30rem;border-width: 0px;">
                     <ng-container *ngIf="!userAccess?.canViewAllSalesOrder">
                        {{chargeBack.salesOrderNo}}
                     </ng-container>
                     <ng-container *ngIf="userAccess?.canViewAllSalesOrder">
                        <a [routerLink]="['/salesOrder/view/'+chargeBack.salesOrderId]" target="_blank">{{chargeBack.salesOrderNo}}</a>
                     </ng-container>
                  </span>
                  <!-- <input type="text" name="salesOrderNo" class="form-control form-control-sm" [(ngModel)]="chargeBack.salesOrderNo" style="font-size: 11px;" [disabled]="true"> -->
               </div>

               <!--
               <div class="col-lg-2" *ngIf="!editable">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Vendor Name</label>
                  <input type="text" name="vendorName" class="form-control form-control-sm" [(ngModel)]="chargeBack.vendorName" style="font-size: 11px;" [disabled]="true">
               </div>
            -->

               <div class="col-lg-2" *ngIf="true">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Vendor Type</label>
                  <select class="form-control form-control-sm custom-select" [(ngModel)]="chargeBack.vendorType" id="vendorType" name="vendorType" style="font-size: 11px;" #vendorTypeVar="ngModel"
                     [ngClass]="{'is-invalid':vendorTypeVar.touched && vendorTypeVar.errors}" [disabled]="!editable" (change)="handleVendorTypeChange($event)">

                     <option *ngFor="let vendorType of cbVendorTypeList" [ngValue]="vendorType">
                        {{vendorType}}</option>
                  </select>
                  <div *ngIf="vendorTypeVar?.invalid && (vendorTypeVar.dirty || vendorTypeVar.touched)" class="invalid-feedback">
                     <div *ngIf="vendorTypeVar.errors.required">
                        Vendor Type is required.
                     </div>
                  </div>
               </div>

               <div class="col-lg-2" *ngIf="true">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Vendor Name</label>
                  <ng-container *ngIf="chargeBackVendorList?.length > 0">
                     <select class="form-control form-control-sm custom-select" [(ngModel)]="chargeBack.vendorPtInfoId" id="vendorName" name="vendorName" style="font-size: 11px;" #vendorNameVar="ngModel"
                        [ngClass]="{'is-invalid':vendorNameVar.touched && vendorNameVar.errors}"
                        [disabled]="!editable" (change)="handleVendorChange($event)">
                        <option *ngFor="let chargeBackVendor of chargeBackVendorList" [ngValue]="chargeBackVendor.parentId">
                           {{chargeBackVendor.displayName}}</option>
                     </select>
                     <div *ngIf="vendorNameVar?.invalid && (vendorNameVar.dirty || vendorNameVar.touched)" class="invalid-feedback">
                        <div *ngIf="vendorNameVar.errors.required">
                           Vendor Name is required.
                        </div>
                     </div>
                  </ng-container>
                  <ng-container *ngIf="chargeBackVendorList?.length === 0">
                     <input type="text" name="vendorName" class="form-control form-control-sm" #vendorNameTextVar="ngModel" [(ngModel)]="chargeBack.vendorName" style="font-size: 11px;" [disabled]="!editable"
                     (blur)="handleVendorNameChange()">
                  </ng-container>
               </div>

               <div class="col-lg-2">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Vendor PO#</label>
                  <input type="text" name="vendorPoNo" class="form-control form-control-sm" [(ngModel)]="chargeBack.vendorPoNo" style="font-size: 11px;" [disabled]="!editable">
               </div>

               <div class="col-lg-2">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Amount Recovered</label>
                  <input type="number" name="cbAmountRecovered" class="form-control form-control-sm" #cbAmountRecoveredVar [(ngModel)]="chargeBack.amountRecovered"
                     style="text-align: right; font-size: 11px;" [disabled]="!editable" [ngClass]="{'is-invalid':cbAmountRecoveredVar.touched && cbAmountRecoveredVar.invalid}">
               </div>

               <div class="col-lg-2">
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Vendor Status</label>
                  <select class="form-control form-control-sm custom-select" [(ngModel)]="chargeBack.vendorStatus" id="vendorStatus" name="vendorStatus" style="font-size: 11px;"
                     #cbVendorStatusVar="ngModel" [ngClass]="{'is-invalid':cbVendorStatusVar.touched && cbVendorStatusVar.errors}" [disabled]="!editable">
                     <option *ngFor="let vendorStatus of cbVendorStatusList" [ngValue]="vendorStatus">
                        {{vendorStatus}}</option>
                  </select>
                  <div *ngIf="cbVendorStatusVar?.invalid && (cbVendorStatusVar.dirty || cbVendorStatusVar.touched)" class="invalid-feedback">
                     <div *ngIf="cbVendorStatusVar.errors.required">
                        Vendor Status is required.
                     </div>
                  </div>
               </div>
            </div>

         </div>


         <div class="mt-3 col-sm-12 card-header border">
            <h4>Documents & Notes</h4>
            <hr class="mt-1">

            <div class="row f-10 mt-4">
               <div class="col-6" style="width: calc(70vw);">
                  <app-attachment-operations [header]="'Charge Back Documents'" [editable]="editable" [createAction]="editable" [attachments]="chargeBack.chargeBackAttachments"
                     [canUpload]="userAccess?.canMaintainChargeBackFiles" [canRenameFile]="userAccess?.canMaintainChargeBackFiles" [canDownload]="userAccess?.canDownloadChargeBackFiles"
                     [canDelete]="userAccess?.canMaintainChargeBackFiles" [category]="'SALES_CHARGE_BACK_COMMUNICATION_FILES'" [showHeader]="true" [headerSize]="'6'"
                     [canQuickView]="userAccess?.canViewChargeBackFiles" [isGroupedAttachments]="true" [groupItems]="getGroupItemForChargeBack(chargeBack)" [groupItemDisplayVar]="'chargeBackNo'"
                     [groupItemReferenceVar]="'id'">
                  </app-attachment-operations>
               </div>

               <div class="col-6" style="width: calc(70vw);">
                  <div>
                     <h6>Charge Back Notes</h6>
                     <hr>
                  </div>
                  <ng-container *ngIf="chargeBack.salesOrderId">
                     <app-notes [editable]="editable" [parentId]="chargeBack.id" [type]="'SALES_ORDER'" [subType]="'CHARGE_BACK'" [notesHeaderText]="''"></app-notes>
                  </ng-container>
               </div>
            </div>
         </div>

      </ng-container>

   </div>


   <div class="floatBtn d-flex flex-column">
      <button type="button" (click)="saveChargeBack()" class=" btn btn-circle bg-info mb-2" *ngIf="editable" matTooltip="Save Changes" matTooltipPosition="left" matTooltipClass="bg-dark"
         [disabled]="chargeBackForm.invalid"><i class="fas fa-save fa-2x" style="color:white"></i>
      </button>
      <a rel="noopener" (click)="cancelEditOrCreateChargeBack()" class=" btn btn-circle bg-dark mb-2" *ngIf="editable" matTooltip="Cancel Edit" matTooltipPosition="left" matTooltipClass="bg-dark"><i
            class="fas fa-times fa-2x" style="color:white"></i></a>
      <a class="btn btn-circle bg-dark mb-2" (click)="editChargeBack()" *ngIf="!editable" matTooltip="Edit" matTooltipPosition="left" matTooltipClass="bg-dark"> <i class="fas fa-pencil-alt fa-lg"
            style="color: white;"></i></a>
      <a (click)="goBack()" class="btn btn-circle" style="background-color: tomato;" *ngIf="true" matTooltip="Exit" matTooltipPosition="left" matTooltipClass="bg-dark"><i
            class="fas fa-arrow-left  fa-2x"></i></a>
   </div>

</form>