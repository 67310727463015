import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SearchComponent } from 'src/app/common/search/search.component';
import { SearchDetails } from 'src/app/shared/model/common/search-details.model';
import { FilterConfig } from 'src/app/shared/model/common/search-filter.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { SearchService } from 'src/app/shared/service/search.service';
import { FileClosureSummaryView } from 'src/app/shared/model/sales/file-closure/file-closure-summary-view.model';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared/service/shared.service';
import { SearchCriteriaBuilder } from 'src/app/shared/model/common/search-criteria-builder.model';
import { FileClosureService } from 'src/app/shared/service/sales/file-closure/file-closure.service';

@Component({
   selector: 'app-file-closure-summary-list',
   templateUrl: './file-closure-summary-list.component.html',
   styleUrls: ['./file-closure-summary-list.component.css']
})
export class FileClosureSummaryListComponent implements OnInit {

   userAccessSubscription: Subscription;
   userAccess: UserAccess;

   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;

   fileClosureSummaryViewList: FileClosureSummaryView[] = [];

   searchConfig: SearchDetails;

   sortColumns = [
      { columnName: 'SALES_ORDER_NUMBER', sortByName: 'Sales Order #' },
      { columnName: 'PRODUCTION_STATUS', sortByName: 'Production Status' },
   ];

   @ViewChild('searchComponent') searchComponent: SearchComponent<FileClosureSummaryView>;

   constructor(private authService: AuthService, private searchService: SearchService, private fileClosureService: FileClosureService,
      private sharedService: SharedService, private toastrService: ToastrService) { }

   ngOnInit(): void {
      this.handleSubscriptions();
      if (!this.searchConfig) {
         this.searchConfig = this.searchService.fileClosureSummaryListSearchDetails;
         //this.setFilterConfigs();
      }
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
         this.currentLoggedInUser = currentLoggedInUser;
      });
   }

   setFilterConfigs() {
      if (!this.searchConfig.filterConfigs) {
         this.searchConfig.filterConfigs = [];
         let printerShipDate: FilterConfig = {
            filterType: 'DATE_RANGE',
            label: 'Printer Ship Date',
            currentSelection: undefined,
            fieldName: 'PRINTER_ACTUAL_SHIP_DATE'
         };
         this.searchConfig.filterConfigs.push(printerShipDate);
      }
   }

   createFileClosureSummaryExcel() {
      let criteria: SearchCriteriaBuilder = JSON.parse(JSON.stringify(this.searchConfig.searchCriteria));
      criteria.loadAll = true;
      criteria.loadMore == false;
      criteria.loadedCount = 0;
      this.fileClosureService.createFileClosureSummaryExcel(criteria).subscribe(res => {
         if (res && res !== null) {
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = today.getFullYear();
            const todayStr = dd + '-' + mm + '-' + yyyy;
            const filename = 'FileClosureSummary' + '_' + todayStr + ".xlsx"
            this.toastrService.success("File Closure Summary exported successfully");
            this.sharedService.downLoadFile(res, "application/vnd.ms-excel", filename);
         } else {
            this.toastrService.error("Export failed.");
         }
      }, err => {
         this.toastrService.error("Export failed. Error - " + err);
      });
   }
}
