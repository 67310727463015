import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/shared/model/user.model';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/service/auth.service';
import { VendorsService } from 'src/app/shared/service/vendors/vendors.service';
import { Printer } from 'src/app/shared/model/vendors/printer.model';
import { SageService } from 'src/app/shared/service/sage/sage.service';

@Component({
   selector: 'app-printer',
   templateUrl: './printer.component.html',
   styleUrls: ['./printer.component.css']
})
export class PrinterComponent implements OnInit {

   createAction: boolean = false;
   viewAction: boolean = false;
   editable: boolean = false;

   constructor(private authService: AuthService, private router: Router, private vendorService: VendorsService,
      private activatedRoute: ActivatedRoute, private toastrService: ToastrService, private sageService:SageService) { }

   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;
   selectedPrinterId: string;
   printer: Printer;
   sageIntegrationViewList:any[]=[];


   ngOnInit() {
      this.getCompanyDetails();
      this.handleSubscriptions();
      this.printer = new Printer();
      this.getPrinter();
      this.evaluateAction();
      window.scrollTo(0, 0);
   }
   getCompanyDetails() {
      this.sageService.getAvailableCompanies().subscribe(
         response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.sageIntegrationViewList = response.responsePayload;
            }
         }
      );
   }

   handleSubscriptions() {
      // Subscriptions
      this.userAccess = new UserAccess();
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });

      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
         this.currentLoggedInUser = currentLoggedInUser;
      });
   }


   onSubmit() {
      if (this.editable && !this.createAction) {
         this.updatePrinter();
      } else {
         this.createPrinter();
      }
   }


   getPrinter() {
      let printerId = this.activatedRoute.snapshot.paramMap.get('id');
      this.selectedPrinterId = printerId;
      if (printerId) {
         this.vendorService.getPrinterById(printerId).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.printer = response.responsePayload;
            } else {
               this.toastrService.error(response.responseStatus.message);
            }
         });
      }
   }


   createPrinter() {
      this.vendorService.createPrinter(this.printer).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.router.navigate(['printer/list']);
         } else {
            console.error("Printer Component - create printer failed...", response.responseStatus.message);

            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }

   updatePrinter() {
      this.vendorService.updatePrinter(this.printer).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.getPrinter();
            this.editable = false;
            this.viewAction = true;
            //this.router.navigate(['printer/list']);
         } else {
            console.error("Printer Component - update Printer failed...", response.responseStatus.message);
            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }


   evaluateAction() {
      const activatedRoutePath = this.activatedRoute.snapshot.routeConfig.path;
      if (this.activatedRoute.snapshot.paramMap.get('action') === "view") {
         this.viewAction = true;
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "create") {
         this.createAction = true;
         this.editable = true;
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "edit") {
         this.editable = true;
         this.createAction = false;
      }
   }

   goBack() {
      this.router.navigate(['printer/list']);
   }

   edit(printerId: string) {
      this.vendorService.getPrinterById(printerId).subscribe(response => {
         this.printer = response.responsePayload;
      });
      this.createAction = false;
      this.viewAction = false;
      this.editable = true;

   }

   cancelEdit() {
      this.getPrinter();
      this.editable = false;
      this.viewAction = true;
   }

   addCompanyVendorDetails(){
      this.printer.vendorInfoList
   }

}
