import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/shared/model/product.model';

@Component({
  selector: 'app-printer-matrix',
  templateUrl: './printer-matrix.component.html',
  styleUrls: ['./printer-matrix.component.css']
})
export class PrinterMatrixComponent implements OnInit {

  constructor() { }
@Input() editable=false;
@Input() product:Product;
  ngOnInit(): void {
    /*{label:'Front Width',source:'frontWidth'},
    {label:'Front Height',source:'frontHeight'},
    {label:'Back Width',source:'backWidth'},
    {label:'Back Height',source:'backHeight'},
    {label:'PNL Code',source:'pnlCode'},
    {label:'Important Comments',source:'comments', type:'textArea'},
    */
  }
  attributes:any[]=[
    {label:'Front Width',source:'frontWidth'},
    {label:'Front Height',source:'frontHeight'},
    {label:'Back Width',source:'backWidth'},
    {label:'Back Height',source:'backHeight'},
    {label:'PNL Code',source:'pnlCode'},
    {label:'Important Comments',source:'comments', type:'textArea'},
  ];
  colorAttributes:any[]=[
    
    {label:'Channel 1',source:'frontColor1'},
    {label:'Channel 1',source:'backColor1'},


    
    {label:'Channel 2',source:'frontColor2'},
    {label:'Channel 2',source:'backColor2'},

    
    {label:'Channel 3',source:'frontColor3'},
    {label:'Channel 3',source:'backColor3'},


    
    {label:'Channel 4',source:'frontColor4'},
    {label:'Channel 4',source:'backColor4'},

    {label:'Channel 5',source:'frontColor5'},
    {label:'Channel 5',source:'backColor5'},

    {label:'Channel 6',source:'frontColor6'},
    {label:'Channel 6',source:'backColor6'},

    {label:'Channel 7',source:'frontColor7'},
    {label:'Channel 7',source:'backColor7'},

    {label:'Channel 8',source:'frontColor8'},
    {label:'Channel 8',source:'backColor8'},

    {label:'Channel 9',source:'frontColor9'},
    {label:'Channel 9',source:'backColor9'},

    {label:'Channel 10',source:'frontColor10'},
    {label:'Channel 10',source:'backColor10'},

    {label:'Channel 11',source:'frontColor11'},
    {label:'Channel 11',source:'backColor11'},

    {label:'Channel 12',source:'frontColor12'},
    {label:'Channel 12',source:'backColor12'},

    {label:'Channel 13',source:'frontColor13'},
    {label:'Channel 13',source:'backColor13'},
  ];

}
