import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ViewChild } from '@angular/core';
import { RoleService } from 'src/app/shared/service/role.service';
import { Role } from 'src/app/shared/model/role.model';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
   selector: 'app-role-list',
   templateUrl: './role-list.component.html',
   styleUrls: ['./role-list.component.css']
})
export class RoleListComponent implements OnInit {

   constructor(private authService:AuthService,private sharedService: SharedService, private toastrService: ToastrService, private roleService: RoleService) { }

   userAccessSubscription: Subscription;
   userAccess: UserAccess;

   roles: Role[];
   filteredRolesList: Role[];

   @ViewChild('addNewRoleForm') addNewRoleForm: any;
   newRole: Role = new Role;

   ngOnInit() {
      this.handleSubscriptions();
      this.getRoles();
      window.scrollTo(0, 0);
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
   }

   getRoles() {
      this.roleService.loadRoles().subscribe(response => {
         this.roles = response.responsePayload;
         this.filteredRolesList = this.roles;
         this.filteredRolesList = this.sharedService.sortListByPropertyName(this.filteredRolesList, 'roleDescription');
      });
   }

   addNewRole() {
      this.addNewRoleForm.reset();
      document.getElementById('addNewRoleModalToggler').click();
   }

   saveNewRole() {
      this.newRole.roleId = this.newRole.roleDescription.replace(/\s+/g, "_");
      //
      this.roleService.createRole(this.newRole).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let createdRole: Role = response.responsePayload;
            //
            this.toastrService.success(response.responseStatus.message);
            this.getRoles();
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
      }, (err) => {
         this.toastrService.error(JSON.stringify(err));
      });
   }

   deleteRole(roleToDelete: Role) {
      if(confirm('Are you sure you want to delete the role?\n1. This Role will be removed from the Users.\n2. Associated Permissions with the Role will be removed.')){
         this.roleService.deleteRole(roleToDelete).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.toastrService.success(response.responseStatus.message);
               this.getRoles();
            }
            else {
               this.toastrService.error(response.responseStatus.message);
            }
         }, (err) => {
            this.toastrService.error(JSON.stringify(err));
         });
      }
   }

   closeAddNewRoleModal() {
   }

   onKeyupRoleFilter(event, value) {
      if (event.key !== 'Shift') {
         if (value.trim().length > 0) {
            this.filteredRolesList = this.roles.filter(items => items.roleDescription.toUpperCase().indexOf(value.toUpperCase()) > -1);
         } else {
            this.filteredRolesList = this.roles;
         }
      }
   }
}
