import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../../environments/environment';
import { ApiResponse } from '../model/api-response';
import { Blanks } from '../model/blanks.model';

@Injectable({
   providedIn: 'root'
})
export class BlanksService {
   constructor(private http: HttpClient) { }

   readonly rooturl = environment.apiEndPoint;

   getBlanksList(): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/blanks/list', null);
   }

   getBlanksById(blanksId: string): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/blanks/'+blanksId, blanksId);
   }

   deleteBlanks(blanks: Blanks): Observable<ApiResponse>{
      return this.http.post<any>(this.rooturl + '/blanks/delete', blanks);
   }

   createBlanks(blanks: Blanks){
      return this.http.post<any>(this.rooturl + '/blanks/create', blanks);
   }

   updateBlanks(blanks: Blanks){
      return this.http.post<any>(this.rooturl + '/blanks/update', blanks);
   }

}
