export class UpcDetail {
   upcList: Upc[] = [];
   sizeListWithNoUpcCode: Upc[] = [];
}

export class Upc {
   id: string;
   parentId: string;
   upcCode: string;
   size: string;
   sizeSelected: boolean;
   upcCodeCreationType: string;

   constructor() {
      this.id = null;
      this.parentId = null;
      this.upcCode = null;
      this.size = null;
      this.sizeSelected = false;
      this.upcCodeCreationType = null;
   }
}
