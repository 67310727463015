import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SearchComponent } from 'src/app/common/search/search.component';
import { SearchDetails } from 'src/app/shared/model/common/search-details.model';
import { FilterConfig } from 'src/app/shared/model/common/search-filter.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { SearchService } from 'src/app/shared/service/search.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared/service/shared.service';
import { SearchCriteriaBuilder } from 'src/app/shared/model/common/search-criteria-builder.model';
import { RoyaltyMismatchView } from 'src/app/shared/model/sales/royalty-mismatch/royalty-mismatch-view.model';
import { RoyaltyMismatchService } from 'src/app/shared/service/sales/royalty-mismatch/royalty-mismatch.service';

@Component({
  selector: 'app-royalty-mismatch-list',
  templateUrl: './royalty-mismatch-list.component.html',
  styleUrls: ['./royalty-mismatch-list.component.css']
})
export class RoyaltyMismatchListComponent implements OnInit {

   userAccessSubscription: Subscription;
   userAccess: UserAccess;

   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;

   royaltyMismatchViewList: RoyaltyMismatchView[] = [];

   searchConfig: SearchDetails;

   sortColumns = [
      { columnName: 'SALES_ORDER_NUMBER', sortByName: 'Sales Order #' },
      { columnName: 'APPROVAL_DATE', sortByName: 'Approval Date' },
      { columnName: 'PRODUCTION_STATUS', sortByName: 'Production Status' },
   ];

   @ViewChild('searchComponent') searchComponent: SearchComponent<RoyaltyMismatchView>;

   constructor(private authService: AuthService, private searchService: SearchService, private royaltyMismatchService: RoyaltyMismatchService,
      private sharedService: SharedService, private toastrService: ToastrService) { }

   ngOnInit(): void {
      this.handleSubscriptions();
      if (!this.searchConfig) {
         this.searchConfig = this.searchService.royaltyMismatchListSearchDetails;
         this.setFilterConfigs();
      }
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
         this.currentLoggedInUser = currentLoggedInUser;
      });
   }

   setFilterConfigs() {
      if (!this.searchConfig.filterConfigs) {
         this.searchConfig.filterConfigs = [];
         let approvateDateFilterConfig: FilterConfig = {
            filterType: 'DATE_RANGE',
            label: 'Approval Date',
            currentSelection: undefined,
            fieldName: 'APPROVAL_DATE'
         };
         this.searchConfig.filterConfigs.push(approvateDateFilterConfig);
      }
   }

   createRoyaltyMismatchExcel() {
      let criteria: SearchCriteriaBuilder = JSON.parse(JSON.stringify(this.searchConfig.searchCriteria));
      criteria.loadAll = true;
      criteria.loadMore == false;
      criteria.loadedCount = 0;
      this.royaltyMismatchService.createRoyaltyMismatchExcel(criteria).subscribe(res => {
         if (res && res !== null && res.size>0) {
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = today.getFullYear();
            const todayStr = dd + '-' + mm + '-' + yyyy;
            const filename = 'RoyaltyMismatch' + '_' + todayStr + ".xlsx"
            this.toastrService.success("Royalty Mismatch Exported Successfully.");
            this.sharedService.downLoadFile(res, "application/vnd.ms-excel", filename);
         } else {
            this.toastrService.error("Royalty Mismatch Export Failed.");
         }
      }, err => {
         this.toastrService.error("Royalty Mismatch Export Failed. Error - " + err);
      });
   }

}
