import { Injectable } from '@angular/core';
import { CostTypeDetail } from 'src/app/shared/model/sales/costsheet/cost-type-detail.model';
import { CostingSheet } from '../../model/sales/costing/costingSheet/costing-sheet.model';
import { StyleCosting } from '../../model/sales/costing/allocation/style-costing.model';
import { AllocatedBlankSupplierDetails, BlankSupplierMatrixDetails } from '../../model/sales/costing/allocation/allocated-blank-supplier-details.model';
import { CostingAllocation } from '../../model/sales/costing/allocation/costing-allocation.model';
import { AllocatedPrinterDetails } from '../../model/sales/costing/allocation/allocated-printer-details.model';
import { AbstractClassPart } from '@angular/compiler/src/output/output_ast';

@Injectable({
   providedIn: 'root'
})
export class CostingHelperService {
  
   public static materialChangeArray: string[] = ["totalCost", "totalOrderQuantity", "trims", "orderDetailAdded", "orderDetailRemoved"];

   constructor() { }

   /*calculateCostingSheetsForStyle(styleCosting: StyleCosting) {
      for (let j = 0; j < styleCosting.costingSheets?.length; j++) {
         let costingSheet: CostingSheet = styleCosting.costingSheets[j];
         this.calculateCostingSheet(styleCosting, costingSheet);
      }
   }*/

   calculateCostingSheet(costingAllocation:CostingAllocation,styleCosting: StyleCosting, costingSheet: CostingSheet) {
      //console.log('calculateCostingSheet CAlled');
      this.calculateUnitCostForSpecialCostTypes(costingAllocation,styleCosting, costingSheet);
      this.calculateTotalCosts(styleCosting, costingSheet)
      this.calculateTotalProductionCost(costingSheet);
      this.calculateTotalSellingCost(costingSheet);
      this.calculateGrossProfit(styleCosting, costingSheet);
      this.roundUpAllValues(costingSheet);
   }

   calculateUnitCostForSpecialCostTypes(costingAllocation:CostingAllocation,styleCosting: StyleCosting, costingSheet: CostingSheet) {
      for (let j = 0; j < costingSheet.costTypeDetailList?.length; j++) {
         let costTypeDetail: CostTypeDetail = costingSheet.costTypeDetailList[j];
         // IMPORT DUTY - SPECIAL HANDLING
         if(costTypeDetail.description=== 'Import Duty'&& costTypeDetail.category==='PRODUCTION_COST' && costTypeDetail.subCategory==='FREIGHT_DUTY_COST' && costTypeDetail.classification === 'PRINTER'){
            let printIngCostType = this.getCostTypeDetail(costingSheet,'PRODUCTION_COST','PRINTING_COST');
            costTypeDetail.unitCost = (printIngCostType.unitCost*costTypeDetail.remarks)/100
         }

         // SCREEN PRINTING CHARGES - SPECIAL HANDLING
         if(costTypeDetail.description==='Film charges' && costTypeDetail.category==='PRODUCTION_COST' && costTypeDetail.subCategory==='PRINTING_FILM_SETUP_SCREEN_COST'){
            costTypeDetail.remarks = costTypeDetail.remarks*styleCosting.noOfColours;
            costTypeDetail.unitCost = (costTypeDetail.unitCost*styleCosting.noOfColours);// Unit cost must be used as it has been already sanitized for currency.
            
         }
      }
   }

   calculateTotalCosts(styleCosting: StyleCosting, costingSheet: CostingSheet) {
      for (let j = 0; j < costingSheet.costTypeDetailList?.length; j++) {
         let costTypeDetail: CostTypeDetail = costingSheet.costTypeDetailList[j];

         if (costTypeDetail.category === 'SELLING_COST' && costTypeDetail.costType !== 'HEADER' && costTypeDetail.costType !== 'TOTAL') {
            costTypeDetail.remarks = costTypeDetail.uiCost;
            if (costTypeDetail.calculationType === 'PERCENTAGE') {
               if (costTypeDetail.subCategory === 'SALES_COMMISSION') {
                  let tmpCustomerDiscountCostTypeDetail = this.getCostTypeDetail(costingSheet, 'SELLING_COST', 'CUSTOMER_DISCOUNTS');
                  costTypeDetail.unitCost = ((styleCosting.sellingPricePerUnit - tmpCustomerDiscountCostTypeDetail.unitCost) * costTypeDetail.remarks) / 100;

               } else {
                  costTypeDetail.unitCost = (styleCosting.sellingPricePerUnit * costTypeDetail.remarks) / 100;
               }
            } else {
               costTypeDetail.unitCost = styleCosting.sellingPricePerUnit * costTypeDetail.remarks;
            }
         }

         // TOTAL COST Calculation in the end. After Unit Costs are calculated.
         if (costTypeDetail.classification === 'SUPPLIER') {
            costTypeDetail.totalCost = costTypeDetail.unitCost * styleCosting.totalRequiredBlanksQuantity;
         } 
         else if (costTypeDetail.category === 'SELLING_COST' && (costTypeDetail.subCategory==='ROYALTY' || costTypeDetail.subCategory==='CUSTOMER_DISCOUNTS' || costTypeDetail.subCategory==='SALES_COMMISSION')) {
            costTypeDetail.totalCost = costTypeDetail.unitCost * styleCosting.orderQty;
         }
         else {
            costTypeDetail.totalCost = costTypeDetail.unitCost * styleCosting.totalQty;
         }
      }
   }


   calculateTotalProductionCost(costingSheet: CostingSheet): CostTypeDetail {
      let totalProductionCostTypeDetailObject: CostTypeDetail = new CostTypeDetail;
      let totalProductionUnitCost: number = 0;
      let totalProductionTotalCost: number = 0;
      for (let j = 0; j < costingSheet.costTypeDetailList?.length; j++) {
         let costTypeDetail: CostTypeDetail = costingSheet.costTypeDetailList[j];
         if (costTypeDetail.category === 'PRODUCTION_COST' && costTypeDetail.subCategory !== 'TOTAL_PRODUCTION_COST') {
            totalProductionUnitCost = totalProductionUnitCost + costTypeDetail.unitCost;
            totalProductionTotalCost = totalProductionTotalCost + costTypeDetail.totalCost;
         }
         if (costTypeDetail.category === 'PRODUCTION_COST' && costTypeDetail.subCategory === 'TOTAL_PRODUCTION_COST') {
            costTypeDetail.unitCost = totalProductionUnitCost;
            costTypeDetail.totalCost = totalProductionTotalCost;
            totalProductionCostTypeDetailObject.unitCost = totalProductionUnitCost;
            totalProductionCostTypeDetailObject.totalCost = totalProductionTotalCost;
         }
      }
      return totalProductionCostTypeDetailObject;
   }


   /** This method calculates Total Selling Costs **/
   calculateTotalSellingCost(costingSheet: CostingSheet): CostTypeDetail {
      let totalSelliingCostTypeDetailObject: CostTypeDetail = new CostTypeDetail;
      let totalSellingUnitCost: number = 0;
      let totalSellingTotalCost: number = 0;
      for (let j = 0; j < costingSheet.costTypeDetailList?.length; j++) {
         let costTypeDetail: CostTypeDetail = costingSheet.costTypeDetailList[j];
         if (costTypeDetail.category === 'SELLING_COST' && costTypeDetail.subCategory !== 'TOTAL_SELLING_COST') {
            totalSellingUnitCost = totalSellingUnitCost + costTypeDetail.unitCost;
            totalSellingTotalCost = totalSellingTotalCost + costTypeDetail.totalCost;
         }
      }
      //console.log('calculateTotalSellingCost',costingSheet);
      let totalSellingCostType: CostTypeDetail = this.getCostTypeDetail(costingSheet, 'SELLING_COST', 'TOTAL_SELLING_COST');
      totalSellingCostType.unitCost = totalSellingUnitCost;
      totalSellingCostType.totalCost = totalSellingTotalCost;
      totalSelliingCostTypeDetailObject.unitCost = totalSellingUnitCost;
      totalSelliingCostTypeDetailObject.totalCost = totalSellingTotalCost;
      return totalSelliingCostTypeDetailObject;
   }


   calculateGrossProfit(styleCosting: StyleCosting, costingSheet: CostingSheet) {
      let totalProductionCostType: CostTypeDetail = this.calculateTotalProductionCost(costingSheet);
      let totalSellingCostType: CostTypeDetail = this.calculateTotalSellingCost(costingSheet);

      let grandTotalUnitCost: number = 0;
      grandTotalUnitCost = totalProductionCostType.unitCost + totalSellingCostType.unitCost;
      let grandTotalCost: number = 0;
      grandTotalCost = totalProductionCostType.totalCost + totalSellingCostType.totalCost;

      let sellingPricePerUnit = styleCosting.sellingPricePerUnit;
      let totalSalesRevenue = (styleCosting.orderQty * styleCosting.sellingPricePerUnit);
      //console.log('sellingPricePerUnit = ', sellingPricePerUnit);
      //console.log('totalRevenue', totalSalesRevenue);

      let grossProfitUnit: number = 0;
      grossProfitUnit = styleCosting.sellingPricePerUnit - grandTotalUnitCost;
      let grossProfitTotal: number = 0;
      grossProfitTotal = totalSalesRevenue - grandTotalCost;

      for (let j = 0; j < costingSheet.costTypeDetailList?.length; j++) {
         let costTypeDetail: CostTypeDetail = costingSheet.costTypeDetailList[j];
         if (costTypeDetail.category === 'TOTAL_COST') {
            costTypeDetail.unitCost = grandTotalUnitCost;
            costTypeDetail.totalCost = grandTotalCost;
         }
         if (costTypeDetail.category === 'TOTAL_SALES_REVENUE') {
            costTypeDetail.unitCost = sellingPricePerUnit;
            costTypeDetail.totalCost = totalSalesRevenue;
         }

         if (costTypeDetail.category === 'GROSS_PROFIT' && costTypeDetail.calculationType === 'ABSOLUTE_UNIT') {
            costTypeDetail.unitCost = grossProfitUnit;
            costTypeDetail.totalCost = grossProfitTotal;
         }
         if (costTypeDetail.category === 'GROSS_PROFIT' && costTypeDetail.calculationType === 'PERCENTAGE') {
            costTypeDetail.unitCost = ((grossProfitUnit / styleCosting.sellingPricePerUnit) * 100);
            costTypeDetail.totalCost = ((grossProfitTotal / totalSalesRevenue) * 100);
         }
      }
      costingSheet.grossProfit = this.roundUp(grossProfitTotal,3);
      costingSheet.grossProfitPercentage = this.roundUp((grossProfitTotal / totalSalesRevenue) * 100,3);
      costingSheet.totalCost = this.roundUp(grandTotalCost,3);
      costingSheet.totalRevenue = this.roundUp(totalSalesRevenue,3);
   }


   getCostTypeDetail(costingSheet: CostingSheet, category: string, subCategory: string): CostTypeDetail {
      for (let j = 0; j < costingSheet.costTypeDetailList?.length; j++) {
         let costTypeDetail: CostTypeDetail = costingSheet.costTypeDetailList[j];
         if (costTypeDetail.category === category && costTypeDetail.subCategory === subCategory) {
            return costTypeDetail;
         }
      }
   }

   roundUpAllValues(costingSheet: CostingSheet) {
      //console.log('Round up values');
      for (let j = 0; j < costingSheet.costTypeDetailList?.length; j++) {
         let costTypeDetail: CostTypeDetail = costingSheet.costTypeDetailList[j];
         //console.log(costTypeDetail.unitCost);
         costTypeDetail.unitCost = this.roundUp(costTypeDetail.unitCost, 3);
         costTypeDetail.totalCost = this.roundUp(costTypeDetail.totalCost, 3);
         //console.log(costTypeDetail.unitCost);
      }
   }


   roundUp(value: number, decimalPlaces): number {
      return Number(Math.round(parseFloat(value + 'e' + decimalPlaces)) + 'e-' + decimalPlaces);
   }


   sanitizeCurrency(salesOrderCurrency: string, costTypeCurrency: string, unitCost: number, exchangeRateUsdToCad: number, exchangeRateCadToUsd: number): number {
      if (salesOrderCurrency === costTypeCurrency) {
         return unitCost;
      }
      else {
         return unitCost * this.getCurrencyConversion(costTypeCurrency + 'TO' + salesOrderCurrency,exchangeRateUsdToCad,exchangeRateCadToUsd);
      }
   }

   getCurrencyConversion(key: string, exchangeRateUsdToCad: number, exchangeRateCadToUsd: number): number {
      if (key === 'CADTOUSD') {
         return exchangeRateCadToUsd;
      } else if (key === 'USDTOCAD') {
         return exchangeRateUsdToCad;
      } else {
         return 1;
      }
   }

   isCurrencyMismatch(costTypeCurrency: string, salesOrderCurrency: string): boolean {
      return (costTypeCurrency !== salesOrderCurrency) ? true : false;
   }

   isCostTypeDetailSame(costTypeDetail1: CostTypeDetail, costTypeDetail2: CostTypeDetail): boolean {
      if (costTypeDetail1.id === costTypeDetail2.id && costTypeDetail1.category === costTypeDetail2.category
         && costTypeDetail1.subCategory === costTypeDetail2.subCategory && costTypeDetail1.sortOrder === costTypeDetail2.sortOrder) {
         return true;
      } else {
         return false;
      }
   }

   sanitizeAllocatedBlankSupplier(costingAllocation:CostingAllocation) {
      let toRemoveSuppliers:AllocatedBlankSupplierDetails[]=[]
      if(costingAllocation.allocatedSuppliers && costingAllocation.allocatedSuppliers.length>0){
        costingAllocation.allocatedSuppliers.forEach(alloSupp => {
          let isRequired:boolean=false;
          costingAllocation.styleCosting.forEach(sc => {
            if(sc.bSupplierCombDetails && sc.bSupplierCombDetails.length>0){
              sc.bSupplierCombDetails.forEach(bComb => {
                if(bComb && bComb.bSupplierDetails && bComb.bSupplierDetails.length>0){
                  bComb.bSupplierDetails.forEach(bSupp => {
                    if(bSupp.supplierId===alloSupp.supplierId){
                      isRequired=true;
                    }
                  });
                }
              });
            }
          });
          if(!isRequired){
            toRemoveSuppliers.push(alloSupp);
          }
        });
      }
      if(toRemoveSuppliers && toRemoveSuppliers.length>0){
        toRemoveSuppliers.forEach(suppToRemove => {
          costingAllocation.allocatedSuppliers=costingAllocation.allocatedSuppliers.filter(x=>x.supplierId!=suppToRemove.supplierId)
        });
      }
      this.sanitizeFreightDetailsMatrix(costingAllocation);
    }
    sanitizeAllocatedPrinters(costingAllocation:CostingAllocation) {
      let toRemovePrinters:AllocatedPrinterDetails[]=[]
      if(costingAllocation.allocatedPrinters && costingAllocation.allocatedPrinters.length>0){
        costingAllocation.allocatedPrinters.forEach(alloPrinter => {
          let isRequired:boolean=false;
          costingAllocation.styleCosting.forEach(sc => {
            if(sc.printerCombDetails && sc.printerCombDetails.length>0){
              sc.printerCombDetails.forEach(pComb => {
                if(pComb && pComb.printerDetails && pComb.printerDetails.length>0){
                  pComb.printerDetails.forEach(printer => {
                    if(printer.printerId===alloPrinter.printerId){
                      isRequired=true;
                    }
                  });
                }
              });
            }
          });
          if(!isRequired){
            toRemovePrinters.push(alloPrinter);
          }
        });
      }
      if(toRemovePrinters && toRemovePrinters.length>0){
         toRemovePrinters.forEach(printerToRemove => {
          costingAllocation.allocatedPrinters=costingAllocation.allocatedPrinters.filter(x=>x.printerId!=printerToRemove.printerId)
        });
      }
      this.sanitizeFreightDetailsMatrix(costingAllocation);
    }

    sanitizeFreightDetailsMatrix(costingAllocation: CostingAllocation) {
      
      if(costingAllocation.allocatedSuppliers && costingAllocation.allocatedSuppliers.length>0){
         costingAllocation.allocatedSuppliers.forEach(alloSupp => {
            //add missing Printer Details
            if(costingAllocation.allocatedPrinters && costingAllocation.allocatedPrinters.length>0){
               costingAllocation.allocatedPrinters.forEach(alloPrinter => {
                  let arr=alloSupp.freightDetailsMatrix?.filter(x=>x.printerId===alloPrinter.printerId);
                  if(arr && arr.length===0){
                     this.createAndAssignNewFreightDetails(alloSupp,alloPrinter); 
                  }
               });
            }
            //add missing Printer Details
            let toRemoveFreight:BlankSupplierMatrixDetails[]=[];
            if(alloSupp.freightDetailsMatrix && alloSupp.freightDetailsMatrix.length>0){
               alloSupp.freightDetailsMatrix.forEach(freightDetails => {
                  let isRequired:boolean=false;
                  if(costingAllocation.allocatedPrinters && costingAllocation.allocatedPrinters.length>0){
                     costingAllocation.allocatedPrinters.forEach(alloPrinter => {
                        if(alloPrinter.printerId===freightDetails.printerId){
                           isRequired=true;
                        }
                     });
                  }
                  if(!isRequired){
                     toRemoveFreight.push(freightDetails);
                   }
               });
            }
            if(toRemoveFreight && toRemoveFreight.length>0){
               toRemoveFreight.forEach(freightToRemove => {
                alloSupp.freightDetailsMatrix=alloSupp.freightDetailsMatrix.filter(x=>x.printerId!=freightToRemove.printerId);
              });
            }
         
         });
      }
    }

    getFreightCost(costingAllocation:CostingAllocation,supplierId:string,printerId:string):number{
      let retVal:number=0;
      if(costingAllocation.allocatedSuppliers && costingAllocation.allocatedSuppliers.length>0){
         let arr=costingAllocation.allocatedSuppliers.filter(x=>x.supplierId===supplierId);
         if(arr.length>0 && arr[0].freightDetailsMatrix && arr[0].freightDetailsMatrix.length>0){
            let fd=arr[0].freightDetailsMatrix.filter(x=>x.printerId===printerId);
            if(fd.length>0){
               retVal=fd[0].value;
            }
         }
      }
      return retVal;
    }

   createAndAssignNewFreightDetails(alloSupp: AllocatedBlankSupplierDetails, alloPrinter: AllocatedPrinterDetails) {
      let freightDetails=new BlankSupplierMatrixDetails();
      freightDetails.printerId=alloPrinter.printerId;
      freightDetails.printerName=alloPrinter.printerName;
      freightDetails.type="FREIGHT";
      freightDetails.value=0;
      alloSupp.freightDetailsMatrix.push(freightDetails);
   }
}
