import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { Observable } from "rxjs/index";
import { environment } from 'src/environments/environment';
import { Royalty } from '../model/royalty.model';

@Injectable({
   providedIn: 'root'
})
export class RoyaltyService {
   readonly baseUrl = environment.apiEndPoint;

   constructor(private http: HttpClient) { }

   getRoyaltyList(): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/royalty/list', null);
   }

   createOrUpdateRoyaltyList(royaltyList: Royalty[]): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/royalty/list/save', royaltyList);
   }

   getRoyalty(royaltyId: string): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/royalty/' + royaltyId, null);
   }

   createOrUpdateRoyalty(royalty: Royalty): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/royalty/save', royalty);
   }

   deleteRoyalty(royaltyId: string): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/royalty/delete/' + royaltyId, null);
   }

   getDefaultRoyaltyFeeDetailList(): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/royalty/feeDetail/default/list', null);
   }

   getDefaultRoyaltyTierDetailList(): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/royalty/tierDetail/default/list', null);
   }

   getRoyaltyProductCategoryList(): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUrl + '/royalty/product/category/list', null);
   }


}
