import { BlanksDetails } from './blanks-details.model';

export class BlanksSupplier {
   id: string;
   name: string;
   status: boolean;
   notes: string;
   currency:string;
   blanksDetailsList: BlanksDetails[] = [];
}
