<div  appDragDrop  [editable]="editable" *ngIf="!isGroupedAttachments"
(onFileDropped)="fileSelectedDragDrop($event,category)" >
    <div class="row">
        <div [class]="editable?'col-sm-5':'col-sm-auto'" *ngIf="showHeader">
            <h4 *ngIf="headerSize==='4'">{{header}}</h4>
            <h5 *ngIf="headerSize==='5'">{{header}}</h5>
            <h6 *ngIf="headerSize==='6'">{{header}}</h6>
            <span style="font-size: 13px; font-weight: 550;" *ngIf="headerSize==='s13'">{{header}}</span>
        </div>
        <div class="col-sm">
            <div *ngIf="editable && canUpload" class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop [editable]="editable"
                (onFileDropped)="fileSelectedDragDrop($event,category)">
                <input hidden type="file" #fileInput multiple onclick="this.value=null"
                    (change)="fileSelectedDragDrop($event.target.files,category)">
            </div>
        </div>
    </div>
    
    <hr *ngIf="showHeader" [class]="headerSize==='s13'?'hr-light':''">
   
    <div *ngIf="getAvailableAttachment()===0 && !emptyListError">
        <p class="text-muted f-12"><em>{{header}} not available</em></p>
    </div>
    <div *ngIf="getAvailableAttachment()===0 && emptyListError">
        <input hidden [ngClass]="{'is-invalid':getAvailableAttachment()===0}" >
        <div *ngIf="getAvailableAttachment()===0" class="invalid-feedback">
                {{emptyListError}}                         
        </div>
    </div>
    <div class="border-box" style="background-color: transparent;" *ngIf="attachmentList?.length>0 && category!=='IMAGES'" >
        <ng-container class="row ml-2 mt-1" *ngFor="let attachment of attachmentList;index as index">
            <div class="row ml-1 mt-1" *ngIf="!attachment.toDelete">
                <div class="col-2 d-inline">
                    <div class="row mb-1">

                        <div class="d-inline ml-1 d-flex align-items-center" *ngIf="isSelectable && !editable">
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" name="{{attachment.id}}" 
                                   [(ngModel)]="attachment.isSelected" [disabled]="editable" id="{{attachment.id}}">
                                   <label class="custom-control-label" for="{{attachment.id}}">&nbsp;</label>
                             </div>
                        </div>
                        <!--<div class="d-inline ml-1" *ngIf="category=== 'PHOTO_APPROVAL' && attachment.mimeType &&   attachment.mimeType.startsWith('image')">
                            <img [src]="attachment.thumbnailSignedUrl"
                                                class="img-thumbnail img-fluid "
                                                style="display: block;  width:30px;  height:30px; padding: 0rem;z-index:9999 ; border: none;"
                                                (error)="handleThumbnailError(attachment,$event)"
                                                >
                        </div>
                        <div *ngIf="category=== 'PHOTO_APPROVAL' && attachment.mimeType && !attachment.mimeType.startsWith('image')" class="ml-1">
                            <span class="d-flex justify-content-center align-items-center" style="display: block;  width:30px;  height:30px; padding: 0rem;z-index:9999 ; border: none;"><i class="far fa-eye-slash"></i></span>
                        </div>-->
                        
                        <div class="d-inline ml-1"><a class="btn btn-sm"
                            [href]="attachment.fileSignedUrl" *ngIf=" !createAction && canDownload" target="_blank"
                            [download]="attachment.originalFileName"><i class="fas fa-download"></i></a>
                        </div>
                        <div class="d-inline ml-1" *ngIf="!editable && canQuickView && (attachment.mimeType?.startsWith('image') || attachment.originalFileName.toUpperCase().endsWith('.PDF'))">
                            <a class="btn btn-sm" (click)="openAttachmentViewer(attachment)"><i class="fas fa-expand"></i></a>
                        </div>
                        <div *ngIf="editable && canDelete" class="d-inline ml-1">
                            <span type="button" class="btn btn-sm"
                                (click)="deleteFile(attachment.id,category)"><i
                                    class="fas fa-trash "></i></span>
                        </div>
                       
                        <div *ngIf="editable && !attachment.islocal && canRenameFile" class="d-inline ml-1">
                            <span type="button" class="btn btn-sm"
                                (click)="renameFile(attachment.id,category)"><i
                                    class="fas fa-pen"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-4 text-muted   {{viewCreatedBy?'d-flex flex-column':'d-flex align-items-center'}}" [style]="viewCreatedBy?'font-size: x-small':'font-size: small'">
                    <label class="mb-0">{{attachment.uploadDate | date:'medium'}}</label>
                    <span *ngIf="viewCreatedBy" style="color:lightslategray;font-style: italic;">{{attachment.createdBy}}</span>
                </div>

                <div class="col text-muted d-flex align-items-center" style="font-size: small;">
                    <span *ngIf="editable && !attachment.islocal && likeable && !attachment.liked" style="color:lightslategray;font-style: italic;margin-bottom: 0.5rem" (click)="liked(attachment)">
                        <i class="far fa-thumbs-up"
                        style="color: #6c757d24;" 
                                          onmouseover="this.style.color='tomato'" 
                                          onmouseout="this.style.color='#6c757d24'"></i></span>
                    <span *ngIf="likeable && editable && attachment.liked" style="margin-bottom: 0.5rem">
                    <i class="fas fa-thumbs-up" style="color: tomato;"  >
                    </i>
                </span>
                    <!--<a (click)="openAttachmentViewer(attachment)" >--><label class="text-break ml-1">{{attachment.originalFileName}}</label>
                </div>
            </div>
           
        </ng-container>
    </div>



    <!---IMAGES-->
    <div *ngIf="category==='IMAGES'">
        
        <div *ngIf="selectedImgAccesUrl" class="row d-flex justify-content-center align-items-center">
            <div class="card" style="width:95%;height:500px;">
                   <div class="card-body d-flex justify-content-center align-items-center" [@fadeInOut]>
                    <img [src]="selectedImgAccesUrl"
                            class="img-fluid img-auto-size-500" alt="Error"><!-- style="z-index: 1;"-->
                    </div> 
                    <div class="card-img-overlay"
                            style="display: block; height:3rem;width: 3rem; top:70% !important;"><!--Add: z-index:2;width: 3rem; top:70% !important; Remove:  h-100 d-flex flex-column justify-content-end -->
                            <div>
                                    <div class="mb-2">
                                            <a class="btn btn-circle" *ngIf="canDownload"
                                                    [href]="selectedImgAccesUrl"
                                                    target="_blank"
                                                    [download]="selectedImgAccesUrl.split('/').pop()"><i
                                                            class="fas fa-download fa-lg text-light"></i>
                                    </a>
                                    </div>
                                    <div *ngIf="editable  && canDelete && ((!createAction && getAvailableAttachment()>1)||(createAction && getAvailableAttachment()>0))" class="mb-2">
                                            <button type="button" class="btn btn-circle"
                                                    (click)="deleteFile(selectedImgId,category)"><i
                                                            class="fas fa-trash fa-lg text-light"></i>
                                            </button>
                                    </div>


                            </div>

                    </div>

            </div>
        </div>
        <div class="overflow-auto min-height-hor-scroll" style="width:100%" #scrollingThing (mouseover)="showSlideTracker=true;" (mouseleave)="showSlideTracker=false;">
            <div class="ml-1 mt-1 mr-1 " style="width:max-content">
                <div class="d-flex align-items-center justify-content-center float-left"  style="max-width: fit-content;" *ngIf="showSlideTracker &&  scrollingThing?.scrollWidth>scrollingThing?.clientWidth"><div type="button" style="opacity: 0.6;border-radius: 10%;" class="btn btn-sm btn-dark" (mouseover)="scrollDiv(scrollingThing, 3)" (mouseleave)="stopTimer(timer)">&lt;</div></div>
                <div class="d-flex align-items-center justify-content-center float-right" style="max-width: fit-content;" *ngIf="showSlideTracker &&  scrollingThing?.scrollWidth>scrollingThing?.clientWidth"><div type="button" style="opacity: 0.6;border-radius: 10%;" class="btn btn-sm btn-dark" (mouseover)="scrollDiv(scrollingThing, -3)" (mouseleave)="stopTimer(timer)">&gt;</div></div>
                <div cdkDropList [cdkDropListData]="attachmentList" (cdkDropListDropped)="drop($event)" cdkDropListOrientation="horizontal" class="icon-drag-list">
                    <ng-container *ngFor="let images of attachmentList">
                        <div class="d-flex d-inline pop ml-1" cdkDrag [cdkDragDisabled]="!(editable ||createAction)"
                                ><!--[cdkDragDisabled]="!(editable ||createAction)"-->
                                <a (click)="showImage(images.fileSignedUrl,images.id,$event)" *ngIf="!images.toDelete"
                                        [style.background-color]="images.fileSignedUrl== selectedImgAccesUrl? 'white' : 'white'">
                                        <img [src]="images.thumbnailSignedUrl"
                                                class="img-thumbnail img-fluid"
                                                style="display: block;  width:60px;  height:60px;"
                                                [style.border-color]="images.fileSignedUrl== selectedImgAccesUrl? 'cadetblue' : '#80808033'"
                                                (error)="handleThumbnailError(images,$event)"
                                                matTooltip="&#8226; File Name : {{images.originalFileName}} &#13;&#8226; Uploaded On : {{images.uploadDate | date:'medium'}} {{viewCreatedBy  ? '&#13;&#8226; Uploaded By : '+images.createdBy : ''}}"  
                matTooltipPosition="below" matTooltipClass="bg-dark allow-cr f-10">
                                </a>
                                <a *ngIf="images.toDelete"
                                        [style.background-color]="images.fileSignedUrl== selectedImgAccesUrl? 'white' : 'white'">
                                        <img src="/assets/deleteImage2.png"
                                                class="img-thumbnail img-fluid"
                                                style="display: block;  width:60px;  height:60px;"
                                                matTooltip="File has been marked for deletion" matTooltipPosition="below" matTooltipClass="bg-dark allow-cr f-10">
                                </a>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

</div>
<div *ngIf="isGroupedAttachments">
    <div class="col-sm-5" *ngIf="showHeader">
        <h4 *ngIf="headerSize==='4'">{{header}}</h4>
        <h5 *ngIf="headerSize==='5'">{{header}}</h5>
        <h6 *ngIf="headerSize==='6'">{{header}}</h6>
    </div>
    <hr *ngIf="showHeader">
    <div *ngIf="getAvailableAttachment()===0 && !emptyListError">
        <p class="text-muted f-12"><em>{{header}} not available</em></p>
    </div>
    <div *ngIf="getAvailableAttachment()===0 && emptyListError">
        <input hidden [ngClass]="{'is-invalid':getAvailableAttachment()===0}" >
        <div *ngIf="getAvailableAttachment()===0" class="invalid-feedback">
                {{emptyListError}}                         
        </div>
    </div>
    <div *ngFor="let groupItemAttachment of groupItemsAttachment">
        <ng-container *ngIf="editable || (!editable && groupItemAttachment && groupItemAttachment.groupAttachmentList?.length>0)">
        <div  appDragDrop  [editable]="editable"
        (onFileDropped)="fileSelectedDragDrop($event,category,groupItemAttachment.groupItemConfig)" class="mb-2  p-2">
            <div class="row">
                <div class="col-sm-6">{{groupItemAttachment?.groupName}}</div>
                <div class="col-sm-6">
                    <div *ngIf="editable && canUpload" class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop [editable]="editable"
                        (onFileDropped)="fileSelectedDragDrop($event,category,groupItemAttachment.groupItemConfig)">
                        <input hidden type="file" #fileInput multiple onclick="this.value=null"
                            (change)="fileSelectedDragDrop($event.target.files,category,groupItemAttachment.groupItemConfig)">
                    </div>
                </div>
            </div>
            <hr class="m-1" style="display: none;">
            
            
            <div class="border-box" style="background-color: transparent;" *ngIf="attachmentList?.length>0" >
                <ng-container class="row ml-2 mt-1" *ngFor="let attachment of groupItemAttachment.groupAttachmentList;index as index">
                    <div class="row ml-1 mt-1" *ngIf="!attachment.toDelete">
                        <div class="col-2 d-inline">
                            <div class="row mb-1">
        
                                <div class="d-inline ml-1 d-flex align-items-center" *ngIf="isSelectable && !editable">
                                    <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" name="{{attachment.id}}" 
                                        [(ngModel)]="attachment.isSelected" [disabled]="editable" id="{{attachment.id}}">
                                        <label class="custom-control-label" for="{{attachment.id}}">&nbsp;</label>
                                    </div>
                                </div>
                                <!--<div class="d-inline ml-1" *ngIf="category=== 'PHOTO_APPROVAL' && attachment.mimeType &&   attachment.mimeType.startsWith('image')">
                                    <img [src]="attachment.thumbnailSignedUrl"
                                                        class="img-thumbnail img-fluid "
                                                        style="display: block;  width:30px;  height:30px; padding: 0rem;z-index:9999 ; border: none;"
                                                        (error)="handleThumbnailError(attachment,$event)"
                                                        >
                                </div>
                                <div *ngIf="category=== 'PHOTO_APPROVAL' && attachment.mimeType && !attachment.mimeType.startsWith('image')" class="ml-1">
                                    <span class="d-flex justify-content-center align-items-center" style="display: block;  width:30px;  height:30px; padding: 0rem;z-index:9999 ; border: none;"><i class="far fa-eye-slash"></i></span>
                                </div>-->
                                
                                <div class="d-inline ml-1"><a class="btn btn-sm"
                                    [href]="attachment.fileSignedUrl" *ngIf=" !createAction && canDownload" target="_blank"
                                    [download]="attachment.originalFileName"><i class="fas fa-download"></i></a>
                                </div>
                                <div class="d-inline ml-1" *ngIf="!editable && canQuickView && (attachment.mimeType?.startsWith('image') || attachment.originalFileName.toUpperCase().endsWith('.PDF'))">
                                    <a class="btn btn-sm" (click)="openAttachmentViewer(attachment)"><i class="fas fa-expand"></i></a>
                                </div>
                                <div *ngIf="editable && canDelete" class="d-inline ml-1">
                                    <span type="button" class="btn btn-sm"
                                        (click)="deleteFile(attachment.id,category)"><i
                                            class="fas fa-trash "></i></span>
                                </div>
                            
                                <div *ngIf="editable && !attachment.islocal && canRenameFile" class="d-inline ml-1">
                                    <span type="button" class="btn btn-sm"
                                        (click)="renameFile(attachment.id,category)"><i
                                            class="fas fa-pen"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 text-muted   {{viewCreatedBy?'d-flex flex-column':'d-flex align-items-center'}}" [style]="viewCreatedBy?'font-size: x-small':'font-size: small'">
                            <label class="mb-0">{{attachment.uploadDate | date:'medium'}}</label>
                            <span *ngIf="viewCreatedBy" style="color:lightslategray;font-style: italic;">{{attachment.createdBy}}</span>
                        </div>
        
                        <div class="col text-muted d-flex align-items-center" style="font-size: small;">
                            <span *ngIf="editable && !attachment.islocal && likeable && !attachment.liked" style="color:lightslategray;font-style: italic;margin-bottom: 0.5rem" (click)="liked(attachment)">
                                <i class="far fa-thumbs-up"
                                style="color: #6c757d24;" 
                                                onmouseover="this.style.color='tomato'" 
                                                onmouseout="this.style.color='#6c757d24'"></i></span>
                            <span *ngIf="likeable && editable && attachment.liked" style="margin-bottom: 0.5rem">
                            <i class="fas fa-thumbs-up" style="color: tomato;"  >
                            </i>
                        </span>
                            <!--<a (click)="openAttachmentViewer(attachment)" >--><label class="text-break ml-1">{{attachment.originalFileName}}</label>
                        </div>
                    </div>
                
                </ng-container>
            </div>
        </div>
        </ng-container>
    </div>
</div>




<!--Visuas Upload progress Starts   
   
    <div>
            <div class="progress form-group" *ngIf="SelectedImageToUpload">
                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="progress"></div>
            </div>
            <div *ngIf="SelectedImageToUpload">
                    <button type="button" class="btn btn-warning"  *ngIf="SelectedImageToUpload && fileUploading &&  progress !=100" disabled>
                            <span class="spinner-grow spinner-grow-sm"></span>Uploading
                            Stage 1 - {{SelectedImageToUpload.name}}
                    </button>
                    <button type="button" class="btn btn-warning" *ngIf="SelectedImageToUpload && !fileUploaded && progress ==100" disabled>
                            <span class="spinner-grow spinner-grow-sm"></span>Uploading
                            Stage 2 - {{SelectedImageToUpload.name}}
                    </button>
            </div>
    </div>
   Visuas Upload progress Ends-->