import { CostTypeDetail } from "../../costsheet/cost-type-detail.model";
import { BlankSizeDetails } from "./blank-size-details.model";

export class AllocatedBlankSupplierDetails {
	combinationId:string;
    supplierId:string;
	supplierName:string;
	blankCode:string;
	blankSizeDetails:BlankSizeDetails[]=[];
	costTypeDetails:CostTypeDetail[]=[];
	currency:string;
	blankStatus:string;
	allocatedQty:number;
	freightDetailsMatrix:BlankSupplierMatrixDetails[]=[];// Only used bu top allocated Blanksuppier list
	coo:string;
   constructor(combinationId:string){
	   this.combinationId=combinationId;
   }
}

export class BlankSupplierMatrixDetails{
	printerId:string;
	printerName:string;
	type:string;
	value:number;
}
