import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/shared/model/user.model';

import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/shared/service/customer.service';
import { Customer } from 'src/app/shared/model/customer.model';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { UserManagementService } from 'src/app/shared/service/user-management.service';
import { CustomerSalesPerson } from 'src/app/shared/model/customer-sales-person.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { CustomerDiscount } from 'src/app/shared/model/customer-discount.model';

@Component({
   selector: 'app-customer',
   templateUrl: './customer.component.html',
   styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {

   createAction: boolean = false;
   viewAction: boolean = false;
   editable: boolean = false;

   constructor(private authService:AuthService,private router: Router, private customerService: CustomerService, private metadataService: MetadataService,
      private activatedRoute: ActivatedRoute, private userService: UserManagementService,
      private sharedService: SharedService, private toastrService: ToastrService) { }

   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;
   selectedCustomerId: string;
   customer: Customer;
   territoryList: string[];
   customerTierList: string[];
   salesUserList: User[] = [];
   selectedSalesPerson: string = '';
   customerListForAssociation: Customer[] = [];
   selectedAssociatedCustomer: Customer ;
   productionUserList: User[] = [];
   customerDiscountTypeList: string[] = [];
   

   ngOnInit() {
      this.handleSubscriptions();
      this.loadTerritories();
      this.loadCustomerTierList();
      this.loadCustomerDiscountTypeList();
      this.customer = new Customer();
      this.getCustomer();
      this.getCustomerList();
      this.evaluateAction();
      window.scrollTo(0, 0);
   }

   handleSubscriptions() {
      // Subscriptions
      this.userAccess = new UserAccess();
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });

      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
         this.currentLoggedInUser = currentLoggedInUser;
      });
   }


   onSubmit() {
      this.setAssociationFlag();
      if (this.editable && !this.createAction) {
         this.updateCustomer();
      } else {
         this.createCustomer();
      }
   }
   setAssociationFlag() {
      if(this.customer.customerAssociationList && this.customer.customerAssociationList.length>0){
         this.customer.parent=true;
      }else{
         this.customer.parent=false;
      }
   }

   loadTerritories() {
      this.metadataService.getMetadataValueListByClassificationAndType('LICENSOR', 'TERRITORY').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.territoryList = this.sharedService.sort(res.responsePayload);
         }
      });
   }

   loadCustomerTierList(){
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('LICENSOR', 'ROYALTY', 'TIER_TYPE').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.customerTierList = this.sharedService.sort(res.responsePayload);
         }
      });
   }

   loadCustomerDiscountTypeList(){
      this.metadataService.getMetadataValueListByClassificationAndType('CUSTOMER', 'DISCOUNT_TYPE').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.customerDiscountTypeList = this.sharedService.sort(res.responsePayload);
         }
      });
   }

   getCustomer() {
      let customerId = this.activatedRoute.snapshot.paramMap.get('id');
      this.selectedCustomerId = customerId;
      if (customerId) {
         this.customerService.getCustomerById(customerId).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.customer = response.responsePayload;
               this.getProductionUserList();
            } else {
               this.toastrService.error(response.responseStatus.message);
            }
         });
      }
   }

   getCustomerList(){
      this.customerService.getCustomers().subscribe(response =>{
         if (response.responseStatus.status === 'SUCCESS') {
            this.customerListForAssociation = response.responsePayload;
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      });
   }

   createCustomer() {
      this.customerService.createCustomer(this.customer).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.router.navigate(['customer/list']);
         } else {
            console.error("Customer Component - create customer failed...", response.responseStatus.message);

            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }

   updateCustomer() {
      this.customerService.updateCustomer(this.customer).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.getCustomer();
            this.editable=false;
            this.viewAction = true;
            //this.router.navigate(['customer/list']);
         } else {
            console.error("Customer Component - update Customer failed...", response.responseStatus.message);
            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }


   evaluateAction() {
      const activatedRoutePath = this.activatedRoute.snapshot.routeConfig.path;
      if (this.activatedRoute.snapshot.paramMap.get('action') === "view") {
         this.viewAction = true;
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "create") {
         this.createAction = true;
         this.editable = true;
         this.getProductionUserList();
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "edit") {
         this.editable = true;
         this.createAction = false;
      }
   }

   goBack() {
      this.router.navigate(['customer/list']);
   }

   edit(customerId: string) {
      this.customerService.getCustomerById(customerId).subscribe(response => {
         this.customer = response.responsePayload;
      });
      this.createAction = false;
      this.viewAction = false;
      this.editable = true;
      this.getSalesUserList();
      
   }

   cancelEdit(){
      this.getCustomer();
      this.editable=false;
      this.viewAction = true;
   }


   /****** START RELEASE#3 CHANGES *********/
   getSalesUserList() {
      this.userService.getUsersByRoleId('SALES_USER').subscribe(response => {
         this.salesUserList = response.responsePayload;
      });
   }

   getSalesUser(userId: string): any {
      let salesUser: User = new User;
      this.salesUserList.forEach((item, index) => {
         if (item.id === userId) {
            salesUser = item;
         }
      });
      return salesUser;
   }
   getSalesUserByUserName(userName: string): any {
      let salesUser: User = new User;
      this.salesUserList.forEach((item, index) => {
         if (item.userName === userName) {
            salesUser = item;
         }
      });
      return salesUser;
   }
   getSalesUserNameByUserName(userName: string): any {
      let salesUser: User = new User;
      this.salesUserList.forEach((item, index) => {
         if (item.userName === userName) {
            salesUser = item;
         }
      });
      return salesUser.firstName+ ' '+salesUser.lastName ;
   }

   addSalesPerson() {
      //
      let customerSalesPersonToAdd = new CustomerSalesPerson;
      customerSalesPersonToAdd.userId = this.selectedSalesPerson;
      customerSalesPersonToAdd.parentId = this.customer.id;
      //
      let salesUser: User = this.getSalesUser(this.selectedSalesPerson);
      customerSalesPersonToAdd.salesPerson = salesUser;
      //

      if (this.customer.customerSalesPersonList === undefined || this.customer.customerSalesPersonList === null) {
         this.customer.customerSalesPersonList = [];
      }

      if (!this.checkIfSalesPersonExists(this.customer.customerSalesPersonList, customerSalesPersonToAdd)) {
         this.customer.customerSalesPersonList.push(customerSalesPersonToAdd);
      }else{
         this.toastrService.info('Sales Rep already associated with the customer');
      }
      this.selectedSalesPerson = "";
   }

   addSharedSalesPerson(customerSalesPerson:CustomerSalesPerson) {
      if(!customerSalesPerson.sharedSalesRepUserNameList){
         customerSalesPerson.sharedSalesRepUserNameList=[];
      }
      /*if(!customerSalesPerson.sharedSalesReps){
         customerSalesPerson.sharedSalesReps=[];
      }*/
      customerSalesPerson.sharedSalesRepUserNameList.push(customerSalesPerson.selectedSharedSalesPersonUsername);
      //customerSalesPerson.sharedSalesReps.push(this.getSalesUserByUserName(customerSalesPerson.selectedSharedSalesPersonUsername));
      customerSalesPerson.selectedSharedSalesPersonUsername=undefined;
      /*//
      let customerSalesPersonToAdd = new CustomerSalesPerson;
      customerSalesPersonToAdd.userId = this.selectedSalesPerson;
      customerSalesPersonToAdd.parentId = this.customer.id;
      //
      let salesUser: User = this.getSalesUser(this.selectedSalesPerson);
      customerSalesPersonToAdd.salesPerson = salesUser;
      //

      if (this.customer.customerSalesPersonList === undefined || this.customer.customerSalesPersonList === null) {
         this.customer.customerSalesPersonList = [];
      }

      if (!this.checkIfSalesPersonExists(this.customer.customerSalesPersonList, customerSalesPersonToAdd)) {
         this.customer.customerSalesPersonList.push(customerSalesPersonToAdd);
      }else{
         this.toastrService.info('Sales Rep already associated with the customer');
      }
      this.selectedSalesPerson = "";*/
   }

   removeSharedSalesPerson(customerSalesPerson:CustomerSalesPerson,userNameToRemove:string){
      if(customerSalesPerson.sharedSalesRepUserNameList){
         customerSalesPerson.sharedSalesRepUserNameList=customerSalesPerson.sharedSalesRepUserNameList.filter(x=>x!=userNameToRemove);
      }
      /*if(customerSalesPerson.sharedSalesReps){
         customerSalesPerson.sharedSalesReps=customerSalesPerson.sharedSalesReps.filter(x=>x.userName!=userNameToRemove);
      }*/
   }


   checkIfSalesPersonExists(list: CustomerSalesPerson[], item: CustomerSalesPerson) {
      if (list) {
         let temp = list.filter(itemInList => itemInList.userId === item.userId);
         return (temp.length == 1);
      } else {
         return false;
      }
   }

   removeSalesUser(customerSalesPerson: CustomerSalesPerson) {
      customerSalesPerson.deleteFlag = true;
      //this.customer.customerSalesPersonList = this.customer.customerSalesPersonList.filter(item => item !=customerSalesPerson);
   }

   /****** END RELEASE#3 CHANGES *********/

   /******* START: Customer Association Changes ****************************/
   
   removeCustomerAssociation(removedAssociatedCustomer: Customer) {
      this.customerListForAssociation.forEach(element => {
         if (element.id === removedAssociatedCustomer.id) {
            element.parentCustomerId = null;
         }
      });

      if (this.customer.customerAssociationList) {
         this.customer.customerAssociationList.forEach(element => {
            if (element.id === removedAssociatedCustomer.id) {
               element.parentCustomerId = null
            }
         });
      }
   }

   addCustomerAssociation() {
      if (this.customer.customerAssociationList === undefined || this.customer.customerAssociationList === null) {
         this.customer.customerAssociationList = [];
      }

      this.customerListForAssociation.forEach(element => {
         if (element.id === this.selectedAssociatedCustomer.id) {
            element.parentCustomerId = this.customer.id;
         }
      });

      if (!this.checkIfChildCustomerAssociationExists(this.customer.customerAssociationList, this.selectedAssociatedCustomer)) {
         this.selectedAssociatedCustomer.parentCustomerId = this.customer.id;
         this.customer.customerAssociationList.push(this.selectedAssociatedCustomer);
      } else {
         this.customer.customerAssociationList.forEach(element => {
            if (element.id === this.selectedAssociatedCustomer.id) {
               element.parentCustomerId = this.customer.id;
            }
         });
      }
      this.selectedAssociatedCustomer = null;
   }

   checkIfChildCustomerAssociationExists(list: Customer[], item: Customer) {
      if (list) {
         let temp = list.filter(itemInList => itemInList.id === item.id);
         return (temp.length == 1);
      } else {
         return false;
      }
   }

   /******* END: Customer Association Changes ****************************/


   /******* START: Customer Production Coordinator Changes ****************************/

   getProductionUserList() {
      this.userService.getUsersByRoleId('PRODUCTION_USER').subscribe(response => {
         this.productionUserList = response.responsePayload;
      });
   }

   /******* End: Customer Production Coordinator Changes ****************************/

   /******* START: Customer Discount Changes ****************************/
   selectedDiscountTypeToAdd: string;
   addCustomerDiscount() {
      if (!this.checkIfDiscountExists(this.selectedDiscountTypeToAdd)) {
         let customerDiscount: CustomerDiscount = new CustomerDiscount;
         customerDiscount.customerId = this.customer.id;
         customerDiscount.discountType = this.selectedDiscountTypeToAdd;
         if(this.customer.customerDiscountList === null || this.customer.customerDiscountList === undefined){
            this.customer.customerDiscountList = [];
         }
         this.customer.customerDiscountList.push(customerDiscount);
      }
      this.selectedDiscountTypeToAdd = undefined;
   }

   deleteCustomerDiscount(customerDiscount: CustomerDiscount) {
      customerDiscount.deleteFlag = true;
      this.calculateTotalCustomerDiscount();
   }

   onDiscountPercentChange() {
      this.calculateTotalCustomerDiscount();
   }

   calculateTotalCustomerDiscount() {
      if (this.customer.customerDiscountList !== null && this.customer.customerDiscountList !== undefined) {
         let totalDiscount: number = 0;
         for (let index = 0; index < this.customer.customerDiscountList.length; index++) {
            let customerDiscount: CustomerDiscount = this.customer.customerDiscountList[index];
            if (!customerDiscount.deleteFlag) {
               totalDiscount = totalDiscount + customerDiscount.discountPercentage;
            }
         }
         this.customer.totalDiscount = totalDiscount;
      }
   }

   checkIfDiscountExists(selectedDiscountType: string) {
      if (this.customer && this.customer.customerDiscountList) {
         for (let index = 0; index < this.customer.customerDiscountList.length; index++) {
            let customerDiscount: CustomerDiscount = this.customer.customerDiscountList[index];
            if (!customerDiscount.deleteFlag) {
               if (customerDiscount.discountType === selectedDiscountType) {
                  this.toastrService.info('[' + selectedDiscountType + '] already exists.');
                  return true;
               }
            }
         }
      }
      return false;
   }
   /******* END: Customer Discount Changes ****************************/

}


