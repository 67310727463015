<div class="row mt-2 d-flex justify-content-center">
   <h4 class="d-inline">Brand Rights Overview</h4>
</div>
<hr>

<form #brandRightsForm="ngForm" autocomplete="off" novalidate>
   <div class="row mt-2 mb-2">
      <div class="col-sm-1" *ngIf="userAccess?.canCreateBrandRights">
         <a (click)="addNewBrandRights()" class="btn btn-info btn-sm text-white"><i class="fas fa-plus fa-1x"></i>Add</a>
      </div>
      <div class="col-sm-6">
         <label class="col-sm-2 text-right">Sort By:</label>
         <select class="form-control form-control-sm custom-select col-sm-3 mr-1" id="sortBy" name="sortBy" [(ngModel)]="selectedSortByName" (change)="onSortColumnChange($event.target.value)">
            <option *ngFor="let sortColumn of sortColumns" [ngValue]="sortColumn.sortByName">
               {{sortColumn.columnName}}</option>
         </select>
         <span (click)="sortAsc()" *ngIf="selectedSortByName && sortMode==='asc'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1" style="color:black; vertical-align: middle;"></i></span>
         <span (click)="sortDesc()" *ngIf="selectedSortByName && sortMode==='asc'"><i class="fas fa-sort-amount-down fa-lg mr-1" style="color:grey; vertical-align: middle;"></i></span>
         <span (click)="sortAsc()" *ngIf="selectedSortByName && sortMode==='desc'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1" style="color:grey; vertical-align: middle;"></i></span>
         <span (click)="sortDesc()" *ngIf="selectedSortByName && sortMode==='desc'"><i class="fas fa-sort-amount-down fa-lg" style="color:black; vertical-align: middle;"></i></span>

      </div>
      <div class="col-sm-5">
         <div class="row">
            <span class="col-sm-2 text-right align-middle" style="align-self: center;">Filter:</span>
            <input type="search" class="form-control col-sm-8" #search placeholder="Filter by Licensor Name, Brand Name etc .." (keyup)="onKeyupFilter($event,search.value)">
         </div>
      </div>
   </div>

   <table class="table table-sm table-striped table-bordered table-condensed">
      <thead class="text-white bg-secondary">

         <tr>
            <th class="align-middle text-center text-nowrap" style="width:1%"></th>
            <th class="align-middle text-center text-nowrap" style="width:7%">Licensor Name</th>
            <th class="align-middle text-center text-nowrap" style="width:7%">Brand Name</th>
            <th class="align-middle text-center text-nowrap" style="width:7%">Contract Start Date</th>
            <th class="align-middle text-center text-nowrap" style="width:7%">Contract End Date</th>
            <th class="align-middle text-center text-nowrap" style="width:7%">Sell Off Date</th>
            <th class="align-middle text-center text-nowrap" style="width:10%">Territory</th>
            <th class="align-middle text-center text-nowrap" style="width:10%">Product Category</th>
            <th class="align-middle text-center text-nowrap" style="width:10%">Age Category</th>
            <th class="align-middle text-center text-nowrap" style="width:15%">Customers</th>
            <th class="align-middle text-center text-nowrap" style="width:18%">Notes</th>
         </tr>

      </thead>

      <tbody>
         <ng-container *ngFor="let brandRights of filteredBrandRightsList; index as brandRightsIndex">
            <!-- <div class="text-nowrap" *ngIf="brandRights.hasError==='true'" style="width:50px;background-color: #ff6e6e;">
                  Same Record Exisits.
            </div> -->
            <tr [ngModelGroup]="brandRightsIndex" #brandRightsRowGroup="ngModelGroup" [style]="brandRights.hasError?'background-color: rgb(253 180 180);':''">
               <td style="white-space: nowrap;width: 1%;">
                  <div class="d-flex flex-column">
                     <button *ngIf="!brandRights.editable && userAccess?.canEditBrandRights" type="button" id="save_{{brandRightsIndex}}" (click)="editBrandRights(brandRights)"
                        class="mb-1 btn btn-sm btn-warning"><i class="fas fa-pencil-alt"></i></button>
                     <button *ngIf="brandRights.editable" [disabled]="!brandRightsRowGroup.valid || brandRights.productCategoryList.length === 0  || brandRights.ageCategoryList.length === 0" type="button"
                        (click)="saveBrandRights(brandRights,brandRightsIndex)" class="mb-1 btn btn-sm btn-info"><i class="fas fa-save" style="color:white"></i></button>
                     <button *ngIf="brandRights.editable && brandRights.id && userAccess?.canDeleteBrandRights" type="button" (click)="deleteBrandRights(brandRights,brandRightsIndex)"
                        class="mb-1 btn btn-sm btn-danger"><i class="fas fa-trash"></i></button>
                     <button *ngIf="brandRights.editable" type="button" (click)="cancelBrandRights(brandRights,brandRightsIndex)" class=" btn btn-dark  btn-sm mb-1">
                        <i class="fas fa-times"></i></button>
                     <button *ngIf="!brandRights.editable" type="button" (click)="cloneBrandRights(brandRights,brandRightsIndex)" class=" btn btn-dark  btn-sm">
                        <i class="fas fa-copy"></i></button>
                  </div>
               </td>

               <td *ngIf="!brandRights.editable">
                  {{brandRights.licensorName}}
               </td>
               <td *ngIf="brandRights.editable">
                  <select class="form-control form-control-sm custom-select" [(ngModel)]="brandRights.licensorId" id="licensor_{{brandRightsIndex}}" name="licensor_{{brandRightsIndex}}"
                     #licensorVar="ngModel" (change)="onLicensorChange($event,brandRights)" required [ngClass]="{'is-invalid':licensorVar.touched && licensorVar.errors}">
                     <option *ngFor="let licensor of licensorList" [ngValue]="licensor.id">
                        {{licensor.licensorName}}</option>
                  </select>
                  <div *ngIf="licensorVar?.invalid && (licensorVar.dirty || licensorVar.touched)" class="invalid-feedback">
                     <div *ngIf="licensorVar.errors.required">
                        Licensor is required.
                     </div>
                  </div>
               </td>

               <td *ngIf="!brandRights.editable">
                  {{brandRights.brandName}}
               </td>
               <td *ngIf="brandRights.editable">
                  <select class="form-control form-control-sm custom-select" [(ngModel)]="brandRights.brandId" id="brand_{{brandRightsIndex}}" name="brand_{{brandRightsIndex}}"
                     [disabled]="!brandRights.licensorId" (change)="onBrandChange($event)" #brandVar="ngModel" required [ngClass]="{'is-invalid':brandVar.touched && brandVar.errors}">
                     <option *ngFor="let brand of brandRights.brandList" [ngValue]="brand.id">
                        {{brand.brandName}}</option>
                  </select>
                  <div *ngIf="brandVar?.invalid && (brandVar.dirty || brandVar.touched)" class="invalid-feedback">
                     <div *ngIf="brandVar.errors.required">
                        Brand is required.
                     </div>
                  </div>
               </td>

               <td *ngIf="!brandRights.editable">
                  {{brandRights.contractStartDate | date:'mediumDate'}}
               </td>
               <td *ngIf="brandRights.editable">
                  <div class="d-flex d-inline">
                     <input matInput [matDatepicker]="picker" name="contractStartDate_{{brandRightsIndex}}" id="contractStartDate_{{brandRightsIndex}}" [(ngModel)]="brandRights.contractStartDate"
                        [disabled]="!brandRights.editable" class="form-control  form-control-sm" placeholder="Start Date" #contractStartDateVar="ngModel" required [max]="brandRights.contractEndDate"
                        [ngClass]="{'is-invalid':contractStartDateVar.touched && contractStartDateVar.errors}">
                     <mat-datepicker #picker></mat-datepicker>
                     <button class="btn btn-sm" (click)="picker.open()"><i class="fas fa-calendar-alt"></i></button>
                  </div>
                  <!--{{brandRightsForm.form.controls[brandRightsIndex].value | json}} - {{contractStartDateVar.value | json}} -->
                  <!-- [max]="brandRights.contractEndDate"  -->
                  <!--(dateInput)="onStartDateInput(brandRightsForm.form,brandRightsIndex,$event)" -->
                  <mat-error *ngIf="contractStartDateVar?.errors?.required && (contractStartDateVar.dirty || contractStartDateVar.touched)" style="font-size: 80%;color: #dc3545">
                     Contract Start Date is required.
                  </mat-error>
                  <mat-error *ngIf="contractStartDateVar?.errors?.startDateError" style="font-size: 80%;color: #dc3545">
                     Start Date Error From TS Component.
                  </mat-error>
                  <div *ngIf="contractStartDateVar?.invalid && (contractStartDateVar.dirty || contractStartDateVar.touched)" class="invalid-feedback">
                     <div *ngIf="contractStartDateVar.errors.required">Contract Start Date is required.</div>
                  </div>
               </td>

               <td *ngIf="!brandRights.editable">
                  {{brandRights.contractEndDate | date:'mediumDate'}}
               </td>
               <td *ngIf="brandRights.editable">
                  <div class="d-flex d-inline">
                     <input matInput [matDatepicker]="picker" name="contractEndDate_{{brandRightsIndex}}" id="contractEndDate_{{brandRightsIndex}}" [(ngModel)]="brandRights.contractEndDate"
                        [min]="brandRights.contractStartDate" [disabled]="!brandRights.editable" class="form-control  form-control-sm" placeholder="End Date" #contractEndDateVar="ngModel" required
                        [ngClass]="{'is-invalid':contractEndDateVar.touched && contractEndDateVar.errors}">
                     <mat-datepicker #picker></mat-datepicker>
                     <button class="btn btn-sm" (click)="picker.open()"><i class="fas fa-calendar-alt"></i></button>
                  </div>
                  <!-- [min]="brandRights.contractStartDate" -->
                  <mat-error *ngIf="contractEndDateVar?.errors?.required && (contractEndDateVar.dirty || contractEndDateVar.touched)" style="font-size: 80%;color: #dc3545">
                     Contract End Date is required.
                  </mat-error>

                  <div *ngIf="contractEndDateVar?.invalid && (contractEndDateVar.dirty || contractEndDateVar.touched)" class="invalid-feedback">
                     <div *ngIf="contractEndDateVar.errors.required">Contract End Date is required.</div>
                  </div>
               </td>

               <td *ngIf="!brandRights.editable">
                  {{brandRights.sellOffDate  | date:'mediumDate'}}
               </td>
               <td *ngIf="brandRights.editable">
                  <div class="d-flex d-inline">
                     <input matInput [matDatepicker]="sellOffDatePicker" name="sellOffDate_{{brandRightsIndex}}" id="sellOffDate_{{brandRightsIndex}}" [(ngModel)]="brandRights.sellOffDate"
                        class="form-control  form-control-sm" placeholder="Sell Off Date" #sellOffDateVar="ngModel" [ngClass]="{'is-invalid':sellOffDateVar.touched && sellOffDateVar.errors}">
                     <mat-datepicker #sellOffDatePicker></mat-datepicker>
                     <button class="btn btn-sm" (click)="sellOffDatePicker.open()"><i class="fas fa-calendar-alt"></i></button>
                  </div>
                  <div *ngIf="sellOffDateVar?.invalid && (sellOffDateVar.dirty || sellOffDateVar.touched)" class="invalid-feedback">
                     <div *ngIf="sellOffDateVar.errors.required">Sell Off Date is required.</div>
                  </div>
                  <!--
                  <input type="date" name="sellOffDate_{{brandRightsIndex}}" id="sellOffDate_{{brandRightsIndex}}" 
                     [(ngModel)]="brandRights.sellOffDate" [disabled]="!brandRights.editable" useValueAsDate
                     class="form-control  form-control-sm" placeholder="Sell Off Date" #sellOffDateVar="ngModel" 
                     required [ngClass]="{'is-invalid':sellOffDateVar.touched && sellOffDateVar.errors}">
                  <div *ngIf="sellOffDateVar?.invalid && (sellOffDateVar.dirty || sellOffDateVar.touched)" class="invalid-feedback">
                     <div *ngIf="sellOffDateVar.errors.required">Sell Off Date is required.</div>
                  </div>
                  -->
               </td>

               <td *ngIf="!brandRights.editable">
                  {{brandRights.territory}}
               </td>
               <td *ngIf="brandRights.editable">
                  <select class="form-control form-control-sm custom-select" [(ngModel)]="brandRights.territory" name="territory_{{brandRightsIndex}}" #territoryVar="ngModel"
                     [disabled]="!brandRights.editable" (change)="onTerritoryChange(brandRights.territory, brandRights)" required
                     [ngClass]="{'is-invalid':territoryVar.touched && territoryVar.errors}">

                     <option *ngFor="let territory of territoryList" [ngValue]="territory">
                        {{territory}}</option>
                  </select>
                  <div *ngIf="territoryVar?.invalid && (territoryVar.dirty || territoryVar.touched)" class="invalid-feedback">
                     <div *ngIf="territoryVar?.errors.required">
                        Territory is required.
                     </div>
                  </div>
               </td>

               <td *ngIf="!brandRights.editable">
               <!--
                  <ng-container *ngFor="let productCategory of brandRights.productCategoryList; index as productCategoryIndex">
                     &#8226;{{productCategory}}
                  </ng-container>
               -->
                  <div *ngFor="let productCategory of brandRights.productCategoryList; index as prodCatIndex">
                     <div class="d-inline custom-control custom-checkbox mr-sm-2">
                        <input type="checkbox" class="custom-control-input " name="productCategory_{{brandRightsIndex}}_{{prodCatIndex}}" id="productCategory_{{brandRightsIndex}}_{{prodCatIndex}}" 
                        [checked]="true" [disabled]="true">
                        <label class="custom-control-label text-break pt-1" style="color:#212529" for="productCategory_{{brandRightsIndex}}_{{prodCatIndex}}">{{productCategory}}</label>
                     </div>
                  </div>
               </td>

               <td *ngIf="brandRights.editable">
                  <button *ngIf="brandRights.editable" type="button" class="mt-1 btn btn-sm color-3 btn-block" style="line-height:0.25rem" (click)="onProductCategoryAddOrEdit(brandRights)">
                     <i class="fas fa-plus fa-xs"></i>
                  </button>
                  <div *ngFor="let productCategory of brandRights.productCategoryList; index as productCategoryIndex">
                     <div class="d-inline custom-control custom-checkbox mr-sm-2">
                        <input type="checkbox" class="custom-control-input " id="productCategory_{{brandRightsIndex}}_{{productCategoryIndex}}" [checked]="true"
                           (click)="onProductCategorySelection($event,productCategory,brandRights)" [disabled]="!brandRights.editable">
                        <label class="custom-control-label text-break" for="productCategory_{{brandRightsIndex}}_{{productCategoryIndex}}">{{productCategory}}</label>
                     </div>
                  </div>
                  <div *ngIf="brandRights.productCategoryList.length === 0" style="color:#DC3545; font-size: 80%;">
                     <div>Select Product Categories.</div>
                  </div>
               </td>

               <td *ngIf="!brandRights.editable">
               <!--   
                  <ng-container *ngFor="let ageCategory of brandRights.ageCategoryList;index as ageCategoryIndex">
                     &#8226;{{ageCategory}}
                  </ng-container>
               -->
                  <div *ngFor="let ageCategory of brandRights.ageCategoryList;index as ageCategoryIndex">
                     <div class="custom-control custom-checkbox mr-sm-2">
                        <input type="checkbox" class="custom-control-input " id="ageCategory_{{brandRightsIndex}}_{{ageCategoryIndex}}" [checked]="true"
                           [disabled]="true">
                        <label class="custom-control-label text-break pt-1" style="color:#212529" for="ageCategory_{{brandRightsIndex}}_{{ageCategoryIndex}}">{{ageCategory}}</label>
                     </div>
                  </div>
               </td>
               <td *ngIf="brandRights.editable">
                  <button type="button" class="mt-1 btn btn-sm color-3 btn-block" style="line-height:0.25rem" (click)="onAgeCategoryAddOrEdit(brandRights)">
                     <i class="fas fa-plus fa-xs"></i>
                  </button>
                  <div *ngFor="let ageCategory of brandRights.ageCategoryList;index as ageCategoryIndex">
                     <div class="custom-control custom-checkbox mr-sm-2">
                        <input type="checkbox" class="custom-control-input " id="ageCategory_{{brandRightsIndex}}_{{ageCategoryIndex}}" [checked]="true"
                           (click)="onAgeCategorySelection($event,ageCategory,brandRights)" [disabled]="!brandRights.editable">
                        <label class="custom-control-label text-break" for="ageCategory_{{brandRightsIndex}}_{{ageCategoryIndex}}">{{ageCategory}}</label>
                     </div>
                  </div>
                  <div *ngIf="brandRights?.ageCategoryList?.length === 0" style="color:#DC3545; font-size: 80%;">
                     <div>Select Age Categories.</div>
                  </div>
               </td>

               <td *ngIf="!brandRights.editable">
                  <span *ngIf="brandRights.allCustomersPermitted">
                     All Permitted {{brandRights.exceptionCustomerList.length !==0? ' except below:' : ''}}
                  </span>
                  <span *ngIf="brandRights.allCustomersRestricted" class="btn-danger">
                     All Restricted {{brandRights.exceptionCustomerList.length !==0? ' except below:' : ''}}
                  </span>
                  <br>
                  <ng-container *ngFor="let exceptionCustomer of brandRights.exceptionCustomerList; index as exceptionCustomerIndex">
                     &#8226;&nbsp;{{exceptionCustomer.customerName}}
                  </ng-container>
               </td>
               <td *ngIf="brandRights.editable">
                  <button [disabled]="!brandRights.territory" type="button" class="mt-1 btn btn-sm color-3 btn-block" style="line-height:0.25rem" (click)="onCustomerAddOrEdit(brandRights)">
                     <i class="fas fa-plus fa-xs"></i>
                  </button>
                  <span *ngIf="brandRights.allCustomersPermitted">
                     All Permitted {{brandRights.exceptionCustomerList.length !==0? ' except below:' : ''}}
                  </span>
                  <span *ngIf="brandRights.allCustomersRestricted" class="btn-danger">
                     All Restricted {{brandRights.exceptionCustomerList.length !==0? ' except below:' : ''}}
                  </span>
                  <br>
                  <div *ngFor="let exceptionCustomer of brandRights.exceptionCustomerList; index as exceptionCustomerIndex">
                     <div class="custom-control custom-checkbox mr-sm-2">
                        <input type="checkbox" class="custom-control-input" id="exceptionCustomer_{{brandRightsIndex}}_{{exceptionCustomerIndex}}" [checked]="true" [disabled]="!brandRights.territory"
                           (change)="onCustomerSelection($event,exceptionCustomer, brandRights)">
                        <label class="custom-control-label text-break" for="exceptionCustomer_{{brandRightsIndex}}_{{exceptionCustomerIndex}}">{{exceptionCustomer.customerName}}</label>
                     </div>
                  </div>
                  <div *ngIf="!brandRights.territory" style="color:#DC3545; font-size: 80%;">
                     <div>Select Territory.</div>
                  </div>
               </td>

               <td *ngIf="!brandRights.editable" style="white-space: pre-wrap;">
                  {{brandRights.notes}}
               </td>
               <td *ngIf="brandRights.editable">
                  <textarea name="notes_{{brandRightsIndex}}" id="notes_{{brandRightsIndex}}" rows="{{(brandRights?.notes?.length / 25)>2?(brandRights?.notes?.length / 25):2}}"
                     [(ngModel)]="brandRights.notes" class="form-control form-control-sm" #notesVar="ngModel" [disabled]="!brandRights.editable" [ngClass]="{'is-invalid':notesVar.touched && notesVar.errors}"
                     style="white-space: pre-wrap;" maxlength="250"></textarea>
                  <div *ngIf="notesVar?.invalid && (notesVar.dirty || notesVar.touched)" class="invalid-feedback">
                     <div *ngIf="notesVar.errors.required">
                        Notes is required.
                     </div>
                  </div>
               </td>
            </tr>
         </ng-container>
      </tbody>

      <tbody *ngIf="filteredBrandRightsList?.length===0 || filteredBrandRightsList ==null">
         <tr>
            <td colspan="11">
               <p class="text-muted text-center"><em>Brand Rights Not Available</em></p>
            </td>
         </tr>
      </tbody>

   </table>

</form>



<!-- Product Category Selection Modal -->
<div class="modal fade" id="ProductCategorySelectionModal" tabindex="-1" role="dialog" aria-labelledby="ProductCategorySelectionModalTitle" aria-hidden="true">
   <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="ProductCategorySelectionLongTitle">Product Category</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <div class="row mt-2 mb-2 border-bottom">
               <div class="mr-4 col-sm-3">
                  <input type="radio" id="selectAllProductCategory" [(ngModel)]="selectUnSelectAllRadio" style="transform: scale(1.75);" name="productCategoryRadio" value="true"
                  class="mr-2" (click)="onSelectOrUnselectAll('true','ProductCategory')">
                  <label for="selectAllCategory"><span></span>&nbsp;Select All</label>
               </div>
               <div>
                  <input type="radio" id="unselectAllProductCategory" [(ngModel)]="selectUnSelectAllRadio" style="transform: scale(1.75);" name="productCategoryRadio" value="false"
                  class="mr-2" (click)="onSelectOrUnselectAll('false','ProductCategory')">
                  <label for="unselectAllCategory"><span></span>&nbsp;Unselect All</label>
               </div>
            </div>
            <div class="ml-2" *ngFor="let item of productCategoryList">
               <div class="row">
                  <label class="cb-container">
                     <h6 class="ml-4 mt-1">{{item}}</h6>
                     <input type="checkbox" [checked]="isItemSelected(item,'ProductCategory')" (change)="onProductCategorySelection($event,item, null)">
                     <span class="checkmark"></span>
                  </label>
               </div>
            </div>
         </div>
         <div class="modal-footer">
            <div class="m-1 d-flex">
               <button class="btn bg-dark text-white btn-block" data-dismiss="modal">Ok</button>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- Age Category Selection Modal -->
<div class="modal fade" id="AgeCategorySelectionModal" tabindex="-1" role="dialog" aria-labelledby="AgeCategorySelectionModalTitle" aria-hidden="true">
   <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="AgeCategorySelectionLongTitle">Age Category</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <div class="row mt-2 mb-2 border-bottom">
               <div class="mr-4 col-sm-3">
                  <input type="radio" id="selectAllAgeCategory" [(ngModel)]="selectUnSelectAllRadio" style="transform: scale(1.75);" 
                  class="mr-2" name="ageCategoryRadio" value="true" (click)="onSelectOrUnselectAll('true','AgeCategory')">
                  <label for="selectAllCategory"><span></span>&nbsp;Select All</label>
               </div>
               <div>
                  <input type="radio" id="unselectAllAgeCategory" [(ngModel)]="selectUnSelectAllRadio" style="transform: scale(1.75);" 
                  class="mr-2" name="ageCategoryRadio" value="false" (click)="onSelectOrUnselectAll('false','AgeCategory')">
                  <label for="unselectAllCategory"><span></span>&nbsp;Unselect All</label>
               </div>
            </div>

            <div class="ml-2" *ngFor="let item of ageCategoryList">
               <div class="row">
                  <label class="cb-container">
                     <h6 class="ml-4 mt-1">{{item}}</h6>
                     <input type="checkbox" [checked]="isItemSelected(item,'AgeCategory')" (change)="onAgeCategorySelection($event,item, null)">
                     <span class="checkmark"></span>
                  </label>
               </div>
            </div>
         </div>
         <div class="modal-footer">
            <div class="m-1 d-flex">
               <button class="btn bg-dark text-white btn-block" data-dismiss="modal">Ok</button>
            </div>
         </div>
      </div>
   </div>
</div>

<div class="modal fade" id="CustomerSelectionModal" tabindex="-1" role="dialog" aria-labelledby="CustomerSelectionModalModalTitle" aria-hidden="true">
   <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="RightsDetailsColumnFilterModalLongTitle">Select Customers</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">

            <table class="table table-sm table-bordered table-condensed">
               <thead class="text-white bg-dark">
                  <tr>
                     <th>
                        <div class="form-check">
                           <input type="radio" id="customerRadio" name="customerRadio" value="true" style="transform: scale(1.75);" 
                              (click)="onCustomerStatusRadioSelection('allpermitted')"
                              [checked]="selectedBrandRightsObject?.allCustomersPermitted">
                           <label for="customerPermitted" class="form-check-label mt-2 mb-2">&nbsp;&nbsp;All Permitted</label>
                        </div>
                     </th>

                     <th>
                        <div class="form-check">
                           <input type="radio" id="customerRadio" name="customerRadio" value="false" style="transform: scale(1.75);" 
                              (click)="onCustomerStatusRadioSelection('allrestricted')"
                              [checked]="selectedBrandRightsObject?.allCustomersRestricted">
                           <label for="customerRestricted" class="form-check-label mt-2 mb-2"><span></span>&nbsp;&nbsp;All Restricted</label>
                        </div>
                     </th>
                  </tr>
                  <tr>
                     <th colspan="2" class="bg-secondary">All Customers {{selectedBrandRightsObject?.allCustomersPermitted ?'Permitted':'Restricted'}} except below:
                     </th>
                  </tr>
               </thead>
               <tbody>
                  <tr *ngFor="let customer of customerList;let i = index">
                     <td colspan="2">
                        <label class="cb-container">
                           <h6 class="ml-4 mt-1">{{customer.customerName}}</h6>
                           <input type="checkbox" [checked]="isCustomerSelected(customer)" (change)="onCustomerSelection($event,customer, null)">
                           <span class="checkmark"></span>
                        </label>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
         <div class="modal-footer">
            <div class="m-1 d-flex">
               <button class="btn bg-dark text-white btn-block" data-dismiss="modal">Ok</button>
            </div>
         </div>
      </div>
   </div>
</div>


<button id="addOrEditRightsDetailsModalToggler" type="button" style="display: none;" data-toggle="modal" data-target="#addOrEditRightsDetailsModal"></button>
<button id="ageCategoryModalToggler" type="button" style="display: none;" data-toggle="modal" data-target="#AgeCategorySelectionModal"></button>
<button id="productCategoryModalToggler" type="button" style="display: none;" data-toggle="modal" data-target="#ProductCategorySelectionModal"></button>
<button id="customerSelectionModalToggler" type="button" style="display: none;" data-toggle="modal" data-target="#CustomerSelectionModal"></button>


<div class="floatBtn d-flex flex-column">
<!--   
   <button type="button" class=" btn btn-circle bg-info mb-2" [disabled]="!brandRightsForm.valid" *ngIf="editMode" (click)="saveBrandRightsList()" matTooltip="Save Changes" matTooltipPosition="left"
      matTooltipClass="bg-dark">
      <i class="fas fa-save fa-2x" style="color:white"></i>
   </button>
   <button type="button" class=" btn btn-circle bg-dark mb-2" *ngIf="editMode" matTooltip="Cancel Changes" matTooltipPosition="left" matTooltipClass="bg-dark" (click)="cancelEditMode()"><i
         class="fas fa-times fa-2x" style="color:white"></i>
   </button>
-->
   <a (click)="goBack()" class=" btn btn-circle" style="background-color: tomato;" matTooltip="Exit" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-arrow-left  fa-2x"></i></a>
</div>