import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from 'src/app/shared/service/dashboard.service';
import { DashletDetails } from 'src/app/shared/model/dashoard/dashlet-details.model';
import { Router } from '@angular/router';

@Component({
   selector: 'app-dashlet-table',
   templateUrl: './dashlet-table.component.html',
   styleUrls: ['./dashlet-table.component.css']
})
export class DashletTableComponent implements OnInit {

   constructor(private dashboardService: DashboardService, private router: Router) { }

   @Input() dashletId: string;
   @Input() dashletType: string;
   dashletDetails: DashletDetails;

   ngOnInit(): void {
      this.loadDashlet();
   }

   ngOnDestroy() {
   }

   loadDashlet() {
      this.dashboardService.getDashlet(this.dashletId, this.dashletType).subscribe((res) => {
         this.dashletDetails = res as DashletDetails;
      });
   }

   handleClick(dashletDetails: DashletDetails) {
      if (dashletDetails && dashletDetails.landingPath) {
         this.router.navigate([dashletDetails.landingPath]);
      }
   }

}
