import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SearchComponent } from 'src/app/common/search/search.component';
import { SearchDetails } from 'src/app/shared/model/common/search-details.model';
import { FilterConfig, SearchFilter } from 'src/app/shared/model/common/search-filter.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { SearchService } from 'src/app/shared/service/search.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared/service/shared.service';
import { SearchCriteriaBuilder } from 'src/app/shared/model/common/search-criteria-builder.model';
import { ChargeBack } from 'src/app/shared/model/accounts/charge-back.model';
import { ChargeBackService } from 'src/app/shared/service/accounts/charge-back.service';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { Router, NavigationExtras } from '@angular/router';
import { ProductAttachment } from 'src/app/shared/model/product.model';
import { FileHandlingService } from 'src/app/shared/service/file-handling.service';
import { SalesService } from 'src/app/shared/service/sales/sales.service';
import { ComposeEmail } from 'src/app/shared/model/compose-email.model';
import { EmailTemplateConfig } from 'src/app/shared/model/email-template-config.model';
import { ComposeEmailService } from 'src/app/shared/service/compose-email.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';

@Component({
   selector: 'app-charge-back-list',
   templateUrl: './charge-back-list.component.html',
   styleUrls: ['./charge-back-list.component.css']
})
export class ChargeBackListComponent implements OnInit {

   constructor(private authService: AuthService, private searchService: SearchService, private sharedService: SharedService, private metadataService: MetadataService, private salesService: SalesService,
      private router: Router, public chargeBackService: ChargeBackService, private dialog: MatDialog, private toastrService: ToastrService, private fileHanldingService: FileHandlingService, private composeEmailService: ComposeEmailService) { }

   @ViewChild('searchComponent') searchComponent: SearchComponent<ChargeBack>;

   userAccessSubscription: Subscription;
   userAccess: UserAccess;

   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;

   chargeBackList: ChargeBack[] = [];
   cbCategoryList: string[] = [];
   cbReasonList: string[] = [];
   cbVendorStatusList: string[] = [];
   cbCustomerStatusList: string[] = [];

   showSalesOrderListModal: boolean;
   searchConfig: SearchDetails;
   editMode: boolean = false;
   showEmailEditor: boolean = false;
   composeEmail: ComposeEmail = new ComposeEmail;
   customerStatusListForRequiredFieldValidation: string[] = [];
   selectedChargeBackStatus: string = "ACTIVE";

   sortColumns = [
      { columnName: 'LAST_MODIFIED_DATE', sortByName: 'Last Modified Date' },
      { columnName: 'CHARGE_BACK_NO', sortByName: 'Philcos CB #' },
      { columnName: 'CUSTOMER_CHARGE_BACK_NO', sortByName: 'Customer CB #' },
      { columnName: 'CUSTOMER_NAME', sortByName: 'Customer Name' },
      { columnName: 'VENDOR_NAME', sortByName: 'Vendor Name' }
   ];

   ngOnInit(): void {
      this.handleSubscriptions();
      if (!this.searchConfig) {
         this.searchConfig = this.searchService.chargeBackListSearchDetails;
         this.searchConfig.setFilterInList('STATUS','String',[this.selectedChargeBackStatus]);
         //this.setFilterConfigs();
      }
      this.loadCbCategoryList();
      this.loadCbReasonList();
      this.loadCbVendorStatusList();
      this.loadCbCustomerStatusList();
      this.loadCustomerStatusListForRequiredFieldValidation();
      this.chargeBackService.selectedChargeBackList = [];
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
         this.currentLoggedInUser = currentLoggedInUser;
      });
   }

   setFilterConfigs() {
      if (!this.searchConfig.filterConfigs) {
         this.searchConfig.filterConfigs = [];
         let approvateDateFilterConfig: FilterConfig = {
            filterType: 'DATE_RANGE',
            label: 'Approval Date',
            currentSelection: undefined,
            fieldName: 'APPROVAL_DATE'
         };
         this.searchConfig.filterConfigs.push(approvateDateFilterConfig);
      }
   }

   changeStatusAndSearch(){
      if('ALL'===this.selectedChargeBackStatus){
         this.searchConfig.setFilterInList('STATUS','String',[]);
         this.selectedChargeBackStatus='ALL';
      }else{
         this.searchConfig.setFilterInList('STATUS','String',[this.selectedChargeBackStatus]);
      }
      this.search();    
   }

   private search() {
      if (this.searchComponent) {
         this.searchComponent.search();
      }
   }

   loadCbCategoryList() {
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('ACCOUNTS', 'CHARGE_BACK', 'CATEGORY').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.cbCategoryList = this.sharedService.sort(res.responsePayload);
         }
      });
   }

   loadCbReasonList() {
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('ACCOUNTS', 'CHARGE_BACK', 'REASON').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.cbReasonList = this.sharedService.sort(res.responsePayload);
         }
      });
   }

   loadCbVendorStatusList() {
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('ACCOUNTS', 'CHARGE_BACK', 'VENDOR_STATUS').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.cbVendorStatusList = this.sharedService.sort(res.responsePayload);
         }
      });
   }

   loadCbCustomerStatusList() {
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('ACCOUNTS', 'CHARGE_BACK', 'CUSTOMER_STATUS').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.cbCustomerStatusList = this.sharedService.sort(res.responsePayload);
         }
      });
   }

   loadCustomerStatusListForRequiredFieldValidation(){
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('ACCOUNTS', 'CHARGE_BACK', 'STATUS_LIST_FOR_REQUIRED_FIELD_VALIDATION').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.customerStatusListForRequiredFieldValidation = this.sharedService.sort(res.responsePayload);
         }
      });
   }

   showSalesOrderListModalAction(show: boolean) {
      this.showSalesOrderListModal = show;
      this.salesService.resetSelectedSalesOrders();
   }

   createDraftChargeBack() {
      this.showSalesOrderListModalAction(false);
      this.router.navigate(['chargeback/draft']);
   }

   createSelectedChargeBack() {
      if (this.salesService.selectedSalesOrders?.length != 1) {
         return this.toastrService.error("Please select a Sales Order.")
      }
      let selectedSalesOrderId: string = this.salesService.selectedSalesOrders[0].id;
      let params = { 'currency': this.salesService.selectedSalesOrders[0].currency };
      this.showSalesOrderListModalAction(false);

      this.router.navigate(['chargeback/create/' + selectedSalesOrderId], { queryParams: params });
      //,{queryParams: {'currency':this.salesService.selectedSalesOrders[0].currency}}
   }

   cancelSelection() {
      this.showSalesOrderListModalAction(false);
   }

   deleteChargeBack(cb: ChargeBack) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
         disableClose: true,
         data: {
            title: 'Delete Charge Back',
            message: 'Are you sure you want to delete this Charge Back.?',
            confirmBtnText: 'Yes',
            cancelBtnText: 'Cancel'
         }
      });
      confirmDialog.afterClosed().subscribe(res => {
         if (res) {
            this.chargeBackService.deleteChargeBack(cb).subscribe(response => {
               if (response.responseStatus.status === 'SUCCESS') {
                  this.toastrService.success(response.responseStatus.message);
                  this.search();
               } else {
                  this.toastrService.error(response.responseStatus.message);
               }
            },
               error => {
                  console.error(error);
                  this.toastrService.error(error.message);
               });
         } else {

         }
      });
   }

   editChargeBack(cb: ChargeBack, index: any) {
      this.editMode = true;
      cb.editable = true;
      if (!cb.chargeBackAttachments || cb.chargeBackAttachments == null || cb.chargeBackAttachments.length == 0) {
         this.loadAttachments(cb);
      }
   }

   saveChargeBack(cb: ChargeBack, index: any) {

      if(!this.chargeBackService.isCBDocumentAvailable(this.customerStatusListForRequiredFieldValidation, cb)){
         return;
      }

      cb.cbDocumentsCount = this.chargeBackService.getChargeBackDocumentCount(cb);
      this.chargeBackService.updateChargeBack(cb).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let cbFromDB = response.responsePayload;
            this.editMode = false;
            cbFromDB.editable = false;
            this.chargeBackList[index] = cbFromDB;
            this.toastrService.success(response.responseStatus.message);
            this.uploadAttachments(cbFromDB, cb);
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      }, err => {
         console.error('Error while updating Charge Back : ' + JSON.stringify(err));
      });
   }

   async cancelEditChargeBack(cb: ChargeBack, index: any) {
      //console.log(cb);
      this.editMode = false;
      await this.restoreChargeBackOnCancelEdit(cb.id, index);
      //console.log(cb);
   }

   async restoreChargeBackOnCancelEdit(cbId: string, index: any) {
      await this.chargeBackService.getChargeBackById(cbId).toPromise().then(response => {
         let cbFromDB = response.responsePayload;
         this.editMode = false;
         cbFromDB.editable = false;
         //console.log(cbFromDB);
         this.chargeBackList[index] = { ...cbFromDB };
      });
   }

   isChargeBackSelected(cbId: string): boolean {
      return this.chargeBackService.isChargeBackSelected(cbId);
   }

   selectionChanged(cb: ChargeBack) {
      if (!cb.chargeBackAttachments || cb.chargeBackAttachments == null || cb.chargeBackAttachments.length == 0) {
         this.loadAttachments(cb);
      }
      return this.chargeBackService.selectionChanged(cb);
   }

   showEmailButton(): boolean {
      let retVal: boolean = true;
      retVal = this.chargeBackService.selectedChargeBackList?.length > 0;
      let chargeBacks: ChargeBack[] = this.chargeBackService.selectedChargeBackList;
      if (chargeBacks && chargeBacks.length > 0) {
         let uniqueVendorIds = [...new Set(chargeBacks.map(x => x.vendorId))];
         let uniqueVendorTypes = [...new Set(chargeBacks.map(x => x.vendorType))];
         if (uniqueVendorIds && uniqueVendorIds.length === 1 && uniqueVendorTypes && uniqueVendorTypes.length === 1) {
            retVal = true;
         } else {
            retVal = false;
         }
      }
      return retVal;
   }

   sendChargeBackEmail() {
      this.showEmailEditor = true;
      let selectedCbIdList = [];
      let cbAttachments: ProductAttachment[] = [];
      if (this.chargeBackService.selectedChargeBackList && this.chargeBackService.selectedChargeBackList.length > 0) {
         this.chargeBackService.selectedChargeBackList.forEach(cb => {
            if (cb.chargeBackAttachments && cb.chargeBackAttachments != null && cb.chargeBackAttachments.length > 0) {
               let cbAttachmentsForCurrent = cb.chargeBackAttachments.filter(x => x.groupReferenceId === cb.id);
               cbAttachments = cbAttachments.concat(cbAttachmentsForCurrent);
            }
            selectedCbIdList.push(cb.id);
         });
      }
      //console.log(cbAttachments);
      if (cbAttachments && cbAttachments.length > 0) {
         cbAttachments.forEach(att => {
            att.isSelected = true;
         });
      }
      let emailConfig: EmailTemplateConfig = new EmailTemplateConfig;
      emailConfig.templateName = 'chargeBackDetailsEmail';
      emailConfig.idList = selectedCbIdList;
      this.getEmailTemplate(emailConfig);
      this.composeEmail = new ComposeEmail;
      this.composeEmail.emailAttachments = this.sharedService.productAttachmentToEmailAttachment(cbAttachments);
   }

   getEmailTemplate(emailConfig: EmailTemplateConfig) {
      this.composeEmailService.getEmailTemplate(emailConfig).subscribe(response => {
         let composeEmailTemp: ComposeEmail = response.responsePayload;
         this.composeEmail.emailContent = composeEmailTemp.emailContent;
         this.composeEmail.emailTo = composeEmailTemp.emailTo;
         this.composeEmail.emailCc = composeEmailTemp.emailCc;
         this.composeEmail.emailBcc = composeEmailTemp.emailBcc;
         this.composeEmail.emailSubject = composeEmailTemp.emailSubject;
         document.getElementById('composeEmailToggler').click();
      });
   }

   createChargeBackExcel() {
      let criteria: SearchCriteriaBuilder = JSON.parse(JSON.stringify(this.searchConfig.searchCriteria));
      criteria.loadAll = true;
      criteria.loadMore == false;
      criteria.loadedCount = 0;

      this.chargeBackService.createChargeBackExcel(criteria).subscribe(res => {
         if (res && res !== null && res.size > 0) {
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = today.getFullYear();
            const todayStr = dd + '-' + mm + '-' + yyyy;
            const filename = 'ChargeBack' + '_' + todayStr + ".xlsx"
            this.toastrService.success("ChargeBack Summary Exported Successfully.");
            this.sharedService.downLoadFile(res, "application/vnd.ms-excel", filename);
         } else {
            this.toastrService.error("ChargeBack Summry Export Failed.");
         }
      }, err => {
         this.toastrService.error("ChargeBack Summary Export Failed. Error - " + err);
      });
   }


   /****************************** File Handling Starts *******************************/

   async loadAttachments(cb: ChargeBack) {
      this.chargeBackService.loadAttachments(cb);
   }

   uploadAttachments(updatedChargeBack: ChargeBack, cb: ChargeBack) {
      //console.log(cb.editable);
      this.chargeBackService.uploadAttachments(updatedChargeBack, cb, false);
   }

   getGroupItemForChargeBack(chargeBack: ChargeBack) {
      return this.chargeBackService.getGroupItemForChargeBack(chargeBack);
   }


   /****************************** File Handling Ends ******************************/

}
