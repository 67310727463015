import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { ActivatedRoute } from '@angular/router';
import { ServiceStatus } from 'src/app/shared/model/service-status.model';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/shared/service/customer.service';
import { Customer } from 'src/app/shared/model/customer.model';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
   selector: 'app-customer-list',
   templateUrl: './customer-list.component.html',
   styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent implements OnInit {

   constructor(private sharedService: SharedService, private service: CustomerService,
      private activatedRoute: ActivatedRoute,private authService:AuthService, private toastrService: ToastrService) { }

   groupedItemsList: any;
   itemList: any[] = [];
   filteredItemList: any[] = [];
   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   filterEventsubscription: Subscription;
   editable: boolean;


   ngOnInit() {
      this.handleSubscriptions();
      this.loadItems();
      window.scrollTo(0, 0);
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
      this.filterEventsubscription = this.authService.filterEvent.subscribe(filterEventData => {
         if (filterEventData != null) {
            this.onKeyupCustomerNameFilter(filterEventData.event, filterEventData.value);
         }
      });
   }

   onKeyupCustomerNameFilter(event, value) {
      if (event.key !== 'Shift') {
         if (value.trim().length > 0) {
            this.filteredItemList = this.itemList.filter(items => items.customerName.toUpperCase().indexOf(value.toUpperCase()) > -1);
         } else {
            this.filteredItemList = this.itemList;
         }
      }
      this.groupList();
   }

   loadItems() {
      this.service.getCustomers().subscribe(response => {
         this.itemList = response.responsePayload;
         this.filteredItemList = this.itemList;
         //
         this.filteredItemList = this.sharedService.sortListByPropertyName(this.filteredItemList, 'customerName');
         this.groupList();
      }, err => {
         console.error('Load Item List Error : ' + JSON.stringify(err) + ' with status - ');
      });
   }

   deleteItem(item: any) {
   }

   /**
    * This method groups the list.
    */
   groupList() {
      let grouped = this.filteredItemList.reduce((groups, item) => {
         const letter = item['customerName'].charAt(0).toUpperCase();
         groups[letter] = groups[letter] || [];
         groups[letter].push(item);
         return groups;
      },
         {}
      );
      let groupedList = Object.keys(grouped).map(key => ({ key, items: grouped[key] }));
      this.groupedItemsList = groupedList;
   }

   deleteCustomer(customer: Customer) {
      if (confirm('Are you sure you want to delete the customer?')) {
         this.service.deleteCustomer(customer).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.loadItems();
               this.toastrService.success("Successfully deleted the customer - " + customer.customerName);
            } else {
               this.toastrService.error("Error during deleting the customer - " + customer.customerName);
            }
         });
      }
   }


   onKeyupProductionCoordinatorFilter(event, value) {
      if (event.key !== 'Shift') {
         if (value.trim().length > 0) {
            this.filteredItemList = this.itemList.filter(item => 
               (item.customerName.toUpperCase().indexOf(value.toUpperCase()) > -1) ||
               (item.productionCoordinator?.firstName.toUpperCase().indexOf(value.toUpperCase()) > -1) ||
               (item.productionCoordinator?.lastName.toUpperCase().indexOf(value.toUpperCase()) > -1)
            );
         } else {
            this.filteredItemList = this.itemList;
         }
      }
   }


   sortColumns = [{ columnName: 'Customer', sortByName: 'customerName' } ];

   selectedSortByName: string;
   sortMode: string = 'asc';

   onSortColumnChange($event: any) {
      this.filteredItemList = this.sharedService.sortListByPropertyName(this.filteredItemList, this.selectedSortByName);
   }

   sortAsc() {
      this.sortMode = "asc";
      this.onSortColumnChange(null);
   }

   sortDesc() {
      this.sortMode = "desc";
      this.onSortColumnChange(null);
      this.filteredItemList.reverse();
   }

   createProductionCoordinatorAccountDetailsExcel() {
      this.service.createCustomerAndProductionCoordinatorAssignmentExcel().subscribe(res => {
         if (res && res !== null) {
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = today.getFullYear();
            const todayStr = dd + '_' + mm + '_' + yyyy;
            const filename = 'ProductionCoordinatorAccountDetails_' + todayStr + ".xlsx"
            this.toastrService.success("Report exported successfully");
            this.sharedService.downLoadFile(res, "application/vnd.ms-excel", filename);
         } else {
            this.toastrService.error("Export failed.");
         }
      }, err => {
         this.toastrService.error("Export failed. Error - " + err);
      });
   }

}
