<div class="container">
   <div class="row mt-2 d-flex justify-content-center">
      <h2 class="d-inline">Roles</h2>
      <button type="button" (click)="addNewRole()" *ngIf="userAccess?.isSupportAdmin"
         class="btn btn-circle btn-circle-md d-inline ml-4" matTooltip="Create New Role" matTooltipPosition="right"
         matTooltipClass="bg-dark"><i class="fas fa-plus fa-lg text-light"></i></button>
   </div>
   <hr>

   <div class="row">
      <div class="col-sm-6">
         <input class="form-control mb-2" #search placeholder="Filter by Role Description..."
            (keyup)="onKeyupRoleFilter($event,search.value)">
      </div>
      <div class="col-sm-6 mt-2">
         Found {{filteredRolesList?.length}}
         {{search.value.length>0? 'Roles for "'+search.value+'"' : 'Roles'}}
      </div>
   </div>


   <div class="row gallery-block cards-gallery mt-4">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-4" *ngFor="let role of filteredRolesList;index as i">
         <div class=" card border-0 transform-on-hover h-100 u-color-{{i%4}}" style="margin-bottom: 2px;">
            <a [routerLink]="['/role/permission/view/'+role.id]">
               <div class="card-body" style="padding: 0.25rem;">
                  <div class=" ml-1 mr-1">
                     <div class=" d-flex justify-content-left">
                        <button
                           class="btn bnt-lg bg-dark text-white ">{{role.roleDescription.charAt(0).toUpperCase()}}</button>
                     </div>
                     <div class="mb-1">
                        <h5 class="text-break  ml-2 mr-2 card-text justify-content-center">
                           {{role.roleDescription.toUpperCase()}}</h5>
                     </div>
                     <p class="card-text">{{role.roleId}}</p>
                  </div>
               </div>
            </a>
            <div class="d-flex justify-content-center">
               <!--<button type="button" class="btn  btn-circle btn-circle-sm d-inline" (click)="deleteRole(role)" *ngIf="userAccess?.canDeleteUser">
                  <i class="fas fa-trash text-light"></i>
               </button> -->
           </div>

         </div>
      </div>
   </div>


</div>

<!--  MODAL for Add New Role -->
<div class="modal fade" id="addNewRoleModal" data-keyboard="false" tabindex="-1" role="dialog"
   data-focus-on="input:first" aria-labelledby="addNewRoleModalTitle" aria-hidden="true">
   <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
         <div class="modal-header" style="background-color: #f7f7f7;">
            <h6 class="modal-title" id="addNewRoleModalLongTitle" style="font-weight: bold;">Add New Role</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
               (click)="closeAddNewRoleModal()">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <form #addNewRoleForm="ngForm">
               <div class="form-group">
                  <label class="col-form-label">Role Description</label>
                  <input name="roleDescription" class="form-control mb-2" type="text" #roleDescriptionVar="ngModel" required
                     oninput="this.value = this.value.toUpperCase()"
                     placeholder="Enter Role Description..." [(ngModel)]="newRole.roleDescription"
                     [ngClass]="{'is-invalid':roleDescriptionVar.touched && roleDescriptionVar.errors}">
                  <div
                     *ngIf="roleDescriptionVar?.invalid && (roleDescriptionVar.dirty || roleDescriptionVar.touched)"
                     class="invalid-feedback">
                     <div *ngIf="roleDescriptionVar.errors.required">Role Description is required.</div>
                  </div>
               </div>
            </form>
         </div>
         <div class="modal-footer" style="background-color: #f7f7f7;">
            <button type="button" id="addNewRoleModalCloseButton" class="btn btn-secondary btn-sm" data-dismiss="modal"
               (click)="closeAddNewRoleModal()">Close</button>
            <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal" [disabled]="!addNewRoleForm.valid"
               (click)="saveNewRole()">Save</button>
         </div>
      </div>
   </div>
</div>

<button id="addNewRoleModalToggler" type="button" style="display: none;" data-toggle="modal" data-target="#addNewRoleModal"></button>