import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ProductAttachment } from 'src/app/shared/model/product.model';
import { OrderDetailInfo } from 'src/app/shared/model/sales/fulfillment/order-detail-info.model';
import { PrinterInfo } from 'src/app/shared/model/sales/fulfillment/printer-info.model';
import { ProductionTracker } from 'src/app/shared/model/sales/fulfillment/production-tracker.model';

@Component({
  selector: 'app-order-detail-info',
  templateUrl: './order-detail-info.component.html',
  styleUrls: ['./order-detail-info.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class OrderDetailInfoComponent implements OnInit {
  @Input() editable:boolean;
  @Input() orderDetailInfoIndex:number=0;
  @Input() orderDetailInfo:OrderDetailInfo;
  @Input() printerInfoIndex:number=0;
  @Input() printerInfo:PrinterInfo;
  @Input() productionTracker:ProductionTracker;
  constructor() { }

  ngOnInit(): void {
  }

  
  handleThumbnailError(productAttachment: ProductAttachment, event) {
    if(productAttachment){
    if (isNaN(productAttachment?.thumbnailErrorCount)) {
      productAttachment.thumbnailErrorCount = 0;
    }
    event.target.src = productAttachment.fileSignedUrl;
    var x: number = productAttachment.thumbnailErrorCount;
    productAttachment.thumbnailErrorCount = x + 1;
    if (productAttachment.thumbnailErrorCount < 2) {
      event.target.src = productAttachment.fileSignedUrl;
      productAttachment.thumbnailSignedUrl = productAttachment.fileSignedUrl;
    } else {
      event.target.src = "/assets/default-image.jpg"
    }
    }
  }
}
