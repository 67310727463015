export class Phone {
   id: string;
   phoneType: string;
   countryCode: string;
   phoneNumber: string;
   phoneExtension: string;
   transientIndex:number;

   constructor(){
      this.id=null;
      this.phoneType=null;
      this.countryCode=null;
      this.phoneNumber=null;
      this.phoneExtension=null;
   }
}
