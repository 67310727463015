export class CustomerDiscount {
   id: string;
   customerId: string;
   discountType: string;
   discountPercentage: number;
   terms: string;
   remarks: string;
   status: boolean;
   deleteFlag: boolean;
}

