
<form #statusChangeForm="ngForm" autocomplete="off"><!-- data-backdrop="static"-->
    <div class="modal fade" id="{{modalId}}" tabindex="-1" data-focus-on="input:first" data-backdrop="static" role="dialog" aria-labelledby="title"
       aria-hidden="true">
       <div class="modal-dialog modal-dialog-scrollable " style="max-width: 99%;" role="document">
          <div class="modal-content">
             <div class="modal-header" style="background-color: #f7f7f7;">
                <h5 class="modal-title" id="longTitle" style="font-weight: bold;">{{product.sku}} - {{header}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                   (click)="closeModal();">
                   <span aria-hidden="true">&times;</span>
                </button>
             </div>
 
             <div class="modal-body">
                  <div class="row">
                     <div class="form-group row">
                        <div class="col-sm-2 ml-4" style="font-size: 16px;">Status</div>
                        <div class="col-sm">
                              <select style="width: auto;" id="astatus" name="astatus" class="f-13 form-control form-control-sm custom-select" [(ngModel)]="product.approvalStatus"
                                             name="approvalStatusVar" #approvalStatusVar="ngModel" required (change)="statusChanged(product,$event)"
                                 [ngClass]="{'is-invalid':approvalStatusVar.touched && approvalStatusVar.errors}"
                                 [disabled]="!(editable && product.approvalStatus===existingStatus)">
                                 <option *ngFor="let approvalStatus1 of approvalStatusList" [ngValue]="approvalStatus1">
                                                                     {{approvalStatus1}}</option>
                              </select>
                        </div>
                     </div>
                  </div>
                <!--<mat-tab-group>
                    <mat-tab>
                       <ng-template mat-tab-label>
                          <h6>SKU Approval Stages</h6>
                       </ng-template>
                       <div class="table-responsive">
                        <table id="detailsTable" class="mt-1 f-13 table table-sm table-bordered table-striped table-condensed hover">
                            <thead>
                               <tr class="bg-dark text-white">
                                 <th style="white-space: nowrap;width: 1%;">&nbsp;</th>
                                  <th style="width: auto;" class="align-middle text-center text-nowrap" *ngFor="let submissionDetailHeader of submissionDetailHeaders">{{submissionDetailHeader}}</th>
                               </tr>
                            </thead>
                            <tbody class="bg-light">
                                <ng-container class="row ml-2 mt-1" *ngFor="let sd of product?.statusDetails;index as index">
                                 <tr>
                                    <td style="white-space: nowrap;width: 1%;;" class="align-middle text-center"><i class="fas fa-play" style="color: tomato;" *ngIf="sd.current"></i><span (click)="remove(sd)"><i class="fas fa-trash" style="color: #db2707;" *ngIf="!sd.id"></i></span></td>
                                    <td style="width: auto;" class="align-middle text-center text-nowrap">{{sd.sku}}</td>
                                    <td style="width: auto;" class="align-middle text-center text-nowrap">{{sd.status}}</td>
                                    <td style="width: auto;" class="align-middle text-center text-nowrap">{{sd.statusDate | date:'mediumDate'}}</td>
                                    <td style="width: auto;" class="align-middle text-center text-nowrap">{{sd.createdBy}}</td>
                                  <td style="width: auto;" class="align-middle text-center text-wrap" *ngIf="!editable">{{sd.licensorComments}}</td>
                                  <td style="width: auto;" class="align-middle text-center" *ngIf="editable">
                                        <textarea name="licensoComments{{index}}" id="licensoComments{{index}}" [(ngModel)]="sd.licensorComments" rows="{{(sd.licensorComments?.length / 50)>1?(sd.licensorComments?.length / 50)+1:1}}"
                                        class="form-control form-control-sm f-13" [disabled]="!editable" maxlength="2500"
                                            #licensoCommentsVar="ngModel" [required]="sharedService.licComReqMandAppStatus.includes(sd.status)"
                                            [ngClass]="{'is-invalid':licensoCommentsVar.touched && licensoCommentsVar.errors}">
                                        </textarea>
                                    </td>
                                    <td style="width: auto;" class="align-middle text-center text-wrap" *ngIf="!editable">{{sd.philcosComments}}</td>
                                    <td style="width: auto;" class="align-middle text-center" *ngIf="editable">
                                        <textarea name="philcosComments{{index}}" id="philcosComments{{index}}" [(ngModel)]="sd.philcosComments" rows="{{(sd.philcosComments?.length / 50)>1?(sd.philcosComments?.length / 50)+1:1}}"
                                        class="form-control form-control-sm f-13" [disabled]="!editable" maxlength="2500"
                                            #philcosCommentsVar="ngModel"
                                            [ngClass]="{'is-invalid':philcosCommentsVar.touched && philcosCommentsVar.errors}">
                                        </textarea>
                                    </td>
                               </tr>
                            
                                </ng-container>
                               
                            </tbody>
                            </table>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                           <h6>Sample Worklfow</h6>
                        </ng-template>
                        <p>To Implement</p>
                     </mat-tab>
                </mat-tab-group>-->
               
                <app-sku-tracker [userAccess]="userAccess" [editable]="editable" [modalId]="'skuStatusChange'" [product]="product" [approvalStatusList]="approvalStatusList" [existingStatus]="existingStatus" [customerList]="customerList" [courierList]="courierList"></app-sku-tracker>
                
             </div>
 
             <div class="modal-footer" style="background-color: #f7f7f7;">
               <button type="button" class="btn btn-warning" *ngIf="!editable && editBtnVisible" (click)="editClicked();">Edit</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal" *ngIf="!editable">OK</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                *ngIf="editable" (click)="closeModal()">Cancel</button>
                <button type="button" id="modalOKButton" class="btn btn-primary" data-dismiss="modal"
                   *ngIf="editable"  (click)="saveModal();" [disabled]="!statusChangeForm.valid" >{{saveBtnText}}</button>
             </div>
          </div>
       </div>
    </div>
    
 </form>