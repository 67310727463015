<form #composeEmailForm="ngForm" autocomplete="off">
   <div class="modal fade" id="composeEmail" tabindex="-1" data-focus-on="input:first" data-backdrop="static" role="dialog" aria-labelledby="title" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable " style="max-width: 97%;" role="document">
         <div class="modal-content">

            <!--<div class="modal-header p-1" style="background-color: #f7f7f7;">
               <h5 class="modal-title text-center" id="longTitle" style="font-weight: bold;">{{emailHeader}}</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal();">
                  <span aria-hidden="true">&times;</span>
               </button>
            </div>-->

            <div class="modal-body">

               <!-- ######### To Email Address ################ -->
               <div class="form-group row mb-0">
                  <div class="text-right col-1 d-inline m-1 mt-0">
                     <label for="ToAddress" class="font-weight-bold f-13 col-form-label">To:</label>
                  </div>
                  <div class="col-10 d-inline m-1 mt-0">
                     <table width="100%">
                        <tr>
                           <td>
                              <div class="row ml-0">
                                 <div class="d-inline mt-0 ml-2" *ngFor="let to of composeEmail?.emailTo;index as i">
                                    <div>
                                       <div class="chip color-0 mb-1">
                                          {{to}}
                                          <span class="closebtn" *ngIf="true" (click)="removeToRecipient(to)">&times;</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="input-group col-3 mt-0">
                                    <input type="search" name="addEmailRecipient" class="form-control form-control-sm f-13" placeholder="Enter Email Address..." [(ngModel)]="emailTo"
                                       [ngClass]="{'is-invalid':emailToInput.touched && emailToInput.invalid}" #emailToInput="ngModel" email
                                       [matAutocomplete]="autoTo" (keyup)="onKeyupEmailToFilter($event,emailToInput.value,addEmailTo.disabled)" (blur)="onKeyupEmailToFilter($event,emailToInput.value,addEmailTo.disabled)">
                                       <mat-autocomplete #autoTo="matAutocomplete">
                                          <mat-option *ngFor="let email of filteredEmailToList" [value]="email" >
                                             {{email}}
                                          </mat-option>
                                       </mat-autocomplete>
                                    <div class="input-group-append">
                                       <button type="button" style="height: calc(1.5em + .5rem + 1px);" class="btn btn-sm btn-dark" (click)="addToRecipient(emailTo)" [disabled]="emailToInput.invalid || !emailToInput.value"
                                        #addEmailTo  matTooltip="Add Recipient" matTooltipPosition="below" matTooltipClass="bg-dark"><i class="fas fa-plus"></i></button>
                                    </div>
                                    <div *ngIf="emailToInput.invalid" class="invalid-feedback">
                                       <div *ngIf="emailToInput.errors.email">
                                          Email must be a valid email address.
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </td>
                        </tr>
                     </table>
                  </div>
               </div>


               <!-- ######### Cc Email Address ################ -->
               <div class="form-group row mb-0">
                  <div class="text-right col-1 d-inline m-1 mt-0">
                     <label for="ToAddress" class="font-weight-bold f-13 col-form-label">Cc:</label>
                  </div>
                  <div class="col-10 d-inline m-1 mt-0">
                     <table width="100%">
                        <tr>
                           <td>
                              <div class="row ml-0">
                                 <div class="d-inline mt-0 ml-2" *ngFor="let cc of composeEmail?.emailCc;index as i">
                                    <div>
                                       <div class="chip color-2 mb-1">
                                          {{cc}}
                                          <span class="closebtn" *ngIf="true" (click)="removeCcRecipient(cc)">&times;</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="input-group col-3 mt-0">
                                    <input type="search" name="addEmailCcRecipient" class="form-control form-control-sm f-13" placeholder="Enter Cc Email Address..." [(ngModel)]="emailCc"
                                       [ngClass]="{'is-invalid':emailCcInput.touched && emailCcInput.invalid}" #emailCcInput="ngModel" email
                                       [matAutocomplete]="autoCc" (keyup)="onKeyupEmailCcListFilter($event,emailCcInput.value,emailCC.disabled)" (blur)="onKeyupEmailCcListFilter($event,emailCcInput.value,emailCC.disabled)">
                                       <mat-autocomplete #autoCc="matAutocomplete">
                                          <mat-option *ngFor="let email of filteredEmailCcList" [value]="email" >
                                             {{email}}
                                          </mat-option>
                                       </mat-autocomplete>
                                    <div class="input-group-append">
                                       <button type="button" style="height: calc(1.5em + .5rem + 1px);" class="btn btn-sm btn-dark" (click)="addCcRecipient(emailCc)" [disabled]="emailCcInput.invalid || !emailCcInput.value"
                                        #emailCC  matTooltip="Add Cc Recipient" matTooltipPosition="below" matTooltipClass="bg-dark"><i class="fas fa-plus"></i></button>
                                    </div>
                                    <div *ngIf="emailCcInput.invalid" class="invalid-feedback">
                                       <div *ngIf="emailCcInput.errors.email">
                                          Email must be a valid email address.
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </td>
                        </tr>
                     </table>
                  </div>
               </div>

               <!-- ######### Bcc Email Address ################ -->
               <div class="form-group row mb-0">
                  <div class="text-right col-1 d-inline m-1 mt-0">
                     <label for="ToAddress" class="font-weight-bold f-13 col-form-label">Bcc:</label>
                  </div>
                  <div class="col-10 d-inline m-1 mt-0">
                     <table width="100%">
                        <tr>
                           <td>
                              <div class="row ml-0">
                                 <div class="d-inline mt-0 ml-2" *ngFor="let bcc of composeEmail?.emailBcc;index as i">
                                    <div>
                                       <div class="chip color-2 mb-1">
                                          {{bcc}}
                                          <span class="closebtn" *ngIf="true" (click)="removeBccRecipient(bcc)">&times;</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="input-group col-3 mt-0">
                                    <input type="search" name="addEmailBccRecipient" class="form-control form-control-sm f-13" placeholder="Enter Bcc Email Address..." [(ngModel)]="emailBcc"
                                       [ngClass]="{'is-invalid':emailBccInput.touched && emailBccInput.invalid}" #emailBccInput="ngModel" email
                                       [matAutocomplete]="autoBcc" (keyup)="onKeyupEmailBccListFilter($event,emailBccInput.value,emailBCC.disabled)" (blur)="onKeyupEmailBccListFilter($event,emailBccInput.value,emailBCC.disabled)">
                                       <mat-autocomplete #autoBcc="matAutocomplete">
                                          <mat-option *ngFor="let email of filteredEmailBccList" [value]="email" >
                                             {{email}}
                                          </mat-option>
                                       </mat-autocomplete>
                                    <div class="input-group-append">
                                       <button type="button" style="height: calc(1.5em + .5rem + 1px);" class="btn btn-sm btn-dark" (click)="addBccRecipient(emailBcc)" [disabled]="emailBccInput.invalid || !emailBccInput.value"
                                        #emailBCC  matTooltip="Add Bcc Recipient" matTooltipPosition="below" matTooltipClass="bg-dark"><i class="fas fa-plus"></i></button>
                                    </div>
                                    <div *ngIf="emailBccInput.invalid" class="invalid-feedback">
                                       <div *ngIf="emailBccInput.errors.email">
                                          Email must be a valid email address.
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </td>
                        </tr>
                     </table>
                  </div>
               </div>

               <!-- ######### SUBJECT ################ -->
               <div class="form-group row mb-0">
                  <div class="text-right col-1 d-inline m-1 mt-0">
                     <label for="subject" class="font-weight-bold f-13 col-form-label">Subject:</label>
                  </div>
                  <div class="col-8 d-inline m-1 mt-0">
                     <input type="text" name="subject" class="form-control form-control-sm f-13 ml-3" id="subject" 
                     [(ngModel)]="composeEmail.emailSubject" required
                     [ngClass]="{'is-invalid':emailSubject.touched && emailSubject.invalid}" #emailSubject="ngModel" />
                     <div *ngIf="emailSubject.invalid" class="invalid-feedback ml-3">
                        <div *ngIf="emailSubject.errors.required">
                           Email Subject is required.
                        </div>
                     </div>
                  </div>
                  <div class="col-2 d-flex align-items-center">
                     <div class="custom-control custom-checkbox mr-sm-2 ml-3">
                        <input type="checkbox" class="custom-control-input ml-2" name="priority" id="priority" 
                           [checked]="composeEmail.emailPriority" [(ngModel)]="composeEmail.emailPriority">
                        <label class="custom-control-label font-weight-bold f-13" for="priority">
                           High Priority
                        </label>
                     </div>
                      <!-- ######### Priority ################ -->
                        <!--<div class="d-flex d-inline ">
                           <div class="">
                              <label for="priority" class="font-weight-bold f-13 col-form-label text-nowrap">High Priority:</label>
                           </div>
                           <div class="ml-2 d-flex  align-middle">
                              <div class="form-check">
                                 <input class="form-check-input" type="checkbox" name="priority" id="priority" [(ngModel)]="composeEmail.emailPriority"
                                    [checked]="composeEmail.emailPriority">
                              </div>
                           </div>
                        </div>-->
                  </div>
                  
               </div>


               <!-- ######### Attachments ################ -->
               <div class="row">
                  <div class="col-1"></div>
                  <div class="col-10 ml-4">
                  <hr *ngIf="composeEmail?.emailAttachments.length >0">
                  </div>
               </div>
               
               <div class="form-group row mb-0" *ngIf="composeEmail?.emailAttachments.length >0 && showAttachmentAsLinkCheckBox && !forceHideAttachmentAsLinkCheckBox">
                  <div class="text-right col-1 d-inline m-1 mt-0">
                     <label for="attachments" class="font-weight-bold f-13 col-form-label">&nbsp;</label>
                  </div>
                  <div class="col-2 d-flex align-items-center">
                     <div class="custom-control custom-checkbox ml-4">
                        <input type="checkbox" class="custom-control-input ml-2" name="emailAttachmentAsLink" id="emailAttachmentAsLink" 
                           [checked]="composeEmail.emailAttachmentAsLink" [(ngModel)]="composeEmail.emailAttachmentAsLink">
                        <label class="custom-control-label font-weight-bold f-13" for="emailAttachmentAsLink">
                           Attachment(s) as link
                        </label>
                     </div>
                  </div>
               </div>
               
               <div class="form-group row mb-0" *ngIf="composeEmail?.emailAttachments.length >0">
                 
                  <div class="text-right col-1 d-inline m-1 mt-0">
                     <label for="attachments" class="font-weight-bold f-13 col-form-label">Attachments:</label>
                  </div>
                  <div class="col-10 d-inline m-1 mt-0">
                     <div class="row">

                           <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let emailAttachment of composeEmail?.emailAttachments;index as i">
                              <div class="custom-control custom-checkbox mr-sm-2 ml-3">
                                 <input type="checkbox" class="custom-control-input ml-2" name="an_{{i}}" id="an_{{i}}" 
                                    [checked]="emailAttachment.attachmentSelected"
                                    (click)="removeAttachment($event,emailAttachment)">
                                 <label class="custom-control-label" for="an_{{i}}">
                                    {{emailAttachment.attachmentFileName}}
                                 </label>
                              </div>
                           </div>
                     </div>
                        
                  </div>
               </div>
               <div class="row">
                  <div class="col-1"></div>
                  <div class="col-10 ml-4">
                  <hr *ngIf="composeEmail?.emailAttachments.length >0">
                  </div>
               </div>

              

               <!-- ######### Email Content with Editor ################ -->
               <div class="form-group row mb-0">
                  <div class="text-right col-1 d-inline m-1 mt-0">
                     <label for="content" class="font-weight-bold  col-form-label">Content:</label>
                  </div>
                  <div class="col-10 d-inline m-1 mt-0 ml-3">
                     <ng-container *ngIf="showHtmlEditor">
                        <angular-editor name="htmlEditor" [(ngModel)]="composeEmail.emailContent" [config]="config"></angular-editor>
                     </ng-container>
                     <ng-container  *ngIf="showRichTextEditor">
                        <p-editor name="emailContentEditor" [(ngModel)]="composeEmail.emailContent" [style]="{'height':'100%'}" (onInit)="onEditorInit($event)"></p-editor>
                     </ng-container>

                  </div>
               </div>

            </div>

            <!-- ######### Email Buttons ################ -->
            <div class="modal-footer p-1" style="background-color: #f7f7f7;">
               <button type="button" class="btn btn-sm btn-primary" [disabled]="!composeEmailForm.valid || (composeEmail?.emailTo.length === 0 && composeEmail?.emailCc.length === 0 && composeEmail?.emailBcc.length === 0)" (click)="sendEmail()">Send Email</button>
               <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal" (click)="closeModal()">Cancel</button>
               <button type="button" id="closeEmailModal" class="btn btn-secondary" data-dismiss="modal" style="display: none;">Close</button>
            </div>


         </div>
      </div>
   </div>

</form>

