import { Brand } from './brand.model';
import { LinkDetailsCredential } from './link-details-credential.model';

export class LinkDetails {
    id:string;
    url:string;
    username:string;
    password:string;
    linkDetailsType:string;
    type:string;
    //brandName: string;
    //brandId: string;
    //Transient
    associatedBrands: Brand[] = [];
    linkDetailsCredentialsList: LinkDetailsCredential[] = [];
    
    constructor(){
       this.associatedBrands = [];
       this.linkDetailsCredentialsList = [];
    }
}

