
<label *ngIf="!sectionLabel" class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">{{header}}</label>
<div *ngIf="sectionLabel" class="mb-0 ml-1">
    <div>
        <h4 *ngIf="headerSize==='4'">{{header}}</h4>
        <h5 *ngIf="headerSize==='5'">{{header}}</h5>
        <h6 *ngIf="headerSize==='6'">{{header}}</h6>
    </div>
</div>
<hr *ngIf="sectionLabel">
<div class="row">
    <div class="col-auto" *ngFor="let linkItem of linksArray">
        <a *ngIf="linkItem.url && !linkItem.deleteFlag && linkItem.url!==''" href='{{linkItem.url}}' target="_blank" class="f-10" >{{linkItem.description}}</a>
        <span class="f-10" *ngIf="!linkItem.url && !linkItem.deleteFlag">{{linkItem.description}}</span>
    </div>
    <button class="btn btn-sm btn-info f-10" type="button" (click)="openModal()" *ngIf="editable"><i class="fas fa-pencil-alt"></i></button>
</div>
