import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from '../shared/service/shared.service';
import { UserAccess } from '../shared/model/user-access.model';
import { AuthService } from '../shared/service/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit, OnDestroy {
  userAccessSubscription:Subscription;
  userAccess:UserAccess;

  constructor(private sharedService:SharedService,private authService:AuthService) { 
    //
    this.authService.loadUserRolesAndPermissions(this.authService.currentUserValue.id);
    //
  }

  ngOnInit() {
    //
    this.handleSubscriptions();
    //
  }

  handleSubscriptions(){
   //
    this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
      this.userAccess = access;
      //
    });
    //
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.userAccessSubscription.unsubscribe();
  }
 
}
