import { Component, OnInit, Input } from '@angular/core';
import { Address } from 'src/app/shared/model/address.model';
import { HttpClient } from '@angular/common/http';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { ControlContainer, NgForm } from '@angular/forms';
import { BrandService } from 'src/app/shared/service/brand.service';
import { CustomerService } from 'src/app/shared/service/customer.service';

@Component({
   selector: 'app-address',
   templateUrl: './address.component.html',
   styleUrls: ['./address.component.css'],
   viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class AddressComponent implements OnInit {

   constructor(private httpClient: HttpClient, private metadataService: MetadataService, 
      private customerService: CustomerService) {}

   //@Input()
   localAddressList: Address[];
   
   @Input() editable: boolean;
   @Input() multipleAddress: boolean;
   @Input() contactType: string;
   @Input() showAddressType: boolean = true;

   addressTypes: string[];
   countriesWithRegionsDetails: any;
   selectedCountry: string;
   regionsForSelectedCountry: any = [];

   
   @Input()
   set addressList(addressPassed: Address[]) {
      this.localAddressList = addressPassed;
      if (this.countriesWithRegionsDetails === null || this.countriesWithRegionsDetails === undefined) {
         this.httpClient.get("assets/country-region-data.json").subscribe(data => {
            this.countriesWithRegionsDetails = data;
            for (let index = 0; index < this.localAddressList?.length; index++) {
               let address: Address = this.localAddressList[index];
               this.populateRegionsForCountry(this.getCountryObjectByCountryCode(address.countryCode), index);
            }
         });
      }
   }

   get addressList(): Address[] {
      return this.localAddressList;
   }

   ngOnInit(): void {
      this.loadAddressTypes();
      //this.loadCountriesWithRegions();
   }

   private loadAddressTypes() {
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('PROFILE', 'ADDRESS', 'TYPE').subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.addressTypes = response.responsePayload;
         }
      }, error => {
         console.error(error);
      });
   }

   private loadCountriesWithRegions(){
      this.loadCountriesWithRegionsFromFE();
      //this.loadCountriesWithRegionsFromBE();
   }

   private loadCountriesWithRegionsFromFE() {
      if(this.countriesWithRegionsDetails === null || this.countriesWithRegionsDetails === undefined){
         this.httpClient.get("assets/country-region-data.json").subscribe(data => {
         //this.httpClient.get("assets/country/countries.json").subscribe(data => {
         this.countriesWithRegionsDetails = data;
         });
      }
   }

   private loadCountriesWithRegionsFromBE() {
      if (this.countriesWithRegionsDetails === null || this.countriesWithRegionsDetails === undefined) {
         this.customerService.getCountriesWithProvinces().subscribe(response => {
            this.countriesWithRegionsDetails = response.responsePayload;
         });
      }
   }

   addAddress() {
      let address = new Address;
      address.transientIndex=this.localAddressList.length;
      this.localAddressList.push(address);
   }

   removeAddress(index: number) {
      this.localAddressList.splice(index, 1);
      //this.localAddressList.splice(this.localAddressList.indexOf(addressToRemove), 1);
   }

   onCountryDropdownChange(selectedCountryCode: any, index: any) {
      let selectedCountry = this.getCountryObjectByCountryCode(selectedCountryCode);
      this.localAddressList[index].countryName = selectedCountry.countryName;
      this.populateRegionsForCountry(selectedCountry, index);
   }

   onProvinceDropdownChange(selectedProvinceCode: any, index: any) {
      let selectedProvinceObj = this.getRegionsObjectByProvinceCode(selectedProvinceCode,index);
      this.localAddressList[index].provinceName = selectedProvinceObj.name;
   }

   getCountryObjectByCountryCode(countryCode: string) {
      let countryObjectByCountryCode = this.countriesWithRegionsDetails?.find(x => x.countryShortCode === countryCode);
      return countryObjectByCountryCode;
   }

   getRegionsObjectByProvinceCode(provinceCode: string, index: any) {
      let provinceObjectByProvinceCode = this.regionsForSelectedCountry[index]?.find(x => x.shortCode === provinceCode);
      return provinceObjectByProvinceCode;
   }

   populateRegionsForCountry(country: any, index: any) {
      let regionsForCountry: [] = country?.regions;
      this.regionsForSelectedCountry[index] = regionsForCountry;
   }

}
