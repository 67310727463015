<form #contactForm="ngForm"  autocomplete="off" #formVar="ngForm" novalidate>
    <div class="container">
        <!--<div style="position: absolute;">
            <button type="button" id="backButton" class="btn  btn-outline-info mt-2 " (click)="goBack()"> <i class="fas fa-angle-double-left fa-lg"></i></button>
            <button type="button"  class="btn  btn-outline-warning mt-2 ml-2" (click)="edit()"  *ngIf="viewAction && userAccess.canEditBrand"> <i class="fas fa-pencil-alt fa-lg"></i></button>
        </div>-->
    
        <div class="d-flex justify-content-center mt-3">
            <div class="text-color-{{brand.brandStatus+'' | replace:' ':'_'|lowercase}}"><h3>{{brand.brandName}}&nbsp;</h3></div>
            <div></div>
        </div>
        <div *ngIf="brand.brandStatus!==undefined" class="separator-{{brand.brandStatus+'' | replace:' ':'_'|lowercase}}"><h6>{{brand.brandStatus?'Active':'Inactive'}}</h6></div>
    
        <div class="row mt-4 shadow-lg">

            <div class="col-lg-6">
                <div class="row mb-4"  >
                    <div class="col-lg-6  mt-4 px-1">
                        <div class="row">
                            <div class="col-sm-4">
                                <h5 class="mt-2 ml-2">LOGO</h5>
                            </div>
                                <div class="col-sm ml-1 mt-2 mr-3" *ngIf="editable && brand.logos.length===0">
                                        <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="fileSelectedDragDrop($event,'LOGO')" style="height: 30px;" [editable]="editable"> 
                                                <input hidden type="file" #fileInput  (change)="fileSelectedDragDrop($event.target.files,'LOGO')">
                                        </div>
                                </div>
                        </div>
                        <hr>
                        <div  class=" card ml-3 d-flex align-items-center justify-content-center border border-dark" style="width: 246px;height:246px;">
                            <div *ngIf="selectedLogoAccesUrl!==undefined">
                                <img [src]="selectedLogoAccesUrl" class="img-auto-size" onerror="this.src='/assets/default-image.jpg';">
                            </div>
                            <div class="card-img-overlay h-100 d-flex flex-column justify-content-end "
                                                                style="display: block;    height:3rem;"
                                                                >
                                <div>
                                    <div class="mb-2" *ngIf="editable  && selectedLogoAccesUrl!==undefined && selectedLogoAccesUrl!==null">
                                        <button type="button" class="btn btn-circle btn-circle-sm"
                                                    (click)="deleteFile(selectedLogoId,'LOGO')"><i
                                                            class="fas fa-trash fa-lg text-light" ></i></button>
                                    </div>
                                    <div class="mb-2" *ngIf="selectedLogoAccesUrl!==undefined && selectedLogoAccesUrl!==null">
                                        <a class="btn btn-circle btn-circle-sm"
                                                [href]="selectedLogoAccesUrl"
                                                target="_blank"
                                                [download]="selectedLogoAccesUrl.split('/').pop()"><i
                                                        class="fas fa-download fa-lg text-light"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="false">
                            <div class="d-flex flex-wrap">
                                <div *ngFor="let attachment of brand.logos">
                                        <a (click)="showLogo(attachment.fileSignedUrl,attachment.id,$event)"
                                                [style.background-color]="attachment.fileSignedUrl== selectedLogoAccesUrl? 'cadetblue' : 'white'">
                                                <img [src]="attachment.thumbnailSignedUrl"
                                                        class="img-thumbnail img-fluid"
                                                        alt="assets/addImage.png"
                                                        style="display: block;  width:60px;  height:60px;"
                                                        [style.border-color]="attachment.fileSignedUrl== selectedLogoAccesUrl? 'cadetblue' : 'white'"
                                                        ><!--#thumbLogo (error)="thumbLogo.src = attachment.fileSignedUrl"-->
                                        </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" col-lg-6  mt-4 px-1">
                        <div class="row">
                            <div class="col-sm-4">
                                <h5 class="mt-2 ml-2">PNL</h5>
                            </div>
                                <div class="col-sm ml-1 mt-2 mr-3" *ngIf="editable">
                                        <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="fileSelectedDragDrop($event,'PNL')" style="height: 30px;" [editable]="editable">
                                                <input hidden type="file" #fileInput multiple  (change)="fileSelectedDragDrop($event.target.files,'PNL')">
                                        </div>
                                </div>
                        </div>
                        <hr>
                        <div  class=" card ml-3 d-flex align-items-center justify-content-center border border-dark" style="width: 246px;height:246px;">
                            <div *ngIf="selectedPnlAccesUrl!==undefined" class="d-flex align-items-center justify-content-center">
                                <img [src]="selectedPnlAccesUrl" class="img-auto-size" onerror="this.src='/assets/default-image.jpg';">
                            </div>
                            <div class="card-img-overlay h-100 d-flex flex-column justify-content-end "
                                                                style="display: block;    height:3rem;"
                                                                >
                                <div>
                                    <div class="mb-2" *ngIf="userAccess?.canDeleteBrandPnls && editable &&  selectedPnlAccesUrl!==undefined && selectedPnlAccesUrl!==null">
                                        <button type="button" class="btn btn-circle btn-circle-sm"
                                                    (click)="deleteFile(selectedPnlId,'PNL')"
                                                    >
                                                    <i class="fas fa-trash fa-lg text-light" ></i></button>
                                    </div>
                                    <div class="mb-2" *ngIf="userAccess?.canDownloadPnls && selectedPnlAccesUrl!==undefined && selectedPnlAccesUrl!==null">
                                        <a class="btn btn-circle btn-circle-sm" 
                                                [href]="selectedPnlAccesUrl"
                                                target="_blank"
                                                [download]="selectedPnlAccesUrl.split('/').pop()"><i
                                                        class="fas fa-download fa-lg text-light"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <mat-tab-group>
                                <mat-tab>
                                   <ng-template mat-tab-label>
                                      <h6>Images</h6>
                                   </ng-template>
                                    <div class="mt-2 overflow-auto border-box" style="max-height: calc(30vh)">
                                        <ng-container  *ngFor="let attachment of brand.pnls;index as index">
                                            <div *ngIf="attachment.mimeType?.startsWith('image')" class="m-2">
                                                    <a (click)="showPnl(attachment.fileSignedUrl,attachment.id,$event)"
                                                            [style.background-color]="attachment.fileSignedUrl== selectedPnlAccesUrl? 'white' : 'white'">
                                                            <div class="d-flex flex-row" 
                                                            [style.background]="attachment.fileSignedUrl== selectedPnlAccesUrl? '#ff6347c9' : '#80808033'"
                                                            [style.color]="attachment.fileSignedUrl== selectedPnlAccesUrl? 'white' : 'black'"
                                                                matTooltip="&#8226; File Name : {{attachment.originalFileName}} &#13;&#8226; Uploaded On : {{attachment.uploadDate | date:'medium'}}" 
                                                                matTooltipPosition="below" matTooltipClass="bg-dark allow-cr f-10">
                                                                <div class="pl-2 d-inline">
                                                                    <span *ngIf="editable && attachment.id && attachment.sortOrder!==0 &&userAccess?.canDefaultPnl" style="color:lightslategray;font-style: italic;" (click)="defaultPnlSelectedImage(attachment)">
                                                                        <i class="far fa-star"
                                                                        style="color: #6c757d24;" 
                                                                                          onmouseover="this.style.color='black'" 
                                                                                          onmouseout="this.style.color='#6c757d24'"></i></span>
                                                                    <span *ngIf="attachment.sortOrder===0">
                                                                        <i class="fas fa-star" style="color: black;"  >
                                                                    </i>
                                                                </span>
                                                                </div>
                                                                <div class="d-inline"><a class="btn btn-sm f-11"
                                                                    [href]="attachment.fileSignedUrl" *ngIf=" !createAction && userAccess?.canDownloadPnls" target="_blank"
                                                                    [download]="attachment.originalFileName"><i class="fas fa-download "></i></a>
                                                                </div>
                                                                <div *ngIf="editable && userAccess?.canDeleteBrandPnls" class="d-inline">
                                                                    <button type="button" class="btn btn-sm f-11"
                                                                        (click)="deleteFile(attachment.id,attachment.fileCategory)"><i
                                                                            class="fas fa-trash"></i></button>
                                                                </div>
                                                                <!--<img [src]="attachment.thumbnailSignedUrl" #thumb
                                                                        class="img-thumbnail img-fluid"
                                                                        alt="Pending"
                                                                        style="display: block;  width:60px;  height:60px;"
                                                                        onerror="this.src='/assets/default-image.jpg';"
                                                                        [style.border-color]="attachment.fileSignedUrl== selectedPnlAccesUrl? 'cadetblue' : '#80808033'"
                                                                        matTooltip="&#8226; File Name : {{attachment.originalFileName}} &#13;&#8226; Uploaded On : {{attachment.uploadDate | date:'medium'}}" 
                                                                        matTooltipPosition="below" matTooltipClass="bg-dark allow-cr f-10">-->
                                                                <div class="my-2 d-flex align-items-center">
                                                                    <div>
                                                                        <div class="text-wrap f-11 mx-1">{{attachment.originalFileName}}</div>
                                                                        
                                                                        <!--<ul><li><div class="text-muted text-wrap f-10 ml-1">{{attachment.uploadDate | date:'medium'}}</div></li></ul>-->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </a>
                                            </div>
                                        </ng-container>
                                    </div>
                                </mat-tab>
                                <mat-tab>
                                    <ng-template mat-tab-label>
                                       <h6>Files</h6>
                                    </ng-template>
                                    <div class="mt-2 overflow-auto border-box" style="height: calc(30vh)">
                                        <ng-container  *ngFor="let attachment of brand.pnls;index as index">
                                            <div *ngIf="!attachment.mimeType?.startsWith('image')" class="m-2">
                                             <a (click)="showPnl(attachment.fileSignedUrl,attachment.id,$event)" [style.background-color]="attachment.fileSignedUrl== selectedPnlAccesUrl? 'white' : 'white'">
                                                <div class="d-flex flex-row" [style.background]="attachment.fileSignedUrl== selectedPnlAccesUrl? '#ff6347c9' : '#80808033'"
                                                   [style.color]="attachment.fileSignedUrl== selectedPnlAccesUrl? 'white' : 'black'"
                                                   matTooltip="&#8226; File Name : {{attachment.originalFileName}} &#13;&#8226; Uploaded On : {{attachment.uploadDate | date:'medium'}}" matTooltipPosition="below"
                                                   matTooltipClass="bg-dark allow-cr f-10">
                                                   <div class="pl-2 d-inline">
                                                      <span *ngIf="editable && attachment.id &&  !attachment.liked && userAccess?.canDefaultPnl" style="color:lightslategray;font-style: italic;" (click)="defaultPnlSelected(attachment)">
                                                         <i class="far fa-thumbs-up" style="color: #6c757d24;" onmouseover="this.style.color='black'" onmouseout="this.style.color='#6c757d24'"></i></span>
                                                      <span *ngIf="attachment.liked">
                                                         <i class="fas fa-thumbs-up" style="color: black;">
                                                         </i>
                                                      </span>
                                                   </div>
                                                   <div class="d-inline"><a class="btn btn-sm f-11" [href]="attachment.fileSignedUrl" *ngIf=" !createAction && userAccess?.canDownloadPnls" target="_blank"
                                                         [download]="attachment.originalFileName"><i class="fas fa-download "></i></a>
                                                   </div>
                                                   <div *ngIf="editable && userAccess?.canDeleteBrandPnls" class="d-inline">
                                                      <button type="button" class="btn btn-sm f-11" (click)="deleteFile(attachment.id,attachment.fileCategory)"><i class="fas fa-trash"></i></button>
                                                   </div>
                                                   <!--<img [src]="attachment.thumbnailSignedUrl" #thumb
                                                                                                      class="img-thumbnail img-fluid"
                                                                                                      alt="Pending"
                                                                                                      style="display: block;  width:60px;  height:60px;"
                                                                                                      onerror="this.src='/assets/default-image.jpg';"
                                                                                                      [style.border-color]="attachment.fileSignedUrl== selectedPnlAccesUrl? 'cadetblue' : '#80808033'"
                                                                                                      matTooltip="&#8226; File Name : {{attachment.originalFileName}} &#13;&#8226; Uploaded On : {{attachment.uploadDate | date:'medium'}}" 
                                                                                                      matTooltipPosition="below" matTooltipClass="bg-dark allow-cr f-10">-->
                                                   <div class="my-2 d-flex align-items-center">
                                                      <div>
                                                         <div class="text-wrap f-11 mx-1">{{attachment.originalFileName}}</div>
                                                         <!--<ul><li><div class="text-muted text-wrap f-10 ml-1">{{attachment.uploadDate | date:'medium'}}</div></li></ul>-->
                                                      </div>
                                                   </div>
                                                </div>
                                             </a>
                                            </div>
                                        </ng-container>
                                    </div>
                                     <!--<div class="d-flex flex-wrap overflow-hidden mt-4">
                                         <ng-container  *ngFor="let attachment of brand.pnls">
                                             <div *ngIf="!attachment.mimeType?.startsWith('image')" class="ml-1">
                                                     <a (click)="showPnl(attachment.fileSignedUrl,attachment.id,$event)"
                                                             [style.background-color]="attachment.fileSignedUrl== selectedPnlAccesUrl? 'white' : 'white'">
                                                             <img [src]="attachment.thumbnailSignedUrl" #thumb
                                                                     class="img-thumbnail img-fluid"
                                                                     alt="Pending"
                                                                     style="display: block;  width:60px;  height:60px;"
                                                                     onerror="this.src='/assets/default-image.jpg';"
                                                                     [style.border-color]="attachment.fileSignedUrl== selectedPnlAccesUrl? 'cadetblue' : '#80808033'"
                                                                     matTooltip="&#8226; File Name : {{attachment.originalFileName}} &#13;&#8226; Uploaded On : {{attachment.uploadDate | date:'medium'}}" 
                                                                     matTooltipPosition="below" matTooltipClass="bg-dark allow-cr f-10">
                                                     </a>
                                             </div>
                                         </ng-container>
                                     </div>-->
                                 </mat-tab>
                            </mat-tab-group>
                            
                            <button type="button" (click)="sendPnlChangeEmail()"
                                                       class="btn bg-navy-blue btn-sm mt-2 btn-block text-white f-13" *ngIf="brand?.pnls?.length>0 && !editable && userAccess?.canCreateBrand"
                                                       matTooltip="Send PNL Change Email" matTooltipPosition="below" matTooltipClass="bg-dark">
                                                       <i class="fa fa-envelope text-light fa-lg "></i>&nbsp;&nbsp;Send PNL Change Email
                            </button>
                        </div>
                    </div>

                    
                </div>
            </div>

            <div class="col-lg mt-4">
            <mat-tab-group>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <h5>Details</h5>
                    </ng-template>
                    <div class="ml-1 mt-2 overflow-hidden">   
                
                            <div class="form-group row">
                                <label class=" f-13 col-sm-4">Brand Name</label>
                                <div class="col-sm-8">
                                    <input name="brandName" [(ngModel)]="brand.brandName" class="form-control form-control-sm" #brandName="ngModel"
                                        required [disabled]="!editable"
                                        [ngClass]="{'is-invalid':brandName.touched && brandName.errors}" maxlength="40">
                                    <div *ngIf="brandName?.invalid && (brandName.dirty || brandName.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="brandName.errors.required">
                                            Name is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                           <div class="form-group row">
                              <label class=" f-13 col-sm-4">Short Name</label>
                              <div class="col-sm-8">
                                 <input name="shortName" [(ngModel)]="brand.shortName" class="form-control form-control-sm" #shortName="ngModel" required [disabled]="!editable"
                                    [ngClass]="{'is-invalid':shortName.touched && shortName.errors}" maxlength="40">
                                 <div *ngIf="shortName?.invalid && (shortName.dirty || shortName.touched)" class="invalid-feedback">
                                    <div *ngIf="shortName.errors.required">
                                       Short Name is required.
                                    </div>
                                 </div>
                              </div>
                           </div>
                            <div class="form-group row">
                                <label class=" f-13 col-sm-4">SKU Code</label>
                                <div class="col-sm-8">
                                    <input name="skuCode" [(ngModel)]="brand.skuCode" class="form-control form-control-sm" #skuCode="ngModel"
                                        required [disabled]="!editable" minlength="3" maxlength="3"
                                        [ngClass]="{'is-invalid':skuCode.touched && skuCode.errors}" oninput="this.value = this.value.toUpperCase()">
                                    <div *ngIf="skuCode?.invalid && (skuCode.dirty || skuCode.touched)" class="invalid-feedback">
                                        <div *ngIf="skuCode.errors.required">
                                            SKU Code is required.
                                        </div>
                                        <div *ngIf="skuCode.errors.minlength">
                                            SKU Code Minimum Length is 3
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="form-group row">
                            <label class=" f-13 col-sm-4">Account Set</label>
                            <div class="col-sm-8">
                                <input name="accountSet" [(ngModel)]="brand.accountSet" class="form-control form-control-sm" #accountSet="ngModel"
                                    [disabled]="!editable" required
                                    [ngClass]="{'is-invalid':accountSet.touched && accountSet.errors}" maxlength="40">
                                <div *ngIf="accountSet?.invalid && (accountSet.dirty || accountSet.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="accountSet.errors.required">
                                        Account Set is required.
                                    </div>
                                </div>
                            </div>
                        </div>    

                        <div class="form-group row">
                        <div class="col-sm-4">
                            <div class="row">
                                <label class=" f-13 col-sm-4" style="margin-bottom: 0px;">Category</label>
                            </div>
                        </div>
                        <div class="col-sm-8" *ngIf="!editable">
                            <input name="categoryVar" [(ngModel)]="brand.category" class="form-control form-control-sm" #categoryVar readonly>
                        </div>
                        <div class="col-sm-8" *ngIf="editable">
                        
                            <select class="form-control form-control-sm custom-select" [(ngModel)]="brand.category"
                                name="categoryVar" #categoryVar="ngModel" required
                                [ngClass]="{'is-invalid':categoryVar.touched && categoryVar.errors}"
                                (change)="onDropdownChange('Category', brand.category)">
                        
                                <option value="add">
                                    Add Category...</option>
                                <hr>
                                <option *ngFor="let category of categoryList" [ngValue]="category">
                                    {{category}}</option>
                            </select>
                            <div *ngIf="categoryVar?.invalid && (categoryVar.dirty || categoryVar.touched)" class="invalid-feedback">
                                <div *ngIf="categoryVar.errors.required">
                                    Category is required.
                                </div>
                            </div>
                        </div>
                        </div>              

                            <div class="form-group row">
                                <label class=" f-13 col-sm-4">Brand Status</label>
                                <div class="col-sm-8">
                                    <select class="form-control form-control-sm custom-select" [(ngModel)]="brand.brandStatus"
                                        name="BrandStatus" #brandStatus="ngModel" [disabled]="!editable" required
                                        [ngClass]="{'is-invalid':brandStatus.touched && brandStatus.errors}">
                                        <option value=false>Inactive</option>
                                        <option value=true>Active</option>

                                    </select>
                                    <div *ngIf="!viewAction && brandStatus?.invalid && (brandStatus.dirty || brandStatus.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="brandStatus.errors.required">
                                            Brand Status is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div class="form-group row">
                            <label class=" f-13 col-sm-4">Legal Line</label>
                            <div class="col-sm-8">
                                <textarea name="pnlDescription" rows="{{(brand.pnlDescription?.length / 25)>2?(brand.pnlDescription?.length / 25):2}}"
                                matTooltip="&#8226; Type (c) for Copyright Symbol © &#13;&#8226; Type (r) for Registered Symbol ® &#13;&#8226; Type (tm) for Trademark Symbol ™" 
                                matTooltipPosition="left" matTooltipClass="bg-dark allow-cr f-14"
                                [ngModel]="brand.pnlDescription | replace:'\\(c\\)':'&#169;' | replace:'\\(r\\)':'&#174;' | replace:'\\(tm\\)':'&#8482;'| replace:'\\(C\\)':'&#169;' | replace:'\\(R\\)':'&#174;' | replace:'\\(TM\\)':'&#8482;'" 
                                (ngModelChange)="brand.pnlDescription=$event" class="form-control form-control-sm" #pnlDescription="ngModel"
                                    [disabled]="!editable" [ngClass]="{'is-invalid':pnlDescription.touched && pnlDescription.errors}"
                                    maxlength="1000"></textarea>
                                <div *ngIf="pnlDescription?.invalid && (pnlDescription.dirty || pnlDescription.touched)" class="invalid-feedback">
                                    <div *ngIf="pnlDescription.errors.required">
                                    Legal Line is required.
                                    </div>
                                </div>
                            </div>
                        </div>



                            <div class="form-group row">

                                <label class=" f-13 col-sm-4">Licensor</label>
                                <div class="col-sm-8"  *ngIf="!editable">
                                    <input name="Licensor" value="{{brand.licensorName}}" class="form-control form-control-sm"
                                        [disabled]="!editable">
                                </div>
                                <div class="col-sm-8" *ngIf="editable">
                                    <select class="form-control form-control-sm custom-select" [(ngModel)]="brand.licensorName"
                                        name="Licensor" #licensorVar="ngModel" [disabled]="!editable" (change)="onLicensorChangeByUser()"
                                        required   [ngClass]="{'is-invalid':licensorVar.touched && licensorVar.errors}">
                                        
                                        <option *ngFor="let licensor of licensorList" [ngValue]="licensor.licensorName">
                                            {{licensor.licensorName}}</option>
                                    </select>
                                    <div *ngIf="!viewAction &&  licensorVar?.invalid && (licensorVar.dirty || licensorVar.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="licensorVar?.errors.required">
                                            Licensor Code is required.
                                        </div>
                                    </div>
                                </div>
                            </div>

                        <div class="form-group row">
                            <label class=" f-13 col-sm-4">Photo Approval Required?</label>
                            <div class="col-sm-8">
                                <select class="form-control form-control-sm custom-select" [(ngModel)]="brand.photoApprovalRequired"
                                    name="PhotoApprovalRequired" #photoApprovalRequired="ngModel" [disabled]="!editable" required
                                    [ngClass]="{'is-invalid':photoApprovalRequired.touched && photoApprovalRequired.errors}">
                                    <option value=true>Yes</option>
                                    <option value=false>No</option>

                                </select>
                                <div *ngIf="!viewAction && photoApprovalRequired?.invalid && (photoApprovalRequired.dirty || brandStatus.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="photoApprovalRequired.errors.required">
                                        Photo Approval is required.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class=" f-13 col-sm-4">Licensing Pre Pro Approval Required?</label>
                            <div class="col-sm-8">
                                <select class="form-control form-control-sm custom-select" [(ngModel)]="brand.licensingPreProApprovalRequired"
                                    name="LicensingPreProApprovalRequired" #licensingPreProApprovalRequired="ngModel" [disabled]="!editable" required
                                    [ngClass]="{'is-invalid':licensingPreProApprovalRequired.touched && licensingPreProApprovalRequired.errors}">
                                    <option value=true>Yes</option>
                                    <option value=false>No</option>

                                </select>
                                <div *ngIf="!viewAction && licensingPreProApprovalRequired?.invalid && (licensingPreProApprovalRequired.dirty || brandStatus.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="licensingPreProApprovalRequired.errors.required">
                                        Photo Approval is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="form-group row" *ngIf="brand?.category==='Sports'">
                            <label class="col-sm-4 f-13">Pantone Colors</label>
                            <div class="col-sm-8">
                                
                            <textarea name="pantoneColors"  [rows]="getEnterCount(varTest.value,10)"  [(ngModel)]="brand.pantoneColors"
                                  class="form-control form-control-sm " [disabled]="!editable" maxlength="200" 
                                  #pantoneColorsVar="ngModel" #varTest>
                                  </textarea>
                                  
                            </div>
                            <!--[style.height]="varTest.scrollHeight+'px'"    rows="getEnterCount()"-->
                         </div>
                    </div>
                </mat-tab>
                <mat-tab *ngIf="brand.id">
                    <ng-template mat-tab-label>
                        <h5>PNL Speciality</h5>
                    </ng-template>
                    <div class="ml-1 mt-2 overflow-hidden">
                        <div class="mt-3 f-13">
                            <ng-container *ngIf="editable">
                                <button type="button"  class="btn btn-sm btn-info ml-1" (click)="addNewPnlSPeciality()">Add</button>
                            </ng-container>
                            <ng-container *ngIf="brand.brandPnlSpecialities">
                                <table class="table table-sm table-striped table-bordered table-condensed f-11">
                                    <thead class="text-white bg-secondary">
                                        <tr class="f-11">
                                           <th class="align-middle text-center text-nowrap">Speciality Name</th>
                                           <th class="align-middle text-center text-nowrap">PNL File</th>
                                           <th class="align-middle text-center text-nowrap">Status</th>
                                           
                                        </tr>
                                     </thead>
                                     <tbody>
                                        <ng-container *ngFor="let pnlSpeciality of brand.brandPnlSpecialities; index as i">
                                            <tr *ngIf="!pnlSpeciality.deleteFlag">
                                            <td class="align-middle text-center text-nowrap" style="width: 50%;">
                                                <input name="dn_{{brand.id}}_{{i}}" [(ngModel)]="pnlSpeciality.displayName" required class="form-control form-control-sm"
                                                    maxlength="40" [disabled]="!editable || pnlSpeciality.id" style="font-size: 11px !important;">
                                            </td>
                                            <td class="align-middle text-center text-nowrap" style="width: 40%;">
                                                <select class="form-control form-control-sm f-11" name="fp_{{brand.id}}_{{i}}" [disabled]="!editable"
                                                [(ngModel)]="pnlSpeciality.fileName" style="font-size:11px;" required>
                                                        <ng-container>
                                                            <ng-container *ngFor="let pnl of brand.pnls">
                                                                <option [value]="pnl.originalFileName" *ngIf="pnl.id" style="font-size: 11px !important;">{{pnl.originalFileName}}</option>
                                                            </ng-container>
                                                            
                                                        </ng-container>
                                                </select>
                                            </td>
                                            <td  *ngIf="!pnlSpeciality.id">
                                                <ng-container>
                                                    <div class="d-flex align-items-center justify-content-end col">
                                                        <span class="ml-1 mr-1 text-danger" style="cursor: pointer;"
                                                        (click)="pnlSpeciality.deleteFlag=true" *ngIf="editable"><i
                                                        class="fas fa-trash fa-1x"></i></span>
                                                    </div>
                                                </ng-container>
                                            </td>
                                            <td class="align-middle text-center text-nowrap" *ngIf="pnlSpeciality.id">
                                                <div class="">
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" class="custom-control-input" name="status_sp_{{brand.id}}_{{i}}" id="status_sp_{{brand.id}}_{{i}}" [(ngModel)]="pnlSpeciality.status" [disabled]="!editable">
                                                        <label class="custom-control-label custom-control-label-toggle" for="status_sp_{{brand.id}}_{{i}}">&nbsp;</label>
                                                    </div>
                                                    <!--<div> 
                                                        <label class="switch  d-flex justify-content-end" style="margin-bottom: 0px;">
                                                            <input type="checkbox" [(ngModel)]="pnlSpeciality.status" [disabled]="!editable" name="status_sp_{{brand.id}}_{{i}}" class="pull-left" >
                                                            <span class="slider round"></span>
                                                            </label>
                                                    </div>-->
                                                </div>
                                            </td>
                                            
                                            </tr>
                                        </ng-container>
                                     </tbody>  
                                </table>
                                
                            </ng-container>
                         </div>
                    </div>
                    
                </mat-tab> 
                <mat-tab>
                    <ng-template mat-tab-label>
                        <h5>Files</h5>
                    </ng-template>
                    <div class="ml-1 mt-2 overflow-hidden">
                        <div class="mt-3 f-13">
                            <app-attachment-operations  [header]="'Brand Files'" [editable]="editable" 
                            [createAction]="createAction" [attachments]="brand.brandFiles" 
                            [canUpload]="userAccess?.canUploadBrandFiles" [canDownload]="userAccess?.canDownloadBrandFiles" 
                            [canDelete]="userAccess?.canDeleteBrandFiles" [category]="'BRAND_FILES'" [showHeader]="false"
                            ></app-attachment-operations>
                         </div>
                    </div>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                      <h5>Trends</h5>
                  </ng-template>
                  <div class="ml-1 mt-2 overflow-hidden">
                      <div class="mt-3 f-13">
                          <app-attachment-operations  [header]="'Brand Trend Files'" [editable]="editable" 
                          [createAction]="createAction" [attachments]="brand.brandTrendFiles" 
                          [canUpload]="userAccess?.canUploadBrandTrendFiles" [canDownload]="true" 
                          [canDelete]="userAccess?.canDeleteBrandTrendFiles" [category]="'BRAND_TREND_FILES'" [showHeader]="false"
                          ></app-attachment-operations>
                       </div>
                  </div>
              </mat-tab>                
            </mat-tab-group>
                            
                
                <div class="row">
                    <div class="mb-4 col-sm-4"></div>
                    <div class="mb-4 col-sm-8">
                        <button type="button" (click)="createOrUpdateBrand()" class="btn btn-info btn-block" *ngIf="editable && createAction"
                            [disabled]="!contactForm.valid">Submit</button>
                        <!--<button type="submit" class="btn btn-info btn-block" *ngIf="editable && !createAction"
                            [disabled]="!contactForm.valid">Save</button>
                        <button type="button" class="btn btn-dark btn-block" *ngIf="editable && !createAction"
                            [disabled]="!contactForm.valid" (click)="cancelEdit()">Cancel
                        </button>-->
                        <div class="floatBtn d-flex flex-column">
                            <button type="button" [routerLink]="['/brand/create/null']"  class="btn btn-circle mb-2"
                             *ngIf="viewAction && userAccess?.canCreateBrand" matTooltip="Create New Brand" matTooltipPosition="left"
                            matTooltipClass="bg-dark">
                           <i class="fas fa-plus fa-lg text-light"></i>
                            </button>
                            <button type="button" (click)="createOrUpdateBrand()" class=" btn btn-circle bg-info mb-2" *ngIf="editable && !createAction"
                            matTooltip="Save Changes" matTooltipPosition="left" matTooltipClass="bg-dark" [disabled]="!contactForm.valid">
                                <i class="fas fa-save fa-2x" style="color:white"></i>
                            </button>
                            <button type="button" class=" btn btn-circle bg-dark" *ngIf="editable && !createAction" matTooltip="Cancel Edit" matTooltipPosition="left" matTooltipClass="bg-dark"
                             (click)="cancelEdit()"><i class="fas fa-times fa-2x"
                                    style="color:white"></i>
                            </button>
                            <a class=" btn btn-circle bg-dark mb-2" (click)="edit()"   *ngIf="viewAction && userAccess?.canEditBrand" matTooltip="Edit" matTooltipPosition="left" matTooltipClass="bg-dark"> <i class="fas fa-pencil-alt fa-lg" style="color: white;"></i></a>
                            <a (click)="goBack()" class=" btn btn-circle" style="background-color: tomato;" *ngIf="viewAction || createAction" matTooltip="Exit" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-arrow-left  fa-2x"></i></a><!--fa-file-pdf-->
                            
                        </div>
                    
                    </div>
                </div> 
                
            </div>
            <div class="mt-4"></div>
        </div>

        <!--Product List components starts-->
        <div class="row mt-5 shadow-lg p-3 mb-5 bg-white rounded" *ngIf="brand.id && showProducts">
            <app-product-list [showSideBar]="false" [brandSku]="brand.skuCode" [searchCriteriaOverrideForBrand]="brand"></app-product-list>
        </div>
        <!--Product List components starts-->
        

        <!--
        <div class="row mt-5 shadow-lg p-3 mb-5 bg-white rounded" *ngIf="brand.id">
            <div class="col-lg-12">
                <div class="row mt-2 d-flex justify-content-center">
                        <h3 class="d-inline">Products for {{brand.brandName}}</h3>
                        <button type="button" [routerLink]="['/createProduct/create/'+brand.licensorId+'/'+brand.id+'/'+brand.skuCode]"
                        class="btn btn-circle btn-circle-md d-inline ml-4" *ngIf="userAccess.canCreateProduct" matTooltip="Create New Product" matTooltipPosition="right" matTooltipClass="bg-dark"><i class="fas fa-plus fa-lg text-light"></i></button>
                </div>
                <hr>
                <button class="btn btn-sm btn-dark mb-1" *ngIf="!editable" type="button" (click)="showHideProducts()">Show/Hide Products</button>
                <div *ngIf="showProducts">
                    <div class="row">
                        <div class="col-sm-6">
                            <input class="form-control mb-2" #search placeholder="Filter by SKU.." (keyup)="onKeyupProductFilter($event,search.value)">
                        </div>
                        <div class="col-sm-6 mt-2">
                            <p>Found {{filteredProductList?.length}} {{search.value.length>0? 'Products found for "'+search.value+'"' : 'Products'}}</p>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-4"
                                *ngFor="let product of filteredProductList">
                                <app-product-image-card [product]="product" [userAccess]="userAccess"  (onDelete)="loadBrand(brand.id)"></app-product-image-card>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
        -->
    </div>


      <!--
                MODAL for adding metadata like color, garment, treatment etc.       
        -->
   <div class="modal fade" id="addNewMetadataModal" data-keyboard="false" data-backdrop="static"  tabindex="-1" role="dialog" aria-labelledby="addMetadataModalTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header"
                    style="padding-top: 0.25rem; padding-bottom: 0.25rem; padding-left: 0.50rem; padding-right: 0.50rem;">
               <h5 class="modal-title" style="font-weight: bold;">Add {{metadata?.metadataType}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                  (click)="closeMetadataModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
               <input name="metadataValue" class="form-control mb-2" type="text" #metadataInput
                  placeholder="Enter Value..." [(ngModel)]="metadata.metadataValue">
                </div> 
                <div class="modal-footer"
                    style="padding-top: 0.25rem; padding-bottom: 0.25rem; padding-left: 0.50rem; padding-right: 0.50rem;">
               <button type="button" id="metadataModalCloseButton" class="btn btn-secondary btn-sm" data-dismiss="modal"
                  (click)="closeMetadataModal()">Close</button>
                    <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal"
                  [disabled]="!metadataInput.value" (click)="saveMetadata()">Save</button>
                </div>
            </div>
        </div>
    </div>

   <button id="metadataModalToggler" type="button" style="display: none;" data-toggle="modal"
      data-target="#addNewMetadataModal"></button>

      <div *ngIf="showEmailEditor">
        <app-compose-email  [modalId]="'composeEmail'" [emailHeader]="'Email'" [composeEmail]="composeEmail" ></app-compose-email>
        </div>
</form>