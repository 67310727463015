import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  readonly rooturl = environment.apiEndPoint;

  getSalesDashBoardDetails(){
    return this.http.get(this.rooturl + "/dashboard/sales/getSalesDashBoardDetails");
  }

  getCostingDashBoardDetails(){
   return this.http.get(this.rooturl + "/dashboard/costing/getCostingDashBoardDetails");
 }

 getCostingPendingReasonDashBoardDetails(){
   return this.http.get(this.rooturl + "/dashboard/costing/getCostingPendingReasonDashBoardDetails");
 }

 getDashBoardTables(){
   return this.http.get(this.rooturl + "/dashboard/costing/getDashBoardDetails/table");
 }

 getDashboard(dashboardName:string){
   return this.http.get(this.rooturl + "/dashboard/"+dashboardName);
 }

 getDashboardConfig(dashboardId:string){
   return this.http.get(this.rooturl + "/dashboard/config/"+dashboardId);
 }

 getDashlet(dashletId:string, dashletType: string){
   return this.http.get(this.rooturl + "/dashlet/"+dashletId+"/"+dashletType);
 }

}
