<form name="notesForm" #notesForm="ngForm" autocomplete="off">

   <mat-dialog-content class="mt-2">
      <ng-container *ngIf="salesOrder && orderDetail">
         <app-order-detail [salesOrder]="salesOrder" [orderDetail]="orderDetail" [editable]="false" [createAction]="false" [orderDetailIndex]="orderDetailIndex" [colspanIncrementCount]="1"
            [showSubOrderDetailPoCustomer]="true" [collapseOnInit]="false"></app-order-detail>
      </ng-container>
   </mat-dialog-content>

   <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close class="btn btn-dark">Close</button>
    </mat-dialog-actions>

</form>