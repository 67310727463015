<form name="forgotpassword" (ngSubmit)="onFormSubmit()" #forgotPasswordForm="ngForm" autocomplete="off" class="form-signin">
  <div class="container" style="display: flex;justify-content: center;align-items: center;height: 100vh;">
    <div class="card shadow" style="width: 30rem;">

      <div class="card-body">
        
        <div class="text-center mb-4">
          <img class="mb-4 round" src="{{'loginBrandIcon'|env}}" alt="Icon" style="border-radius: 50%;" width="136px" height="136px">
          <h1 class="h2 mb-2 font-weight-normal">Forgot Password</h1>
        </div>

        <div class="card-text">
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-envelope"></i> </span>
            </div>
              <input name="email" id="email" [(ngModel)]="user.email" placeholder="Email"  class="form-control" #email="ngModel" 
               required [ngClass]="{'is-invalid':email.touched && email.invalid}" email>
              <div *ngIf="email.invalid" class="invalid-feedback">
                <div *ngIf="email.errors.required">
                  Email address is required.
                </div>
                <div *ngIf="email.errors.email">
                  Email must be a valid email address.
                </div>
              </div>
          </div>

          <button class="btn btn-md btn-primary" [disabled]="forgotPasswordForm.invalid">Submit</button>
          <a [routerLink]="['/login']" class="btn btn-link btn-sm">Cancel</a>

        </div>
      </div>
    </div>
  </div>
</form>
