import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { ActivatedRoute } from '@angular/router';
import { PdfFormService } from 'src/app/shared/service/pdf-form.service';
import { PdfForm } from 'src/app/shared/model/pdf-form.model';
import { ServiceStatus } from 'src/app/shared/model/service-status.model';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
  selector: 'app-pdf-form-list',
  templateUrl: './pdf-form-list.component.html',
  styleUrls: ['./pdf-form-list.component.css']
})
export class PdfFormListComponent implements OnInit {

  constructor(private sharedService: SharedService,private authService:AuthService, private service: PdfFormService, 
    private activatedRoute: ActivatedRoute,private toastrService:ToastrService) { }

 groupedItemsList: any;
 itemList: any[] = [];
 filteredItemList: any[] = [];
 userAccessSubscription: Subscription;
 userAccess: UserAccess;
 filterEventsubscription: Subscription;
 editable: boolean;
 

 ngOnInit() {
    this.handleSubscriptions();
    this.loadItems();
    window.scrollTo(0, 0);
 }

 handleSubscriptions() {
    this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
       this.userAccess = access;
    });
    this.filterEventsubscription = this.authService.filterEvent.subscribe(filterEventData => {
       if (filterEventData != null) {
          this.onKeyupBrandFilter(filterEventData.event, filterEventData.value);
       }
    });
 }

 onKeyupBrandFilter(event, value) {
    if (event.key !== 'Shift') {
       if (value.trim().length > 0) {
          this.filteredItemList = this.itemList.filter(items => items.name.toUpperCase().indexOf(value.toUpperCase()) > -1);
       } else {
          this.filteredItemList = this.itemList;
       }
    }
    this.groupList();
 }

 loadItems() {
  this.service.loadPdfForms().subscribe((res: any[]) => {
     this.itemList = res;
     this.filteredItemList = this.itemList;
     this.filteredItemList =this.sharedService.sortListByPropertyName(this.filteredItemList,'name');
     this.groupList();
  }, err => {
     console.error('Load Item List Error : ' + JSON.stringify(err) + ' with status - ');
  });
}

 deleteItem(item: any) {
 }

 /**
  * This method groups the list.
  */
 groupList() {
    let grouped = this.filteredItemList.reduce((groups, item) => {
       const letter = item['name'].charAt(0).toUpperCase();
       groups[letter] = groups[letter] || [];
       groups[letter].push(item);
       return groups;
    },
       {}
    );
    let groupedList = Object.keys(grouped).map(key => ({ key, items: grouped[key] }));
    this.groupedItemsList = groupedList;
 }
 deletePdfFrom(pdfForm:PdfForm){
    if(confirm('Are you sure you want to delete the PDF Form. The fillable PDF form will be lost')){
       this.service.deletePdfForms(pdfForm.id).subscribe((res)=>{
         let serviceStatus:ServiceStatus=res as ServiceStatus;
         if(serviceStatus.status==='SUCCESS'){
            this.loadItems();
            this.toastrService.success("Successfully deleted the pdf form - "+pdfForm.name);
         }else{
            this.toastrService.error("Error during deleting the pdf form - "+pdfForm.name);
         }
       });
    }
 }

}
