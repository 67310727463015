import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchComponent } from 'src/app/common/search/search.component';
import { SearchDetails } from 'src/app/shared/model/common/search-details.model';
import { FilterConfig } from 'src/app/shared/model/common/search-filter.model';
import { Customer } from 'src/app/shared/model/customer.model';
import { ProductionTracker } from 'src/app/shared/model/sales/fulfillment/production-tracker.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { CustomerService } from 'src/app/shared/service/customer.service';
import { SalesHelperService } from 'src/app/shared/service/sales/sales-helper.service';
import { SearchService } from 'src/app/shared/service/search.service';
import { Task } from 'src/app/shared/model/task/task.model';
import { TaskService } from 'src/app/shared/service/task/task.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { SelectFromListDialogComponent } from 'src/app/common/select-from-list-dialog/select-from-list-dialog.component';
import { UserManagementService } from 'src/app/shared/service/user-management.service';
import { TaskDetails } from 'src/app/shared/model/task/details/task-details.model';

@Component({
   selector: 'app-task-list',
   templateUrl: './task-list.component.html',
   styleUrls: ['./task-list.component.css']
})
export class TaskListComponent implements OnInit {

   constructor(private searchService: SearchService, private authService: AuthService, private customerService: CustomerService, 
      private salesHelperService: SalesHelperService, private taskService: TaskService,  private toastrService: ToastrService,
      private dialog: MatDialog,private userService:UserManagementService) { }

   userAccess: UserAccess;
   readyForInitSearch: boolean = false;
   currentLoggedInUser: User;
   taskList: Task[] = [];
   searchConfig: SearchDetails;
   polling:any;
   selectedTab: number=0;
   userList:User[]=[];
   taskSelectedCount:number=0;

   sortColumns = [
      { columnName: 'NAME', sortByName: 'Task Name' },
      { columnName: 'DESCRIPTION', sortByName: 'Task Description' },
      { columnName: 'CREATED_ON', sortByName: 'Created On' },
      { columnName: 'PLANNED_END_DATE', sortByName: 'Due On' },
   ];

   @ViewChild('searchComponent') searchComponent: SearchComponent<Task>;

   async ngOnInit() {
      console.log('ng Init Called...');
      this.getUserList();
      this.handleSubscriptions();
      await this.handleSearchOnInit();
      //this.startReloadingPageInRegularInerval(10000);
   }
   getUserList() {
      
         this.userService.getUsersByRoleId('PRODUCTION_USER').subscribe(response => {
            this.userList = response.responsePayload;
         });
      
   }
   ngOnDestroy() {
      if (this.polling) {
        clearInterval(this.polling);
      }
    }
   startReloadingPageInRegularInerval(intervalMilliSeconds: number) {
      this.polling=setInterval(()=>{
         console.log('Searching after interval');
         this.search();
      }, intervalMilliSeconds);
   }



   handleSubscriptions() {
      this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
      this.authService.currentUser.subscribe(async currentLoggedInUser => {
         if (!this.currentLoggedInUser && currentLoggedInUser && currentLoggedInUser !== null) {
            this.currentLoggedInUser = currentLoggedInUser;
            console.log(this.currentLoggedInUser);
            await this.handleSearchOnInit();
         }
      });

   }


   search() {
      if (this.searchComponent) {
         this.searchComponent.search();
      }
   }

   async handleSearchOnInit() {
      //await this.getVendorList();
      //console.log('Call Get vendor');
      //await this.getCustomers();
      //console.log('Call Get Customer');
      //await this.getSalesUserList();
      //console.log('Call Get SalesUser list');
      if (!this.searchConfig) {
         this.searchConfig = this.searchService.taskListSearchDetails;
         this.setFilterConfigs();
         //this.search();
         /*this.searchConfig.setFilterInList('CLIENTID','String',[]);
         this.searchConfig.setFilterInList('WHPID','String',[]);
         this.searchConfig.searchCriteria.searchSubType='INVENTORY_LEVEL_2';*/
      }
   }


   selectedTabChange(event: any) {
      switch (event.tab.textLabel) {
         case 'active':
            break;
         default:
            break;
      }
   }

   async saveTask(task:Task){
   await this.taskService.saveTaskDetails(task).toPromise().then(response=>{
      if (response.responseStatus.status === 'SUCCESS') {
         this.toastrService.success(response.responseStatus.message);
         this.search();
      } else {
         this.toastrService.error(response.responseStatus.message);
      }
   });
   }

   async completeTask(task: Task) {
      let workItemsStatusValid:boolean=true;
      if(task.taskDetails && task.taskDetails.taskWorkItems && task.taskDetails.taskWorkItems.length>0){
         task.taskDetails.taskWorkItems.forEach(wi => {
            if(wi.eligibleStatuses && wi.eligibleStatuses.length>0 && wi.eligibleStatuses.includes(wi.status)){
               workItemsStatusValid=false;
            }
         });
      }
      await this.saveTask(task);
      if(!workItemsStatusValid){
         this.toastrService.error("Please complete all work items before completing the task");
         return;
      }
      this.taskService.completeTask(task).subscribe(response => {
         console.log('Complere Response', response);
         if(response){
            if ( response.responseStatus.status === 'SUCCESS') {
               this.toastrService.success(response.responseStatus.message);
               this.search();
            } else {
               this.toastrService.error(response.responseStatus.message);
            }
         }
      }, err => {
         console.error('Error while Completing Tasks : ' + JSON.stringify(err) + ' with status - ');
      });
   }

   setFilterConfigs() {
      if (!this.searchConfig.filterConfigs) {
         this.searchConfig.filterConfigs = [];

         let taskStatusFilterConfig: FilterConfig = {
            location:'INLINE',
            filterType: 'IN_LIST',
            label: 'Task Status',
            currentSelection: 'ACTIVE',
            options: [{value:'ACTIVE',display:'Active'},{value:'COMPLETE',display:'Complete'},{value:'CANCELLED',display:'Cancelled'}],
            optionDislpayVar:'display',
            optionValueVar:'value',
            fieldName: 'STATUS'
         };
         this.searchConfig.filterConfigs.push(taskStatusFilterConfig);
         

      }
     
   }

   showHideTaskDetails(task:Task){
      if(!task.expanded){
         task.expanded=true;
         this.taskService.loadTaskDetails(task.id).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               task.taskDetails=response.responsePayload;
            } else {
               this.toastrService.error(response.responseStatus.message);
            }
         }, err => {
            console.error('Error while Completing Tasks : ' + JSON.stringify(err) + ' with status - ');
         });
      }else{
         task.expanded=false;
      }
   }

   

   reassignTaskList(){
         let selectedTaskList=this.taskList.filter(x=>x.selected);
         if(selectedTaskList.length===0){
            alert('Please select one or more tasks to Re-assign.');
            return;
         }
         //let selectableOptions:any[]=[{key:"ACAS",desc:"Add Selected Combination to All Styles",value:false}];
         const confirmDialog = this.dialog.open(SelectFromListDialogComponent, {
           disableClose: false,
           data: {
             title: 'Re-Assign Task(s) to:',
             modalType: 'SINGLE_SELECT',
             cancelBtnText: 'Cancel',
             confirmBtnText: 'Re-Assign Task(s)',
             message: undefined,
             selectionList: this.userList,
             displaySelectionTitle: 'User',
             displayOptionvar:'fullName'
             //selectableOptions:selectableOptions
           }
         });
         confirmDialog.afterClosed().subscribe(async result => {
           if (result && result.length>0) {
            await this.reAssignSelectedTaskToUser(result[0]);
            this.search();
           } else {
           }
         });
       
   }
   async reAssignSelectedTaskToUser(selectedUserForReassignment: User) {
      let selectedTaskList=this.taskList.filter(x=>x.selected);
      for (const task of selectedTaskList) {
         task.assignedToIndividual = selectedUserForReassignment.userName;
         await this.taskService.reassignTask(task).toPromise().then(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.toastrService.success(response.responseStatus.message);
            } else {
               this.toastrService.error(response.responseStatus.message);
            }

         }, err => {
            this.toastrService.error(err);
         });
      }
   }

   taskSelected(task:Task){
      if(task.selected){
         this.taskSelectedCount=this.taskSelectedCount+1
      }
      else{
         this.taskSelectedCount=this.taskSelectedCount-1;
         if(this.taskSelectedCount<0){
            this.taskSelectedCount=0;
         }
      }
   }

   
}
