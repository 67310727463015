import { Brand } from './brand.model';
import { Customer } from './customer.model';

export class BrandRights {
   id: string;
   licensorId: string;
   licensorName: string;
   brandId: string;
   brandName: string;
   notes: string;
   contractStartDate: Date;
   contractEndDate: Date;
   sellOffDate: Date;
   territory: string;
   ageCategories: string;
   productCategories: string;

   ageCategoryList: string[];
   productCategoryList: string[];

   brandList: Brand[];
   editable: boolean = false;

   allCustomersRestricted: boolean = false ;
	allCustomersPermitted: boolean = true;
   exceptionCustomers: string ;
   exceptionCustomerList : Customer[];
   hasError: boolean;

   constructor() {
      this.licensorId = null;
      this.brandId = null;
      this.notes = null;
      this.contractStartDate = null;
      this.contractEndDate = null;
      this.sellOffDate = null;
      this.territory = null;
      this.ageCategories = null;
      this.productCategories = null;
      this.ageCategoryList = [];
      this.productCategoryList = [];

      this.brandList = [];
      this.editable = false;

      this.exceptionCustomers= null;
      this.exceptionCustomerList = [];

      this.hasError=false;

   }
}
