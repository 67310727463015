import { Component, OnInit, Input, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { ComposeEmail, EmailAttachment } from 'src/app/shared/model/compose-email.model';
import { ComposeEmailService } from 'src/app/shared/service/compose-email.service';
import { ToastrService } from 'ngx-toastr';
import { Editor } from "primeng/editor";
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
   selector: 'app-compose-email',
   templateUrl: './compose-email.component.html',
   styleUrls: ['./compose-email.component.css']
})

export class ComposeEmailComponent implements OnInit {

   constructor(private metadataService:MetadataService, private composeEmailService: ComposeEmailService, private toastrService: ToastrService) { }


   config: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: '15rem',
      minHeight: '20rem',
      //placeholder: 'Enter text here...',
      translate: 'no',
      defaultParagraphSeparator: 'p',
      defaultFontName: 'Arial',
      sanitize: false,
      outline: false,
      toolbarHiddenButtons: [
         //['insertImage','insertVideo','toggleEditorMode']
         ['insertVideo','toggleEditorMode']
      ],
   };

   usersEmailList: string[];
   filteredEmailToList: string[];
   filteredEmailCcList: string[];
   filteredEmailBccList:string[];
   showAttachmentAsLinkCheckBox:boolean=false;

   @Input() forceHideAttachmentAsLinkCheckBox:boolean=false;

   @Input() modalId: string;
   @Input() composeEmail: ComposeEmail;
   @Input() emailHeader: string;
   @Output() onSend = new EventEmitter<ComposeEmail>();

   @Input() showRichTextEditor: boolean = true;
   @Input() showHtmlEditor: boolean = false;

   @ViewChild(Editor) editorComponent: Editor;

   emailTemplate: string;

   emailTo: string;
   emailCc: string;
   emailBcc: string;


   ngOnInit(): void {
      this.getUsersEmailList();
      this.loadMetadata();
      //this.getEmailTemplate();
   }

   loadMetadata() {
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('SYSTEM', 'COMPOSE_EMAIL', 'CONFIG').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            res.responsePayload.forEach(element => {
               if(element==="SHOW_ATTACHMENT_AS_LINK_CHECKBOX"){
                  this.showAttachmentAsLinkCheckBox=true;
               }
            });
         }
      });
   }
  /* ngAfterViewInit() {
      // this hook gets called after the view has been fully initialized, and
      // should set the cursor position immediately before the word 'World'
      let selection: any = this.editorComponent.getQuill().getSelection(0);
      this.editorComponent.getQuill().focus();
   }*/

   public onEditorInit(event: any) {
      //event.editor.editor.insertText(0, 'ABCD');
      //event.editor.root.autofocus = true;
     // event.editor.selection
     /* let selection: any = this.editorComponent.getQuill().getSelection(0);
      this.editorComponent.getQuill().focus();
      event.editor.root.focus();*/
      //event.editor.root.setSelection(0);
   }

   closeModal() {
   }

   getUsersEmailList() {
      this.composeEmailService.getUsersEmailList().subscribe(response => {
         this.usersEmailList = response.responsePayload;
         this.filteredEmailToList = this.usersEmailList;
         this.filteredEmailCcList = this.usersEmailList;
      });
   }

   getEmailTemplate() {
      this.composeEmailService.getEmailTemplate(null).subscribe(response => {
         this.emailTemplate = response.responsePayload;
         this.composeEmail.emailContent = this.emailTemplate;
      });
   }

   removeAttachment(event: any, attachmentToRemove: EmailAttachment) {
      if (event.target.checked) {
         attachmentToRemove.attachmentSelected = true;
      } else {
         attachmentToRemove.attachmentSelected = false;
      }
   }

   checkIfItemExists(list: EmailAttachment[], item: EmailAttachment) {
      if (list) {
         let temp = list.filter(itemInList => itemInList.attachmentdId === item.attachmentdId);
         return (temp.length == 1);
      } else {
         return false;
      }
   }

   addToRecipient(emailAddress: string) {
      if (!this.checkIfEmailExists(this.composeEmail.emailTo, this.emailTo)) {
         this.composeEmail.emailTo.push(this.emailTo);
      }
      this.filteredEmailToList = this.usersEmailList;
      this.emailTo = null;
   }

   removeToRecipient(itemToRemove: string) {
      this.composeEmail.emailTo = this.composeEmail.emailTo.filter(item => item != itemToRemove);
      this.filteredEmailToList = this.usersEmailList;
   }

   addCcRecipient(emailAddress: string) {
      if (!this.checkIfEmailExists(this.composeEmail.emailCc, this.emailCc)) {
         this.composeEmail.emailCc.push(this.emailCc);
      }
      this.filteredEmailCcList = this.usersEmailList;
      this.emailCc = null;
   }

   removeCcRecipient(itemToRemove: string) {
      this.composeEmail.emailCc = this.composeEmail.emailCc.filter(item => item != itemToRemove);
      this.filteredEmailCcList = this.usersEmailList;
   }

   addBccRecipient(emailAddress: string) {
      if (!this.checkIfEmailExists(this.composeEmail.emailBcc, this.emailBcc)) {
         this.composeEmail.emailBcc.push(this.emailBcc);
      }
      this.filteredEmailBccList = this.usersEmailList;
      this.emailBcc = null;
   }

   removeBccRecipient(itemToRemove: string) {
      this.composeEmail.emailBcc = this.composeEmail.emailBcc.filter(item => item != itemToRemove);
      this.filteredEmailBccList = this.usersEmailList;
   }

   checkIfEmailExists(list: string[], itemToCheck: string) {
      if (list) {
         let temp = list.filter(itemInList => itemInList === itemToCheck);
         return (temp.length == 1);
      } else {
         return false;
      }
   }

   sendEmail() {
      this.composeEmailService.sendEmail(this.composeEmail).subscribe(response => {
         let sendMailStatus: boolean = response.responsePayload;
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.onSend.emit(this.composeEmail);
            document.getElementById('closeEmailModal').click();

         } else {
            console.error("Compose Email Component - compose email send failed...", response.responseStatus.message);
            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });

   }

   onKeyupEmailToFilter(event, value, invalidInput:boolean) {
      if((event.key==='Enter' || event.type==='blur') && !invalidInput){
         this.addToRecipient(this.emailTo);
      }else if (event.key !== 'Shift') {
         if (value?.trim().length > 0) {
            this.filteredEmailToList = this.usersEmailList.filter(items => items.toUpperCase().indexOf(value.toUpperCase()) > -1);
         } else {
            this.filteredEmailToList = this.usersEmailList;
         }
      }
   }

   onKeyupEmailCcListFilter(event, value,invalidInput:boolean) {
      if((event.key==='Enter' || event.type==='blur') && !invalidInput){
         this.addCcRecipient(this.emailCc);
      }else if (event.key !== 'Shift') {
         if (value?.trim().length > 0) {
            this.filteredEmailCcList = this.usersEmailList.filter(items => items.toUpperCase().indexOf(value.toUpperCase()) > -1);
         } else {
            this.filteredEmailCcList = this.usersEmailList;
         }
      }
   }

   onKeyupEmailBccListFilter(event, value,invalidInput:boolean) {
      if((event.key==='Enter' || event.type==='blur') && !invalidInput){
         this.addBccRecipient(this.emailBcc);
      }else if (event.key !== 'Shift') {
         if (value?.trim().length > 0) {
            this.filteredEmailBccList = this.usersEmailList.filter(items => items.toUpperCase().indexOf(value.toUpperCase()) > -1);
         } else {
            this.filteredEmailBccList = this.usersEmailList;
         }
      }
   }
}
