export class ExternalLinks {
    id:string;
    parentId:string;
    description:string;
    url:string;
    type:string;
    subType:string;
    version:number;
    deleteFlag:boolean;

constructor(type:string,subType:string){
    this.type=type;
    this.subType=subType;
}
}
