<form #confirmForm="ngForm" autocomplete="off" #formVar="ngForm">

    
<div width="50%">
    <div class="d-flex justify-content-between align-items-center ">
        <h1 mat-dialog-title>Select Size(s)</h1>
        <p>&nbsp;</p>
        <span name="discardModal" type="button" class="ml-4 close" (click)="discardModalWithNoAction()" aria-label="Close" aria-hidden="true">&times;</span>
     </div>
    <hr>
    <div mat-dialog-content>
        <div class="ml-2" *ngFor="let ordeDetailSizeQuantity of ordeDetailSizeQuantityListForModal; index as index">
            <div class="row">
               <div class="col-sm-8">
                  <label class="cb-container">
                     <h6 class="ml-4 mt-1">{{ordeDetailSizeQuantity.size}} <span *ngIf="!ordeDetailSizeQuantity.activeSize">(Inactive size)</span></h6>
                     <input type="checkbox" name="size_{{index}}" [(ngModel)]="ordeDetailSizeQuantity.selected" [disabled]="!ordeDetailSizeQuantity.selected && !ordeDetailSizeQuantity.activeSize" #sizeCheckBox>
                     <span class="checkmark"></span>
                  </label>
               </div>
            </div>
         </div>
    </div>
    <div mat-dialog-actions>
    <div style="float:right;margin: 40px 0px 10px 0px;">
        <button class="btn bg-dark text-white btn-block" type="button" (click)="sizeSelected()">Ok</button>
    </div>
    </div>
 </div>

</form>
