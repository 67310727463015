export class MetadataSize {
   constructor(status: boolean, sortOrder: number) {
      this.status = status;
      this.sortOrder = sortOrder;
   }
   id: string;
   size: string;
   shortName: string;
   description: string;
   status: boolean;
   sortOrder: number
}
