import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Licensor } from 'src/app/shared/model/licensor.model';
import { Brand } from 'src/app/shared/model/brand.model';
import { Product} from 'src/app/shared/model/product.model';
import { SearchCriteria } from 'src/app/shared/model/search-criteria.model';
import { LicensorService } from 'src/app/shared/service/licensor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/shared/service/product.service';
import { Location } from '@angular/common';
import { ProductCatalogService } from 'src/app/shared/service/product-catalog.service';
import { ProductSearchService } from 'src/app/shared/service/product-search.service';
import { BrandService } from 'src/app/shared/service/brand.service';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { EmailTemplateConfig } from 'src/app/shared/model/email-template-config.model';
import { ComposeEmail } from 'src/app/shared/model/compose-email.model';
import { ComposeEmailService } from 'src/app/shared/service/compose-email.service';
import { ServiceStatus } from 'src/app/shared/model/service-status.model';
import { ToastrService } from 'ngx-toastr';
import { ProductSearchDetails } from 'src/app/shared/model/product-search-details.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { ProductMetadataService } from 'src/app/shared/service/product-metadata.service';

declare var $:any;
@Component({
   selector: 'app-product-list',
   templateUrl: './product-list.component.html',
   styleUrls: ['./product-list.component.css'],
   animations: [
      trigger('fadeInOut', [
         state('void', style({
           opacity: 0
         })),
         transition('void => *', animate(500)),
       ]),
       trigger('EnterLeave', [
         state('flyIn', style({ transform: 'translateX(0)' })),
         transition(':enter', [
           style({ transform: 'translateX(-100%)' }),
           animate('0.5s 300ms ease-in')
         ]),
         transition(':leave', [
           animate('0.3s ease-out', style({ transform: 'translateX(100%)' }))
         ])
       ]),
       trigger('balloonEffect', [
         state('void', style({
           backgroundColor: 'green',
           transform: 'scale(0.2)'
         })),
         state('*', style({
           backgroundColor: 'red',
           transform: 'scale(1)'
         })),
         transition('void=>*', animate('1000ms')),
         transition('*=>void', animate('1500ms'))
       ]),
   ]
})
export class ProductListComponent implements OnInit {
   /*licensorFilterListForSearch: string[] = [];
   brandFilterListForSearch: string[] = [];
   tagFilterListForSearch: string[] = [];
   colorFilterListForSearch: string[] = [];
   garmentFilterListForSearch: string[] = [];
   treatmentFilterListForSearch: string[] = [];
   categoryFilterListForSearch: string[] = [];
   brandCategoryFilterListForSearch: string[] = [];
   printEffectFilterListForSearch: string[] = [];
   approvalStatusFilterListForSearch: string[] = [];
   createdByFilterListForSearch: string[] = [];*/
   searchStringForSearch: string = "";
   
   licensorListModalFiltered: string[] = [];
   brandListModalFiltered: string[] = [];
   colorListModalFiltered: string[] = [];
   garmentListModalFiltered: string[] = [];
   treatmentListModalFiltered: string[] = [];
   tagListModalFiltered: string[] = [];
   createdByListModalFiltered: string[] = [];
   approvalStatusListModalFiltered: string[] = [];
   artStatusListModalFiltered: string[] = [];
   categoryListModalFiltered:string[]=[];
   brandCategoryListModalFiltered:string[]=[];
   printEffectListModalFiltered:string[]=[];

   licensorList: string[] = null;
   brandList: string[] = null;
   productList: Product[];
   filteredProductList: Product[];
   colorList = [];
   garmentList = [];
   approvalStatusList = [];
   artStatusList = [];
   treatmentList = [];
   categoryList=[];
   brandCategoryList=[];
   printEffectList=[];
   tagList = []
   createdByList = [];

   serachCriteria: SearchCriteria;

   LoadArtError: string;
   LoadArtErrorFlag: boolean;
   selectedProductForCatalogCount: number = 0;

  userAccessSubscription:Subscription;
  userAccess:UserAccess;
  //showLatestSearch:boolean=false;
  //type1:string='COLOR';
  filterTypes:string[]=['LICENSORS','BRANDS','BRAND_CATEGORY','CATEGORY','APPROVAL_STATUS','ART_STATUS','GARMENT','COLOR','TREATMENT','PRINT_EFFECT','TAGS'];
  //Same sequence as filterTypes
  filterTypesNames:string[]=['Licensors','Brands','Brand Categories','Product Categories','Approval Status','Art Status','Garments','Colors','Treatments','Print Effects','Tags'];
  defaultDisplaySize:number=25;
  
  sortColumns = [{ columnName: 'SKU', sortByName: 'sku' },
  { columnName: 'Last Updated', sortByName: 'updated_on' },
  { columnName: 'Status', sortByName: 'approval_status' }
  ];

  selectedSortByName: string;
  sortMode: string = 'ASC';

  showEmailEditor:boolean=false;
  composeEmail: ComposeEmail = new ComposeEmail;
  totalSearchCount=0;
  showMoreDisabled: boolean;
  searchConfig:ProductSearchDetails;
  //embedded:boolean=false;
  @Input() hideFloatBtns:boolean=false;
  @Input() hideAddProduct:boolean=false;
  @Input() stopProductNavigation:boolean=false;
  @Input() stopProductDeletion:boolean=false;
  @Input() showSideBar:boolean=true;
  @Input() brandSku:string;
  @Input() enableExclusiveSelection:boolean=false;
  @Input() showAdhocProduct:boolean=false;
  

   @Input()
    set searchCriteriaOverrideForBrand(brand:Brand){
      this.searchConfig=this.productSearchService.brandProductSearchDetails;
      this.searchConfig.resetAdvSearchCriteria();
      this.searchConfig.add(brand.brandName,'BRANDS');
      this.searchConfig.add(brand.licensorName,'LICENSORS');
      this.hideFloatBtns=true;
    }
   constructor(private toastrService:ToastrService,private authService: AuthService,private router: Router,private metadataService: MetadataService, private sharedService: SharedService, private productService: ProductService, private productSearchService: ProductSearchService, private LicensorService: LicensorService, private brandService: BrandService, private productCatalogService: ProductCatalogService, private activatedRoute: ActivatedRoute, private location: Location,private composeEmailService:ComposeEmailService,
      private productMetadataService:ProductMetadataService) { }

   ngOnInit() {
      console.log('showAdhocProduct',this.showAdhocProduct);
      this.handleSubscriptions();
      if(!this.searchConfig){
         //
         this.searchConfig=this.productSearchService.productSearchDetails;
      }
      this.reloadSearchCriteriaFromService();
      this.loadProducts(false);
      this.selectedProductForCatalogCount = this.productCatalogService.selectedProductsForCatalog.length;
      window.scrollTo(0, 0);
      $('[data-toggle="tooltip"]').tooltip();
      //$('[data-toggle="popover"]').popover();
   }
   
   loadFilterItem(type:string){
      if(type==='LICENSORS'){
         this.loadLicensors();
      }else if(type==='BRANDS'){
         this.loadBrands();
      }else  if(type==='TAGS'){
         this.loadTags();
      }else  if(type==='BRAND_CATEGORY'){
         this.loadMetaDataForType('BRAND','CATEGORY');
      }
      else {
         if(type!=='COLOR' && type!=='GARMENT' && type!=='CATEGORY' && type!=='TREATMENT'){
            this.loadMetaDataForType('PRODUCT',type);
         }
      }
      
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
      this.productMetadataService.colorListObservable.subscribe(res => {
         this.colorList.length=0;
         this.colorList.push(...res);
         console.log("Subscribed Color List",this.colorList);
         this.setMasterList('COLOR',this.colorList);
         this.setFilteredMasterListForModal('COLOR',this.colorList);
         
         });
   
         this.productMetadataService.categoryListObservable.subscribe(res => {
           this.categoryList.length=0;
           this.categoryList.push(...res);
           console.log("Subscribed Category List",this.categoryList);
           this.setMasterList('CATEGORY',this.categoryList);
         this.setFilteredMasterListForModal('CATEGORY',this.categoryList);
         });
   
         this.productMetadataService.treatmentListObservable.subscribe(res => {
           this.treatmentList.length=0;
           this.treatmentList.push(...res);
           console.log("Subscribed Treatment List",this.treatmentList);
           this.setMasterList('TREATMENT',this.treatmentList);
           this.setFilteredMasterListForModal('TREATMENT',this.treatmentList);
         });
   
         this.productMetadataService.garmentListObservable.subscribe(res => {
           this.garmentList.length=0;
           this.garmentList.push(...res);
           console.log("Subscribed Garment List",this.garmentList);
           this.setMasterList('GARMENT',this.garmentList);
           this.setFilteredMasterListForModal('GARMENT',this.garmentList);
         });
   }

   reloadSearchCriteriaFromService() {
      if(this.showAdhocProduct){
         this.searchConfig.excludeAdhocProduct=false;
      }else{
         this.searchConfig.excludeAdhocProduct=true;
      }
      this.serachCriteria = this.searchConfig.getSearchCriteria();
     
      this.searchStringForSearch = this.searchConfig.searchString;
      this.selectedSortByName = this.searchConfig.selectedSortByName;
      this.sortMode = this.searchConfig.sortMode;  
      console.log('Search - showAdhocProduct',this.showAdhocProduct)
     
   }

   async loadProducts(loadMore:boolean) {
      this.searchConfig.loadMore=loadMore;
      this.searchConfig.resetLoadMoreCount();
      console.log('Product SearchCriteria',this.searchConfig.getSearchCriteria());
      await this.productService.loadProductForSearch(this.searchConfig.getSearchCriteria()).toPromise().then((res:SearchCriteria) => {
         if(loadMore){
            this.searchConfig.lastSelection=0;
            this.productList=this.productList.concat(res.productSearchResult);
         }else{
            this.productList = res.productSearchResult as Product[];
            this.totalSearchCount=res.totalCount;
         }
         this.searchConfig.setResultMetadata(res)
         this.onSortColumnChange();
         this.showMoreDisabled=false;
         
      },
         (err) => {
            console.error(err);
            this.LoadArtErrorFlag = true;
            this.LoadArtError = err.message;
            this.showMoreDisabled=false;
         });
      this.selectedProductForCatalogCount = this.productCatalogService.selectedProductsForCatalog.length;
   }
   loadTags() {
      this.productService.loadTags().subscribe((res: string[]) => {
         let list = this.sharedService.sort(res);
         this.setMasterList('TAGS',list);
         this.setFilteredMasterListForModal('TAGS',list);
      });

   }
   loadBrands() {
      this.brandService.loadBrands([]).subscribe((res: Brand[]) => {
         let list = this.sharedService.sort(res.map(x=>x.brandName));
         this.setMasterList('BRANDS',list);
         this.setFilteredMasterListForModal('BRANDS',list);
      });
   }
   loadLicensors() {
      this.LicensorService.loadLicensors().subscribe((res: Licensor[]) => {
         let list = this.sharedService.sort(res.map(x=>x.licensorName));
         this.setMasterList('LICENSORS',list);
         this.setFilteredMasterListForModal('LICENSORS',list);
      });
   }
   loadMetaDataForType(classification, type) {
      this.metadataService.getMetadataValueListByClassificationAndType(classification, type).subscribe((res: ApiResponse) => {
         let typeTemp=type;
         if(classification==='BRAND' && type==='CATEGORY'){
            typeTemp='BRAND_CATEGORY';
         }
         
         if (res.responseStatus.status === 'SUCCESS') {
            let maserList=this.sharedService.sort(res.responsePayload);
            this.setMasterList(typeTemp,maserList);
            this.setFilteredMasterListForModal(typeTemp,maserList);
         }
      });
   }
   getFilteredListForSearch(type: any):string[] {
      if(type==='LICENSORS'){
         return this.searchConfig.selectedLicensorsForSearch;
       }else if(type==='BRANDS'){
         return this.searchConfig.selectedBrandsForSearch;
       }else if(type==='CATEGORY'){
         return this.searchConfig.selectedCategoriesForSearch;
       }else if(type==='BRAND_CATEGORY'){
         return this.searchConfig.selectedBrandCategoriesForSearch;
       }else if(type==='PRINT_EFFECT'){
         return this.searchConfig.selectedPrintEffectsForSearch;
       }else if(type==='TAGS'){
         return this.searchConfig.selectedTagsForSearch;
       }else if(type==='APPROVAL_STATUS'){
         return this.searchConfig.selectedApprovalStatusListForSearch;
       }else if(type==='ART_STATUS'){
         return this.searchConfig.selectedArtStatusListForSearch;
       }else if(type==='TREATMENT'){
         return this.searchConfig.selectedTreatmentsForSearch;
       }else if(type==='GARMENT'){
         return this.searchConfig.selectedGarmentsForSearch
       }else if(type==='COLOR'){
         return this.searchConfig.selectedColorsForSearch;
       }
   }
   setFilteredMasterListForModal(type: any, list: string[]) {
      if(type==='LICENSORS'){
         this.licensorListModalFiltered=list;
      }else if(type==='BRANDS'){
         this.brandListModalFiltered=list;
       }else if(type==='CATEGORY'){
         this.categoryListModalFiltered=list;
       }else if(type==='BRAND_CATEGORY'){
         this.brandCategoryListModalFiltered=list;
       }else if(type==='PRINT_EFFECT'){
         this.printEffectListModalFiltered=list;
       }else if(type==='TAGS'){
         this.tagListModalFiltered=list;
       }else if(type==='APPROVAL_STATUS'){
         this.approvalStatusListModalFiltered=list;
       }else if(type==='ART_STATUS'){
         this.artStatusListModalFiltered=list;
       }else if(type==='TREATMENT'){
         this.treatmentListModalFiltered=list;
       }else if(type==='GARMENT'){
         this.garmentListModalFiltered=list;
       }else if(type==='COLOR'){
         this.colorListModalFiltered=list;
       }
   }
   getFilteredMasterListForModal(type: any):string[] {
      if(type==='LICENSORS'){
         return this.licensorListModalFiltered;
      }else if(type==='BRANDS'){
         return this.brandListModalFiltered;
       }else if(type==='CATEGORY'){
         return this.categoryListModalFiltered;
       }else if(type==='BRAND_CATEGORY'){
         return this.brandCategoryListModalFiltered;
       }else if(type==='PRINT_EFFECT'){
         return this.printEffectListModalFiltered;
       }else if(type==='TAGS'){
         return this.tagListModalFiltered;
       }else if(type==='APPROVAL_STATUS'){
         return this.approvalStatusListModalFiltered;
       }else if(type==='ART_STATUS'){
         return this.artStatusListModalFiltered;
       }else if(type==='TREATMENT'){
         return this.treatmentListModalFiltered;
       }else if(type==='GARMENT'){
         return this.garmentListModalFiltered;
       }else if(type==='COLOR'){
         return this.colorListModalFiltered;
       }
   }
   setMasterList(type: any, list: string[]) {
      if(type==='LICENSORS'){
         this.licensorList=list;
      }else if(type==='BRANDS'){
         this.brandList=list;
       }else if(type==='CATEGORY'){
         this.categoryList=list;
       }else if(type==='BRAND_CATEGORY'){
         this.brandCategoryList=list;
       }else if(type==='PRINT_EFFECT'){
         this.printEffectList=list;
       }else if(type==='TAGS'){
         this.tagList=list;
       }else if(type==='APPROVAL_STATUS'){
         this.approvalStatusList=list;
       }else if(type==='ART_STATUS'){
         this.artStatusList=list;
       }else if(type==='TREATMENT'){
         this.treatmentList=list;
       }else if(type==='GARMENT'){
         this.garmentList=list;
       }else if(type==='COLOR'){
         this.colorList=list;
       }
   }
   getMasterList(type: any):string[] {
      if(type==='LICENSORS'){
         return this.licensorList;
      } else if(type==='BRANDS'){
         return this.brandList;
       }else if(type==='CATEGORY'){
         return this.categoryList;
       }else if(type==='BRAND_CATEGORY'){
         return this.brandCategoryList;
       }else if(type==='PRINT_EFFECT'){
         return  this.printEffectList;
       }else if(type==='TAGS'){
         return  this.tagList;
       }else if(type==='APPROVAL_STATUS'){
         return  this.approvalStatusList;
       }else if(type==='ART_STATUS'){
         return  this.artStatusList;
       }else if(type==='TREATMENT'){
         return  this.treatmentList;
       }else if(type==='GARMENT'){
         return  this.garmentList;
       }else if(type==='COLOR'){
         return this.colorList;
       }
   }
   selectedAllForSearch(selectedAll,type:string){
      this.getFilteredMasterListForModal(type).forEach(element => {
         this.selectedForSearch(event,element,type);
         if (selectedAll) {
            this.searchConfig.add(element,type);
         } else {
            this.searchConfig.remove(element,type);
         }
      });
      this.reloadSearchCriteriaFromService();
   }
   selectedForSearch(event, item: string,type:string) {
      if (event.target.checked) {
         this.searchConfig.add(item,type);
      } else {
         this.searchConfig.remove(item,type);
      }
      this.reloadSearchCriteriaFromService();
   }

   isSelectedForSearch(id: string,type:string): boolean {
      return this.searchConfig.isSelected(id,type);
   }

   onKeyupModalFilter(event, value,type) {
      if (value.trim().length > 0) {
         this.setFilteredMasterListForModal(type,this.getMasterList(type).filter(item => item.toUpperCase().indexOf(value.toUpperCase()) !== -1));
      } else {
         this.setFilteredMasterListForModal(type,this.getMasterList(type));
      }
   }

   resetAdvSearch() {
      this.searchConfig.resetAdvSearchCriteria();
      this.serachCriteria = this.searchConfig.getSearchCriteria();
      this.searchConfig.lastSelection=0;
      this.loadProducts(false);
   }

   search(event?) {
      this.searchConfig.lastSelection=0;
      var regexp = new RegExp('^([0-9]{12})$');
      let tempSearchString: string = this.searchStringForSearch;
      var result = regexp.test(tempSearchString.trim());
      //alert(result);
      if (result) {
         this.searchConfig.excludeProductType = 'SALES';
      } else {
         if (this.searchConfig.excludeProductType === 'SALES') {
            this.searchConfig.excludeProductType = 'ALL';
         }
      }
      this.searchConfig.searchString = this.searchStringForSearch;
      this.loadProducts(false);
     
   }
   
   searchInput(event){
      if(event?.target.value.length===0 && event.type !== 'focus'){
         this.search(event);
      }
   }

   
   resetSkuCodeSearch() {
      this.serachCriteria = this.searchConfig.getSearchCriteria();
   }

   
   /*onKeyupProductFilter(event, value) {
      if (event.key !== 'Shift') {
         if (value.trim().length > 0) {
            this.filteredProductList = this.productList.filter(items => items.sku.toUpperCase().indexOf(value.toUpperCase()) > -1);
         } else {
            this.filteredProductList = this.productList;
         }
      }

   }*/
   loadMoreProducts(){
      /*let existingCount=this.filteredProductList.length;
      let additionalProducts:Product[]=this.productList.slice(existingCount,existingCount+this.defaultDisplaySize);
      this.filteredProductList=this.filteredProductList.concat(additionalProducts);*/
      this.loadProducts(true);
      
   }

   async selectAll(){
      //this.searchConfig.loadMoreCount=500;
      this.searchConfig.lastSelection=400;
      await this.loadProducts(false);
      this.selectCurrentFilteredListForCatalog();
      
   }

   


/*******************************CATALOG RELATED STARTS************************** */
  
   childSelectionChanged(selection:boolean){
      this.selectedProductForCatalogCount = this.productCatalogService.selectedProductsForCatalog.length;
   }

   selectCurrentFilteredListForCatalog(){
      this.productList.forEach(element => {
         if(element.approvalStatus!=='Concept Rejected' && element.productImages.length>0 && element.brandActive && element.licensorActive && !element.exclusiveToCustomers){
            this.productCatalogService.addProduct(element);
         }
      });
      this.selectedProductForCatalogCount = this.productCatalogService.selectedProductsForCatalog.length;
   }

   resetCatalogSelection() {
      this.productCatalogService.selectedProductsForCatalog = [];
      if(this.productCatalogService.catalogSettings){
         this.productCatalogService.catalogSettings.catalogName='Catalog';
      }
      this.selectedProductForCatalogCount = this.productCatalogService.selectedProductsForCatalog.length;
   }
/*******************************CATALOG RELATED ENDS************************** */

   downloadSelectedImages(){
      this.productCatalogService.download();
      this.selectedProductForCatalogCount = this.productCatalogService.selectedProductsForCatalog.length;
   }

   
   @HostListener("window:scroll", ["$event"])
   onWindowScroll() {
      let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
      let max = document.documentElement.scrollHeight;
      if (pos >= (max - 5)) {
         //if (this.filteredProductList?.length !== this.productSearchService?.productSearchDetails.totalCount) {
         if(this.productSearchService?.productSearchDetails.loadedCount<this.productSearchService?.productSearchDetails.totalCount && this.productSearchService?.productSearchDetails.loadedCount<500){
            document.getElementById('showMoreId').click();
            this.showMoreDisabled=true;
         }
      }
   }

   onSortColumnChange() {
     // let countToDisplay=this.defaultDisplaySize;
      let lastSelection=this.searchConfig.lastSelection;
      lastSelection=lastSelection?lastSelection:0;
      /*if(lastSelection>this.defaultDisplaySize){
         countToDisplay=lastSelection+this.defaultDisplaySize;
      }
      if(this.productList.length>countToDisplay){
      this.filteredProductList = this.productList.slice(0,countToDisplay);
      }else{
         this.filteredProductList=this.productList.slice(0,this.productList.length);
      }*/
      this.filteredProductList=this.productList;
      if(this.filteredProductList.length>=lastSelection && lastSelection>3){
         setTimeout(()=>{
            lastSelection=lastSelection>4?lastSelection-4:0;
            if(document.getElementById('imageCard_'+lastSelection)){
               document.getElementById('imageCard_'+lastSelection).scrollIntoView({block: "start", behavior: "smooth"});
            }
         },1000);
      }
   }

   sort(mode:string) {
      this.sortMode = mode;
      this.searchConfig.lastSelection=0;
      this.searchConfig.selectedSortByName=this.selectedSortByName;
      this.searchConfig.sortMode=this.sortMode;  
      this.loadProducts(false);
   }

   changeSortCriteria(){
      this.searchConfig.lastSelection=0;
      this.searchConfig.selectedSortByName=this.selectedSortByName;
      this.loadProducts(false);
   }

   createCollection(){
      let productIdArr:String[]=this.productCatalogService.getSelecedProductIdList();
      let searchCriteria=new SearchCriteria;
      searchCriteria.productIdArray=productIdArr;
      this.productService.createCollectionProduct(searchCriteria).subscribe(res =>{
         let status:ServiceStatus=res as ServiceStatus;
         if(status.status==='SUCCESS'){
            let product:Product=status.object as Product;
            this.router.navigate(['/product/newCollection/' + product.licensorId + '/' + product.brandId + '/' + product.id],{skipLocationChange:true});
         }else{
            this.toastrService.error(status.message);
         }

      });
   }

   /****EMAIL for multiple products */
   sendProductEmail(){
      //this.createCatalogue();
       this.showEmailEditor=true;
       let emailConfig: EmailTemplateConfig = new EmailTemplateConfig;
       emailConfig.productIdList=this.productCatalogService.getSelecedProductIdList();
       emailConfig.templateName='productDetailsWithVisual';
       this.composeEmail=new ComposeEmail;
       this.getEmailTemplate(emailConfig);
    }
 
    getEmailTemplate(emailConfig:EmailTemplateConfig){
       this.composeEmailService.getEmailTemplate(emailConfig).subscribe(response => {
          let composeEmailTemp:ComposeEmail = response.responsePayload;
          this.composeEmail.emailContent=composeEmailTemp.emailContent;
          this.composeEmail.emailTo=composeEmailTemp.emailTo;
          this.composeEmail.emailCc=composeEmailTemp.emailCc;
          this.composeEmail.emailSubject=composeEmailTemp.emailSubject;
          document.getElementById('composeEmailToggler').click();
       });
    }
   /****EMAIL for Multiple products ends */

   isEligibleForMultipleSubmission():boolean{
      let retVal:boolean=false;
      let products:Product[]=this.productCatalogService.selectedProductsForCatalog;
      if(products && products.length>0){
         let uniqueBrandIds=[...new Set(products.map(x=>x.brandId))];
         if(uniqueBrandIds && uniqueBrandIds.length===1){
            retVal=true;
         }
      }
      return retVal;
   }
}
