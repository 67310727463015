<form name="login" (ngSubmit)="onFormSubmit()" #loginForm="ngForm" autocomplete="off" class="form-signin">
   <div class="container" style="display: flex;justify-content: center;align-items: center;height: 100vh;">
     <div class="card shadow" style="width: 30rem;">
       <div class="card-body">
         <div class="text-center mb-4">
           <img class="mb-4 round" src="{{'loginBrandIcon'|env}}" alt="Icon" style="border-radius: 50%;" width="136px" height="136px">
           <h2 class="h2 mb-2 font-weight-normal"> Login</h2>
         </div>
       <!--
         <div *ngIf="isServerErrorPresent" class="invalid-feedback d-block">
           {{serverErrors}}
         </div>
       -->
         <div class="card-text">
           <div class="form-group input-group">
             <div class="input-group-prepend">
               <span class="input-group-text"> <i class="fa fa-envelope"></i> </span>
             </div>
               <input name="email" id="email" [(ngModel)]="user.email" placeholder="Email"  class="form-control" #email="ngModel" 
               required [ngClass]="{'is-invalid':email.touched && email.invalid}" email>
               <div *ngIf="email.invalid" class="invalid-feedback">
                 <div *ngIf="email.errors.required">
                   Email address is required.
                 </div>
                 <div *ngIf="email.errors.email">
                   Email must be a valid email address.
                 </div>
               </div>
           </div>
 
           <div class="form-group input-group">
             <div class="input-group-prepend">
               <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
             </div>
             <input type="password" [(ngModel)]="user.password" placeholder="Password" name="password" class="form-control" id="password"
               #password="ngModel" required minlength="3" maxlength="20" [ngClass]="{'is-invalid':password.touched && password.errors}">
             <div *ngIf="password?.invalid && (password.dirty || password.touched)" class="invalid-feedback">
               <div *ngIf="password.errors.required">
                 Password is required.
               </div>
               <div *ngIf="password.errors.minlength">
                 Password Minimum Length is 3
               </div>
             </div>
           </div>
 
           
           <button class="btn btn-md btn-primary btn-block" [disabled]="loginForm.invalid">Login</button>
           <a rel="noopener" [routerLink]="['/forgotpassword']" class="btn btn-sm btn-link">Forgot Password?</a>
           
         </div>
       </div>
     </div>
   </div>
 </form>




   <!--
   <div class="form-group input-group custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input form-control" id="customCheck1" required #abc="ngModel" 
      [(ngModel)]="user.active" name="abc"
      [ngClass]="{'is-invalid':abc.touched && abc.errors}">
      <label class="custom-control-label" for="customCheck1">I have read and agree to the <a href="#">Terms of Service.</a></label>
      <div *ngIf="abc?.invalid && (abc.dirty || abc.touched)" class="invalid-feedback">
         <div *ngIf="abc.errors.required">
            Check box is required.
         </div>
      </div>
   </div>
-->   