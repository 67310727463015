export class CostTypeDetail {
   id: string;
   description: string;
   classification:string;
	subClassification:string;
   category: string;
   subCategory: string;
   costType: string;
   calculationType: string;
   sortOrder: number;
   remarks: number;
   unitCost: number;
   totalCost: number;
   uiCost:number;
   defaultCost:number;
   //hasError: boolean;
   //errorMessage: string;
   currency:string;
   source:string;
}