import { Injectable } from '@angular/core';
import { Permission } from '../model/permission.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
   providedIn: 'root'
})

export class PermissionService {
   private basePath = environment.apiEndPoint;

   constructor(private httpClient: HttpClient) { }

   loadPermissions(): Observable<any> {
      return this.httpClient.post<any>(this.basePath + '/permission/list', null);
   }

   createPermission(permission: Permission): Observable<any> {
      return this.httpClient.post<any>(this.basePath + '/permission/create', permission);
   }

   updatePermission(permission: Permission): Observable<any> {
      return this.httpClient.post<any>(this.basePath + '/permission/update', permission);
   }

   loadPermissionsListByRole(roleId): Observable<any> {
      return this.httpClient.post<any>(this.basePath + '/permission/role/'+roleId, roleId);
   }

   deletePermission(permission: Permission){
      return this.httpClient.post<any>(this.basePath + '/permission/delete/'+permission.id, null);
   }

}
