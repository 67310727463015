import { ExternalLinks } from "../../common/external-links.model";
import { Product } from "../../product.model";
import { OrderDetail } from "../order-detail.model";


export class OrderDetailInfo {
    id:string;
    salesOrderId:string;
    //printerName:string;
    printerInfoId:string;
    printerId:string;
    orderDetail:OrderDetail;
    orderDetailId:string;
    orderDetailStatus:string;
    artSentDate:Date;
    ppSamplesRequired:boolean;
    ppSampleQty:number;
    ppSampleTrackingNum:string;
    ppSamplePrinterDeadline:Date;
    ppSampleStatus:string
    ppTrackingLinks:ExternalLinks[]=[];

    topSamplesRequired:boolean;
    topSampleQty:number;
    topSampleTrackingNum:String;
    topSamplePrinterDeadline:Date;
    topSampleStatus:string
    topTrackingLinks:ExternalLinks[]=[];

    //sampleInfoList:SamplesInfo[]=[];
    ppSampleInfoStatus:string;
    topSampleInfoStatus:string;

    totalSaleQty:number=0;

    deleteFlag:boolean=false;
    origination:string;
    obsolete:boolean;
    version:number=0;
    productId:string;
}
