import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlanksInfo } from 'src/app/shared/model/sales/fulfillment/blanks-info.model';
import { ProductionTracker } from 'src/app/shared/model/sales/fulfillment/production-tracker.model';
import { SalesOrder } from 'src/app/shared/model/sales/sales-order.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { BlanksSupplier } from 'src/app/shared/model/vendors/blanks-supplier.model';
import { Printer } from 'src/app/shared/model/vendors/printer.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { VendorsService } from 'src/app/shared/service/vendors/vendors.service';

@Component({
  selector: 'app-blank-info',
  templateUrl: './blank-info.component.html',
  styleUrls: ['./blank-info.component.css']
  /*,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]*/
})
export class BlankInfoComponent implements OnInit {
  @Input() salesOrder:SalesOrder;
  @Input() userAccess:UserAccess;
  @Input() productionTracker:ProductionTracker;
  @Output() onBlankInfoEdit = new EventEmitter<boolean>();
  @Output() onBlankInfoCancelEdit = new EventEmitter<boolean>();
  @Output() onBlankInfoSave = new EventEmitter<boolean>();
  @Input() editable:boolean=false;
  blanksSupplierList:BlanksSupplier[]=[];
  printerList:Printer[]=[];
  
    
  
  constructor(private vendorService:VendorsService,private sharedService:SharedService) { }
  
    ngOnInit(): void {
      //this.getVendorList();
      this.handleSubscriptions();
    }
    handleSubscriptions() {
      // Subscriptions
      this.vendorService.printerListObservable.subscribe(res => {
         this.printerList = res;
         console.log("PTBI PrinterList",this.printerList);
      });
      this.vendorService.blankSupplierListObservable.subscribe(res => {
        this.blanksSupplierList = res;
        console.log("PTBI SupplierList",this.blanksSupplierList);
     });
    }

  
   /* getVendorList() {
    
      this.vendorService.getBlanksSupplierList().subscribe(res=>{
        if(res && res.responseStatus.status==='SUCCESS' && res.responsePayload !=null){
          this.blanksSupplierList=res.responsePayload;
          this.blanksSupplierList = this.sharedService.sortListByPropertyName(this.blanksSupplierList,'name');
        }
      });
      this.vendorService.getPrinterList().subscribe(res=>{
        if(res && res.responseStatus.status==='SUCCESS' && res.responsePayload !=null){
          this.printerList=res.responsePayload;
          this.printerList = this.sharedService.sortListByPropertyName(this.printerList,'name');
        }
      });
    }*/

    getPrinterName(blankInfo:BlanksInfo):string{
      let retVal:string='';
      if(this.printerList){
        this.printerList.forEach(printer => {
          if(printer.id===blankInfo.printerId){
            blankInfo.printerName=printer.name;
            retVal= printer.name;
          }
        });
      }
      return retVal
    }
   
    addBlankInfo(){
      if(!this.productionTracker.blanksInfoList){
        this.productionTracker.blanksInfoList=[];
      }
      this.productionTracker.blanksInfoList.push(new BlanksInfo(this.productionTracker.salesOrderId));
    };
    deleteBlankInfo(blankInfo:BlanksInfo){
      blankInfo.deleteFlag=true;
    };
    
    getBlankName(blankInfo:BlanksInfo):string{
      let retVal:string='';
      if(this.blanksSupplierList){
        this.blanksSupplierList.forEach(blankSupplier => {
          if(blankSupplier.id===blankInfo.supplierId){
            blankInfo.blankSupplierName=blankSupplier.name;
            retVal= blankSupplier.name;
          }
        });
      }
      return retVal
    }

    getBlankSupplierCount(blanksInfoList:BlanksInfo[]):number{
      let retVal=0;
      if(blanksInfoList){
        let temp=blanksInfoList.filter(x=>x.deleteFlag===false);
        retVal=temp.length;
      }
      return retVal;
    }

    edit(){
      this.onBlankInfoEdit.next(true);
    }
    cancel(){
      this.editable=false;
      this.onBlankInfoCancelEdit.next(true);
    }
    save(){
      this.onBlankInfoSave.next(true);
    }
 
}
