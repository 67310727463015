export class MetadataProductConfig {
   constructor(status: boolean, sortOrder: number) {
      this.status = status;
      this.sortOrder = sortOrder;
   }
   id: string;
   productGarment: string;
   productGarmentCode: string;
   productCategory: string;
   royaltyProductCategory: string;
   sageCategory: string;
   sageSubCategory: string;
	sizeApplicableForSageCode: boolean;
   status: boolean;
   sortOrder: number;

   // FrontEnd Transient
   hasErrors: boolean = false;
}
