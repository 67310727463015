import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/shared/model/user.model';
import { Location } from '@angular/common';

import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/service/auth.service';
import { ProductMetadataService } from 'src/app/shared/service/product-metadata.service';
import { MetadataColour } from 'src/app/shared/model/product-metadata/metadata-colour.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MetadataSize } from 'src/app/shared/model/product-metadata/metadata-size.model';
import { MetadataGarment } from 'src/app/shared/model/product-metadata/metadata-garment.model';
import { MetadataTreatment } from 'src/app/shared/model/product-metadata/metadata-treatment.model';
import { MetadataRoyaltyCategory } from 'src/app/shared/model/product-metadata/metadata-royalty-category.model';
import { MetadataCategory } from 'src/app/shared/model/product-metadata/metadata-category.model';
import _, { cloneDeep } from 'lodash';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { SharedService } from 'src/app/shared/service/shared.service';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { MetadataProductConfig } from 'src/app/shared/model/product-metadata/metadata-product-config.model';

@Component({
   selector: 'app-product-metadata',
   templateUrl: './product-metadata.component.html',
   styleUrls: ['./product-metadata.component.css']
})
export class ProductMetadataComponent implements OnInit {

   constructor(private authService: AuthService, private productMetadataService: ProductMetadataService, private sharedService: SharedService,
      private location: Location, private metadataService: MetadataService, private toastrService: ToastrService) { }

   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;

   sageCategoryCodeList: string[] = [];
   sageSubCategoryCodeList: string[] = [];

   colourEditable: boolean = false;
   colourList: MetadataColour[] = [];
   filteredColourList: any = [];

   sizeEditable: boolean = false;
   sizeList: MetadataSize[] = [];
   filteredSizeList: any = [];

   treatmentEditable: boolean = false;
   treatmentList: MetadataTreatment[] = [];
   filteredTreatmentList: any = [];

   categoryEditable: boolean = false;
   categoryList: MetadataCategory[] = [];
   filteredCategoryList: any = [];

   royaltyCategoryEditable: boolean = false;
   royaltyCategoryList: MetadataRoyaltyCategory[] = [];
   filteredRoyaltyCategoryList: any = [];

   garmentEditable: boolean = false;
   garmentList: MetadataGarment[] = [];
   filteredGarmentList: any = [];

   productConfigEditable: boolean = false;
   productConfigList: MetadataProductConfig[] = [];
   filteredProductConfigList: any = [];


   ngOnInit(): void {
      this.handleSubscriptions();
      this.getColourList();
      this.getSizeList();
      this.getTreatmentList();
      this.getCategoryList();
      this.getRoyaltyCategoryList();
      this.getGarmentList();
      this.getProductConfigList();
      this.loadSageCategoryCodeList();
      this.loadSageSubCategoryCodeList();
   }

   handleSubscriptions() {
      // Subscriptions
      this.userAccess = new UserAccess();
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });

      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
         this.currentLoggedInUser = currentLoggedInUser;
      });
   }

   loadSageCategoryCodeList() {
      this.metadataService.getMetadataValueListByClassificationAndType('SAGE', 'CATEGORY_CODE').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.sageCategoryCodeList = this.sharedService.sort(res.responsePayload);
         }
      });
   }


   loadSageSubCategoryCodeList() {
      this.metadataService.getMetadataValueListByClassificationAndType('SAGE', 'SUB_CATEGORY_CODE').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.sageSubCategoryCodeList = this.sharedService.sort(res.responsePayload);
         }
      });
   }

   /*********************** COLOUR **************************** */

   getColourList() {
      this.productMetadataService.getColourList().subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.colourList = response.responsePayload;
            this.filteredColourList = this.colourList;
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      });
   }

   addColour() {
      this.colourList.push(new MetadataColour(true, (this.colourList?.length) + 1));
   }

   editColour() {
      this.filteredColourList = this.colourList;
      this.colourEditable = true;
   }

   cancelColour() {
      this.colourEditable = false;
      this.getColourList();
   }

   removeColour(index) {
      this.colourList.splice(index, 1);
      this.setColourMetadataSortOrder();
   }

   saveColour() {
      const keysToValidateDuplicateEntry = ["name"];
      if (this.hasDuplicateEntryErrors(this.colourList, keysToValidateDuplicateEntry)) {
         this.toastrService.error("Error !!! - Colour with same " + keysToValidateDuplicateEntry + " already Exists.");
         return;
      }

      this.productMetadataService.saveColourList(this.colourList).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.colourList = response.responsePayload;
            this.toastrService.success(response.responseStatus.message);
            this.colourEditable = false;
            this.getColourList();
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      });
   }

   colourDrop(event: CdkDragDrop<string[]>) {
      moveItemInArray(this.colourList, event.previousIndex, event.currentIndex);
      this.setColourMetadataSortOrder();
   }

   setColourMetadataSortOrder() {
      if (this.colourList != undefined && this.colourList != null && this.colourList.length > 0) {
         for (let index = 0; index < this.colourList.length; index++) {
            this.colourList[index].sortOrder = index + 1;
         }
      }
   }

   onKeyupColourFilter(event, filterValue) {
      if (event.key !== 'Shift') {
         if (filterValue.trim().length > 0) {
            this.filteredColourList = this.colourList.filter(item =>
               (item == undefined || item == null || !item.id) ||
               (item.name.toUpperCase().indexOf(filterValue.toUpperCase()) > -1) ||
               (item.shortName.toUpperCase().indexOf(filterValue.toUpperCase()) > -1));
         } else {
            this.filteredColourList = this.colourList;
         }
      }
   }



   /*********************** SIZE **************************** */

   getSizeList() {
      this.productMetadataService.getSizeList().subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.sizeList = response.responsePayload;
            this.filteredSizeList = this.sizeList;
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      });
   }

   addSize() {
      this.sizeList.push(new MetadataSize(true, (this.sizeList?.length) + 1));
   }

   editSize() {
      this.filteredSizeList = this.sizeList;
      this.sizeEditable = true;
   }

   cancelSize() {
      this.sizeEditable = false;
      this.getSizeList();
   }

   removeSize(index) {
      this.sizeList.splice(index, 1);
   }

   saveSize() {

      const keysToValidateDuplicateEntry = ["size"];
      if (this.hasDuplicateEntryErrors(this.sizeList, keysToValidateDuplicateEntry)) {
         this.toastrService.error("Error !!! - Size with same " + keysToValidateDuplicateEntry + " already Exists.");
         return;
      }

      this.productMetadataService.saveSizeList(this.sizeList).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.sizeList = response.responsePayload;
            this.toastrService.success(response.responseStatus.message);
            this.sizeEditable = false;
            this.getSizeList();
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      });
   }

   sizeDrop(event: CdkDragDrop<string[]>) {
      moveItemInArray(this.sizeList, event.previousIndex, event.currentIndex);
      this.setSizeMetadataSortOrder();
   }

   setSizeMetadataSortOrder() {
      if (this.sizeList != undefined && this.sizeList != null && this.sizeList.length > 0) {
         for (let index = 0; index < this.sizeList.length; index++) {
            this.sizeList[index].sortOrder = index + 1;
         }
      }
   }


   onKeyupSizeFilter(event, filterValue) {
      if (event.key !== 'Shift') {
         if (filterValue.trim().length > 0) {
            this.filteredSizeList = this.sizeList.filter(item =>
               (item == undefined || item == null || !item.id) ||
               (item.size.toUpperCase().indexOf(filterValue.toUpperCase()) > -1) ||
               (item.shortName.toUpperCase().indexOf(filterValue.toUpperCase()) > -1));
         } else {
            this.filteredSizeList = this.sizeList;
         }
      }
   }

   /*********************** TREATMENT **************************** */

   getTreatmentList() {
      this.productMetadataService.getTreatmentList().subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.treatmentList = response.responsePayload;
            this.filteredTreatmentList = this.treatmentList;
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      });
   }

   addTreatment() {
      this.treatmentList.push(new MetadataTreatment(true, (this.treatmentList?.length) + 1));
   }

   editTreatment() {
      this.filteredTreatmentList = this.treatmentList;
      this.treatmentEditable = true;
   }

   cancelTreatment() {
      this.treatmentEditable = false;
      this.getTreatmentList();
   }

   removeTreatment(index) {
      this.treatmentList.splice(index, 1);
   }

   saveTreatment() {
      const keysToValidateDuplicateEntry = ["name"];
      if (this.hasDuplicateEntryErrors(this.treatmentList, keysToValidateDuplicateEntry)) {
         this.toastrService.error("Error !!! - Treatment with same [" + keysToValidateDuplicateEntry + "] already Exists.");
         return;
      }

      this.productMetadataService.saveTreatmentList(this.treatmentList).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.treatmentList = response.responsePayload;
            this.toastrService.success(response.responseStatus.message);
            this.treatmentEditable = false;
            this.getTreatmentList();
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      });
   }

   treatmentDrop(event: CdkDragDrop<string[]>) {
      moveItemInArray(this.treatmentList, event.previousIndex, event.currentIndex);
      this.setTreatmentMetadataSortOrder();
   }

   setTreatmentMetadataSortOrder() {
      if (this.treatmentList != undefined && this.treatmentList != null && this.treatmentList.length > 0) {
         for (let index = 0; index < this.treatmentList.length; index++) {
            this.treatmentList[index].sortOrder = index + 1;
         }
      }
   }

   onKeyupTreatmentFilter(event, filterValue) {
      if (event.key !== 'Shift') {
         if (filterValue.trim().length > 0) {
            this.filteredTreatmentList = this.treatmentList.filter(item =>
               (item == undefined || item == null || !item.id) ||
               (item.name.toUpperCase().indexOf(filterValue.toUpperCase()) > -1) ||
               (item.shortName.toUpperCase().indexOf(filterValue.toUpperCase()) > -1));
         } else {
            this.filteredTreatmentList = this.treatmentList;
         }
      }
   }

   /*********************** CATEGORY **************************** */

   getCategoryList() {
      this.productMetadataService.getCategoryList().subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.categoryList = response.responsePayload;
            this.filteredCategoryList = this.categoryList;
            this.filteredCategoryList = this.sharedService.sortListByPropertyName(this.filteredCategoryList, 'name');
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      });
   }

   addCategory() {
      this.categoryList.push(new MetadataCategory(true, (this.categoryList?.length) + 1));
   }

   editCategory() {
      this.filteredCategoryList = this.categoryList;
      this.categoryEditable = true;
   }

   cancelCategory() {
      this.categoryEditable = false;
      this.getCategoryList();
   }

   removeCategory(index) {
      this.categoryList.splice(index, 1);
   }

   saveCategory() {
      const keysToValidateDuplicateEntry = ["name"];
      if (this.hasDuplicateEntryErrors(this.categoryList, keysToValidateDuplicateEntry)) {
         this.toastrService.error("Error !!! - Category with same [" + keysToValidateDuplicateEntry + "] already Exists.");
         return;
      }

      this.productMetadataService.saveCategoryList(this.categoryList).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.categoryList = response.responsePayload;
            this.toastrService.success(response.responseStatus.message);
            this.categoryEditable = false;
            this.getCategoryList();
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      });
   }

   categoryDrop(event: CdkDragDrop<string[]>) {
      moveItemInArray(this.categoryList, event.previousIndex, event.currentIndex);
      this.setCategoryMetadataSortOrder();
   }

   setCategoryMetadataSortOrder() {
      if (this.categoryList != undefined && this.categoryList != null && this.categoryList.length > 0) {
         for (let index = 0; index < this.categoryList.length; index++) {
            this.categoryList[index].sortOrder = index + 1;
         }
      }
   }

   onKeyupCategoryFilter(event, filterValue) {
      if (event.key !== 'Shift') {
         if (filterValue.trim().length > 0) {
            this.filteredCategoryList = this.categoryList.filter(item =>
               (item == undefined || item == null || !item.id) ||
               (item.name.toUpperCase().indexOf(filterValue.toUpperCase()) > -1) ||
               (item.shortName.toUpperCase().indexOf(filterValue.toUpperCase()) > -1));
         } else {
            this.filteredCategoryList = this.categoryList;
         }
      }
   }


   /*********************** ROYALTY CATEGORY **************************** */

   getRoyaltyCategoryList() {
      this.productMetadataService.getRoyaltyCategoryList().subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.royaltyCategoryList = response.responsePayload;
            this.filteredRoyaltyCategoryList = this.royaltyCategoryList;
            this.filteredRoyaltyCategoryList = this.sharedService.sortListByPropertyName(this.filteredRoyaltyCategoryList, 'name');
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      });
   }

   addRoyaltyCategory() {
      this.royaltyCategoryList.push(new MetadataRoyaltyCategory(true, (this.royaltyCategoryList?.length) + 1));
   }

   editRoyaltyCategory() {
      this.filteredRoyaltyCategoryList = this.royaltyCategoryList;
      this.royaltyCategoryEditable = true;
   }

   cancelRoyaltyCategory() {
      this.royaltyCategoryEditable = false;
      this.getRoyaltyCategoryList();
   }

   removeRoyaltyCategory(index) {
      this.royaltyCategoryList.splice(index, 1);
      this.setRoyaltyCategoryMetadataSortOrder();
   }

   saveRoyaltyCategory() {

      const keysToValidateDuplicateEntry = ["name"];
      if (this.hasDuplicateEntryErrors(this.royaltyCategoryList, keysToValidateDuplicateEntry)) {
         this.toastrService.error("Error !!! - Royalty Category with same [" + keysToValidateDuplicateEntry + "] already Exists.");
         return;
      }

      this.productMetadataService.saveRoyaltyCategoryList(this.royaltyCategoryList).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.royaltyCategoryList = response.responsePayload;
            this.toastrService.success(response.responseStatus.message);
            this.royaltyCategoryEditable = false;
            this.getRoyaltyCategoryList();
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      });

   }

   royaltyCategoryDrop(event: CdkDragDrop<string[]>) {
      moveItemInArray(this.royaltyCategoryList, event.previousIndex, event.currentIndex);
      this.setRoyaltyCategoryMetadataSortOrder();
   }

   setRoyaltyCategoryMetadataSortOrder() {
      if (this.royaltyCategoryList != undefined && this.royaltyCategoryList != null && this.royaltyCategoryList.length > 0) {
         for (let index = 0; index < this.royaltyCategoryList.length; index++) {
            this.royaltyCategoryList[index].sortOrder = index + 1;
         }
      }
   }


   onKeyupRoyaltyCategoryFilter(event, filterValue) {
      if (event.key !== 'Shift') {
         if (filterValue.trim().length > 0) {
            this.filteredRoyaltyCategoryList = this.royaltyCategoryList.filter(item =>
               (item == undefined || item == null || !item.id) ||
               (item.name.toUpperCase().indexOf(filterValue.toUpperCase()) > -1) ||
               (item.shortName.toUpperCase().indexOf(filterValue.toUpperCase()) > -1));
         } else {
            this.filteredRoyaltyCategoryList = this.royaltyCategoryList;
         }
      }
   }


   /*********************** GARMENT **************************** */

   getGarmentList() {
      this.productMetadataService.getGarmentList().subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.garmentList = response.responsePayload;
            this.filteredGarmentList = this.garmentList;
            this.filteredGarmentList = this.sharedService.sortListByPropertyName(this.filteredGarmentList, 'name');
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      });
   }

   addGarment() {
      this.garmentList.push(new MetadataGarment(true, (this.garmentList?.length) + 1));
   }

   editGarment() {
      this.filteredGarmentList = this.garmentList;
      this.garmentEditable = true;
   }

   cancelGarment() {
      this.garmentEditable = false;
      this.getGarmentList();
   }

   removeGarment(index) {
      this.garmentList.splice(index, 1);
   }

   saveGarment() {
      const keysToValidateDuplicateEntry = ["name"];
      if (this.hasDuplicateEntryErrors(this.garmentList, keysToValidateDuplicateEntry)) {
         this.toastrService.error("Error !!! - Garment with same [" + keysToValidateDuplicateEntry + "] already Exists.");
         return;
      }

      this.productMetadataService.saveGarmentList(this.garmentList).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.garmentList = response.responsePayload;
            this.toastrService.success(response.responseStatus.message);
            this.garmentEditable = false;
            this.getGarmentList();
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      });
   }

   garmentDrop(event: CdkDragDrop<string[]>) {
      moveItemInArray(this.garmentList, event.previousIndex, event.currentIndex);
      this.setGarmentMetadataSortOrder();
   }

   setGarmentMetadataSortOrder() {
      if (this.garmentList != undefined && this.garmentList != null && this.garmentList.length > 0) {
         for (let index = 0; index < this.garmentList.length; index++) {
            this.garmentList[index].sortOrder = index + 1;
         }
      }
   }

   onKeyupGarmentFilter(event, filterValue) {
      if (event.key !== 'Shift') {
         if (filterValue.trim().length > 0) {
            this.filteredGarmentList = this.garmentList.filter(item =>
               (item == undefined || item == null || !item.id) ||
               (item.name.toUpperCase().indexOf(filterValue.toUpperCase()) > -1) ||
               (item.shortName.toUpperCase().indexOf(filterValue.toUpperCase()) > -1));
         } else {
            this.filteredGarmentList = this.garmentList;
         }
      }
   }



   /*********************** PRODUCT CONFIG **************************** */

   getProductConfigList() {
      this.productMetadataService.getProductConfigList().subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.productConfigList = response.responsePayload;
            this.filteredProductConfigList = this.productConfigList;
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      });
   }

   addProductConfig() {
      this.productConfigList.push(new MetadataProductConfig(true, (this.productConfigList?.length) + 1));
   }

   editProductConfig() {
      this.filteredProductConfigList = this.productConfigList;
      this.productConfigEditable = true;
   }

   cancelProductConfig() {
      this.productConfigEditable = false;
      this.getProductConfigList();
   }

   removeProductConfig(index) {
      this.productConfigList.splice(index, 1);
      this.setProductConfigMetadataSortOrder();
   }


   cloneProductConfig(index: number) {

      let tmpList: MetadataProductConfig[] = [];
      tmpList = cloneDeep(this.productConfigList);

      const objectToClone = this.productConfigList[index];
      const clonedObject = { ...objectToClone };
      clonedObject.id = undefined
      console.log(clonedObject);

      tmpList.splice(index + 1, 0, clonedObject);

      this.productConfigList = [];

      tmpList.forEach(item => {
         this.productConfigList.push(item);
      });

      this.filteredProductConfigList = this.productConfigList;
      this.setProductConfigMetadataSortOrder();

   }


   saveProductConfig() {
      const keysToValidateDuplicateEntry = ['productGarment', 'productCategory'];

      if (this.checkIfGarmentDuplicateEntryExists(this.productConfigList, keysToValidateDuplicateEntry)) {
         this.toastrService.error("Error !!! - Garment with same [" + keysToValidateDuplicateEntry + "] already Exists.");
         return;
      }

      /* if (!this.isValidGarmentAndRoyaltyProductCategory()) {
          return;
       }*/

      this.productMetadataService.saveProductConfigList(this.productConfigList).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.productConfigList = response.responsePayload;
            this.toastrService.success(response.responseStatus.message);
            this.productConfigEditable = false;
            this.getProductConfigList();
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      });
   }

   productConfigDrop(event: CdkDragDrop<string[]>) {
      moveItemInArray(this.productConfigList, event.previousIndex, event.currentIndex);
      this.setProductConfigMetadataSortOrder();
   }

   setProductConfigMetadataSortOrder() {
      if (this.productConfigList != undefined && this.productConfigList != null && this.productConfigList.length > 0) {
         for (let index = 0; index < this.productConfigList.length; index++) {
            this.productConfigList[index].sortOrder = index + 1;
         }
      }
   }

   onKeyupProductConfigFilter(event, filterValue) {
      if (event.key !== 'Shift') {
         if (filterValue.trim().length > 0) {
            this.filteredProductConfigList = this.productConfigList.filter(item =>
               (item == undefined || item == null || !item.id) ||
               (item.productGarment.toUpperCase().indexOf(filterValue.toUpperCase()) > -1) ||
               (item.royaltyProductCategory.toUpperCase().indexOf(filterValue.toUpperCase()) > -1) ||
               (item.sageCategory.toUpperCase().indexOf(filterValue.toUpperCase()) > -1) ||
               (item.sageSubCategory.toUpperCase().indexOf(filterValue.toUpperCase()) > -1) ||
               (item.productCategory.toUpperCase().indexOf(filterValue.toUpperCase()) > -1));
         } else {
            this.filteredProductConfigList = this.productConfigList;
         }
      }
   }


   /********************** Validations *********************************** */

   hasDuplicateEntryErrors(list: any, keysToValidateDuplicateEntry: any): boolean {
      let hasErrors = false;
      const unique = [];
      for (const item of list) {
         const duplicate = unique.find(function (obj) {
            for (const key of keysToValidateDuplicateEntry) {
               console.log('obj[key].toUpperCase()', obj[key].trim().toUpperCase());
               console.log('item[key].toUpperCase()', item[key].trim().toUpperCase());
               if (obj[key].trim().toUpperCase() === item[key].trim().toUpperCase()) {
                  return obj[key].trim().toUpperCase() === item[key].trim().toUpperCase();
               } else {
                  continue;
               }
            }
         });

         if (!duplicate) {
            unique.push(item);
            item.hasErrors = false;
         } else {
            item.hasErrors = true;
            hasErrors = true;
         }
      }
      console.log('hasDuplicateEntryErrors = ', hasErrors, list);
      return hasErrors;
   }


   checkIfGarmentDuplicateEntryExists(list: any, keysToValidateDuplicateEntry: any): boolean {
      return new Set(list.map(v => JSON.stringify(keysToValidateDuplicateEntry.map(key => v[key])))).size < list.length;
   }


   isValidGarmentAndRoyaltyProductCategory(): boolean {
      var garmentsGroup = _.groupBy(this.productConfigList, 'productGarment');
      console.log('garmentsGroup = ', garmentsGroup);
      //let groupedGarmentList = Object.keys(garmentsGroup).map(key => ({garments: garmentsGroup[key] }));
      //console.log('list = ', groupedGarmentList);

      //var rpcGroup = _.groupBy(groupedGarmentList, 'royaltyProductCategory');
      //console.log("rpcGroup = ", rpcGroup);

      for (const garment of Object.keys(garmentsGroup)) {
         let groupedGarmentList = garmentsGroup[garment];
         //let groupedGarmentList = Object.keys(garmentsGroup).map(key => ({garments: garmentsGroup[key] }));
         console.log('list = ', groupedGarmentList);

         var rpcGroup = _.groupBy(groupedGarmentList, 'royaltyProductCategory');
         console.log("Garment = ", garment, "rpcGroup = ", rpcGroup);

         let rpgGroupLength: number = Object.keys(rpcGroup).length;

         console.log("RPG Group Size = ", rpgGroupLength);

         if (rpgGroupLength > 1) {
            this.toastrService.error("Garment [" + garment + "] has More than 1 Royalty Product Category.");
            return false;
         }
      }

      return true;

   }

   /*************************************************************** */

   goBack() {
      this.location.back()
   }

}

