<div>
   <div class="row mt-2 d-flex justify-content-center">
      <h2 class="d-inline">
         Charge Back Summary
      </h2>
   </div>
   <div>
      <div class="row">
         <div class="col-sm-6" *ngIf="currentLoggedInUser && currentLoggedInUser!==null">
            <app-search #searchComponent [placeholder]="'CB#, Customer CB#, Customer Name etc. (space delimited)'" [searchConfig]="searchConfig" [existingList]="chargeBackList"
               [sortColumns]="sortColumns">
            </app-search>
         </div>

         <div class="col-sm-2" *ngIf="userAccess?.isSupportAdmin">
            <div class="d-flex d-inline ">
               <div>
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">ChargeBack Status</label>
                  <select class="form-control form-control-sm custom-select mr-1" id="selectedChargeBackStatus" name="selectedChargeBackStatus" [(ngModel)]="selectedChargeBackStatus"
                     (change)="changeStatusAndSearch()">
                     <option value="ALL">Show All</option>
                     <option value="ACTIVE">Active</option>
                     <option value="DELETED">Deleted</option>
                  </select>
               </div>
            </div>
         </div>

         <div class="col-sm-4 mb-2 pr-4" style="align-self: end;">
            <div class="row justify-content-end">
               <div class="col-4">
                  <button id="sendEmail" type="button" class="btn btn-block  btn-sm mb-2 bg-navy-blue text-white" (click)="sendChargeBackEmail()"
                     *ngIf="userAccess?.canMaintainChargeBack && showEmailButton()" matTooltip="Send ChargeBack Email" matTooltipPosition="left" matTooltipClass="bg-dark"><i
                        class="fa fa-envelope text-light fa-sm"></i>&nbsp;&nbsp;Email</button>
               </div>
               <div class="col-4">
                  <button id="salesOrderSelectionModalToggler" type="button" class="btn btn-block btn-sm wrap ml-1 text-white" (click)="showSalesOrderListModalAction(true)" data-toggle="modal"
                     data-target="#selectSalesOrderModal" *ngIf="userAccess?.canMaintainChargeBack" style="background-color: #17a2b8;" matTooltip="Create New Charge Back" matTooltipPosition="above"
                     matTooltipClass="bg-dark"><i class="fas fa-plus fa-sm text-light"></i>&nbsp;&nbsp;New</button>
               </div>

               <div class="col-4">
                  <button type="button" class="btn btn-block btn-sm bg-success ml-2 text-white" *ngIf="true" (click)="createChargeBackExcel()"><i class="fas fa-file-excel fa-md"
                        style="color:white"></i>&nbsp;&nbsp;Export</button>
               </div>
            </div>
         </div> 

      </div>

      <div class="mb-5">
         <ng-container>
            <table class=" f-10 table table-sm table-bordered customtablehover table-condensed">
               <thead class="thead-dark" style="position: sticky;top: 0">
                  <tr class="text-center">
                     <th style="width: 5%;">Actions</th>
                     <th>Philcos<br />CB#</th>
                     <th>Sales Order<br />Number</th>
                     <th>Customer<br />PO#</th>
                     <th>Customer<br />CB#</th>
                     <th>Customer<br />Number</th>
                     <th>Customer<br />Name</th>
                     <th>CB<br />Amount</th>
                     <th>Currency</th>
                     <th>Document<br />Date</th>
                     <th>Sage Entry <br />Date</th>
                     <th>Category</th>
                     <th>Reason</th>
                     <th>Vendor<br />PO#</th>
                     <th>Vendor<br />Name</th>
                     <th>Amount<br />Recovered</th>
                     <th>Vendor<br />Status</th>
                     <th>Customer<br />Status</th>
                     <th style="width: 1%;">Documents</th>
                  </tr>
               </thead>
               <tbody>
                  <ng-container *ngFor="let cb of chargeBackList; index as cbIndex;">
                     <tr class="text-center border" [style.background-color]="cb.status ==='DELETED'? 'rgb(238 6 6 / 32%)' : (cbIndex%2===1?'rgba(0,0,0,.05)':'')">
                        <td class="align-middle text-left wrap pl-2">
                           <ng-container *ngIf="(cb && cb.status  !== 'DELETED') ">
                              <div class="d-flex" style="align-items: center;">
                                 <ng-container *ngIf="userAccess?.canMaintainChargeBack">
                                    <a class="btn" style="border: 0px;" class="ml-1 mr-1" *ngIf="userAccess?.canDeleteChargeBack && !cb.editable" (click)="deleteChargeBack(cb)"><i class="fas fa-trash fa-1x"
                                          style="color: #dc3545;cursor: pointer;"></i></a>
                                    <button type="button" class="btn btn-sm" *ngIf="!editMode && !cb.editable" (click)="editChargeBack(cb, cbIndex)"><i class="fas fa-pencil-alt fa-sm"></i></button>
                                    <button type="button" class="btn btn-sm" *ngIf="editMode && cb.editable" (click)="saveChargeBack(cb, cbIndex)"><i class="fas fa-save fa-lg"
                                          style="color:green"></i></button>
                                    <button type="button" class="btn btn-sm p-0" *ngIf="editMode && cb.editable" (click)="cancelEditChargeBack(cb, cbIndex)"><i class="fas fa-window-close fa-lg"
                                          style="color:red"></i></button>
                                 </ng-container>
                                 <div class="ml-1" (click)="cb.expand?cb.expand=!cb.expand:cb.expand=true;">
                                    <span *ngIf="!cb.expand" (click)="loadAttachments(cb)"><i class="fas fa-chevron-down fa-2x"></i></span>
                                    <span *ngIf="cb.expand"><i class="fas fa-chevron-up fa-2x"></i></span>
                                 </div>
                                 <div class="d-inline custom-control custom-checkbox ml-2 mt-1" *ngIf="(!editMode && cb.vendorId) || (!editMode && !cb.vendorId && cb.vendorName)">
                                    <input type="checkbox" class="custom-control-input " name="cb.id_{{cbIndex}}" id="cb.id_{{cbIndex}}" (click)="selectionChanged(cb)"
                                       [checked]="isChargeBackSelected(cb.id)">
                                    <label class="custom-control-label text-break pt-1" style="color:#212529" for="cb.id_{{cbIndex}}"></label>
                                 </div>
                              </div>
                           </ng-container>
                        </td>

                        <td class="align-middle text-nowrap">
                           <ng-container *ngIf="cb?.status!=='DELETED'"><a [routerLink]="['/chargeback/view/'+cb.id]">{{cb.chargeBackNo}}</a></ng-container>
                           <ng-container *ngIf="cb?.status==='DELETED'">{{cb.chargeBackNo}}</ng-container>
                        </td>
                        <td style="width: auto;" class="align-middle text-nowrap">{{cb.salesOrderNo}}</td>
                        <td style="width: auto;" class="align-middle text-nowrap">{{cb.customerPoNumber}}</td>
                        <td style="width: auto;" class="align-middle text-nowrap">{{cb.customerChargeBackNo}}</td>
                        <td style="width: auto;" class="align-middle text-nowrap">{{cb.customerNo}}</td>
                        <td style="width: auto;" class="align-middle text-nowrap">{{cb.customerName}}</td>
                        <td style="width: auto;" class="align-middle text-right text-nowrap pr-2">{{cb.customerChargeBackAmount | currency:cb?.currency}}</td>
                        <td style="width: auto;" class="align-middle text-nowrap">{{cb.currency}}</td>
                        <td style="width: auto;" class="align-middle text-nowrap">{{cb.customerDocumentDate | date:'mediumDate'}}</td>
                        <td style="width: auto;" class="align-middle text-nowrap">{{cb.sageEntryDate | date:'mediumDate'}}</td>
                        <td style="width: auto;" class="align-middle text-nowrap">
                           <ng-container *ngIf="!cb.editable">
                              <div class="justify-content-between" style="align-items: center;">
                                 <span>{{cb?.category}}</span>
                              </div>
                           </ng-container>
                           <ng-container *ngIf="cb.editable">
                              <div class="">
                                 <select class="form-control form-control-sm" [(ngModel)]="cb.category" name="category{{cbIndex}}" #cbCategory="ngModel" style="width:100px; font-size: 0.65rem;"
                                    [ngClass]="{'is-invalid':cbCategory.touched && cbCategory.errors}" [disabled]="false">
                                    <option *ngFor="let category of cbCategoryList" [ngValue]="category">
                                       {{category}}</option>
                                 </select>
                                 <div *ngIf="cbCategory?.invalid && (cbCategory.dirty || cbCategory.touched)" class="invalid-feedback">
                                    <div *ngIf="cbCategory.errors.required">
                                       Category is required.
                                    </div>
                                 </div>
                              </div>
                           </ng-container>
                        </td>
                        <td style="width: auto;" class="align-middle text-nowrap">
                           <ng-container *ngIf="!cb.editable">
                              <div class="justify-content-between" style="align-items: center;">
                                 <span>{{cb?.reason}}</span>
                              </div>
                           </ng-container>
                           <ng-container *ngIf="cb.editable">
                              <div class="">
                                 <select class="form-control form-control-sm" [(ngModel)]="cb.reason" name="reason{{cbIndex}}" #cbReason="ngModel" style="width:100px; font-size: 0.65rem;"
                                    [ngClass]="{'is-invalid':cbReason.touched && cbReason.errors}" [disabled]="false">
                                    <option *ngFor="let reason of cbReasonList" [ngValue]="reason">
                                       {{reason}}</option>
                                 </select>
                                 <div *ngIf="cbReason?.invalid && (cbReason.dirty || cbReason.touched)" class="invalid-feedback">
                                    <div *ngIf="cbReason.errors.required">
                                       Reason is required.
                                    </div>
                                 </div>

                              </div>
                           </ng-container>
                        </td>

                        <td style="width: auto;" class="align-middle text-nowrap">{{cb.vendorPoNo}}</td>
                        <td style="width: auto;" class="align-middle text-nowrap">{{cb.vendorName}}</td>
                        <td style="width: auto;" class="align-middle text-right text-nowrap pr-2">
                           <ng-container *ngIf="!cb.editable">
                              {{cb.amountRecovered}}
                           </ng-container>
                           <ng-container *ngIf="cb.editable">
                              <input name="amtRecovered_{{cbIndex}}" type="number" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #amtRecoveredVar="ngModel"
                                 [disabled]="false" [(ngModel)]="cb.amountRecovered" [customMin]="0">
                           </ng-container>
                        </td>
                        <td style="width: auto;" class="align-middle text-nowrap">
                           <ng-container *ngIf="!cb.editable">
                              <div class="justify-content-between" style="align-items: center;">
                                 <span>{{cb?.vendorStatus}}</span>
                              </div>
                           </ng-container>
                           <ng-container *ngIf="cb.editable">
                              <div class="">
                                 <select class="form-control form-control-sm" [(ngModel)]="cb.vendorStatus" name="vendorStatus{{cbIndex}}" #vendorStatus="ngModel"
                                    style="width:auto; font-size: 0.65rem;" [ngClass]="{'is-invalid':vendorStatus.touched && vendorStatus.errors}" [disabled]="false">
                                    <option *ngFor="let vendorStatus of cbVendorStatusList" [ngValue]="vendorStatus">
                                       {{vendorStatus}}</option>
                                 </select>
                                 <div *ngIf="vendorStatus?.invalid && (vendorStatus.dirty || vendorStatus.touched)" class="invalid-feedback">
                                    <div *ngIf="vendorStatus.errors.required">
                                       Vendor Status is required.
                                    </div>
                                 </div>

                              </div>
                           </ng-container>
                        </td>
                        <td style="width: auto;" class="align-middle text-nowrap">
                           <ng-container>
                              <div class="justify-content-between" style="align-items: center;">
                                 <span>{{cb?.customerStatus}}</span>
                              </div>
                           </ng-container>
                           <!--
                           <ng-container *ngIf="cb.editable">
                              <div class="">
                                 <select class="form-control form-control-sm" [(ngModel)]="cb.customerStatus" name="customerStatus{{cbIndex}}" #customerStatus="ngModel"
                                    style="width:auto; font-size: 0.65rem;" required [ngClass]="{'is-invalid':customerStatus.touched && customerStatus.errors}" [disabled]="false">
                                    <option *ngFor="let customerStatus of cbCustomerStatusList" [ngValue]="customerStatus">
                                       {{customerStatus}}</option>
                                 </select>
                                 <div *ngIf="customerStatus?.invalid && (customerStatus.dirty || customerStatus.touched)" class="invalid-feedback">
                                    <div *ngIf="customerStatus.errors.required">
                                       Customer Status is required.
                                    </div>
                                 </div>

                              </div>
                           </ng-container>
                           -->
                        </td>

                        <td style="width: auto;" class="align-middle text-left text-nowrap pl-3 pr-3">
                         
                           <!--
                           <div>
                              <span class=" f-10 badge badge-primary">Documents
                                 <span class="badge badge-light">{{cb?.cbDocumentsCount}}</span>
                              </span>
                           </div>
                        
                        -->
                           <div>
                              <span><i class="fas fa-file-contract fa-lg" style="color:black"></i></span>
                              <span class="f-12">&nbsp;{{cb?.cbDocumentsCount}}</span>
                           </div>
                           

                           <!--<div>
                              <span *ngIf="!cb.expand"><i class="fas fa-chevron-down"></i></span>
                              <span *ngIf="cb.expand"><i class="fas fa-chevron-up"></i></span>
                           </div>
                        -->
                        </td>
                     </tr>
                     <tr *ngIf="cb.expand " class="border-top-0 border" style="background-color: #ff634712;">
                        <td colspan="9999">
                           <div class="row f-10">
                              <div class="col-5" style="width: calc(70vw);">
                                 <app-attachment-operations [header]="'Charge Back Documents'" [editable]="cb.editable" [createAction]="cb.editable" [attachments]="cb.chargeBackAttachments"
                                    [canUpload]="userAccess?.canMaintainChargeBackFiles" [canRenameFile]="userAccess?.canMaintainChargeBackFiles" [canDownload]="userAccess?.canDownloadChargeBackFiles"
                                    [canDelete]="userAccess?.canMaintainChargeBackFiles" [category]="'SALES_CHARGE_BACK_COMMUNICATION_FILES'" [showHeader]="true" [headerSize]="'6'"
                                    [canQuickView]="userAccess?.canViewChargeBackFiles" [isGroupedAttachments]="true" [groupItems]="getGroupItemForChargeBack(cb)"
                                    [groupItemDisplayVar]="'chargeBackNo'" [groupItemReferenceVar]="'id'">
                                 </app-attachment-operations>
                              </div>
                              <div class="col-1">
                              </div>

                              <div class="col-5" style="width: calc(70vw);">
                                 <div>
                                    <h6>Charge Back Notes</h6>
                                    <hr>
                                 </div>
                                 <app-notes [editable]="true" [parentId]="cb.id" [type]="'SALES_ORDER'" [subType]="'CHARGE_BACK'" [notesHeaderText]="''"></app-notes>
                              </div>
                           </div>
                        </td>
                     </tr>
                  </ng-container>
               </tbody>
            </table>
         </ng-container>
      </div>

   </div>


   <div class="modal fade" id="selectSalesOrderModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="selectSalesOrderModalTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable " style="max-width: 97%;" role="document">
         <div class="modal-content">
            <div class="modal-body">
               <div *ngIf="showSalesOrderListModal">
                  <app-sales-order-list [isSelectionMode]="true"></app-sales-order-list>
               </div>
            </div>
            <div class="modal-footer p-1 mr-5" style="background-color: #f7f7f7;">
               <button type="button" class="btn btn-sm btn-info" data-dismiss="modal" (click)="createSelectedChargeBack()">Select</button>
               <button type="button" class="btn btn-sm btn-secondary mr-7" data-dismiss="modal" (click)="cancelSelection()">Cancel</button>
            </div>
         </div>
      </div>
   </div>


   <button id="composeEmailToggler" type="button" style="display: none;" data-toggle="modal" data-target="#composeEmail"></button>
   <div *ngIf="showEmailEditor">
      <app-compose-email [modalId]="'composeEmail'" [emailHeader]="'Email'" [composeEmail]="composeEmail" [forceHideAttachmentAsLinkCheckBox]="true" [showHtmlEditor]="true"
         [showRichTextEditor]="false"></app-compose-email>
   </div>




   <div class="floatBtn d-flex flex-column">

      <!--
      <button id="sendEmail" type="button" class="btn btn-circle btn-sm mb-2 bg-navy-blue" (click)="sendChargeBackEmail()"   *ngIf="userAccess?.canMaintainChargeBack && showEmailButton()" 
         matTooltip="Send ChargeBack Email" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fa fa-envelope text-light fa-2x"></i></button>
      <a id="salesOrderSelectionModalToggler" (click)="showSalesOrderListModalAction(true)" class="btn btn-circle text-white mb-2" data-toggle="modal" data-target="#selectSalesOrderModal" *ngIf="userAccess?.canMaintainChargeBack"
         style="background-color: #17a2b8;" matTooltip="Create New Charge Back" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-plus fa-2x text-light"></i></a>
      <button type="button" class="btn btn-circle bg-success mb-2" *ngIf="true" (click)="createChargeBackExcel()"><i class="fas fa-file-excel fa-2x" style="color:white"></i></button>
   -->
      <button type="button" class="btn btn-circle bg-secondary mb-2" matTooltip="Scroll To Top" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'});" matTooltipPosition="left"
         matTooltipClass="bg-dark"> <i class="fas fa-arrow-up fa-2x" style="color:white"></i>
      </button>
   </div>
</div>