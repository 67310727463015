<div class="container">

  <div class="row mt-2 d-flex justify-content-center">
      <h2 class="d-inline">Users</h2>
      <button type="button" [routerLink]="['/user/create']" *ngIf="userAccess?.canCreateUser" class="btn btn-circle btn-circle-md d-inline ml-4" matTooltip="Create New User" matTooltipPosition="right" matTooltipClass="bg-dark"><i class="fas fa-plus fa-lg text-light"></i></button>
  </div>
  <hr >

  <div class="row">
    <div class="col-sm-4">
      <input class="form-control mb-2" #search placeholder="Filter by User First Name.."  (keyup)="onKeyupUserFilter($event,search.value)">
    </div>
    <div class="col-sm-4 mt-2">
      Found {{filteredUserList?.length}} {{search.value.length>0? 'Users found for "'+search.value+'"' : 'Users'}}
    </div>
    <div class="col-sm-4 mt-2 text-right">
      <div class="custom-radio-button">
         <div class="mr-4">
            <input type="radio" id="userStatusActive" name="userStatusRadio" [(ngModel)]="userStatusRadio" value="true" (click)="statusChange('true')">
            <label for="userStatusActive"><span></span>Active</label>
         </div>
         <div>
            <input type="radio" id="userStatusInActive" name="userStatusRadio" [(ngModel)]="userStatusRadio"  value="false" (click)="statusChange('false')">
            <label for="userStatusInActive"><span></span>Inactive</label>
         </div>
      </div>
    </div>
  </div>


  <div class="row gallery-block cards-gallery mt-4">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-4" *ngFor="let user of filteredUserList;index as i">
          <div class=" card border-0 transform-on-hover h-100 u-color-{{i%4}}" style="margin-bottom: 2px;">
            <a [routerLink]="['/user/view/'+user.id]" >
              <div class="card-body" style="padding: 0.25rem;">
                <div class=" ml-1 mr-1" >
                  <div class=" d-flex justify-content-left">
                    <button class="btn bnt-lg bg-dark text-white ">{{user.firstName.charAt(0).toUpperCase()}}{{user.lastName?.charAt(0).toUpperCase()}}</button>
                  </div>
                  <div class="mb-1" >
                    <h5 class="text-break  ml-2 mr-2 card-text justify-content-center">{{user.firstName.toUpperCase()}}</h5>
                    <h5 class="text-break  ml-2 mr-2 card-text justify-content-center">{{user.lastName.toUpperCase()}}</h5>
                  </div>
                  <p class="card-text">{{user.email}}</p>
                </div>
                <p class="card-text">{{user.active?'Active':'Inactive'}}</p>
                <!--<button class="btn  btn-circle btn-circle-sm d-inline" (click)="deleteUser(user)" *ngIf="userAccess?.canDeleteUser">
                  <i class="fas fa-trash text-light"></i>
                </button>-->
              </div>
            </a>
          </div>
      </div>
  </div>   
</div>