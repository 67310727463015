import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Role } from '../model/role.model';

@Injectable({
  providedIn: 'root'
})


export class RoleService {
  private basePath = environment.apiEndPoint;
  readonly SERVICE_NAME = 'Auth Service';
  
  constructor(private httpClient: HttpClient) {
  }

  loadRoles() :Observable<any>{
    return this.httpClient.post<any>(this.basePath+'/user/roles/list',null);
  }

  loadUserRoles(userId: string) :Observable<any>{
    return this.httpClient.post<any>(this.basePath+'/user/roles/'+userId, userId);
  }

  loadUserRolesAndPermissions(userId) :Observable<any>{
    return this.httpClient.post<any>(this.basePath+'/user/roles/permissions/'+userId,userId);
  }

   loadRoleById(id: string): Observable<any> {
      return this.httpClient.post<any>(this.basePath + '/role/id/'+id, null);
   }

   createRole(role: Role): Observable<any> {
      return this.httpClient.post<any>(this.basePath + '/role/create', role);
   }

   updateRole(role: Role): Observable<any> {
      return this.httpClient.post<any>(this.basePath + '/role/update', role);
   }

   updateRoleWithSelectedPermissions(role: Role): Observable<any> {
      return this.httpClient.post<any>(this.basePath + '/role/permissions/update', role);
   }

   deleteRole(role:Role): Observable<any>{
      return this.httpClient.post<any>(this.basePath + '/role/delete', role);
   }

}
