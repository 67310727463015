import { Brand } from './brand.model';
import { ContactDetails } from './contact-details.model';
import { LinkDetails } from './link-details.model';
import { ProductAttachment } from './product.model';
import { GroupContactDetails } from './group-contact-details.model';

export class Licensor {
    id:string;
    licensorName:string;
    licensorStatus:boolean;
    comments:string;
    groupContactDetails:GroupContactDetails[]=[];
    brands: Brand[];
    linkDetails:LinkDetails[]=[];
    licensorColor:string;
    licensorSampleDetails :LicensorSampleDetails[] =[];
    licensorFiles:ProductAttachment[]=[];
    licensorContractFiles:ProductAttachment[]=[];
    licensorArtistSignOffFiles:ProductAttachment[]=[];
    licensorFactoryFormsFiles:ProductAttachment[]=[];
    changeBrandStatusOnLicensorStatusChange: boolean = false;
}

export class LicensorSampleDetails {
   licensorId: string;
   brandId: string;
   brandName: string;
   selectedSampleSizesList: LicensorSample[]=[];
   allSampleSizesList: LicensorSample[]=[];
   sumOfPreProductionSampleQty:number;
   sumOfFinalSampleQty:number;
   sumOfPreProPhotoSampleQty:number;
}

export class LicensorSample {
   id: string;
   licensorId: string;
   brandId: string;
   size: string;
   preProductionSampleQuantity: number;
   finalSampleQuantity: number;
   photoSampleQuantity: number;
}

export class LicensorCostMatrix {
   id: string;
   licensorId: string;
   brandId: string;
   garment:string;
   garmentCode:string;
	retailCost:number;
    wholesaleCost:number;
}

