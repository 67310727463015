import { ExternalLinks } from "../../common/external-links.model";

export class BlanksInfo {
    id:string;
    salesOrderId:string;
    //printerName:string;
    supplierId:string;
    blankSupplierName:string;
    philcosBlanksPO:string;
    invoiceValue:number;
    invoiceCurrency:String;
    blanksEta:Date;
    printerDeliveryDate:Date;
    blanksStatus:string;
    blanksTrackingNo:string;
    blanksTrackingLinks:ExternalLinks[]=[];
    //blanks:Notes[]=[];
    deleteFlag:boolean=false;
    origination:string;
    version:number=0;
    obsolete:boolean;
    printerId:string;
    printerName:string;
    constructor(salesOrderId:string){
        this.origination='MANUAL';
        this.salesOrderId=salesOrderId;
    }
}
