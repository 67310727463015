import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from 'src/app/shared/service/auth.service';
import { User } from 'src/app/shared/model/user.model';
import { Subscription, Observable } from 'rxjs';
import { SharedService } from '../shared/service/shared.service';
import { UserAccess } from '../shared/model/user-access.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { SalesService } from '../shared/service/sales/sales.service';

@Component({
   selector: 'app-navbar',
   templateUrl: './navbar.component.html',
   styleUrls: ['./navbar.component.css'],
   animations: [
      trigger('fadeInOut', [
         state('void', style({
           opacity: 0
         })),
         transition('void => *', animate(800))
       ])
   ]
})

export class NavbarComponent implements OnInit {
   width = document.documentElement.clientWidth;
   isUserLoggedInSubscription: Subscription;
   isUserLoggedIn: boolean;

   currentUserSubscription: Subscription;
   currentUser: User;

   userAccessSubscription: Subscription;
   userAccess: UserAccess;

   isUserSectionActive: boolean;
   isLicensorSectionActive: boolean;
   isBrandSectionActive: boolean;
   isProductSectionActive: boolean;
   isCatalogSectionActive: boolean;
   isMetadataSectionActive: boolean;
   isProductMetadataSectionActive: boolean;
   isRoleSectionActive: boolean;
   isCustomerSectionActive:boolean;
   isPrinterSectionActive:boolean;
   isBlanksSupplierSectionActive:boolean;
   isAdminSectionActive: boolean;
   isUpcOverviewSectionActive:boolean;
   isLicensorSampleOverviewSectionActive:boolean;
   isReportSectionActive: boolean;
   isLicensingSectionActive:boolean;
   isArtSectionActive:boolean;
   isArtTrackerSectionActive:boolean;
   isRightsOverviewActive:boolean;
   isRoyaltyOverviewActive:boolean;
   isRoyaltySectionActive:boolean;
   isSkuTrackerSectionActive:boolean;
   isSkuTrackerDashboardSectionActive:boolean;
   isSalesDashboardSectionActive:boolean;
   isSalesSectionActive:boolean;
   isCostingSectionActive:boolean;
   isEstimationSectionActive:boolean;
   isEstimationListSectionActive:boolean;
   isEstimationPendingListSectionActive:boolean;
   isEstimationApprovalPendingListSectionActive:boolean;
   isPdfFormActive:boolean;
   showProgressArea:boolean=true;
   isPrinterPortalSectionActive:boolean;
   isTaskSectionActive:boolean;
   isTaskListSectionActive: boolean;
   isProductionSectionActive: boolean
   isProductionTrackerSectionActive: boolean
   isFileClosureSummarySectionActive: boolean
   isRoyaltyMismatchSectionActive: boolean;
   isPrinterCapacitySectionActive: boolean;
   isChargeBackSectionActive: boolean;
   isAccountsSectionActive: boolean;
   isDashboardSectionActive: boolean;
   isPreProTrackerSectionActive: boolean;

   isSupportAdminUser:boolean=false;
   

   constructor(private sharedService: SharedService, private authService: AuthService,
      private activatedRoute: ActivatedRoute, private router: Router, private salesService:SalesService,
      public loaderService: LoaderService) {
      //
      //this.sharedService.loadUserRolesAndPermissions(this.sharedService.currentUserValue.id);
      //
   }

   ngOnInit() {
      this.handleSubscriptions();
      this.handleNavSectionActive();
   }

   handleSubscriptions() {
      // Subscriptions
      this.isUserLoggedInSubscription = this.authService.isUserLoggedIn.subscribe(x => {
         this.isUserLoggedIn = x;
         if(this.isUserLoggedIn===true){
           
         }
      })
      this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
         this.currentUser = user;
         this.isSupportAdminUser = this.sharedService.isSupportAdminUser(this.currentUser?.userRoles);
      });
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
         //
      });
   }
  

   handleNavSectionActive() {
      this.router.events.pipe(filter(event => event instanceof NavigationEnd))
         .subscribe((event: NavigationEnd) => {
            this.isUserSectionActive = false;
            this.isLicensorSectionActive = false;
            this.isBrandSectionActive = false;
            this.isProductSectionActive = false;
            this.isCatalogSectionActive = false;
            this.isMetadataSectionActive = false;
            this.isProductMetadataSectionActive = false;
            this.isRoleSectionActive = false;
            this.isCustomerSectionActive = false;
            this.isPrinterSectionActive = false;
            this.isBlanksSupplierSectionActive = false;
            this.isAdminSectionActive=false;
            this.isReportSectionActive=false;
            this.isLicensingSectionActive=false;
            this.isRoyaltySectionActive=false;
            this.isSkuTrackerSectionActive=false;
            this.isSkuTrackerDashboardSectionActive=false;
            this.isSalesDashboardSectionActive=false;
            this.isSalesSectionActive=false;
            this.isCostingSectionActive=false;
            this.isEstimationSectionActive = false;
            this.isEstimationListSectionActive = false;
            this.isEstimationPendingListSectionActive = false;
            this.isEstimationApprovalPendingListSectionActive = false;
            this.isPdfFormActive=false;
            this.isUpcOverviewSectionActive=false;
            this.isLicensorSampleOverviewSectionActive=false;
            this.isRightsOverviewActive=false;
            this.isRoyaltyOverviewActive=false;
            this.isArtSectionActive=false;
            this.isArtTrackerSectionActive=false;
            this.isPrinterPortalSectionActive=false;
            this.isTaskSectionActive = false;
            this.isTaskListSectionActive = false;
            this.isProductionSectionActive = false;
            this.isProductionTrackerSectionActive = false;
            this.isFileClosureSummarySectionActive = false;
            this.isRoyaltyMismatchSectionActive = false;
            this.isPrinterCapacitySectionActive = false;
            this.isAccountsSectionActive = false;
            this.isChargeBackSectionActive = false;
            this.isDashboardSectionActive = false;
            this.isPreProTrackerSectionActive = false;

            if (event.url.includes('user')) {
               this.isUserSectionActive = true;
            }
            if (event.url.includes('licensor')) {
               this.isLicensorSectionActive = true;
            }
            if (event.url.includes('brand')) {
               this.isBrandSectionActive = true;
            }
            if(event.url.includes('sku-tracker-list')){
               this.isSkuTrackerSectionActive=true;
            }
            if(event.url.includes('dashboard/sku-tracker')){
               this.isSkuTrackerDashboardSectionActive=true;
            }
            if(event.url.includes('/prepro-tracker-list')){
               this.isPreProTrackerSectionActive=true;
            }
            if(event.url.includes('dashboard/sales-dashboard')){
               this.isSalesDashboardSectionActive=true;
            }

            if(event.url.endsWith('printercapacity/list')){
               this.isPrinterCapacitySectionActive=true;
            }
            if(event.url.endsWith('royalty/mismatch/list')){
               this.isRoyaltyMismatchSectionActive=true;
            }
            if(event.url.includes('costing') || this.isRoyaltyMismatchSectionActive || this.isPrinterCapacitySectionActive){
               this.isCostingSectionActive=true;
            }

            if(event.url.includes('estimation/list')){
               this.isEstimationListSectionActive=true;
            }            
            if(event.url.includes('estimation/pending/list')){
               this.isEstimationPendingListSectionActive=true;
            }
            if(event.url.includes('estimation/approval/pending/list')){
               this.isEstimationApprovalPendingListSectionActive=true;
            }
            if(this.isEstimationListSectionActive || this.isEstimationPendingListSectionActive || this.isEstimationApprovalPendingListSectionActive){
               this.isEstimationSectionActive=true;
            }
            if(event.url.includes('salesOrder')){
               this.isSalesSectionActive=true;
            }
            if(this.isLicensorSectionActive || this.isBrandSectionActive || this.isSkuTrackerSectionActive || this.isSkuTrackerDashboardSectionActive || this.isPreProTrackerSectionActive){
               this.isLicensingSectionActive=true;
            }
            if ((event.url.endsWith('product-search') || event.url.includes('similarDesign') || event.url.includes('preview-submission-sheet') || event.url.includes('preview-approval-form')  )&& !event.url.includes('catalog')) {
               this.isProductSectionActive = true;
            }
            if (event.url.includes('catalog')) {
               this.isCatalogSectionActive = true;
            }
            if(event.url.includes('art-tracker-list')){
               this.isArtTrackerSectionActive = true;
            }
            if(this.isProductSectionActive || this.isCatalogSectionActive || this.isArtTrackerSectionActive){
               this.isArtSectionActive=true;
            }
            if (event.url.endsWith('metadata/list')) {
               this.isMetadataSectionActive = true;
            }
            if (event.url.endsWith('metadata/product')) {
               this.isProductMetadataSectionActive = true;
            }
            if (event.url.includes('role')) {
               this.isRoleSectionActive = true;
            }
            if (event.url.includes('UPC_OVERVIEW')) {
               this.isUpcOverviewSectionActive = true;
            }
            if (event.url.includes('LICENSOR_SAMPLE_OVERVIEW')) {
               this.isLicensorSampleOverviewSectionActive = true;
            }
            if (this.isLicensorSampleOverviewSectionActive || this.isUpcOverviewSectionActive) {
               this.isReportSectionActive = true;
            }
            if (event.url.endsWith('rights-overview')) {
               this.isRightsOverviewActive = true;
            }
            if (event.url.endsWith('royalty-overview')) {
               this.isRoyaltyOverviewActive = true;
            }
            
            
            
            if (this.isRoyaltyOverviewActive || this.isRightsOverviewActive) {
               this.isRoyaltySectionActive = true;
            }
            
            if (event.url.includes('pdf-form')) {
               this.isPdfFormActive = true;
            }
            if (event.url.includes('customer')) {
               this.isCustomerSectionActive = true;
            }
            if(event.url.includes('printer-portal')){
               this.isPrinterPortalSectionActive=true;
            }else if (event.url.endsWith('printer/list')) {
               this.isPrinterSectionActive = true;
            }
            if (event.url.includes('blanksSupplier')) {
               this.isBlanksSupplierSectionActive = true;
            }
            
            
            if (this.isUserSectionActive || this.isMetadataSectionActive || this.isProductMetadataSectionActive || this.isRoleSectionActive || this.isPdfFormActive || this.isCustomerSectionActive || this.isPrinterSectionActive || this.isBlanksSupplierSectionActive) {
               this.isAdminSectionActive = true;
            }

            if(event.url.includes('task/list')){
               this.isTaskListSectionActive=true;
            }

            if(this.isTaskListSectionActive){
               this.isTaskSectionActive = true;
            }

            if(event.url.endsWith('production-tracker/list')){
               this.isProductionTrackerSectionActive=true;
            }

            if(event.url.endsWith('fileClosureSummary/list')){
               this.isFileClosureSummarySectionActive=true;
            }
            
            if(this.isProductionTrackerSectionActive || this.isFileClosureSummarySectionActive){
               this.isProductionSectionActive = true;
            }

            
            if (event.url.includes('chargeback')) {
               this.isChargeBackSectionActive = true;
            }
            if (this.isChargeBackSectionActive) {
               this.isAccountsSectionActive = true;
            }

            if(event.url.endsWith('/dashboard')){
               this.isDashboardSectionActive=true;
            }

         });
   }

   ngOnDestroy() {
      // unsubscribe to ensure no memory leaks
      this.currentUserSubscription.unsubscribe();
      this.isUserLoggedInSubscription.unsubscribe();
   }

   logout() {
      this.authService.logout();
   }

  
  toggleProgressBar(){
     this.showProgressArea=!this.showProgressArea;
  }
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if(this.loaderService.filesUploading.length>0){
      return false;
    }else{
    return true;
    }
  }
}
