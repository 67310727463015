import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchCriteriaBuilder } from 'src/app/shared/model/common/search-criteria-builder.model';
import { SalesArtTrackerView } from 'src/app/shared/model/sales/art/sales-art-tracker-view.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesArtTrackerService {
  constructor(private http: HttpClient) { }

  readonly rooturl = environment.apiEndPoint;

  getSalesOrderArtTrackerDetails(salesArtTrackerView:SalesArtTrackerView) {
     return this.http.post<any>(this.rooturl + '/salesArtTracker/sales/printer/art/details/get', salesArtTrackerView);
  }
  saveSalesOrderArtTrackerDetails(salesArtTrackerView:SalesArtTrackerView) {
    return this.http.post<any>(this.rooturl + '/salesArtTracker/sales/printer/art/details/save', salesArtTrackerView);
 }
  
}
