<form name="createUser" (ngSubmit)="onSubmit()" #createUserForm="ngForm" autocomplete="off" novalidate class="mb-3">

   <div class="col-lg-6 container">

      <div class="d-flex justify-content-center mt-3">
         <div class="text-color-{{user.active+'' | replace:' ':'_'|lowercase}}">
            <h2>{{user.firstName|titlecase}} {{user.lastName|titlecase}}&nbsp;</h2>
         </div>
         <div></div>
      </div>
      <div *ngIf="user.active!==undefined" class="separator-{{user.active+'' | replace:' ':'_'|lowercase}}">
         <h6>{{user.active?'Active':'Inactive'}}</h6>
      </div>

      <div class="row shadow-lg mt-4">

         <div class="col mt-4">

            <div class="form-group input-group">
               <!-- <label class="mr-2" style="font-size: medium;" for="active">Status</label>-->
               <div class="input-group-prepend" style="border-top-right-radius: 0.25rem !important;
                  border-bottom-right-radius: 0.25rem !important;">
                  <span class="input-group-text mr-2" style="border-top-right-radius: 0.25rem !important;
                     border-bottom-right-radius: 0.25rem !important;"> <strong>Status:&nbsp;&nbsp;</strong>
                     <label class="switch" style="margin-bottom: 0px;">
                        <input type="checkbox" [(ngModel)]="user.active" name="active"
                           [disabled]="!createAction && !editAction" class="pull-left" id="active">
                        <span class="slider round"></span>
                     </label>
                     &nbsp;&nbsp;({{user.active?'Active':'Inactive'}})
                     <!--The user is {{user.active?'Active':'Inactive'}}-->
                  </span>
               </div>
            </div>
            <div class="form-group input-group">
               <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-envelope"></i> </span>
               </div>
               <input name="email" id="email" [(ngModel)]="user.email" placeholder="Email" class="form-control"
                  #email="ngModel" required [disabled]="(!createAction && !editAction) || (user.isUserRegistered)"
                  [ngClass]="{'is-invalid':email.touched && email.invalid}" email>
               <div *ngIf="email.invalid" class="invalid-feedback">
                  <div *ngIf="email.errors.required">
                     Email address is required.
                  </div>
                  <div *ngIf="email.errors.email">
                     Email must be a valid email address.
                  </div>
               </div>
            </div>

            <div class="form-group input-group">
               <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-user"></i> </span>
               </div>
               <input [(ngModel)]="user.firstName" placeholder="First Name" name="firstName" class="form-control"
                  id="firstName" #firstname="ngModel" required [disabled]="!createAction && !editAction" minlength="2"
                  maxlength="20" [ngClass]="{'is-invalid':firstname.touched && firstname.errors}"
                  style="text-transform: capitalize;">

               <div class="input-group-prepend ml-2">
                  <span class="input-group-text"> <i class="fa fa-user"></i> </span>
               </div>
               <input [(ngModel)]="user.lastName" placeholder="Last name" name="lastName" class="form-control"
                  id="lastName" #lastname="ngModel" required [disabled]="!createAction && !editAction" minlength="1"
                  maxlength="20" [ngClass]="{'is-invalid':lastname.touched && lastname.errors}"
                  style="text-transform: capitalize;">

               <div *ngIf="firstname?.invalid && (firstname.dirty || firstname.touched)" class="invalid-feedback">
                  <div *ngIf="firstname.errors.required">
                     Firstname is required.
                  </div>
                  <div *ngIf="firstname.errors.minlength">
                     Firstname Minimum Length is 3
                  </div>
               </div>

               <div *ngIf="lastname?.invalid && (lastname.dirty || lastname.touched)" class="invalid-feedback">
                  <div *ngIf="lastname.errors.required">
                     Lastname is required.
                  </div>
                  <div *ngIf="lastname.errors.minlength">
                     Lastname Minimum Length is 3
                  </div>
               </div>

            </div>

            <div class="form-group input-group">
               <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-phone"></i> </span>
               </div>
               <input type="text" [(ngModel)]="user.phone" placeholder="Phone" name="phone" class="form-control"
                  id="phone" #phone="ngModel" required [disabled]="!createAction && !editAction" minlength="10"
                  maxlength="14" pattern="\d{10,14}" [ngClass]="{'is-invalid':phone.touched && phone.errors}">
               <div *ngIf="phone?.invalid && (phone.dirty || phone.touched)" class="invalid-feedback">
                  <div *ngIf="phone.errors.required">
                     Phone # is required.
                  </div>
                  <div *ngIf="phone.errors.minlength">
                     Phone # Minimum Length is 10
                  </div>
                  <div *ngIf="phone.errors.pattern">
                     Phone # is incorrect.
                  </div>
               </div>
            </div>
            <div class="row mb-2">
                  <div class="col-3">
                     <label class="form-label">Organization</label>
                     <select class="form-control form-control-sm custom-select" [(ngModel)]="user.orgType"
                                                name="orgTypeVar" #orgTypeVar="ngModel" required [disabled]="!createAction && !editAction" (change)="orgTypeChanged(user)"
                                                [ngClass]="{'is-invalid':orgTypeVar.touched && orgTypeVar.errors}">
                                                <option value="Internal">Internal</option>
                                                <option *ngFor="let orgTypeItem of orgTypeList" [ngValue]="orgTypeItem">
                                                   {{orgTypeItem}}</option>
                                             </select>
                  </div>
                  <div class="col-3" *ngIf="user.orgType!=='Internal'">
                     <label class="form-label">Organization Name</label>
                     <select class="form-control form-control-sm custom-select" [(ngModel)]="user.orgId"
                                                name="orgNameVar" #orgNameVar="ngModel" required [disabled]="!createAction && !editAction"
                                                [ngClass]="{'is-invalid':orgNameVar.touched && orgNameVar.errors}">
                                                <option *ngFor="let orgItem of orgList" [ngValue]="orgItem[orgIdProperty]">
                                                   {{orgItem[orgNameProperty]}}</option>
                                             </select>
                  </div>
            </div>

            <div class="form-group input-group">
               <div class="card form-group input-group">
                  <h6 class="" *ngIf="createAction || editAction">
                     <button type="button" [disabled]="!createAction && !editAction" class="btn btn-info btn-block"
                        data-toggle="modal" data-target="#roleFilterModal">Select User Role(s)</button>
                  </h6>
                  <div class="row ml-2 mr-2">
                     <div class="d-inline m-1 mt-0" *ngFor="let role of user.userRoles;index as i">
                        <div >
                           <div class="chip color-{{i%4}}">
                              {{role.roleDescription}}
                              <span class="closebtn" *ngIf="createAction || editAction"
                                 (click)="selectedRolesForSearch($event,role)">&times;</span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="form-group input-group">
               <button class="btn btn-primary btn-sm mr-2" [disabled]="createUserForm.invalid"
                  *ngIf="createAction">Submit</button>
               <!--<button class="btn btn-info btn-sm mr-2" [disabled]="createUserForm.invalid" *ngIf="editAction">Save</button>
               <a rel="noopener" [routerLink]="['/user/list']" class="btn btn-sm btn-dark mr-2" *ngIf="createAction || editAction">Cancel</a>-->
               <button class="btn btn-info btn-sm" type="button" (click)="sendResetPasswordMail()" *ngIf="editAction"><i
                     class="fa fa-envelope" aria-hidden="true"></i> Password Reset Email</button>
            </div>
            <div class="floatBtn d-flex flex-column">
               <button type="submit" class=" btn btn-circle bg-info mb-2" *ngIf="editAction && !createAction"
                  matTooltip="Save Changes" matTooltipPosition="left" matTooltipClass="bg-dark"
                  [disabled]="createUserForm.invalid">
                  <i class="fas fa-save fa-2x" style="color:white"></i>
               </button>
               <a rel="noopener" [routerLink]="['/user/list']" class=" btn btn-circle bg-dark mb-2"
                  *ngIf="createAction || editAction" matTooltip="Cancel Edit" matTooltipPosition="left"
                  matTooltipClass="bg-dark"><i class="fas fa-times fa-2x" style="color:white"></i></a>
               <a class="btn btn-circle bg-dark mb-2" (click)="edit(user.id)"
                  *ngIf="viewAction && userAccess?.canEditUser" matTooltip="Edit" matTooltipPosition="left"
                  matTooltipClass="bg-dark"> <i class="fas fa-pencil-alt fa-lg" style="color: white;"></i></a>
               <a (click)="goBack()" class="btn btn-circle" style="background-color: tomato;"
                  *ngIf="viewAction || createAction" matTooltip="Exit" matTooltipPosition="left"
                  matTooltipClass="bg-dark"><i class="fas fa-arrow-left  fa-2x"></i></a>
               <!--fa-file-pdf-->
            </div>
         </div>
      </div>
   </div>

   <!-- User Role Filter -->
   <div class="modal fade" id="roleFilterModal" tabindex="-1" role="dialog" aria-labelledby="roleFilterModalTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
         <div class="modal-content">
            <div class="modal-header">
               <h5 class="modal-title" id="rolesModel">Roles</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
            </div>

            <div class="modal-body">
               <input class="form-control mb-2" #roleModalSearchInput
                  (keyup)="onKeyupRoleModalFilter($event,roleModalSearchInput.value)" placeholder="Search..">
               <div class="ml-2" *ngFor="let role of roleListModalFiltered">
                  <div class="row">
                     <label class="cb-container">
                        <h6>{{role.roleDescription}}</h6>
                        <input type="checkbox" [checked]="isRoleSelected(role.id)"
                           (change)="selectedRolesForSearch($event,role)">
                        <span class="checkmark"></span>
                     </label>
                  </div>
               </div>
            </div>

            <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
         </div>
      </div>
   </div>

</form>