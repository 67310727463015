<!--

<form #addressForm="ngForm" class="container mt-3" (ngSubmit)="logValue()">

   <div>
      <div *ngIf="multipleAddress">
         <button type="button" class="btn btn-sm btn-primary mb-2" (click)="addAddress()">Add Address</button>
      </div>

      <div class="container border mb-2 p-3" *ngFor="let address of addressList; let addressIndex = index;">

         <div class="row form-group" style="margin-bottom:0rem;">
            <label class="col-auto">&nbsp;Address Type:</label>
            <div class="col-sm-3">
               <select name="addressType_{{addressIndex}}" [(ngModel)]="address.addressType"
                  class="form-control form-control-sm" placeholder="Country" #addressTypeVar="ngModel" required
                  [ngClass]="{'is-invalid':addressTypeVar.touched && addressTypeVar.errors}">
                  <option value="india">Home</option>
                  <option value="usa">Work</option>
               </select>
               <div *ngIf="addressTypeVar?.invalid && (addressTypeVar.dirty || addressTypeVar.touched)"
                  class="invalid-feedback">
                  <div *ngIf="addressTypeVar.errors.required">Address Type is required.</div>
               </div>
            </div>
            <div class="col-6 text-right" *ngIf="multipleAddress">
               <button type="button" class="btn btn-danger btn-sm" (click)="removeAddress(address)">Remove</button>
            </div>
         </div>
         <hr style="margin-top:0.5rem;">

         <div class="row mt-2">
            <div class="col-3 form-group">
               <label class="col-form-label"><i class="fa fa-road"></i>&nbsp;Street Number</label>
               <input name="streetNumber_{{addressIndex}}" [(ngModel)]="address.streetNumber"
                  class="form-control  form-control-sm" placeholder="Street Number" #streetNumberVar="ngModel" required
                  [ngClass]="{'is-invalid':streetNumberVar.touched && streetNumberVar.errors}">
               <div *ngIf="streetNumberVar?.invalid && (streetNumberVar.dirty || streetNumberVar.touched)"
                  class="invalid-feedback">
                  <div *ngIf="streetNumberVar.errors.required">Street # is required.</div>
               </div>
            </div>
            <div class="col-6 form-group">
               <label class="col-form-label"><i class="fa fa-road"></i>&nbsp;Street Name</label>
               <input name="streetName_{{addressIndex}}" [(ngModel)]="address.streetName"
                  class="form-control  form-control-sm" placeholder="Street Name" #streetNameVar="ngModel" required
                  [ngClass]="{'is-invalid':streetNameVar.touched && streetNameVar.errors}">
               <div *ngIf="streetNameVar?.invalid && (streetNameVar.dirty || streetNameVar.touched)"
                  class="invalid-feedback">
                  <div *ngIf="streetNameVar.errors.required">Street Name is required.</div>
               </div>
            </div>
            <div class="col-3 form-group">
               <label class="col-form-label"><i class="fa fa-road"></i>&nbsp;Apartment Number</label>
               <input name="apartmentNumber_{{addressIndex}}" [(ngModel)]="address.apartmentNumber"
                  class="form-control  form-control-sm" placeholder="Apartment Number" #apartmentNumberVar="ngModel"
                  [required]="false" [ngClass]="{'is-invalid':apartmentNumberVar.touched && apartmentNumberVar.errors}">
               <div *ngIf="apartmentNumberVar?.invalid && (apartmentNumberVar.dirty || apartmentNumberVar.touched)"
                  class="invalid-feedback">
                  <div *ngIf="apartmentNumberVar.errors.required">Appartment Number is required.</div>
               </div>
            </div>
         </div>


         <div class="row mt-2">
            <div class="col-3 form-group">
               <label class="col-form-label"><i class="fa fa-building"></i>&nbsp;City</label>
               <input name="city_{{addressIndex}}" [(ngModel)]="address.city" class="form-control  form-control-sm"
                  placeholder="City" #cityVar="ngModel" required
                  [ngClass]="{'is-invalid':cityVar.touched && cityVar.errors}">
               <div *ngIf="cityVar?.invalid && (cityVar.dirty || cityVar.touched)" class="invalid-feedback">
                  <div *ngIf="cityVar.errors.required">City is required.</div>
               </div>
            </div>
            <div class="col-3 form-group">
               <label class="col-form-label"><i class="fa fa-globe"></i>&nbsp;Country</label>
               <!--<input name="country_{{address.id}}" [(ngModel)]="address.country"
                     class="form-control  form-control-sm" placeholder="Country" #countryVar="ngModel"
                     required [ngClass]="{'is-invalid':countryVar.touched && countryVar.errors}"> -->


<!--
               <select name="country_{{addressIndex}}" [(ngModel)]="address.country"
                  class="form-control  form-control-sm" placeholder="Country" #countryVar="ngModel" required
                  [ngClass]="{'is-invalid':countryVar.touched && countryVar.errors}">
                  <option value="india">India</option>
                  <option value="usa">USA</option>
                  <option value="england">England</option>
               </select>
               <div *ngIf="countryVar?.invalid && (countryVar.dirty || countryVar.touched)" class="invalid-feedback">
                  <div *ngIf="countryVar.errors.required">Country is required.</div>
               </div>
            </div>
            <div class="col-3 form-group">
               <label class="col-form-label"><i class="fa fa-road"></i>&nbsp;Province</label>
               <input name="province_{{addressIndex}}" [(ngModel)]="address.province"
                  class="form-control  form-control-sm" placeholder="Province" #provinceVar="ngModel" required
                  [ngClass]="{'is-invalid':provinceVar.touched && provinceVar.errors}">
               <div *ngIf="provinceVar?.invalid && (provinceVar.dirty || provinceVar.touched)" class="invalid-feedback">
                  <div *ngIf="provinceVar.errors.required">Province is required.</div>
               </div>
            </div>
            <div class="col-3 form-group">
               <label class="col-form-label"><i class="fa fa-road"></i>&nbsp;Postal Code</label>
               <input name="postalCode_{{addressIndex}}" [(ngModel)]="address.postalCode"
                  class="form-control  form-control-sm" placeholder="Postal Code" #postalCodeVar="ngModel" required
                  [ngClass]="{'is-invalid':postalCodeVar.touched && postalCodeVar.errors}">
               <div *ngIf="postalCodeVar?.invalid && (postalCodeVar.dirty || postalCodeVar.touched)"
                  class="invalid-feedback">
                  <div *ngIf="postalCodeVar.errors.required">Province is required.</div>
               </div>
            </div>
         </div>
         <hr>

      </div>


      <div class="text-right">
         <input type="submit" class="btn btn-success" value="Submit" />
      </div>

   </div>

</form>
-->


<!-- EDITABLE MODE -->

<!--
   <div *ngIf="editable">
      <div *ngIf="multipleAddress">
         <button type="button" class="btn btn-sm btn-primary mb-2" (click)="addAddress()">Add Address</button>
      </div>

      <div class="container border mb-2 p-3" *ngFor="let address of addressList; let addressIndex = index;">

         <div class="row form-group">
            <label class="col-sm-3"><i class="fa fa-road"></i>&nbsp;Address Type</label>
            <div class="col-sm-3">
               <select name="addressType_{{addressIndex}}" [(ngModel)]="addressList[addressIndex].addressType"
                  class="form-control form-control-sm" #addressTypeVar="ngModel" required
                  [ngClass]="{'is-invalid':addressTypeVar.touched && addressTypeVar.errors}">
                  <option *ngFor="let addressType of addressTypes" [ngValue]="addressType">{{addressType}}</option>
               </select>

               <div *ngIf="addressTypeVar?.invalid && (addressTypeVar.dirty || addressTypeVar.touched)"
                  class="invalid-feedback">
                  <div *ngIf="addressTypeVar.errors.required">Address Type is required.</div>
               </div>
            </div>
            <div class="col-sm" *ngIf="multipleAddress">
               <button type="button" class="btn btn-danger btn-sm float-right"
                  (click)="removeAddress(addressList[addressIndex])">Remove</button>
            </div>
         </div>

         <hr style="margin-top:0.5rem;">

         <div class="row form-group">
            <label class="col-sm-3 col-form-label"><i class="fa fa-road"></i>&nbsp;Street Number</label>
            <div class="col-sm">
               <input name="streetNumber_{{addressIndex}}" [(ngModel)]="addressList[addressIndex].streetNumber"
                  class="form-control form-control-sm" placeholder="Street Number" #streetNumberVar="ngModel" required
                  [ngClass]="{'is-invalid':streetNumberVar.touched && streetNumberVar.errors}">
               <div *ngIf="streetNumberVar?.invalid && (streetNumberVar.dirty || streetNumberVar.touched)"
                  class="invalid-feedback">
                  <div *ngIf="streetNumberVar.errors.required">Street # is required.</div>
               </div>
            </div>
         </div>

         <div class="row form-group">
            <label class="col-sm-3 col-form-label"><i class="fa fa-road"></i>&nbsp;Street Name</label>
            <div class="col-sm">
               <input name="streetName_{{addressIndex}}" [(ngModel)]="addressList[addressIndex].streetName"
                  class="form-control  form-control-sm" placeholder="Street Name" #streetNameVar="ngModel" required
                  [ngClass]="{'is-invalid':streetNameVar.touched && streetNameVar.errors}">
               <div *ngIf="streetNameVar?.invalid && (streetNameVar.dirty || streetNameVar.touched)"
                  class="invalid-feedback">
                  <div *ngIf="streetNameVar.errors.required">Street Name is required.</div>
               </div>
            </div>
         </div>

         <div class="row form-group">
            <label class="col-sm-3 col-form-label"><i class="fa fa-building"></i>&nbsp;Apartment Number</label>
            <div class="col-sm">
               <input name="apartmentNumber_{{addressIndex}}" [(ngModel)]="addressList[addressIndex].apartmentNumber"
                  class="form-control  form-control-sm" placeholder="Apartment Number" #apartmentNumberVar="ngModel"
                  [required]="false" [ngClass]="{'is-invalid':apartmentNumberVar.touched && apartmentNumberVar.errors}">
               <div *ngIf="apartmentNumberVar?.invalid && (apartmentNumberVar.dirty || apartmentNumberVar.touched)"
                  class="invalid-feedback">
                  <div *ngIf="apartmentNumberVar.errors.required">Appartment Number is required.</div>
               </div>
            </div>
         </div>

         <div class="row form-group">
            <label class="col-sm-3 col-form-label"><i class="fa fa-building"></i>&nbsp;City</label>
            <div class="col-sm">
               <input name="city_{{addressIndex}}" [(ngModel)]="addressList[addressIndex].city"
                  class="form-control  form-control-sm" placeholder="City" #cityVar="ngModel" required
                  [ngClass]="{'is-invalid':cityVar.touched && cityVar.errors}">
               <div *ngIf="cityVar?.invalid && (cityVar.dirty || cityVar.touched)" class="invalid-feedback">
                  <div *ngIf="cityVar.errors.required">City is required.</div>
               </div>
            </div>
         </div>

         <div class="row form-group">
            <label class="col-sm-3 col-form-label"><i class="fa fa-globe"></i>&nbsp;Country</label>
            <div class="col-sm">
               <select name="country_{{addressIndex}}" [(ngModel)]="addressList[addressIndex].countryCode"
                  class="form-control  form-control-sm" placeholder="Country" #countryVar="ngModel" required
                  (change)="onCountryDropdownChange(addressList[addressIndex].countryCode, addressIndex)"
                  [ngClass]="{'is-invalid':countryVar.touched && countryVar.errors}">
                  <option *ngFor="let country of countriesWithRegionsDetails" [ngValue]="country.countryShortCode">
                     {{country.countryName}}</option>
               </select>
               <div *ngIf="countryVar?.invalid && (countryVar.dirty || countryVar.touched)" class="invalid-feedback">
                  <div *ngIf="countryVar.errors.required">Country is required.</div>
               </div>
            </div>
         </div>

         <div class="row form-group">
            <label class="col-sm-3 col-form-label"><i class="fa fa-road"></i>&nbsp;Province</label>
            <div class="col-sm">
               <select name="province_{{addressIndex}}" [(ngModel)]="addressList[addressIndex].provinceCode"
                  class="form-control  form-control-sm" placeholder="Province" #provinceVar="ngModel" required
                  (change)="onProvinceDropdownChange(addressList[addressIndex].provinceCode, addressIndex)"
                  [ngClass]="{'is-invalid':provinceVar.touched && provinceVar.errors}">
                  <option *ngFor="let region of regionsForSelectedCountry[addressIndex]" [ngValue]="region.shortCode">
                     {{region.name}}</option>
               </select>
               <div *ngIf="provinceVar?.invalid && (provinceVar.dirty || provinceVar.touched)" class="invalid-feedback">
                  <div *ngIf="provinceVar.errors.required">Province is required.</div>
               </div>
            </div>
         </div>

         <div class="row form-group">
            <label class="col-sm-3 col-form-label"><i class="fa fa-road"></i>&nbsp;Postal Code</label>
            <div class="col-sm">
               <input name="postalCode_{{addressIndex}}" [(ngModel)]="addressList[addressIndex].postalCode"
                  class="form-control  form-control-sm" placeholder="Postal Code" #postalCodeVar="ngModel" required
                  [ngClass]="{'is-invalid':postalCodeVar.touched && postalCodeVar.errors}">
               <div *ngIf="postalCodeVar?.invalid && (postalCodeVar.dirty || postalCodeVar.touched)"
                  class="invalid-feedback">
                  <div *ngIf="postalCodeVar.errors.required">Province is required.</div>
               </div>
            </div>
         </div>

      </div>

      <div class="text-right">
         <input type="submit" class="btn btn-sm btn-success" value="Submit" />
      </div>

   </div>
-->


<div ngModelGroup="AddressMG" style="border: 0px;">
   <div class="container h-100 mb-2" *ngIf="localAddressList?.length===0 || localAddressList ==null">
      <div class="row h-100">
         <div class="col-sm-12">
             <div class="w-25 mx-auto"><p class="text-muted"><em>No Address(s) Available.</em></p></div>
         </div>
     </div>
   </div>

   <div *ngIf="editable" >
      <div [class]="index===(localAddressList.length - 1)?'mb-1 p-1 ml-3':'border-bottom mb-1 p-1 ml-3'" *ngFor="let address of localAddressList; index as index">
         <div class="row form-group">

            <div class="col-3 mt-1" *ngIf="showAddressType">
               <label class="mb-0 label-medium">Address Type</label>
               <div>
                  <select name="addressType_{{address.id}}_{{address.transientIndex}}" [(ngModel)]="address.addressType" class="form-control form-control-sm" #addressTypeVar="ngModel" required
                     [ngClass]="{'is-invalid':addressTypeVar.touched && addressTypeVar.errors}"   [disabled]="!editable">
                     <option *ngFor="let addressType of addressTypes" [ngValue]="addressType">{{addressType}}</option>
                  </select>

                  <div *ngIf="addressTypeVar?.invalid && (addressTypeVar.dirty || addressTypeVar.touched)" class="invalid-feedback">
                     <div *ngIf="addressTypeVar.errors.required">Address Type is required.</div>
                  </div>
               </div>
            </div>

            <div class="col-2 mt-1">
               <label class="mb-0 label-medium">Street #</label>
               <div>
                  <input name="streetNumber_{{address.id}}_{{address.transientIndex}}" [(ngModel)]="address.streetNumber" maxlength="10" class="form-control form-control-sm" placeholder=""
                     #streetNumberVar="ngModel" [ngClass]="{'is-invalid':streetNumberVar.touched && streetNumberVar.errors}" [disabled]="!editable">
                  <div *ngIf="streetNumberVar?.invalid && (streetNumberVar.dirty || streetNumberVar.touched)" class="invalid-feedback">
                     <div *ngIf="streetNumberVar.errors.required">Street # is required.</div>
                  </div>
               </div>
            </div>

            <div class="col-4 mt-1">
               <label class="mb-0 label-medium">Street Name</label>
               <div>
                  <input name="streetName_{{address.id}}_{{address.transientIndex}}" [(ngModel)]="address.streetName" maxlength="250" class="form-control  form-control-sm" placeholder=""
                     #streetNameVar="ngModel" required [ngClass]="{'is-invalid':streetNameVar.touched && streetNameVar.errors}" [disabled]="!editable">
                  <div *ngIf="streetNameVar?.invalid && (streetNameVar.dirty || streetNameVar.touched)" class="invalid-feedback">
                     <div *ngIf="streetNameVar.errors.required">Street Name is required.</div>
                  </div>
               </div>
            </div>

            <div class="col-2 mt-1">
               <label class="mb-0 label-medium">Suite/Apt #</label>
               <div>
                  <input name="apartmentNumber_{{address.id}}_{{address.transientIndex}}" [(ngModel)]="address.apartmentNumber" maxlength="10" class="form-control  form-control-sm" placeholder=""
                     #apartmentNumberVar="ngModel" [required]="false" [ngClass]="{'is-invalid':apartmentNumberVar.touched && apartmentNumberVar.errors}"  [disabled]="!editable">
                  <div *ngIf="apartmentNumberVar?.invalid && (apartmentNumberVar.dirty || apartmentNumberVar.touched)" class="invalid-feedback">
                     <div *ngIf="apartmentNumberVar.errors.required">Appartment Number is required.</div>
                  </div>
               </div>
            </div>
         
            <div class="col-3 mt-1">
               <label class="mb-0 label-medium">City</label>
               <div>
                  <input name="city_{{address.id}}_{{address.transientIndex}}" [(ngModel)]="address.city" maxlength="20" class="form-control  form-control-sm" placeholder="" #cityVar="ngModel" required
                     [ngClass]="{'is-invalid':cityVar.touched && cityVar.errors}" [disabled]="!editable">
                  <div *ngIf="cityVar?.invalid && (cityVar.dirty || cityVar.touched)" class="invalid-feedback">
                     <div *ngIf="cityVar.errors.required">City is required.</div>
                  </div>
               </div>
            </div>

            <div class="col-3 mt-1">
               <label class="mb-0 label-medium">Country</label>
               <div>
                  <select name="country_{{address.id}}_{{address.transientIndex}}" [(ngModel)]="address.countryCode" class="form-control  form-control-sm" placeholder="" #countryVar="ngModel" required
                     (change)="onCountryDropdownChange(address.countryCode, index)" [ngClass]="{'is-invalid':countryVar.touched && countryVar.errors}"  [disabled]="!editable">
                     <option *ngFor="let country of countriesWithRegionsDetails" [ngValue]="country.countryShortCode">
                        {{country.countryName}}</option>
                  </select>
                  <div *ngIf="countryVar?.invalid && (countryVar.dirty || countryVar.touched)" class="invalid-feedback">
                     <div *ngIf="countryVar.errors.required">Country is required.</div>
                  </div>
               </div>
            </div>

            <div class="col-3 mt-1">
               <label class="mb-0 label-medium">Province / State</label>
               <div>
                  <select name="province_{{address.id}}_{{address.transientIndex}}" [(ngModel)]="address.provinceCode" class="form-control  form-control-sm" placeholder="" #provinceVar="ngModel"
                     required (change)="onProvinceDropdownChange(address.provinceCode, index)" [ngClass]="{'is-invalid':provinceVar.touched && provinceVar.errors}"  [disabled]="!editable">
                     <option *ngFor="let region of regionsForSelectedCountry[index]" [ngValue]="region.shortCode">
                        {{region.name}}</option>
                  </select>
                  <div *ngIf="provinceVar?.invalid && (provinceVar.dirty || provinceVar.touched)" class="invalid-feedback">
                     <div *ngIf="provinceVar.errors.required">Province / State is required.</div>
                  </div>
               </div>
            </div>
            
            <div class="col-2 mt-1">
               <label class="mb-0 label-medium">Postal / Zip</label>
               <div>
                  <input name="postalCode_{{address.id}}_{{address.transientIndex}}" [(ngModel)]="address.postalCode" maxlength="10" class="form-control  form-control-sm" placeholder=""
                     #postalCodeVar="ngModel" [ngClass]="{'is-invalid':postalCodeVar.touched && postalCodeVar.errors}"  [disabled]="!editable">
                  <div *ngIf="postalCodeVar?.invalid && (postalCodeVar.dirty || postalCodeVar.touched)" class="invalid-feedback">
                     <div *ngIf="postalCodeVar.errors.required">Postal / Zip Code is required.</div>
                  </div>
               </div>
            </div>

            <div class="col-sm-1 p-0" *ngIf="editable" style="align-self: flex-end;">
               <label>&nbsp;</label>
               <a class="btn" style="border: 0px;" (click)="removeAddress(index)" class="ml-2"><i class="fas fa-trash fa-1x"
                     style="color: #dc3545"></i></a>
            </div>
         </div>
      </div>

      <div *ngIf="(multipleAddress && editable) || (!multipleAddress && editable && (localAddressList?.length===0 || localAddressList ==null))" class="row col-sm-auto">
         <a (click)="addAddress()" class="btn btn-info btn-sm text-white"><i class="fas fa-plus fa-1x"></i>&nbsp;Address</a>
      </div>

   </div>

   <!-- NON - EDITABLE MODE

   <div *ngIf="!editable">
      <div class="container mb-2" *ngFor="let address of localAddressList; let addressIndex = index;">

         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label text-right"><i class="fa fa-road"></i>&nbsp;Address Type:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{address.addressType}} </span>
            </div>
         </div>

         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label  text-right"><i class="fa fa-road"></i>&nbsp;Street Number:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{address.streetNumber}} </span>
            </div>
         </div>

         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label  text-right"><i class="fa fa-road"></i>&nbsp;Street Name:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{address.streetName}} </span>
            </div>
         </div>

         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label  text-right"><i class="fa fa-building"></i>&nbsp;Apartment Number:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{address.apartmentNumber}} </span>
            </div>
         </div>

         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label text-right"><i class="fa fa-building"></i>&nbsp;City:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{address.city}} </span>
            </div>
         </div>


         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label text-right"><i class="fa fa-globe"></i>&nbsp;Country:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{address.countryName}} </span>
            </div>
         </div>

         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label text-right"><i class="fa fa-road"></i>&nbsp;Province / State:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{address.provinceName}} </span>
            </div>
         </div>

         <div class="form-group text-wrap" style="margin-bottom: 0rem;">
            <div class="row">
               <label class="col-4 col-form-label text-right"><i class="fa fa-road"></i>&nbsp;Postal / Zip Code:</label>
               <span class="col col-form-label" style="word-wrap:break-word;">{{address.postalCode}} </span>
            </div>
         </div>

         <hr>

      </div>
   </div>
   -->

   <div *ngIf="!editable" class="row mt-2">
      <ng-container *ngFor="let address of localAddressList">
      <div class="col-4 mb-1">
         <div class="card mb-2 h-100 border-0">
            <div>
               <!--<div class="card-header text-white bg-secondary py-0" style="padding: .3rem .3rem;">
                  <div class="row ">
                     <div class="col-12 text-truncate" style="align-self: center;" data-toggle="tooltip" data-placement="top">
                        &nbsp;{{address.addressType}}
                     </div>
                  </div>
               </div>-->
               <div class="card-body" style="padding: 0.25rem;">
                  <p class="card-text f-14 font-weight-bold ml-2 mb-0 text-wrap">{{address.addressType}}</p>
                  <p class="card-text ml-2 mb-0 text-wrap">
                     <span *ngIf="address.apartmentNumber">{{address.apartmentNumber}} - </span>
                     <span *ngIf="address.streetNumber">{{address.streetNumber}} </span>
                     <span *ngIf="address.streetName">{{address.streetName}} </span>
                  </p>
                  <p class="card-text ml-2 mb-0 text-wrap">
                     <span *ngIf="address.city">{{address.city}} </span>
                     <span *ngIf="address.provinceName">{{address.provinceName}} </span>
                  </p>
                  <p class="card-text ml-2 mb-0 text-wrap">
                     <span *ngIf="address.countryName">{{address.countryName}}</span>
                     <span *ngIf="address.postalCode"> - {{address.postalCode}}</span>
                  </p>
               </div>
            </div>
         </div>
         
      </div>
      </ng-container>
   </div>

</div>