import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { SalesService } from 'src/app/shared/service/sales/sales.service';
import { SalesOrder } from 'src/app/shared/model/sales/sales-order.model';
import { User } from 'src/app/shared/model/user.model';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/service/auth.service';
import { SearchDetails } from 'src/app/shared/model/common/search-details.model';
import { SearchService } from 'src/app/shared/service/search.service';
import { SearchComponent } from 'src/app/common/search/search.component';
import { FilterConfig, SearchFilter } from 'src/app/shared/model/common/search-filter.model';
import { UserManagementService } from 'src/app/shared/service/user-management.service';
import { SalesHelperService } from 'src/app/shared/service/sales/sales-helper.service';
import {  ActivatedRoute } from '@angular/router';
import { SalesOrderActivity } from 'src/app/shared/model/sales/sales-order-activity.model';
import { formatDate } from '@angular/common';
import { Notes } from 'src/app/shared/model/common/notes.model';
import { NotesService } from 'src/app/shared/service/notes/notes.service';
import { TransformPipe} from 'src/app/transform.pipe';




@Component({
   selector: 'app-sales-order-list',
   templateUrl: './sales-order-list.component.html',
   styleUrls: ['./sales-order-list.component.css']
})

export class SalesOrderListComponent implements OnInit {

   constructor(private sharedService: SharedService, private salesService: SalesService, private userService: UserManagementService, private notesService: NotesService,
      private toastrService: ToastrService, private dialog: MatDialog,private authService:AuthService, private searchService:SearchService, private salesHelperService:SalesHelperService,
      private activatedRoute: ActivatedRoute, private transformPipe: TransformPipe) { }

   userAccessSubscription: Subscription;
   userAccess: UserAccess;

   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;

   //selectedSortByName: string;
   //sortMode: string = 'ASC';

   //draftSalesOrderList: SalesOrder[] = [];
   //filteredDraftSalesOrderList: SalesOrder[] = [];

   //submittedSalesOrderList: SalesOrder[] = [];
   //filteredSubmittedSalesOrderList: SalesOrder[] = []

   salesOrderList: SalesOrder[] = [];
   
   editable: boolean;

   searchConfig:SearchDetails;
   sortColumns = [
      { columnName: 'LAST_MODIFIED_DATE', sortByName: 'Last Modified' },
      { columnName: 'ORDER_INITIAL_SUBMIT_DATE', sortByName: 'Initial Submit Date' },
      { columnName: 'SALES_ORDER_NUMBER', sortByName: 'Sales Order #' },
      { columnName: 'CUSTOMER_NAME', sortByName: 'Customer' },
      { columnName: 'ORDER_CREATED_DATE', sortByName: 'Order Date' },
      { columnName: 'ORDER_SHIP_DATE', sortByName: 'Order Ship Date' },
      { columnName: 'ORDER_CANCEL_DATE', sortByName: 'Order Cancel Date' },
   ];
   /*sortColumns = [
      { columnName: 'Sales Order Number', sortByName: 'salesOrderNumber' },
      { columnName: 'Customer', sortByName: 'customerName' },
      { columnName: 'Order Date', sortByName: 'orderCreatedDate' },
      { columnName: 'Ship Date', sortByName: 'orderShipDate' },
      { columnName: 'Cancel Date', sortByName: 'orderCancelDate' }
   ];*/
   selectedStatus:string='SUBMITTED';
   selectedProductionStatus='IN_PROGRESS';
   productionStatusList:string[]=[];
   requestFilterType:string;
   requestFilterSalesOrderType: string;
   @ViewChild('searchComponent') searchComponent:SearchComponent<SalesOrder>;
   productionUserList: User[] = [];

   @Input() isSelectionMode: boolean = false;
   
   ngOnInit(): void {
      this.productionStatusList=this.salesHelperService.productionStatusListForFilter;
      this.handleSubscriptions();
      
      this.getProductionUserList();
      if (!this.searchConfig) {
         this.searchConfig = this.searchService.salesOrderListSearchDetails;
         this.evaluateRequestFilter();
         this.setFilterConfigs();
         this.setSelectedStatus();
         this.setSelectedProductionStatus();
         /*this.searchConfig.setFilterInList('CLIENTID','String',[]);
         this.searchConfig.setFilterInList('WHPID','String',[]);
         this.searchConfig.searchCriteria.searchSubType='INVENTORY_LEVEL_2';*/
       }
      //this.loadSubmittedSalesOrders();
   }

   evaluateRequestFilter() {

      if (this.activatedRoute.snapshot.data && this.activatedRoute.snapshot.data.requestFilterSalesOrderType) {
         this.requestFilterSalesOrderType = this.activatedRoute.snapshot.data.requestFilterSalesOrderType;
         console.log('requestFilterSalesOrderType = ', this.requestFilterSalesOrderType);
         if (this.requestFilterSalesOrderType === 'Estimation') {
            this.searchConfig = this.searchService.salesOrderEstimationListSearchDetails;
            this.selectedProductionStatus = 'ALL';
            this.changeProductionStatusType();
         }
         if (this.requestFilterSalesOrderType === 'Costing') {
            this.searchConfig = this.searchService.salesOrderCostingListSearchDetails;
         }
      }

      if (this.activatedRoute.snapshot.data && this.activatedRoute.snapshot.data.filter) {
         this.requestFilterType = this.activatedRoute.snapshot.data.filter;
         //console.log(this.requestFilterType);
         if (this.requestFilterType) {
            this.selectedProductionStatus = this.requestFilterType;
            this.changeProductionStatusType();
            //console.log('selectedProductionStatus', this.selectedProductionStatus);
         }
      }

      // Order Type Filter
      this.searchConfig.setFilterInList('ORDER_TYPE', 'String', []);
      this.searchConfig.setFilterInList('ORDER_TYPE', 'String', ['Sales', 'Sample']);
      if (this.requestFilterSalesOrderType) {
         if (this.requestFilterSalesOrderType === 'Estimation') {
            this.searchConfig.setFilterInList('ORDER_TYPE', 'String', ['Estimation']);
         }
      }

   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
         this.currentLoggedInUser = currentLoggedInUser;
      });
   }

   setFilterConfigs() {
      if(!this.searchConfig.filterConfigs){
         this.searchConfig.filterConfigs=[];
       let customerShipDate:FilterConfig={
         filterType:'DATE_RANGE',
         label:'Ship Date',
         currentSelection:undefined,
         fieldName:'ORDER_SHIP_DATE'
       };
       this.searchConfig.filterConfigs.push(customerShipDate);
     }
   }

   /*loadDraftSalesOrders() {
      this.salesService.getDraftSalesOrderList().subscribe(response => {
         this.draftSalesOrderList = response.responsePayload;
         this.filteredDraftSalesOrderList = this.draftSalesOrderList;
         //this.filteredSalesOrderList = this.sharedService.sortListByPropertyName(this.filteredSalesOrderList, 'customerName');
      }, err => {
         console.error('Error while Loading Draft Sales Order : ' + JSON.stringify(err) + ' with status - ');
      });
   }

   loadSubmittedSalesOrders() {
      this.salesService.getSubmittedSalesOrderList().subscribe(response => {
         this.submittedSalesOrderList = response.responsePayload;
         this.filteredSubmittedSalesOrderList = this.submittedSalesOrderList;
      }, err => {
         console.error('Error while Loading Submitted Sales Order : ' + JSON.stringify(err) + ' with status - ');
      });
   }

   onKeyupFilterDraft(event, value) {
      if (event.key !== 'Shift') {
         if (value.trim().length > 0) {
            this.filteredDraftSalesOrderList = this.filter(this.draftSalesOrderList, value);
         } else {
            this.filteredDraftSalesOrderList = this.draftSalesOrderList;
         }
      }
   }

   onKeyupFilterSubmitted(event, value) {
      if (event.key !== 'Shift') {
         if (value.trim().length > 0) {
            this.filteredSubmittedSalesOrderList = this.filter(this.submittedSalesOrderList, value);
         } else {
            this.filteredSubmittedSalesOrderList = this.submittedSalesOrderList;
         }
      }
   }

   filter(list: SalesOrder[], value: string) {
      let filteredList: SalesOrder[] = list.filter(item => {
         return (
            item.salesOrderNumber?.toUpperCase().includes(value.toUpperCase()) ||
            item.customerName?.toUpperCase().includes(value.toUpperCase()) ||
            item.salesRepName?.toUpperCase().includes(value.toUpperCase()) ||
            item.customerPoNumbers?.toUpperCase().includes(value.toUpperCase())
         )
      });
      return filteredList;
   }

   onSortColumnChange($event: any, salesOrderStatus: string) {
      if (salesOrderStatus === 'DRAFT') {
         this.filteredDraftSalesOrderList = this.sharedService.sortListByPropertyName(this.filteredDraftSalesOrderList, this.selectedSortByName);
      } else {
         this.filteredSubmittedSalesOrderList = this.sharedService.sortListByPropertyName(this.filteredSubmittedSalesOrderList, this.selectedSortByName);
      }
   }

   sortAsc(salesOrderStatus: string) {
      this.sortMode = "ASC";
      this.onSortColumnChange(null, salesOrderStatus);
   }

   sortDesc(salesOrderStatus: string) {
      this.sortMode = "DESC";
      this.onSortColumnChange(null, salesOrderStatus);
      if (salesOrderStatus === 'DRAFT') {
         this.filteredDraftSalesOrderList.reverse();
      } else {
         this.filteredSubmittedSalesOrderList.reverse();
      }
   }*/

   deleteSalesOrder(salesOrder: SalesOrder) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
         disableClose: true,
         data: {
            title: 'Delete Sales Order',
            message: 'Are you sure you want to delete this sales order.?',
            confirmBtnText: 'Yes',
            cancelBtnText: 'Cancel'
         }
      });
      confirmDialog.afterClosed().subscribe(res => {
         if (res) {
            this.salesService.deleteSalesOrder(salesOrder.id).subscribe(response => {
               if (response.responseStatus.status === 'SUCCESS') {
                  let updatedSalesOrderFromDB = response.responsePayload;
                  this.toastrService.success(response.responseStatus.message);
                  this.search();
               } else {
                  this.toastrService.error(response.responseStatus.message);
               }
            },
               error => {
                  console.error(error);
                  this.toastrService.error(error.message);
               });
         }else {
            
         }
      });

      /*
      if (confirm('Are you sure you want to delete this sales order ?')) {
         this.salesService.deleteSalesOrder(salesOrder.id).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               let updatedSalesOrderFromDB = response.responsePayload;
               this.toastrService.success(response.responseStatus.message);
               this.loadDraftSalesOrders();
            } else {
               this.toastrService.error(response.responseStatus.message);
            }
         },
            error => {
               console.error(error);
               this.toastrService.error(error.message);
            });
      }
      */
   }

  /* onDraftTabClick() {
      this.selectedSortByName = null;
      this.loadDraftSalesOrders();
   }

   onSubmittedTabClick() {
      this.selectedSortByName = null;
      this.loadSubmittedSalesOrders();
   }
  
   selectedTabChange(index: number) {
      switch (index) {
         case 1:
            this.loadDraftSalesOrders();
            break;
         case 0:
            this.loadSubmittedSalesOrders();
            break;
         default:
            break;
      }
   }
*/
   changeStatusTypeAndSearch(){
      this.changeStatusType();
      this.search();
   }
   private search() {
      if (this.searchComponent) {
         this.searchComponent.search();
      }
   }

   changeProductionStatusTypeAndSearch(){
      this.changeProductionStatusType();
     this.search();
   }

   changeStatusType(){
      if('ALL'===this.selectedStatus){
         this.searchConfig.setFilterInList('STATUS','String',[]);
         this.selectedProductionStatus='ALL';
         this.changeProductionStatusType();
      }else{
         this.searchConfig.setFilterInList('STATUS','String',[this.selectedStatus]);
         if(this.selectedStatus==='DRAFT'){
            this.selectedProductionStatus='ALL';
            this.changeProductionStatusType();
         }
      }    
   }

   changeProductionStatusType(){
      if('ALL'===this.selectedProductionStatus){
         this.searchConfig.setFilterInList('PRODUCTION_STATUS','String',[]);
      }else if('IN_PROGRESS'===this.selectedProductionStatus){
         this.searchConfig.setFilterInList('PRODUCTION_STATUS','String',this.salesHelperService.inProgressProductionStatusList);
      }else{
         this.searchConfig.setFilterInList('PRODUCTION_STATUS','String',[this.selectedProductionStatus]);
      }
   }

   private setSelectedStatus() {
      let filterInList: SearchFilter[] = this.searchConfig.searchCriteria.filterInList;
      if (filterInList) {
         let filter: SearchFilter = this.searchConfig.getExistingFilter(filterInList, 'STATUS');
         if(filter){
            if (filter && filter.values && filter.values.length===0) {
               this.selectedStatus = 'ALL';
               this.changeStatusType();
            }else {
               this.selectedStatus = filter.values[0];
            }
         }else{
            this.selectedStatus = 'SUBMITTED';
            this.changeStatusType();
         }
      }
   }

   private setSelectedProductionStatus() {
      let filterInList: SearchFilter[] = this.searchConfig.searchCriteria.filterInList;
      if (filterInList) {
         let filter: SearchFilter = this.searchConfig.getExistingFilter(filterInList, 'PRODUCTION_STATUS');
         if(filter){
            if (filter && filter.values && filter.values.length===0) {
               this.selectedProductionStatus = 'ALL';
               this.changeProductionStatusTypeAndSearch();
            }else if(filter && filter.values && filter.values === this.salesHelperService.inProgressProductionStatusList) {
               this.selectedProductionStatus = 'IN_PROGRESS';
               this.changeProductionStatusTypeAndSearch();
            }else {
               this.selectedProductionStatus = filter.values[0];
               this.changeProductionStatusTypeAndSearch();
            }
         }else{
            /*if(this.requestFilterType && this.requestFilterType==='COSTING_GENERATION_PENDING'){
               this.selectedProductionStatus = 'COSTING_GENERATION_PENDING';
            }else if(this.requestFilterType && this.requestFilterType==='COSTING_APPROVAL_PENDING'){
               this.selectedProductionStatus = 'COSTING_APPROVAL_PENDING';
            }else if(this.requestFilterType && this.requestFilterType==='COSTING_APPROVAL_COMPLETE'){
               this.selectedProductionStatus = 'COSTING_APPROVAL_COMPLETE';
            }else{*/
               this.selectedProductionStatus = 'IN_PROGRESS';
            //}
            //console.log('selectedProductionStatus',this.selectedProductionStatus);
            this.changeProductionStatusTypeAndSearch();
         }
      }
   }

   getProductionUserList() {
      this.userService.getUsersByRoleId('PRODUCTION_USER').subscribe(response => {
         this.productionUserList = response.responsePayload;
      });
   }

   editProductionCoordinator(salesOrder: SalesOrder){
      salesOrder.editable = true;
   }

   saveProductionCoordinatorChange(salesOrder: SalesOrder, index: string){
      this.salesService.updateProductionCoordinatorChange(salesOrder).subscribe(response => {
         if(response.responseStatus.status === 'SUCCESS'){
            salesOrder.editable = false;
            let updatedSalesOrder: SalesOrder = response.responsePayload;
            this.search();
            //this.submittedSalesOrderList[index] = updatedSalesOrder;
            this.toastrService.success(response.responseStatus.message);
         }else{
            this.toastrService.error(response.responseStatus.message);
         }
      }, err => {
         console.error('Error while updating Production Coordinator : ' + JSON.stringify(err));
      });
   }

   cancelProductionCoordinatorChange(salesOrder: SalesOrder){
      salesOrder.editable = false;
   }

   productionStatusReasonColor(reason:string):string{
      if(this.sharedService.productionStatusReasonColor.get(reason)!==undefined){
        return this.sharedService.productionStatusReasonColor.get(reason);
      }else{
        return '';
      }

    }
    isManualCosting(salesOrder:SalesOrder){
      if(salesOrder && salesOrder.orderInitialSubmitDate && 
         (salesOrder.productionStatus==='COSTING_GENERATION_PENDING'
            ||  salesOrder.productionStatus==='COSTING_APPROVAL_PENDING')){
         let costingModuleDeploymentDate=new Date('2022-07-23');
         let initialSubmissionDate=new Date(salesOrder.orderInitialSubmitDate);
         return initialSubmissionDate < costingModuleDeploymentDate;
      }else{
         return false;
      }
    }


   loadActivities(salesOrder: SalesOrder, salesOrderId: string, isExpand: boolean, activityType: string) {
      if (salesOrderId && isExpand && (salesOrder.activityNotes === null || salesOrder.activityNotes === undefined)) {
         this.salesService.getActivitiesList(salesOrderId).subscribe(res => {
            if (res.responseStatus.status === 'SUCCESS') {
               let retVal: SalesOrderActivity[] = res.responsePayload;
               if (retVal) {
                  let notesList: string[] = [];
                  retVal.forEach(salesOrderActivity => {
                     if (salesOrderActivity.activity === activityType) {
                        let activityNotes: string = "";
                        //activityNotes = "<li>"+activityNotes + "<b><i>Re-Submitted By:</i></b>&nbsp;" + salesOrderActivity.userName + "&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<b><i>ON:</i></b>&nbsp;" + formatDate(salesOrderActivity.createdOn,'medium', 'en_US') + "&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<b><i>NOTES:</i></b><pre class='f-13'>" + salesOrderActivity.notes+ "</pre></li>";
                        //activityNotes = "<li>"+activityNotes + "<b><i>Re-Submitted By:</i></b>&nbsp;" + salesOrderActivity.userName + "&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<b><i>ON:</i></b>&nbsp;" + formatDate(salesOrderActivity.createdOn,'medium', 'en_US') + "&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<b><i>NOTES:</i></b><pre class='f-13'>" + salesOrderActivity.notes+ "</pre></li>";
                        activityNotes = "<li>" + activityNotes + "<b><i>Re-Submitted By:</i></b>&nbsp;" + salesOrderActivity.userName + "&nbsp;|&nbsp;" + formatDate(salesOrderActivity.createdOn, 'medium', 'en_US') + "<pre class='f-12 mb-2'>" + salesOrderActivity.notes + "</pre></li>";
                        notesList.push(activityNotes);
                     }
                  });
                  this.loadNotes(salesOrder.id,salesOrder.productionStatus, 'STATUS_FLAGGED_NOTES', notesList);
                  salesOrder.activityNotes = notesList;
               }
               //console.log(salesOrder.activityNotes);
            }
         });
      }
   }

   loadNotes(parentId: string, type: string, subType: string, notesList: string[]) {
      this.notesService.getNotesList(parentId, type, subType).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let retVal: Notes[] = response.responsePayload;
            if (retVal) {
               retVal.forEach(notes => {
                  let tmpProductionStatus: string = this.transformPipe.transform('sales.productionStatus.' + notes.type);
                  let notesString: string = "";
                  notesString = "<li>" + notesString + "<b><i>"+tmpProductionStatus+ " Status Flagged Notes - By:</i></b>&nbsp;" + notes.userName + "&nbsp;|&nbsp;" + formatDate(notes.createdDate, 'medium', 'en_US') + "<pre class='f-12 mb-2'>" + notes.description + "</pre></li>";
                  notesList.push(notesString);
               });
            }
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      }, err => {
         console.error('Error while loading Notes : ' + JSON.stringify(err));
      });
   }


   flagSalesOrder(salesOrder: SalesOrder, flag: boolean) {
      if (flag) {
         let tmpProductionStatus: string = this.transformPipe.transform('sales.productionStatus.' + salesOrder.productionStatus);
         const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
               width: '100vw',
               maxWidth: '90vw',
               minWidth: '90vw',
               minHeight: '80%',
               maxlength: 250,
               modalType: 'TEXTAREA_INPUT',
               textInputRegex: '^(.|\\s)*\\S(.|\\s)*$',
               textInputDefault: '',
               textInputRegexError: 'Reason is required',
               title: tmpProductionStatus + ' - Flag Notes for CS# ' + salesOrder.salesOrderNumber,
               message: 'Please enter the notes.',
               confirmBtnText: 'OK',
               cancelBtnText: 'Cancel'
            }
         });
         confirmDialog.afterClosed().subscribe(res => {
            if (res) {
               console.log(res);
               salesOrder.flagged = flag;
               this.flagSalesOrderAndCreateNotes(salesOrder, true, res);
            } else {

            }
         });
      } else {
         salesOrder.flagged = flag;
         this.flagSalesOrderAndCreateNotes(salesOrder, false, '');
      }
   }

   flagSalesOrderAndCreateNotes(salesOrder: SalesOrder, createNotes: boolean, description: string) {
      let tmpProductionStatus: string = this.transformPipe.transform(salesOrder.orderType.toLowerCase()+'.productionStatus.' + salesOrder.productionStatus);
      //console.log('Transporderddd  = ', tmpProductionStatus, salesOrder);
      if (createNotes) {
         let notesList: Notes[] = [];
         let newNotes: Notes = new Notes;
         newNotes.createdDate = new Date;
         newNotes.parentId = salesOrder.id;
         newNotes.createdBy = this.currentLoggedInUser.fullName;
         newNotes.userName = this.currentLoggedInUser.userName;
         newNotes.type = salesOrder.productionStatus;
         newNotes.subType = 'STATUS_FLAGGED_NOTES';
         newNotes.description = description;
         newNotes.subject = tmpProductionStatus + ' - Flag Notes for CS# ' + salesOrder.salesOrderNumber;
         newNotes.emailRecipients = salesOrder.coordinatorUserName + ',' + salesOrder.createdBy
         if(salesOrder.sharedSalesRepsUserNames !==null && salesOrder.sharedSalesRepsUserNames !==undefined){
            newNotes.emailRecipients = newNotes.emailRecipients  + ',' + salesOrder.sharedSalesRepsUserNames;
         }
         notesList.push(newNotes);
         this.notesService.saveNotesList(notesList, salesOrder.id).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
            } else {
               this.toastrService.error(response.responseStatus.message);
            }
         }, err => {
            console.error('Error while saving Notes : ' + JSON.stringify(err));
         });
      }


      this.salesService.flagSalesOrder(salesOrder).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.search();
         } else {
            this.toastrService.error(response.responseStatus.message);
         }
      }, err => {
         console.error('Error while flagging sales order : ' + JSON.stringify(err));
      });
   }


   isSalesOrderSelected(salesOrderId: string): boolean {
      return this.salesService.isSalesOrderSelected(salesOrderId);
   }

   selectionChanged(salesOrder: SalesOrder) {
      this.salesService.resetSelectedSalesOrders();
      //this.selectedSalesOrderCount = this.salesService.selectedSalesOrders.length;
      return this.salesService.selectionChanged(salesOrder);
   }

   getSelectedSalesOrderCount():number{
      return this.salesService.selectedSalesOrders.length;
   }


}
