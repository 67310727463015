<!--Routing SECTION STARTS-->
<div >
    <!--<div ngModelGroup="routingInfo" #routingInfoCtrl="ngModelGroup">-->
        <form #routingInfoForm="ngForm"  autocomplete="off" novalidate>
        <!--<h6 class="mt-4">Routing Info</h6>
        <hr class="hr-min">-->
        <div class="d-flex justify-content-end mr-4 mt-2">
    
            <button type="button" class="btn btn-sm btn-warning ml-2" (click)="edit()" *ngIf="!editable && userAccess?.canEditProductionTracker">Edit</button>
            <button type="button" class="btn btn-sm btn-success ml-2" (click)="save()" *ngIf="editable" [disabled]="!routingInfoForm.valid">Save</button>
            <button type="button" class="btn btn-sm btn-dark ml-2" (click)="cancel()" *ngIf="editable">Cancel</button>
        </div>
        <div class="p-2 m-3" *ngIf="productionTracker.routingInfoList?.length===0  && !editable"><h6  class="text-muted f-13">No Routing info available</h6></div>
        <ng-container *ngFor="let routingInfo of productionTracker.routingInfoList; index as routingInfoIndex">
       
        <div class="p-2 m-3 border border-3 rounded " [style]="routingInfoIndex%2===1?'background:#fff7f0':''" *ngIf="routingInfo.deleteFlag===false"> 
            
            <div class="row "  ><!--background:#fff7f0-->
                <div style="position: absolute;right:0%;" (click)="deleteRoutingInfo(routingInfo)" *ngIf="editable"><i class="fas fa-trash text-danger shadow"></i></div>
                <div class="col-2">
                    <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Routing Type</label>
                    <select class="form-control form-control-sm custom-select" [(ngModel)]="routingInfo.routingType"  name="routingType_{{routingInfoIndex}}"  style="font-size: 11px;"
                        #routingTypeVar="ngModel" required  [ngClass]="{'is-invalid':routingTypeVar.touched && routingTypeVar.errors}"
                        [disabled]="!editable" >
                        <option value="EDI">EDI</option>
                        <option value="MANUAL">Manual</option>
                        <option value="PORTAL">Portal</option>
                    </select>
                    <div *ngIf="routingTypeVar?.invalid && (routingTypeVar.dirty || routingTypeVar.touched)" class="invalid-feedback">
                        <div *ngIf="routingTypeVar.errors.required">
                            Routing Type is required.
                        </div>
                    </div>
                </div>
                
                
                <div class="col-2">
                    <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Routing Deadline</label>
                    <div class="d-flex d-inline">
                    <input matInput [matDatepicker]="routingDeadline"  name="routingDeadline_{{routingInfoIndex}}" [(ngModel)]="routingInfo.routingDeadline" style="cursor: pointer;font-size: 11px;"
                        class="form-control  form-control-sm" required #routingDeadlineVar="ngModel" [ngClass]="{'is-invalid':routingDeadlineVar.touched && routingDeadlineVar.errors}"
                         [disabled]="!editable">
                    <mat-datepicker #routingDeadline></mat-datepicker>
                    <button class="btn btn-sm" type="button" (click)="routingDeadline.open()" [disabled]="!editable"><i class="fas fa-calendar-alt"></i></button>
                    </div>
                    <mat-error *ngIf="routingDeadlineVar?.errors?.required && (routingDeadlineVar.dirty || routingDeadlineVar.touched)" style="font-size: 80%;color: #dc3545">
                        Routing Deadline is required.
                    </mat-error>
                    <div *ngIf="routingDeadlineVar?.invalid && (routingDeadlineVar.dirty || routingDeadlineVar.touched)" class="invalid-feedback">
                    <div *ngIf="routingDeadlineVar.errors.required">Routing Deadline is required.</div>
                    </div>
                </div>

                <div class="col-2">
                    <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Routing Status</label>
                    <select class="form-control form-control-sm custom-select" [(ngModel)]="routingInfo.routingStatus"  name="routingStatus_{{routingInfoIndex}}"  style="font-size: 11px;"
                        #routingStatusVar="ngModel" required  [ngClass]="{'is-invalid':routingStatusVar.touched && routingStatusVar.errors}"
                        [disabled]="!editable">
                        <option value="ROUTING_PENDING">Routing Pending</option>
                        <option value="ROUTED_PARTIALLY">Routed Partially</option>
                        <option value="PO_DELAYED">PO Delayed</option>
                        <option value="ROUTED">Routed</option>
                        <option value="CANCELLED">Cancelled</option>
                    </select>
                    <div *ngIf="routingStatusVar?.invalid && (routingStatusVar.dirty || routingStatusVar.touched)" class="invalid-feedback">
                        <div *ngIf="routingStatusVar.errors.required">
                            Routing Status is required.
                        </div>
                    </div>
                </div>
               
                
            </div>
            </div>
           
        </ng-container>
        <div class=" my-2 pl-4">
            <button type="button" class="btn btn-sm btn-info" (click)="addRoutingInfo()" *ngIf="editable">Add Routing Info</button>
        </div>
    </form>
</div>
<!--Routing SECTION ENDS-->