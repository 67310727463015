import { Permission } from './permission.model';

export class Role {
    id: string;
    roleDescription: string;
    roleId: string;
    rolePermissions: Permission[];

    constructor(){
        this.id = null;
        this.roleDescription = null;
        this.roleId = null;
    }
}
