import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';
// import { Observable } from 'rxjs/Observable';

export interface CanComponentDeactivate {
  confirm(): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private dialog: MatDialog){  }
  canDeactivate(
    component: CanComponentDeactivate,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean>|boolean {

      //

      if(!component.confirm()) {
        /*  if (confirm('You have unsaved changes! If you leave, your changes will be lost.')) {
          return true;
        } else {
          return false;
        }*/
        return this.customConfirm();
      }else{
        return true;
      }
  }

  customConfirm():Observable<boolean>{
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        title: 'Cancel Edit',
        message: 'You have unsaved changes! If you confirm, your changes will be lost.'
      }
    });
    return confirmDialog.afterClosed();
  }
}