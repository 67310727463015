import { User } from './user.model';

export class CustomerSalesPerson {
   id: string;
   parentId: string;
   userId: string;
   salesPerson: User;
   deleteFlag: boolean = false;
   sharedSalesRepUserNameList:string[];
   

   //transient
   selectedSharedSalesPersonUsername:string;
}
