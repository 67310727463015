import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesOrderCostingService } from 'src/app/shared/service/sales/sales-order-costing.service';
import { CostingAllocation } from 'src/app/shared/model/sales/costing/allocation/costing-allocation.model';
import { CostingSheet } from 'src/app/shared/model/sales/costing/costingSheet/costing-sheet.model';
import { CostingHelperService } from 'src/app/shared/service/sales/costing-helper.service';
import { StyleCosting } from 'src/app/shared/model/sales/costing/allocation/style-costing.model';
import { CostTypeDetail } from 'src/app/shared/model/sales/costsheet/cost-type-detail.model';

@Component({
   selector: 'app-costing-sheet',
   templateUrl: './costing-sheet.component.html',
   styleUrls: ['./costing-sheet.component.css']
})
export class CostingSheetComponent implements OnInit {

   constructor(private activatedRoute: ActivatedRoute, private costingService: SalesOrderCostingService, private location: Location, private costingHelperService: CostingHelperService) { }

   ngOnInit(): void {
      this.setSalesOrderIdAndOrderDetailId();
      this.getCostingAllocation(this.salesOrderId);
   }

   salesOrderId: string;
   orderDetailId: string;
   costingAllocation: CostingAllocation;
   costingSheets: CostingSheet[] = [];
   currentStyleCosting: StyleCosting;

   editable: boolean = false;


   setSalesOrderIdAndOrderDetailId() {
      this.salesOrderId = this.activatedRoute.snapshot.paramMap.get('salesOrderId');
      this.orderDetailId = this.activatedRoute.snapshot.paramMap.get('orderDetailId');
      //console.log(this.salesOrderId);
   }

   getCostingAllocation(salesOrderId: string) {
      this.costingService.getAllocationSheet(salesOrderId).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let costingAllocation: CostingAllocation = response.responsePayload as CostingAllocation;
            this.costingAllocation = costingAllocation;
            this.populateCostingSheetsForStyle();
            console.log('CostingSheets = ', this.costingSheets);
         }
      });
   }

   populateCostingSheetsForStyle() {
      this.costingAllocation.styleCosting.forEach(styleCosting => {
         if (styleCosting.orderDetailId === this.orderDetailId) {
            this.currentStyleCosting = styleCosting;
            this.costingSheets = [];
            this.costingSheets = styleCosting.costingSheets;
            return;
         }
      });
   }

   goBack() {
      this.location.back();
   }

   onUnitCostChange(costingSheet: CostingSheet) {

   }

   onRemarksChange(costTypeDetail: CostTypeDetail, costingSheet: CostingSheet) {

   }

   viewOrEditCostingSheetNotes() {

   }

   close() {
      window.close();
   }

}
