import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Product, ProductAttachment } from '../model/product.model';
import { environment } from 'src/environments/environment';
import { ProductCatalogRequest } from '../model/product-catalog-request.model';
import { Observable, forkJoin } from 'rxjs';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { LoaderService } from './loader.service';
import { ToastrService } from 'ngx-toastr';
import { ServiceStatus } from '../model/service-status.model';
@Injectable({
   providedIn: 'root'
})

export class ProductCatalogService {
   readonly rooturl = environment.apiEndPoint;
   data = ["/assets/loading.gif"];

  getRequests = [];
   constructor(private http: HttpClient,private loader:LoaderService, private toastrService: ToastrService) {
      //  this.selectedProductsForCatalog=[];
   }
   catalogSettings: ProductCatalogRequest;
   selectedProductsForCatalog: Product[] = [];

   ngOnInit() {
     //  this.selectedProductsForCatalog = [];
   }

   addProduct(product: Product) {
      if (!this.isProductSelected(product.id)) {
         this.selectedProductsForCatalog.push(product);
      }
   }

   removeProduct(product: Product) {
      //
      this.selectedProductsForCatalog = this.selectedProductsForCatalog.filter(productInList => productInList.id !== product.id);
      //
   }

   isProductSelected(id: string): boolean {
      let temp: Product[] = this.selectedProductsForCatalog.filter(productInList => productInList.id === id);
      if (temp.length == 1) {
         return true;
      } else {
         return false;
      }
   }

   checkAndRemoveForCatalog(product: Product) {
      if (this.isProductSelected(product.id)) {
         this.removeProduct(product);
      }
   }
   selectionChanged(product) {
      let selected:boolean=!this.isProductSelected(product.id);
      let selectedForCatalog = product as Product;
      if (selected) {
         this.addProduct(selectedForCatalog);
      } else {
         this.removeProduct(product);
      }
   }

   createCatalog(req: ProductCatalogRequest) {
     // 
      return this.http.post(this.rooturl + "/catalog/createCatalog", req, { responseType: 'arraybuffer' });
   }

   saveCatalog(req: ProductCatalogRequest,catalogName:string){
      return this.http.post(this.rooturl + "/catalog/saveCatalog/"+catalogName, req);
   }
   loadCatalog(attachmentId:string){
      return this.http.get<ServiceStatus>(this.rooturl + "/catalog/loadCatalog/"+attachmentId);
   }
   getDraftCatalogList(){
      return this.http.get(this.rooturl + "/catalog/list/draft");
   }
   deleteCatalogDraft(id: string) {
      return this.http.get(this.rooturl + "/catalog/delete/draft/"+id);
    }
   isCatalogDraftExisting(catalogName: string) {
      return this.http.get(this.rooturl + "/catalog/draft/exists/"+catalogName);
    }
    

   loadFileList(category: string): Observable<ProductAttachment[]> {
      return this.http.get<ProductAttachment[]>(this.rooturl + "/catalog/loadFileList/" + category);
   }
   
   deleteCatalogFile(pdfName: string) {
      return this.http.get(this.rooturl + "/catalog/deleteCatalogFile/" + pdfName);
   }

   uploadFileForCatalogWithProgress(file: File, filecategory: string) {
      const fd = new FormData();
      fd.append('file', file, file.name);
      return this.http.post(this.rooturl + "/catalog/uploadFile/" + filecategory, fd, {
         reportProgress: true,
         observe: 'events'
      });
   }

   deleteFile(attachmentId: string, category: string) {
      return this.http.get(this.rooturl + "/catalog/deleteFile/" + category + "/" + attachmentId);
   }

   
/*********************************DOWNLOAD IMAGES AS ZIP************************/
async download() {
   var utc = new Date().toJSON().slice(0,10);
   this.data=[];
   this.getRequests=[];
   let productAttachmentIds:string[]=this.selectedProductsForCatalog.map(x=>x.productImages[0].id);
   this.selectedProductsForCatalog=[];
   await this.http.post(this.rooturl + "/product/refreshSignedUrls",productAttachmentIds).toPromise().then(res=>{
     // let productAttachments =res as ProductAttachment[];
      //
      //this.data=productAttachments.map(x=>x.fileSignedUrl);
      this.data=res as string[];
      if(this.data===null || this.data===undefined || this.data.length===0){
         this.toastrService.error('Selected image(s) can not be downloaded');
      }
   },err=>{
      this.toastrService.error('Error while downloading selected image(s) - '+err);
      //
   });
   
   //
   //this.data=this.selectedProductsForCatalog.map(x=>x.productImages[0].fileSignedUrl);
   if(this.data!==null && this.data!==undefined && this.data.length>0){
      
      this.createGetRequets(this.data);
      this.loader.show();
      forkJoin(...this.getRequests).subscribe(res => {
        
         const zip = new  JSZip.default();
         let fileName: String;
         res.forEach((f, i) => {
            fileName = f.url.substring(f.url.lastIndexOf("/") + 1,f.url.lastIndexOf("?") );//'abc'+i+'.jpg'// // extract filename from the response
            if(fileName.length>14){
               fileName=fileName.slice(fileName.indexOf('-')+1,fileName.length);
            }
            zip.file(`${fileName}`, f.body); // use it as name, this way we don't need the file type anymore
         });
         zip.generateAsync({ type: "blob" }).then(blob => {
            saveAs(blob, "PhilcosLinksSelectedImages_"+utc+".zip");
            this.loader.hide();
            this.toastrService.success('Selected images downloaded successfully');
            this.selectedProductsForCatalog=[];
         });
        
      });
      
   }
 }

 getSelecedProductIdList():string[]{
   let productIdArr:string[]=[];
   this.selectedProductsForCatalog.forEach(product => {
      productIdArr.push(product.id);
   });
   return productIdArr;
 }

 private createGetRequets(data: string[]) {
   data.forEach(url =>
     this.getRequests.push(
       this.http.get(url, { responseType: 'arraybuffer' , observe: 'response' })
     )
   );
 }
/*********************************DOWNLAOD IMAGES AS ZIP EDS********************/

}
