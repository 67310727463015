export class FileDeleteCriteria {
    /*fileToDeleteUrl:string;
    fileToDeleteCategory:string;
    licensorId:string;
    brandId:string;
    productId:string;
    licensorName:string;
    brandName:string;
    productName:string;*/
    id:string;
}
