<div class="container">
   <div class="row mt-2 d-flex justify-content-center">
      <h2 class="d-inline">Printers</h2>
      <button type="button" [routerLink]="['/printer/create']" class="btn btn-circle btn-circle-md d-inline ml-4" *ngIf="userAccess?.canCreateVendors"><i
            class="fas fa-plus fa-lg text-light"></i></button>
   </div>

   <hr>

   <div class="row mb-3">
      <div class="col-sm-4">
         <input id='filterInput' class="form-control mb-2" #search placeholder="Filter by Printer Name.." (keyup)="onKeyupPrinterNameFilter($event,search.value)">
      </div>
      <div class="col-sm-4 mt-2">
         Found {{filteredItemList.length}}
         {{search.value.length>0? 'Printers for "'+search.value+'"' : 'Printers'}}
      </div>
   </div>

   <!-- GROUPED DISPLAY -->

   <div *ngFor="let groupedItems of groupedItemsList;index as l">
      <h5> {{groupedItems.key}} </h5>
      <hr style="margin-top: 0rem">
      <div class="row gallery-block cards-gallery mt-4">
         <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-4" *ngFor="let item of groupedItems.items;index as i">
            <div class=" card border-0 transform-on-hover h-100 color-2">
               <a [routerLink]="['/printer/view/'+item.id]">
                  <div class="card-body" style="padding: 0.25rem;">
                     <div class=" ml-1 mr-1">
                        <div class="mb-3 mt-2">
                           <h5 class="text-truncate ml-2 mr-2 card-text justify-content-center">
                              {{item.name}}
                           </h5>
                        </div>
                     </div>
                     <p class="card-text">{{item.status?'Active':'Inactive'}}</p>

                  </div>
               </a>
               <div class="d-flex justify-content-center">
                  <button class="btn  btn-circle btn-circle-sm d-inline" (click)="deletePrinter(item)" *ngIf="userAccess.canDeleteVendors">
                     <i class="fas fa-trash text-light"></i>
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>


</div>

<div class="floatBtn d-flex flex-column">
   <button type="button" class="btn btn-circle bg-secondary mb-2" matTooltip="Scroll To Top" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'});" matTooltipPosition="left"
      matTooltipClass="bg-dark">
      <i class="fas fa-arrow-up fa-2x" style="color:white"></i>
   </button>
</div>