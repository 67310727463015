
<div class="container-fluid">
    <div class="mx-1 mt-3 d-flex justify-content-center">
        <div class="d-flex flex-row  mb-3 ml-1"  *ngFor="let dashboaardStat of dashBoardStats; index as index" style="border-right: 1px solid lightgray;min-width: 10%;max-width: 10%;">
            <a (click)="setSelectedItemIndex(index)" style="cursor: pointer;">
        <div class="h-100 d-flex justify-content-center" style="border:0px;" [style]="selectedItemIndex===index?'color:tomato;':''">
              <div class="p-2" >
                <div class="d-flex justify-content-center">
                    <h1 class="mr-2 align-text-top align-middle" style="font-size: 2rem;">{{dashboaardStat.skuList.length}}</h1>
                </div>
                <div class="d-flex justify-content-center f-14">
                    <span>{{dashboaardStat.title}}</span>
                   
                </div>
                <div class="d-flex align-items-end text-nowrap text-muted f-11 mt-1">
                    {{dashboaardStat.footerDesc}}
                  </div>
                
              </div>
              
        </div>
        <!--<div class="d-flex justify-content-start align-items-end">
           
        </div>-->
        
            </a>
          </div>    
       
    </div>
    <!--<div class="mt-2 mx-4" *ngIf="this.selectedItemIndex>=0 && this.dashBoardStats[this.selectedItemIndex]?.skuList?.length>0">
        <h4 class="text-center">{{this.dashBoardStats[this.selectedItemIndex]?.title}}</h4>
        <hr class="hr-min">
    </div>-->
    <hr class="hr-min">
    
    <br>
    
    <div *ngIf="this.selectedItemIndex>=0 && searchProductListOverride.length>0">
        <app-sku-tracker-list [searchProductListOverride]="searchProductListOverride" (onSave)="onSave($event)"></app-sku-tracker-list>
    </div>













    <!--<div class="d-flex justify-content-center align-items-center">
        <div class="card bg-success" style="width: 18rem;"  >
            <div class="card d-flex justify-content-start" style="width: 6rem;" >
                <div class="card-body">
                    <h2>25</h2>
                </div>
            </div>
        </div>
   
    </div>-->

</div>
