import { Component, OnInit, Input, ViewChild, Directive } from '@angular/core';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { NgForm, ControlContainer, NgModelGroup } from '@angular/forms';
import { Metadata } from 'src/app/shared/model/metadata.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { Profile } from 'src/app/shared/model/profile.model';
import { Brand } from 'src/app/shared/model/brand.model';
import { BrandService } from 'src/app/shared/service/brand.service';
import { LicensorService } from 'src/app/shared/service/licensor.service';
import { Licensor } from 'src/app/shared/model/licensor.model';

@Component({
   selector: 'app-profile',
   templateUrl: './profile.component.html',
   styleUrls: ['./profile.component.css'],
   viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class ProfileComponent implements OnInit {
   constructor(private metadataService: MetadataService, private sharedService: SharedService, private brandService: BrandService, private licensorService: LicensorService) { }

   @Input() editable: boolean;
   @Input() profile: Profile;
   @Input() licensorId: string;
   @Input() contactType: string;

   metadata: Metadata;
   contactTypeList = [];
   filteredBrandList: Brand[] = [];

   ngOnInit(): void {
      this.loadContactTypes();
      this.loadBrandList();
   }

   logValue() {
   }

   loadContactTypes() {
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('LICENSOR', 'CONTACT', 'TYPE').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.contactTypeList = this.sharedService.sort(res.responsePayload);
         }
      });
   }

   loadBrandList(){
      this.licensorService.loadLicensorWithOnlyBrands(this.licensorId,false).subscribe((res) => {
         let selectedlicensor = res as Licensor;
         this.filteredBrandList = this.sharedService.sortListByPropertyName(selectedlicensor.brands, 'brandName');
         let defaultBrand:Brand  = new Brand;
         defaultBrand.id='ANY';
         defaultBrand.brandName = 'Default';
         this.filteredBrandList.unshift(defaultBrand);
      });
   }

   isItemSelected(item: string): boolean {
      return this.checkIfItemExists(this.profile?.contactDetailsTypeList, item);
   }

   checkIfItemExists(list: string[], item: string) {
      if (list) {
         let temp = list.filter(itemInList => itemInList === item);
         return (temp.length == 1);
      } else {
         return false;
      }
   }

   onContactTypeSelection(event: any, item: string) {
      if (event.target.checked) {
         if (!this.checkIfItemExists(this.profile.contactDetailsTypeList, item)) {
            this.profile.contactDetailsTypeList.push(item);
         }
      } else {
         this.profile.contactDetailsTypeList = this.profile.contactDetailsTypeList.filter(itemInList => itemInList !== item);
      }
   }

   submissionSheetReviewerSelected(selection:boolean){
      //
      if(selection){
         this.profile.emailRecipient='TO';
      }else{
         this.profile.emailRecipient='N/A';
      }

   }

   openBrandSelectionModal(){
      document.getElementById('brandSelectionModalToggler').click();
   }

   isBrandSelected(brand: Brand){
      if(this.profile.associatedBrands === undefined || this.profile.associatedBrands === null){
         return false;
      }
      let temp = this.profile.associatedBrands.filter(itemInList => itemInList.id === brand.id);
      return (temp.length == 1);
   }

   onBrandSelection(event:any,brand:Brand){
      if(this.profile.associatedBrands === undefined || this.profile.associatedBrands === null){
         this.profile.associatedBrands = [];
      }
      if (event.target.checked) {
         if (!this.checkIfBrandExists(this.profile.associatedBrands, brand.id)) {
            if(brand.id === 'ANY'){
               this.profile.associatedBrands = [];
            }else{
               this.profile.associatedBrands = this.profile.associatedBrands.filter(associatedBrand => associatedBrand.id !== 'ANY');
            }
            this.profile.associatedBrands.push(brand);
            this.sharedService.sortListByPropertyName(this.profile.associatedBrands,'brandName');
         }
      } else {
         this.profile.associatedBrands = this.profile.associatedBrands.filter(associatedBrand => associatedBrand.id !== brand.id);
      }
   }

   onClickOkBrandSelectionModal(){
      document.getElementById('brandSelectionModalToggler').click();
   }

   checkIfBrandExists(list: Brand[], brandId: string) {
      if (list) {
         let temp = list.filter(itemInList => itemInList.id === brandId);
         return (temp.length == 1);
      } else {
         return false;
      }
   }

}
