import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SharedService } from 'src/app/shared/service/shared.service';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { ToastrService } from 'ngx-toastr';
import { LicensorService } from 'src/app/shared/service/licensor.service';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { Royalty, RoyaltyFeeDetail, RoyaltyTierDetail } from 'src/app/shared/model/royalty.model';
import { Licensor } from 'src/app/shared/model/licensor.model';
import { Brand } from 'src/app/shared/model/brand.model';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { RoyaltyService } from 'src/app/shared/service/royalty.service';
import { cloneDeep } from 'lodash';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
   selector: 'app-royalty-overview',
   templateUrl: './royalty-overview.component.html',
   styleUrls: ['./royalty-overview.component.css']
})
export class RoyaltyOverviewComponent implements OnInit {

   constructor(private sharedService: SharedService ,private authService:AuthService, private metadataService: MetadataService,
      private royaltyService: RoyaltyService, private toastrService: ToastrService, private licensorService: LicensorService,
      private location: Location
   ) { }

   userAccessSubscription: Subscription;
   userAccess: UserAccess;

   sortColumns = [
      { columnName: 'Licensor', sortByName: 'licensorName' },
      { columnName: 'Brand', sortByName: 'brandName' }
   ];

   selectedSortByName: string;
   sortMode: string = 'ASC';

   royaltyList: Royalty[];
   filteredRoyaltyList: Royalty[];

   defaultRoyaltyFeeDetailList: RoyaltyFeeDetail[];
   defaultRoyaltyTierDetailList: RoyaltyTierDetail[];

   licensorList: Licensor[] = null;
   brandList: Brand[] = null;

   productCategoryList: string[];
   filteredProductCategoryList: string[];

   selectedRoyaltyObject: Royalty;
   editMode: boolean = false;

   selectUnSelectAllRadio: string = null;

   ngOnInit(): void {
      this.handleSubscriptions();
      this.loadRoyaltyList();
      this.loadLicensors();
      //this.loadProductCategories();
      //this.loadRoyaltyProductCategories();
      this.loadDefaultRoyaltyFeeDetailList();
      this.loadDefaultRoyaltyTierDetailList();
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
   }

   loadRoyaltyList() {
      this.royaltyService.getRoyaltyList().subscribe(response => {
         this.royaltyList = response.responsePayload;
         this.filteredRoyaltyList = response.responsePayload;
         //
         this.filteredRoyaltyList = this.sharedService.sortListByPropertyName(this.filteredRoyaltyList,'licensorName');
      });
   }


   loadLicensors() {
      this.licensorService.loadLicensors().subscribe((res: Licensor[]) => {
         this.licensorList = this.sharedService.sortListByPropertyName(res, 'licensorName');
      });
   }

   
   loadRoyaltyProductCategories(){
      this.royaltyService.getRoyaltyProductCategoryList().subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.productCategoryList = this.sharedService.sort(res.responsePayload);
            this.filteredProductCategoryList = this.productCategoryList;
         }
      });
   }

   loadLicensorWithOnlyBrands(licensorId: string, royaltyRightsIndex: string) {
      this.licensorService.loadLicensorWithOnlyBrands(licensorId,false).subscribe((res) => {
         let selectedlicensor = res as Licensor;
         this.brandList = this.sharedService.sortListByPropertyName(selectedlicensor.brands, 'brandName');
         this.filteredRoyaltyList[royaltyRightsIndex].brandList = this.brandList;
      });
   }

   loadDefaultRoyaltyFeeDetailList() {
      this.royaltyService.getDefaultRoyaltyFeeDetailList().subscribe(response => {
         this.defaultRoyaltyFeeDetailList = response.responsePayload;
         //
      });
   }

   loadDefaultRoyaltyTierDetailList() {
      this.royaltyService.getDefaultRoyaltyTierDetailList().subscribe(response => {
         this.defaultRoyaltyTierDetailList = response.responsePayload;
         //
      });
   }

   onLicensorChange(event: any, royalty: Royalty, royaltyIndex: string) {
      //
      this.loadLicensorWithOnlyBrands(royalty.licensorId, royaltyIndex);
      this.filteredRoyaltyList[royaltyIndex].brandList = null;
      this.filteredRoyaltyList[royaltyIndex].brandId = undefined;
      this.filteredRoyaltyList[royaltyIndex].brandName = undefined;
   }

   onBrandChange($event) {
   }


   onProductCategoryAddOrEdit(royalty: Royalty) {
      //
      this.selectedRoyaltyObject = royalty;
      //this.loadProductCategories();
      this.loadRoyaltyProductCategories();
      //this.selectUnSelectAllRadio = null;
      document.getElementById('productCategoryModalToggler').click();
   }

   onProductCategorySelection(event: any, item: string, royalty: Royalty) {
      //
      if (royalty !== null && royalty !== undefined) {
         this.selectedRoyaltyObject = royalty;
      }
      if (event.target.checked) {
         if (!this.checkIfItemExists(this.selectedRoyaltyObject.productCategoryList, item)) {
            this.selectedRoyaltyObject.productCategoryList.push(item);
         }
      } else {
         this.selectedRoyaltyObject.productCategoryList = this.selectedRoyaltyObject.productCategoryList.filter(itemInList => itemInList !== item);
      }
      this.sharedService.sort(this.selectedRoyaltyObject.productCategoryList);
   }

   checkIfItemExists(list: string[], item: string) {
      if (list) {
         let temp = list.filter(itemInList => itemInList === item);
         return (temp.length == 1);
      } else {
         return false;
      }
   }

   onSelectOrUnselectAll(status: string, type: string) {
      //
      this.selectUnSelectAllRadio = status;
      if (status === 'true') {
         if (type === 'ProductCategory') {
            this.selectedRoyaltyObject.productCategoryList = [];
            this.selectedRoyaltyObject.productCategoryList = this.selectedRoyaltyObject.productCategoryList.concat(this.productCategoryList);
         }

      } else {
         if (type === 'ProductCategory') {
            this.selectedRoyaltyObject.productCategoryList = [];
         }

      }
   }

   isItemSelected(item: string, type: string): boolean {
      if (type === 'ProductCategory') {
         return this.checkIfItemExists(this.selectedRoyaltyObject?.productCategoryList, item);
      }
   }

   editRoyalty(royalty: Royalty, royaltyRightsIndex: string) {
      //
      royalty.editable = true;
      this.loadLicensorWithOnlyBrands(royalty.licensorId, royaltyRightsIndex);
      //this.evaluateEditMode();
   }

   cancelRoyalty(royalty: Royalty, royaltyRightsIndex: string) {
      //
      royalty.editable = false;
      if (royalty.id !== null && royalty.id !== undefined) {
         this.getRoyalty(royalty.id, royaltyRightsIndex);
      } else {
         this.filteredRoyaltyList = this.filteredRoyaltyList.filter(item => item !== royalty);
      }
      let tempRoyaltyList : Royalty[] =[];
      tempRoyaltyList = cloneDeep(this.filteredRoyaltyList);
      this.refreshRoyaltyList(tempRoyaltyList);
      //this.evaluateEditMode();
   }

   getRoyalty(royaltyId: string, royaltyRightsIndex: string) {
      this.royaltyService.getRoyalty(royaltyId).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.filteredRoyaltyList[royaltyRightsIndex] = response.responsePayload;
         }
      });
   }

/* Commenting this because not required for individual save

   evaluateEditMode() {
      let temp: Royalty[] = this.filteredRoyaltyList.filter(item => item.editable === true);
      //('evaluateEditMode =', temp)
      this.editMode = (temp.length >= 1);
   }
*/
   deleteRoyalty(royalty: Royalty, royaltyIndex: string) {
      //
      this.royaltyService.deleteRoyalty(royalty.id).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.filteredRoyaltyList = this.filteredRoyaltyList.filter(item => item !== royalty);
            let tempRoyaltyList : Royalty[] =[];
            tempRoyaltyList = cloneDeep(this.filteredRoyaltyList);
            this.refreshRoyaltyList(tempRoyaltyList);
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
      }, (err) => {
         this.toastrService.error(JSON.stringify(err));
      });
      //this.evaluateEditMode();
   }

   addNewRoyalty() {
      let newRoyalty: Royalty = new Royalty;
      newRoyalty.editable = true;
      this.loadDefaultRoyaltyTierDetailList();
      this.loadDefaultRoyaltyFeeDetailList();
      newRoyalty.allRoyaltyFeeDetails = this.defaultRoyaltyFeeDetailList;
      newRoyalty.allRoyaltyTierDetails = this.defaultRoyaltyTierDetailList;
      this.filteredRoyaltyList.push(newRoyalty);
      //
      //this.evaluateEditMode();
      setTimeout(x=>
      window.scrollTo(0,document.body.scrollHeight+100),500);
      //window.scrollTo({top: document.body.scrollHeight, left: 0, behavior: 'smooth'});
   }

   saveRoyaltyOld(royalty, royaltyIndex) {
      //
      royalty.editable = false;
      this.royaltyService.createOrUpdateRoyalty(royalty).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            let broyaltyAfterSave = response.responsePayload;
            this.getRoyalty(broyaltyAfterSave.id, royaltyIndex);
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
      }, (err) => {
         console.error(err);
         this.toastrService.error(JSON.stringify(err));
      });

      //this.evaluateEditMode();
   }

   saveRoyalty(royalty: Royalty, royaltyIndex: any) {
      //
      this.royaltyService.createOrUpdateRoyalty(royalty).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let royaltyAfterSave = response.responsePayload;
            //
            royalty.hasError = royaltyAfterSave.hasError;
            if (royaltyAfterSave.hasError) {
               royalty.editable = true;
               royalty.hasError = royaltyAfterSave.hasError;
               this.toastrService.error("Royalty Save Failed.");
            } else {
               this.getRoyalty(royaltyAfterSave.id, royaltyIndex);
               royalty.editable = false;
               this.toastrService.success(response.responseStatus.message);
            }
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
      }, (err) => {
         console.error(err);
         this.toastrService.error(JSON.stringify(err));
      });
      //this.evaluateEditMode();
   }

   saveRoyaltyListOld(){
      //
      let royaltyListToSave: Royalty[] = this.filteredRoyaltyList.filter(item => item.editable === true);
      //
      this.royaltyService.createOrUpdateRoyaltyList(royaltyListToSave).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
         this.loadRoyaltyList();
         this.filteredRoyaltyList = this.filteredRoyaltyList.filter(item => item.editable = false);
         //this.evaluateEditMode();
      }, (err) => {
         console.error(err);
         this.toastrService.error(JSON.stringify(err));
      });
   }

   saveRoyaltyList() {
      //
      this.filteredRoyaltyList.forEach((element, index) => {
         if (element.editable) {
            this.saveRoyalty(element, index);
         }
      });
   }


   onKeyupFilter(event, value) {
      if (event.key !== 'Shift') {
         if (value.trim().length > 0) {
            this.filteredRoyaltyList = this.filter(this.filteredRoyaltyList, value);
         } else {
            this.filteredRoyaltyList = this.royaltyList;
         }
      }
   }

   filter(list: Royalty[], value: string) {
      //
      let filteredList: Royalty[] = list.filter(item => {
         //
         //
         //
         //
         //
         return (
            item.licensorName?.toUpperCase().includes(value.toUpperCase()) ||
            item.brandName?.toUpperCase().includes(value.toUpperCase()) ||
            item.productCategories?.toUpperCase().includes(value.toUpperCase()) ||
            item.notes?.toUpperCase().includes(value.toUpperCase())
         )
      });
      return filteredList;
   }

   goBack() {
      this.location.back();
   }

   cancelEditMode() {
      //
      this.loadRoyaltyList();
      this.filteredRoyaltyList = this.filteredRoyaltyList.filter(item => item.editable = false);
      //this.evaluateEditMode();
   }

   onSortColumnChange($event: any) {
      //
      this.filteredRoyaltyList = this.sharedService.sortListByPropertyName(this.filteredRoyaltyList, this.selectedSortByName);
   }

   sortAsc() {
      this.sortMode = "ASC";
      this.onSortColumnChange(null);
   }

   sortDesc() {
      this.sortMode = "DESC";
      this.onSortColumnChange(null);
      this.filteredRoyaltyList.reverse();
   }

   loadBrands(royalty: Royalty) {
      this.licensorService.loadLicensorWithOnlyBrands(royalty.licensorId,false).subscribe((res) => {
         let selectedlicensor = res as Licensor;
         this.brandList = this.sharedService.sortListByPropertyName(selectedlicensor.brands, 'brandName');
         royalty.brandList = this.brandList;
      });
   }

   cloneRoyalty(royaltyToBeCloned: Royalty, royaltyIndex: number){
      let tempRoyaltyList : Royalty[] =[];
      tempRoyaltyList = cloneDeep(this.filteredRoyaltyList);
      let clonedRoyalty: Royalty = new Royalty;
      clonedRoyalty.id = null;
      clonedRoyalty.editable = true;
      clonedRoyalty.brandId = royaltyToBeCloned.brandId;
      clonedRoyalty.brandName = royaltyToBeCloned.brandName;
      clonedRoyalty.licensorId = royaltyToBeCloned.licensorId;
      clonedRoyalty.notes = royaltyToBeCloned.notes;
      clonedRoyalty.licensorName = royaltyToBeCloned.licensorName;
      clonedRoyalty.productCategories = royaltyToBeCloned.productCategories;
      clonedRoyalty.productCategoryList = royaltyToBeCloned.productCategoryList;
      this.loadBrands(clonedRoyalty);

      for (let index = 0; index < royaltyToBeCloned.allRoyaltyFeeDetails.length; index++) {
         let clonedRoylatyFeeDetail:RoyaltyFeeDetail  = new RoyaltyFeeDetail;
         clonedRoylatyFeeDetail.feeType = royaltyToBeCloned.allRoyaltyFeeDetails[index].feeType;
         clonedRoylatyFeeDetail.feeValue = royaltyToBeCloned.allRoyaltyFeeDetails[index].feeValue;
         clonedRoyalty.allRoyaltyFeeDetails.push(clonedRoylatyFeeDetail);
      }

      for (let index = 0; index < royaltyToBeCloned.allRoyaltyTierDetails.length; index++) {
         let clonedRoylatyTierDetail:RoyaltyTierDetail  = new RoyaltyTierDetail;
         clonedRoylatyTierDetail.tierType = royaltyToBeCloned.allRoyaltyTierDetails[index].tierType;
         clonedRoylatyTierDetail.tierValue = royaltyToBeCloned.allRoyaltyTierDetails[index].tierValue;
         clonedRoyalty.allRoyaltyTierDetails.push(clonedRoylatyTierDetail);
      }
      tempRoyaltyList.splice(royaltyIndex+1, 0, clonedRoyalty);
      this.refreshRoyaltyList(tempRoyaltyList);
      //this.evaluateEditMode();
   }

   refreshRoyaltyList(tempRoyaltyList : Royalty[]){
      this.filteredRoyaltyList = [];
      tempRoyaltyList.forEach(royalty => {
         this.filteredRoyaltyList.push(royalty);
       });
   }
}
