import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchComponent } from 'src/app/common/search/search.component';
import { SearchDetails } from 'src/app/shared/model/common/search-details.model';
import { FilterConfig } from 'src/app/shared/model/common/search-filter.model';
import { Customer } from 'src/app/shared/model/customer.model';
import { ProductionTracker } from 'src/app/shared/model/sales/fulfillment/production-tracker.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { Printer } from 'src/app/shared/model/vendors/printer.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { CustomerService } from 'src/app/shared/service/customer.service';
import { ProductionTrackerService } from 'src/app/shared/service/sales/fulfillment/production-tracker.service';
import { SalesHelperService } from 'src/app/shared/service/sales/sales-helper.service';
import { SearchService } from 'src/app/shared/service/search.service';
import { UserManagementService } from 'src/app/shared/service/user-management.service';
import { VendorsService } from 'src/app/shared/service/vendors/vendors.service';

@Component({
  selector: 'app-allocated-sales-order-list',
  templateUrl: './allocated-sales-order-list.component.html',
  styleUrls: ['./allocated-sales-order-list.component.css']
})
export class AllocatedSalesOrderListComponent implements OnInit {

  constructor(private productionTrackerService:ProductionTrackerService,private searchService:SearchService,
    private authService:AuthService,private vendorService:VendorsService, private customerService:CustomerService,
    private userService:UserManagementService, private salesHelperService:SalesHelperService) { }

     //userAccessSubscription: Subscription;
   userAccess: UserAccess;
   readyForInitSearch:boolean=false;

   //currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;
   sortColumns = [
    { columnName: 'SALES_ORDER_NUMBER', sortByName: 'Sales Order #' },
    { columnName: 'PRINTER_REQUEST_SHIP_DATE', sortByName: 'Order Ship Date' },
    { columnName: 'PRINTER_CANCEL_DATE', sortByName: 'Order Cancel Date' },
 ];
 productionTrackerList:ProductionTracker[]=[];
  searchConfig:SearchDetails;
  //blanksSupplierList:BlanksSupplier[]=[];
  printerList:Printer[]=[];
  customerList:Customer[]=[];

 @ViewChild('searchComponent') searchComponent:SearchComponent<ProductionTracker>;

  async ngOnInit(){
    console.log('AllocatedSalesOrderListComponent INIT Called');
    this.handleSubscriptions();
    await this.handleSearchOnInit();
  }
  handleSubscriptions() {
    this.authService.userAccess.subscribe(access => {
       this.userAccess = access;
       //console.log('Got userAccess');
    });
    this.authService.currentUser.subscribe(async currentLoggedInUser => {
      if(!this.currentLoggedInUser && currentLoggedInUser && currentLoggedInUser!==null){
        this.currentLoggedInUser = currentLoggedInUser;
        console.log(this.currentLoggedInUser);
        await this.handleSearchOnInit();
      }
       
       //console.log(' Got CurrentLoggedINuser');
    });
    this.vendorService.printerListObservable.subscribe(res => {
      if(res && res!=null && res.length>0){
        this.printerList.length=0;
        this.printerList.push(...res);
      }
      //console.log("PTBI PrinterList",this.printerList);
   });
   this.customerService.customerListObservable.subscribe(res => {
    if(res && res!=null && res.length>0){
      this.customerList.length=0;
      this.customerList.push(...res);
    }
 });
   
 }

  
  search() {
    if (this.searchComponent) {
       this.searchComponent.search();
    }
 }
  
  async handleSearchOnInit() {
    //await this.getVendorList();
    //console.log('Call Get vendor');
    //await this.getCustomers();
    //console.log('Call Get Customer');
    //await this.getSalesUserList();
    //console.log('Call Get SalesUser list');
    if (!this.searchConfig) {
      this.searchConfig = this.searchService.printerPortalListSearchDetails;
      this.setFilterConfigs();
      //this.search();
      /*this.searchConfig.setFilterInList('CLIENTID','String',[]);
      this.searchConfig.setFilterInList('WHPID','String',[]);
      this.searchConfig.searchCriteria.searchSubType='INVENTORY_LEVEL_2';*/
    }
  }

  setFilterConfigs() {
    if(!this.searchConfig.filterConfigs){
       this.searchConfig.filterConfigs=[];
       if(this.currentLoggedInUser && this.currentLoggedInUser.orgType!=='Printer'){
          let filterConfig2:FilterConfig={
            filterType:'IN_CHECKLIST',
            label:'Printer',
            currentSelection:'ALL',
            options: this.printerList,
            optionDislpayVar:'name',
            optionValueVar:'name',
            fieldName:'printer_name'
          };
          this.searchConfig.filterConfigs.push(filterConfig2);

          let customerFilterConfig:FilterConfig={
            filterType:'IN_CHECKLIST',
            label:'Customers',
            currentSelection:'ALL',
            options: this.customerList,
            optionDislpayVar:'customerName',
            optionValueVar:'id',
            fieldName:'CUSTOMER_ID'
          };
          this.searchConfig.filterConfigs.push(customerFilterConfig);
        }

     let shipDateFilterConfig:FilterConfig={
       filterType:'DATE_RANGE',
       label:'Ship Date',
       currentSelection:undefined,
       fieldName:'PRINTER_REQUEST_SHIP_DATE'
     };
     this.searchConfig.filterConfigs.push(shipDateFilterConfig);
     
     let cancelDateFilterConfig:FilterConfig={
      filterType:'DATE_RANGE',
      label:'Cancel Date',
      currentSelection:undefined,
      fieldName:'PRINTER_CANCEL_DATE'
    };
    this.searchConfig.filterConfigs.push(cancelDateFilterConfig);
     
 
     /*let salesUserFilterConfig:FilterConfig={
       filterType:'IN_CHECKLIST',
       label:'Sales Rep.',
       currentSelection:'ALL',
       options: this.salesUserList,
       optionDislpayVar:'fullName',
       optionValueVar:'userName',
       fieldName:'SALES_USER_NAME'
     };
     this.searchConfig.filterConfigs.push(salesUserFilterConfig);
 
     let filterConfig3:FilterConfig={
       filterType:'IN_CHECKLIST',
       label:'Printer Status',
       currentSelection:'ALL',
       options: [{value:'DELAYED',display:'Delayed'},{value:'ON_TIME',display:'On Time'},{value:'DETAILS_MISSING',display:'Details Missing'}],
       optionDislpayVar:'display',
       optionValueVar:'value',
       fieldName:'PRINT_INFO_STATUS'
     };
     this.searchConfig.filterConfigs.push(filterConfig3);
      */
     /*let fulfillmentStatus:FilterConfig={
       filterType:'IN_CHECKLIST',
       label:'Fulfillment Status',
       currentSelection:'ALL',
       options: [{value:'ART_PENDING',display:'Art Pending'},{value:'TRIMS Pending',display:'Trims Pending'}],
       optionDislpayVar:'display',
       optionValueVar:'value',
       fieldName:'SAMPLE_STATUS'
     };
     this.searchConfig.filterConfigs.push(fulfillmentStatus);*/
     
    }
    if(!this.searchConfig.defaultNonDisplayFilterConfigs){
      this.searchConfig.defaultNonDisplayFilterConfigs=[];
      let productionStatusListFilterForProductionTracker=this.salesHelperService.productionStatusListFilterForProductionTracker;
     let productionStatus:FilterConfig={
       filterType:'IN_CHECKLIST',
       label:'Production Status',
       currentSelection:'ALL',
       options: productionStatusListFilterForProductionTracker,
       optionDislpayVar:'display',
       optionValueVar:'value',
       fieldName:'PRODUCTION_STATUS'
     };
     this.searchConfig.defaultNonDisplayFilterConfigs.push(productionStatus);

     /*if(this.currentLoggedInUser && this.currentLoggedInUser.orgType==='Printer'){
      let filterPrinter:FilterConfig={
        filterType:'IN_LIST',
        label:'Printer',
        currentSelection:this.currentLoggedInUser.orgId,
        fieldName:'PRINTER_ID'
      };
      this.searchConfig.defaultNonDisplayFilterConfigs.push(filterPrinter);
      this.readyForInitSearch=true;
     //}else{*/
      //this.readyForInitSearch=true;
     //}
    }
   }

}
