import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/shared/model/user.model';
import { OrderDetail } from 'src/app/shared/model/sales/order-detail.model';
import { BlanksService } from 'src/app/shared/service/blanks.service';
import { Blanks } from 'src/app/shared/model/blanks.model';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
   selector: 'app-blanks-list',
   templateUrl: './blanks-list.component.html',
   styleUrls: ['./blanks-list.component.css']
})
export class BlanksListComponent implements OnInit {

   constructor(private sharedService: SharedService,
      private toastrService: ToastrService, private blanksService: BlanksService,private authService:AuthService) { }

   userAccessSubscription: Subscription;
   userAccess: UserAccess;

   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;

   sortColumns = [
      { columnName: 'SKU Code', sortByName: 'skuCode' },
      { columnName: 'Description', sortByName: 'description' },
      { columnName: 'Supplier', sortByName: 'supplier' },
      { columnName: 'GSM', sortByName: 'gsm' },
      { columnName: 'Fabric Content', sortByName: 'fabricContent' }
   ];

   selectedSortByName: string;
   sortMode: string = 'ASC';

   blanksList: Blanks[] = [];
   filteredBlanksList: Blanks[] = [];

   editable: boolean;

   ngOnInit(): void {
      this.handleSubscriptions();
      this.loadBlanksList();
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
         this.currentLoggedInUser = currentLoggedInUser;
      });
   }

   loadBlanksList() {
      
      this.blanksService.getBlanksList().subscribe(response => {
         this.blanksList = response.responsePayload;
         this.filteredBlanksList = this.blanksList;
      }, err => {
         console.error('Load Item List Error : ' + JSON.stringify(err) + ' with status - ');
      });
   }

   onKeyupFilter(event, value) {
      if (event.key !== 'Shift') {
         if (value.trim().length > 0) {
            this.filteredBlanksList = this.filter(this.filteredBlanksList, value);
         } else {
            this.filteredBlanksList = this.blanksList;
         }
      }
   }

   filter(list: Blanks[], value: string) {
      
      let filteredList: Blanks[] = list.filter(item => {
         return (
            item.skuCode?.toUpperCase().includes(value.toUpperCase()) ||
            item.description?.toUpperCase().includes(value.toUpperCase()) ||
            item.gsm?.toUpperCase().includes(value.toUpperCase())
         )
      });
      return filteredList;
   }

   onSortColumnChange($event: any) {
      //
      this.filteredBlanksList = this.sharedService.sortListByPropertyName(this.filteredBlanksList, this.selectedSortByName);
   }

   sortAsc() {
      this.sortMode = "ASC";
      this.onSortColumnChange(null);
   }

   sortDesc() {
      this.sortMode = "DESC";
      this.onSortColumnChange(null);
      this.filteredBlanksList.reverse();
   }


   deleteBlanks(blanks: Blanks) {
      if (confirm('Are you sure you want to delete this Blank ?')) {
         this.blanksService.deleteBlanks(blanks).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               let updatedblanksFromDB = response.responsePayload;
               this.toastrService.success(response.responseStatus.message);
               this.loadBlanksList();
            } else {
               this.toastrService.error(response.responseStatus.message);
            }
         },
            error => {
               console.error(error);
               this.toastrService.error(error.message);
            });
      }
   }

}
