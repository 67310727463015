import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from 'src/app/shared/model/user.model';
import { ApiResponse} from 'src/app/shared/model/api-response';
import { Observable} from "rxjs/index";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UserManagementService {
  readonly baseUrl = environment.apiEndPoint;
  constructor(private http: HttpClient) { }

  getUsers() : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl+'/user/list');
  }

  getUserById(id: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl+'/user/'+id);
  }

  getUserByToken(token: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl+'/user/token/'+token);
  }

  createUser(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl+'/user/create', user);
  }

  updateUser(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl+'/user/update/'+user.id,user);
  }

  deleteUser(id: string): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(this.baseUrl+id);
  }

  getUsersRoles() : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl+'/user/list');
  }

  getUsersRolePermissions(userId:string) : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl+'/user/permissions/'+userId);
  }

  sendResetPasswordEmail(user: User){
    return this.http.post<ApiResponse>(this.baseUrl + '/login/password/forgot', user);
  }
  
  getUsersByRoleId(roleId: string){
   return this.http.post<ApiResponse>(this.baseUrl + '/user/list/roleId', roleId);
  }

}
