import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { SearchComponent } from 'src/app/common/search/search.component';
import { SearchDetails } from 'src/app/shared/model/common/search-details.model';
import { FilterConfig } from 'src/app/shared/model/common/search-filter.model';
import { ComposeEmail } from 'src/app/shared/model/compose-email.model';
import { EmailTemplateConfig } from 'src/app/shared/model/email-template-config.model';
import { ProductAttachment } from 'src/app/shared/model/product.model';

import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { Printer } from 'src/app/shared/model/vendors/printer.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { ComposeEmailService } from 'src/app/shared/service/compose-email.service';

import { SearchService } from 'src/app/shared/service/search.service';
import { VendorsService } from 'src/app/shared/service/vendors/vendors.service';
import { SalesPreProTrackerService } from 'src/app/shared/service/sales/prePro/sales-pre-pro-tracker.service';
import { SalesPreProTrackerView } from 'src/app/shared/model/sales/prePro/sales-pre-pro-tracker-view.model';
import { SalesHelperService } from 'src/app/shared/service/sales/sales-helper.service';



@Component({
  selector: 'app-pre-pro-tracker-list',
  templateUrl: './pre-pro-tracker-list.component.html',
  styleUrls: ['./pre-pro-tracker-list.component.css']
})
export class PreProTrackerListComponent implements OnInit {

   constructor(private authService: AuthService, private searchService: SearchService, private salesPreProTrackerService: SalesPreProTrackerService, private toastrService: ToastrService,
      private composeEmailService: ComposeEmailService, private vendorService: VendorsService, private salesHelperService: SalesHelperService) { }

   userAccessSubscription: Subscription;
   userAccess: UserAccess;

   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;

   printerList: Printer[] = [];
   searchConfig: SearchDetails;
   sortColumns = [
      { columnName: 'SALES_ORDER_NUMBER', sortByName: 'Sales Order #' },
      //{ columnName: 'APPROVAL_STATUS', sortByName: 'Approval Status' },
      { columnName: 'ORDER_SHIP_DATE', sortByName: 'Ship Date' },
      { columnName: 'PRINTER_NAME', sortByName: 'Printer Name' },

   ];

   @ViewChild('searchComponent') searchComponent: SearchComponent<any>;
   showEmailEditor: boolean = false;
   composeEmail: ComposeEmail = new ComposeEmail;
   salesPreProTrackerViewList: SalesPreProTrackerView[] = [];


   ngOnInit(): void {
      this.handleSubscriptions();
      if (!this.searchConfig) {
         this.searchConfig = this.searchService.salesPreProTrackerViewListSearchDetails;
         this.setFilterConfigs();
      }
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
         this.currentLoggedInUser = currentLoggedInUser;
      });
      this.vendorService.printerListObservable.subscribe(res => {
         if (res && res != null && res.length > 0) {
            this.printerList.length = 0;
            this.printerList.push(...res);
         }
         //console.log("PTBI PrinterList",this.printerList);
      });
   }

   setFilterConfigs() {
      if (!this.searchConfig.filterConfigs) {
         this.searchConfig.filterConfigs = [];
         //Printer List
         let printerConfig: FilterConfig = {
            filterType: 'IN_CHECKLIST',
            label: 'Printer',
            currentSelection: 'ALL',
            options: this.printerList,
            optionDislpayVar: 'name',
            optionValueVar: 'name',
            fieldName: 'printer_name'
         };
         this.searchConfig.filterConfigs.push(printerConfig);
         //Date Range

         let approvateDateFilterConfig: FilterConfig = {
            filterType: 'DATE_RANGE',
            label: 'Ship Date',
            currentSelection: undefined,
            fieldName: 'ORDER_SHIP_DATE'
         };
         this.searchConfig.filterConfigs.push(approvateDateFilterConfig);

       
         let productionStatusListFilterForProductionTracker=this.salesHelperService.productionStatusListFilterForProductionTracker;
         let productionStatus:FilterConfig={
             filterType:'IN_CHECKLIST',
             label:'Production Status',
             currentSelection:'ALL',
             options: productionStatusListFilterForProductionTracker,
             optionDislpayVar:'display',
             optionValueVar:'value',
             fieldName:'PRODUCTION_STATUS'
           };
           this.searchConfig.filterConfigs.push(productionStatus);
      
          
      }
   }

   showHidePreProTrackerViewDetails(atv: SalesPreProTrackerView) {
      if (!atv.expanded) {
         this.salesPreProTrackerService.getSalesOrderPreProTrackerDetails(atv).subscribe(res => {
            if (res.responseStatus.status === 'SUCCESS') {
               atv.salesPreProTrackers = res.responsePayload;
               atv.expanded = true;
            } else {
               this.toastrService.error('Operation failed while retrieving pre-pro details for the Sales Order');
            }
         }, err => {
            this.toastrService.error('Error while retrieving pre-pro details for the Sales Order');
         });


      } else {
         atv.expanded = false;
      }
   }

   handleThumbnailError(productAttachment: ProductAttachment, event) {
      if (productAttachment) {
         if (isNaN(productAttachment?.thumbnailErrorCount)) {
            productAttachment.thumbnailErrorCount = 0;
         }
         event.target.src = productAttachment.fileSignedUrl;
         var x: number = productAttachment.thumbnailErrorCount;
         productAttachment.thumbnailErrorCount = x + 1;
         if (productAttachment.thumbnailErrorCount < 2) {
            event.target.src = productAttachment.fileSignedUrl;
            productAttachment.thumbnailSignedUrl = productAttachment.fileSignedUrl;
         } else {
            event.target.src = "/assets/default-image.jpg"
         }
      }
   }

   editPreProTrackerView(preProTrackerView: SalesPreProTrackerView) {
      if (!preProTrackerView.expanded) {
         this.showHidePreProTrackerViewDetails(preProTrackerView);
      }
      preProTrackerView.editable = true;
   }

   savePreProTrackerView(preProTrackerView: SalesPreProTrackerView) {
      this.salesPreProTrackerService.saveSalesOrderPreProTrackerDetails(preProTrackerView).subscribe(res => {
         if (res.responseStatus.status === 'SUCCESS') {
            preProTrackerView.expanded = false;
            preProTrackerView.editable = false;
            this.showHidePreProTrackerViewDetails(preProTrackerView);
         } else {
            this.toastrService.error('Operation failed while saving prePro details for the Sales Order');
         }
      }, err => {
         this.toastrService.error('Error while saving prePro details for the Sales Order');
      });
   }


   relaodPreProTrackerView(preProTrackerView: SalesPreProTrackerView) {
      preProTrackerView.expanded = false;
      preProTrackerView.editable = false;
      this.showHidePreProTrackerViewDetails(preProTrackerView);
   }


   expandList(expand: boolean) {
      if (this.salesPreProTrackerViewList && this.salesPreProTrackerViewList.length > 0) {
         this.salesPreProTrackerViewList.forEach(pptv => {
            pptv.expanded = expand;
         });
      }
   }

   sendSalesPreProEmail(pptv: SalesPreProTrackerView) {
      this.showEmailEditor = true;
      let emailConfig: EmailTemplateConfig = new EmailTemplateConfig;
      let productIds = pptv.salesPreProTrackers.map(pptvd => pptvd.productId);
      emailConfig.productIdList = productIds;
      emailConfig.templateName = 'productDetailsWithVisualForPreProTracker';
      emailConfig.salesOrderNumber = pptv.salesOrderNumber;
      emailConfig.salesOrderId = pptv.salesOrderId;
      this.composeEmail = new ComposeEmail;
      this.getEmailTemplate(emailConfig);
   }


   getEmailTemplate(emailConfig: EmailTemplateConfig) {
      this.composeEmailService.getEmailTemplate(emailConfig).subscribe(response => {
         let composeEmailTemp: ComposeEmail = response.responsePayload;
         this.composeEmail.emailContent = composeEmailTemp.emailContent;
         this.composeEmail.emailTo = composeEmailTemp.emailTo ? composeEmailTemp.emailTo : [];
         this.composeEmail.emailCc = composeEmailTemp.emailCc ? composeEmailTemp.emailCc : [];
         this.composeEmail.emailBcc = composeEmailTemp.emailBcc ? composeEmailTemp.emailBcc: [];
         this.composeEmail.emailSubject = composeEmailTemp.emailSubject;
         document.getElementById('composeEmailToggler').click();
      });
   }

}
