import { CustomerSalesPerson } from './customer-sales-person.model';
import { User } from './user.model';
import { CustomerDiscount } from './customer-discount.model';

export class Customer {
   id: string;
   customerCode: string;
   customerName: string;
   legalName: string;
   tier: string;
   territory: string;
   status: boolean;
   notes: string;
   parentCustomerId: string;
   customerSalesPersonList: CustomerSalesPerson[] = [];
   customerAssociationList: Customer[] = [];
   parent:boolean;
   productionCoordinatorId: string;
   productionCoordinator: User;
   customerDiscountList: CustomerDiscount[] = [];
   totalDiscount: number;
   deliveryPreference:string;
}
