
<div  class="ml-1" *ngIf="attachment"><!-- *ngIf="attachment && attachment.mimeType && attachment.mimeType.startsWith('image')"-->
    <!--<a (click)="selectVariableImage(attachment,variable,$event)" 
        [style.background-color]="attachment.fileSignedUrl== variable.fileSignedUrl? 'white' : 'white'">-->
        <img [src]="attachment.thumbnailSignedUrl" class="img-thumbnail img-fluid" (mouseenter)="preview(attachment,true,$event);" (mouseleave) ="preview(attachment,false,$event)"
            alt="Pending" style="display: block;" [style.width]="defaultWidth" [style.height]="defaultHeight"
            [style.border-color]="attachment?.fileSignedUrl== fileSignedUrl? 'tomato' : '#80808033'"
            [style.border-width]="attachment?.fileSignedUrl== fileSignedUrl? '1.5px' : 'thin'"
            (error)="handleThumbnailError(attachment,$event)">
    <!--</a>-->
</div>

<div  class="ml-1" *ngIf="!attachment">
        <img src="/assets/default-image.jpg" class="img-thumbnail img-fluid" 
            alt="Pending" style="display: block;  width:60px;  height:60px;">
    <!--</a>-->
</div>

<div *ngIf="previewImage" class="shadow">
    <img [src]="previewAttachment.thumbnailSignedUrl" class="img-thumbnail img-fluid"
                                    [style.top]="previewTop" [style.left]="previewLeft"
                                    alt="Pending" style="display: block;  width:300px;  height:300px;  position: fixed;"
                                    (error)="handleThumbnailError(previewAttachment,$event)">
</div>
