import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../model/api-response';
import { AllocatedBlankSupplierCombinationDetails } from '../../model/sales/costing/allocation/allocated-blank-supplier-combination-details.model';
import { AllocatedPrinterCombinationDetails } from '../../model/sales/costing/allocation/allocated-printer-combination-details.model';
import { CostingAllocation } from '../../model/sales/costing/allocation/costing-allocation.model';
import { StyleCosting } from '../../model/sales/costing/allocation/style-costing.model';
import { CostTypeDetail } from '../../model/sales/costsheet/cost-type-detail.model';
import { BlanksSupplier } from '../../model/vendors/blanks-supplier.model';
import { Printer } from '../../model/vendors/printer.model';
import { VendorsService } from '../vendors/vendors.service';
import { CostingHelperService } from './costing-helper.service';
import { SharedService } from 'src/app/shared/service/shared.service';
import { CostTypeTemplate } from '../../model/sales/costsheet/cost-type-template.model';


@Injectable({
  providedIn: 'root'
})
export class SalesOrderCostingService {
  
  
  blanksSupplierList: BlanksSupplier[] = [];
  private printerList: Printer[] = [];
  masterCostTypeDetailList:CostTypeTemplate[]=[];
  
  constructor(private http: HttpClient, private vendorsService: VendorsService,private costingHelperService:CostingHelperService
   ,private sharedService:SharedService) {
     this.loadBlanksSupplierList();
     this.loadPrinterList();
     this.loadAllCostTypeMaster();
   }
   getPrinterList(){
      return this.sharedService.deepClone(this.printerList);
   }

   async loadAllCostTypeMaster() {
      await this.loadAllCostTypes().toPromise().then(response => {
        if (response.responseStatus.status === 'SUCCESS') {
          let tmpCostTypeDetailList = response.responsePayload;
          this.masterCostTypeDetailList = tmpCostTypeDetailList;
        }
      });
    }

   validateCostingAllocation(costingAllocation: CostingAllocation): any {
      let retVal = { status: 'VALID', reasons: [] };
      if (!costingAllocation.allocatedPrinters || costingAllocation.allocatedPrinters === null || costingAllocation.allocatedPrinters.length === 0) {
         retVal.status = 'INVALID';
         retVal.reasons.push('No Printer selected');
      }
      if (costingAllocation.allocatedPrinters && costingAllocation.allocatedPrinters !== null && costingAllocation.allocatedPrinters.length > 0) {
         costingAllocation.styleCosting.forEach(sc => {
            this.validateCostingAllocationForStyle(sc, retVal);
         });

      }
      return retVal;
  }
  
   validateCostingAllocationForStyle(sc: StyleCosting, retVal: { status: string; reasons: any[]; }) {
      if(sc.status!=='CANCELLED'){
      let allocatedSupplierComb: number = 0;
      if (sc.bSupplierCombDetails && sc.bSupplierCombDetails.length > 0) {
         sc.bSupplierCombDetails.forEach(bComb => {
            if (bComb.allocated) {
               allocatedSupplierComb = allocatedSupplierComb + 1;
            }
         });
      }
      let allocatedPrinterComb: number = 0;
      let nonFullPackageComb: number = 0;
      if (sc.printerCombDetails && sc.printerCombDetails.length > 0) {
      sc.printerCombDetails.forEach(pcomb => {
         if (pcomb.allocated) {
            allocatedPrinterComb = allocatedPrinterComb + 1;
            if (!pcomb.fullPackage) {
               nonFullPackageComb = nonFullPackageComb + 1;
            }
         }
      });
   }
      if (allocatedPrinterComb === 0) {
         retVal.status = 'INVALID';
         retVal.reasons.push(sc.sku +': Missing Printer Details');
      }
      if (nonFullPackageComb > 0 && allocatedSupplierComb === 0) {
         retVal.status = 'INVALID';
         retVal.reasons.push(sc.sku +': Missing Supplier Details');
         
      }
   }
   }

   loadCostTypes(classification:string,subClassification:string,currency:string,subjectId?:string):Observable<ApiResponse> {
      if(!subjectId){
         subjectId='NA'
      }
      return this.http.get<ApiResponse>(this.rooturl + '/costing/allocation/costType/'+classification+'/'+subClassification+'/'+subjectId+'/'+currency).
      pipe(
         map(
            response =>{
               if (response.responseStatus.status === 'SUCCESS') {
                  let tmpCostTypeDetailList = response.responsePayload;
                  this.populateCostTypeDetailFromTemplate(tmpCostTypeDetailList);
               }
               return response;
            }
          )
      )
   }

   loadAllCostTypes() {
      return this.http.get<any>(this.rooturl + '/costTypeDetail/default/list');
   }

   loadBlanksSupplierList() {
     this.vendorsService.getBlanksSupplierList().subscribe(response => {
        this.blanksSupplierList = response.responsePayload;
        this.blanksSupplierList = this.sharedService.sortListByPropertyName(this.blanksSupplierList,'name');
     }, err => {
        console.error('Error while Loading Blanks Supplier List : ' + JSON.stringify(err) + ' with status - ');
     });
  }

  loadPrinterList() {
     this.vendorsService.getPrinterList().subscribe(response => {
        this.printerList = response.responsePayload;
        this.printerList = this.sharedService.sortListByPropertyName(this.printerList,'name');
     }, err => {
        console.error('Error while Loading Printer List : ' + JSON.stringify(err) + ' with status - ');
     });
  }
 
  readonly rooturl = environment.apiEndPoint;
 /****Allocation Sheet Changes */

 getAllocationSheet(salesOrderId: string): Observable<ApiResponse> {
  return this.http.get<any>(this.rooturl + '/costing/allocation/'+salesOrderId)
  .pipe(map(
      response =>{
         if (response.responseStatus.status === 'SUCCESS') {
            let costingAllocation: CostingAllocation = response.responsePayload as CostingAllocation;
            this.populateAdditionalAttributesInCostTypeDetails(costingAllocation);
         }
         return response;
      }
  ));
}

saveAllocationSheet(salesOrderId: string,costingAllocation: CostingAllocation): Observable<ApiResponse> {
  return this.http.post<any>(this.rooturl + '/costing/allocation/save/'+salesOrderId, costingAllocation)
  .pipe(map(
      response =>{
         if (response.responseStatus.status === 'SUCCESS') {
            let costingAllocation: CostingAllocation = response.responsePayload as CostingAllocation;
            this.populateAdditionalAttributesInCostTypeDetails(costingAllocation); 
          }
          return response;
      }
  ));
}
/** Allocation Sheet Changes */
/** Allocation Sheet Changes Calulcation */

calculateAllocationSheet(costingAllocation:CostingAllocation){
  if(costingAllocation){
     if(costingAllocation.styleCosting && costingAllocation.styleCosting.length>0){
        costingAllocation.styleCosting.forEach(sc => {
            this.calculatePrinterAndBlankCount(sc);// For UI table width pecentage determintaion
           this.calculateTotalBlanks(sc);
           this.calulateBlanksSupplierComboDetails(sc.bSupplierCombDetails,costingAllocation);
           this.calulatePrinterComboDetails(sc.printerCombDetails,costingAllocation);
           
        });
     }
     
  }
}
   calculatePrinterAndBlankCount(sc: StyleCosting) {
    let totalSupplierCount=1;//minimum 1 for NOtes section
    let totalPrinterCount=1;
    if(sc.bSupplierCombDetails && sc.bSupplierCombDetails.length>0){
      sc.bSupplierCombDetails.forEach(bComb => {
         if(bComb.bSupplierDetails && bComb.bSupplierDetails.length>0){
            totalSupplierCount=totalSupplierCount+bComb.bSupplierDetails.length;
         }
      });
    }
    if(sc.printerCombDetails && sc.printerCombDetails.length>0){
      sc.printerCombDetails.forEach(pComb => {
         if(pComb.printerDetails && pComb.printerDetails.length>0){
            totalPrinterCount=totalPrinterCount+pComb.printerDetails.length;
         }
      });
    }
    sc.printerCount=totalPrinterCount;
    sc.supplierCount=totalSupplierCount;
    
   }
calulateBlanksSupplierComboDetails(bSupplierCombDetails: AllocatedBlankSupplierCombinationDetails[],costingAllocation:CostingAllocation) {

 if(bSupplierCombDetails && bSupplierCombDetails.length>0){
     bSupplierCombDetails.forEach(bSuppComb => {
        let sizeDetailsMap = new Map<string, AverageBlankSizeDetails>();
     bSuppComb.bSupplierDetails.forEach(bsDetails => {
        let bsQty=0;
        bsDetails.blankSizeDetails.forEach(bsd => {
           if(!sizeDetailsMap.get(bsd.size)){
              sizeDetailsMap.set(bsd.size,{size:bsd.size,totalBlankQtyCost:0,totalBlankQty:0});
           }
           let mapEntry=sizeDetailsMap.get(bsd.size);
           let bsdQty=0;
           let bsdCost=0;
           if(!isNaN(bsd.qty)){
            bsdQty=bsd.qty;
           }
           if(!isNaN(bsd.cost)){
            bsdCost=bsd.cost;
           }
           bsQty=bsQty+bsd.qty;
           mapEntry.totalBlankQty=mapEntry.totalBlankQty+bsdQty;
           mapEntry.totalBlankQtyCost= mapEntry.totalBlankQtyCost + (bsdQty * this.costingHelperService.sanitizeCurrency(costingAllocation.orderCurrency,bsDetails.currency,bsdCost,costingAllocation.exchangeRateUsdToCad, costingAllocation.exchangeRateCadToUsd));
        });
        bsDetails.allocatedQty=bsQty;
     }); 
     let totalQtyAllocated:number=0;
     let totalAverageSizeCost:number=0;
     let sizeCount:number=0;
     bSuppComb.averageBlankSizeDetails.forEach(avgBsd => {
        
        let mapEntry=sizeDetailsMap.get(avgBsd.size);
        avgBsd.cost=0;
        if(mapEntry.totalBlankQty && mapEntry.totalBlankQty!==0){
         let avgCost:number=mapEntry.totalBlankQtyCost/mapEntry.totalBlankQty;
         avgBsd.cost=avgCost;
        }
        avgBsd.qty=mapEntry.totalBlankQty;
        
        totalQtyAllocated=totalQtyAllocated+ avgBsd.qty;
        totalAverageSizeCost=totalAverageSizeCost+ (avgBsd.cost * avgBsd.qty);

        sizeCount++;
     });
     bSuppComb.averageBlankCost=0;
     if(totalQtyAllocated!==0){
        bSuppComb.averageBlankCost=totalAverageSizeCost/totalQtyAllocated;
        bSuppComb.averageBlankCost=this.costingHelperService.roundUp(bSuppComb.averageBlankCost,3);
     }
        bSuppComb.totalQtyAllocated=totalQtyAllocated;
     });
 }
}

   calulatePrinterComboDetails(printerCombDetails: AllocatedPrinterCombinationDetails[],costingAllocation:CostingAllocation) {

      if (printerCombDetails && printerCombDetails.length > 0) {
         printerCombDetails.forEach(printerComb => {
            let sizeDetailsMap = new Map<string, AveragePrinterSizeDetails>();
            printerComb.printerDetails.forEach(printrDetails => {
               let pQty=0;
               printrDetails.printerSizeDetails.forEach(psd => {
                  if (!sizeDetailsMap.get(psd.size)) {
                     sizeDetailsMap.set(psd.size, { size: psd.size, totalPrintQtyCost: 0, totalPrintQty: 0 });
                  }
                  let psdQty=0;
                  let psdCost=0;
                  if(!isNaN(psd.qty)){
                     psdQty=psd.qty;
                  }
                  if(!isNaN(psd.cost)){
                     psdCost=psd.cost;
                  }
                  let mapEntry = sizeDetailsMap.get(psd.size);
                  pQty=pQty+psdQty;
                  mapEntry.totalPrintQty = psdQty;//mapEntry.totalPrintQty + psd.qty;
                  mapEntry.totalPrintQtyCost = mapEntry.totalPrintQtyCost + (psdQty * this.costingHelperService.sanitizeCurrency(costingAllocation.orderCurrency,printrDetails.currency,psdCost, costingAllocation.exchangeRateUsdToCad, costingAllocation.exchangeRateCadToUsd));

               });
               printrDetails.allocatedQty=pQty;
            });
            //console.log('Size Detail Map For Printer', sizeDetailsMap);
            let totalQtyAllocated: number = 0;
            let totalAveragePrintingCost: number = 0;
            let sizeCount: number = 0;
            printerComb.averagePrinterSizeDetails.forEach(avgPsd => {

               let mapEntry = sizeDetailsMap.get(avgPsd.size);
               avgPsd.cost = 0;
               if(mapEntry.totalPrintQty && mapEntry.totalPrintQty!==0){
                  let avgCost: number = mapEntry.totalPrintQtyCost / mapEntry.totalPrintQty;
                  avgPsd.cost = avgCost;
               }
               avgPsd.qty = mapEntry.totalPrintQty;

               totalQtyAllocated = totalQtyAllocated + avgPsd.qty;
               totalAveragePrintingCost = totalAveragePrintingCost + (avgPsd.cost * avgPsd.qty);

               sizeCount++;
            });
            //console.log('COmbo Average Printing Details -> ', totalQtyAllocated, totalAveragePrintingCost, sizeCount);
            printerComb.averagePrintCost = 0;
            if(totalQtyAllocated!==0){
               printerComb.averagePrintCost = totalAveragePrintingCost / totalQtyAllocated;
               printerComb.averagePrintCost=this.costingHelperService.roundUp(printerComb.averagePrintCost,3);
            }
            printerComb.totalQtyAllocated = totalQtyAllocated;
         });
      }
  }

calculateTotalBlanks(sc: StyleCosting) {
   let totalRequiredBlanksQuantity=0;
   if(sc.extraQtyType==='Percentage' && sc.extraQtyPercentage>0/* && sc.status!=='CANCELLED'// CAncelled must have 0 extra qty required*/){
      this.calculateExtraQty(sc,sc.extraQtyPercentage);
  }
  sc.blankSizeQty.forEach(bsq => {
     if(!isNaN(bsq.extraQty) && !isNaN(bsq.orderQty)){
        bsq.totalBlankQty=bsq.orderQty+bsq.extraQty;
        totalRequiredBlanksQuantity=totalRequiredBlanksQuantity+bsq.totalBlankQty;
     }
  });
  sc.totalRequiredBlanksQuantity=totalRequiredBlanksQuantity;
  
}

calculateExtraQty(sc:StyleCosting,extraQtyPercentage:number){
   sc.blankSizeQty.forEach(bsq => {
     let extraQtyForPercentage=((extraQtyPercentage*bsq.orderQty)/100);
     //console.log('extraQtyForPercentage',extraQtyForPercentage);
     if(extraQtyForPercentage>0){
       if(extraQtyForPercentage<1){
         extraQtyForPercentage=1;
       }else{
         extraQtyForPercentage=this.costingHelperService.roundUp(extraQtyForPercentage,0);
       }
     }
     bsq.extraQty=extraQtyForPercentage;
   });
 }

calculateTotalOrderProfit(ca: CostingAllocation) {
   if(ca){
   this.isAllStylesApproved(ca);
    let totalCost:number=0;
    let totalRevenue:number=0;
    let totalGrossProfit:number=0;
    let totalGrossProfitPercentage:number=0;
    let totalQuantity: number =0;
   if(ca && ca.styleCosting && ca.styleCosting.length>0){
      ca.styleCosting.forEach(sc => {
         if(sc.status!=='CANCELLED' && sc.costingSheets && sc.costingSheets.length>0){
            sc.costingSheets.forEach(cs => {
               if(cs.approvalStatus==='APPROVED'){
                  totalGrossProfit=totalGrossProfit+cs.grossProfit;
                  totalCost=totalCost+cs.totalCost;
                  totalRevenue=totalRevenue+cs.totalRevenue;

               }
            });
            totalQuantity = totalQuantity + sc.totalQty;
         }
      });
   }
   totalGrossProfitPercentage=(totalGrossProfit/totalRevenue)*100;
   ca.totalCost=totalCost;
   ca.totalRevenue=totalRevenue;
   ca.totalGrossProfit=totalGrossProfit;
   ca.totalGrossProfitPercentage=totalGrossProfitPercentage;
   
   ca.totalQuantity = totalQuantity;
}
 }

   isAllStylesApproved(ca: CostingAllocation):boolean {
      let allApproved: boolean = false;
      let scApproved: number = 0;
      let scApprovalPending: number = 0;
      if (ca && ca.styleCosting && ca.styleCosting.length > 0) {
         ca.styleCosting.forEach(sc => {
            if (sc.status !== 'CANCELLED' && sc.costingSheets && sc.costingSheets.length > 0) {
               let csApproved: number = 0;
               sc.costingSheets.forEach(cs => {
                  if (cs.approvalStatus === 'APPROVED') {
                     csApproved = csApproved + 1;
                  }
               });
               if (csApproved === 1) {
                  scApproved = scApproved + 1;
               } else {
                  scApprovalPending = scApprovalPending + 1;
               }
            } else if (sc.status !== 'CANCELLED' && (!sc.costingSheets || sc.costingSheets.length === 0)) {
               scApprovalPending = scApprovalPending + 1;
            }
         });
      }
      ca.stylesApproved = scApproved;
      ca.stylesPending = scApprovalPending;
      if (scApprovalPending === 0) {
         allApproved = true;
         ca.allStylesApproved = true;
      } else {
         ca.allStylesApproved = false;
      }

      return ca.allStylesApproved;
   }

/** Allocation Sheet Changes Calulcation Ends */

populateAdditionalAttributesInCostTypeDetails(ca:CostingAllocation){
   if(ca){
      if(ca.customerCostTypeDetails && ca.customerCostTypeDetails.length>0){
         this.populateCostTypeDetailFromTemplate(ca.customerCostTypeDetails);
      }
      if(ca.orgCostTypeDetails && ca.orgCostTypeDetails.length>0){
         this.populateCostTypeDetailFromTemplate(ca.orgCostTypeDetails);
      }
      if(ca.allocatedPrinters && ca.allocatedPrinters.length>0){
         ca.allocatedPrinters.forEach(ap => {
            if(ap.costTypeDetails && ap.costTypeDetails.length>0){
               this.populateCostTypeDetailFromTemplate(ap.costTypeDetails);
            }
         });
      }
      if(ca.allocatedSuppliers && ca.allocatedSuppliers.length>0){
         ca.allocatedSuppliers.forEach(as => {
            if(as.costTypeDetails && as.costTypeDetails.length>0){
               this.populateCostTypeDetailFromTemplate(as.costTypeDetails);
            }
         });
      }
      if(ca.styleCosting && ca.styleCosting.length>0){
         ca.styleCosting.forEach(sc => {
            if(sc.costTypeDetails && sc.costTypeDetails.length>0){
               this.populateCostTypeDetailFromTemplate(sc.costTypeDetails);
            }
            if(sc.printerCombDetails && sc.printerCombDetails.length>0){
               sc.printerCombDetails.forEach(pComb => {
                  if(pComb.printerDetails && pComb.printerDetails.length>0){
                     pComb.printerDetails.forEach(pd => {
                        if(pd.costTypeDetails && pd.costTypeDetails.length>0){
                           this.populateCostTypeDetailFromTemplate(pd.costTypeDetails);
                        }
                     });
                  }
               });
            }
            if(sc.bSupplierCombDetails && sc.bSupplierCombDetails.length>0){
               sc.bSupplierCombDetails.forEach(bComb => {
                  if(bComb.bSupplierDetails && bComb.bSupplierDetails.length>0){
                     bComb.bSupplierDetails.forEach(sd => {
                        if(sd.costTypeDetails && sd.costTypeDetails.length>0){
                           this.populateCostTypeDetailFromTemplate(sd.costTypeDetails);
                        }
                     });
                  }
               });
            }
            if(sc.costingSheets && sc.costingSheets.length>0){
               sc.costingSheets.forEach(cs => {
                  if(cs.costTypeDetailList && cs.costTypeDetailList.length>0){
                     this.populateCostTypeDetailFromTemplate(cs.costTypeDetailList);      
                    //console.log('COsting Sheet after population',cs.costTypeDetailList);
                  }
               });
            }
         });
      }
   }

}

 async populateCostTypeDetailFromTemplate(costTypeDetails:CostTypeDetail[], currency?:string){
   if(!this.masterCostTypeDetailList || this.masterCostTypeDetailList.length===0){
      await this.loadAllCostTypeMaster();
   }
   if(this.masterCostTypeDetailList && this.masterCostTypeDetailList.length>0 && costTypeDetails && costTypeDetails.length>0){
      costTypeDetails.forEach(ctd => {
         let tempArr=this.masterCostTypeDetailList.filter(x=>x.id===ctd.id);
         if(tempArr && tempArr.length>0){
            let template=tempArr[0];
            ctd.calculationType=template.calculationType;
            ctd.category=template.category;
            ctd.classification=template.classification;
            ctd.costType=template.costType;
            if(currency){
               ctd.currency=currency;
            }
            ctd.description=template.description;
            ctd.sortOrder=template.sortOrder;
            ctd.source=template.source;
            ctd.subCategory=template.subCategory;
            ctd.subClassification=template.subClassification;
         }
      });
   }
 }


}
export interface AverageBlankSizeDetails{
size:string;
totalBlankQtyCost:number;
totalBlankQty:number;
}

export interface AveragePrinterSizeDetails{
   size:string;
   totalPrintQtyCost:number;
   totalPrintQty:number;
   }
