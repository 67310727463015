
<div *ngIf="'IMAGE'===variable.varType && variable.varSubType!==('ASSOCIATED_MEMBER_VAR_IMAGE') && variable.varValueSource === 'PNL'">
    <div class="mb-3 ml-1">
        <div class="d-flex flex-wrap " *ngIf="product && product.brandPnl">
            <ng-container *ngFor="let attachment of product.brandPnl">
                <div  class="p-1" *ngIf="attachment.mimeType.startsWith('image')">
                    <a (click)="selectVariableImage(attachment,variable,$event)" 
                        [style.background-color]="attachment.fileSignedUrl== variable.fileSignedUrl? 'white' : 'white'">
                        <img [src]="attachment.thumbnailSignedUrl" class="img-thumbnail img-fluid" (mouseenter)="preview(attachment,true,$event);" (mouseleave) ="preview(attachment,false,$event)"
                            alt="Pending" [style]="'display: block;  width:'+imageWidthPx+'px;  height:'+imageHeightPx+'px;'"
                            [style.border-color]="attachment.fileSignedUrl== variable.fileSignedUrl? 'tomato' : '#80808033'"
                            [style.border-width]="attachment.fileSignedUrl== variable.fileSignedUrl? selectionBorderWidth : 'thin'"
                            (error)="handleThumbnailError(attachment,$event)">
                            <div class="text-muted f-12 py-2 d-flex justify-content-center" *ngIf="modal===true">{{attachment.originalFileName}}</div>
                    </a>
                    
                </div>
            </ng-container>
        </div>
    </div>
</div>
<div *ngIf="'IMAGE'===variable.varType && variable.varSubType!==('ASSOCIATED_MEMBER_VAR_IMAGE') && variable.varValueSource === 'IMAGES'">
    <div class="mb-3 ml-1">
        <div class="d-flex flex-wrap" *ngIf="product && product.productImages">
            <div *ngFor="let attachment of productImages" class="ml-1">
                <a (click)="selectVariableImage(attachment,variable,$event)"
                    [style.background-color]="attachment.fileSignedUrl== variable.fileSignedUrl? 'white' : 'white'">
                    <img [src]="attachment.thumbnailSignedUrl" class="img-thumbnail img-fluid" (mouseenter)="preview(attachment,true,$event);" (mouseleave) ="preview(attachment,false,$event)"
                        alt="Pending" [style]="'display: block;  width:'+imageWidthPx+'px;  height:'+imageHeightPx+'px;'"
                        [style.border-color]="attachment.fileSignedUrl== variable.fileSignedUrl? 'tomato' : '#80808033'"
                        [style.border-width]="attachment.fileSignedUrl== variable.fileSignedUrl? selectionBorderWidth : 'thin'"
                        (error)="handleThumbnailError(attachment,$event)">
                </a>
            </div>
        </div>
    </div>
</div>

<div *ngIf="'IMAGE'===variable.varType && variable.varSubType===('ASSOCIATED_MEMBER_VAR_IMAGE') && variable.varValueSource === 'IMAGES'">
   <div class="mb-3 ml-1">
        <div class="d-flex flex-wrap" *ngIf="variable && variable.images">
            <ng-container *ngFor="let attachment of variable.images">
                <div  *ngIf="attachment.mimeType?.startsWith('image')" class="ml-1">
                    <a (click)="selectVariableImage(attachment,variable,$event)"
                        [style.background-color]="attachment.fileSignedUrl== variable.fileSignedUrl? 'white' : 'white'">
                        <img [src]="attachment.thumbnailSignedUrl" class="img-thumbnail img-fluid" (mouseenter)="preview(attachment,true,$event);" (mouseleave) ="preview(attachment,false,$event)"
                            alt="Pending" [style]="'display: block;  width:'+imageWidthPx+'px;  height:'+imageHeightPx+'px;'"
                            [style.border-color]="attachment.fileSignedUrl== variable.fileSignedUrl? 'tomato' : '#80808033'"
                            [style.border-width]="attachment.fileSignedUrl== variable.fileSignedUrl? selectionBorderWidth : 'thin'"
                            (error)="handleThumbnailError(attachment,$event)">
                    </a>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<div *ngIf="'IMAGE'===variable.varType && variable.varSubType!==('ASSOCIATED_MEMBER_VAR_IMAGE') && variable.varValueSource === 'PHOTO_APPROVAL'">
    <div class="mb-3 ml-1">
        <div class="d-flex flex-wrap" *ngIf="product && product.photoApproval">
            <ng-container *ngFor="let attachment of product.photoApproval">
            <div  class="ml-1" *ngIf="attachment.mimeType.startsWith('image')">
                <a (click)="selectVariableImage(attachment,variable,$event)"
                    [style.background-color]="attachment.fileSignedUrl== variable.fileSignedUrl? 'white' : 'white'">
                    <img [src]="attachment.thumbnailSignedUrl" class="img-thumbnail img-fluid" (mouseenter)="preview(attachment,true,$event);" (mouseleave) ="preview(attachment,false,$event)"
                        alt="Pending" [style]="'display: block;  width:'+imageWidthPx+'px;  height:'+imageHeightPx+'px;'"
                        [style.border-color]="attachment.fileSignedUrl== variable.fileSignedUrl? 'tomato' : '#80808033'"
                        [style.border-width]="attachment.fileSignedUrl== variable.fileSignedUrl? selectionBorderWidth : 'thin'"
                        (error)="handleThumbnailError(attachment,$event)">
                </a>
            </div>
            </ng-container>
        </div>
    </div>
</div>
<div *ngIf="'IMAGE'===variable.varType && variable.varSubType!==('ASSOCIATED_MEMBER_VAR_IMAGE') && variable.varValueSource === 'LOGO'">
    <div class="mb-3 ml-1">
        <div class="d-flex flex-wrap" *ngIf="brand && brand.logos">
            <div *ngFor="let attachment of brand.logos" class="ml-1">
                <a (click)="selectVariableImage(attachment,variable,$event)"
                    [style.background-color]="attachment.fileSignedUrl== variable.fileSignedUrl? 'white' : 'white'">
                    <img [src]="attachment.thumbnailSignedUrl" class="img-thumbnail img-fluid" (mouseenter)="preview(attachment,true,$event);" (mouseleave) ="preview(attachment,false,$event)"
                        alt="Pending" [style]="'display: block;  width:'+imageWidthPx+'px;  height:'+imageHeightPx+'px;'"
                        [style.border-color]="attachment.fileSignedUrl== variable.fileSignedUrl? 'tomato' : '#80808033'"
                        [style.border-width]="attachment.fileSignedUrl== variable.fileSignedUrl? selectionBorderWidth : 'thin'"
                        (error)="handleThumbnailError(attachment,$event)">
                </a>
            </div>
        </div>
    </div>
</div>

<div *ngIf="previewImage && modal!==true" class="shadow">
    <div [style.top]="previewTop" [style.left]="previewLeft"
     style="display: block;  width:300px;  height:300px;  position: fixed;z-index:100;"> 
        <div class="f-12 py-2 d-flex justify-content-center bg-dark text-white">{{previewAttachment.originalFileName}}</div>
        <img [src]="previewAttachment.thumbnailSignedUrl" class="img-thumbnail img-fluid" alt="Pending" 
                                        (error)="handleThumbnailError(previewAttachment,$event)">
    
    </div>
    
</div>