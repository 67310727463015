import { SizeOrderDetail } from './size-order-detail.model';

export class SubOrderDetail {
   id: string;
   parentId: string; // order Detail id
   customerPONumber: string;
   totalSubOrderQuantity: number;
   packagingType: string;
   prepackDetail: string;
   notes: string;
   status: string;
   deleteFlag: boolean = false;
   sizeOrderDetailList: SizeOrderDetail[] = [];
   type: string;
   category:string;
   sortOrder: number;
   poCustomerId: string;
   customerPoType: string;

   constructor() {
      this.id = null;
      this.parentId = null // order Detail id
      this.customerPONumber = null;
      this.totalSubOrderQuantity = 0;
      this.packagingType = null;
      this.prepackDetail = null;
      this.notes = null;
      this.sizeOrderDetailList = [];
      this.category ='';
   }
}

